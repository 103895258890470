import { getConfigVar } from 'features/common';

// SHIPPO auth
const SHIPPO_CLIENT_ID = getConfigVar('REACT_APP_SHIPPO_CLIENT_ID') as string;
export const SHIPPO_STATE_STRING = getConfigVar(
    'REACT_APP_SHIPPO_STATE_STRING',
) as string;
export const SHIPPO_AUTH_URL = `https://goshippo.com/oauth/authorize?response_type=code&client_id=${SHIPPO_CLIENT_ID}&scope=*&state=${SHIPPO_STATE_STRING}&utm_source=SpotOn`;

export enum ShippoStatus {
    activated = 'activated',
    deactivated = 'deactivated',
    notConnected = 'not_connected',
}
