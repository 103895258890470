import { generatePathFromRoot } from 'features/common/utils/routing.utils';

export const settingsMountPath = '/settings';

// paths used in routing definition
export const settingsLocalPaths = {
    root: '/',
    locations: '/locations',
    channels: '/channels',
    taxes: '/taxes',
    fees: '/fees',
    discounts: '/discounts',
    tipping: '/tipping',
    signatures: '/signatures',
    unitsOfMeasure: '/units-of-measure',
    teamMembers: '/members',
    shipping: '/shipping',
    loyalty: '/loyalty',
    receipt: '/receipt',
    onboarding: '/onboarding',
} as const;

// paths used for navigation
export const settingsPaths = {
    root: generatePathFromRoot(settingsMountPath),
    locations: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.locations,
    ),
    channels: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.channels,
    ),
    taxes: generatePathFromRoot(settingsMountPath, settingsLocalPaths.taxes),
    fees: generatePathFromRoot(settingsMountPath, settingsLocalPaths.fees),
    discounts: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.discounts,
    ),
    tipping: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.tipping,
    ),
    signatures: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.signatures,
    ),
    unitsOfMeasure: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.unitsOfMeasure,
    ),
    teamMembers: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.teamMembers,
    ),
    shipping: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.shipping,
    ),
    loyalty: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.loyalty,
    ),
    receipt: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.receipt,
    ),
    onboarding: generatePathFromRoot(
        settingsMountPath,
        settingsLocalPaths.onboarding,
    ),
} as const;
