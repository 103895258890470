import { Button, Text, Icon } from 'spoton-lib';

import { useMediaQuery } from 'features/common/hooks';

import styles from './ProductPageHeader.module.scss';
import { IPropTypes } from './ProductPageHeader.types';

/** Component shows header for component list with possibility to render children below itself */
export function ProductPageHeader(props: IPropTypes) {
    const { children, onAddNewProductClick, shouldHideActions } = props;
    const { isMobile } = useMediaQuery();

    const addNewProductButton = (
        <div className={styles.AddButton}>
            <Button
                block={isMobile}
                onClick={onAddNewProductClick}
                data-testid="addNewProduct"
                className={styles.AddButton_button}
            >
                <Icon
                    name="AddIcon"
                    alt="add category"
                    size={24}
                    className={styles.AddButton_icon}
                />
                New Product
            </Button>
        </div>
    );

    return (
        <div className={styles.ProductPageHeader}>
            <div className={styles.ProductPageHeader_titleContainer}>
                <Text type="h2" className={styles.ProductPageHeader_title}>
                    Products
                </Text>
                {!isMobile ? addNewProductButton : null}
            </div>
            <div className={styles.ProductPageHeader_subtitleContainer}>
                <div
                    className={
                        styles.ProductPageHeader_subtitleContainer___textWrapper
                    }
                >
                    <Text type="p">
                        See all your items or add any other product you&apos;re
                        selling at your business
                    </Text>
                </div>
                {!shouldHideActions && isMobile ? addNewProductButton : null}
            </div>
            {!shouldHideActions && (
                <div className={styles.ProductPageHeader_content}>
                    {children}
                </div>
            )}
        </div>
    );
}

export default ProductPageHeader;
