import { deleteCookie, getCookieValue, setCookie } from './cookies.utils';

export const merchantLocationCookieName = 'MerchantLocationId';

export function setMerchantLocationCookie(id: string): void {
    setCookie(merchantLocationCookieName, `${id}`);
}

export function getMerchantLocationCookieValue(): string | undefined {
    const cookieMerchantLocationId =
        getCookieValue(merchantLocationCookieName) ?? '';

    return cookieMerchantLocationId;
}

export function deleteMerchantLocationCookie(): void {
    deleteCookie(merchantLocationCookieName);
}
