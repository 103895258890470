import { useState } from 'react';

import { DeletePrompt } from 'features/common/components/DeletePrompt';

export const useDeletePrompt = (onRemoveClick: () => void) => {
    const [isDeletePromptOpen, setDeletePromptOpen] = useState(false);

    return {
        setDeletePromptOpen,
        deletePromptElement: isDeletePromptOpen ? (
            <DeletePrompt
                isOpen
                title="Are you sure you want to delete?"
                message="This option will be permanently deleted by clicking remove"
                onCancel={() => setDeletePromptOpen(false)}
                onConfirm={() => {
                    onRemoveClick();
                    setDeletePromptOpen(false);
                }}
            />
        ) : null,
    };
};
