import { getConfigFlag } from 'features/common/utils/config.utils';

// https://stackoverflow.com/a/25490531
export function getCookieValue(name: string): string | undefined {
    const cookie = getCookieName(name);
    // eslint-disable-next-line no-restricted-properties
    const b = document.cookie.match('(^|;)\\s*' + cookie + '\\s*=\\s*([^;]+)');

    return b ? b.pop() : undefined;
}

/**
 * We need to prefix the cookie with QA in only the QA environment or else SSO will not work
 */
export function getCookieName(name: string): string {
    const cookiePrefix = getConfigFlag('REACT_APP_IS_QA') ? 'QA' : '';

    return cookiePrefix + name;
}

export function deleteCookie(
    name: string,
    domain?: string,
    path?: string,
): void {
    const cookie = getCookieName(name);
    const pathString = path ? `path=${path};` : 'path=/;';
    const domainString = domain ? `domain=${domain};` : '';

    // eslint-disable-next-line no-restricted-properties
    document.cookie = `${cookie}=;${pathString}${domainString};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}

export function setCookie(name: string, value: string, days = 0): void {
    const cookie = getCookieName(name);
    let expire;

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expire = '; expires=' + date.toUTCString();
    } else {
        expire = '';
    }

    // eslint-disable-next-line no-restricted-properties
    document.cookie = cookie + '=' + value + expire + '; path=/';
}
