/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMemo } from 'react';
import moment from 'moment';

import { IShippingPackage } from 'features/shipping/types';
import { useQuery, useMutation } from 'features/common/serverStateHandler';
import { ShippingApi } from 'features/shipping/api';
import { requestParams } from 'features/common/constants';

export const queryKey = {
    availableShippingPackages: () =>
        [{ scope: 'availableShippingPackages' }] as const,
    selectedShippingPackages: () =>
        [{ scope: 'selectedShippingPackages' }] as const,
};

/* carrier name lower case:
   {
       fedex: [...packages],
       ups: [...packages],
   }
*/
export const useAvailableShippingPackages = () => {
    const { data, isLoading, ...rest } = useQuery(
        queryKey.availableShippingPackages(),
        ShippingApi.getAllAvailableBoxes,
        {
            useErrorBoundary: true,
        },
    );

    const shippingPackages = useMemo(() => {
        const packages = data || {};

        return Object.fromEntries(
            Object.entries(packages).map(([carrierName, packages]) => [
                carrierName.toLowerCase(),
                packages,
            ]),
        );
    }, [data]);

    return {
        availableShippingPackages: shippingPackages,
        isLoadingAvailableShippingPackages: isLoading,
        ...rest,
    };
};

export const useSelectedShippingPackages = () => {
    const { data, isLoading, ...rest } = useQuery(
        queryKey.selectedShippingPackages(),
        () => ShippingApi.getShippingPackages(requestParams),
    );

    const selectedShippingPackages = useMemo(() => {
        const packages = data || [];

        return packages.sort((a, b) => {
            if (a?.isDefault) {
                return -1;
            }

            if (b?.isDefault) {
                return 1;
            }

            return moment(a?.createdAt || 0).diff(b?.createdAt || 0);
        });
    }, [data]);

    return {
        selectedShippingPackages,
        isLoadingSelectedShippingPackages: isLoading,
        ...rest,
    };
};

type ShippingPackagesMutationProps = {
    onSuccess?: () => void;
};

export const useDeleteShippingPackage = ({
    onSuccess,
}: ShippingPackagesMutationProps = {}) => {
    const { mutate, isLoading, ...rest } = useMutation(
        ShippingApi.deleteShippingPackage,
        {
            onSuccess: ({ variables: packageId, queryHandler }) => {
                const previousPackages =
                    queryHandler.getQueryData<IShippingPackage[]>(
                        queryKey.selectedShippingPackages(),
                    ) || [];

                const updatedPackages = previousPackages.filter(
                    (el) => el.id !== packageId,
                );

                queryHandler.setQueryData(
                    queryKey.selectedShippingPackages(),
                    updatedPackages,
                );

                onSuccess?.();
            },
            isSuccessToast: false,
            formatErrorMessage: () =>
                'There was an error when trying to delete shipping package',
        },
    );

    return {
        deleteShippingPackage: mutate,
        isDeletingPackage: isLoading,
        ...rest,
    };
};

const resetDefaultPackage = (
    shippingPackages: IShippingPackage[],
    shippingPackage: IShippingPackage,
) => {
    return shippingPackages.map((el) => {
        if (shippingPackage.isDefault) {
            return {
                ...el,
                isDefault: false,
            };
        }
        return el;
    });
};

export const useSaveShippingPackage = ({
    onSuccess,
}: ShippingPackagesMutationProps = {}) => {
    const { mutate, isLoading, ...rest } = useMutation(
        ShippingApi.saveShippingPackage,
        {
            onSuccess: ({ data: shippingPackage, queryHandler }) => {
                const previousPackages =
                    queryHandler.getQueryData<IShippingPackage[]>(
                        queryKey.selectedShippingPackages(),
                    ) || [];

                const formattedPackages = resetDefaultPackage(
                    previousPackages,
                    shippingPackage,
                );

                const updatedPackages = [...formattedPackages, shippingPackage];
                queryHandler.setQueryData(
                    queryKey.selectedShippingPackages(),
                    updatedPackages,
                );

                onSuccess?.();
            },
            isSuccessToast: false,
            formatErrorMessage: () =>
                'There was an error when trying to add shipping package',
        },
    );

    return {
        saveShippingPackage: mutate,
        isSavingShippingPackage: isLoading,
        ...rest,
    };
};

export const useEditShippingPackage = ({
    onSuccess,
}: ShippingPackagesMutationProps = {}) => {
    const { mutate, isLoading, ...rest } = useMutation(
        ShippingApi.editShippingPackage,
        {
            onSuccess: ({ data: shippingPackage, queryHandler }) => {
                const previousPackages =
                    queryHandler.getQueryData<IShippingPackage[]>(
                        queryKey.selectedShippingPackages(),
                    ) || [];

                const formattedPackages = resetDefaultPackage(
                    previousPackages,
                    shippingPackage,
                );

                const updatedPackages = formattedPackages.map((el) => {
                    if (shippingPackage.id === el.id) {
                        return shippingPackage;
                    }
                    return el;
                });

                queryHandler.setQueryData(
                    queryKey.selectedShippingPackages(),
                    updatedPackages,
                );

                onSuccess?.();
            },
            isSuccessToast: false,
            formatErrorMessage: () =>
                'There was an error when trying to edit shipping package',
        },
    );

    return {
        editShippingPackage: mutate,
        isEditingShippingPackage: isLoading,
        ...rest,
    };
};
