import { useQuery, useMutation } from 'features/common/serverStateHandler';
import { ShippingApi } from 'features/shipping/api';
import { ShippoStatus } from 'features/shipping/constants';

export const queryKeys = {
    userShippoStatus: () => [{ scope: 'userShippoStatus' }] as const,
};

export const useUserShippoStatus = (isEnabled = true) => {
    const {
        data: userShippoStatus,
        isLoading,
        isError,
        refetch,
        ...rest
    } = useQuery(
        queryKeys.userShippoStatus(),
        ShippingApi.userShippoStatusGet,
        {
            enabled: isEnabled,
            useErrorBoundary: true,
        },
    );

    const isShippoActivated = userShippoStatus === ShippoStatus.activated;
    const isShippoDeactivated = userShippoStatus === ShippoStatus.deactivated;
    const isShippoNotConnected = userShippoStatus === ShippoStatus.notConnected;

    return {
        userShippoStatus,
        isShippoActivated,
        isShippoDeactivated,
        isShippoNotConnected,
        isLoadingUserShippoStatus: isLoading,
        isUserShippoStatusError: isError,
        refetchUserShippoStatus: refetch,
        ...rest,
    };
};

export const useToggleShippoStatus = () => {
    const { mutate, ...rest } = useMutation(ShippingApi.toggleShippoStatus, {
        onMutate: ({ queryHandler }) => {
            const previousStatus = queryHandler.getQueryData<string>(
                queryKeys.userShippoStatus(),
            );
            const nextStatus =
                previousStatus === ShippoStatus.activated
                    ? ShippoStatus.deactivated
                    : ShippoStatus.activated;

            queryHandler.setQueryData(queryKeys.userShippoStatus(), nextStatus);
            return previousStatus;
        },
        onSuccess: ({ queryHandler }) => {
            queryHandler.invalidateQueries(queryKeys.userShippoStatus());
        },
        onError: ({ context, queryHandler }) => {
            if (context) {
                queryHandler.setQueryData(
                    queryKeys.userShippoStatus(),
                    context,
                );
            }
        },
        formatSuccessMessage: (_data, _vars, previousStatus) => {
            const isActivated = previousStatus === ShippoStatus.deactivated;

            return `Shippo is currently ${
                isActivated ? 'activated' : 'deactivated'
            }`;
        },
    });

    return {
        toggleStatus: mutate,
        ...rest,
    };
};
