import { Button, colors, Icon, Text } from 'spoton-lib';

import { IPropTypes } from './OnboardingSection.types';
import styles from './OnboardingSection.module.scss';

export function OnboardingSection({
    isVisited,
    imageUrl,
    videoUrl,
    description,
    onClick,
    buttonLabel,
    importCallback,
}: IPropTypes) {
    const handleActionButtonClick = () => {
        onClick();
    };

    const button = (
        <Button
            variant="primary"
            onClick={handleActionButtonClick}
            color={colors.black}
            className={styles.Button}
            data-testid="onboardingSectionActionButton"
        >
            {buttonLabel}
        </Button>
    );

    return (
        <div
            className={styles.OnboardingSection}
            data-testid="OnboardingSection"
        >
            <div className={styles.Assets}>
                {isVisited ? (
                    <img
                        src={imageUrl}
                        className={styles.Assets_image}
                        alt="Image for Onboarding"
                        data-testid="onboardingSectionImage"
                    />
                ) : (
                    <video
                        data-testid="onboardingSectionVideo"
                        className={styles.Assets_video}
                        controls
                    >
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                )}
            </div>
            <Text type="p" className={styles.Description} color={colors.base90}>
                {description}
            </Text>
            <div className={styles.Buttons}>
                {importCallback && (
                    <Button
                        variant="secondary"
                        onClick={importCallback}
                        color={colors.black}
                        className={styles.Button}
                    >
                        <>
                            <Icon
                                name="FileImportIcon"
                                alt="Import Catalog button"
                                size={18}
                                className={styles.Button_icon}
                                color={colors.primary50}
                            />
                            Import Catalog
                        </>
                    </Button>
                )}
                {button}
            </div>
        </div>
    );
}

export default OnboardingSection;
