/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { LoadingOverlay, ScrollNav } from 'spoton-lib';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AddEditPage } from 'features/common/components/AddEditPage/AddEditPage.component';
import { EditControls } from 'features/common/components/EditControls';
import { GeneralSection } from 'features/products/components/GeneralSectionNew';
import { scrollToTop } from 'features/common/utils/scroll.utils';
import { convertRemToPixels } from 'features/common/utils/units.utils';
import { usePreventUnload } from 'features/common/utils/hooks.utils';
import { useChannels, useFlags } from 'features/common/hooks';
import { productsPaths } from 'features/products/routes/products.paths';
import { useUnsavedChangesToggle } from 'features/shipping/components/UnsavedPrompt';
import { useGetCurrentMerchantLocation } from 'features/common/services';
import { ILocationState } from 'features/common/types';
import {
    useFetchProductData,
    useCreateProduct,
    useUpdateProduct,
    useDeleteProduct,
} from 'features/products/services/products';
import {
    connector,
    IPropTypes,
} from 'features/products/components/ProductPage/ProductPage.types';
import { getInitialProductPageData } from 'features/products/components/ProductPage/ProductPage.utils';
import { ProductErrorsBanner } from 'features/products/components/ProductErrorsBanner';
import { IPropFields as GeneralSectionFields } from 'features/products/components/GeneralSectionNew/GeneralSection.types';
import { getInitialGeneralData } from 'features/products/components/GeneralSection/GeneralSection.utils';

import styles from './ProductPage.module.scss';
import { IProductFormInputs } from './ProductPage.types';
import { validationSchema } from './ProductPage.utils';

// Total height of navbar and AddEditPage top bar
const OFFSET_TOP = convertRemToPixels(11);

export function ProductPage({
    isLoading,
    isDuplicated,
    productId,
    duplicatedProductData,
    isEditing,
    onCancel,
}: IPropTypes) {
    const { shouldUseLegacyUPCValidation, isOpenPriceFeatureAvailable } =
        useFlags();
    const { locationActiveChannels, locationChannels } = useChannels();
    const { getCurrentMerchantLocationLoaded } =
        useGetCurrentMerchantLocation();
    const isLoyaltyEnabled = getCurrentMerchantLocationLoaded().isLoyalty;

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            // Make sure duplicated product data is cleared
            if (isDuplicated) {
                onCancel();
            }
        };
    }, [isDuplicated, productId]);

    const navigateBack = (shouldPreserveUrl: boolean) => {
        if (
            shouldPreserveUrl &&
            (location.state as ILocationState)?.from?.includes(
                productsPaths.root,
            )
        ) {
            navigate(-1);
        } else {
            navigate(productsPaths.root);
        }
    };

    const { rawProduct, productData: fetchedProductData } =
        useFetchProductData(productId);

    const productData = productId
        ? fetchedProductData
        : duplicatedProductData ||
          getInitialProductPageData(locationActiveChannels, isLoyaltyEnabled);

    // Initial data for each section. Should be computed once, at first render.
    const initialGeneralData = useMemo<GeneralSectionFields>(
        () => getInitialGeneralData(isLoyaltyEnabled, productData),
        [productData],
    );

    const [isDirty, setIsDirty] = useState(isDuplicated);
    const [shouldPromptWhenDirty, setShouldPromptWhenDirty] = useState(true);

    const onProductSaveSuccess = () => {
        setIsDirty(false);
        navigateBack(false);
    };

    const onProductSaveError = () => {
        setShouldPromptWhenDirty(true);
        scrollToTop();
    };

    const { createProduct, isCreatingProduct } = useCreateProduct(
        onProductSaveSuccess,
        onProductSaveError,
    );
    const { updateProduct, isUpdatingProduct } = useUpdateProduct(
        onProductSaveSuccess,
        onProductSaveError,
    );

    const isSaving = productId ? isUpdatingProduct : isCreatingProduct;

    const submitProductData = async () => {
        // @TODO add submit logic within the last task
    };

    const shouldDisplayUnsavedChangesModal = isDirty && shouldPromptWhenDirty;
    const [isDuplicationOpen, setIsDuplicationOpen] = useState(false);

    //When duplicating a product we want to have custom behaviour of unsaved changes modal, therefore we don't display the default one
    useUnsavedChangesToggle(
        shouldDisplayUnsavedChangesModal && !isDuplicationOpen,
    );

    // Main product handlers (operations on product).
    const handleOnSave = async () => {
        setShouldPromptWhenDirty(false);

        if (isEditing && !isDirty) {
            navigateBack(false);
            return;
        }

        submitProductData();
    };

    const handleOnCancel = useCallback(() => {
        setShouldPromptWhenDirty(true);
        navigateBack(true);
    }, [navigate]);

    const onProductDeleteSuccess = () => {
        navigateBack(true);
    };

    const { deleteProduct } = useDeleteProduct(onProductDeleteSuccess);

    const handleOnDelete = useCallback(async () => {
        if (!productId) {
            return;
        }
        setShouldPromptWhenDirty(false);

        deleteProduct(productId);
    }, [navigate, productId]);

    const generalSection = useMemo(() => {
        return (
            <GeneralSection
                isLoading={isLoading}
                isEditing={isEditing}
                isLoyaltyEnabled={isLoyaltyEnabled}
                isDuplicated={isDuplicated}
            />
        );
    }, [isLoading, isEditing]);

    const editControls = productId ? (
        <EditControls
            onDelete={handleOnDelete}
            isInactive={isLoading}
            isDuplicationOpen={isDuplicationOpen}
            setIsDuplicationOpen={setIsDuplicationOpen}
            shouldDisplayUnsavedChangesModal={shouldDisplayUnsavedChangesModal}
            renderDuplicationModal={(isOpen, onClose) => (
                <>{/* @TODO add <ProductDuplication /> within last task */}</>
            )}
        />
    ) : null;

    // @TODO extend with every other section data
    const formInitialValues: IProductFormInputs = initialGeneralData;

    const formMethods = useForm<IProductFormInputs>({
        defaultValues: formInitialValues,
        resolver: yupResolver(validationSchema),
        mode: 'onTouched',
    });
    const {
        handleSubmit,
        formState: { isDirty: isFormDirty },
        watch,
    } = formMethods;

    usePreventUnload(isFormDirty);

    return (
        <FormProvider {...formMethods}>
            <form>
                <AddEditPage
                    title={
                        productId ? 'Edit Product (New)' : 'New Product (New)'
                    } // @TODO: remove (New) annotation
                    onCancelHandler={handleOnCancel}
                    onSaveHandler={handleSubmit(handleOnSave)}
                    saveBtnTestId="productSaveChanges"
                    cancelBtnTestId="productCancelChanges"
                    saveBtnText="Done"
                    isSaveButtonDisabled={!isFormDirty}
                >
                    {editControls}
                    <ScrollNav
                        offsetTop={OFFSET_TOP}
                        className={styles.ScrollNav}
                    >
                        <>
                            <ProductErrorsBanner />
                            <ScrollNav.Section
                                className={styles.ScrollNav_section}
                                id="general"
                                title="General"
                            >
                                {generalSection}
                            </ScrollNav.Section>
                        </>
                    </ScrollNav>
                    {isSaving && <LoadingOverlay />}
                </AddEditPage>
                {/* 
                    Calling watch() is suboptimal as it's causing the whole component to rerender on each form's state change.
                    This is a temporary util for debugging purposes only.
                    @TODO Remove this with last refactoring task.
                */}
                {/* <div>
                    {'Real time Form values: '}
                    <pre>{JSON.stringify(watch(), null, 2)}</pre>
                </div> */}
            </form>
        </FormProvider>
    );
}

export default connector(ProductPage);
