import { Form, Formik, Field } from 'formik';
import { v4 as uuid } from 'uuid';
// eslint-disable-next-line no-restricted-imports
import { Container, Row, Col, Text, colors, Checkbox } from 'spoton-lib';

import { ShippingRateOptionFooter } from 'features/shipping/components/ShippingRateOptionFooter';
import { TextField } from 'features/common/components/TextField';
import { NumberFormatField } from 'features/common/components/NumberFormatField';
import { RateByRangeTable } from 'features/shipping/components/RateByRangeTable';
import { useUnsavedChangesToggle } from 'features/shipping/components/UnsavedPrompt';

import { validationSchema } from './RateByWeightForm.utils';
import { IProps, IRateByWeightData, IWeight } from './RateByWeightForm.types';
import styles from './RateByWeightForm.module.scss';

const initialWeights: IWeight[] = [
    {
        from: 0,
        to: 5,
        price: 5,
        fieldId: uuid(),
    },
    {
        from: 5.01,
        to: Infinity,
        price: 10,
        fieldId: uuid(),
    },
];

export function RateByWeightForm({
    onCancel,
    onSubmit,
    initialValues,
}: IProps) {
    return (
        <Formik<IRateByWeightData>
            initialValues={{
                rateName: initialValues?.rateName || '',
                deliveryTime: initialValues?.deliveryTime || '',
                weights: initialValues?.weights || initialWeights,
                withMinSpend: initialValues?.withMinSpend || false,
                minSpendPrice: initialValues?.minSpendPrice ?? null,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ dirty, isSubmitting, values, setFieldValue }) => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { weights, withMinSpend } = values;
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useUnsavedChangesToggle(dirty);

                return (
                    // disable showing input errors
                    <Form noValidate>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <TextField
                                        label="Rate Name"
                                        name="rateName"
                                        placeholder="E.g. Free Shipping"
                                        data-testid="RateNameInput"
                                    />
                                </Col>
                                <Col>
                                    <TextField
                                        label="Estimated delivery time"
                                        name="deliveryTime"
                                        placeholder="E.g. 3 - 5 business days"
                                        data-testid="DeliveryTimeInput"
                                        primaryCondition="Optional"
                                    />
                                </Col>
                            </Row>
                            <Row className={styles.NoteRow}>
                                <Col>
                                    <Text color={colors.base90} as="p">
                                        Enter a weight range to show an specific
                                        shipping cost
                                    </Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <RateByRangeTable
                                        weights={weights}
                                        onChange={(values) => {
                                            setFieldValue('weights', values);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className={styles.CheckboxContainer}>
                                <Col>
                                    <Field
                                        as={Checkbox}
                                        checked={withMinSpend}
                                        name="withMinSpend"
                                        label="Offer free shipping with a minimum spend at checkout"
                                    />
                                </Col>
                            </Row>
                            {withMinSpend && (
                                <Row className={styles.RowMinPrice}>
                                    <Col xs={6}>
                                        <NumberFormatField
                                            name="minSpendPrice"
                                            placeholder="$0.00"
                                            label="Set minimum spend"
                                            prefix="$"
                                            decimalScale={2}
                                            shouldShowInstantError={false}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Container>
                        <div className={styles.FooterWrapper}>
                            <ShippingRateOptionFooter
                                onClose={onCancel}
                                onSave={() => onSubmit}
                                isSaveButtonDisabled={isSubmitting}
                                isCancelButtonDisabled={isSubmitting}
                                isLoading={isSubmitting}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default RateByWeightForm;
