import * as yup from 'yup';

import {
    DEFAULT_DIMENSION_UNIT_OPT,
    DEFAULT_WEIGHT_UNIT_OPT,
} from 'features/common/constants';
import { IBox, IShippingPackage } from 'features/shipping/types';

import { ICustomPackageForm, IInputFormFields } from './AddPackagesModal.types';

const formatValue = (value: string | undefined) =>
    `${value ? +value ?? '' : ''}`;

export const getFormValues = (
    packageToBeEdited: IShippingPackage | null,
): ICustomPackageForm => ({
    slug: '',
    length: formatValue(packageToBeEdited?.length?.value),
    width: formatValue(packageToBeEdited?.width?.value),
    height: formatValue(packageToBeEdited?.height?.value),
    weight: formatValue(packageToBeEdited?.weight?.value),
    weightUnit:
        packageToBeEdited?.weight?.unit || DEFAULT_WEIGHT_UNIT_OPT.value,
    distanceUnit:
        packageToBeEdited?.length?.unit || DEFAULT_DIMENSION_UNIT_OPT.value,
    name: packageToBeEdited?.name || '',
    isCarrierPackage: false,
    isDefault: packageToBeEdited?.isDefault || false,
    id: packageToBeEdited?.id || null,
});

export const convertBoxToPackageType = (
    box: IBox,
    isCarrierPackage = false,
    isDefault = false,
): IShippingPackage => {
    return {
        id: null,
        length: {
            value: box.length,
            unit: box.distanceUnit,
        },
        width: {
            value: box.width,
            unit: box.distanceUnit,
        },
        height: {
            value: box.height,
            unit: box.distanceUnit,
        },
        weight:
            box.weight && Number(box.weight) > 0
                ? {
                      value: box.weight,
                      unit: box.weightUnit,
                  }
                : null,
        name: box.name,
        isCarrierPackage,
        isDefault,
    };
};

const requiredStringSchema = yup
    .string()
    .required('Value can not be empty!')
    .nullable();

const requiredValueSchema = yup
    .string()
    .required('Value can not be empty!')
    .nullable()
    .test(
        'value',
        'Should be greater then 0.',
        (value) => !value || Number(value) > 0,
    )
    .test(
        'Only two decimal places',
        'Should be up to 2 decimal place!',
        (value) => !value || /^\d+(.\d{1,2})?$/gm.test(value || ''),
    );

const notRequiredValueSchema = yup
    .string()
    .nullable()
    .test(
        'value',
        'Should be greater then 0.',
        (value) => !value || Number(value) > 0,
    )
    .test(
        'Only two decimal places',
        'Should be up to 2 decimal place!',
        (value) => !value || /^\d+(.\d{1,2})?$/gm.test(value || ''),
    );

export const customPackageValidationSchema: yup.SchemaOf<IInputFormFields> = yup
    .object()
    .shape({
        length: requiredValueSchema,
        width: requiredValueSchema,
        height: requiredValueSchema,
        weight: notRequiredValueSchema,
        name: requiredStringSchema,
    });

export const carrierPackageValidationSchema = yup.object().shape({
    name: requiredStringSchema,
});
