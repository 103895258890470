import { z } from 'zod';

import { IUnit, ShippingCarrier } from 'features/common';
import { DimensionUnit } from 'features/common/constants/shippingRates';

const shippingCarrierServiceSchema = z.object({
    id: z.string(),
    name: z.string(),
    minPrice: z.string().optional(),
    estimatedDeliveryTime: z.string(),
    speedClass: z.string(),
    slug: z.string().optional(),
    weightLimit: z.object({
        unit: z.string(),
        value: z.string(),
    }),
    carrier: z.object({
        isActive: z.boolean(),
        name: z.nativeEnum(ShippingCarrier),
        slug: z.string(),
    }),
});

export const shippingCarrierSchema = z.object({
    name: z.nativeEnum(ShippingCarrier),
    isEnabled: z.boolean(),
    isActive: z.union([z.boolean(), z.null()]),
    description: z.string().optional(),
    services: z.array(shippingCarrierServiceSchema),
});

export type IShippingCarrier = z.infer<typeof shippingCarrierSchema>;
export type IShippingCarrierService = z.infer<
    typeof shippingCarrierServiceSchema
>;

type Dimension = IUnit<DimensionUnit>;

export interface IShippingPackage {
    id: string | null;
    length: Dimension | null;
    width: Dimension | null;
    height: Dimension | null;
    weight: IUnit | null;
    name: string;
    isCarrierPackage: boolean;
    isDefault: boolean;
    createdAt?: string;
}

export interface IBox {
    slug: string;
    name: string;
    length: string;
    width: string;
    height: string;
    distanceUnit: DimensionUnit;
    weight: string | undefined | null;
    weightUnit: string;
}

export type IFreeShippingRealTimeData = {
    service: string | null;
    minPrice: string | null;
};

export type IMethodService = {
    service: string | null;
    minPrice: string | null;
};

export interface IShippingCarrierMethod {
    freeShippingFor: string | null;
    id: string;
    merchantLocation: string;
    minPrice: string | null;
    services: IShippingCarrierService[];
}

export interface ISelectedServices {
    [key: string]: IShippingCarrierService[];
}
