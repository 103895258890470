import { Text } from 'spoton-lib';

import { NexusModal } from 'features/common';
import { ReactComponent as ProductExportImg } from 'features/common/assets/product_export.svg';

import { IPropTypes } from './ExportModalStarted.types';
import styles from './ExportModalStarted.module.scss';

export function ExportModalStarted({ isOpen, onClose, userEmail }: IPropTypes) {
    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Product Export Started!" />
                <Text className={styles.Modal_paragraph}>
                    We’re exporting your amazing products now. We’ll send you an
                    email at <strong>{userEmail}</strong> once the file is
                    completed.
                </Text>
                <div className={styles.Modal_imageWrapper}>
                    <ProductExportImg />
                </div>
                <NexusModal.Footer submitTitle="Got it!" onSubmit={onClose} />
            </div>
        </NexusModal>
    );
}

export default ExportModalStarted;
