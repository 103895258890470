import React, {
    ChangeEvent,
    useState,
    useRef,
    RefObject,
    useEffect,
} from 'react';
import { Icon, IconButton, Text } from 'spoton-lib';

import styles from './SearchBar.module.scss';
import { ISearchBarProps } from './SearchBar.types';

/**
 * Search Bar supporting contextual search.
 * When typing, user can select one of search contexts to apply.
 *
 * Component supports callbacks for input change and selection change.
 */
export function SearchBar(props: ISearchBarProps) {
    const {
        initialState,
        onInputChange,
        contextOptions,
        defaultContextOption,
        selectedOption,
        placeholder,
        onContextOptionSelected,
        shouldHideContextSelection = false,
    } = props;
    const myself = useRef() as RefObject<HTMLFormElement>;
    const [inputState, setInput] = useState(initialState || '');
    const [isInputFocused, setInputFocused] = useState(false);

    const showCleanInputButton = inputState || selectedOption;
    const showContextSelection =
        isInputFocused && inputState && !selectedOption;

    useEffect(() => {
        setInput(initialState || '');
    }, [initialState]);

    /**
     * Tricky onBlur implementation as onBlur state change cause re-render
     * and losing click event on dropdown element.
     * Issue: https://github.com/facebook/react/issues/4210
     */
    useEffect(() => {
        function handleClick(ev: MouseEvent) {
            if (
                !(
                    myself &&
                    myself.current &&
                    myself.current.contains(ev.target as Node)
                )
            ) {
                setInputFocused(false);
            }
        }
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [myself]);

    function onSubmit(event: React.FormEvent) {
        event.preventDefault();

        if (selectedOption === undefined) {
            onContextOptionSelected(defaultContextOption);
        }
    }

    /** Called on internal input change event. */
    function onChange(input: string) {
        setInput(input);
        onInputChange(input);
    }

    /** Called when `X` icon clicked. */
    function onCleanInput() {
        onChange('');
        onContextOptionSelected(null);
    }

    /** Called for option select, "Anything" clears current option. */
    function onOptionSelected(opt: string) {
        onContextOptionSelected(opt);
    }

    return (
        <form onSubmit={onSubmit} className={styles.SearchBar} ref={myself}>
            <div className={styles.SearchBar_container}>
                <div className={styles.Input}>
                    <div
                        className={`${styles.Input_prefix} ${
                            selectedOption ? styles.Input_prefix___selected : ''
                        }`}
                        onClick={() => onContextOptionSelected(null)}
                    >
                        {selectedOption ? (
                            <Text
                                className={styles.SelectedOption}
                                type="label"
                            >
                                {selectedOption}
                            </Text>
                        ) : (
                            <Icon
                                name="SearchIcon"
                                alt="search"
                                size={16}
                                className={styles.SearchIcon}
                            />
                        )}
                    </div>
                    <input
                        type="text"
                        data-testid="SearchBarInput"
                        className={styles.Input_input}
                        value={inputState}
                        onChange={(ev: ChangeEvent<HTMLInputElement>): void =>
                            onChange(ev.target.value)
                        }
                        onFocus={() => setInputFocused(true)}
                        placeholder={placeholder}
                    />
                    {showCleanInputButton && (
                        <IconButton
                            name="ClearSolidIcon"
                            alt="Clear search input"
                            className={styles.Input_suffix}
                            onClick={() => onCleanInput()}
                            size={24}
                            disableBorder
                            buttonProps={{
                                'data-testid': 'SearchBarClearButton',
                                type: 'button',
                            }}
                        />
                    )}
                </div>
                {!shouldHideContextSelection && showContextSelection && (
                    <ul className={styles.DropdownOptions}>
                        <li className={styles.DropdownOptions_tip}>
                            <Text type="p" className={styles.ColorGray}>
                                Search for &quot;{inputState}&quot; in ...
                            </Text>
                        </li>
                        {contextOptions.map((opt) => (
                            <li
                                className={styles.DropdownOptions_selectable}
                                key={opt}
                            >
                                <button
                                    onClick={() => onOptionSelected(opt)}
                                    className={styles.UndecoratedButton}
                                    data-testid="SearchBarOption"
                                    type="button"
                                >
                                    <Text type="p">{opt}</Text>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </form>
    );
}

export default SearchBar;
