import { useEffect, useState } from 'react';

import {
    IOnBoardedPagesProps,
    IOnBoardedPages,
} from './useOnBoardedPages.types';

export const useOnBoardedPages = ({
    page,
    id,
    handleRedirect,
    isEnabled = true,
}: IOnBoardedPagesProps): IOnBoardedPages => {
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
    // it should only be invoked once
    const [shouldShowBanner, setShouldShowBanner] = useState<boolean>(false);

    function getOnBoardedPages(): string[] {
        const onBoardedPages = localStorage.getItem('onBoardedPages') || '[]';
        return JSON.parse(onBoardedPages);
    }

    function getIsPageOnBoarded(page: string): boolean {
        const onBoardedPages = getOnBoardedPages();
        return onBoardedPages.includes(page);
    }

    function setIsPageOnBoarded(page: string): void {
        const onBoardingDone = getOnBoardedPages();
        const set = new Set([...onBoardingDone, page]);
        const pages = Array.from(set);
        localStorage.setItem('onBoardedPages', JSON.stringify(pages));
    }

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const showBanner = () => {
        if (shouldShowBanner) {
            setIsBannerVisible(true);
            setShouldShowBanner(false);
        }
    };

    const closeBanner = () => {
        setIsBannerVisible(false);
    };

    useEffect(() => {
        if (isEnabled && id && page) {
            const name = `${page}_${id}`;
            const isOnBoarded = getIsPageOnBoarded(name);

            if (document.referrer.includes(page) && !isOnBoarded) {
                handleRedirect && handleRedirect();
                setIsPopupOpen(true);
                setShouldShowBanner(true);
                setIsPageOnBoarded(name);
            }
        }
    }, [isEnabled, id, page, handleRedirect]);

    return {
        isPopupOpen,
        isBannerVisible,
        showBanner,
        closePopup,
        closeBanner,
    };
};
