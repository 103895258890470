import {
    IChannelLink,
    IChannelName,
    IItemImage,
    IUnit,
    ValidatorsResults,
    IItemImagePayload,
} from 'features/common';
import { IPropFields as AvailabilitySectionFields } from 'features/products/components/AvailabilitySection/AvailabilitySection.types';
import { IPropFields as GeneralSectionFields } from 'features/products/components/GeneralSection/GeneralSection.types';
import { IPropFields as ShippingSectionFields } from 'features/products/components/ShippingSection/ShippingSection.types';

import {
    IProductVariant,
    IProductVariantPayload,
    IVariantAttributeValue,
    IVariantChannel,
} from './ProductVariants.type';
import { IProductAttribute } from './ProductAttributes.type';

export type IProductImage = IItemImage;

export interface IProductChannel {
    channelName: IChannelName;
    salePrice: string | null;
    channel: string;
    active: boolean;
    openPriceAllowed: boolean;
}

export enum IProductStructure {
    standalone = 'standalone',
    parent = 'parent',
}

export interface IProduct {
    id: string;
    images: IProductImage[];
    primaryImage: IProductImage | null;
    tags: string[];
    created: string;
    modified: string;
    title: string;
    slug: string;
    description: string;
    deleted: string | null;
    compareAtPrice: string | null;
    costPrice: string | null;
    priceUnit: string;
    category: IProductCategory | null;
    vendor: IProductVendor | null;
    productAttributes: IProductAttribute[];
    variants: IProductVariant[];
    canEarnReward: boolean;
    canRedeemReward: boolean;
    lowStockAlertEnabled?: boolean;
    lowStockAlertQuantity?: IUnit | null;
    weight: IUnit | null;
    height: IUnit | null;
    length: IUnit | null;
    width: IUnit | null;
}

export interface IProductAttributesOptionsPayload {
    id: string;
    displayOrder: number;
}

export interface IProductAttributesPayload {
    id: string;
    displayOrder: number;
    options: IProductAttributesOptionsPayload[];
}

export interface IProductPost
    extends Omit<
        IProduct,
        | 'id'
        | 'images'
        | 'primaryImage'
        | 'created'
        | 'modified'
        | 'dateCreated'
        | 'dateUpdated'
        | 'deleted'
        | 'slug'
        | 'productAttributes'
        | 'variants'
        | 'category'
        | 'vendor'
        // @TODO: These values are only omitted as the shipping section is hidden for now
        | 'width'
        | 'height'
        | 'length'
    > {
    productAttributes: IProductAttributesPayload[];
    variants: IProductVariantPayload[];
    images: IItemImagePayload[];
    category: string | null;
    vendor: string | null;
}

export interface IProductByIdChildrenAttributeValues {
    id: string;
    name: string;
    value: string;
    valueLabel: string;
    code: string;
    attribute: string;
    type: string;
}

export interface IProductByIdChildren {
    id: string;
    title: string;
    upc?: string;
    sku?: string;
    description: string;
    parent: string;
    channels: IProductChannel[];
    primaryImage: IProductImage | null;
    images: IProductImage[];
    attributeValues: IProductByIdChildrenAttributeValues[];
    variantCode: string;
    costPrice?: string | null;
    stock: IProductStockEntry;
}

export interface IProductByIdVariant {
    id: string;
    title: string;
    upc: string;
    sku: string;
    slug: string;
    primaryImage?: IProductImage;
    images: IProductImage[];
    attributeValues: IVariantAttributeValue[];
    stock: IProductStockEntry;
    channels: IVariantChannel[];
    costPrice?: string | null;
}

export interface IProductById {
    id: string;
    title: string;
    description: string;
    priceUnit: string;
    slug: string;
    created: string;
    modified: string;
    deleted: string | null;
    compareAtPrice: string | null;
    costPrice: string | null;
    category: string | null;
    vendor: string | null;
    attributes: string[];
    images: IProductImage[];
    primaryImage: IProductImage | null;
    tags: string[];
    variants: IProductByIdVariant[];
    canEarnReward: boolean;
    canRedeemReward: boolean;
    upc?: string;
    sku?: string | null;
    structure: IProductStructure;
    weight: IUnit | null;
    channels: IProductChannel[];
    stock?: {
        quantityInStock: IUnit;
    };
    children: IProductByIdChildren[];
}

export type IProductOrVariantById = Pick<
    IProductById,
    'id' | 'title' | 'upc' | 'channels' | 'images'
> & {
    price?: string;
};

export type IProductPatch = Partial<Omit<IProductPost, 'priceUnit'>>;

export interface IProductStockEntry {
    id: string;
    created: string;
    modified: string;
    stockrecord: string;
    quantityAllocated: IUnit;
    quantityInStock: IUnit;
}

export interface IProductStock {
    quantityInStock: IUnit | null;
}

export interface IProductLink {
    id: string;
    upc?: string;
    sku?: string;
    title: string;
    abbreviation: string | null;
    channels: IChannelLink[];
    structure: IProductStructure;
    category: string | null;
    vendor: string | null;
    stock: IProductStock;
    lowStock: boolean;
    primaryImage: IProductImage | null;
    minPrice: string;
    maxPrice: string;
    tags: string[];
    areAllChannelsSelected: boolean;
    created: string;
    modified: string;
    variantsCount: number;
}

export enum IBulkAction {
    delete = 'delete',
    addTags = 'add_tags',
    removeTags = 'remove_tags',
    addVariantChannelAvailability = 'add_variant_channel_availability',
    removeVariantChannelAvailability = 'remove_variant_channel_availability',
    makePrivate = 'make_private',
    makePublic = 'make_public',
    changeUpc = 'change_upc',
}

export interface IBulkActionUpcPayload {
    [key: string]: { upc: string };
}

export interface IBulkActionParameters {
    filters: {
        id: string[];
        all?: boolean;
    };
    value?: string[] | number[] | IBulkActionUpcPayload[];
}

export interface IProductData {
    general: GeneralSectionFields;
    availability: AvailabilitySectionFields;
    shipping: ShippingSectionFields;
}

export interface IPartialProductData {
    general?: Partial<GeneralSectionFields>;
    availability?: Partial<AvailabilitySectionFields>;
    shipping?: Partial<ShippingSectionFields>;
}

// IAttribute stripped to values needed in the UI and eventual POST
export interface IProductOption {
    /** Attribute id */
    attribute: string | null;
    /** Key used to uniquely identify new attribute */
    key?: string | null;
    /** Display name. */
    name: string;
    /** Array of values for the current attribute in current product. */
    values: IProductOptionValue[];

    displayOrder?: number;

    isEditing?: boolean;
}

export interface IProductOptionValue {
    key?: string;
    value: string;
    displayOrder?: number;
    id?: string;
}

export type IProductOptionErrors = ValidatorsResults<IProductOption>;
export type IProductOptionsErrors = Record<string, IProductOptionErrors>;

export interface IProductCategory {
    id: string;
    name: string;
    slug: string;
}

export interface IProductVendor {
    id: string;
    name: string;
    slug: string;
}
