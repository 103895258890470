import { getConfigVar } from './config.utils';

/**
 * Returns normalized path with respect of BASE_URL from config file
 */
export const generatePathFromRoot = (...paths: string[]): string => {
    const root = String(getConfigVar('REACT_APP_BASE_URL')) || '/';
    const fragments = [root, ...paths];
    return fragments
        .map((fragment, index) => {
            let newFragment = String(fragment);

            // remove leading slash for not-first fragment
            if (index > 0) {
                newFragment = newFragment.replace(/^[/]+/, '');
            }
            // remove trailing slash for not-last fragment
            if (index < fragments.length - 1) {
                newFragment = newFragment.replace(/[/]+$/, '');
            }
            return newFragment;
        })
        .join('/');
};

export const appendSearchQuery = <QueryParams extends Record<string, string>>(
    url: string,
    queryParams: QueryParams,
): string => {
    const searchParams = new URLSearchParams(queryParams);
    const searchString = searchParams.toString();

    if (!searchString) {
        return url;
    }

    return `${url}?${searchParams.toString()}`;
};

export const nestedPath = (path: string) => path + '/*';
