import { AxiosError } from 'axios';

export class RumError extends Error {
    readonly source = 'custom';
    type: string;
    context?: object;

    constructor(message: string, type?: string, context?: object) {
        super(message);
        this.type = type ?? 'APIError';
        this.context = context;
    }
}

export class RumAxiosError extends RumError {
    constructor(axiosError: AxiosError) {
        super(axiosError.message, axiosError.code, {
            method: axiosError.config?.method,
            baseURL: axiosError.config?.baseURL,
            url: axiosError.config?.url,
            status: axiosError.response?.status,
            data: axiosError.response?.data,
        });
    }
}
