import { generatePathFromRoot } from 'features/common/utils/routing.utils';
import { settingsPaths } from 'features/settings/routes/settings.paths';

export const shippingMountPath = settingsPaths.shipping;

export const shippingLocalPaths = {
    root: '/',
    localDelivery: '/local-delivery',
    localPickup: '/local-pickup',
    shippingMethods: '/shipping-methods',
} as const;

// paths used for navigation
export const shippingPaths = {
    root: generatePathFromRoot(shippingMountPath),
    localPickup: generatePathFromRoot(
        shippingMountPath,
        shippingLocalPaths.localPickup,
    ),
    localDelivery: generatePathFromRoot(
        shippingMountPath,
        shippingLocalPaths.localDelivery,
    ),
    shippingMethods: generatePathFromRoot(
        shippingMountPath,
        shippingLocalPaths.shippingMethods,
    ),
} as const;
