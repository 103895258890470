import { useField } from 'formik';
import { Text, IconButton, colors } from 'spoton-lib';
import clsx from 'clsx';

import { DraggableWrapper } from 'features/common/components/DraggableWrapper';
import { DnDIcon } from 'features/common/components/DnDIcon';
import { useDeletePrompt } from 'features/products/components/ProductOptions/hooks';
import { ProductAttributeOptions } from 'features/products/components/ProductOptions/ProductOptionsList/ProductAttributeOptions';
import { ProductAttributesDropdown } from 'features/products/components/ProductAttributesDropdown';
import { IProductOption } from 'features/products/types';

import styles from './ProductAttributeEdit.module.scss';
import { IPropsType } from './ProductAttributeEdit.types';

export function ProductAttributeEdit({
    name,
    index,
    onRemoveClick,
    isDragDisabled,
    attributeKey,
    isPageEditing,
}: IPropsType) {
    const { deletePromptElement, setDeletePromptOpen } =
        useDeletePrompt(onRemoveClick);
    const [
        { value: optionAttribute },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { error, touched },
    ] = useField<IProductOption['attribute']>(`${name}.attribute`);
    const [{ value: productOption }, , { setValue: setProductOptionValue }] =
        useField<IProductOption>(name);

    const [{ value: allProductOptions }] =
        useField<IProductOption[]>(`productOptions`);

    const errorText = touched ? error : '';

    return (
        <>
            <DraggableWrapper
                draggableId={`edit-attribute-${attributeKey}`}
                index={index}
                key={attributeKey}
                isDragDisabled={isDragDisabled}
                className={clsx(
                    styles.Wrapper,
                    isPageEditing && styles.Wrapper___edit,
                )}
            >
                {({ dragHandleProps }) => (
                    <>
                        <Text type="sub2">Attribute {index + 1}</Text>
                        <div className={styles.AttributeInputWrapper}>
                            <div
                                className={styles.AttributeInputWrapper}
                                {...dragHandleProps}
                                tabIndex={-1}
                            >
                                <DnDIcon isDisabled={isDragDisabled} />
                            </div>
                            <div className={styles.AttributeDropdown}>
                                <ProductAttributesDropdown
                                    value={optionAttribute}
                                    onChange={(item: IProductOption) =>
                                        setProductOptionValue(item)
                                    }
                                    menuPortalTarget={document.body}
                                    isValid={!errorText}
                                    secondaryCondition={errorText}
                                    allSelected={allProductOptions}
                                    isDisabled={isPageEditing}
                                />
                            </div>
                            {!isPageEditing && (
                                <IconButton
                                    name="DeleteIcon"
                                    alt={`Delete ${productOption.name} attribute icon`}
                                    onClick={() => setDeletePromptOpen(true)}
                                    disableBorder
                                    size={27}
                                    buttonProps={{ tabIndex: -1 }}
                                    color={colors.black}
                                />
                            )}
                        </div>
                        <div className={styles.ValuesWrapper}>
                            {optionAttribute && (
                                <>
                                    <Text
                                        className={styles.ValuesWrapper_title}
                                        type="p"
                                    >
                                        Value (e.g. Small, Medium, Large)
                                    </Text>
                                    <ProductAttributeOptions
                                        name={name}
                                        isError={!!error}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
            </DraggableWrapper>
            {deletePromptElement}
        </>
    );
}
