import { Checkbox, Text } from 'spoton-lib';
import InfiniteScroller from 'react-infinite-scroller';

import { NetworkError, PlaceholderList } from 'features/common/components';
import { filtersContentTypeString } from 'features/common/components/FiltersSidebar/FiltersSidebar.utils';
import { naturalSortByProperty } from 'features/common/utils';
import { useInfiniteCategories } from 'features/categories/services';

import styles from './FiltersSidebarCategory.module.scss';
import { IPropTypes } from './FiltersSidebarCategory.types';

/** Component for filtering products by category */
export function FiltersSidebarCategory(props: IPropTypes) {
    const { selectedCategories, contentType, onChange } = props;

    const {
        categories,
        fetchNextPage,
        refetch,
        isFetching,
        isError,
        hasNextPage,
    } = useInfiniteCategories({ pageSize: 20 });

    const categoriesForSelect = categories
        .filter((category) => Boolean(category))
        .map((category) => ({
            id: String(category.id),
            label: category.name,
            value: `${category.id}`,
        }));

    const selectedIds = selectedCategories.map(({ id }) => id);

    const handleChange = (categoryId: string) => {
        const isSelected = selectedIds.includes(categoryId);
        const newSelectedCategories = categoriesForSelect.filter(({ id }) =>
            id === categoryId ? !isSelected : selectedIds.includes(id),
        );

        onChange(newSelectedCategories);
    };

    const handleLoadMore = () => {
        if (!isFetching) {
            fetchNextPage();
        }
    };

    if (isError) {
        return (
            <div className={styles.FiltersSidebarCategory}>
                <NetworkError
                    onRefreshClick={refetch}
                    data-testid="FiltersSidebarCategoryErrorButton"
                />
            </div>
        );
    }

    return (
        <div className={styles.FiltersSidebarCategory}>
            <Text className={styles.FiltersSidebarCategory_header}>
                Select a category in which you want to search your{' '}
                {filtersContentTypeString[contentType]}.
            </Text>
            <form
                onSubmit={(e) => e.preventDefault()}
                className={styles.FiltersSidebarCategory_form}
                data-testid="FiltersSidebarCategory"
            >
                <InfiniteScroller
                    className={styles.FiltersSidebarCategory_infiniteScroller}
                    pageStart={1}
                    loadMore={handleLoadMore}
                    hasMore={hasNextPage}
                    loader={
                        <div
                            className={styles.Loader}
                            key="FiltersSidebarCategoryLoader"
                        >
                            <PlaceholderList />
                        </div>
                    }
                    useWindow={false}
                >
                    {naturalSortByProperty(categoriesForSelect, 'label').map(
                        ({ id, value, label }) => (
                            <Checkbox
                                key={id}
                                checked={selectedIds.includes(id)}
                                onChange={() => handleChange(String(id))}
                                label={label}
                                className={styles.CheckboxItem}
                                data-testid={`FiltersSidebarCategoryOption-${value}`}
                            />
                        ),
                    )}
                </InfiniteScroller>
            </form>
        </div>
    );
}

export default FiltersSidebarCategory;
