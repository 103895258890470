import { NumberFormat, NumberFormatValues } from 'spoton-lib';
import { useField, useFormikContext } from 'formik';

import { IPropTypes } from './NumberFormatField.types';

const defaultValueFormatter = ({ floatValue }: NumberFormatValues) => {
    return Number.isFinite(floatValue) ? floatValue : null;
};

export function NumberFormatField({
    name,
    shouldShowInstantError = true,
    clearable = false,
    allowLeadingZeros = false,
    valueFormatter = defaultValueFormatter,
    ...props
}: IPropTypes) {
    const { setFieldValue, submitCount } = useFormikContext();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [field, { error, touched }] = useField(name);

    const isValid = touched ? !error : true;
    const errorText = touched ? error : '';
    const shouldShowErrorRegardlessOfTouched =
        shouldShowInstantError || submitCount > 0;

    return (
        <NumberFormat
            {...props}
            name={name}
            value={field.value ?? ''}
            isValid={shouldShowErrorRegardlessOfTouched ? !error : isValid}
            secondaryCondition={
                shouldShowErrorRegardlessOfTouched ? error : errorText
            }
            onBlur={field.onBlur}
            clearable={clearable}
            allowLeadingZeros={allowLeadingZeros}
            onValueChange={(values) => {
                setFieldValue(field.name, valueFormatter(values));
            }}
        />
    );
}

export default NumberFormatField;
