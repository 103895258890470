import { useEffect, useState, useCallback } from 'react';
import { showToast } from 'spoton-lib';

import { useDebounce } from 'features/common/hooks';
import { WeightLimitCheck } from 'features/shipping/api';
import { IUnit } from 'features/common/types';
import { WeightLimitCheckMessageType } from 'features/shipping/api/WeightLimitCheck.types';
import { formatError } from 'features/common/utils';

import {
    WeightLimitCheckState,
    StateType,
} from './use-weight-limit-check.types';
import { formatWeightErrorMessage } from './utils';

const TIMEOUT = 450;

// ensuring that value is formatted correctly (2 decimal places)
const formatValue = (val: IUnit['value']) =>
    (Math.round(Number(val) * 100) / 100).toFixed(2);

interface IUseWeightLimitCheckProps extends IUnit {
    shouldCheckWeight?: boolean;
    timeout?: number;
}

export const useWeightLimitCheck = ({
    unit,
    value,
    shouldCheckWeight = true,
    timeout = TIMEOUT,
}: IUseWeightLimitCheckProps) => {
    const [weightCheckState, setWeightCheckState] =
        useState<WeightLimitCheckState>({
            type: StateType.idle,
        });

    const weightCheckResults = () => {
        if (
            weightCheckState.type === StateType.success ||
            weightCheckState.type === StateType.loading
        ) {
            return weightCheckState;
        }
    };

    const warningMessage = weightCheckResults()?.warningMessage;
    const weightCheckDetails = weightCheckResults()?.details;

    const checkWeight = async () => {
        if (!value || !unit) {
            setWeightCheckState({
                type: StateType.idle,
            });

            return;
        }

        try {
            setWeightCheckState({
                type: StateType.loading,
                // prevent flickering in ui
                warningMessage,
                details: weightCheckDetails,
            });

            const response = await WeightLimitCheck.checkLimit({
                weight: {
                    value: formatValue(value),
                    unit,
                },
            });

            setWeightCheckState({
                type: StateType.success,
                warningMessage: response.data.message,
                details: response.data.detail,
            });
        } catch (err) {
            const errorContent =
                formatError(err, formatWeightErrorMessage) ||
                'There was an error when checking weight limit';

            setWeightCheckState({
                type: StateType.error,
                errorMessage: errorContent as string,
            });
        }
    };

    const debouncedHandler = useDebounce(() => checkWeight(), timeout);

    const handler = useCallback(() => debouncedHandler(), [unit, value]);

    useEffect(() => {
        if (shouldCheckWeight) {
            handler();
        }
    }, [handler, shouldCheckWeight]);

    useEffect(() => {
        if (weightCheckState.type === StateType.error) {
            showToast({
                variant: 'danger',
                title: 'Error',
                content: weightCheckState.errorMessage,
                autoClose: 4000,
            });
        }
    }, [weightCheckState]);

    useEffect(() => {
        if (!value) {
            setWeightCheckState({ type: StateType.idle });
        }
    }, [value]);

    const isLoading = weightCheckState.type === StateType.loading;
    const isExceedingWeightLimit =
        warningMessage === WeightLimitCheckMessageType.exceedRangeLimit;

    return {
        isLoading,
        warningMessage,
        isExceedingWeightLimit,
        weightCheckDetails,
    };
};
