import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';
import { IVendor } from 'features/vendors/types';

import {
    IVendorsGetResponse,
    IVendorPostPayload,
    IVendorPostResponse,
    IVendorsGetParams,
    IVendorGetResponse,
    IVendorPatchPayload,
    IVendorPatchResponse,
} from './Vendors.types';

/** Obtains a paginated result from vendors collection. */
export const vendorsGet = async ({
    page = 1,
    pageSize,
    name = undefined,
    phrase = undefined,
    ordering,
}: IVendorsGetParams) =>
    axios
        .get<IVendorsGetResponse>('/api/v1/vendors/', {
            params: {
                page,
                pageSize,
                name,
                q: phrase,
                ordering,
            },
        })
        .then(getData);

/** Obtains data for single vendor. */
export const vendorGet = async (id: string) =>
    axios.get<IVendorGetResponse>(`/api/v1/vendors/${id}/`).then(getData);

/** Creates a vendor */
export const vendorCreate = async (vendor: IVendorPostPayload) =>
    axios.post<IVendorPostResponse>('/api/v1/vendors/', vendor).then(getData);

export const vendorDelete = async (vendor: IVendor) =>
    axios.delete(`/api/v1/vendors/${vendor.id}`).then(getData);

export const vendorPatch = async (vendor: IVendorPatchPayload) =>
    axios
        .patch<IVendorPatchResponse>(`/api/v1/vendors/${vendor.id}/`, vendor)
        .then(getData);
