import { useEffect, useState } from 'react';
import { Sidebar, IconButton, SidebarFooter, Button, colors } from 'spoton-lib';

import UnitsOfMeasurementSelector from 'features/settings/components/UnitsOfMeasurementSelector/UnitsOfMeasurementSelector.component';
import { SidebarHeader } from 'features/common/components/SidebarHeader';
import { IUnitOfMeasureGroup } from 'features/common';

import styles from './UnitsOfMeasurementSelectorSidebar.module.scss';
import { IPropTypes } from './UnitsOfMeasurementSelectorSidebar.types';

export function UnitsOfMeasurementSelectorSidebar(props: IPropTypes) {
    const {
        slideTo,
        onBackClick,
        onChange,
        onSaveClick,
        data,
        ...selectorProps
    } = props;

    const [unitsData, setUnitsData] = useState<IUnitOfMeasureGroup[]>(data);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        setUnitsData(data);
        setIsDirty(false);
    }, [data]);

    const handleDataChange = (data: IUnitOfMeasureGroup[]) => {
        setUnitsData(data);
        onChange(data);
        setIsDirty(true);
    };

    const handleSaveClick = () => {
        onSaveClick(unitsData);
    };

    // reset data on back
    const handleOnBackClick = () => {
        setUnitsData(data);
        setIsDirty(false);
        onBackClick();
    };

    return (
        <Sidebar.Page
            slideTo={slideTo}
            headerComponent={
                <SidebarHeader
                    title="Unit of Measure"
                    leftElement={
                        <IconButton
                            alt="Close"
                            name="CloseIcon"
                            onClick={handleOnBackClick}
                            disableBorder
                            color={colors.base90}
                        />
                    }
                />
            }
            footerComponent={
                <SidebarFooter>
                    <Button
                        variant="primary"
                        onClick={handleSaveClick}
                        disabled={!isDirty}
                        block
                    >
                        Save
                    </Button>
                </SidebarFooter>
            }
        >
            <div className={styles.ContentWrapper}>
                <UnitsOfMeasurementSelector
                    onChange={handleDataChange}
                    data={unitsData}
                    {...selectorProps}
                />
            </div>
        </Sidebar.Page>
    );
}

export default UnitsOfMeasurementSelectorSidebar;
