import { Table, IconButton, Input, colors, Button } from 'spoton-lib';
import { Column } from 'react-virtualized';
import clsx from 'clsx';

import { useWeightRange } from 'features/shipping/hooks/use-weights';
import { useDidUpdate } from 'features/common/utils';

import styles from './RateByRangeTable.module.scss';
import { IPropsTypes } from './RateByRangeTable.types';

// eslint-disable-next-line @typescript-eslint/naming-convention
const TableColumn = Table.Column as typeof Column;

export function RateByRangeTable({
    weights: inputWeights,
    onChange,
}: IPropsTypes) {
    const {
        handleAdd,
        weights,
        handleUptoChange,
        handleRemove,
        handlePriceChange,
        handleFromChange,
    } = useWeightRange(inputWeights);

    useDidUpdate(() => {
        onChange(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            weights.map(({ meta, ...weight }) => weight),
        );
    }, [weights]);

    return (
        <div>
            <Table
                height={80 * weights.length + 55}
                keyGetter={({ index }) => weights[index].fieldId}
                rowGetter={({ index }) => weights[index]}
                rowCount={weights.length}
                rowHeight={80}
            >
                <TableColumn
                    width={150}
                    flexGrow={1}
                    dataKey="from"
                    label="When weight is from"
                    cellRenderer={({ rowData, rowIndex }) => (
                        <Input
                            hideLabel
                            className={styles.InputSuffix}
                            disabled={!rowData.meta.allowFromInput}
                            type="number"
                            clearable={false}
                            value={rowData.from}
                            onChange={({ target }) =>
                                handleFromChange(target.value, rowIndex)
                            }
                        />
                    )}
                />
                <TableColumn
                    dataKey=""
                    width={104}
                    cellRenderer={() => <span className={styles.Dash} />}
                />
                <TableColumn
                    width={228}
                    flexGrow={1}
                    dataKey="to"
                    label="Up to"
                    cellRenderer={({ rowData, rowIndex }) => (
                        <Input
                            hideLabel
                            data-testid={`toValue-${rowIndex}`}
                            className={clsx(styles.InputUpTo, {
                                [styles.InputSuffix]: rowData.meta.allowToInput,
                            })}
                            disabled={!rowData.meta.allowToInput}
                            clearable={false}
                            type="number"
                            value={rowData.to}
                            isValid={!rowData.isToWeightError}
                            placeholder={
                                rowData.meta.allowToInput ? '' : 'And up'
                            }
                            onChange={({ target }) =>
                                handleUptoChange(target.value, rowIndex)
                            }
                        />
                    )}
                />
                <TableColumn
                    width={150}
                    flexGrow={1}
                    dataKey="price"
                    label="Shipping price"
                    cellRenderer={({ rowData, rowIndex }) => (
                        <Input
                            hideLabel
                            icon={<span>$</span>}
                            type="number"
                            placeholder="0,00"
                            clearable={false}
                            isValid={!rowData.isPriceError}
                            value={rowData.price >= 0 ? rowData.price : ''}
                            onChange={({ target }) =>
                                handlePriceChange(target.value, rowIndex)
                            }
                        />
                    )}
                />
                <TableColumn
                    dataKey=""
                    width={100}
                    label=""
                    cellRenderer={({ rowData, rowIndex }) =>
                        rowData.meta.allowRemove && (
                            <IconButton
                                name="ClearSolidIcon"
                                onClick={() => handleRemove(rowIndex)}
                                alt="Delete"
                                color={colors.black}
                                buttonSize="large"
                                size={22}
                                disableBorder
                                buttonProps={{
                                    type: 'button',
                                }}
                            />
                        )
                    }
                />
            </Table>
            <Button
                className={styles.AddRange}
                variant="secondary"
                onClick={handleAdd}
                type="button"
            >
                Add range +
            </Button>
        </div>
    );
}

export default RateByRangeTable;
