export const DIMENSION_UNITS_OPTIONS = [
    {
        label: 'in',
        value: 'inch',
    },
    {
        label: 'cm',
        value: 'cm',
    },
    {
        label: 'mm',
        value: 'mm',
    },
] as const;
export const DEFAULT_DIMENSION_UNIT_OPT = DIMENSION_UNITS_OPTIONS[0];

const dimensionValues = DIMENSION_UNITS_OPTIONS.map((el) => el.value);
export type DimensionUnit = typeof dimensionValues[number];

export const WEIGHT_UNITS_OPTIONS = [
    {
        label: 'lb',
        value: 'lb',
    },
    {
        label: 'oz',
        value: 'oz',
    },
    {
        label: 'g',
        value: 'g',
    },
    {
        label: 'kg',
        value: 'kg',
    },
] as const;
export const DEFAULT_WEIGHT_UNIT_OPT = WEIGHT_UNITS_OPTIONS[0];

// HARDCODED BECAUSE NO NEED PAGINATION
const PAGE = 1;
const PAGESIZE = 100;

export const requestParams = {
    page: PAGE,
    pageSize: PAGESIZE,
};
