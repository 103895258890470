import { generatePathFromRoot } from 'features/common/utils/routing.utils';

export const categoriesMountPath = '/categories';

// paths used in routing definition
export const categoriesLocalPaths = {
    root: '/',
} as const;

// paths used for navigation
export const categoriesPaths = {
    root: generatePathFromRoot(categoriesMountPath),
} as const;
