import { useMutation, useQuery } from 'features/common/serverStateHandler';
import {
    unitsOfMeasureGet,
    unitsOfMeasureSet,
} from 'features/common/api/UnitsOfMeasure.data';
import {
    IUnitsOfMeasurePostPayload,
    IUnitsOfMeasurePostResponse,
} from 'features/common/api/UnitsOfMeasure.type';

import {
    findUnitOfMeasureByName,
    mapUnitOfMeasureToDropdownOption,
    quantityDropdownUnit,
    quantityUnit,
} from './utils';

export const unitsOfMeasureQueryKeys = {
    all: [{ scope: 'unitOfMeasure' }] as const,
};

export const useUnitsOfMeasure = () => {
    const { data, isLoading, ...result } = useQuery(
        unitsOfMeasureQueryKeys.all,
        unitsOfMeasureGet,
    );

    const flatData = (data || []).flatMap((group) => group.units);

    const enabledUnits = flatData
        .filter(({ isEnabled }) => isEnabled)
        // Quantity is a unit of measure that's always enabled for all the locations
        .concat([quantityUnit]);

    return {
        ...result,
        unitsOfMeasure: flatData,
        unitsOfMeasureGroups: data || [],
        enabledUnits,
        enabledUnitsForDropdown: enabledUnits.map(
            mapUnitOfMeasureToDropdownOption,
        ),
        getSelectedDropdownOption: (value?: string | null) => {
            if (!value) {
                return quantityDropdownUnit;
            }

            const productUnit = enabledUnits.find(
                findUnitOfMeasureByName(value),
            );

            return productUnit
                ? mapUnitOfMeasureToDropdownOption(productUnit)
                : quantityDropdownUnit;
        },
        isLoadingUnitsOfMeasure: isLoading,
    };
};

export const useUnitsOfMeasureUpdate = () => {
    const { mutate, isLoading, ...rest } = useMutation<
        IUnitsOfMeasurePostResponse,
        unknown,
        IUnitsOfMeasurePostPayload
    >(unitsOfMeasureSet, {
        onMutate: ({ variables, queryHandler }) => {
            const previous = queryHandler.getQueryData(
                unitsOfMeasureQueryKeys.all,
            );

            queryHandler.setQueryData(unitsOfMeasureQueryKeys.all, variables);

            return previous;
        },
        onSuccess: ({ data: apiResponse, queryHandler }) => {
            return queryHandler.setQueryData(
                unitsOfMeasureQueryKeys.all,
                apiResponse,
            );
        },
        onError: ({ context, queryHandler }) => {
            if (context) {
                queryHandler.setQueryData(unitsOfMeasureQueryKeys.all, context);
            }
        },
        formatSuccessMessage: () =>
            'Units of measurement updated successfully!',
        formatErrorMessage: () =>
            'Unexpected error when setting units of measurements. Please try again.',
    });

    return {
        updateUnitsOfMeasure: mutate,
        isUpdatingUnitsOfMeasure: isLoading,
        ...rest,
    };
};
