/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react';
import {
    Sidebar,
    SidebarPagePositions,
    SidebarFooter,
    RowButton,
    Icon,
    IconButton,
    Button,
    colors,
} from 'spoton-lib';

import { SidebarHeader } from 'features/common/components/SidebarHeader';
import { IFiltersSidebarFilterState } from 'features/common/types';
import { naturalSort } from 'features/common/utils';

import { IPropTypes, IFilterPage, filterPage } from './FiltersSidebar.types';
import {
    defaultFiltersState,
    filterPageTitles,
    filtersConfigs,
} from './FiltersSidebar.utils';
import styles from './FiltersSidebar.module.scss';

/** Component for filtering products */
export function FiltersSidebar(props: IPropTypes) {
    const {
        filtersState,
        isSidebarOpen,
        filtersToShow,
        contentType,
        onClose,
        onApply,
    } = props;
    const [currentPage, setCurrentPage] = useState<IFilterPage>(
        filterPage.home,
    );
    const [isSaveButtonDisabled] = useState(false);
    const [currentState, setCurrentState] =
        useState<IFiltersSidebarFilterState>(
            filtersState || defaultFiltersState,
        );

    const isStateClear = filtersToShow.every((filterType) => {
        const filterConfig = filtersConfigs[filterType];

        if (!filterConfig) {
            return true;
        }

        return filterConfig.getIsClear(currentState);
    });

    useEffect(() => {
        if (filtersState && isSidebarOpen) {
            setCurrentState(filtersState);
        }
    }, [filtersState, isSidebarOpen]);

    const handleClickApply = () => {
        const nextState = {
            ...currentState,
            tags: naturalSort(currentState.tags),
        };
        onApply(nextState);
    };

    const handleClickClose = () => {
        onClose();
    };

    const closeButton = (
        <IconButton
            alt="Close"
            name="CloseIcon"
            variant="secondary"
            onClick={handleClickClose}
            color={colors.black}
            disableBorder
            buttonProps={{
                'data-testid': 'FiltersSidebarCloseButton',
            }}
        />
    );

    const backButton = (
        <IconButton
            alt="Back"
            name="ArrowLeftIcon"
            onClick={(): void => setCurrentPage(filterPage.home)}
            variant="secondary"
            color={colors.black}
            disableBorder
            buttonProps={{
                'data-testid': 'FiltersSidebarBackButton',
            }}
        />
    );

    const clearAllButton = (
        <Button
            onClick={() => setCurrentState(defaultFiltersState)}
            variant="tertiary"
            className={styles.ClearButton}
            data-testid="FiltersSidebarClearAllButton"
        >
            Clear all
        </Button>
    );

    return (
        <Sidebar isOpen={isSidebarOpen}>
            <Sidebar.Page
                slideTo={
                    currentPage === filterPage.home
                        ? SidebarPagePositions.CENTER
                        : SidebarPagePositions.LEFT
                }
                headerComponent={
                    <SidebarHeader
                        leftElement={closeButton}
                        rightElement={isStateClear ? '' : clearAllButton}
                        title="Filters"
                    />
                }
                footerComponent={
                    <SidebarFooter withShadow={false}>
                        <Button
                            block
                            variant="primary"
                            onClick={handleClickApply}
                            disabled={isSaveButtonDisabled}
                            data-testid="FiltersSidebarApplyButton"
                        >
                            Apply Filters
                        </Button>
                    </SidebarFooter>
                }
            >
                {filtersToShow.map((filterType) => {
                    const filterConfig = filtersConfigs[filterType];

                    if (!filterConfig) {
                        return null;
                    }

                    const title = filterPageTitles[filterType];

                    return (
                        <RowButton
                            key={filterType}
                            title={title}
                            subtitle={filterConfig.getSubtitle(currentState)}
                            className={styles.RowButton}
                            leftElement={
                                <Icon size={22} {...filterConfig.icon} />
                            }
                            onClick={() => setCurrentPage(filterType)}
                            data-testid={`FiltersSidebarRowButton${title}`}
                        />
                    );
                })}
            </Sidebar.Page>

            {filtersToShow.map((filterType) => {
                const filterConfig = filtersConfigs[filterType];

                if (!filterConfig) {
                    return null;
                }

                const title = filterPageTitles[filterType];
                const stateKey = filterType as keyof IFiltersSidebarFilterState;

                const handleClearState = () =>
                    setCurrentState((state) => ({
                        ...state,
                        [stateKey]: defaultFiltersState[stateKey],
                    }));

                return (
                    <Sidebar.Page
                        key={filterType}
                        slideTo={
                            currentPage === filterType
                                ? SidebarPagePositions.CENTER
                                : SidebarPagePositions.RIGHT
                        }
                        headerComponent={
                            <SidebarHeader
                                leftElement={backButton}
                                title={title}
                                rightElement={
                                    filterConfig.getIsClear(currentState) ? (
                                        ''
                                    ) : (
                                        <Button
                                            onClick={handleClearState}
                                            variant="tertiary"
                                            className={styles.ClearButton}
                                            data-testid={`FiltersSidebarHeaderClearButton${title}`}
                                        >
                                            Clear
                                        </Button>
                                    )
                                }
                            />
                        }
                    >
                        {filterConfig.getContent(
                            currentState,
                            setCurrentState,
                            contentType,
                        )}
                    </Sidebar.Page>
                );
            })}
        </Sidebar>
    );
}

export default FiltersSidebar;
