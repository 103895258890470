// eslint-disable-next-line no-restricted-imports
import { useFlags as useFlagsUnsafe } from 'launchdarkly-react-client-sdk';

import { ILaunchDarklyFeatureFlags } from 'features/common/types';

export const useFlags = () => {
    const flags = useFlagsUnsafe();

    // great place to mock flags values
    return {
        ...flags,
        // this hook somehow replaces `UPC` with `Upc` so doesn't meet LD value defined in LD admin panel
        shouldUseLegacyUPCValidation: flags.shouldUseLegacyUpcValidation,
    } as ILaunchDarklyFeatureFlags;
};
