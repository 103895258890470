import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

import {
    IValidatePhoneNumberParams,
    IValidatePhoneNumberResponse,
} from './PhoneNumber.types';

export function validatePhoneNumber(
    params: IValidatePhoneNumberParams,
): Promise<AxiosResponse<IValidatePhoneNumberResponse>> {
    return axios.get<IValidatePhoneNumberResponse>(
        `/api/v1/team-members/validate-phone-number/`,
        { params },
    );
}
