import {
    Table,
    Index,
    TableCellProps,
    LoadingIndicator,
    Icon,
    colors,
    Tooltip,
} from 'spoton-lib';
import { TableHeaderProps } from 'react-virtualized';

import * as iconSet from 'features/common/assets/icons';
import { useGetVariantsLinks } from 'features/products/services';
import { getAvailabilityText } from 'features/common/utils';
import { useChannels, useFlags } from 'features/common/hooks';

import { IPropTypes } from './ProductVariant.types';
import styles from './ProductList.module.scss';

const headerRowHeight = 54;
const variantRowHeight = 64;

export function ProductVariant({ product }: IPropTypes) {
    const { productActiveChannels } = useChannels();
    const { variants, isLoadingVariants } = useGetVariantsLinks({
        productId: product.id,
    });
    const { isLowStockAlertFeatureEnabled } = useFlags();

    if (isLoadingVariants) {
        return (
            <div className={styles.LoadingIndicatorWrapper}>
                <LoadingIndicator
                    className={styles.LoadingIndicatorWrapper_indicator}
                />
            </div>
        );
    }

    let variantsToShow = variants;
    const isDisplayingProduct = !product.hasVariants || variants.length === 0;
    const hasEmptyVariants = product.hasVariants && variants.length === 0;

    if (isDisplayingProduct) {
        variantsToShow = [
            {
                id: product.id,
                name: product.name,
                sku: product.sku,
                upc: product.upc,
                channels: product.channels,
                stock: product.stock,
                lowStock: product.lowStock,
            },
        ];
    }
    if (hasEmptyVariants) {
        variantsToShow = [{ id: product.id, name: product.name, channels: {} }];
    }

    const renderVariantInfo = ({ rowIndex }: TableCellProps) => {
        const variant = variantsToShow[rowIndex];

        return (
            <div className={styles.Cell}>
                <h3
                    data-testid={`ProductListVariantName-${variant.id}`}
                    className={styles.Cell_name}
                >
                    {variant.name}
                </h3>
                {product.hasVariants && (
                    <p
                        data-testid={`ProductListVariantAvailability-${variant.id}`}
                        className={styles.Cell_availability}
                    >
                        {getAvailabilityText(
                            productActiveChannels,
                            variant.channels,
                        )}
                    </p>
                )}
            </div>
        );
    };

    const renderCell = ({ rowData, dataKey }: TableCellProps): JSX.Element => (
        <div className={styles.Cell}>
            {rowData[dataKey] === undefined ? '-' : rowData[dataKey]}
        </div>
    );

    const renderNumericCell = ({
        rowData,
        dataKey,
    }: TableCellProps): JSX.Element => (
        <div className={styles.Cell_numeric}>
            {rowData[dataKey] === undefined ? '-' : rowData[dataKey]}
        </div>
    );

    const renderLowStock = ({ rowData }: TableCellProps): JSX.Element => {
        return (
            rowData.lowStock && (
                <Tooltip
                    tooltipContent="Low Stock"
                    variant="topLeft"
                    portalTarget={document.body}
                >
                    <Icon
                        name="WarningSolidIcon"
                        alt="Warning icon"
                        size={18}
                        color={colors.warning70}
                        iconSet={iconSet}
                    />
                </Tooltip>
            )
        );
    };

    const renderNumericHeader = ({ label }: TableHeaderProps): JSX.Element => {
        return <div className={styles.Cell_numeric}>{label}</div>;
    };

    return (
        <div className={styles.CollapsibleContent}>
            <Table
                className={styles.VariantsTable}
                rowCount={variantsToShow.length}
                rowGetter={({ index }: Index) => variantsToShow[index]}
                keyGetter={({ index }: Index) =>
                    String(variantsToShow[index].id)
                }
                rowHeight={variantRowHeight}
                headerHeight={headerRowHeight}
                variant="secondary"
                height={
                    headerRowHeight +
                    Math.min(variantsToShow.length, 3.5) * variantRowHeight
                }
                // following are here only for aligned indent
                isCollapsible
                collapsibleColumnCellRenderer={() => null}
                collapsibleContentGetter={() => ''}
                collapsibleContentHeight={0}
            >
                <Table.Column
                    key="name"
                    label={
                        isDisplayingProduct ? 'Product name' : 'Variant name'
                    }
                    dataKey="name"
                    width={410}
                    flexGrow={1}
                    cellRenderer={renderVariantInfo}
                />
                <Table.Column
                    key="sku"
                    label="SKU"
                    dataKey="sku"
                    width={200}
                    flexGrow={1}
                    cellRenderer={renderCell}
                />
                <Table.Column
                    key="upc"
                    label="UPC"
                    dataKey="upc"
                    width={200}
                    flexGrow={1}
                    cellRenderer={renderCell}
                />
                <Table.Column
                    key="stock"
                    label="Stock"
                    dataKey="stock"
                    width={80}
                    flexShrink={0}
                    flexGrow={0}
                    cellRenderer={renderNumericCell}
                    headerRenderer={renderNumericHeader}
                />
                {isLowStockAlertFeatureEnabled && (
                    <Table.Column
                        key="lowStock"
                        dataKey="lowStock"
                        width={76}
                        flexShrink={0}
                        flexGrow={0}
                        cellRenderer={renderLowStock}
                    />
                )}
            </Table>
        </div>
    );
}
