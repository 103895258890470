import {
    ILocalDeliveryDoordash,
    ILocalDeliveryDoordashSendData,
    ILocalDeliveryOwnDrivers,
    ILocalDeliveryState,
    ILocalDeliveryApiType,
    ILocalDeliveryData,
} from 'features/shipping/types/LocalDelivery.types';
import {
    mapWeekdayIntervalsToHours,
    parseWeekdayScheduleData,
} from 'features/settings/utils';

export const sidebarSubtitle: Record<ILocalDeliveryApiType, string> = {
    [ILocalDeliveryApiType.ownDrivers]: 'Using my own drivers',
    [ILocalDeliveryApiType.doordash]: 'Sameday by Doordash',
};

export const initialLocalDeliveryData: ILocalDeliveryData = {
    type: ILocalDeliveryApiType.doordash,
    contactless: false,
    customSchedule: false,
    availability: [],
    deliveryPrice: 0,
    minimumOrderPrice: 0,
    doordashOutOfPocketCost: null,
    doordashBaseCost: 0,
    doordashRequireSignature: false,
    leadtime: 30,
    details: '',
};

export const mapLocalDeliveryDataToOwnDriversData = (
    {
        deliveryPrice,
        minimumOrderPrice,
        leadtime,
        contactless,
        customSchedule,
        availability,
        details,
        id,
    }: ILocalDeliveryData,
    merchantLocation: string,
): ILocalDeliveryOwnDrivers => ({
    deliveryPrice: String(deliveryPrice),
    minimumOrderPrice: String(minimumOrderPrice),
    leadtime: String(leadtime),
    allowContactlessDelivery: Boolean(contactless),
    sameAsBusinessHours: !customSchedule,
    availability: availability
        ? mapWeekdayIntervalsToHours(availability)
        : null,
    deliveryDetails: details || '',
    merchantLocation,
    id,
});

export const mapOwnDriversDataToLocalDeliveryData = (
    ownDriversData: ILocalDeliveryOwnDrivers | undefined,
): ILocalDeliveryData => {
    if (!ownDriversData) {
        return { ...initialLocalDeliveryData };
    }

    return {
        ...initialLocalDeliveryData,
        deliveryPrice: Number(ownDriversData.deliveryPrice),
        minimumOrderPrice: Number(ownDriversData.minimumOrderPrice),
        leadtime: Number(ownDriversData.leadtime) || 30,
        contactless: Boolean(ownDriversData.allowContactlessDelivery),
        customSchedule: !ownDriversData.sameAsBusinessHours,
        availability: parseWeekdayScheduleData(ownDriversData.availability),
        details: ownDriversData.deliveryDetails || '',
        id: ownDriversData.id,
    };
};
export const mapLocalDeliveryDataToDoordashData = (
    {
        doordashOutOfPocketCost,
        leadtime,
        doordashRequireSignature,
        contactless,
        customSchedule,
        details,
        availability,
        id,
    }: ILocalDeliveryData,
    merchantLocation: string,
): ILocalDeliveryDoordashSendData => ({
    outOfPocketCost: String(doordashOutOfPocketCost),
    leadtime: String(leadtime),
    requireSignature: doordashRequireSignature,
    allowContactlessDelivery: Boolean(contactless),
    sameAsBusinessHours: !customSchedule,
    availability: availability
        ? mapWeekdayIntervalsToHours(availability)
        : null,
    deliveryDetails: details || '',
    merchantLocation,
    id,
});

export const mapDoordashDataToLocalDeliveryData = (
    doordashData: ILocalDeliveryDoordash | undefined,
    doordashBaseCost: number,
): ILocalDeliveryData => {
    if (!doordashData) {
        return {
            ...initialLocalDeliveryData,
            doordashBaseCost,
            type: ILocalDeliveryApiType.doordash,
        };
    }

    const leadtime = Number(doordashData.leadtime) || 30;

    return {
        ...initialLocalDeliveryData,
        type: ILocalDeliveryApiType.doordash,
        doordashBaseCost,
        doordashOutOfPocketCost: Number(doordashData.outOfPocketCost),
        leadtime,
        doordashRequireSignature: doordashData.requireSignature,
        contactless: Boolean(doordashData.allowContactlessDelivery),
        availability: parseWeekdayScheduleData(doordashData.availability),
        customSchedule: !doordashData.sameAsBusinessHours,
        details: doordashData.deliveryDetails || '',
        id: doordashData.id,
    };
};

export const localDeliveryStateAsLocalDeliveryData = (
    doordashBaseCost: number,
    localDeliveryState: ILocalDeliveryState,
    deliveryType?: ILocalDeliveryApiType,
) => {
    if (!deliveryType) {
        return { ...initialLocalDeliveryData, doordashBaseCost };
    }

    return deliveryType === ILocalDeliveryApiType.ownDrivers
        ? mapOwnDriversDataToLocalDeliveryData(localDeliveryState.ownDrivers)
        : mapDoordashDataToLocalDeliveryData(
              localDeliveryState.doordash,
              doordashBaseCost,
          );
};
