import clsx from 'clsx';

import styles from './GlobalPlaceholder.module.scss';

export function GlobalPlaceholder() {
    return (
        <div
            className={styles.GlobalPlaceholder}
            data-testid="GlobalPlaceholder"
        >
            <div className={styles.Content}>
                <div className={clsx(styles.Content_left, styles.Element)} />
                <div className={styles.Content_right}>
                    <div
                        className={clsx(styles.Element_title, styles.Element)}
                    />
                    <div
                        className={clsx(styles.Element_line, styles.Element)}
                    />
                    <div
                        className={clsx(styles.Element_line, styles.Element)}
                    />
                    <div
                        className={clsx(styles.Element_content, styles.Element)}
                    />
                </div>
            </div>
        </div>
    );
}

export default GlobalPlaceholder;
