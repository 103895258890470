import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { showToast } from 'spoton-lib';

export const useFileDownload = (
    request: () => Promise<AxiosResponse<Blob>>,
    filename: string,
    shouldTakeNameFromResponse = false,
) => {
    const downloadFile = useCallback(async () => {
        try {
            const response = await request();
            const fileUrl = URL.createObjectURL(new Blob([response.data]));

            const trigger = document.createElement('a');
            trigger.href = fileUrl;

            if (shouldTakeNameFromResponse) {
                const [, filenameFromResponse] =
                    response.headers['content-disposition']?.split(
                        'filename=',
                    ) || [];
                filename = filenameFromResponse?.replaceAll('"', '');
            }

            trigger.setAttribute('download', filename);

            document.body.appendChild(trigger);
            trigger.click();

            document.body.removeChild(trigger);
        } catch (error) {
            showToast({
                title: 'Download failed',
                content: shouldTakeNameFromResponse
                    ? 'Download failed. Please try again.'
                    : `'${filename}' download failed. Please try again.`,
                variant: 'danger',
                autoClose: 4000,
            });
            return;
        }
    }, [request, filename]);

    return downloadFile;
};
