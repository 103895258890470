import { useCallback, useMemo } from 'react';
import {
    AutoSizer,
    List,
    CellMeasurerCache,
    CellMeasurer,
} from 'react-virtualized';
import { Icon, Text, colors } from 'spoton-lib';

import { ReactComponent as WarningIllustration } from 'features/common/assets/warning_gray.svg';
import * as iconSet from 'features/common/assets/icons';

import { IPropTypes } from './ImportIssueTab.types';
import styles from './ImportIssueTab.module.scss';

export function ImportIssueTab({ issues, type }: IPropTypes) {
    const isErrorTab = type === 'error';

    const cache = useMemo(
        () =>
            new CellMeasurerCache({
                fixedWidth: true,
                defaultHeight: 40,
            }),
        [],
    );

    const renderRow = useCallback(
        ({ index, key, parent, style }) => (
            <CellMeasurer
                parent={parent}
                key={key}
                columnIndex={0}
                rowIndex={index}
                cache={cache}
            >
                <div style={style} className={styles.List_item}>
                    {issues[index]}
                </div>
            </CellMeasurer>
        ),
        [issues],
    );

    const issuesCount = issues.length;
    const headingText = isErrorTab
        ? ` There ${
              issuesCount === 1 ? ' is 1 error' : ` are ${issuesCount} errors`
          } importing your CSV/XLSX file. Try importing the CSV/XLSX file again.`
        : ` There ${
              issuesCount === 1
                  ? ' is 1 warning'
                  : ` are ${issuesCount} warnings`
          } importing your CSV/XLSX file that need your attention.`;

    return (
        <div className={styles.ListWrapper}>
            {issuesCount > 0 && (
                <div className={styles.ListHeader}>
                    <Icon
                        alt="Warning icon"
                        name={isErrorTab ? 'DangerIcon' : 'WarningIcon'}
                        size={24}
                        iconSet={isErrorTab ? iconSet : undefined}
                    />
                    <Text
                        type="boldParagraph"
                        className={styles.ListHeader_title}
                    >
                        {headingText}
                    </Text>
                </div>
            )}
            {issuesCount > 0 ? (
                <div className={styles.List}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                width={width}
                                height={height}
                                rowCount={issuesCount}
                                rowHeight={cache.rowHeight}
                                rowRenderer={renderRow}
                                overscanRowCount={1}
                                deferredMeasurementCache={cache}
                                className={styles.List_scrollable}
                            />
                        )}
                    </AutoSizer>
                </div>
            ) : (
                <div className={styles.Warning}>
                    <WarningIllustration
                        className={styles.WarningIllustration}
                    />
                    <Text color={colors.base90} type="h5">
                        There are no {isErrorTab ? 'errors' : 'warnings'}
                    </Text>
                    <Text color={colors.base90} type="p">
                        But there may be some issues in the{' '}
                        {isErrorTab ? 'Warnings' : 'Errors'} tab
                    </Text>
                </div>
            )}
        </div>
    );
}
