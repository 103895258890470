import { ReactNode } from 'react';

import { IItemImage } from 'features/common/types';

export enum FieldsNames {
    name = 'name',
    unit = 'unit',
    weight = 'weight',
}

export interface IFieldPropTypes {
    name: string;
    children: ReactNode;
    isHeader?: boolean;
}

export interface IField {
    name: FieldsNames;
    label: string;
}

export type OnWeightCheck = (
    index: number,
    weightWarning: string | undefined,
) => void;

export interface IPropTypes {
    onCancel: () => void;
    isOpen: boolean;
    onSuccessProductUpdate: () => void;
}

export interface IProductFormItem {
    id: string;
    title: string;
    primaryImage: IItemImage | null;
    priceUnit: string;
    value: string;
    unit: string;
}

export interface IMissingProductsFormState {
    products: IProductFormItem[];
}
