import { AxiosResponse } from 'axios';

import { ErrorLogger } from './errorLogger.utils';
import { RumAxiosError } from './rumError.utils';

// Interceptor logging response errors to DataDog
export const logResponseErrors = [
    (response: AxiosResponse) => response,
    // type safety here sucks but blame Axios
    (error: any) => {
        // AxiosError before axios v1.0.0 has an issue with stacktrace not being
        // created and saved on the Error object. This is why we create
        // new custom error object. Unfortunately stacktrace in this case is not
        // very helpful, but it's necessary in order to land in DataDog.
        const rumError = new RumAxiosError(error);
        ErrorLogger.reportError(rumError, rumError.context);

        return Promise.reject(error);
    },
];
