import { useEffect, useState } from 'react';

import { useGetCurrentUser } from 'features/common/services';
import { ExportModal } from 'features/products/components';
import { useExportModal } from 'features/products/components/ExportModal/hooks';
import { ExportFailedBanner } from 'features/products/components/ExportModal/ExportFailedBanner';
import { ExportInProgressBanner } from 'features/products/components/ExportModal/ExportInProgressBanner';
import { ExportDownloadBanner } from 'features/products/components/ExportModal/ExportDownloadBanner';
import { ProductsExportAcknowledgeStatus } from 'features/products/api/ProductsExport.types';

import { IPropTypes } from './Export.types';

export function ExportModalStarted({
    shouldShowExportModal,
    onShouldShowExportModal,
}: IPropTypes) {
    const [
        shouldShowExportInProgressBanner,
        setShouldShowExportInProgressBanner,
    ] = useState(false);
    const [shouldShowExportFailedBanner, setShouldShowExportFailedBanner] =
        useState(false);
    const [shouldShowExportDownloadBanner, setShouldShowExportDownloadBanner] =
        useState(false);

    const { currentUser } = useGetCurrentUser();

    const {
        canShowExportInProgress,
        canShowExportProcessFail,
        canShowExportFileDownload,
        startDownload,
        acknowledgeDismissedExport,
    } = useExportModal();

    const handleCloseExportInProgressBanner = () => {
        acknowledgeDismissedExport(
            ProductsExportAcknowledgeStatus.inProgressDismissed,
        );
        setShouldShowExportInProgressBanner(false);
    };

    const handleCloseExportFailedBanner = () => {
        acknowledgeDismissedExport(
            ProductsExportAcknowledgeStatus.failedDismissed,
        );
        setShouldShowExportFailedBanner(false);
    };

    const handleCloseExportDownloadBanner = () => {
        acknowledgeDismissedExport(
            ProductsExportAcknowledgeStatus.downloadedDismissed,
        );
        setShouldShowExportDownloadBanner(false);
    };

    const handleStartExportDownload = () => {
        startDownload();
        setShouldShowExportDownloadBanner(false);
    };

    useEffect(() => {
        setShouldShowExportFailedBanner(canShowExportProcessFail);
    }, [canShowExportProcessFail]);

    useEffect(() => {
        setShouldShowExportInProgressBanner(canShowExportInProgress);
    }, [canShowExportInProgress]);

    useEffect(() => {
        setShouldShowExportDownloadBanner(canShowExportFileDownload);
    }, [canShowExportFileDownload]);

    return (
        <>
            {shouldShowExportModal && (
                <ExportModal
                    onClose={() => onShouldShowExportModal(false)}
                    email={currentUser?.email || ''}
                />
            )}
            {shouldShowExportInProgressBanner && (
                <ExportInProgressBanner
                    onClose={handleCloseExportInProgressBanner}
                />
            )}
            {shouldShowExportFailedBanner && (
                <ExportFailedBanner onClose={handleCloseExportFailedBanner} />
            )}
            {shouldShowExportDownloadBanner && (
                <ExportDownloadBanner
                    onClose={handleCloseExportDownloadBanner}
                    onDownload={handleStartExportDownload}
                />
            )}
        </>
    );
}

export default ExportModalStarted;
