import { colors, Text } from 'spoton-lib';

import { IPropTypes } from './EmptyState.types';
import styles from './EmptyState.module.scss';

export function EmptyState(props: IPropTypes) {
    const { imageUrl, imageAlt, description, actions, testId = '' } = props;

    return (
        <div className={styles.EmptyState} data-testid={`emptyState${testId}`}>
            <div className={styles.Hero}>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        className={styles.Hero_image}
                        alt={imageAlt}
                        data-testid={`emptyStateImage${testId}`}
                    />
                )}
            </div>
            <Text
                as="div"
                type="sub1"
                className={styles.Description}
                color={colors.base90}
            >
                {description}
            </Text>
            <div
                className={styles.Actions}
                data-testid={`emptyStateActions${testId}`}
            >
                {actions}
            </div>
        </div>
    );
}

export default EmptyState;
