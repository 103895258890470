import { Route, Routes } from 'react-router-dom';

import {
    ErrorPage403,
    ErrorPage404,
    ErrorPage500,
} from 'features/errors/components';

import { errorLocalPaths } from './error.paths';

export function ErrorRoutes() {
    return (
        <Routes>
            <Route path={errorLocalPaths[403]} element={<ErrorPage403 />} />
            <Route path={errorLocalPaths[404]} element={<ErrorPage404 />} />
            <Route path={errorLocalPaths[500]} element={<ErrorPage500 />} />
        </Routes>
    );
}

export default ErrorRoutes;
