import { useEffect, useState } from 'react';

import { StringFormatInputOnChange } from 'features/common/types';
import { getInitialShippingData } from 'features/products/components/ShippingSection/ShippingSection.utils';

type UseResetShippingDataArgs = {
    shouldResetData: boolean;
    updateWeight: StringFormatInputOnChange;
    updateUnit: StringFormatInputOnChange;
};

const INITIAL_SHIPPING_DATA = getInitialShippingData();

/*
   Since updating form state is asynchronous, we cannot batch together resetting unit and weight.
   We have to do it one at the time.
*/
export const useResetShippingData = ({
    shouldResetData,
    updateUnit,
    updateWeight,
}: UseResetShippingDataArgs): void => {
    const [shouldResetWeight, setShouldResetWeight] = useState(false);

    useEffect(() => {
        if (shouldResetData) {
            updateUnit(INITIAL_SHIPPING_DATA.unit);
            setShouldResetWeight(true);
        }
    }, [shouldResetData]);

    useEffect(() => {
        if (shouldResetWeight) {
            updateWeight(INITIAL_SHIPPING_DATA.value);
            setShouldResetWeight(false);
        }
    }, [shouldResetWeight]);
};
