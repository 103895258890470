import { removeEmpty } from 'features';

import { IProductsGetParams } from 'features/products/api/Products.types';
import { IProductVariantsLinksGetPayload } from 'features/products/api/ProductVariants.types';

export const importKeys = {
    all: [{ scope: 'productImports' }] as const,
    sampleFile: () => [{ ...importKeys.all[0], entity: 'sampleFile' }] as const,
    last: () => [{ ...importKeys.all[0], entity: 'last' }] as const,
    import: (id: string) => [{ ...importKeys.all[0], id }] as const,
};

export const productKeys = {
    all: [{ scope: 'product' }] as const,
    listByParent: (ids: string[]) => [{ ...productKeys.all[0], ids }] as const,
    product: (id?: string) => [{ ...productKeys.all[0], id }] as const,
    list: (params: IProductsGetParams) =>
        [
            removeEmpty({ ...productKeys.all[0], entity: 'list', ...params }),
        ] as const,
    infinityList: () =>
        [{ ...productKeys.all[0], entity: 'infinity' }] as const,
    infinity: (title?: string) =>
        [removeEmpty({ ...productKeys.infinityList()[0], title })] as const,
};

export const productVariantsKeys = {
    all: [{ scope: 'productVariants' }] as const,
    listByParent: (ids: string[]) =>
        [{ ...productVariantsKeys.all[0], ids }] as const,
    product: (id?: string) => [{ ...productVariantsKeys.all[0], id }] as const,
    list: (page?: string) => [
        removeEmpty({ ...productVariantsKeys.all[0], entity: 'list', page }),
    ],
};

export const productsExportKeys = {
    all: [{ scope: 'productsExport' }] as const,
    last: () => [{ ...productsExportKeys.all[0], entity: 'last' }] as const,
    exportFile: (exportId?: string) =>
        [
            { ...productsExportKeys.all[0], entity: 'exportFile', exportId },
        ] as const,
};

export const variantsLinksKeys = {
    all: [{ scope: 'variantsLinks' }] as const,
    lists: () => [{ ...variantsLinksKeys.all[0], entity: 'lists' }] as const,
    list: ({ page, productId }: IProductVariantsLinksGetPayload) =>
        [
            removeEmpty({
                ...variantsLinksKeys.lists()[0],
                page,
                productId,
            }),
        ] as const,
};
