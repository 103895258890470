export interface IMerchantLocationSettingsDefinition {
    name: ILocationSettingFieldName;
    settingType: string;
    group: string;
    helpText: string;
    choices: Record<string, any> | null;
    value: string | null;
    optional: boolean;
}

export interface IMerchantLocationSettings {
    id: string;
    contentType: number;
    definition: IMerchantLocationSettingsDefinition;
    value: string | null;
}

export enum ILocationSettingFieldName {
    tenderPriceEffect = 'TENDER_PRICE_EFFECT',
    cashDiscountEnabled = 'CASH_DISCOUNT_ENABLED',
    cashDiscountValue = 'CASH_DISCOUNT_VALUE',
    cashDiscountPriceSet = 'CASH_DISCOUNT_PRICES_SET',
    convenienceFeeEnabled = 'CONVENIENCE_FEE_ENABLED',
    convenienceFeeChargeState = 'CONVENIENCE_FEE_CHARGE_STAGE',
    convenienceFeeValue = 'CONVENIENCE_FEE_VALUE',
    shippingEnabled = 'SHIPPING_ENABLED',
    businessLogo = 'BUSINESS_LOGO',
    businessName = 'BUSINESS_NAME',
    businessEmail = 'BUSINESS_EMAIL',
    businessAddress = 'BUSINESS_ADDRESS',
    businessNumber = 'BUSINESS_NUMBER',
    businessInformation = 'BUSINESS_INFORMATION',
    receiptColor = 'RECEIPT_CUSTOM_COLOR',
    receiptShowProduct = 'RECEIPT_SHOW_PRODUCT',
    receiptShowLocation = 'RECEIPT_SHOW_LOCATION',
    receiptShowPhoneNumber = 'RECEIPT_SHOW_PHONE_NUMBER',
    receiptShowEmail = 'RECEIPT_SHOW_EMAIL',
    receiptRefundMessage = 'RECEIPT_CUSTOM_TEXT_1',
    receiptCustomMessage = 'RECEIPT_CUSTOM_TEXT_2',
    receiptShowSocialNetworks = 'RECEIPT_SHOW_SOCIAL_NETWORKS',
    doordashBaseCost = 'DOORDASH_BASE_COST',
    localPickup = 'LOCAL_PICKUP',
    localDelivery = 'LOCAL_DELIVERY',
    predefinedPercentageTip1 = 'PREDEFINED_PERCENTAGE_TIP_1',
    predefinedPercentageTip2 = 'PREDEFINED_PERCENTAGE_TIP_2',
    predefinedPercentageTip3 = 'PREDEFINED_PERCENTAGE_TIP_3',
    tipCalculationStage = 'TIP_CALCULATION_STAGE',
    acceptTips = 'ACCEPT_TIPS',
    allowFeedback = 'ALLOW_FEEDBACK',
    allowOverallocation = 'ALLOW_OVERALLOCATION',
    typeOfProduct = 'TYPE_OF_PRODUCT',
    typeOfLocation = 'TYPE_OF_LOCATION',
    signatureCollectionAmount = 'SIGNATURE_COLLECTION_AMOUNT',
    refundCancellationPolicy = 'REFUND_CANCELLATION_POLICY',
    loyaltyCheckinReminder = 'LOYALTY_CHECKIN_REMINDER',
    shippingTypeName = 'SHIPPING_TYPE',
    packingSlipFormat = 'PACKING_SLIP_FORMAT',
    shippingLabelFormat = 'SHIPPING_LABEL_FORMAT',
    customCFDLogo = 'CUSTOM_CFD_LOGO',
    useBusinessNameOnCFD = 'USE_BUSINESS_NAME_ON_CFD',
    useLogoOnCFD = 'USE_LOGO_ON_CFD',
}

export enum ILocationSettingShippingTypeName {
    none = 'None',
    shippo = 'SHIPPO',
    flatRate = 'FLAT_RATE',
}

export enum PackingSlipFormat {
    pdfDefault = 'PDF',
    pdf4x6 = 'PDF_4x6',
}

export enum ShippingLabelFormat {
    pdfDefault = 'PDF',
    pdf4x6 = 'PDF_4x6',
}
