/* eslint-disable no-restricted-imports */
import {
    Container as InternalContainer,
    Col as InternalCol,
    Row as InternalRow,
} from 'spoton-lib';
/* eslint-enable no-restricted-imports */

import { IContainerProps, IRowProps, IColProps } from './Grid.types';

const noPadding = {
    padding: 0,
    margin: 0,
};

function Container(props: IContainerProps) {
    const newProps = {
        style: { ...noPadding, ...props.style },
        ...props,
    };

    return <InternalContainer {...newProps} />;
}

function Col(props: IColProps) {
    return <InternalCol {...props} />;
}

function Row(props: IRowProps) {
    return <InternalRow {...props} />;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export { Container, Col, Row };
