import { useCallback, useState } from 'react';
import { Dropdown } from 'spoton-lib';

import {
    IAttributeOption,
    DropdownOnChange,
    DropdownOnInputChange,
} from 'features/common/types';
import {
    newItemValue,
    extractItemName,
} from 'features/common/components/QueryDropdown/QueryDropdown.utils';
import { useCreateAttributeOption } from 'features/attributes/services/attributes';

import { IPropTypes } from './ProductAttributeOptionsDropdown.types';
import {
    getSelectedItem,
    getItemsList,
} from './ProductAttributeOptionsDropdown.utils';

export function ProductAttributeOptionsDropdown(
    props: IPropTypes,
): JSX.Element {
    const {
        value,
        onChange,
        allSelected = [],
        attributeOptions = [],
        attribute,
        isLoading,
        ...dropdownProps
    } = props;
    const [query, setQuery] = useState('');

    const { createItem, isLoading: isMutating } =
        useCreateAttributeOption(attribute);

    const { itemsList, isQueryItemExists } = getItemsList({
        value,
        query,
        options: attributeOptions,
        selectedElements: allSelected,
    });

    const attributeItem =
        attributeOptions.find(
            (attributeOption) => attributeOption.option === value,
        ) || null;

    const isBusy = isMutating || isLoading;

    const selectedDropdownOption = getSelectedItem(
        attributeItem,
        value,
        itemsList,
        isBusy,
    );

    const handleInputChange: DropdownOnInputChange = (value, { action }) => {
        if (action === 'input-change') {
            setQuery(value);
        }
    };

    const clearInput = () => setQuery('');

    const handleDropdownChange = useCallback<DropdownOnChange>(
        async (selectedItem) => {
            const itemId: string = selectedItem.value;
            let item: IAttributeOption;

            if (itemId === newItemValue) {
                const name = extractItemName(selectedItem.label);

                item = await createItem({
                    option: name,
                    attribute,
                });
            } else {
                item = attributeOptions.find(
                    ({ id }) => String(id) === String(selectedItem.value),
                ) as IAttributeOption;
            }

            onChange(item);
            clearInput();
        },
        [onChange, clearInput, attributeOptions],
    );

    const noOptionsMessageGenerator = () =>
        isQueryItemExists
            ? `Option "${query}" already exists!`
            : 'Type to add a new option';

    return (
        <Dropdown
            value={selectedDropdownOption}
            options={itemsList}
            onBlur={clearInput}
            onChange={handleDropdownChange}
            onInputChange={handleInputChange}
            noOptionsMessage={noOptionsMessageGenerator}
            menuPortalTarget={document.body}
            isLoading={isBusy}
            withDividers
            {...dropdownProps}
        />
    );
}

export default ProductAttributeOptionsDropdown;
