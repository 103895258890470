import { TableCellProps } from 'spoton-lib';

export type IPropTypes<DataItem> = {
    header?: JSX.Element | JSX.Element[] | string;
    children?: JSX.Element[];
    footer?: JSX.Element | JSX.Element[] | string;
    viewMoreText: string;
    viewLessText: string;
    className: string;
    headerClassName?: string;
    bodyClassName?: string;
    footerClassName?: string;
    showMoreButtonClassName: string;
    defaultShowing: number;
    data: DataItem[];
    columnsData: IColumnData<DataItem>[];
};

export const defaultProps = {
    header: '',
    footer: '',
    viewMoreText: 'View more services',
    viewLessText: 'View less services',
    className: '',
    headerClassName: '',
    bodyClassName: '',
    footerClassName: '',
    showMoreButtonClassName: '',
    defaultShowing: 3,
};

export type RendererProps<DataItem> = (
    props: { rowData: DataItem } & Omit<TableCellProps, 'rowData'>,
) => JSX.Element | string;

export interface IColumnData<DataItem> {
    label: string;
    dataKey: string;
    width: number;
    flexGrow: number;
    className?: string;
    headerClassName?: string;
    cellRenderer?: RendererProps<DataItem>;
    headerRenderer?: RendererProps<DataItem>;
}
