import { colors } from 'spoton-lib';

import { IPropsType } from './DnDIcon.types';

export function DnDIcon({ isDisabled }: IPropsType) {
    const color = isDisabled ? colors.base30 : colors.black;

    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="44" height="44" fill="white" />
            <circle cx="19" cy="15" r="2" fill={color} />
            <circle cx="19" cy="22" r="2" fill={color} />
            <circle cx="19" cy="29" r="2" fill={color} />
            <circle cx="26" cy="15" r="2" fill={color} />
            <circle cx="26" cy="22" r="2" fill={color} />
            <circle cx="26" cy="29" r="2" fill={color} />
        </svg>
    );
}
