import { generatePathFromRoot } from 'features/common/utils/routing.utils';

export const servicesMountPath = '/services';

// paths used in routing definition
export const servicesLocalPaths = {
    root: '/',
    new: '/new',
    edit: '/:id',
} as const;

// paths used for navigation
export const servicesPaths = {
    root: generatePathFromRoot(servicesMountPath),
    new: generatePathFromRoot(servicesMountPath, servicesLocalPaths.new),
    edit: (id: string): string => generatePathFromRoot(servicesMountPath, id),
} as const;
