import { generatePathFromRoot } from 'features/common/utils/routing.utils';

export const errorMountPath = 'error';

// paths used in routing definition
export const errorLocalPaths = {
    [403]: '403',
    [404]: '404',
    [500]: '500',
} as const;

// paths used for navigation
export const errorPaths = {
    [403]: generatePathFromRoot(errorMountPath, errorLocalPaths[403]),
    [404]: generatePathFromRoot(errorMountPath, errorLocalPaths[404]),
    [500]: generatePathFromRoot(errorMountPath, errorLocalPaths[500]),
} as const;
