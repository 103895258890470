import { generatePathFromRoot } from 'features/common/utils/routing.utils';

export const vendorsMountPath = '/vendors';

export const vendorsLocalPaths = {
    root: '/',
};

export const vendorsPaths = {
    root: generatePathFromRoot(vendorsMountPath),
};
