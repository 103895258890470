import { Banner, Text } from 'spoton-lib';

import { IPropTypes } from './ErrorsBanner.types';
import styles from './ErrorsBanner.module.scss';

export function ErrorsBanner(props: IPropTypes) {
    const { title, errors } = props;

    return (
        <Banner
            message={
                <div>
                    <Text type="sub2">{title}</Text>
                    <ul className={styles.ErrorsList}>
                        {errors.map((error, index) => (
                            <li key={index}>
                                <Text type="p">{error}</Text>
                            </li>
                        ))}
                    </ul>
                </div>
            }
            variant="danger"
            className={styles.Errors}
        />
    );
}

export default ErrorsBanner;
