import { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import { colors, Text } from 'spoton-lib';

import {
    ILocalDeliveryApiType,
    ILocalDeliveryData,
} from 'features/shipping/types/LocalDelivery.types';
import { RadioBlockOnChange } from 'features/common/components/RadioBlock/RadioBlock.types';
import { RadioBlock } from 'features/common/components';

import styles from './LocalDeliveryForm.module.scss';

const radioBlockContent: Record<
    ILocalDeliveryApiType,
    { label: string; description: ReactNode }
> = {
    [ILocalDeliveryApiType.ownDrivers]: {
        label: 'Use my Own Drivers',
        description: (
            <Text>
                I will provide the delivery driver who will fulfill the order
            </Text>
        ),
    },
    [ILocalDeliveryApiType.doordash]: {
        label: 'Sameday Delivery by Doordash',
        description: (
            <Text>
                A Doordash driver will automatically be requested when a
                customer purchases an eligible item.
            </Text>
        ),
    },
};

const radioOptions: ILocalDeliveryApiType[] = [
    ILocalDeliveryApiType.ownDrivers,
    ILocalDeliveryApiType.doordash,
];

export function LocalDeliveryTypeForm() {
    const { values, setFieldValue } = useFormikContext<ILocalDeliveryData>();
    const handleChange: RadioBlockOnChange = (deliveryType) =>
        setFieldValue('type', deliveryType);

    return (
        <div className={styles.LocalDeliveryTypeForm}>
            <Text type="p" className={styles.Description} color={colors.base80}>
                Choose how you want to deliver your products
            </Text>
            {radioOptions.map((deliveryType) => {
                const { label, description } = radioBlockContent[deliveryType];

                return (
                    <RadioBlock
                        className={styles.RadioBlock}
                        key={deliveryType}
                        value={deliveryType}
                        checked={deliveryType === values.type}
                        label={label}
                        onChange={handleChange}
                    >
                        {description}
                    </RadioBlock>
                );
            })}
        </div>
    );
}
