import { Button, Icon } from 'spoton-lib';

import { DropdownMenu, IDropdownOption } from 'features/common';
import { useFlags } from 'features/common/hooks';

import { DropdownValue, IPropTypes } from './SelectedProductsHeader.types';
import styles from './SelectedProductsHeader.module.scss';

export function SelectedProductsHeader({
    onUpdateAvailability,
    onUpdateTags,
    onPrintBarcodes,
    onDelete,
}: IPropTypes) {
    const { isBarcodePrintingEnabled } = useFlags();

    const options: IDropdownOption<DropdownValue, JSX.Element>[] = [];
    if (isBarcodePrintingEnabled) {
        options.push({
            label: (
                <div className={styles.OptionWrapper}>
                    <Icon
                        className={styles.OptionWrapper_icon}
                        size={24}
                        alt="Barcodes and labels"
                        name="BarcodeIcon"
                        data-testid="printBarcodes"
                    />
                    Barcodes and labels
                </div>
            ),
            value: DropdownValue.barcodesAndLabels,
        });
    }
    options.push({
        label: (
            <div className={styles.OptionWrapper}>
                <Icon
                    className={styles.OptionWrapper_icon}
                    size={24}
                    alt="Delete"
                    name="DeleteIcon"
                    data-testid="delete"
                />
                Delete
            </div>
        ),
        value: DropdownValue.delete,
    });

    return (
        <div className={styles.Controls}>
            <Button
                variant="secondary"
                className={styles.Controls_button}
                onClick={onUpdateAvailability}
                data-testid="modifyAvailability"
            >
                Modify Availability
            </Button>
            <Button
                variant="secondary"
                className={styles.Controls_button}
                onClick={onUpdateTags}
                data-testid="addTags"
            >
                Add Tags
            </Button>
            {options.length > 0 && (
                <DropdownMenu
                    value={null}
                    options={options}
                    menuPortalTarget={document.body}
                    onSelect={({ value }) => {
                        switch (value) {
                            case DropdownValue.barcodesAndLabels:
                                return onPrintBarcodes();
                            case DropdownValue.delete:
                                return onDelete();
                        }
                    }}
                    iconSize={24}
                    menuPosition="absolute"
                    withDividers={false}
                    menuWidth="15rem"
                    triggerElement={
                        <Button
                            variant="secondary"
                            className={styles.Dropdown_trigger}
                            data-testid="moreMenu"
                        >
                            More
                            <Icon
                                alt="More"
                                className={styles.Dropdown_triggerIcon}
                                name="ExpandIcon"
                                size={28}
                            />
                        </Button>
                    }
                    className={styles.Dropdown}
                    useButtonTrigger
                />
            )}
        </div>
    );
}

export default SelectedProductsHeader;
