import PropTypes from 'prop-types';

export interface IProps {
    /** Content of the modal header */
    content: JSX.Element | string;

    /** A className to override styling */
    className?: string;

    /** A className to override close button styling */
    closeButtonClassName?: string;

    /** Called to dismiss the modal. If not supplied ModalHeader will hide the close button */
    onRequestClose?: (event: React.SyntheticEvent) => void;

    /** Alt text for Close Icon */
    iconAlt: string;
}

export const propTypes = {
    content: PropTypes.node.isRequired,
    className: PropTypes.string,
    onRequestClose: PropTypes.func,
    iconAlt: PropTypes.string,
};

export const defaultProps = {
    iconAlt: 'CloseModal',
};
