import { useMemo } from 'react';
import { object, lazy, array, string, number } from 'yup';

import { useSearchParams } from 'features/common/hooks/useSearchParams/useSearchParams';

const paramsSchema = object({
    tags: lazy((val) =>
        Array.isArray(val) ? array().of(string().required()) : string(),
    ),
    channels: lazy((val) =>
        Array.isArray(val) ? array().of(string().required()) : string(),
    ),
    categories: lazy((val) =>
        Array.isArray(val) ? array().of(string().required()) : string(),
    ),
    page: number(),
    q: string(),
    searchContext: string(),
    sortBy: string(),
    sortDirection: string(),
    lowStock: string(),
});

export const ensureArray = <T>(input: T | T[] | undefined) => {
    return [input ?? []].flat();
};

export const useURLParams = () => {
    const [urlSearchParams, setUrlSearchParams, isInitialized] =
        useSearchParams(paramsSchema);

    const tags = useMemo(
        () => ensureArray(urlSearchParams.tags),
        [urlSearchParams.tags],
    );

    const channels = useMemo(
        () => ensureArray(urlSearchParams.channels),
        [urlSearchParams.channels],
    );

    const categories = useMemo(
        () => ensureArray(urlSearchParams.categories),
        [urlSearchParams.categories],
    );

    const params = {
        page: urlSearchParams.page,
        q: urlSearchParams.q,
        searchContext: urlSearchParams.searchContext,
        sortBy: urlSearchParams.sortBy,
        sortDirection: urlSearchParams.sortDirection,
        lowStock: urlSearchParams.lowStock,
        tags,
        channels,
        categories,
    };

    return [params, setUrlSearchParams, isInitialized] as const;
};
