import {
    Icon,
    Text as BaseText,
    Button as BaseButton,
    colors,
    IconButton,
} from 'spoton-lib';
import clsx from 'clsx';

import styles from './WarningInfo.module.scss';
import {
    IPropsTypes,
    ButtonProps,
    HideButtonProps,
    TextProps,
} from './WarningInfo.types';

export function WarningInfo({ containerClassName, children }: IPropsTypes) {
    return (
        <div className={clsx(styles.Container, containerClassName)}>
            <Icon name="WarningIcon" size={20} alt="warning" />
            {children}
        </div>
    );
}

function Text({ className, children }: TextProps) {
    return (
        <BaseText className={clsx(styles.Text, className)} as="p">
            {children}
        </BaseText>
    );
}

function Button({ className, onClick, children }: ButtonProps) {
    return (
        <BaseButton
            className={clsx(styles.Button, className)}
            variant="secondary"
            onClick={onClick}
        >
            {children}
        </BaseButton>
    );
}

function HideButton({ onClick, className }: HideButtonProps) {
    return (
        <IconButton
            name="ClearSolidIcon"
            onClick={onClick}
            alt="HideWarning"
            color={colors.base50}
            buttonSize="regular"
            size={22}
            disableBorder
            className={clsx(styles.HideButton, className)}
            buttonProps={{
                type: 'button',
            }}
        />
    );
}

WarningInfo.Text = Text;
WarningInfo.Button = Button;
WarningInfo.HideButton = HideButton;

export default WarningInfo;
