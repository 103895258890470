import { createAction } from '@reduxjs/toolkit';

import { IProductData } from 'features/products/types';

export const createDuplicatedProduct = createAction<IProductData>(
    'productDuplication/createDuplicatedProduct',
);

export const deleteDuplicatedProduct = createAction(
    'productDuplication/deleteDuplicatedProduct',
);
