import { Table, Index, TableCellProps } from 'spoton-lib';
import { useFormikContext } from 'formik';
import clsx from 'clsx';

import { NumberFormatField, TooltipEnhanced } from 'features/common';
import { IBarcodePrintingFormData } from 'features/products/components/BarcodePrintingModal/BarcodePrintingForm/BarcodePrintingForm.types';
import { TextField } from 'features/common/components/TextField';

import styles from './BarcodePrintingTable.module.scss';
import { IPropTypes } from './BarcodePrintingTable.types';

const headerRowHeight = 54;
const rowHeight = 72;
const minRows = 1;
const maxRows = 3.5;

export function BarcodePrintingTable({ className }: IPropTypes): JSX.Element {
    const {
        values: { variants },
        setFieldValue,
    } = useFormikContext<IBarcodePrintingFormData>();

    const variantsCount = variants.length;
    const tableHeight =
        headerRowHeight +
        rowHeight * Math.min(Math.max(variantsCount, minRows), maxRows);
    const rowGetter = (row: Index) => variants[row.index];
    const keyGetter = (row: Index) => String(variants[row.index].id);
    const handleSelectionChange = (selection: string[]) => {
        // using setFieldValue only on changed variant caused issues
        // with table's SELECT/DESELECT ALL
        setFieldValue(
            'variants',
            variants.map((variant) => ({
                ...variant,
                isSelected: selection.includes(String(variant.id)),
            })),
        );
    };
    const selectedKeys = variants
        .filter(({ isSelected }) => isSelected)
        .map(({ id }) => String(id));

    const renderLabel = ({ rowIndex }: TableCellProps): JSX.Element => {
        const { title, price, isSelected } = variants[rowIndex];
        const label = [title, ...(price ? [price] : [])].join(' / ');

        return (
            <TooltipEnhanced
                content={label}
                tooltipClassName={styles.Tooltip}
                contentClassName={clsx(
                    styles.Name,
                    !isSelected && styles.Name___disabled,
                )}
                variant="topLeft"
                contentTagName="span"
            />
        );
    };

    return (
        <div className={className}>
            <Table
                height={tableHeight}
                rowCount={variantsCount}
                rowGetter={rowGetter}
                keyGetter={keyGetter}
                variant="secondary"
                headerRowHeight={headerRowHeight}
                rowHeight={rowHeight}
                onSelectionChange={handleSelectionChange}
                selectedKeys={selectedKeys}
                isSelectable
            >
                <Table.Column
                    label="Variants"
                    dataKey="label"
                    width={0}
                    flexGrow={1}
                    cellRenderer={renderLabel}
                />
                <Table.Column
                    label="UPC"
                    dataKey="upc"
                    width={280}
                    flexShrink={0}
                    cellRenderer={({ rowIndex: index }: TableCellProps) => (
                        <TextField
                            className={styles.UpcInput}
                            name={`variants.${index}.upc`}
                            placeholder="000000000000"
                            disabled={!variants[index].isSelected}
                        />
                    )}
                />
                <Table.Column
                    label="Qty to print"
                    dataKey="quantity"
                    width={128}
                    flexShrink={0}
                    cellRenderer={({ rowIndex: index }: TableCellProps) => (
                        <NumberFormatField
                            className={styles.QuantityInput}
                            name={`variants.${index}.quantity`}
                            placeholder="0"
                            allowNegative={false}
                            decimalScale={0}
                            disabled={!variants[index].isSelected}
                        />
                    )}
                />
            </Table>
        </div>
    );
}

export default BarcodePrintingTable;
