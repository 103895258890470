import { Input } from 'spoton-lib';
import { Field, useFormikContext, getIn } from 'formik';

import { IPropTypes } from './TextField.types';

export function TextField({ name, ...props }: IPropTypes) {
    const { errors, touched, values } = useFormikContext();

    const touch = getIn(touched, name);
    const error = getIn(errors, name);
    const value = getIn(values, name);

    return (
        <Field
            as={Input}
            id={name}
            isValid={getIn(touched, name) ? !getIn(errors, name) : true}
            secondaryCondition={!!touch && !!error ? error : null}
            name={name}
            value={value || ''}
            {...props}
        />
    );
}

export default TextField;
