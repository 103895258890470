import { SortDirectionType } from 'spoton-lib';

import {
    IProductBulkPayload,
    IProductsGetParams,
} from 'features/products/api/Products.types';
import {
    IBulkAction,
    IBulkActionParameters,
    IProductAvailabilityVariantList,
    IProductListProduct,
} from 'features/products/types';
import { IColumnsMap } from 'features/common/hooks/useSelectableColumns/useSelectableColumns.types';

import {
    IProductsColumns,
    ISearchContextOptions,
} from './MainProductsPage.types';

export const mapProductListToBulkActionAvailabilityRequest = (
    productList: IProductAvailabilityVariantList,
    shouldChannelBeAvailable: boolean,
): IProductBulkPayload => {
    const parameters: IBulkActionParameters[] = productList.variants.map(
        (variant) => ({
            filters: {
                id: [variant.id],
                all: false,
            },
            value: Object.keys(variant.channels).filter(
                (channelId) =>
                    variant.channels[String(channelId)] ===
                    shouldChannelBeAvailable,
            ),
        }),
    );

    return {
        action: shouldChannelBeAvailable
            ? IBulkAction.addVariantChannelAvailability
            : IBulkAction.removeVariantChannelAvailability,
        parameters,
    };
};

/**
 * Returns intersection of multiple array of strings
 * @param tags Tags
 */
export const tagsIntersection = (tags: string[][]): string[] => {
    const firstSet: Set<string> = tags.length ? new Set(tags[0]) : new Set();
    const intersection = tags.reduce(
        (acc, current) => new Set(current.filter((x) => acc.has(x))),
        firstSet,
    );

    return Array.from(intersection);
};

export function mapSearchContextToProductsGetParams(
    searchContext: ISearchContextOptions | null | undefined,
    searchTerm: string | undefined,
): Pick<IProductsGetParams, 'query' | 'title' | 'categoryName' | 'sku'> {
    switch (searchContext) {
        case ISearchContextOptions.anything:
            return {
                query: searchTerm || undefined,
            };
        case ISearchContextOptions.productName:
            return {
                title: searchTerm || undefined,
            };
        case ISearchContextOptions.category:
            return {
                categoryName: searchTerm || undefined,
            };
        case ISearchContextOptions.sku:
            return {
                sku: searchTerm || undefined,
            };
        default:
            return {
                title: searchTerm || undefined,
            };
    }
}

export function mapSelectedProductIdsToTags(
    selectedProductIds: string[],
    products: IProductListProduct[],
) {
    // Convert array to map for quicker access
    const productsMap: Record<string, IProductListProduct> = products.reduce(
        (acc, item) => ({ ...acc, [item.id]: item }),
        {},
    );

    const productsTags = selectedProductIds
        .map((id) => productsMap[id])
        .filter((product) => !!product)
        .map((product) => product.tags);

    return productsTags;
}

export enum ProductsAvailableColumns {
    image = 'image',
    name = 'name',
    category = 'category',
    vendor = 'vendor',
    created = 'created',
    modified = 'modified',
    upc = 'upc',
    sku = 'sku',
    priceRange = 'priceRange',
    stock = 'stock',
    labels = 'labels',
}

export const availableColumnsProducts = [
    ProductsAvailableColumns.image,
    ProductsAvailableColumns.name,
    ProductsAvailableColumns.category,
    ProductsAvailableColumns.vendor,
    ProductsAvailableColumns.created,
    ProductsAvailableColumns.modified,
    ProductsAvailableColumns.sku,
    ProductsAvailableColumns.upc,
    ProductsAvailableColumns.priceRange,
    ProductsAvailableColumns.stock,
    ProductsAvailableColumns.labels,
];

export const productsColumnsMap: IColumnsMap<IProductsColumns> = {
    name: {
        label: 'Product Name',
        isNotSelectable: true,
        width: 360,
        isDefault: true,
        sortOptions: {
            sortKey: 'title',
        },
    },
    image: {
        label: 'Image',
        hasHiddenLabel: true,
        width: 60,
        flexShrink: 0,
        flexGrow: 0,
        isDefault: true,
    },
    category: {
        label: 'Category',
        shouldHideOnMobile: true,
        isDefault: true,
        sortOptions: {
            sortKey: 'category',
            shouldAddDefaultSort: true,
        },
    },
    vendor: {
        label: 'Vendor',
        shouldHideOnMobile: true,
        sortOptions: {
            sortKey: 'vendor',
            shouldAddDefaultSort: true,
        },
    },
    upc: {
        label: 'UPC',
        width: 150,
        shouldHideOnMobile: true,
    },
    sku: {
        label: 'SKU',
        width: 150,
        shouldHideOnMobile: true,
    },
    created: {
        label: 'Created',
        shouldHideOnMobile: true,
        sortOptions: {
            sortKey: 'created',
            shouldReverseSortDirection: true,
        },
    },
    modified: {
        label: 'Modified',
        shouldHideOnMobile: true,
        sortOptions: {
            sortKey: 'modified',
            shouldReverseSortDirection: true,
        },
    },
    priceRange: {
        label: 'Price',
        shouldHideOnMobile: true,
        sortOptions: {
            sortKey: 'price',
            shouldAddDefaultSort: true,
            shouldReverseSortDirection: true,
        },
    },
    stock: {
        label: 'Stock',
        shouldHideOnMobile: true,
        width: 80,
        flexShrink: 0,
        flexGrow: 0,
        isDefault: true,
        sortOptions: {
            sortKey: 'quantity_in_stock',
            shouldAddDefaultSort: true,
        },
    },
    labels: {
        label: 'Labels',
        isNotSelectable: true,
        hasHiddenLabel: true,
        width: 76,
        flexShrink: 0,
        flexGrow: 0,
        isDefault: true,
    },
};

export const defaultSortString = '-modified';

export const mapSortToSortString = (
    sortBy: ProductsAvailableColumns,
    sortDirection: SortDirectionType,
) => {
    const { sortOptions } = productsColumnsMap[sortBy];

    if (!sortOptions) return;

    const sortDirectionString = sortDirection === 'ASC' ? '' : '-';

    const addDefaultSortString = `,${defaultSortString}`;
    const sortByString =
        sortOptions.sortKey +
        (sortOptions.shouldAddDefaultSort ? addDefaultSortString : '');

    return `${sortDirectionString}${sortByString}`;
};
