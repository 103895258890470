import { IProductData } from 'features/products/types';
import { IPropFields as GeneralSectionData } from 'features/products/components/GeneralSection/GeneralSection.types';
import { getInitialGeneralData } from 'features/products/components/GeneralSection/GeneralSection.utils';
import { IPropFields as AvailabilitySectionData } from 'features/products/components/AvailabilitySection/AvailabilitySection.types';
import { getInitialAvailabilityData } from 'features/products/components/AvailabilitySection/AvailabilitySection.utils';
import { IPropFields as ShippingSectionData } from 'features/products/components/ShippingSection/ShippingSection.types';
import { getInitialShippingData } from 'features/products/components/ShippingSection/ShippingSection.utils';
import { IAvailabilitySectionChannelData } from 'features/common/types/pricingSectionChannelData.type';
import {
    duplicateChannelsAvailability,
    duplicateImages,
    IChannel,
} from 'features/common';

import { IProductDuplicationState } from './ProductDuplication.types';

const prepareNewProductGeneralData = async (
    productName: string,
    dataToCopy: IProductDuplicationState,
    generalData: GeneralSectionData,
): Promise<GeneralSectionData> => {
    const newData: GeneralSectionData = getInitialGeneralData();

    newData.name = productName;
    newData.tags = [...generalData.tags];

    if (dataToCopy.variants && generalData.hasVariants) {
        newData.hasVariants = true;
        newData.productOptions = [...generalData.productOptions];
    }

    if (dataToCopy.images) {
        // we need to upload new images created from the old ones
        newData.images = await duplicateImages(generalData.images);
    }

    return newData;
};

const prepareNewProductAvailabilityData = async (
    dataToCopy: IProductDuplicationState,
    availabilityData: AvailabilitySectionData,
    allChannels: IChannel[],
): Promise<AvailabilitySectionData> => {
    const allChannelsIds = allChannels.map(({ id }) => String(id));
    const newData: AvailabilitySectionData =
        getInitialAvailabilityData(allChannels);

    newData.channelsAvailability = duplicateChannelsAvailability(
        allChannelsIds,
        availabilityData.channelsAvailability,
    );
    // copy channels with or without prices
    newData.channels =
        newData.channelsAvailability.map<IAvailabilitySectionChannelData>(
            (channelId) => {
                const availabilityChannelData = availabilityData.channels.find(
                    ({ id }) => channelId === id,
                );

                if (availabilityChannelData) {
                    return {
                        ...availabilityChannelData,
                        ...(dataToCopy.prices
                            ? {}
                            : {
                                  comparePrice: 0,
                                  price: 0,
                              }),
                    };
                }

                const channelData = allChannels.find(
                    ({ id }) => channelId === String(id),
                );
                return {
                    id: channelData?.id ? String(channelData.id) : '',
                    channelName: channelData?.channelName ?? '',
                    displayName: channelData?.displayName ?? '',
                    formData: {
                        price: 0,
                        comparePrice: 0,
                        comparePriceErrorMessage: '',
                        priceErrorMessage: '',
                    },
                    openPriceAllowed: !!channelData?.openPriceAllowed,
                };
            },
        );

    if (dataToCopy.prices) {
        newData.price = availabilityData.price;
        newData.isSamePrice = availabilityData.isSamePrice;
        newData.compareAtPrice = availabilityData.compareAtPrice;
        newData.costPerItem = availabilityData.costPerItem;
    }

    if (dataToCopy.stock) {
        newData.itemsInStock = availabilityData.itemsInStock;
    }

    if (dataToCopy.variants) {
        newData.hasVariants = availabilityData.hasVariants;
        newData.variants = await Promise.all(
            availabilityData.variants.map(async (variant) => {
                const images = await duplicateImages(variant.images);
                const quantity = dataToCopy.stock ? variant.quantity : '';
                const channels = duplicateChannelsAvailability(
                    allChannelsIds,
                    variant.channels.map(String),
                );
                const channelsPrice = dataToCopy.prices
                    ? variant.channelsPrice || {}
                    : undefined;

                return {
                    ...variant,
                    id: null,
                    isExisting: false,
                    sku: '',
                    upc: '',
                    images,
                    channels,
                    channelsPrice,
                    quantity,
                };
            }),
        );
    }

    return newData;
};

const prepareNewProductShippingData = (shippingData: ShippingSectionData) => {
    const newData: ShippingSectionData = getInitialShippingData();

    newData.unit = shippingData.unit;
    newData.value = shippingData.value;

    return newData;
};

export const prepareDuplicatedProductData = async (
    productName: string,
    dataToCopy: IProductDuplicationState,
    productData: IProductData,
    allChannels: IChannel[],
): Promise<IProductData> => {
    const general = await prepareNewProductGeneralData(
        productName,
        dataToCopy,
        productData.general,
    );
    const availability = await prepareNewProductAvailabilityData(
        dataToCopy,
        productData.availability,
        allChannels,
    );
    const shipping = prepareNewProductShippingData(productData.shipping);

    return {
        general,
        availability,
        shipping,
    };
};
