import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { logout } from 'features/common/utils/auth.utils';

import { createSelectors } from './utils';

type State = {
    isAuthenticated: boolean;
};

type Actions = {
    logoutUserAction: () => void;
};

const initialState: State = {
    // for the sake of tests, we are not Authenticated by default
    // for the sake of OKTA we are always Authenticated by default
    isAuthenticated: !globalThis.process?.env?.JEST_WORKER_ID,
};

const useAuthStore = create<State & { actions: Actions }>()(
    devtools(
        () => ({
            ...initialState,
            actions: {
                logoutUserAction: () => {
                    logout();
                },
            },
        }),
        { enabled: !import.meta.env.PROD && import.meta.env.MODE !== 'test' },
    ),
);

export const useAuthStoreSelectors = createSelectors(useAuthStore);
export const useAuthStoreActions = () => useAuthStoreSelectors.use.actions();
