import { Radio } from 'spoton-lib';
import clsx from 'clsx';

import { IPropTypes } from './RadioBlock.types';
import styles from './RadioBlock.module.scss';

export function RadioBlock({
    children,
    className,
    onChange,
    ...radioProps
}: IPropTypes) {
    const radioClass = clsx(
        styles.RadioBlock,
        radioProps.checked && styles.RadioBlock___checked,
        className,
    );
    const handleClick = () => onChange && onChange(radioProps.value);
    const handleChange = () => null;

    return (
        <div onClick={handleClick} className={radioClass}>
            <Radio
                {...radioProps}
                className={styles.Radio}
                onChange={handleChange}
            />
            {children}
        </div>
    );
}

export default RadioBlock;
