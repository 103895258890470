import { Text } from 'spoton-lib';

import { ReactComponent as ProductExportFailedImg } from 'features/common/assets/product_export_failed.svg';
import { NexusModal } from 'features/common';

import { IPropTypes } from './ExportModalFailed.types';
import styles from './ExportModalFailed.module.scss';

export function ExportModalFailed({ isOpen, onClose, errors }: IPropTypes) {
    const hasEmailError = errors && errors.email?.length;
    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Something went wrong!" />
                <Text className={styles.Modal_paragraph}>
                    We couldn’t export your product. Catalog export failed,
                    {hasEmailError
                        ? ' please enter valid email address.'
                        : ' please try again later.'}
                </Text>
                <div className={styles.Modal_imageWrapper}>
                    <ProductExportFailedImg />
                </div>
                <NexusModal.Footer submitTitle="Got it!" onSubmit={onClose} />
            </div>
        </NexusModal>
    );
}

export default ExportModalFailed;
