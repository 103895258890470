import { useEffect, useRef, useState } from 'react';

import {
    IDuplicationCheck,
    IDuplicationCheckPost,
} from 'features/common/api/DuplicationCheck.types';
import { AxiosResponse } from 'features/common/utils/axios.utils';
import { DuplicationCheckApi } from 'features/common/api';
import { useDebouncedState } from 'features/common/utils';

export type BaseValidatedEntity = Record<string, any>;
export type ComparableEntityAction = <
    DuplicationParams extends IDuplicationCheckPost,
>(
    params: DuplicationParams,
) => Promise<AxiosResponse<IDuplicationCheck>>;
export type ComparableEntityType = 'product' | 'service';

const comparableEntityActions: Record<
    ComparableEntityType,
    ComparableEntityAction
> = {
    product: DuplicationCheckApi.postProductDuplicationCheck,
    service: DuplicationCheckApi.postServiceDuplicationCheck,
};

export const checkIsEntityFieldValueUnique = async (
    entityType: ComparableEntityType,
    fieldValue: IDuplicationCheckPost,
    excludedValue = '',
    invalidValues: string[] = [],
): Promise<boolean> => {
    // Consider empty string as unique value.
    // Allow to exclude value from checking (while editing product/service).
    if (!fieldValue.value || fieldValue.value === excludedValue) {
        return true;
    }
    // Allow to consider list of values as incorrect. Exclude values from checking.
    if (invalidValues.includes(fieldValue.value)) {
        return false;
    }

    const checkAction = comparableEntityActions[entityType];
    // @TODO It should/will be handled on API side. Waiting for OM-2246
    const uppercasedFieldValue = {
        ...fieldValue,
        field: fieldValue.field.toUpperCase(),
    };
    const response = await checkAction(uppercasedFieldValue);

    return !response.data.exists;
};

export const useIsUniqueEntityValue = <
    ValidatedEntity extends BaseValidatedEntity = BaseValidatedEntity,
>(
    entityType: ComparableEntityType,
    field: keyof ValidatedEntity,
    value: string,
    duplicates: string[] = [],
    debounceTimeout = 500,
): boolean => {
    // Memoize initial value and do not change it. That protects from false positive while editing product/service.
    const initialValueRef = useRef(value);
    const duplicatesRef = useRef(duplicates);
    const [currentValue, setCurrentValue] = useDebouncedState(
        value,
        debounceTimeout,
    );
    const [isUnique, setIsUnique] = useState(true);

    useEffect(() => {
        duplicatesRef.current = duplicates;
    }, [duplicates]);

    useEffect(() => {
        setCurrentValue(value);
    }, [setCurrentValue, value]);

    useEffect(() => {
        checkIsEntityFieldValueUnique(
            entityType,
            {
                value: currentValue,
                field: field as string,
            },
            initialValueRef.current,
            duplicatesRef.current,
        ).then(setIsUnique);
    }, [entityType, field, currentValue]);

    return isUnique;
};
