import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import {
    IMerchantLocationsGetParams,
    IMerchantLocationsGetResponse,
    IMerchantLocationGetByIdResponse,
    IMerchantLocationPutPayload,
    IMerchantLocationPutResponse,
    IMerchantLocationPatchResponse,
    IMerchantLocationDeleteResponse,
    IMerchantLocationPatchParams,
    IMerchantLocationBulkEnableLoyaltyParams,
} from './MerchantLocations.types';

/** Gets a list of merchantLocations */
export function merchantLocationsGet({
    page = 1,
}: IMerchantLocationsGetParams) {
    return axios
        .get<IMerchantLocationsGetResponse>(`/api/v1/merchant-locations/`, {
            params: {
                page,
            },
        })
        .then(getData);
}

/**
 * Gets a merchantLocation by id
 * `GET: /api/v1/merchant-locations/${id}/`
 */
export function merchantLocationGetById(id?: string) {
    return axios
        .get<IMerchantLocationGetByIdResponse>(
            `/api/v1/merchant-locations/${id}/`,
        )
        .then(getData);
}

/** Updates a model*/
export function merchantLocationUpdate(
    id: string,
    merchantLocation: IMerchantLocationPutPayload,
): Promise<AxiosResponse<IMerchantLocationPutResponse>> {
    return axios.put<IMerchantLocationPutResponse>(
        `/api/v1/merchant-locations/${id}/`,
        merchantLocation,
    );
}

/** Patches a model*/
export function merchantLocationPatch({
    id,
    ...payload
}: IMerchantLocationPatchParams) {
    return axios
        .patch<IMerchantLocationPatchResponse>(
            `/api/v1/merchant-locations/${id}/`,
            payload,
        )
        .then(getData);
}

/** Deletes a model */
export function merchantLocationDelete(
    id: string,
): Promise<AxiosResponse<IMerchantLocationDeleteResponse>> {
    return axios.delete<IMerchantLocationDeleteResponse>(
        `/api/v1/merchant-locations/${id}/`,
    );
}

/** Enables loyalty for all products and services */
export function merchantLocationBulkEnableLoyalty({
    id,
}: IMerchantLocationBulkEnableLoyaltyParams) {
    return axios.post(
        `/api/v1/merchant-locations/${id}/enable-loyalty-for-all-products/`,
    );
}
