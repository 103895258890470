// eslint-disable-next-line no-restricted-imports
import { datadogRum } from '@datadog/browser-rum';

import { getConfigVar } from './config.utils';

const apiURL = (getConfigVar('REACT_APP_OMNICHANNEL_API') as string).replace(
    /\/$/,
    '',
);
const omnishippingApiURL = (
    getConfigVar('REACT_APP_OMNISHIPPING_API') as string
).replace(/\/$/, '');

export class ErrorLogger {
    static #instance: ErrorLogger;

    static #isEnabled = () => {
        const isProductionOrStaging = ['prod', 'staging'].includes(
            getConfigVar('REACT_APP_DATADOG_ENV') as string,
        );

        return isProductionOrStaging;
    };

    static #isTestEnvironment = () => {
        return (getConfigVar('NODE_ENV') as string) === 'test';
    };

    private constructor() {
        if (ErrorLogger.#isEnabled()) {
            datadogRum.init({
                applicationId: getConfigVar(
                    'REACT_APP_DATADOG_APP_ID',
                ) as string,
                clientToken: getConfigVar(
                    'REACT_APP_DATADOG_CLIENT_TOKEN',
                ) as string,
                site: 'datadoghq.com',
                service: 'omnichannel-web',
                env: getConfigVar('REACT_APP_DATADOG_ENV') as string,
                version: getConfigVar('REACT_APP_VERSION') as string,
                sampleRate: 50,
                trackInteractions: false,
                allowedTracingOrigins: [apiURL, omnishippingApiURL],
                beforeSend: (event) => {
                    if (
                        event.type === 'error' &&
                        event.error.source === 'console' &&
                        event.error.message.startsWith('ErrorLogger:')
                    ) {
                        return false;
                    }
                },
            });
        }
    }

    static init = () => {
        if (!ErrorLogger.#instance) {
            ErrorLogger.#instance = new ErrorLogger();
        }
    };

    static reportError = (error: unknown, context?: object) => {
        if (ErrorLogger.#isEnabled()) {
            if (!ErrorLogger.#instance) {
                throw new Error(
                    'Make sure that ErrorLogger.init is called before ErrorLogger.reportError method',
                );
            }
            datadogRum.addError(error, context);
        }
        if (!ErrorLogger.#isTestEnvironment()) {
            console.error('ErrorLogger:', error);
        }
    };
}
