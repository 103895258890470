export interface IPropTypes {
    onClose: () => void;
}

export enum ImportModalView {
    loader = 'loader',
    form = 'form',
    started = 'started',
    pending = 'pending',
    completed = 'completed',
    failed = 'failed',
}

export enum ProductsImportContentType {
    csv = 'text/csv',
    xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
