import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { getFieldErrorName as defaultGetFieldErrorName } from './FieldErrorScroller.utils';
import { IPropsTypes } from './FieldErrorScroller.types';

// Has be to a component as it has to have an access to formik (must be placed inside FormikProvider)

export function ScrollToFieldError<T>({
    scrollBehavior,
    getFieldErrorName = defaultGetFieldErrorName,
}: IPropsTypes<T>) {
    const { submitCount, isValid, errors } = useFormikContext<T>();

    useEffect(() => {
        if (isValid) return;

        const firstFieldErrorName = getFieldErrorName(errors);
        if (!firstFieldErrorName) return;

        const element = document.querySelector(
            `input[name='${firstFieldErrorName}']`,
        );

        element?.scrollIntoView({
            behavior: scrollBehavior?.behavior || 'smooth',
            block: scrollBehavior?.block || 'center',
        });

        // needed to run only when submitting the form
    }, [submitCount]);

    return null;
}
