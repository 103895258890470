import { Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';

import styles from './DraggableWrapper.module.scss';
import { IPropsType } from './DraggableWrapper.types';

export function DraggableWrapper({
    index,
    className,
    children,
    ...draggableProps
}: IPropsType) {
    return (
        <Draggable index={index} {...draggableProps}>
            {(provided, { isDragging }) => (
                <div
                    className={clsx(className, isDragging && styles.Dragging)}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                >
                    {children(provided)}
                </div>
            )}
        </Draggable>
    );
}
