/* eslint-disable react/display-name */

import {
    IFilterValue,
    IFiltersSidebarContentType,
} from 'features/common/types';
import {
    FiltersSidebarTags,
    FiltersSidebarCategory,
    FiltersSidebarChannel,
} from 'features/common/components';
import FiltersSidebarLowStock from 'features/common/components/FiltersSidebarLowStock';
import {
    ILowStockValue,
    LowStockLabel,
} from 'features/common/components/FiltersSidebarLowStock/FiltersSidebarLowStock.types';
import { LowStockIcon } from 'features/common/assets/icons';

import { filterPage, IFilterConfig, IFilterPage } from './FiltersSidebar.types';

export const filtersContentTypeString = {
    [IFiltersSidebarContentType.products]: 'product',
    [IFiltersSidebarContentType.services]: 'service',
};

export const getSingleLabel = (labels: string[]): string =>
    labels.filter(Boolean).join(', ') || 'Not defined';

export const getLowStockLabel = (lowStockValue: ILowStockValue): string =>
    lowStockValue ? LowStockLabel[lowStockValue] : 'Not defined';

export const getValueLabel = (value: null | IFilterValue): string => {
    if (!value) {
        return '';
    }

    return value.label;
};

export const filterPageTitles: Record<IFilterPage, string> = {
    home: 'Home',
    channels: 'Channel',
    categories: 'Category',
    tags: 'Tags',
    lowStock: 'Low Stock',
};

export const defaultFiltersState = {
    location: null,
    categories: [],
    channels: [],
    tags: [],
    lowStock: null,
};

export const filtersConfigs: Partial<Record<IFilterPage, IFilterConfig>> = {
    tags: {
        pageId: filterPage.tags,
        icon: {
            name: 'DealIcon',
            alt: 'Tags filter icon',
        },
        getIsClear: (state) => state.tags.length === 0,
        getSubtitle: (state) => getSingleLabel(state.tags),
        getContent: (state, setState, contentType) => (
            <FiltersSidebarTags
                onTagAdd={(tag) =>
                    setState((state) => ({
                        ...state,
                        tags: [...state.tags, tag],
                    }))
                }
                onTagRemove={(index) =>
                    setState((state) => ({
                        ...state,
                        tags: state.tags.filter(
                            (_, tagIndex) => tagIndex !== index,
                        ),
                    }))
                }
                onClear={() =>
                    setState((state) => ({
                        ...state,
                        tags: [],
                    }))
                }
                tagList={state.tags}
                contentType={contentType}
            />
        ),
    },
    categories: {
        pageId: filterPage.categories,
        icon: {
            name: 'CategoryIcon',
            alt: 'Categories filter icon',
        },
        getIsClear: (state) => state.categories.length === 0,
        getSubtitle: (state) =>
            getSingleLabel(state.categories.map(getValueLabel)),
        getContent: (currentState, setState, contentType) => (
            <FiltersSidebarCategory
                onChange={(categories) => {
                    setState((state) => ({
                        ...state,
                        categories,
                    }));
                }}
                selectedCategories={currentState.categories}
                contentType={contentType}
            />
        ),
    },
    channels: {
        pageId: filterPage.channels,
        icon: {
            name: 'ShareAllIcon',
            alt: 'Channels filter icon',
        },
        getIsClear: (state) => state.channels.length === 0,
        getSubtitle: (state) =>
            getSingleLabel(state.channels.map(getValueLabel)),
        getContent: (currentState, setState, contentType) => (
            <FiltersSidebarChannel
                onChange={(channels) => {
                    setState((state) => ({
                        ...state,
                        channels,
                    }));
                }}
                selectedChannels={currentState.channels}
                contentType={contentType}
            />
        ),
    },
    lowStock: {
        pageId: filterPage.lowStock,
        icon: {
            name: 'LowStockIcon',
            alt: 'Low stock filter icon',
            iconSet: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                LowStockIcon,
            },
        },
        getIsClear: (state) => state.lowStock === null,
        getSubtitle: (state) => getLowStockLabel(state.lowStock),
        getContent: (currentState, setState, contentType) => (
            <FiltersSidebarLowStock
                onChange={(lowStock) => {
                    setState((state) => ({
                        ...state,
                        lowStock,
                    }));
                }}
                selectedLowStock={currentState.lowStock}
                contentType={contentType}
            />
        ),
    },
};
