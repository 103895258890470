import { removeEmpty } from 'features/common/utils';
import { IAttributesGetParams } from 'features/attributes/api/Attributes.types';

export const attributeKeys = {
    all: [{ scope: 'attributes' }] as const,
    attribute: (id: string | null) =>
        [{ ...attributeKeys.all[0], id }] as const,
    lists: () => [{ ...attributeKeys.all[0], entity: 'lists' }] as const,
    list: ({ phrase, page }: IAttributesGetParams) =>
        [
            removeEmpty({
                ...attributeKeys.lists()[0],
                phrase,
                page,
            }),
        ] as const,
    infinityList: () =>
        [{ ...attributeKeys.all[0], entity: 'infinity' }] as const,
    infinity: (phrase?: string) =>
        [removeEmpty({ ...attributeKeys.infinityList()[0], phrase })] as const,
};
