import * as yup from 'yup';

import { IRateByWeightData } from './RateByWeightForm.types';

const testHasError = yup
    .bool()
    .test('valid', 'Error inside weights table', (value) => !value);

export const validationSchema: yup.SchemaOf<
    Omit<IRateByWeightData, 'weights'>
> = yup.object().shape({
    rateName: yup.string().required('Please add rate name'),
    deliveryTime: yup.string(),
    weights: yup.array().of(
        yup.object().shape({
            isToWeightError: testHasError,
            isPriceError: testHasError,
        }),
    ),
    withMinSpend: yup.bool(),
    minSpendPrice: yup
        .number()
        .nullable()
        .when('withMinSpend', {
            is: true,
            then: yup
                .number()
                .min(1, 'Minimum spend must be greater than or equal to 1')
                .required('Minimum spend is required')
                .nullable(),
        }),
});
