import { useState, useCallback, useEffect } from 'react';

import { getValidationError, ValidationMode } from 'features/common/utils';
import { useDebounce } from 'features/common/hooks/useDebounce';

type UseGetValidationHandler = (value: string) => void;

/**
 * Single value debounced validation as hook.
 *
 * @param validators  - List of validators to execute (defined in validators.utils.ts).
 * @param timeout - Debounce timeout for validation.
 * @param validationMode - used by validators.utils/getValidationError
 *
 * @returns
 * [
 *      validationError  - validation error or empty string,
 *      handler - function which triggers validation,
 *      isAwaitingValidation - describes is validation queueed,
 * ]
 */
export const useGetValidationError = <
    Validators extends CallableFunction[] = CallableFunction[],
>(
    validators: Validators,
    timeout = 500,
    validationMode = ValidationMode.parallel,
): [string, UseGetValidationHandler, boolean] => {
    const [validationError, setValidationError] = useState('');
    const [isAwaitingValidation, setIsAwaitingValidation] = useState(false);

    const debouncedHandler = useDebounce<UseGetValidationHandler>(
        async (value) => {
            const error = await getValidationError(
                value,
                validators,
                validationMode,
            );

            setValidationError(error);
            setIsAwaitingValidation(false);
        },
        timeout,
    );

    const handler = useCallback<UseGetValidationHandler>(
        (value) => {
            setIsAwaitingValidation(true);
            debouncedHandler(value);
        },
        [debouncedHandler],
    );

    useEffect(() => {
        // Cancel the handler if the component is being unmounted (prevents warning about setting state of an unmounted component in tests)
        return () => {
            debouncedHandler.cancel();
        };
    }, [debouncedHandler]);

    return [validationError, handler, isAwaitingValidation];
};
