import { generatePathFromRoot } from 'features/common/utils/routing.utils';

import { shippingPaths } from './shipping.paths';

export const methodsMountPath = shippingPaths.shippingMethods;

export const methodsLocalPaths = {
    root: '/',
    onboarding: '/onboarding',
    list: '/list',
    selectShippingMethod: '/select-shipping-method',
    flatRates: '/flat-rates',
    rateByWeight: '/rate-by-weight',
    freeShipping: '/free-shipping',
    calculatedRates: '/calculated-rates',
    shippoSettings: '/shippo-settings',
} as const;

export const methodsPaths = {
    root: generatePathFromRoot(methodsMountPath),
    onboarding: generatePathFromRoot(
        methodsMountPath,
        methodsLocalPaths.onboarding,
    ),
    list: generatePathFromRoot(methodsMountPath, methodsLocalPaths.list),
    selectShippingMethod: generatePathFromRoot(
        methodsMountPath,
        methodsLocalPaths.selectShippingMethod,
    ),
    flatRates: generatePathFromRoot(
        methodsMountPath,
        methodsLocalPaths.flatRates,
    ),
    rateByWeight: generatePathFromRoot(
        methodsMountPath,
        methodsLocalPaths.rateByWeight,
    ),
    freeShipping: generatePathFromRoot(
        methodsMountPath,
        methodsLocalPaths.freeShipping,
    ),
    calculatedRates: generatePathFromRoot(
        methodsMountPath,
        methodsLocalPaths.calculatedRates,
    ),
    shippoSettings: generatePathFromRoot(
        methodsMountPath,
        methodsLocalPaths.shippoSettings,
    ),
} as const;
