import { Button, Text } from 'spoton-lib';

import { IPropTypes } from './MenuPopup.types';
import styles from './MenuPopup.module.scss';

export function MenuPopup(props: IPropTypes) {
    const { text, onClick, isOpen } = props;

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.MenuPopup}>
            <Text>{text}</Text>
            <Button
                variant="secondary"
                onClick={onClick}
                className={styles.MenuPopup_button}
            >
                Got it!
            </Button>
        </div>
    );
}

export default MenuPopup;
