import { useEffect } from 'react';
import { Dropdown, NumberFormat } from 'spoton-lib';

import { Section, WarningInfo } from 'features/common/components';
import { IDropdownOption } from 'features/common/types';

import styles from './ShippingSection.module.scss';
import { IPropTypes } from './ShippingSection.types';
import { isWeightFormatCorrect } from './ShippingSection.utils';

const weightUnits = ['lb', 'oz', 'g', 'kg'];

const options: IDropdownOption[] = weightUnits.map((value) => ({
    label: value,
    value,
}));

const disableWeightUnitForMeasurements = weightUnits;

const blockDescription = `Set shipping weight for this product to ship using
carrier calculated shipping (Shippo). It is recommended to add a weight value
to your products. Not adding a weight value can make your shipping cost
inaccurate.`;

export function ShippingSection(props: IPropTypes) {
    const {
        unit,
        unitOfMeasure,
        onUpdateWeight,
        onUpdateUnit,
        value,
        isLoading,
        errors,
        warningText,
        openWeightWarningModal,
    } = props;

    const isDisabledDropdown =
        disableWeightUnitForMeasurements.includes(unitOfMeasure);
    const defaultValue = options.find(
        (option) => option.value === (unit || unitOfMeasure),
    );

    useEffect(() => {
        if (isDisabledDropdown && unitOfMeasure) {
            onUpdateUnit(unitOfMeasure);
        }
    }, [isDisabledDropdown, unitOfMeasure]);

    const headerComponent = () => {
        if (!warningText) return;

        return (
            <WarningInfo containerClassName={styles.WarningInfo}>
                <WarningInfo.Text>{warningText}</WarningInfo.Text>
                <WarningInfo.Button onClick={openWeightWarningModal}>
                    More info
                </WarningInfo.Button>
            </WarningInfo>
        );
    };

    return (
        <Section
            headerComponent={headerComponent()}
            title="Shipping"
            data-testid="ShippingSection"
            description={blockDescription}
            className={styles.Container}
        >
            <div className={styles.WeightInput}>
                <NumberFormat
                    disabled={isLoading}
                    label="Weight"
                    placeholder="E.g. 0.01"
                    onValueChange={onUpdateWeight}
                    className={styles.WeightInput_input}
                    decimalScale={2}
                    value={value ?? ''}
                    role="input"
                    secondaryCondition={
                        errors.value || 'This is per item in stock'
                    }
                    isValid={!errors.value.length}
                    isAllowed={isWeightFormatCorrect}
                />
                <Dropdown
                    isDisabled={isDisabledDropdown || isLoading}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    searchable={false}
                    withDividers={false}
                    value={defaultValue || options[0]}
                    options={options}
                    onChange={(option: IDropdownOption) =>
                        onUpdateUnit(option.value)
                    }
                    className={styles.WeightInput_dropdown}
                />
            </div>
        </Section>
    );
}

export default ShippingSection;
