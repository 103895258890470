interface IFormatData {
    serviceType: string;
}

export const sortByKeys = <Data extends IFormatData>(
    formatData: Data[],
    orderArray: string[],
): Data[] => {
    return formatData.sort((a, b) => {
        const aIndex = orderArray.indexOf(a.serviceType);
        const bIndex = orderArray.indexOf(b.serviceType);

        return aIndex > bIndex ? 1 : -1;
    });
};

// Alphanumeric sort with natural interpretation of numbers inside strings
// Default lexicographic order [10,2,3] becomes natural order [2,3,10]
export const naturalSort = (arr: string[]): string[] => {
    return [...arr].sort((a, b) =>
        a.localeCompare(b, undefined, {
            numeric: true,
        }),
    );
};

type MatchingProperty<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: P;
};

export const naturalSortByProperty = <
    T extends Record<string, any>,
    A extends MatchingProperty<T, string>,
>(
    arr: T[],
    propertyName: A,
): T[] => {
    return [...arr].sort((a, b) =>
        a[propertyName].localeCompare(b[propertyName], undefined, {
            numeric: true,
        }),
    );
};
