import { useQuery } from 'features/common/serverStateHandler';
import {
    useGetCurrentUser,
    useGetMerchantLocationId,
    useGetMerchantLocationSettings,
    useGetCurrentMerchantLocation,
} from 'features/common/services';
import { getUserInitials } from 'features/common/utils';
import { appLinksGet } from 'features/omnichannel/api/AppLinks.data';

export const useAppInitialLoad = () => {
    const {
        merchantLocationId,
        isMerchantLocationIdError,
        isMerchantLocationIdLoading,
        refetchMerchantLocationId,
    } = useGetMerchantLocationId();

    const {
        currentUser,
        isCurrentUserError,
        isCurrentUserLoading,
        refetchCurrentUser,
    } = useGetCurrentUser();

    const {
        data: menuLinks,
        isLoading: isMenuLinksLoading,
        isError: isMenuLinksError,
        refetch: refetchMenuLinks,
    } = useQuery([{ scope: 'menuLinks' }], appLinksGet, {
        enabled: !!merchantLocationId,
        staleTime: Infinity,
    });

    const {
        currentMerchantLocation,
        isCurrentMerchantLocationError,
        isCurrentMerchantLocationLoading,
        refetchCurrentMerchantLocation,
        isAppointmentsIntegrated,
    } = useGetCurrentMerchantLocation();

    const {
        merchantLocationSettings,
        isMerchantLocationSettingsLoading,
        isMerchantLocationSettingsError,
        refetchMerchantLocationSettings,
    } = useGetMerchantLocationSettings();

    const isError =
        isMerchantLocationIdError ||
        isCurrentUserError ||
        isMenuLinksError ||
        isCurrentMerchantLocationError ||
        isMerchantLocationSettingsError;

    const isLoading =
        isMerchantLocationIdLoading ||
        isCurrentUserLoading ||
        isCurrentMerchantLocationLoading ||
        isMerchantLocationSettingsLoading ||
        isMenuLinksLoading;

    const refetchInitialLoad = () => {
        refetchMenuLinks();
        refetchCurrentUser();
        refetchCurrentMerchantLocation();
        refetchMerchantLocationSettings();
    };

    return {
        merchantLocationId,
        merchantLocationSettings,
        currentUser,
        userName: currentUser
            ? `${currentUser.firstName} ${currentUser.lastName}`
            : '',
        userNameInitials: currentUser
            ? getUserInitials(currentUser.firstName, currentUser.lastName)
            : '',
        userAddress: currentMerchantLocation
            ? `${currentMerchantLocation.addressString} ${currentMerchantLocation.city}, ${currentMerchantLocation.state} ${currentMerchantLocation.postcode}`
            : '',
        menuLinks,
        currentMerchantLocation,
        isLoading,
        isError,
        isMerchantLocationIdError,
        isAppointmentsIntegrated,
        refetchMerchantLocationId,
        refetchInitialLoad,
    };
};
