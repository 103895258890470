import { SyntheticEvent, useCallback } from 'react';
import { Dropdown, Checkbox, NumberFormat, Banner, Text } from 'spoton-lib';
import clsx from 'clsx';

import {
    PlaceholderWrapper,
    SelectionDropdown,
    Container,
    Col,
    Row,
    IDropdownOption,
    useFlags,
    ToggleableSection,
} from 'features/common';
import { ProductVariantsAvailabilityGrid } from 'features/products/components';
import { PricingSectionChannel } from 'features/common/components/PricingSectionChannel';
import { Section } from 'features/common/components/Section';
import { quantityUnit } from 'features/common/redux/unitsOfMeasure/unitsOfMeasure.utils';
import { useUnitsOfMeasure } from 'features/common/services';
import { checkHasSomeChannelWithOpenPriceDisabled } from 'features/products/components/ProductPage/ProductPage.utils';

import styles from './AvailabilitySection.module.scss';
import { IPropTypes } from './AvailabilitySection.types';
import { areUnitsInStockCorrect } from './AvailabilitySection.utils';

export function AvailabilitySection(props: IPropTypes) {
    const {
        isLoading,
        isEditing,
        channels,
        isSamePrice,
        costPerItem,
        itemsInStock,
        channelsAvailability,
        priceUnit,
        price,
        compareAtPrice,
        locationActiveChannels,
        allChannels,
        hasVariants,
        variants,
        inactiveVariantOptions,
        isNegativeStockEnabled,
        isLowStockAlertEnabled,
        lowStockAlertQuantity,
        onChangeVariants,
        onChangeChannels,
        onChangeSamePrice,
        onChangeCostPerItem,
        onChangeItemsInStock,
        onChangeAvailability,
        onChangeUnit,
        onChangePrice,
        onChangeCompareAtPrice,
        onDeactivateVariant,
        onChangeLowStockAlertEnabled,
        onChangeLowStockAlertQuantity,
        errors,
    } = props;
    const { isOpenPriceFeatureAvailable, isLowStockAlertFeatureEnabled } =
        useFlags();

    const { enabledUnitsForDropdown: unitOptions, getSelectedDropdownOption } =
        useUnitsOfMeasure();

    const unit = getSelectedDropdownOption(priceUnit);

    const handleOnChangeCheckbox = (
        event: SyntheticEvent<HTMLInputElement>,
    ) => {
        const isChecked = (event.target as HTMLInputElement).checked;

        onChangeSamePrice(isChecked);
    };

    const handleOnChangeChannelPrice = useCallback(
        (newPrice: number | null, channelIndex: number) => {
            const oldChannel = channels[channelIndex];
            const newChannels = [...channels];
            newChannels[channelIndex] = {
                ...oldChannel,
                price: newPrice,
            };
            onChangeChannels(newChannels);
        },
        [channels, onChangeChannels],
    );
    const hasEnabledUnits = Boolean(
        unitOptions.filter(({ value }) => value !== quantityUnit.name).length,
    );
    const areFractionalQuantitiesEnabled = unit.value !== quantityUnit.name;

    const isPriceRequired =
        isSamePrice &&
        (!hasVariants
            ? checkHasSomeChannelWithOpenPriceDisabled(
                  locationActiveChannels,
                  channelsAvailability,
              )
            : variants.some((variant) =>
                  checkHasSomeChannelWithOpenPriceDisabled(
                      locationActiveChannels,
                      variant.channels,
                  ),
              ));

    const lowStockAlertQuantityValue =
        lowStockAlertQuantity !== null
            ? areFractionalQuantitiesEnabled
                ? lowStockAlertQuantity
                : Math.trunc(lowStockAlertQuantity)
            : null;

    const renderAvailabilityWithVariants = () => {
        return (
            <div className={styles.AvailabilityWithVariants}>
                <Container fluid>
                    <Row className={styles.AvailabilityWithVariants_row}>
                        {hasEnabledUnits ? (
                            <Col width={190}>
                                <Dropdown
                                    menuPortalTarget={document.body}
                                    className={styles.UnitsOfMeasureDropdown}
                                    label="Unit of Measure"
                                    placeholder="Unit of Measure"
                                    primaryCondition="Required"
                                    value={unit}
                                    options={unitOptions}
                                    onChange={(option: IDropdownOption) => {
                                        onChangeUnit(option.value);
                                    }}
                                    isDisabled={isEditing}
                                    data-testid="AvailabilityWithVariantsUnits"
                                />
                            </Col>
                        ) : null}
                        <Col width={200}>
                            <NumberFormat
                                className={styles.VariantsGeneralPriceInput}
                                value={price ?? ''}
                                label="General Price"
                                onValueChange={onChangePrice}
                                variant="rounded"
                                prefix="$"
                                placeholder="$0.00"
                                decimalScale={2}
                                disabled={!isSamePrice}
                                data-testid="AvailabilityWithVariantsGeneralPrice"
                                secondaryCondition={errors.price}
                                isValid={!errors.price.length}
                                required
                                primaryCondition="Required"
                                isMultilineSecondaryCondition
                            />
                        </Col>
                        <Col className={styles.DifferentPriceColumn}>
                            <Checkbox
                                label="This product has different prices per variant or channels"
                                checked={!isSamePrice}
                                onChange={(event) =>
                                    onChangeSamePrice(
                                        !(event.target as HTMLInputElement),
                                    )
                                }
                                data-testid="AvailabilityWithVariantsDifferentPrice"
                            />
                        </Col>
                    </Row>
                </Container>
                <ProductVariantsAvailabilityGrid
                    channels={allChannels}
                    isDifferentPricingPerChannel={!isSamePrice}
                    onChange={onChangeVariants}
                    onDeactivateVariant={(variantKey) => {
                        const removedVariant = variants.find(
                            (variant) => variant.key === variantKey,
                        );

                        const newInactiveVariantOptions = [
                            ...inactiveVariantOptions,
                            ...[removedVariant?.variantOptions || []],
                        ];

                        onDeactivateVariant(
                            variantKey,
                            newInactiveVariantOptions,
                        );
                    }}
                    variants={variants}
                    areFractionalQuantitiesEnabled={
                        areFractionalQuantitiesEnabled
                    }
                    isNegativeStockEnabled={isNegativeStockEnabled}
                    lowStockThreshold={
                        isLowStockAlertEnabled
                            ? lowStockAlertQuantityValue
                            : null
                    }
                    errors={errors.variants}
                />
                <Container fluid style={{ padding: 0 }}>
                    <Row>
                        <Col sm={2}>
                            <NumberFormat
                                className={styles.InputWithTip}
                                disabled
                                placeholder="$0.00"
                                label="Cost per Unit"
                                onValueChange={onChangeCostPerItem}
                                variant="rounded"
                                prefix="$"
                                decimalScale={2}
                                secondaryCondition={
                                    errors.costPerItem ||
                                    'Customers will not see this.'
                                }
                                isValid={!errors.costPerItem.length}
                                isMultilineSecondaryCondition
                            />
                        </Col>
                        <Col sm={2}>
                            <NumberFormat
                                className={styles.InputWithTip}
                                value={compareAtPrice ?? ''}
                                placeholder="$0.00"
                                label="Compare at Price"
                                onValueChange={onChangeCompareAtPrice}
                                variant="rounded"
                                prefix="$"
                                decimalScale={2}
                                data-testid="AvailabilityVariantsCompareAtPrice"
                                secondaryCondition={
                                    errors.compareAtPrice ||
                                    'e.g. Classic Athletic Ball $35.00/ $20 Sale'
                                }
                                isValid={!errors.compareAtPrice.length}
                                isMultilineSecondaryCondition
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };

    const itemsInStockValue =
        areFractionalQuantitiesEnabled || !itemsInStock
            ? itemsInStock
            : Math.trunc(itemsInStock);

    const renderBaseAvailability = () => {
        return (
            <div className={styles.BaseAvailability}>
                <Checkbox
                    label="This product has the same price per channel"
                    checked={isSamePrice}
                    onChange={handleOnChangeCheckbox}
                    data-testid="AvailabilitySamePriceCheckbox"
                />
                <Container fluid>
                    <Row>
                        <Col>
                            <SelectionDropdown
                                menuPortalTarget={document.body}
                                label="Availability"
                                selected={channelsAvailability}
                                options={allChannels}
                                onOptionsChange={onChangeAvailability}
                                testId="Availability"
                                hasOneOptionAlwaysSelected
                                isValid={!errors.channelsAvailability}
                            />
                        </Col>
                        {hasEnabledUnits ? (
                            <Col>
                                <Dropdown
                                    menuPortalTarget={document.body}
                                    placeholder="Unit of Measure"
                                    value={unit}
                                    options={unitOptions}
                                    onChange={(option: IDropdownOption) => {
                                        onChangeUnit(option.value);
                                    }}
                                    isDisabled={isEditing}
                                    data-testid="AvailabilityUnitOfMeasure"
                                    label="Unit of Measure"
                                    primaryCondition="Required"
                                />
                            </Col>
                        ) : null}
                        <Col>
                            <NumberFormat
                                value={itemsInStockValue ?? ''}
                                label="Units in Stock"
                                onValueChange={onChangeItemsInStock}
                                variant="rounded"
                                data-testid="AvailabilityItemsInStock"
                                secondaryCondition={errors.itemsInStock}
                                isValid={!errors.itemsInStock?.length}
                                primaryCondition="Required"
                                isMultilineSecondaryCondition
                                isAllowed={(values) =>
                                    areUnitsInStockCorrect(
                                        isNegativeStockEnabled,
                                        areFractionalQuantitiesEnabled,
                                        values,
                                    )
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <NumberFormat
                                value={costPerItem ?? ''}
                                label="Cost per Unit"
                                placeholder="$0.00"
                                onValueChange={onChangeCostPerItem}
                                variant="rounded"
                                prefix="$"
                                decimalScale={2}
                                data-testid="AvailabilityCostPerItem"
                                secondaryCondition={
                                    errors.costPerItem ||
                                    'Customers will not see this.'
                                }
                                isValid={!errors.costPerItem.length}
                                isMultilineSecondaryCondition
                            />
                        </Col>
                        {isSamePrice && (
                            <Col>
                                <NumberFormat
                                    value={price ?? ''}
                                    label="Price"
                                    placeholder="$0.00"
                                    onValueChange={onChangePrice}
                                    variant="rounded"
                                    prefix="$"
                                    decimalScale={2}
                                    data-testid="AvailabilityPrice"
                                    secondaryCondition={errors.price}
                                    isValid={!errors.price.length}
                                    primaryCondition={
                                        isPriceRequired ? 'Required' : ''
                                    }
                                    isMultilineSecondaryCondition
                                />
                            </Col>
                        )}
                        <Col>
                            <NumberFormat
                                value={compareAtPrice ?? ''}
                                label="Compare at Price"
                                placeholder="$0.00"
                                onValueChange={onChangeCompareAtPrice}
                                variant="rounded"
                                prefix="$"
                                decimalScale={2}
                                data-testid="AvailabilityCompareAtPrice"
                                secondaryCondition={
                                    errors.compareAtPrice ||
                                    'e.g. Classic Athletic Ball $35.00/ $20 Sale'
                                }
                                isValid={!errors.compareAtPrice.length}
                                isMultilineSecondaryCondition
                            />
                        </Col>
                    </Row>
                </Container>
                {!isSamePrice ? (
                    <div className={styles.AvailabilitySection_channels}>
                        <Row>
                            {channels.map((channel, i) => (
                                <Col key={i} width="50%">
                                    <PricingSectionChannel
                                        key={i}
                                        title={channel.displayName}
                                        price={channel.price}
                                        isPriceRequired={
                                            !channel.openPriceAllowed
                                        }
                                        errorMessage={
                                            errors.channels[channel.id]
                                        }
                                        onChangeData={(newPrice) =>
                                            handleOnChangeChannelPrice(
                                                newPrice,
                                                i,
                                            )
                                        }
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <Section
            title="Availability"
            data-testid="AvailabilitySection"
            description="Set the price and say how many items are available."
            className={styles.AvailabilitySection}
        >
            <PlaceholderWrapper isLoading={isLoading}>
                {isOpenPriceFeatureAvailable && (
                    <Banner
                        message="To create a Variable Priced Item, set its price to empty. You can only sell Variable Priced Items on POS and add its price manually at checkout."
                        variant="informative"
                        className={styles.AvailabilitySection_banner}
                    />
                )}
                {hasVariants
                    ? renderAvailabilityWithVariants()
                    : renderBaseAvailability()}
                {isLowStockAlertFeatureEnabled && (
                    <ToggleableSection
                        isLoading={isLoading}
                        isOpen={isLowStockAlertEnabled}
                        switchLabel={
                            <Text type="p">
                                Notify me when this product is low in stock
                            </Text>
                        }
                        onToggle={onChangeLowStockAlertEnabled}
                        className={clsx(
                            styles.LowStockAlert,
                            isLowStockAlertEnabled &&
                                styles.LowStockAlert___open,
                        )}
                        openContentClassName={styles.LowStockAlert_content}
                        data-testid="LowStockAlertEnabledToggle"
                    >
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                <NumberFormat
                                    value={lowStockAlertQuantityValue ?? ''}
                                    label="Low Stock Alert Threshold"
                                    onValueChange={
                                        onChangeLowStockAlertQuantity
                                    }
                                    variant="rounded"
                                    data-testid="LowStockAlertThreshold"
                                    secondaryCondition={
                                        errors.lowStockAlertQuantity
                                            ? errors.lowStockAlertQuantity
                                            : "If your stock falls below this number, you'll receive an email"
                                    }
                                    isValid={!errors.lowStockAlertQuantity}
                                    decimalScale={3}
                                    isAllowed={(values) =>
                                        areUnitsInStockCorrect(
                                            isNegativeStockEnabled,
                                            areFractionalQuantitiesEnabled,
                                            values,
                                        )
                                    }
                                    placeholder="0"
                                    isMultilineSecondaryCondition
                                />
                            </Col>
                        </Row>
                    </ToggleableSection>
                )}
            </PlaceholderWrapper>
        </Section>
    );
}

export default AvailabilitySection;
