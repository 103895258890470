import { useMemo } from 'react';

import {
    useQuery,
    useMutation,
    useIsMutating,
} from 'features/common/serverStateHandler';
import { requestParams } from 'features/common/constants';
import { ShippingApi } from 'features/shipping/api';
import { formatCarrierServices } from 'features/shipping/utils/formatCarrierServices';
import { IShippingCarrier } from 'features/shipping/types/Shipping.types';

const queryKeys = {
    shippingCarriers: () => [{ scope: 'shippingCarriers' }] as const,
    toggleCarrierMutation: () => [{ scope: 'toggleCarrier' }] as const,
};

export const useShippingCarriers = () => {
    const { data, isLoading, ...rest } = useQuery(
        queryKeys.shippingCarriers(),
        () => ShippingApi.shippingCarriersGet(requestParams),
        {
            // needed to keep carrier isActive state up-to-date
            refetchOnWindowFocus: true,
            staleTime: 20_000,
            useErrorBoundary: true,
        },
    );

    // useMemo used in order to fix flickering in the UI
    const shippingCarriers = useMemo(() => {
        const shippingCarriers = data || [];

        // sort carriers -> isEnabled go to the top
        if (shippingCarriers?.length) {
            shippingCarriers.sort((carrierA) => {
                return carrierA.isEnabled ? -1 : 1;
            });
        }

        const formattedCarriers = shippingCarriers.map((carrier) => ({
            ...carrier,
            services: formatCarrierServices(carrier.services),
        }));

        return formattedCarriers;
    }, [data]);

    return {
        shippingCarriers,
        isLoadingShippingCarriers: isLoading,
        ...rest,
    };
};

export const useCarrierToggle = () => {
    const { mutateAsync, isLoading, ...rest } = useMutation(
        ShippingApi.toggleCarrier,
        {
            mutationKey: queryKeys.toggleCarrierMutation(),
            onSuccess: ({ queryHandler, variables }) => {
                const previousCarriers =
                    queryHandler.getQueryData<IShippingCarrier[]>(
                        queryKeys.shippingCarriers(),
                    ) || [];

                const updatedCarriers = previousCarriers.map((carrier) => {
                    if (
                        carrier.name.toLowerCase() ===
                        variables.carrier.toLocaleLowerCase()
                    ) {
                        return {
                            ...carrier,
                            isActive: variables.active,
                        };
                    }
                    return carrier;
                });

                return queryHandler.setQueryData(
                    queryKeys.shippingCarriers(),
                    updatedCarriers,
                );
            },
            isSuccessToast: false,
            formatErrorMessage: (_err, variables) =>
                `There was an error when trying to ${
                    variables.active ? 'activate' : 'deactivate'
                } the carrier.`,
        },
    );

    return {
        toggleCarrier: mutateAsync,
        isTogglingCarrier: isLoading,
        ...rest,
    };
};

export const useIsTogglingCarrier = () => {
    const mutationsCount = useIsMutating({
        queryKey: queryKeys.toggleCarrierMutation(),
    });

    return {
        isTogglingCarrier: !!mutationsCount,
    };
};
