import { useCallback, useState } from 'react';

import * as useFormHandlers from './useFormHandlers';

export interface IBaseUseFormStateShape {} // eslint-disable-line @typescript-eslint/no-empty-interface
export type BaseUseFormFunction = (...args: any[]) => any;

export type UpdateFormState<FormState extends IBaseUseFormStateShape> = (
    newStateFragment: Partial<FormState>,
) => void;

export type UpdateFormField<
    FormState extends IBaseUseFormStateShape,
    Field extends keyof FormState = keyof FormState,
> = (field: Field, value: unknown) => void;

export type FormStateListener<FormState extends IBaseUseFormStateShape> = (
    newState: FormState,
    currentState: FormState,
) => Partial<FormState> | void;

export type FormFieldListener<FormState extends IBaseUseFormStateShape> =
    UpdateFormField<FormState>;

export type FormFieldHandlerArgs<FormState extends IBaseUseFormStateShape> = {
    field: keyof FormState;
    state: FormState;
    updateField: UpdateFormField<FormState>;
    updateState: UpdateFormState<FormState>;
};

export interface IUseFormOptions<FormState extends IBaseUseFormStateShape> {
    onFieldChange?: FormFieldListener<FormState>;
    onStateChange?: FormStateListener<FormState>;
}

export type ExtendedInputHandler<
    InputHandler extends BaseUseFormFunction,
    FormState extends IBaseUseFormStateShape,
> = (
    args: Parameters<InputHandler>,
    handlerArgs: FormFieldHandlerArgs<FormState>,
) => void;
/**
    This hook is suppose to be used in case where we need to handle state of multiple fields,
        and we would like to have strong typing.

    It takes two arguments:
        * initial state (required) - object which fulfils FormState interface.
        * useFormOptions (optional) - object which might contain two listeners:
            * onStateChange - fired each time when state changes, receives new state as single argument.
            * onFieldChange - fired each time when field changes, receives field name and new value for that field as arguments.
            *                 NOTE! This listener will fire only if state is updated via `updateField`!

    It provides three properties:
        * state - current value of state.
        * stateUpdater - higher order function which accepts two arguments, name of field (field names are strictly typed),
        *                and update function.
                         Update function takes two arguments. 1st is array of arguments provided by parent component to listener.
                         2nd is updateState mentioned below.
                         This HoF returns new function, which is suppose to be passed directly to the input.
                         useFormHandlers.ts file contains predefined, commonly used handlers.
        * updateState - function which takes part of new state as single argument (strictly typed).
        * updateField - function which takes two arguments - name of field (strictly typed) and new value for state field.

    Example usage:

    const { state, stateUpdater } = useForm<FormStateShape>(initialFormState);
    const handleInput = stateUpdater<InputOnInput>('someField', useFormHandlers.handleInputOnChangeText);
    const handleCustom = stateUpdater<CustomHandler>(
        'otherField',
        (args: [Parameters<CustomHandler>], updateState, fieldName, state) => {
            // Do anything You want inside of this function, as long as this function fulfills type of CustomHandler.
            // updateState has to be called manually. 'fieldName' in this case has value of 'otherField'
            // 'state' is current state inside useForm hook.
        });

*/
// Allow to infer return type in order to avoid too generic typing.
export const useForm = <FormState extends IBaseUseFormStateShape>(
    initialState: FormState,
    { onStateChange, onFieldChange }: IUseFormOptions<FormState> = {},
) => {
    const [state, setState] = useState<FormState>(initialState);

    const updateState = useCallback<UpdateFormState<FormState>>(
        (newState) => {
            let combinedState = {
                ...state,
                ...newState,
            };

            const listenerStateChanges =
                onStateChange && onStateChange(combinedState, state);

            if (listenerStateChanges) {
                combinedState = {
                    ...combinedState,
                    ...listenerStateChanges,
                };
            }

            setState(combinedState);
        },
        [state, setState, onStateChange],
    );

    const updateField = useCallback<UpdateFormField<FormState>>(
        (field, value) => {
            updateState({
                [field]: value,
            } as Partial<FormState>);

            onFieldChange && onFieldChange(field, value);
        },
        [updateState, onFieldChange],
    );

    const stateUpdater = <InputHandler extends BaseUseFormFunction>(
        field: keyof FormState,
        extendedInputHandler: ExtendedInputHandler<
            InputHandler,
            FormState
        > = useFormHandlers.genericOnChange as InputHandler,
    ): InputHandler => {
        const inputHandler = ((
            ...inputHandlerArgs: Parameters<InputHandler>
        ) => {
            extendedInputHandler(inputHandlerArgs, {
                field,
                state,
                updateField,
                updateState,
            });
        }) as InputHandler;

        return inputHandler;
    };

    return { state, stateUpdater, updateState, updateField };
};
