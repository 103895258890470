import { useEffect, useState } from 'react';
import {
    Text,
    Banner,
    Table,
    TableCellProps,
    LoadingOverlay,
} from 'spoton-lib';
import isEqual from 'lodash.isequal';

import { IProductAvailabilityVariantList } from 'features/products/types';
import { ProductAvailability } from 'features/products';
import { useFlags } from 'features/common';
import {
    ProductPlaceholderA,
    ProductPlaceholderB,
    ProductPlaceholderC,
    ProductPlaceholderD,
} from 'features/common/assets/placeholders';
import { useOrderedPlaceholders } from 'features/common/utils';
import { useGetVariantsByParents } from 'features/products/services/products';
import { NexusModal, TooltipEnhanced } from 'features/common/components';

import {
    IProductAvailabilityModalPage,
    IPropTypes,
} from './ProductAvailabilityModal.types';
import styles from './ProductAvailabilityModal.module.scss';
import {
    getHasNewChannels,
    getNewChannelsList,
    mapProductToVariantAvailability,
} from './ProductAvailabilityModal.utils';

/** Component showing product availability options as a modal */
export function ProductAvailabilityModal(props: IPropTypes) {
    const { onCloseClick, onSaveClick, isOpen, productIds, channels } = props;
    const { isOpenPriceFeatureAvailable } = useFlags();

    const { variants, isFetching, isError } =
        useGetVariantsByParents(productIds);

    const initialData: IProductAvailabilityVariantList = {
        channels,
        variants: variants.map(mapProductToVariantAvailability),
    };

    const [currentPage, setCurrentPage] =
        useState<IProductAvailabilityModalPage>(
            IProductAvailabilityModalPage.selection,
        );

    const [productAvailability, setProductAvailability] =
        useState<IProductAvailabilityVariantList>(initialData);

    const renderImagePlaceholder = useOrderedPlaceholders([
        <ProductPlaceholderA key="a" />,
        <ProductPlaceholderB key="b" />,
        <ProductPlaceholderC key="c" />,
        <ProductPlaceholderD key="d" />,
    ]);

    useEffect(() => {
        const data: IProductAvailabilityVariantList = {
            channels,
            variants: variants.map(mapProductToVariantAvailability),
        };

        setProductAvailability(data);
    }, [variants]);

    const handleContinueClick = () => {
        setCurrentPage(IProductAvailabilityModalPage.confirmation);
    };

    const handleBackClick = () => {
        setCurrentPage(IProductAvailabilityModalPage.selection);
    };

    const handleSaveClick = async () => {
        onSaveClick(productAvailability);
    };

    const renderImage = ({ rowData, rowIndex }: TableCellProps) => {
        if (!rowData.imgUrl) {
            return <div>{renderImagePlaceholder(rowIndex)}</div>;
        }

        return (
            <img
                src={rowData.imgUrl}
                width="40"
                height="40"
                alt={rowData.title}
            />
        );
    };

    const renderNewChannelsCell = ({ rowIndex }: TableCellProps) => {
        return (
            <span data-testid="NewChannelsCell">
                {getNewChannelsList(
                    initialData.variants[rowIndex],
                    productAvailability.variants[rowIndex],
                    productAvailability.channels,
                )}
            </span>
        );
    };

    const renderProductInfo = ({ rowIndex }: TableCellProps): JSX.Element => {
        return (
            <TooltipEnhanced
                content={initialData.variants[rowIndex].title}
                tooltipClassName={styles.Tooltip}
                contentClassName={styles.ProductName}
                variant="topLeft"
                contentTagName="span"
            />
        );
    };

    const renderOpenPriceBanner = () => {
        const channelsWithOpenPriceDisabled = channels
            .filter((channel) => !channel.openPriceAllowed)
            .map((channel) => channel.label);
        const channelsDisabledText = channelsWithOpenPriceDisabled.join(', ');
        const linkingVerb =
            channelsWithOpenPriceDisabled.length === 1 ? 'is' : 'are';

        const isPresentOpenPricedItem = !!productAvailability.variants.find(
            (variant) => variant.price === '',
        );
        const shouldShowBanner =
            channelsWithOpenPriceDisabled.length > 0 && isPresentOpenPricedItem;

        return (
            shouldShowBanner && (
                <Banner
                    message={`${channelsDisabledText} ${linkingVerb} disabled on products you've set as Variable Priced Items.`}
                    variant="informative"
                    className={styles.HeaderInfo_banner}
                    data-testid="openPricedItemsInfoBanner"
                />
            )
        );
    };

    const plural = productAvailability.variants.length > 1 ? 's' : '';

    const hasNewChannels = getHasNewChannels(
        initialData.variants,
        productAvailability.variants,
    );

    const buttonText = hasNewChannels ? 'Continue' : 'Save';

    const renderSelectionPage = (
        <>
            <NexusModal.Header
                title={`${productAvailability.variants.length} Product${plural}/Variant${plural} selected`}
            />
            <div className={styles.HeaderInfo}>
                <Text>
                    Select the products/variants you would like to make
                    available across sales channels
                </Text>
            </div>
            {isOpenPriceFeatureAvailable && renderOpenPriceBanner()}
            <ProductAvailability
                {...props}
                data={productAvailability}
                onChange={setProductAvailability}
                hasError={isError}
            />
            <NexusModal.Footer
                submitTitle={buttonText}
                cancelTitle="Cancel"
                onSubmit={
                    hasNewChannels ? handleContinueClick : handleSaveClick
                }
                onCancel={onCloseClick}
                isSubmitDisabled={
                    isFetching ||
                    isError ||
                    isEqual(initialData, productAvailability)
                }
                submit-button-data-testid="continueButton"
                cancel-button-data-testid="closeModalButton"
            />
        </>
    );

    const renderConfirmationPage = (
        <>
            <div className={styles.HeaderInfo}>
                <Text className={styles.HeaderInfo_description}>
                    If a product has different prices across channels, the new
                    channel will choose the highest price.
                </Text>
                <Text>Preview:</Text>
            </div>
            <div className={styles.ConfirmationTable}>
                <Table
                    rowCount={productAvailability.variants.length}
                    keyGetter={({ index }: any) =>
                        productAvailability.variants[index].id
                    }
                    rowGetter={({ index }: any) =>
                        productAvailability.variants[index]
                    }
                    height={300}
                    rowHeight={50}
                >
                    <Table.Column
                        label={`(${productAvailability.variants.length})`}
                        dataKey="imgUrl"
                        width={60}
                        cellRenderer={renderImage}
                    />
                    <Table.Column
                        label="Product/Variant Name"
                        dataKey="title"
                        width={250}
                        flexGrow={1}
                        cellRenderer={renderProductInfo}
                    />
                    <Table.Column
                        label="New Channels"
                        dataKey="newChannels"
                        cellRenderer={renderNewChannelsCell}
                        width={350}
                        flexGrow={1}
                    />
                    <Table.Column
                        className={styles.ConfirmationTable_priceCell}
                        headerRenderer={() => (
                            <div
                                className={styles.ConfirmationTable_priceHeader}
                            >
                                Price
                            </div>
                        )}
                        label="Price"
                        dataKey="price"
                        width={100}
                        flexGrow={1}
                    />
                </Table>
            </div>
            <div className={styles.ConfirmationBanner}>
                <Banner
                    message="If you want to change the price, you must change it at the product level."
                    variant="warning"
                />
            </div>
            <NexusModal.Footer
                submitTitle="Save"
                cancelTitle="Back"
                onSubmit={handleSaveClick}
                onCancel={handleBackClick}
                submit-button-data-testid="saveButton"
                cancel-button-data-testid="backButton"
            />
        </>
    );

    const renderCurrentPage = () => {
        if (currentPage === IProductAvailabilityModalPage.selection) {
            return renderSelectionPage;
        }

        return renderConfirmationPage;
    };

    if (isOpen && isFetching) {
        return <LoadingOverlay />;
    }

    const baseModalWidth = 21;
    const channelWidth = 9;
    const modalWidth = baseModalWidth + channelWidth * channels.length;

    return (
        <div className={styles.ProductAvailabilityModal}>
            <NexusModal
                isOpen={isOpen}
                onRequestClose={onCloseClick}
                className={styles.ProductAvailabilityModal_modal}
                style={{ content: { maxWidth: `${modalWidth}rem` } }}
            >
                {renderCurrentPage()}
            </NexusModal>
        </div>
    );
}

export default ProductAvailabilityModal;
