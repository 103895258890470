import { removeEmpty } from 'features/common/utils';
import { IVendorsGetParams } from 'features/vendors/api/Vendors.types';

export const vendorKeys = {
    all: [{ scope: 'vendors' }] as const,
    vendor: (id: string) => [{ ...vendorKeys.all[0], id }] as const,
    lists: () => [{ ...vendorKeys.all[0], entity: 'lists' }] as const,
    list: ({ phrase, page, ordering }: IVendorsGetParams) =>
        [
            removeEmpty({
                ...vendorKeys.lists()[0],
                phrase,
                page,
                ordering,
            }),
        ] as const,
    infinityList: () => [{ ...vendorKeys.all[0], entity: 'infinity' }] as const,
    infinity: (phrase?: string) =>
        [removeEmpty({ ...vendorKeys.infinityList()[0], phrase })] as const,
};
