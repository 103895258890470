import { Banner, colors, IconButton } from 'spoton-lib';

import styles from './ExportFailedBanner.module.scss';
import { IPropTypes } from './ExportFailedBanner.types';

export function ExportFailedBanner({ onClose }: IPropTypes) {
    return (
        <Banner
            message="Something went wrong, your product catalog export failed."
            actions={
                <IconButton
                    name="CloseIcon"
                    disableBorder
                    color={colors.danger70}
                    alt="Close"
                    variant="danger"
                    onClick={onClose}
                />
            }
            variant="danger"
            hideIcon={false}
            className={styles.Banner}
        />
    );
}

export default ExportFailedBanner;
