import { IDropdownItem } from 'features/common/api/QueryDropdown.types';
import { IDropdownOption } from 'features/common/types';

export const loadingLabel = 'Loading...';
export const newItemValue = 'new-item';

export const defaultOption: IDropdownOption = {
    label: 'None',
    value: '',
};

// There's no situation where we want to display "No results found", therefore loadingLabel is used.
// 1. When user is searching for item, and it's not found on current list of items, we should fetch for specific phrase, and display "Loading...".
// 2. When no results have been returned from api, we should add "Create new" item to dropdown, therefore we will have at least one item.
export const noResultsFoundGenerator = (): string => loadingLabel;

export const getSelectedItem = (
    memoizedItem: IDropdownItem | null,
    selectedItemId: string | null,
    items: IDropdownOption[],
    hasEmptyOption = true,
    isLoading: boolean,
): IDropdownOption | undefined => {
    if (isLoading) {
        return {
            label: loadingLabel,
            value: '',
        };
    }

    if (!selectedItemId) {
        return hasEmptyOption ? defaultOption : undefined;
    }

    if (memoizedItem?.id === selectedItemId) {
        return {
            label: memoizedItem.name,
            value: selectedItemId,
        };
    }

    const itemFromList = items.find(({ value }) => value === selectedItemId);

    return (
        itemFromList ?? {
            label: loadingLabel,
            value: selectedItemId,
        }
    );
};

export const getNewItem = ({
    itemName,
    label,
}: {
    itemName?: string;
    label?: string;
}): IDropdownOption => ({
    label: label ? label : `Create new item "${itemName}"`,
    value: newItemValue,
});

export const extractItemName = (label: string): string => {
    const startIndex = label.indexOf('"');

    return label.slice(startIndex + 1, -1);
};

export const getItemsList = ({
    items,
    query,
    value,
    hasEmptyOption = true,
    selectedElements,
    itemType,
}: {
    items: IDropdownOption[];
    query: string;
    value: string;
    hasEmptyOption?: boolean;
    selectedElements: string[];
    itemType: string;
}) => {
    const duplicatedElementsWithoutCurrentlySelected = selectedElements.filter(
        (element) => element !== value,
    );

    const uniqueItems = [
        ...new Map(items.map((item) => [item.label, item])).values(),
    ];

    const uniqueResults = uniqueItems.filter(
        (element) =>
            !duplicatedElementsWithoutCurrentlySelected.includes(
                String(element.value),
            ),
    );

    return {
        itemsList: [
            ...(!query && hasEmptyOption ? [defaultOption] : []),
            ...uniqueResults,
            ...(query && uniqueResults.length === 0
                ? [getNewItem({ label: `Create new ${itemType} "${query}"` })]
                : []),
        ],
    };
};

export const mapItemToDropdownOption = ({
    name,
    id,
}: IDropdownItem): IDropdownOption => ({
    label: name,
    value: String(id),
});
