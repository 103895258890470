import * as yup from 'yup';

import { ProductsExportFileType } from 'features/products/api/ProductsExport.types';
import { EMAIL_RX } from 'features/common';

import { IExportModalFormValues } from './ExportModalForm.types';

export const getInitialValues = (email: string) => ({
    fileType: ProductsExportFileType.excel,
    email: email || '',
});

export const validationSchema: yup.SchemaOf<IExportModalFormValues> = yup
    .object()
    .shape({
        fileType: yup
            .mixed<ProductsExportFileType>()
            .oneOf(Object.values(ProductsExportFileType))
            .nullable()
            .required(),
        email: yup
            .string()
            .test(
                'isEmailEmpty',
                'This field cannot be empty',
                (value = '') => !!value.trim(),
            )
            .test(
                'isEmailValid',
                'Please type a valid email',
                (value = '') => !!value.toLowerCase().match(EMAIL_RX),
            )
            .required('This field cannot be empty'),
    });
