import { showToast } from 'spoton-lib';

import { IItemImage, IItemImagePayload } from 'features/common/types';
import { IImage } from 'features/common/components/ImageUploader/ImageUploader.types';
import { fileBlobUrlToBase64, fileToBase64 } from 'features/common/utils';

export const mapItemImageToImage = (
    { caption, image, id }: IItemImage,
    key: number,
): IImage => ({
    imgAlt: caption || 'image',
    imgUrl: image,
    isCover: key === 0, // the first element in the array is a primary_image
    id,
});

export const mapItemImagesToImages = (itemImages: IItemImage[]): IImage[] => {
    const images = itemImages.map(mapItemImageToImage);

    return images;
};

export const mapImageToItemImage = async (
    { id, imgAlt, file, imgUrl }: IImage,
    key: number,
): Promise<IItemImagePayload> => {
    // image without changes (we don't want to send the `image` field):
    // { id, imgUrl, imgAlt, isCover }

    // new image:
    // { file, imgUrl, imgAlt, isCover }

    // new, duplicated image:
    // { imgUrl (base64Image), imgAlt, isCover }

    const image = file ? await fileToBase64(file) : !id ? imgUrl : undefined;

    return {
        caption: imgAlt || 'image',
        image,
        displayOrder: key,
        id,
    };
};

export const mapImagesToItemImages = async (
    images: IImage[],
): Promise<IItemImagePayload[]> => Promise.all(images.map(mapImageToItemImage));

export const getBase64FromImages = (images: IImage[]): Promise<IImage[]> => {
    return Promise.all(
        Array.from(images).map(async (file) => {
            const base64Image = await fileBlobUrlToBase64(file.imgUrl);

            return {
                imgAlt: file.imgAlt || file.imgUrl || 'image',
                imgUrl: base64Image,
                isCover: false,
            };
        }),
    );
};

export const duplicateImages = async (images: IImage[]): Promise<IImage[]> => {
    let newImages: IImage[] = [];

    try {
        newImages = await getBase64FromImages(images);
    } catch (e) {
        showToast({
            variant: 'danger',
            title: 'Error',
            content: 'Error has occured while trying to duplicate images',
            autoClose: 4000,
        });
    }

    return newImages;
};
