import {
    configureStore,
    EnhancedStore,
    AnyAction,
    ThunkDispatch,
    Action,
} from '@reduxjs/toolkit';

import { getConfigFlag } from 'features/common/utils';

import * as middleware from './middleware';
import rootReducer, { RootState } from './rootReducer';
// import * as enhancers from './enhancers';

/**
 * REDUX Store configuration
 *
 * Here we apply the reducers, set middleware, apply state and add enhancers.
 * You probably won't need to edit this file unless you need to add middleware.
 */
export default function configureAppStore(
    preloadedState: any,
): EnhancedStore<any, AnyAction> {
    const store = configureStore({
        // here we define our reducers
        reducer: rootReducer,

        // we use the callback version of the middlware as per redux-toolkits
        // recommendations with typescript. we can 'prepend' and 'concat'
        // middlwares provided they are properly typed, no need to specify
        // generics by hand.
        middleware: (getDefaultMiddleware) => {
            // getConfigVar will return a string so we have to use explicit comparison to boolean as a string
            const isReduxLoggingEnabled = getConfigFlag(
                'REACT_APP_ENABLE_REDUX_LOGGING',
            );

            if (isReduxLoggingEnabled) {
                return getDefaultMiddleware().prepend(middleware.logger);
            }

            return getDefaultMiddleware();
        },

        // initial state
        preloadedState,

        // any redux enhancers we want to use
        //enhancers: [enhancers.monitorReducerEnhancer],
    });

    return store;
}

export type AppThunk = ThunkDispatch<RootState, unknown, Action>;
