import { colors, Icon, Text } from 'spoton-lib';
import clsx from 'clsx';

import { IPropTypes } from './SettingsPageHeader.types';
import styles from './SettingsPageHeader.module.scss';

export function SettingsPageHeader(props: IPropTypes) {
    const { className, text, children } = props;

    return (
        <div className={clsx(styles.SettingsPageHeader, className)}>
            {text && (
                <div className={styles.TextWrapper}>
                    <Icon
                        name="SettingsIcon"
                        alt="Icon illustrating settings cog"
                        size={24}
                        className={styles.TextWrapper_icon}
                    />
                    <Text
                        color={colors.base90}
                        className={styles.TextWrapper_text}
                    >
                        {text}
                    </Text>
                </div>
            )}
            {children}
        </div>
    );
}

export default SettingsPageHeader;
