import { useQuery, useMutation } from 'features/common/serverStateHandler';
import { useFlags } from 'features/common/hooks';
import {
    getUploadUrl,
    importGet,
    importsGet,
    scheduleImportOld,
    scheduleImportS3,
    uploadImageS3,
} from 'features/products/api/ProductsImport.data';
import {
    IProductsImportListParams,
    IProductsImportScheduleParams,
} from 'features/products/api/ProductsImport.types';

import { importKeys as importKeys } from './queryKeys';

const importsFetch = (params: IProductsImportListParams) =>
    importsGet({
        page: params.page,
        pageSize: params.pageSize || 10,
    });

export const useGetImport = (enabled: boolean, importId: string) => {
    const { data, ...rest } = useQuery(
        importKeys.import(importId),
        () => importGet({ importId }),
        {
            enabled,
            refetchOnWindowFocus: false,
            staleTime: 1000,
            keepPreviousData: false,
        },
    );

    return {
        singleImport: data || null,
        ...rest,
    };
};

export const useGetLastImport = (enabled: boolean) => {
    const { data, ...rest } = useQuery(
        importKeys.last(),
        () => importsFetch({ page: 1, pageSize: 1 }),
        { enabled, refetchOnWindowFocus: false, staleTime: 0 },
    );

    return {
        lastImport: data?.results?.[0] || undefined,
        ...rest,
    };
};

const useGetUploadUrl = (errorMessage: string) => {
    return useMutation(getUploadUrl, {
        formatErrorMessage: () => errorMessage,
        isSuccessToast: false,
    });
};

const useUploadImageS3 = (errorMessage: string) => {
    return useMutation(uploadImageS3, {
        formatErrorMessage: () => errorMessage,
        isSuccessToast: false,
    });
};

const useScheduleImportS3 = (errorMessage: string) => {
    return useMutation(scheduleImportS3, {
        onSuccess: ({ queryHandler, data }) => {
            queryHandler.setQueryData(importKeys.import(data.id), data);
            queryHandler.setQueryData(importKeys.last(), {
                results: [data],
            });
        },
        formatErrorMessage: () => errorMessage,
        isSuccessToast: false,
    });
};

export const useScheduleImport = (errorMessage: string) => {
    const { shouldUseS3Import } = useFlags();

    const {
        mutateAsync: getUploadUrl,
        isLoading: isLoadingUploadUrl,
        isSuccess: isSuccessUploadUrl,
    } = useGetUploadUrl(errorMessage);
    const {
        mutateAsync: uploadImageS3,
        isLoading: isLoadingUploadImageS3,
        isSuccess: isSuccessUploadImageS3,
    } = useUploadImageS3(errorMessage);
    const {
        mutateAsync: scheduleImportS3,
        isLoading: isLoadingScheduleImportS3,
        isSuccess: isSuccessScheduleImportS3,
    } = useScheduleImportS3(errorMessage);

    const scheduleImport = async ({ file }: IProductsImportScheduleParams) => {
        try {
            const presignedUrl = (await getUploadUrl({ file })).presignedUrl;
            await uploadImageS3({
                file,
                presignedUrl,
            });
            await scheduleImportS3({
                presignedUrl,
            });
        } catch (error) {
            // error handling done by serverStateHandler
        }
    };

    // @TODO: remove when 'shouldUseS3Import' feature flag is turned on every env
    const { mutate, ...rest } = useMutation(scheduleImportOld, {
        onSuccess: ({ queryHandler, data }) => {
            queryHandler.setQueryData(
                importKeys.import(data.data.id),
                data.data,
            );
            queryHandler.setQueryData(importKeys.last(), {
                results: [data.data],
            });
        },
        formatErrorMessage: () => 'Scheduling import failed. Try again.',
        isSuccessToast: false,
    });

    return shouldUseS3Import
        ? {
              scheduleImport,
              isLoading:
                  isLoadingUploadUrl ||
                  isLoadingUploadImageS3 ||
                  isLoadingScheduleImportS3,
              isSuccess:
                  isSuccessUploadUrl &&
                  isSuccessUploadImageS3 &&
                  isSuccessScheduleImportS3,
          }
        : {
              // @TODO: remove when 'shouldUseS3Import' feature flag is turned on every env
              scheduleImport: mutate,
              ...rest,
          };
};
