import { IChannelData } from 'features/common/types';
import { IAvailabilityChannel } from 'features/products/types';
import { useFetchChannels } from 'features/common/services';
import { getChannelName, serviceExcludedChannels } from 'features/common/utils';
import { useMerchantLocationId } from 'features/common/hooks/useMerchantLocationId';

export const mapChannelDataToAvailabilityChannel = (
    channel: IChannelData,
): IAvailabilityChannel => ({
    id: channel.id,
    label: getChannelName(channel.channelType.name),
    openPriceAllowed: channel.openPriceAllowed,
});

export const useChannels = () => {
    const { channels, isError, refetch } = useFetchChannels();
    const merchantLocationId = useMerchantLocationId();

    const feeChannelsAvailability = channels.map(
        mapChannelDataToAvailabilityChannel,
    );
    const tenderDiscountChannelsAvailability = channels.map((channel) => ({
        ...mapChannelDataToAvailabilityChannel(channel),
        channelName: channel.channelName,
        discountType: channel.tenderPriceEffect,
    }));

    const activeChannels = channels.filter(({ active }) => active);

    const taxChannelsAvailability = activeChannels.map(
        mapChannelDataToAvailabilityChannel,
    );

    // CWF-889 Exclude specific channels for service.
    const locationActiveChannelsForService = activeChannels.filter(
        ({ channelType }) =>
            !serviceExcludedChannels.includes(channelType.name),
    );

    const locationChannels = channels.filter(
        ({ merchantLocation }) => merchantLocation === merchantLocationId,
    );

    const locationActiveChannels = activeChannels.filter(
        ({ merchantLocation }) => merchantLocation === merchantLocationId,
    );

    const productActiveChannels = activeChannels.map(
        mapChannelDataToAvailabilityChannel,
    );

    return {
        channels,
        isError,
        refetch,
        feeChannelsAvailability,
        tenderDiscountChannelsAvailability,
        taxChannelsAvailability,
        locationActiveChannelsForService,
        productActiveChannels,
        locationActiveChannels,
        locationChannels,
    };
};
