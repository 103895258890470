export enum ShippingAppType {
    shippo = 'SHIPPO',
}

export type IShippingAppProps = {
    shippingAppType: ShippingAppType;
    isActivated: boolean;
    onChange: (isActivated: boolean) => void;
    disabled?: boolean;
    buttonRenderer?: () => React.ReactNode;
    description?: string;
    className?: string;
};
