import { Button } from 'spoton-lib';
import {
    useFormik,
    FieldArray,
    FormikProvider,
    move as moveUtil,
    ArrayHelpers,
} from 'formik';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { useDidUpdate } from 'features/common/utils/hooks.utils';
import { IProductOptionValue } from 'features/products/types';

import styles from './ProductOptions.module.scss';
import { validationSchema, emptyAttributeField } from './ProductOptions.utils';
import { IPropsType, IProductOptionsFormType } from './ProductOptions.types';
import { ProductOptionsList } from './ProductOptionsList';

export function ProductOptions({
    initialValues,
    onValueChange,
    isEditing,
    isLoading,
    errors: generalSectionProductOptionsErrors,
}: IPropsType) {
    const formik = useFormik<IProductOptionsFormType>({
        validationSchema,
        initialValues,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit: () => {},
        validateOnMount: true,
        enableReinitialize: true,
    });

    const {
        values: { productOptions },
        setFieldValue,
        submitForm,
    } = formik;

    useDidUpdate(() => {
        // a workaround to make the old validation work with the new formik one
        submitForm();
    }, [generalSectionProductOptionsErrors]);

    useDidUpdate(() => {
        onValueChange(productOptions);
    }, [productOptions]);

    const handleDragEnd =
        (move: ArrayHelpers['move']) => (results: DropResult) => {
            const { destination, type, source } = results;

            if (!destination) {
                return;
            }

            if (type === 'productAttributes') {
                move(source.index, destination.index);
            } else {
                const attributeIndex = Number(source.droppableId.split('.')[1]);

                const updatedOrder = moveUtil(
                    productOptions[attributeIndex].values,
                    source.index,
                    destination.index,
                ) as IProductOptionValue[];

                setFieldValue(
                    `productOptions.${attributeIndex}.values`,
                    updatedOrder,
                );
            }
        };

    if (isLoading) {
        return null;
    }

    return (
        <FormikProvider value={formik}>
            <FieldArray
                name="productOptions"
                render={({ push, remove, move }) => (
                    <DragDropContext onDragEnd={handleDragEnd(move)}>
                        <Droppable
                            droppableId="productAttributes-list"
                            type="productAttributes"
                        >
                            {({ innerRef, placeholder }) => (
                                <div ref={innerRef}>
                                    <ProductOptionsList
                                        productOptions={productOptions}
                                        onRemove={remove}
                                        isPageEditing={isEditing}
                                    />
                                    {placeholder}
                                    {!isEditing && (
                                        <div className={styles.AddButton}>
                                            <Button
                                                variant="tertiary"
                                                type="button"
                                                onClick={() =>
                                                    push(emptyAttributeField())
                                                }
                                            >
                                                + Attribute
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            />
        </FormikProvider>
    );
}

export default ProductOptions;
