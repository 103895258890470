import { IWeekdayBusinessHours } from 'features/settings/types/BusinessHours.types';
import { IBusinessHoursDayData } from 'features/settings/types';

export interface ILocalDeliveryOwnDrivers {
    deliveryPrice: string;
    minimumOrderPrice: string;
    leadtime: string;
    deliveryDetails: string;
    allowContactlessDelivery: boolean;
    sameAsBusinessHours: boolean;
    // If sameAsBusinessHours = true, then availability is not required.
    availability: IWeekdayBusinessHours | null;
    // After API changes in https://spotonteam.atlassian.net/browse/OM-828, we most likely can skip it.
    merchantLocation: string;
    id?: string;
}

export interface ILocalDeliveryDoordash {
    doordashBaseCost: string;
    outOfPocketCost: string;
    leadtime: string;
    deliveryDetails: string;
    allowContactlessDelivery: boolean;
    requireSignature: boolean;
    sameAsBusinessHours: boolean;
    // If sameAsBusinessHours = true, then availability is not required.
    availability: IWeekdayBusinessHours | null;
    // After API changes in https://spotonteam.atlassian.net/browse/OM-828, we most likely can skip it.
    merchantLocation: string;
    id?: string;
}

export interface ILocalDeliveryState {
    ownDrivers: ILocalDeliveryOwnDrivers | undefined;
    doordash: ILocalDeliveryDoordash | undefined;
}

export type ILocalDeliveryDoordashSendData = Omit<
    ILocalDeliveryDoordash,
    'doordashBaseCost'
>;

export enum ILocalDeliveryApiType {
    ownDrivers = 'USE_MY_OWN_DRIVERS',
    doordash = 'SAMEDAY_BY_DOORDASH',
}

export interface ILocalDeliveryData {
    type: ILocalDeliveryApiType;
    deliveryPrice: number;
    minimumOrderPrice: number;
    contactless: boolean;
    doordashBaseCost: number;
    doordashOutOfPocketCost: number | null;
    doordashRequireSignature: boolean;
    leadtime: number;
    customSchedule: boolean;
    availability: IBusinessHoursDayData[] | null;
    details?: string;
    id?: string;
}
