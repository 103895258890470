import {
    checkIsEntityFieldValueUnique,
    ComparableEntityType,
} from 'features/common/hooks';
import { PhoneNumberApi } from 'features/common/api';

type ValidatorReturnType = (value: string) => void;

export type ValidationResult<ErrorsType> = {
    isValid: boolean;
    errors: ErrorsType;
};

export type ValidatorsMap<Entity> = Partial<
    Record<keyof Entity, CallableFunction[]>
>;

export type ValidatorsResults<Entity> = Record<keyof Entity, string>;

export interface IValidatorOptions {
    errorMessage?: string;
}

export interface IConditionalOptions extends IValidatorOptions {
    condition?: boolean;
}

export interface IValidateLengthOptions extends IValidatorOptions {
    length: number;
}

export interface IUniqueAgainstList extends IValidatorOptions {
    list: string[];
}

export type IArrayNotEmpty<T> = IValidatorOptions & {
    list: T[];
};

export enum ValidationMode {
    sequential = 'sequential',
    parallel = 'parallel',
}

/**
 * Run validators against value.
 *
 * @param {string (any)} value - Input data.
 * @param {CallableFunction[]} validators - List of validators.
 * @param validationMode -
 *      Describes how validators are going to be executed (sequential/parallel).
 *      Validation will stop on first error occured.
 * @returns
 */

export async function getValidationError<
    Value = any,
    Validators extends CallableFunction[] = CallableFunction[],
    Result extends string = string,
>(
    value: Value,
    validators: Validators,
    validationMode = ValidationMode.parallel,
): Promise<Result> {
    let result = '' as Result;

    try {
        if (validationMode === ValidationMode.sequential) {
            for (const validator of validators) {
                await validator(value);
            }
        } else {
            await Promise.all(validators.map((validator) => validator(value)));
        }
    } catch (err: any) {
        if ('message' in err) {
            result = err.message;
        }
    }

    return result;
}

/**
 * Run validators against data represented by object.
 *
 * @param {Record<string, unknown>} data - field value pairs for input data
 * @param {Record<keyof Data, CallableFunction[]>} validators - Rules dict by field
 * @returns {Record<keyof Data, string>} Field errors dict by field
 */

export async function getValidationErrors<
    Data extends Record<string, any> = Record<string, any>,
    DataValidatorsMap extends ValidatorsMap<Data> = ValidatorsMap<Data>,
    Result extends ValidatorsResults<DataValidatorsMap> = ValidatorsResults<DataValidatorsMap>,
>(
    data: Data,
    validatorsMap: DataValidatorsMap,
    validationMode?: ValidationMode,
): Promise<Result> {
    const fieldErrors = {} as Result;

    await Promise.all(
        Object.entries(validatorsMap).map(async ([key, validators]) => {
            fieldErrors[key as keyof Data] = await getValidationError(
                data[key],
                validators || [],
                validationMode,
            );
        }),
    );

    return fieldErrors;
}

/**
 * Checks whether there are any errors in results object
 *
 * @param results - results object returned by getValidationErrors function
 */
export function hasErrors<
    Data extends Record<string, any> = Record<string, any>,
>(results: ValidatorsResults<Data>): boolean {
    let hasErrors = false;

    for (const value of Object.values(results)) {
        if (value !== '') {
            hasErrors = true;

            break;
        }
    }

    return hasErrors;
}

/**
 * Field not empty.
 *
 * Do not call directly - use getValidationErrors
 */
export function isNonEmpty(value: string): void {
    if (!String(value)) {
        throw Error('Value can not be empty!');
    }
}

/**
 * Field not empty with optional message.
 *
 * Do not call directly - use getValidationErrors
 */
export function isNonEmptyWithMessage(
    errorMessage?: string,
): ValidatorReturnType {
    return (value: string | number | null) => {
        if (!String(value) || value === null) {
            throw Error(errorMessage ?? 'Value can not be empty!');
        }
    };
}

/**
 * Field not empty if condition met
 *
 * Do not call directly - use getValidationErrors
 */
export function isConditionallyNonEmpty({
    condition = true,
    errorMessage,
}: IConditionalOptions): ValidatorReturnType {
    return (value: string | number | null) => {
        if (condition && (!String(value) || value === null)) {
            throw Error(errorMessage ?? 'Value can not be empty!');
        }
    };
}

/**
 * Field is valid number
 *
 * Do not call directly - use getValidationErrors
 */
export function isValidNumber({
    errorMessage = 'Value is not a number!',
}: IConditionalOptions): ValidatorReturnType {
    return test({
        regex: /^(-?\d+(\.\d+)?)?$/g,
        errorMessage,
    });
}

/**
 * Field not empty and existing
 *
 * Do not call directly - use getValidationErrors
 */
export function isExistingAndNonEmpty({
    condition = true,
    errorMessage,
}: IConditionalOptions): ValidatorReturnType {
    return (value: string | number | null | undefined) => {
        if (
            condition &&
            (!String(value) || value === null || value === undefined)
        ) {
            throw Error(errorMessage ?? 'Value can not be empty!');
        }
    };
}

/**
 * Validates whether value exists in passed in array
 *
 * Do not call directly - use getValidationErrors
 */
export function isUniqueAgainstList({
    list = [],
    errorMessage,
}: IUniqueAgainstList): ValidatorReturnType {
    return (value) => {
        if (list.includes(value)) {
            throw Error(errorMessage ?? 'Value is not unique');
        }
    };
}

/**
 * Field has minimum empty.
 *
 * Do not call directly - use getValidationErrors
 */
export function hasMinLength(length: number): ValidatorReturnType {
    return (value: string | number) => {
        if (`${value}`.length < length) {
            throw Error(`Must be minimum ${length} characters`);
        }
    };
}

/**
 * Field has maximum length.
 *
 * Do not call directly - use getValidationErrors
 */
export function hasMaxLength(length: number): ValidatorReturnType {
    return (value: string | number) => {
        if (`${value}`.length > length) {
            throw Error(`Must be maximum ${length} characters`);
        }
    };
}

export function isNotTooLargeCurrency(options?: {
    errorMessage: string;
}): ValidatorReturnType {
    return (value: string | number) => {
        if (value === undefined || value === null) {
            return;
        }

        if (`${String(value).replace('-', '').split('.')[0]}`.length > 8) {
            throw Error(
                options?.errorMessage ?? 'Must be less than 100 Million',
            );
        }
    };
}

/**
 * Test against regular expression.
 *
 * Do not call directly - use getValidationErrors
 */
export function test({
    regex,
    errorMessage,
}: {
    regex: RegExp;
    errorMessage: string;
}): ValidatorReturnType {
    return (value: string | number) => {
        if (regex.test(String(value)) === false) {
            throw Error(errorMessage);
        }
    };
}

/**
 * Field has specific length.
 *
 * Do not call directly - use getValidationErrors
 */
export function hasLength({
    length,
    errorMessage,
}: IValidateLengthOptions): ValidatorReturnType {
    return (value: string | number) => {
        const valueLength = `${value}`.length;
        if (valueLength === 0) {
            return;
        }
        if (valueLength !== length) {
            throw Error(errorMessage ?? `Must be exactly ${length} characters`);
        }
    };
}

/**
 * Field has only numeric characters.
 *
 * Do not call directly - use getValidationErrors
 */
export function isNumeric(value: string): void {
    if (value && !value.match(/^[0-9.-]*$/)) {
        throw Error('Must be numeric value');
    }
}

/**
 * Field value is valid phone number.
 */
export async function isPhoneNumber(
    phoneNumber: string | undefined,
): Promise<void> {
    const message = 'Phone number is invalid';

    // empty value is valid
    if (!phoneNumber) {
        return;
    }

    try {
        const response = await PhoneNumberApi.validatePhoneNumber({
            phoneNumber,
        });

        if (response.status !== 200) {
            throw new Error(message);
        }
    } catch (error) {
        throw new Error(message);
    }
}

/**
 * Slightly modified version of the below:
 * https://docs.microsoft.com/en-us/dotnet/standard/base-types/how-to-verify-that-strings-are-in-valid-email-format
 * Added support for '+'
 */
export const EMAIL_RX =
    /^([\w-+]+(\.[\w-+]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/;

/**
 * Validate email address structure
 * Throws error message for invalid email.
 *
 *  Do not call directly - use getValidationErrors
 */
export function isEmailAddress(email: string): void {
    if (email && !email.toLowerCase().match(EMAIL_RX)) {
        throw Error('Invalid email address');
    }
}

/**
 * Field value is not a zero.
 */
export function isNonZero({
    condition = true,
    errorMessage,
}: IConditionalOptions) {
    return (value: string | number): void => {
        const valueAsNumber = Number(value);

        if (condition && valueAsNumber === 0) {
            throw Error(errorMessage ?? 'Must not be zero');
        }
    };
}

/**
 * Field value is not a negative number.
 */
export function isNonNegative({
    condition = true,
    errorMessage,
}: IConditionalOptions) {
    return (value: string | number): void => {
        const valueAsNumber = Number(value);

        if (condition && valueAsNumber < 0) {
            throw Error(errorMessage ?? 'Must not be negative');
        }
    };
}

/**
 * Field value is non negative and non zero number.
 */
export function isPositive({
    condition = true,
    errorMessage,
}: IConditionalOptions) {
    return (value: string | number): void => {
        const valueAsNumber = Number(value);

        if (condition && valueAsNumber <= 0) {
            throw Error(errorMessage ?? 'Must not be negative');
        }
    };
}

/**
 * Field value is non-negative and non-zero number or is empty.
 */
export function isPositiveOrEmpty({
    condition = true,
    errorMessage,
}: IConditionalOptions) {
    return (value: unknown): void => {
        if (value === null || value === undefined || value === '') {
            return;
        }

        return isPositive({ condition, errorMessage })(
            value as string | number,
        );
    };
}

/**
 * Validates whether a passed array is empty
 *
 * Do not call directly - use getValidationErrors
 */
export function isAssociatedArrayNotEmpty<T>({
    list = [],
    errorMessage,
}: IArrayNotEmpty<T>): ValidatorReturnType {
    return () => {
        if (!list.length) {
            throw Error(errorMessage ?? 'Array is empty!');
        }
    };
}

/**
 * Returns an object. For example object {upc: 23, sku: null} will be mapped to {upc: "", sku: ""}
 * @param value object to be mapped
 * @returns Object with the same keys a input, but value are set to empty string
 */
export function mapObjectToEmptyErrorObject<T>(value: T): ValidatorsResults<T> {
    return Object.keys(value).reduce((ac, a) => ({ ...ac, [a]: '' }), {}) as {
        [P in keyof T]: string;
    };
}

/**
 * Field value is unique.
 */
export function uniqueEntityValue<
    ValidatedEntity extends Record<string, any> = Record<string, any>,
    FieldType extends keyof ValidatedEntity = keyof ValidatedEntity,
>(
    entityType: ComparableEntityType,
    field: FieldType,
    excludedValue?: string,
    invalidValues?: string[],
    options?: IValidatorOptions,
) {
    return async (value: string): Promise<void> => {
        try {
            const isUnique = await checkIsEntityFieldValueUnique(
                entityType,
                {
                    field: field as string,
                    value,
                },
                excludedValue,
                invalidValues,
            );

            if (!isUnique) {
                throw Error('Value is already taken');
            }
        } catch (error) {
            throw Error(options?.errorMessage ?? (error as string));
        }
    };
}
