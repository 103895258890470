import { IStorage } from './types';

export class SessionStorage implements IStorage {
    getStoredValue = <T>(key: string, defaultValue: T) => {
        try {
            // eslint-disable-next-line no-restricted-properties
            const savedValue = sessionStorage.getItem(key);
            if (!savedValue) return defaultValue;

            const initial = JSON.parse(savedValue);
            return initial as T;
        } catch {
            return defaultValue;
        }
    };

    saveValue = <T>(key: string, value: T) => {
        try {
            // eslint-disable-next-line no-restricted-properties
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch {
            // no need to handle error
        }
    };
}
