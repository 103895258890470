import * as yup from 'yup';
import {
    FieldArrayRenderProps,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';

export const formikArrayFieldRemoveAll = (
    indexes: number[],
    arrayHelpers: FieldArrayRenderProps,
): void => {
    indexes.sort((a, b) => b - a);

    for (let i = 0; i < indexes.length; i++) {
        arrayHelpers.remove(indexes[i]);
    }
};

export const cacheTest = <T>(
    asyncValidate: (value: T, context: yup.TestContext) => Promise<boolean>,
): ((value: T, context: yup.TestContext) => Promise<boolean>) => {
    let isValidCached: boolean | undefined = undefined;
    let valueCached: T | undefined = undefined;

    return async (value: T, context: yup.TestContext) => {
        if (value !== valueCached || typeof isValidCached === 'undefined') {
            const isValidResponse = await asyncValidate(value, context);
            valueCached = value;
            isValidCached = isValidResponse;
            return isValidResponse;
        }

        return isValidCached;
    };
};

// This assures root context beeing passed to validators. Helpful e.g. when
// `test` of variants[i].upc needs to be validated against all other variants[]
export const validateWithContext =
    <T>(validationSchema: yup.SchemaOf<T>) =>
    async (values: T) => {
        const validationContext: T = { ...values };

        try {
            await validateYupSchema<T>(
                values,
                validationSchema,
                false,
                validationContext,
            );

            // Return empty error object if no errors were thrown
            return {};
        } catch (err) {
            return yupToFormErrors(err);
        }
    };
