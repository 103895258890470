import difference from 'lodash.difference';

import {
    IAvailabilityChannel,
    IProductAvailabilityVariant,
    IProductOrVariantById,
} from 'features/products/types';

export const mapProductToVariantAvailability = (
    product: IProductOrVariantById,
): IProductAvailabilityVariant => {
    return {
        id: product.id,
        channels: product.channels.reduce((acc, { channel, active }) => {
            return {
                ...acc,
                [channel]: active,
            };
        }, {}),
        image: product.images.length ? product.images[0].image : '',
        title: product.title,
        price: product.price || '',
    };
};

export const getNewChannelsIds = (
    oldData: IProductAvailabilityVariant,
    currentData: IProductAvailabilityVariant,
) => {
    const oldChannelsIds = Object.keys(oldData.channels).filter(
        (id) => oldData.channels[id],
    );

    const currentChannelsIds = Object.keys(currentData.channels).filter(
        (id) => currentData.channels[id],
    );

    return difference(currentChannelsIds, oldChannelsIds);
};

export const getNewChannelsList = (
    oldData: IProductAvailabilityVariant,
    currentData: IProductAvailabilityVariant,
    channels: IAvailabilityChannel[],
) => {
    const newChannelsIds = getNewChannelsIds(oldData, currentData);

    if (newChannelsIds.length === 0) {
        return '-';
    }

    return newChannelsIds
        .reduce<string[]>((acc, curr) => {
            const channel = channels.find((channel) => channel.id === curr);

            if (channel) {
                acc.push(channel.label);
            }

            return acc;
        }, [])
        .join(', ');
};

export const getHasNewChannels = (
    oldData: IProductAvailabilityVariant[],
    currentData: IProductAvailabilityVariant[],
) =>
    currentData.some(
        (variant, index) =>
            getNewChannelsIds(oldData[index], variant).length > 0,
    );
