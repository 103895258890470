import { Button, Icon, colors } from 'spoton-lib';

import { DropdownMenu } from 'features/common/components';
import { DropdownMenuOnSelect } from 'features/common/components/DropdownMenu/DropdownMenu.types';

import {
    ShippingMethodAction,
    IMoreActionsDropdownProps,
} from './MoreActionsDropdown.types';
import styles from './MoreActionsDropdown.module.scss';
import { allOptions } from './MoreActionsDropdown.constant';

export function MoreActionsDropdown({
    buttonText = 'More',
    withDividers = false,
    menuWidth = '260px',
    menuPosition = 'absolute',
    menuOptions,
    ...rest
}: IMoreActionsDropdownProps): JSX.Element {
    const options = menuOptions
        .map((opt) => allOptions.filter((el) => el.value === opt.action))
        .flat();

    const onSelect: DropdownMenuOnSelect<ShippingMethodAction> = (
        selectedOption,
    ) => {
        const selectedAction = menuOptions.find(
            (option) => option.action === selectedOption.value,
        );

        if (!selectedAction) {
            throw new Error(
                `Not supported action in MoreActionsDropdown - ${selectedOption.value}`,
            );
        }
        selectedAction.onSelect();
    };

    return (
        <div className={styles.Wrapper}>
            <DropdownMenu
                triggerElement={
                    <Button
                        className={styles.TriggerElement}
                        variant="secondary"
                    >
                        {buttonText}
                        <Icon
                            alt="More"
                            className={styles.TriggerElement_icon}
                            color={colors.primary50}
                            name="ExpandIcon"
                            size={28}
                        />
                    </Button>
                }
                options={options}
                onSelect={onSelect}
                withDividers={withDividers}
                menuPortalTarget={document.body}
                menuPosition={menuPosition}
                menuWidth={menuWidth}
                {...rest}
            />
        </div>
    );
}
