import {
    readCookieData,
    setMerchantLocationCookie,
    setMerchantLocationIdHeader,
} from 'features/common/utils';
import { merchantLocationsGet } from 'features/settings/api/MerchantLocations.data';
import { useQuery } from 'features/common/serverStateHandler';

export const merchantLocationsKeys = {
    all: [{ scope: 'merchantLocationId' }] as const,
};

export const useGetMerchantLocationId = () => {
    const cookieData = readCookieData();
    const cookieMerchantLocationId = cookieData?.merchantLocationId;

    const shouldFetchFromServer = !cookieData?.merchantLocationId;

    const { data, isLoading, isError, refetch, ...queryResults } = useQuery(
        merchantLocationsKeys.all,
        () => merchantLocationsGet({ page: 1 }),
        {
            enabled: shouldFetchFromServer,
        },
    );

    const merchantLocationId =
        data?.results[0].id !== undefined
            ? String(data?.results[0].id)
            : cookieMerchantLocationId;

    const results = {
        merchantLocationId,
        getMerchantLocationIdLoaded: () => {
            if (!merchantLocationId) {
                throw new Error(`merchantLocationId not loaded yet`);
            }

            return merchantLocationId;
        },
        isMerchantLocationIdLoading: isLoading,
        isMerchantLocationIdError: isError,
        refetchMerchantLocationId: refetch,
        ...queryResults,
    };

    if (merchantLocationId) {
        setMerchantLocationCookie(merchantLocationId);
        setMerchantLocationIdHeader(merchantLocationId);
    }

    return results;
};
