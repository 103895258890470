import { useMemo } from 'react';

import { IChannel, IFilterValue } from 'features/common/types';
import { ICategory } from 'features/categories/types';
import { ILowStockValue } from 'features/common/components/FiltersSidebarLowStock/FiltersSidebarLowStock.types';

export const parseChannels = (
    channelsIds: string[],
    channelsData: IChannel[],
) => {
    if (channelsData.length === 0) {
        return [];
    }
    return channelsIds
        .map((channelId) => {
            const channelData = channelsData.find(({ id }) => id === channelId);
            return channelData
                ? {
                      id: channelData.id,
                      label: channelData.displayName,
                      value: channelData.id,
                  }
                : undefined;
        })
        .filter((el) => el) as IFilterValue[];
};

export const parseCategories = (
    categoriesIds: string[],
    categoriesData: ICategory[],
) => {
    if (categoriesData.length === 0) {
        return [];
    }
    return categoriesIds
        .map((categoryId) => {
            const categoryData = categoriesData.find(
                ({ id }) => id === categoryId,
            );
            return categoryData
                ? {
                      id: categoryData.id,
                      label: categoryData.name,
                      value: categoryData.id,
                  }
                : undefined;
        })
        .filter((el) => el) as IFilterValue[];
};

export const useFilters = (
    urlParams: {
        channels: string[];
        categories: string[];
        tags: string[];
        lowStock?: string;
    },
    channelsData: IChannel[],
    categoriesData: ICategory[],
) => {
    const categories = useMemo(
        () => parseCategories(urlParams.categories, categoriesData),
        [urlParams.categories, categoriesData.toString()],
    );

    const channels = useMemo(
        () => parseChannels(urlParams.channels, channelsData),
        [urlParams.channels, channelsData.toString()],
    );

    const filtersGetParams = useMemo(() => {
        return {
            channel: urlParams.channels.join(',') || undefined,
            tags: urlParams.tags.join(',') || undefined,
            category: urlParams.categories.join(',') || undefined,
            lowStock: urlParams.lowStock || undefined,
        };
    }, [
        urlParams.tags?.toString(),
        urlParams.channels?.toString(),
        urlParams.categories?.toString(),
        urlParams.lowStock,
    ]);

    return {
        filtersState: {
            tags: urlParams.tags,
            channels,
            categories,
            lowStock: (urlParams.lowStock as ILowStockValue) || null,
        },
        filtersGetParams,
    };
};
