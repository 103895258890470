import { Text, Icon, colors } from 'spoton-lib';

import BoxIcon from 'features/common/assets/box_icon.svg';
import { EditCustomPackage } from 'features/shipping/components/EditCustomPackage';

import { IPropTypes } from './AddCustomPackageDropdown.types';
import styles from './AddCustomPackageDropdown.module.scss';

export function AddCustomPackageDropdown(props: IPropTypes) {
    const { isExpanded, onToggle, packageCombinedGirth, warningText } = props;

    return (
        <div className={styles.AddCustomPackageDropdown}>
            <button
                type="button"
                className={styles.AddCustomPackageDropdownButton}
                onClick={() => onToggle(isExpanded ? '' : 'custom')}
                data-testid="AddCustomPackageDropdown"
            >
                <div className={styles.AddCustomPackageDropdownMainBlock}>
                    <img
                        src={BoxIcon}
                        alt="custom box"
                        className={
                            styles.AddCustomPackageDropdownMainBlock_logo
                        }
                    />
                    <div
                        className={
                            styles.AddCustomPackageDropdownMainBlock_textBlock
                        }
                    >
                        <Text isBold>Add a custom package</Text>
                        <Text type="label">
                            Add a new one by setting up size and weight, it may
                            impact the shipping cost.
                        </Text>
                    </div>
                </div>
                <Icon
                    name={isExpanded ? 'CollapseIcon' : 'ExpandIcon'}
                    alt="toggle"
                    size={30}
                    color={isExpanded ? colors.primary50 : colors.black}
                />
            </button>

            {isExpanded && (
                <EditCustomPackage
                    packageCombinedGirth={packageCombinedGirth}
                    warningText={warningText}
                />
            )}
        </div>
    );
}

export default AddCustomPackageDropdown;
