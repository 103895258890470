import { PlaceholderListWrapper } from 'features/common/components';

import { IPropTypes } from './TablePlaceholder.types';

export function TablePlaceholder({
    hasError,
    errorMessage,
    className,
    children,
    ...placeholderProps
}: IPropTypes) {
    return (
        <PlaceholderListWrapper className={className} {...placeholderProps}>
            {hasError ? errorMessage : children}
        </PlaceholderListWrapper>
    );
}

export default TablePlaceholder;
