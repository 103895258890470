import { useState } from 'react';
import { v4 as uuid } from 'uuid';

export type WeightRange = {
    from: number | '';
    to: number | '';
    price: number;
    isToWeightError?: boolean;
    isPriceError?: boolean;
    fieldId: string;
    id?: string;
};

export type ProcessedWeightRangeType = WeightRange & {
    meta: {
        allowRemove: boolean;
        allowFromInput: boolean;
        allowToInput: boolean;
    };
};

export const decimal = 0.01;
const weightThreshold = 5;
const priceThreshold = 10;

export const getNumberWith2Decimals = (value: string | number): number =>
    Number(Number(value).toFixed(2));

const getFromValue = (weightsCopy: ProcessedWeightRangeType[]) => {
    const lastWeightIndex = weightsCopy.length - 1;
    const lastWeightRangeFromValue = weightsCopy[lastWeightIndex].from || NaN;

    return weightsCopy.length < 2
        ? Number(weightsCopy[lastWeightIndex].to)
        : lastWeightRangeFromValue;
};

const getToValue = (weightsCopy: ProcessedWeightRangeType[]) => {
    const lastWeightRangeFromValue = getFromValue(weightsCopy);
    const rangeToValue = isNaN(lastWeightRangeFromValue)
        ? ''
        : getNumberWith2Decimals(
              lastWeightRangeFromValue + (weightThreshold - decimal),
          );

    return weightsCopy.length < 2 ? lastWeightRangeFromValue : rangeToValue;
};

const processData = (inputData: WeightRange[]) => {
    const isOneRow = inputData.length < 2;

    return inputData.map<ProcessedWeightRangeType>((row, i) => {
        const isLastRow = i === inputData.length - 1;

        return {
            ...row,
            to: isOneRow ? row.to : isLastRow ? Infinity : row.to,
            meta: {
                allowRemove: i > 1,
                allowFromInput: i !== 0,
                allowToInput: isOneRow || !isLastRow,
            },
        };
    });
};
// let it be computed
export const useWeightRange = (inputData: WeightRange[]) => {
    const [weights, setWeights] = useState(() => processData(inputData));

    const checkErrors = (mutatedWeights: WeightRange[]) => {
        for (let i = 0; i < mutatedWeights.length; i++) {
            const current = mutatedWeights[i];
            const next = mutatedWeights[i + 1];

            if (next) {
                mutatedWeights[i + 1] = {
                    ...mutatedWeights[i + 1],
                    isToWeightError: Number(next.to) <= Number(current.to),
                };
            }

            mutatedWeights[i] = {
                ...mutatedWeights[i],
                isToWeightError: Number(current.from) >= Number(current.to),
            };
        }

        return mutatedWeights;
    };

    const handleFromChange = (newValue: string, idx: number) => {
        const val = parseFloat(newValue);

        if (val < 0) {
            return;
        }

        const weightsCopy = [...weights];

        weightsCopy[idx] = {
            ...weightsCopy[idx],
            from: newValue ? val : '',
        };

        weightsCopy[idx - 1] = {
            ...weightsCopy[idx - 1],
            to: newValue ? getNumberWith2Decimals(val - decimal) : '',
        };

        setWeights(processData(checkErrors(weightsCopy)));
    };

    const handleUptoChange = (newValue: string, idx: number) => {
        const val = parseFloat(newValue);

        if (val < 0) {
            return;
        }

        const weightsCopy = [...weights];

        weightsCopy[idx] = {
            ...weightsCopy[idx],
            to: newValue ? val : '',
        };

        if (weightsCopy.length > 1) {
            weightsCopy[idx + 1] = {
                ...weightsCopy[idx + 1],
                from: newValue ? getNumberWith2Decimals(val + decimal) : '',
            };
        }

        setWeights(processData(checkErrors(weightsCopy)));
    };

    const handleRemove = (idx: number) => {
        const weightsCopy = [...weights];

        weightsCopy[idx - 1] = {
            ...weightsCopy[idx - 1],
            to: weightsCopy[idx].to,
        };

        weightsCopy.splice(idx, 1);

        setWeights(processData(checkErrors(weightsCopy)));
    };

    const handleAdd = () => {
        const weightsCopy = [...weights];
        const lastWeightIndex = weightsCopy.length - 1;

        const rangeToValue = getToValue(weightsCopy);

        const fromValue =
            rangeToValue === ''
                ? ''
                : getNumberWith2Decimals(Number(rangeToValue) + decimal);

        if (weightsCopy.length > 1) {
            weightsCopy[lastWeightIndex] = {
                ...weightsCopy[lastWeightIndex],
                to: rangeToValue,
            };
        }

        const weightsData = processData(
            checkErrors([
                ...weightsCopy,
                {
                    from: fromValue,
                    to: Infinity,
                    price:
                        weightsCopy[lastWeightIndex].price + priceThreshold ||
                        0,
                    fieldId: uuid(),
                },
            ]),
        );

        setWeights(weightsData);
    };

    const handlePriceChange = (value: string, idx: number) => {
        const price = getNumberWith2Decimals(parseFloat(value));

        const weightsCopy = [...weights];

        const updatedPrice = price < 0 ? {} : { price };

        weightsCopy[idx] = {
            ...weightsCopy[idx],
            ...updatedPrice,
            isPriceError: value === '',
        };

        setWeights(weightsCopy);
    };

    return {
        handleAdd,
        handleRemove,
        handleUptoChange,
        handleFromChange,
        handlePriceChange,
        weights,
    };
};
