// eslint-disable-next-line no-restricted-imports
import { SidebarHeader as SpotonHeader, Text } from 'spoton-lib';
import clsx from 'clsx';

import styles from './SidebarHeader.module.scss';
import { IPropTypes } from './SidebarHeader.types';

export function SidebarHeader(props: IPropTypes) {
    const { title, centerElement, leftElement, rightElement, className } =
        props;

    const centerHeaderElement = (
        <div className={styles.SidebarHeader_centerElement}>
            {centerElement || <Text type="h5">{title}</Text>}
        </div>
    );

    return (
        <SpotonHeader
            className={clsx(className, styles.SidebarHeader)}
            leftElement={
                <div data-testid="sidebar-header-left-element">
                    {leftElement}
                </div>
            }
            centerElement={centerHeaderElement}
            rightElement={
                <div data-testid="sidebar-header-right-element">
                    {rightElement}
                </div>
            }
        />
    );
}

export default SidebarHeader;
