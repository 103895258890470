export const getPriceRange = (
    minPrice: number | null,
    maxPrice: number | null,
): string => {
    if (minPrice === null || maxPrice === null) {
        return '-';
    }

    if (minPrice === maxPrice) {
        return `$${minPrice}`;
    }

    return `$${minPrice} - $${maxPrice}`;
};
