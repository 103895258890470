import * as yup from 'yup';

import { ILocationSettingFieldName } from 'features/settings/types';

import { ISettingsState, ISettingsStatePresets } from './TippingPage.types';

function validatePreset(value?: string | null): boolean {
    const doubleValue = value ? parseFloat(value) : 0;
    return doubleValue > 0 && doubleValue <= 100;
}

export const tippingTypeMap: Record<
    keyof ISettingsStatePresets,
    ILocationSettingFieldName
> = {
    preset1: ILocationSettingFieldName.predefinedPercentageTip1,
    preset2: ILocationSettingFieldName.predefinedPercentageTip2,
    preset3: ILocationSettingFieldName.predefinedPercentageTip3,
};

const presetSchema: yup.SchemaOf<string> = yup
    .string()
    .nullable()
    .required('Value can not be empty!')
    .test(
        'isPresetValid',
        'Value must be greater than 0 and lower or equal to 100',
        validatePreset,
    );

export const validationSchema: yup.SchemaOf<ISettingsState> = yup
    .object()
    .shape({
        preset1: presetSchema,
        preset2: presetSchema,
        preset3: presetSchema,
        isAcceptTips: yup.boolean().required(),
        isCalculateTipBeforeTax: yup.boolean().required(),
    });
