import { SyntheticEvent } from 'react';
import { NumberFormatValues } from 'spoton-lib';

type GenericHandler<Arguments extends unknown[]> = (
    args: Arguments,
    handlerArgs: {
        field: string;
        state: unknown;
        /*
            'field' should have type keyof FormState, but since these are generic functions,
            we do not know the keys of state at this point.
            We cannot make field: string, because string is not assignable to keyof FormState.
        */
        updateField: (field: any, value: unknown) => void;
        updateState: (newState: Record<string, unknown>) => void;
    },
) => void;

// Can be used for any field, considering that first argument from handler is always a new value to set.
export const genericOnChange: GenericHandler<[unknown]> = (
    [value],
    { field, updateField },
) => {
    updateField(field, value);
};

export const handleInputOnChangeText: GenericHandler<[SyntheticEvent]> = (
    [event],
    { field, updateField },
) => {
    const { value } = event.target as HTMLInputElement;

    updateField(field, value);
};

export const handleInputOnChangeNumber: GenericHandler<[SyntheticEvent]> = (
    [event],
    { field, updateField },
) => {
    const value = Number((event.target as HTMLInputElement).value);

    if (Number.isNaN(value) || !Number.isFinite(value)) {
        return;
    }

    updateField(field, value);
};

export const handleInputOnChangeNumberFormat: GenericHandler<
    [NumberFormatValues]
> = ([values], { field, updateField }) => {
    const value = values.floatValue !== undefined ? values.floatValue : null;

    updateField(field, value);
};

export const handleTagsInputOnAdd: GenericHandler<[string]> = (
    [tag],
    { field, state, updateField },
) => {
    const existingTags = (state as Record<string, string[]>)[field];
    const newTags = [...existingTags, tag];

    updateField(field, newTags);
};

export const handleTagsInputOnRemove: GenericHandler<[number]> = (
    [removedIndex],
    { field, state, updateField },
) => {
    const existingTags = (state as Record<string, string[]>)[field];
    const newTags = existingTags.filter(
        (_, tagIndex) => tagIndex !== removedIndex,
    );

    updateField(field, newTags);
};

export const handleTagsInputOnClear: GenericHandler<[]> = (
    _,
    { field, updateField },
) => {
    updateField(field, []);
};
