export const fileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

export const fileBlobUrlToBase64 = async (fileUrl: string): Promise<string> => {
    const options: RequestInit = {
        mode: 'cors',
        cache: 'no-cache',
    };
    const fileBlob = await fetch(fileUrl, options).then((result) =>
        result.blob(),
    );

    return fileToBase64(fileBlob);
};

/**
 * In order to handle cropped images consistently we need
 * to convert blob to Blob/File format in order to be able to create
 * image string with URL.createObjectURL(...). Solution taken from:
 * https://stackoverflow.com/a/20151856
 */
const base64toBlob = (base64Data: string, contentType: string) => {
    const sliceSize = 1024;
    // eslint-disable-next-line deprecation/deprecation
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
};

export const base64toFile = (base64String: string, filename: string): File => {
    const prefix = 'data:';
    const delimiter = 'base64,';
    const delimiterIndex = base64String.indexOf(delimiter);

    const contentType = base64String.substring(
        prefix.length,
        delimiterIndex - 1,
    );
    const base64Data = base64String.substring(
        delimiterIndex + delimiter.length,
    );

    const blob = base64toBlob(base64Data, contentType);
    return new File([blob], filename, { type: contentType });
};
