import { useField, useFormikContext } from 'formik';
import { Dropdown } from 'spoton-lib';

import { IDropdownOption } from 'features/common/types';

import { IPropTypes } from './DropdownField.types';

export function DropdownField(props: IPropTypes) {
    const { name, options, hasEmptyOption, ...rest } = props;
    const { setFieldValue } = useFormikContext();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [field, { error, touched }] = useField(name);

    const handleOnChange = (option: IDropdownOption) => {
        setFieldValue(name, option.value, true);
    };

    const emptyOption: IDropdownOption = {
        label: 'None',
        value: '',
    };

    const dropdownOptions = [
        ...(hasEmptyOption ? [emptyOption] : []),
        ...options,
    ];

    const getValueFromOptions = () => {
        const option = dropdownOptions.find(
            (option: IDropdownOption) => option.value === field.value,
        );

        if (!option) {
            return null;
        }

        return option;
    };

    const isValid = touched ? !error : true;
    const errorText = touched ? error : '';

    return (
        <Dropdown
            {...rest}
            options={dropdownOptions}
            onBlur={field.onBlur}
            onChange={handleOnChange}
            value={getValueFromOptions()}
            secondaryCondition={errorText}
            isValid={isValid}
        />
    );
}

export default DropdownField;
