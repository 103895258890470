import { useContext, useEffect } from 'react';

import { NexusPrompt } from 'features/common/components';

import { UnsavedPromptContext } from './UnsavedPrompt.component';
import { IUnsavedPromptRawProps } from './UnsavedPrompt.types';

/**
 * Use it if your unsaved state changes are based on local state rather than react-router
 * eg. simulating changing page by conditional render
 */
export function UnsavedPromptRaw(props: IUnsavedPromptRawProps) {
    const {
        isModalOpen,
        onCloseModal,
        onConfirm,
        shouldClearOnUnmount = true,
        title = 'Unsaved changes',
        bodyText = 'You’re about to leave without saving. All changes will be lost.',
        cancelButtonText = 'Continue editing',
        confirmButtonText = 'Leave page',
    } = props;
    const context = useContext(UnsavedPromptContext);

    useEffect(() => {
        return () => {
            // Workaround for using this component as separate one.
            // Clears unsaved state when navigating using local state (not react-router)
            // Probably only in Shipping (to be refactored using react-router)
            if (shouldClearOnUnmount && context) {
                context.setHasUnsavedChanges(false);
            }
        };
    }, []);

    return (
        <NexusPrompt
            isOpen={isModalOpen}
            onRequestClose={onCloseModal}
            title={title}
            message={bodyText}
            onSubmit={onConfirm}
            cancelButtonText={cancelButtonText}
            submitButtonText={confirmButtonText}
            isDanger
            cancel-button-data-testid="CancelButton"
            submit-button-data-testid="ConfirmButton"
        />
    );
}
