import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'reduxConfig';

import { IProductDuplicationState } from './productsDuplication.types';

const selectDuplicatedProductState = (
    state: RootState,
): IProductDuplicationState => state.duplicatedProduct;

export const selectDuplicatedProduct = createSelector(
    selectDuplicatedProductState,
    (state) => state.product,
);
