import { Text } from 'spoton-lib';
import clsx from 'clsx';

import { IShippingMethodInfoItemProps } from './ShippingMethodInfoItem.types';
import styles from './ShippingMethodInfoItem.module.scss';
import { config } from './ShippingMethodInfoItem.constants';

export function ShippingMethodInfoItem({
    shippingMethodType,
    description,
    disabled = false,
    className,
}: IShippingMethodInfoItemProps): JSX.Element {
    const { icon, title, methodDescription } = config[shippingMethodType];

    return (
        <div className={clsx(styles.Wrapper, className)}>
            <div className={clsx({ [styles.Icon___disabled]: disabled })}>
                {icon}
            </div>
            <div className={styles.Content}>
                <Text
                    className={clsx(styles.Content_title, {
                        [styles.Content_title___disabled]: disabled,
                    })}
                    as="p"
                >
                    {title}
                </Text>
                <Text
                    className={clsx(styles.Content_description, {
                        [styles.Content_description___disabled]: disabled,
                    })}
                    as="p"
                >
                    {description || methodDescription}
                </Text>
            </div>
        </div>
    );
}
