import { useMutation, useQuery } from 'features/common/serverStateHandler';
import { attributeOptionCreate } from 'features/common/api/AttributeOptions.data';
import { attributeGetById } from 'features/attributes/api/Attributes.data';

import { attributeKeys } from './queryKeys';

export const useGetAttributes = (attribute: string | null) => {
    return useQuery(
        attributeKeys.attribute(attribute),
        () =>
            attribute
                ? attributeGetById(attribute)
                : Promise.resolve(undefined),
        {
            enabled: !!attribute,
        },
    );
};

export const useCreateAttributeOption = (attribute: string) => {
    const { mutateAsync, ...rest } = useMutation(attributeOptionCreate, {
        onSuccess: ({ queryHandler }) => {
            return queryHandler.refetchQueries(
                attributeKeys.attribute(attribute),
            );
        },
        formatSuccessMessage: ({ option }) =>
            `Item "${option}" created successfully`,
        formatErrorMessage: (_, { option }) =>
            `Error while creating "${option}" item. Try again.`,
    });

    return {
        createItem: mutateAsync,
        ...rest,
    };
};
