import { useEffect, useState } from 'react';
import { NumberFormat } from 'spoton-lib';

import { useGetValidationError } from 'features/common/hooks';

import { IPropTypes } from './ValidateableNumberFormat.types';

export function ValidateableNumberFormat({
    value,
    validators,
    validationMode,
    timeout,
    secondaryCondition,
    isValid,
    onChange,
    onBlur,
    ...inputProps
}: IPropTypes): JSX.Element {
    const [validationError, setValidationError] = useState('');
    const [onChangeError, validateOnChange, isAwaitingOnChange] =
        useGetValidationError(
            validators.onChange || [],
            timeout,
            validationMode,
        );
    const [onBlurError, validateOnBlur, isAwaitingOnBlur] =
        useGetValidationError(validators.onBlur || [], timeout, validationMode);

    const isValueValid = validationError ? false : isValid;
    const error = validationError || secondaryCondition;
    // If onBlur check was performed - use onBlurError only if theres no onChangeError.
    useEffect(() => {
        if (!isAwaitingOnBlur && !onChangeError) {
            setValidationError(onBlurError);
        }
    }, [isAwaitingOnBlur, onBlurError, onChangeError]);
    // If onChange check was performed - use onChangeError.
    useEffect(() => {
        if (!isAwaitingOnChange) {
            setValidationError(onChangeError);
        }
    }, [isAwaitingOnChange, onChangeError]);
    // If value is invalid, and onChange validators have changed - rerun validation.
    useEffect(() => {
        if (!isValueValid) {
            validateOnChange(value ? String(value) : '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validators.onChange]);

    return (
        <NumberFormat
            {...inputProps}
            value={value}
            onChange={(event) => {
                onChange?.(event);

                validateOnChange(event.target.value);
            }}
            onBlur={(event) => {
                onBlur?.(event);

                validateOnBlur(event.target.value);
            }}
            isValid={isValueValid}
            secondaryCondition={error}
            isMultilineSecondaryCondition
        />
    );
}

export default ValidateableNumberFormat;
