import { useCallback, useState } from 'react';
import { colors, IconButton, ImageSelector, Sidebar } from 'spoton-lib';
import clsx from 'clsx';

import { base64toFile, useDidUpdate } from 'features/common/utils';
import { showError } from 'features/common/utils/errors.utils';
import { ImageCropModal } from 'features/common/components/ImageCropModal';
import { ImageCropModalOnChange } from 'features/common/components/ImageCropModal/ImageCropModal.types';
import { SidebarHeader } from 'features/common/components/SidebarHeader';

import styles from './ImageUploader.module.scss';
import { IImage, IPropTypes } from './ImageUploader.types';

const SIZE_ERROR_MESSAGE = `Images can't be larger than 15MB!`;
const FORMAT_ERROR_MESSAGE = 'Unsupported image file format!';
const SUPPORTED_IMAGE_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
];

export function ImageUploader({
    images,
    className,
    isSidebarOpen: initialIsSidebarOpen,
    showInitialImageSelector = true,
    sidebarImageSelectorRef,
    sidebarTitle,
    onChange,
    onSidebarClose,
}: IPropTypes) {
    const [cropIndex, setCropIndex] = useState(0);
    const [imageToCrop, setImageToCrop] = useState('');
    const [isSidebarOpen, setIsSidebarOpen] = useState(
        initialIsSidebarOpen || false,
    );
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);

    useDidUpdate(() => {
        setIsSidebarOpen(Boolean(initialIsSidebarOpen));
    }, [initialIsSidebarOpen]);

    const isFileSizeValid = (file: File) => file.size <= 15 * 1024 * 1024;
    const isFileFormatValid = (file: File) =>
        SUPPORTED_IMAGE_FORMATS.includes(file.type);
    const isFileValid = (file: File): boolean =>
        isFileSizeValid(file) && isFileFormatValid(file);

    const hasAnyInValidFileSizes = (files: File[]) =>
        !files.every(isFileSizeValid);
    const hasAnyInvalidFileFormats = (files: File[]) =>
        !files.every(isFileFormatValid);

    const fileToImage = (file: File): IImage => ({
        imgAlt: file.name || 'image',
        imgUrl: URL.createObjectURL(file),
        isCover: false,
        file,
    });

    const setCoverIfMissing = (images: IImage[]): IImage[] => {
        if (images.length > 0 && !images.find(({ isCover }) => isCover)) {
            return [{ ...images[0], isCover: true }, ...images.slice(1)];
        }
        return images;
    };

    const handleNewImage = useCallback(
        async (filesList: FileList) => {
            const files = Array.from(filesList);

            if (hasAnyInValidFileSizes(files)) {
                showError(SIZE_ERROR_MESSAGE);
            }

            if (hasAnyInvalidFileFormats(files)) {
                showError(FORMAT_ERROR_MESSAGE);
            }

            const newImages = files.filter(isFileValid).map(fileToImage);
            const allImages = [...images, ...newImages];

            onChange(setCoverIfMissing(allImages));
        },
        [images, onChange],
    );

    const handleRemoveImage = useCallback(
        (removalIndex: number) => {
            const filteredImages = images.filter(
                (_image, index) => index !== removalIndex,
            );

            onChange(setCoverIfMissing(filteredImages));
        },
        [images, onChange],
    );

    const handleCoverImage = useCallback(
        (newCoverIndex: number) => {
            const changedImages = images.map<IImage>((image, index) => {
                const isCover = index === newCoverIndex;

                return { ...image, isCover };
            });
            changedImages.unshift(changedImages.splice(newCoverIndex, 1)[0]);

            onChange(changedImages);
        },
        [images, onChange],
    );

    const handleImageCropped = useCallback<ImageCropModalOnChange>(
        (base64Image) => {
            // there is no way to update the src of the existing image so cropped image is in fact a new one
            // that's why `id` property is being removed
            const newImages = images.map<IImage>(({ id, ...image }, index) => {
                if (index === cropIndex) {
                    const file = base64toFile(base64Image, image.imgAlt);
                    const imgUrl = URL.createObjectURL(file);

                    return { ...image, imgUrl, file };
                }

                return { ...image, id };
            });

            setIsCropModalOpen(false);

            onChange(newImages);
        },
        [images, onChange, cropIndex, setIsCropModalOpen],
    );

    const handleCropImage = (cropIndex: number) => {
        setCropIndex(cropIndex);
        setImageToCrop(images[cropIndex].imgUrl);
        setIsCropModalOpen(true);
    };

    const handleShowMoreImages = () => setIsSidebarOpen(true);
    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);

        if (onSidebarClose) {
            onSidebarClose();
        }
    };
    const handleCloseCropper = () => setIsCropModalOpen(false);

    return (
        <>
            {showInitialImageSelector && (
                <ImageSelector
                    images={images}
                    className={clsx(styles.ImageUploader, className)}
                    onAddImages={handleNewImage}
                    onRemoveImage={handleRemoveImage}
                    onCoverImage={handleCoverImage}
                    onCropImage={handleCropImage}
                    onShowMoreImages={handleShowMoreImages}
                />
            )}
            <Sidebar
                isOpen={isSidebarOpen}
                headerComponent={
                    <SidebarHeader
                        title={
                            sidebarTitle || `Product Images (${images.length})`
                        }
                        leftElement={
                            <IconButton
                                alt="close"
                                name="CloseIcon"
                                onClick={handleCloseSidebar}
                                disableBorder
                                color={colors.black}
                                size={34}
                            />
                        }
                    />
                }
            >
                <ImageSelector
                    ref={sidebarImageSelectorRef}
                    className={styles.SidebarImageUploader}
                    images={images}
                    layout="list"
                    onAddImages={handleNewImage}
                    onRemoveImage={handleRemoveImage}
                    onCoverImage={handleCoverImage}
                    onCropImage={handleCropImage}
                    // This handler is not needed out there, but 'onShowMoreImages' is required prop. Sidebar should not use that handler.
                    onShowMoreImages={handleShowMoreImages}
                />
            </Sidebar>
            <ImageCropModal
                isOpen={isCropModalOpen}
                imageSrc={imageToCrop}
                onChange={handleImageCropped}
                onClose={handleCloseCropper}
            />
        </>
    );
}

export default ImageUploader;
