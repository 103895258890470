import { Radio, RowButton, Text } from 'spoton-lib';
import { Form, FormikProvider, useFormik } from 'formik';

import { NexusModal } from 'features/common/components';
import { TextField } from 'features/common/components/TextField';
import { noop } from 'features/common';
import { ProductsExportFileType } from 'features/products/api/ProductsExport.types';

import { IExportModalFormValues, IPropTypes } from './ExportModalForm.types';
import styles from './ExportModalForm.module.scss';
import { getInitialValues, validationSchema } from './ExportModalForm.utils';

export function ExportModalForm({
    isOpen,
    onClose,
    onSubmit,
    email,
}: IPropTypes) {
    const onSubmitForm = (formValues: IExportModalFormValues) => {
        onSubmit({
            fileType: formValues.fileType as ProductsExportFileType,
            email: formValues.email,
        });
    };

    const formik = useFormik<IExportModalFormValues>({
        validationSchema,
        initialValues: getInitialValues(email),
        onSubmit: onSubmitForm,
    });

    const { isSubmitting, values, isValid, setFieldValue, submitForm } = formik;

    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Export Products" />
                <Text className={styles.Modal_paragraph}>
                    Your file will contain your products, inventory quantities,
                    SKU / UPC numbers and variants.
                </Text>
                <FormikProvider value={formik}>
                    <Form>
                        <section>
                            <RowButton
                                className={styles.ModalForm_checkbox}
                                title="Export to Excel"
                                subtitle="This option is recommended for most users. The Excel format is supported by all modern spreadsheet editors."
                                leftElement={
                                    <Radio
                                        checked={
                                            values.fileType ===
                                            ProductsExportFileType.excel
                                        }
                                        value={ProductsExportFileType.excel}
                                        onChange={noop}
                                    />
                                }
                                onClick={() =>
                                    setFieldValue(
                                        'fileType',
                                        ProductsExportFileType.excel,
                                    )
                                }
                                buttonProps={{ type: 'button' }}
                            />
                            <RowButton
                                className={styles.ModalForm_checkbox}
                                title="Export to CSV"
                                subtitle="This option is recommended for use with automated tools."
                                leftElement={
                                    <Radio
                                        checked={
                                            values.fileType ===
                                            ProductsExportFileType.csv
                                        }
                                        value={ProductsExportFileType.csv}
                                        onChange={noop}
                                    />
                                }
                                onClick={() =>
                                    setFieldValue(
                                        'fileType',
                                        ProductsExportFileType.csv,
                                    )
                                }
                                buttonProps={{ type: 'button' }}
                            />
                            <TextField
                                name="email"
                                label="Send to"
                                placeholder="Enter email..."
                                className={styles.ModalForm_emailInput}
                            />
                        </section>
                        <NexusModal.Footer
                            submitTitle="Export"
                            cancelTitle="Cancel"
                            onSubmit={submitForm}
                            isSubmitLoading={isSubmitting}
                            isSubmitDisabled={!isValid || !values.email}
                        />
                    </Form>
                </FormikProvider>
            </div>
        </NexusModal>
    );
}

export default ExportModalForm;
