import { useState, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { Table, Button, Icon, TableCellProps } from 'spoton-lib';
import {
    CellMeasurerCache,
    WindowScroller,
    CellMeasurer,
} from 'react-virtualized';

import { ReactComponent as EmptyIllustration } from 'features/common/assets/shipping_methods_empty.svg';

import { IPropTypes, defaultProps, RendererProps } from './ShippingTable.types';
import styles from './ShippingTable.module.scss';

const headerRowHeight = 54;
const rowHeight = 54;

export function ShippingTable<DataItem extends { id: string | number | null }>(
    props: IPropTypes<DataItem>,
) {
    const [isShowingAll, setIsShowingAll] = useState(false);
    // needed to trigger rerender on resizing
    const [width, setWidth] = useState(0);
    const {
        footer,
        viewMoreText,
        viewLessText,
        className,
        showMoreButtonClassName,
        footerClassName,
        defaultShowing,
        data,
        columnsData,
    } = props;
    const isExpandButtonVisible = data.length > defaultShowing;
    const tableData = isShowingAll ? data : data.slice(0, defaultShowing);

    const cache = useMemo(
        () =>
            new CellMeasurerCache({
                fixedWidth: true,
                minHeight: rowHeight,
            }),
        [tableData, width],
    );

    const renderCell = useCallback(
        ({
            parent,
            rowData,
            columnIndex,
            rowIndex,
            cellData,
        }: TableCellProps) => {
            const cellHeight = cache.getHeight(rowIndex, columnIndex);

            return (
                <CellMeasurer
                    parent={parent}
                    key={rowData.id}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    cache={cache}
                >
                    {/* cannot use spoton-lib Text as there is not way to pass style */}
                    <p
                        style={{
                            height: `${cellHeight}px`,
                        }}
                        className={styles.DefaultCellRenderer}
                    >
                        {cellData}
                    </p>
                </CellMeasurer>
            );
        },
        [cache],
    );

    const renderCellWithProvidedRenderer =
        // eslint-disable-next-line react/display-name
        (cellRenderer: RendererProps<DataItem>) => (props: TableCellProps) => {
            return (
                <CellMeasurer
                    parent={props.parent}
                    key={props.rowData.id}
                    columnIndex={props.columnIndex}
                    rowIndex={props.rowIndex}
                    cache={cache}
                >
                    {cellRenderer(props)}
                </CellMeasurer>
            );
        };

    return (
        <div className={clsx(styles.ShippingTable, className)}>
            <WindowScroller onResize={({ width }) => setWidth(width)}>
                {({ height, scrollTop, registerChild }) => (
                    // fix issues with having multiple shipping tables on the same page (some rows were disappearing)
                    <div ref={(el) => registerChild(el)}>
                        <Table
                            rowCount={tableData.length}
                            rowGetter={({ index }) => tableData[index]}
                            keyGetter={({ index }) =>
                                String(tableData[index].id)
                            }
                            variant="secondary"
                            className={styles.ShippingMethodsTable}
                            height={height}
                            scrollTop={scrollTop}
                            autoHeight
                            headerRowHeight={headerRowHeight}
                            rowHeight={({ index }) =>
                                cache.rowHeight({ index })
                            }
                            noRowsRenderer={() => (
                                <div className={styles.EmptyWrapper}>
                                    <EmptyIllustration />
                                </div>
                            )}
                            deferredMeasurementCache={cache}
                        >
                            {columnsData.map((columnData, index) => (
                                <Table.Column
                                    {...columnData}
                                    key={index}
                                    className={className || ''}
                                    cellRenderer={
                                        columnData.cellRenderer
                                            ? renderCellWithProvidedRenderer(
                                                  columnData.cellRenderer,
                                              )
                                            : renderCell
                                    }
                                />
                            ))}
                        </Table>
                    </div>
                )}
            </WindowScroller>

            {isExpandButtonVisible && (
                <Button
                    className={clsx(
                        styles.ShippingTable_showMoreButton,
                        showMoreButtonClassName,
                    )}
                    variant="tertiary"
                    onClick={() => setIsShowingAll((isShowing) => !isShowing)}
                    type="button"
                >
                    {isShowingAll ? viewLessText : viewMoreText}
                    <Icon
                        name={isShowingAll ? 'CollapseIcon' : 'ExpandIcon'}
                        alt="Toggle Show All"
                        size={24}
                        className={styles.ShippingTable_showMoreButtonIcon}
                    />
                </Button>
            )}
            {footer && (
                <div
                    className={clsx(
                        styles.ShippingTable_footer,
                        footerClassName,
                    )}
                >
                    {footer}
                </div>
            )}
        </div>
    );
}

ShippingTable.defaultProps = defaultProps;

export default ShippingTable;
