import { IAuthStateCookie } from 'features/common/utils/auth.utils.types';
import {
    deleteMerchantLocationCookie,
    getMerchantLocationCookieValue,
} from 'features/common/utils/merchantLocationCookies.utils';

export const readCookieData = (): IAuthStateCookie | undefined => {
    const merchantLocationId = getMerchantLocationCookieValue();
    return {
        merchantLocationId,
    };
};

export const logout = (): void => {
    // clear merchant location id cookie
    deleteMerchantLocationCookie();
};

export const getUserInitials = (
    firstName: string,
    lastName: string,
): string => {
    return `${firstName[0] || ''} ${lastName[0] || ''}`;
};
