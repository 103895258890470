import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import {
    ICategoriesGetResponse,
    ICategoryPostPayload,
    ICategoryPostResponse,
    ICategoryDeleteResponse,
    ICategoriesGetParams,
    ICategoryPatchPayload,
    ICategoryPatchResponse,
    ICategoryGetResponse,
} from './Categories.types';

/** Obtains not paginated result with all categories. */
export const categoriesAllGet = async ({
    page = 1,
    pageSize,
    name = undefined,
    phrase = undefined,
    ordering,
}: ICategoriesGetParams) => {
    const getResponse = await axios.get<ICategoriesGetResponse>(
        '/api/v1/categories/',
        {
            params: {
                page,
                pageSize,
                name,
                q: phrase,
                ordering,
            },
        },
    );
    return getData(getResponse);
};

/** Obtains a paginated result from categories collection. */
export const categoriesGet = ({
    page = 1,
    pageSize,
    name = undefined,
    phrase = undefined,
    ordering,
}: ICategoriesGetParams) =>
    axios
        .get<ICategoriesGetResponse>('/api/v1/categories/', {
            params: {
                page,
                pageSize,
                name,
                q: phrase,
                ordering,
            },
        })
        .then(getData);

/** Obtains data for single category. */
export const categoryGet = (id: string) =>
    axios.get<ICategoryGetResponse>(`/api/v1/categories/${id}/`).then(getData);

/** Creates a category */
export const categoryCreate = (category: ICategoryPostPayload) =>
    axios
        .post<ICategoryPostResponse>('/api/v1/categories/', category)
        .then(getData);

/** Patches a category */
export const categoryPatch = ({ id, ...update }: ICategoryPatchPayload) =>
    axios
        .patch<ICategoryPatchResponse>(`/api/v1/categories/${id}/`, update)
        .then(getData);

/** Deletes a category */
export const categoryDelete = (id: string) =>
    axios
        .delete<ICategoryDeleteResponse>(`/api/v1/categories/${id}/`)
        .then(getData);
