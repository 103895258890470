import { FC, SetStateAction, SVGProps } from 'react';

import {
    IFiltersSidebarFilterState,
    IFiltersSidebarContentType,
} from 'features/common/types';

export type SVG = FC<SVGProps<SVGSVGElement>>;
export type IconSet = Record<string, SVG>;

export type IFilterPage = keyof IFiltersSidebarFilterState | 'home';

export const filterPage: Record<IFilterPage, IFilterPage> = {
    channels: 'channels',
    categories: 'categories',
    tags: 'tags',
    home: 'home',
    lowStock: 'lowStock',
};

export interface IFilterRowIconConfig {
    name: string;
    alt: string;
    iconSet?: IconSet;
}

export interface IFilterConfig {
    pageId: IFilterPage;
    icon: IFilterRowIconConfig;
    getSubtitle: (state: IFiltersSidebarFilterState) => string;
    getContent: (
        state: IFiltersSidebarFilterState,
        setState: (value: SetStateAction<IFiltersSidebarFilterState>) => void,
        contentType: IFiltersSidebarContentType,
    ) => JSX.Element;
    getIsClear: (state: IFiltersSidebarFilterState) => boolean;
}

export interface IPropTypes {
    filtersState: IFiltersSidebarFilterState;
    isSidebarOpen: boolean;
    contentType: IFiltersSidebarContentType;
    filtersToShow: IFilterPage[];
    onClose(): void;
    onApply(newFiltersState: IFiltersSidebarFilterState): void;
}
