import React from 'react';
import clsx from 'clsx';
import { IconX } from '@tabler/icons';
import { colors } from 'spoton-lib';

// import IconButton from 'components/IconButton/IconButton.component';
import ButtonBaseTemp from './ButtonBaseTemp/ButtonBase.component';
import { IProps, propTypes, defaultProps } from './ModalHeaderTempFix.types';
import styles from './ModalHeaderTempFix.module.scss';

/**
 * Copied from https://github.com/SpotOnInc/style-guide-web/tree/dev/src/components/ModalHeader
 * This is only temporary fix, because ModalHeader from spotonLib behaves incorrectly when we need to center the title.
 * The whole component is 1:1 copy, except for styles which were slightly corrected.
 * Fix request on slack channel: https://spoton.slack.com/archives/C01S5A7PDU6/p1679579864157399
 */
export class ModalHeaderTempFix extends React.Component<IProps> {
    static displayName = 'ModalHeader';
    static propTypes = propTypes;
    static defaultProps = { ...defaultProps };

    render(): JSX.Element {
        const {
            className,
            closeButtonClassName,
            content,
            onRequestClose,
            iconAlt,
        } = this.props;

        {
            /*
        A temporary solution to reduce the bundle,
        after fixing the Icon component,
        uncomment IconButton and remove inline svg with ButtonBase
        */
        }
        const closeIcon = (
            <ButtonBaseTemp
                onClick={onRequestClose}
                type="button"
                tabIndex={-1}
                data-testid="close-button"
                style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                }}
                className={clsx(
                    styles.ModalHeader_closeButton,
                    closeButtonClassName,
                )}
                title={iconAlt}
            >
                <IconX color={colors.black} size={19} />
            </ButtonBaseTemp>
        );
        {
            /* <IconButton
                disableBorder
                alt={iconAlt}
                name="CloseIcon"
                size={32}
                color={colors.black}
                onClick={onRequestClose}
            /> */
        }

        return (
            <div
                className={clsx(
                    styles.ModalHeader,
                    !onRequestClose && styles.ModalHeader___noIcon,
                    className,
                )}
            >
                <div className={styles.ModalHeader_title}>{content}</div>
                {onRequestClose && closeIcon}
            </div>
        );
    }
}

export default ModalHeaderTempFix;
