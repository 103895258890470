/** Gets a MerchantLocationLinks */

import { AxiosResponse } from 'axios';

import { axios, getData } from 'features/common/utils';

import {
    ICopyMerchantLocationPostParams,
    IMerchantLocationLinksGetParams,
    IMerchantLocationLinksGetResponse,
} from './MerchantLocationLinks.types';

export function fetchMerchantLocationLinks({
    page = 1,
}: IMerchantLocationLinksGetParams) {
    return axios
        .get<IMerchantLocationLinksGetResponse>('/api/v1/merchant-locations/', {
            params: { page },
        })
        .then(getData);
}

export function copyMerchantLocationCatalog({
    locationId,
    targetLocations,
    details,
}: ICopyMerchantLocationPostParams): Promise<AxiosResponse<undefined>> {
    return axios.post<undefined>(
        `api/v1/merchant-locations/${locationId}/copy-catalog/`,
        {
            targetLocations,
            details,
        },
    );
}
