import { useQuery } from 'features/common/serverStateHandler';
import { ProductVariantsApi } from 'features/products/api';
import { IProductListProductVariant } from 'features/products/types';
import { IProductVariantsLinksGetPayload } from 'features/products/api/ProductVariants.types';

import { variantsLinksKeys } from './queryKeys';
import { parseProductQuantity } from './products.utils';

export const useGetVariantsLinks = (args: IProductVariantsLinksGetPayload) => {
    const { data, isLoading, ...rest } = useQuery(
        variantsLinksKeys.list(args),
        () => ProductVariantsApi.productVariantsLinksGet(args),
        {
            select: ({ results }) =>
                results.map<IProductListProductVariant>((variant) => ({
                    id: variant.id,
                    upc: variant.upc,
                    sku: variant.sku,
                    name: variant.title,
                    channels: variant.channels.reduce(
                        (acc, channel) => ({
                            ...acc,
                            [channel.channel]: channel.active,
                        }),
                        {},
                    ),
                    stock: parseProductQuantity(
                        variant.stock?.quantityInStock,
                        '0',
                    ),
                    lowStock: variant.lowStock,
                })),
        },
    );

    return {
        variants: data || [],
        isLoadingVariants: isLoading,
        ...rest,
    };
};
