import clsx from 'clsx';

import { useDelayedToggle } from 'features/common/utils';
import { Placeholder } from 'features/common/components/Placeholder';

import { IPropTypes } from './PlaceholderWrapper.types';
import styles from './PlaceholderWrapper.module.scss';

export function PlaceholderWrapper(props: IPropTypes) {
    const {
        isLoading,
        children,
        className,
        toggleOnTimeSpan,
        toggleOffTimeSpan,
    } = props;
    const isVisible = useDelayedToggle(
        isLoading,
        toggleOnTimeSpan,
        toggleOffTimeSpan,
    );

    return isVisible ? (
        <div className={clsx(styles.PlaceholderWrapper, className)}>
            <span
                className={styles.PlaceholderWrapper_children}
                data-testid="HiddenItems"
            >
                {children}
            </span>
            <Placeholder
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    zIndex: 2,
                }}
            />
        </div>
    ) : (
        <>{children}</>
    );
}

export default PlaceholderWrapper;
