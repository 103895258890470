import { useEffect } from 'react';

import { ExportModalViews, IPropTypes } from './ExportModal.types';
import { ExportModalFailed } from './ExportModalFailed';
import { ExportModalForm } from './ExportModalForm';
import { ExportModalInProgress } from './ExportModalInProgress';
import { ExportModalStarted } from './ExportModalStarted';
import { useExportModal } from './hooks';

export function ExportModal({ onClose, email }: IPropTypes) {
    const {
        exportModalView,
        onSubmitExport,
        userEmail,
        refetchLastExport,
        canShowExportFileDownload,
        errors,
    } = useExportModal();

    const handleExportInitiatedModalClose = () => {
        refetchLastExport();
        onClose();
    };

    useEffect(() => {
        if (
            canShowExportFileDownload &&
            exportModalView === ExportModalViews.exportInProgress
        ) {
            onClose();
        }
    }, [canShowExportFileDownload, exportModalView]);

    return (
        <>
            <ExportModalForm
                isOpen={exportModalView === ExportModalViews.form}
                onClose={onClose}
                onSubmit={onSubmitExport}
                email={email}
            />
            <ExportModalStarted
                isOpen={exportModalView === ExportModalViews.exportInitialized}
                onClose={handleExportInitiatedModalClose}
                userEmail={userEmail}
            />
            <ExportModalFailed
                isOpen={
                    exportModalView ===
                    ExportModalViews.exportInitializationFailed
                }
                onClose={onClose}
                errors={errors}
            />
            <ExportModalInProgress
                isOpen={exportModalView === ExportModalViews.exportInProgress}
                onClose={onClose}
                userEmail={userEmail}
            />
        </>
    );
}

export default ExportModal;
