import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams as useSearchParamsBase } from 'react-router-dom';
import qs from 'qs';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { AnySchema, InferType } from 'yup';

// there is no direct params that enables parsing arrays with encoded commas (%2C) in qs
const parseArrays = (input: URLSearchParams) => {
    return qs.parse(
        qs.stringify(qs.parse(input.toString()), {
            arrayFormat: 'comma',
            encode: false,
        }),
        { comma: true },
    );
};

export const useSearchParams = <T extends AnySchema>(validationSchema: T) => {
    const [urlSearchParams, setUrlSearchParams] = useSearchParamsBase();
    const [plainSearchParams, setPlainSearchParams] = useState<InferType<T>>(
        {} as InferType<T>,
    );
    const [isInitialized, setIsInitialized] = useState(false);

    const decodeParams = async (urlSearchParams: URLSearchParams) => {
        const decodedParams = omitBy(parseArrays(urlSearchParams), isEmpty);
        try {
            const validatedParams: T = await validationSchema.cast(
                decodedParams,
                {
                    stripUnknown: true,
                },
            );
            setPlainSearchParams(validatedParams);
        } catch (error) {
            console.warn(error);
        }
        setIsInitialized(true);
    };

    useEffect(() => {
        decodeParams(urlSearchParams);
    }, [urlSearchParams.toString()]);

    const updateSearchParams = (params: InferType<T>) => {
        setPlainSearchParams(params);
        const encodedParams = qs.stringify(params, { arrayFormat: 'comma' });
        setUrlSearchParams(encodedParams);
    };

    return [plainSearchParams, updateSearchParams, isInitialized] as const;
};
