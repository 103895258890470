import { nanoid } from '@reduxjs/toolkit';

import {
    IPartialProductData,
    IProduct,
    IProductVariant,
} from 'features/products/types';
import { INumberFormatValidators } from 'features/common/components/ValidateableNumberFormat/ValidateableNumberFormat.types';
import { isNonEmptyWithMessage, uniqueEntityValue } from 'features/common';
import { IInputValidators } from 'features/common/components/ValidateableInput/ValidateableInput.types';
import { upcValueLength } from 'features/products/utils/validators';

import { IPropFields } from './GeneralSection.types';

export const getInitialGeneralData = (
    isLoyaltyEnabled = false,
    productData?: IPartialProductData,
): IPropFields => {
    return {
        name: '',
        sku: '',
        upc: '',
        description: '',
        images: [],
        tags: [],
        productOptions: [
            {
                attribute: null,
                key: nanoid(),
                name: '',
                isEditing: true,
                values: [
                    {
                        key: nanoid(),
                        value: '',
                    },
                ],
            },
        ],
        hasVariants: false,
        category: '',
        vendor: '',
        canEarnReward: isLoyaltyEnabled,
        canRedeemReward: isLoyaltyEnabled,
        ...(productData ? productData.general : {}),
    };
};

export const generalSectionErrorMessages = {
    name: {
        nonBlank: `Name can't be blank`,
        unique: 'Name already taken',
    },
    sku: {
        unique: 'SKU already taken',
    },
    upc: {
        unique: 'UPC already taken',
    },
    hasVariants: {
        nonEmpty: 'At least one variant has to be defined',
    },
};

export const getGeneralSectionInlineValidators = (
    initialValues: {
        name: string;
        sku: string;
        upc: string;
    },
    shouldUseLegacyUPCValidation?: boolean,
): Record<
    keyof Pick<IPropFields, 'name' | 'sku' | 'upc'>,
    INumberFormatValidators | IInputValidators
> => ({
    name: {
        onChange: [
            uniqueEntityValue<IProduct>(
                'product',
                'title',
                initialValues.name,
                [],
                {
                    errorMessage: generalSectionErrorMessages.name.unique,
                },
            ),
        ],
        onBlur: [
            isNonEmptyWithMessage(generalSectionErrorMessages.name.nonBlank),
        ],
    },
    sku: {
        onChange: [
            uniqueEntityValue<IProductVariant>(
                'product',
                'sku',
                initialValues.sku,
                [],
                {
                    errorMessage: generalSectionErrorMessages.sku.unique,
                },
            ),
        ],
    },
    upc: {
        onBlur: [upcValueLength(shouldUseLegacyUPCValidation)],
        onChange: [
            upcValueLength(shouldUseLegacyUPCValidation),
            uniqueEntityValue<IProductVariant>(
                'product',
                'upc',
                initialValues.upc,
                [],
                {
                    errorMessage: generalSectionErrorMessages.upc.unique,
                },
            ),
        ],
    },
});
