import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from './utils';

type State = {
    message: string;
};

type Actions = {
    showBanner: (message: string) => void;
    hideBanner: () => void;
};

const initialState: State = {
    message: '',
};

const useBannerStore = create<State & { actions: Actions }>()(
    devtools(
        (set) => ({
            ...initialState,
            actions: {
                showBanner: (message: string) => {
                    set({ message });
                },
                hideBanner: () => {
                    set(initialState);
                },
            },
        }),
        { enabled: !import.meta.env.PROD && import.meta.env.MODE !== 'test' },
    ),
);

export const useBannerStoreSelectors = createSelectors(useBannerStore);
export const useBannerStoreActions = () =>
    useBannerStoreSelectors.use.actions();
