import React from 'react';

import { ReactComponent as ShippoIcon } from 'features/common/assets/shippo_rate_logo.svg';

import { ShippingAppType } from './ShippingApp.types';

export const config: Record<
    ShippingAppType,
    { icon: React.ReactNode; title: string; appDescription: string }
> = {
    [ShippingAppType.shippo]: {
        icon: <ShippoIcon />,
        title: 'Shippo',
        appDescription:
            'Get discounted shipping rates, buy and print labels through SpotOn, and automatically calculate rates at checkout.',
    },
};
