import { SyntheticEvent, useState } from 'react';
import {
    Table,
    Pagination,
    Index,
    TableCellProps,
    Text,
    Label,
} from 'spoton-lib';
import { Link, useLocation } from 'react-router-dom';
import { SortIndicator, TableHeaderProps } from 'react-virtualized';
import moment from 'moment-timezone';
import clsx from 'clsx';

import {
    getAvailabilityText,
    useOrderedPlaceholders,
} from 'features/common/utils';
import {
    ProductPlaceholderA,
    ProductPlaceholderB,
    ProductPlaceholderC,
    ProductPlaceholderD,
} from 'features/common/assets/placeholders';
import { productsPaths } from 'features/products/routes/products.paths';
import { TooltipEnhanced } from 'features/common';
import { useFlags, useMediaQuery } from 'features/common/hooks';
import {
    availableColumnsProducts,
    productsColumnsMap,
} from 'features/products/components/MainProductsPage/MainProductsPage.utils';
import { IProductsColumns } from 'features/products/components/MainProductsPage/MainProductsPage.types';
import { useGetCurrentMerchantLocation } from 'features/common/services';
import { IProductListProduct } from 'features/products/types';

import styles from './ProductList.module.scss';
import { IPropTypes } from './ProductList.types';
import { ProductVariant } from './ProductVariant.component';

const headerRowHeight = 54;
const rowHeight = 80;
const variantRowHeight = 64;

export function ProductList(props: IPropTypes) {
    const {
        products,
        channels,
        currentPage,
        numberOfPages,
        onPageChange,
        onProductsSelectionChange,
        onExpandVariants,
        usedColumns,
        renderSelectionMenu,
        onSortChange,
        sortBy,
        sortDirection,
    } = props;
    const { pathname } = useLocation();
    const { timezone } = useGetCurrentMerchantLocation();
    const { isLowStockAlertFeatureEnabled } = useFlags();

    const [selectedProductKeys, setSelectedProductKeys] = useState<string[]>(
        [],
    );

    const [currentOpen, setCurrentOpen] = useState<IProductListProduct | null>(
        null,
    );

    const renderImagePlaceholder = useOrderedPlaceholders([
        <ProductPlaceholderA key="a" />,
        <ProductPlaceholderB key="b" />,
        <ProductPlaceholderC key="c" />,
        <ProductPlaceholderD key="d" />,
    ]);

    const { isMobile } = useMediaQuery();

    const onSelectedProductKeysChange = (keys: string[]) => {
        setSelectedProductKeys(keys);
        onProductsSelectionChange(keys);
    };

    const renderRow = ({ style, className, columns, rowData }: any) => {
        const productLink = productsPaths.edit(rowData.id);
        const isSelected = selectedProductKeys.includes(rowData.id);

        if (isMobile) {
            const [checkboxColumn, ...dataColumns] = columns;

            return (
                <div style={style} className={className}>
                    {checkboxColumn}
                    <Link
                        className={styles.Product_link}
                        to={productLink}
                        state={{ from: pathname }}
                    >
                        {dataColumns}
                    </Link>
                </div>
            );
        } else {
            const [collapsibleButtonColumn, checkboxColumn, ...dataColumns] =
                columns;

            return (
                <div
                    style={style}
                    className={clsx(
                        className,
                        isSelected && styles.Product_link__selected,
                    )}
                >
                    {collapsibleButtonColumn}
                    {checkboxColumn}
                    <Link
                        className={styles.Product_link}
                        to={productLink}
                        state={{ from: pathname }}
                    >
                        {dataColumns}
                    </Link>
                </div>
            );
        }
    };

    const renderVariants = ({ index }: Index) => {
        onExpandVariants(index);

        return <ProductVariant product={products[index]} />;
    };

    const renderImage = ({
        rowData,
        rowIndex,
    }: TableCellProps): JSX.Element => {
        if (!rowData.imgUrl) {
            return (
                <div className={styles.Image}>
                    {renderImagePlaceholder(rowIndex)}
                </div>
            );
        }

        return (
            <div className={styles.Image}>
                <img
                    src={rowData.imgUrl}
                    width="48"
                    height="48"
                    alt={rowData.product}
                    data-testid={`productsListProductImage-${rowData.id}`}
                />
            </div>
        );
    };

    const renderProductInfo = ({ rowData }: TableCellProps): JSX.Element => {
        const availability = getAvailabilityText(channels, rowData.channels);

        return (
            <>
                <TooltipEnhanced
                    content={rowData.name}
                    tooltipClassName={styles.Product_tooltip}
                    contentClassName={styles.Product_name}
                    variant="topLeft"
                    contentTestId={`ProductListProductName-${rowData.id}`}
                />
                {!rowData.hasVariants && (
                    <p
                        data-testid={`ProductListProductAvailability-${rowData.id}`}
                        className={styles.Product_availability}
                    >
                        {availability}
                    </p>
                )}
            </>
        );
    };

    const renderDateCell = (date: string): JSX.Element => {
        if (!date || !timezone) {
            return <Text>-</Text>;
        }

        return <Text>{moment(date).tz(timezone).format('MM/DD/YYYY')}</Text>;
    };

    const renderCreated = ({ rowData }: TableCellProps): JSX.Element => {
        return renderDateCell(rowData.created);
    };

    const renderModified = ({ rowData }: TableCellProps): JSX.Element => {
        return renderDateCell(rowData.modified);
    };

    const renderLabels = ({ rowData }: TableCellProps): JSX.Element => {
        return (
            <>
                {isLowStockAlertFeatureEnabled && rowData.lowStock && (
                    <Label text="Low Stock" variant="warning" />
                )}
            </>
        );
    };

    const renderNumericCell = ({
        rowData,
        dataKey,
    }: TableCellProps): JSX.Element => {
        return <div className={styles.Cell_numeric}>{rowData[dataKey]}</div>;
    };

    const renderNumericHeader = ({
        label,
        sortBy,
        dataKey,
    }: TableHeaderProps): JSX.Element => {
        const shouldShowSortIndicator = sortBy === dataKey;

        return (
            <div className={styles.Table_numericHeader}>
                <span>{label}</span>
                {shouldShowSortIndicator && (
                    <SortIndicator sortDirection={sortDirection} />
                )}
            </div>
        );
    };

    const renderUnsortableHeader = ({
        label,
    }: TableHeaderProps): JSX.Element => {
        return <Text className={styles.Table_unsortableHeader}>{label}</Text>;
    };

    const renderPagination = (
        <div className={styles.Pagination}>
            <Pagination
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                onPageChange={onPageChange}
                data-testid="productsPagination"
            />
        </div>
    );

    const getVariantsHeight = () =>
        currentOpen?.id
            ? headerRowHeight +
              Math.min(currentOpen.variantsCount, 3.5) * variantRowHeight
            : 0;

    const tableHeight =
        headerRowHeight + rowHeight * products.length + getVariantsHeight();

    // TODO: use onCollapsibleContentChange or other callback from spoton-lib when available
    const handleRowClick = ({
        index,
        event,
    }: Index & {
        event: SyntheticEvent<Element, Event>;
    }) => {
        // target only the button responsible for the expansion of the row
        if (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event.nativeEvent?.target?.tagName === 'BUTTON' ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            event.nativeEvent?.target?.closest('BUTTON')
        ) {
            setCurrentOpen(
                products[index].id !== currentOpen?.id ? products[index] : null,
            );
        }
    };

    const tableColumnsRenderers: Partial<
        Record<IProductsColumns, CallableFunction>
    > = {
        created: renderCreated,
        modified: renderModified,
        image: renderImage,
        name: renderProductInfo,
        stock: renderNumericCell,
        labels: renderLabels,
    };

    const tableHeadersRenderers: Partial<
        Record<IProductsColumns, CallableFunction>
    > = {
        upc: renderUnsortableHeader,
        sku: renderUnsortableHeader,
        stock: renderNumericHeader,
    };

    return (
        <div className={styles.ProductList} data-testid="ProductList">
            <div className={styles.Table}>
                <Table
                    height={tableHeight}
                    headerHeight={headerRowHeight}
                    rowHeight={rowHeight}
                    rowCount={products.length}
                    rowGetter={({ index }: Index) => products[index]}
                    keyGetter={({ index }: Index) => String(products[index].id)}
                    isSelectable
                    selectedKeys={selectedProductKeys}
                    onSelectionChange={onSelectedProductKeysChange}
                    isCollapsible={!isMobile}
                    collapsibleContentHeight={getVariantsHeight()}
                    collapsibleContentGetter={renderVariants}
                    onRowClick={handleRowClick}
                    customRowRenderer={renderRow}
                    collapsibleRowClassName={styles.CollapsibleRow}
                    renderHeaderActions={renderSelectionMenu()}
                    sort={onSortChange}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                >
                    {availableColumnsProducts.map((column) => {
                        if (!usedColumns.includes(column)) {
                            return null;
                        }

                        const {
                            label,
                            hasHiddenLabel,
                            flexShrink,
                            flexGrow,
                            width,
                        } = productsColumnsMap[column];

                        return (
                            <Table.Column
                                key={column}
                                dataKey={column}
                                label={!hasHiddenLabel && label}
                                width={width ?? 200}
                                flexShrink={flexShrink ?? 1}
                                flexGrow={flexGrow ?? 1}
                                cellRenderer={tableColumnsRenderers[column]}
                                headerRenderer={tableHeadersRenderers[column]}
                            />
                        );
                    })}
                </Table>
            </div>
            {renderPagination}
        </div>
    );
}

export default ProductList;
