export interface ILabel {
    title: string;
    price?: string;
    upc: string;
    quantity?: number;
}

export enum LabelSize {
    dymo30299 = 'dymo30299',
    dymo30330 = 'dymo30330',
    dymo30333 = 'dymo30333',
    dymo30334 = 'dymo30334',
    dymo30336 = 'dymo30336',
    dymo99012 = 'dymo99012',
    dymo1738595 = 'dymo1738595',
}

export interface ILabelSize {
    value: LabelSize;
    label: string;
    width: number;
    height: number;
    fontSize: number;
    maxLabelLinesCount: number;
    sampleQuantity?: number;
    showOnlyPrice?: boolean;
    renderer?: (size: ILabelSize, labels: ILabel[]) => void;
}

export type LabelSizesMap = Record<LabelSize, ILabelSize>;
