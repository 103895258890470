import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { generateNumberArray } from 'features/common/utils';
import { Placeholder } from 'features/common/components/Placeholder';

import { IPropTypes } from './PlaceholderList.types';
import styles from './PlaceholderList.module.scss';

export function PlaceholderList(props: IPropTypes) {
    const { lineHeight = 50, headerHeight = 55, hasHeader, className } = props;

    const self = useRef<HTMLDivElement>(null);
    const [linesNumber, setLinesNumber] = useState(1);

    const margin = 4;
    const shouldRenderHeader = hasHeader ?? false;

    // Generate as many lines as needed to cover entire view
    useEffect(() => {
        if (self.current) {
            const { height } = self.current.getBoundingClientRect();
            const heightToBeDivided =
                height + margin - (shouldRenderHeader ? headerHeight : 0);
            const lines = Math.floor(heightToBeDivided / (lineHeight + margin));

            setLinesNumber(lines >= 1 ? lines : 1);
        }
    }, []);

    const lines = generateNumberArray(linesNumber).map((i) => (
        <Placeholder
            lineHeight={lineHeight}
            style={{ margin: `${margin}px 0` }}
            key={i}
        />
    ));

    return (
        <div
            data-testid="PlaceholderList"
            ref={self}
            className={clsx(styles.PlaceholderList, className)}
        >
            {shouldRenderHeader ? (
                <div
                    className={styles.PlaceholderList_header}
                    style={{ height: headerHeight }}
                />
            ) : null}
            {lines}
        </div>
    );
}

export default PlaceholderList;
