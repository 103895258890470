import { Text } from 'spoton-lib';

import { NexusModal } from 'features/common';

import { IPropTypes } from './ImportModalStarted.types';
import styles from './ImportModalStarted.module.scss';

export function ImportModalStarted({ isOpen, onClose, initiator }: IPropTypes) {
    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Product Import Started!" isCentered />
                <Text className={styles.Modal_paragraph}>
                    We’re importing you amazing products now. This should take
                    less than 30 minutes. We’ll send you an email at{' '}
                    <strong>{initiator}</strong> once the file is processed.
                </Text>
                <Text className={styles.Modal_paragraph}>
                    If you’d like, you can close the dialog box and continue
                    working.
                </Text>
                <NexusModal.Footer submitTitle="OK" onSubmit={onClose} />
            </div>
        </NexusModal>
    );
}

export default ImportModalStarted;
