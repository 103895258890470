import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';

export const useImportOpener = (open: (state: true) => void): void => {
    const searchParams = useSearchParams()[0];
    const importId = searchParams.get('importId');

    useEffect(() => {
        if (importId) {
            open(true);
        }
    }, [importId, open]);
};
