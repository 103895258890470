import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'reduxConfig/config';
import initialState from 'reduxConfig/initialState';
import { useFlags } from 'features';

import { OmnichannelRoutes } from 'features/omnichannel/routes';
import { UnsavedPromptProvider } from 'features/shipping/components';
import { MaintenancePage } from 'features/common/components/MaintenancePage';

import { ReactQueryProvider } from './ReactQueryProvider.component';

export function AppProvider({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { showMaintenancePageForCatalog } = useFlags();

    const app = showMaintenancePageForCatalog ? (
        <MaintenancePage />
    ) : (
        <UnsavedPromptProvider>
            <OmnichannelRoutes />
            {children}
        </UnsavedPromptProvider>
    );

    return (
        <Provider store={configureStore(initialState)}>
            <ReactQueryProvider>
                <BrowserRouter>{app}</BrowserRouter>
            </ReactQueryProvider>
        </Provider>
    );
}
