import { nanoid } from '@reduxjs/toolkit';

import {
    cartesianProduct,
    getValidationError,
    IChannel,
    isNotTooLargeCurrency,
} from 'features/common';
import {
    isExistingAndNonEmpty,
    isConditionallyNonEmpty,
    uniqueEntityValue,
    ValidatorsMap,
    ValidatorsResults,
    hasErrors,
    mapObjectToEmptyErrorObject,
    getValidationErrors,
    ValidationResult,
    isPositive,
    isPositiveOrEmpty,
    isAssociatedArrayNotEmpty,
    isNonNegative,
    isValidNumber,
} from 'features/common/utils/validators.utils';
import {
    IAvailabilitySectionErrors,
    IPropFields as AvailabilitySectionFields,
} from 'features/products/components/AvailabilitySection/AvailabilitySection.types';
import {
    IPropFields as ShippingSectionFields,
    IShippingSectionErrors,
} from 'features/products/components/ShippingSection/ShippingSection.types';
import {
    availabilitySectionErrorMessages,
    getInitialAvailabilityData,
    getVariantsDuplicatedValues,
} from 'features/products/components/AvailabilitySection/AvailabilitySection.utils';
import {
    generalSectionErrorMessages,
    getInitialGeneralData,
} from 'features/products/components/GeneralSection/GeneralSection.utils';
import {
    getInitialShippingData,
    shippingSectionErrorMessages,
} from 'features/products/components/ShippingSection/ShippingSection.utils';
import {
    IAvailabilitySectionVariant,
    IAvailabilityChannelsPrice,
    IChannelsErrors,
    IPartialProductData,
    IProduct,
    IProductData,
    IProductOption,
    IProductOptionsErrors,
    IProductVariantOption,
    IVariantsErrors,
    IProductOptionsToRemove,
    IProductVariant,
} from 'features/products/types';
import {
    IGeneralSectionErrors,
    IPropFields as GeneralSectionFields,
} from 'features/products/components/GeneralSection/GeneralSection.types';
import { upcValueLength } from 'features/products/utils/validators';
import { IAvailabilitySectionChannelData } from 'features/common/types/pricingSectionChannelData.type';

import { E_COMMERCE_CHANNEL_ID } from './constants';

export const getInitialProductPageData = (
    locationChannels: IChannel[],
    isLoyaltyEnabled: boolean,
    productData?: IPartialProductData,
): IProductData => {
    return {
        general: getInitialGeneralData(isLoyaltyEnabled, productData),
        availability: getInitialAvailabilityData(locationChannels, productData),
        shipping: getInitialShippingData(productData),
    };
};

export const mapProductOptionsToVariantOptions = (
    productOptions: IProductOption[],
): IProductVariantOption[][] => {
    const optionsValues = productOptions.map<IProductVariantOption[]>(
        (productOption) =>
            productOption.values
                .filter(({ value }) => value)
                .map<IProductVariantOption>((value) => ({
                    attribute: productOption.attribute,
                    value: value.value,
                })),
    );

    const variants = productOptions.length
        ? cartesianProduct(optionsValues)
        : [];

    return variants;
};

const uniqueVariantOption = ({
    attribute,
    value,
}: IProductVariantOption): string => `${attribute}|${value}`;

export const uniqueVariantId = (
    variantOptions: IProductVariantOption[],
): string => variantOptions.map(uniqueVariantOption).sort().join('/');

const combinedVariantName = (variantOptions: IProductVariantOption[]): string =>
    variantOptions.map((option) => option.value).join('/');

export const mapProductOptionsToAvailabilitySectionVariants = (
    allVariantOptions: IProductVariantOption[][],
    existingVariants: IAvailabilitySectionVariant[],
    savedVariants: IAvailabilitySectionVariant[],
    allChannelsIds: string[],
): IAvailabilitySectionVariant[] => {
    return allVariantOptions.map((variantOptions) => {
        const uniqueId = uniqueVariantId(variantOptions);
        const variantName = combinedVariantName(variantOptions);

        const savedVariant = savedVariants.find(
            (variant) => uniqueVariantId(variant.variantOptions) === uniqueId,
        );

        if (savedVariant) {
            return {
                ...savedVariant,
                title: variantName,
                combinedVariantName: variantName,
            };
        }

        const existingVariant = existingVariants.find(
            (variant) => uniqueVariantId(variant.variantOptions) === uniqueId,
        );

        if (existingVariant) {
            return {
                ...existingVariant,
                title: variantName,
                combinedVariantName: variantName,
            };
        }

        const channelsPrice = Object.assign(
            {},
            ...allChannelsIds.map((id) => ({ [id]: '0' })),
        );

        return {
            id: null,
            key: nanoid(),
            title: variantName,
            combinedVariantName: variantName,
            channels: allChannelsIds,
            variantOptions,
            images: [],
            quantity: '',
            sku: '',
            upc: '',
            costPrice: null,
            isExisting: false,
            channelsPrice,
        };
    });
};

export const mapVariantErrorsForExistingVariants = (
    variants: IAvailabilitySectionVariant[],
    variantsErrors: IVariantsErrors,
): IVariantsErrors =>
    variants
        // Remove errors for old variants e.g. with no key matching in current variants
        .reduce(
            (acc, curr) => ({
                ...acc,
                [curr.key]:
                    variantsErrors[curr.key] ??
                    mapObjectToEmptyErrorObject(curr),
            }),
            {},
        );

export const generalSectionValidators = (
    initialGeneralData: GeneralSectionFields,
    { hasVariants }: GeneralSectionFields,
    variants: IAvailabilitySectionVariant[],
    isDuplicated: boolean,
    shouldUseLegacyUPCValidation: boolean,
): ValidatorsMap<GeneralSectionFields> => ({
    name: [
        isConditionallyNonEmpty({
            errorMessage: generalSectionErrorMessages.name.nonBlank,
        }),
        uniqueEntityValue<IProduct>(
            'product',
            'title',
            isDuplicated ? undefined : initialGeneralData.name,
            [],
            {
                errorMessage: generalSectionErrorMessages.name.unique,
            },
        ),
    ],
    ...(hasVariants
        ? {
              hasVariants: [
                  isAssociatedArrayNotEmpty<IAvailabilitySectionVariant>({
                      list: variants,
                      errorMessage:
                          generalSectionErrorMessages.hasVariants.nonEmpty,
                  }),
              ],
          }
        : {
              upc: [
                  upcValueLength(shouldUseLegacyUPCValidation),
                  uniqueEntityValue<IProductVariant>(
                      'product',
                      'upc',
                      isDuplicated ? undefined : initialGeneralData.upc,
                      [],
                      {
                          errorMessage: generalSectionErrorMessages.upc.unique,
                      },
                  ),
              ],
              sku: [
                  uniqueEntityValue<IProductVariant>(
                      'product',
                      'sku',
                      isDuplicated ? undefined : initialGeneralData.sku,
                      [],
                      {
                          errorMessage: generalSectionErrorMessages.sku.unique,
                      },
                  ),
              ],
          }),
});

export const checkHasSomeChannelWithOpenPriceDisabled = (
    channels: IChannel[],
    value: string[],
) =>
    !!value.find((channelId) =>
        channels.find(
            (channel) => channel.id === channelId && !channel.openPriceAllowed,
        ),
    );

export function hasOnlyChannelsWithOpenPriceAllowed({
    condition = true,
    errorMessage,
    channels,
}: {
    condition?: boolean;
    errorMessage: string;
    channels: IChannel[];
}): (value: string[]) => void {
    return (value: string[]) => {
        if (
            condition &&
            checkHasSomeChannelWithOpenPriceDisabled(channels, value)
        ) {
            throw Error(
                errorMessage ??
                    `Variable Priced Items aren't available on some of selected channels`,
            );
        }
    };
}

const productHasEmptyPriceChannelWithOpenPriceDisabled = (
    channels: IAvailabilitySectionChannelData[],
) =>
    channels.some(
        (channel) => !channel.openPriceAllowed && channel.price === null,
    );

export const availabilitySectionValidators = (
    {
        isSamePrice,
        hasVariants,
        channelsAvailability,
        price,
        channels,
        variants,
        isLowStockAlertEnabled,
    }: AvailabilitySectionFields,
    isOpenPriceFeatureAvailable: boolean,
    isLowStockAlertFeatureEnabled: boolean,
    locationActiveChannels: IChannel[],
): ValidatorsMap<AvailabilitySectionFields> => {
    return {
        price: !isSamePrice
            ? []
            : isOpenPriceFeatureAvailable
            ? [
                  isPositiveOrEmpty({
                      errorMessage:
                          availabilitySectionErrorMessages.price.isPositive,
                  }),
                  isConditionallyNonEmpty({
                      condition: !hasVariants
                          ? checkHasSomeChannelWithOpenPriceDisabled(
                                locationActiveChannels,
                                channelsAvailability,
                            )
                          : variants.some((variant) =>
                                checkHasSomeChannelWithOpenPriceDisabled(
                                    locationActiveChannels,
                                    variant.channels,
                                ),
                            ),
                      errorMessage:
                          availabilitySectionErrorMessages.price
                              .isPositiveForChannelWithOpenPriceDisabled,
                  }),
                  isNotTooLargeCurrency({
                      errorMessage:
                          availabilitySectionErrorMessages.price
                              .isNotTooLargeCurrency,
                  }),
              ]
            : [
                  isConditionallyNonEmpty({
                      errorMessage:
                          availabilitySectionErrorMessages.price.nonBlank,
                  }),
                  isPositive({
                      errorMessage:
                          availabilitySectionErrorMessages.price.isPositive,
                  }),
                  isNotTooLargeCurrency({
                      errorMessage:
                          availabilitySectionErrorMessages.price
                              .isNotTooLargeCurrency,
                  }),
              ],
        itemsInStock: [
            isConditionallyNonEmpty({
                condition: !hasVariants,
                errorMessage:
                    availabilitySectionErrorMessages.quantity.nonBlank,
            }),
            isValidNumber({
                errorMessage:
                    availabilitySectionErrorMessages.quantity.nonBlank,
            }),
        ],
        compareAtPrice: [
            isPositiveOrEmpty({
                errorMessage:
                    availabilitySectionErrorMessages.compareAtPrice.isPositive,
            }),
            isNotTooLargeCurrency({
                errorMessage:
                    availabilitySectionErrorMessages.compareAtPrice
                        .isNotTooLargeCurrency,
            }),
        ],
        costPerItem: [
            isPositiveOrEmpty({
                errorMessage:
                    availabilitySectionErrorMessages.costPerItem.isPositive,
            }),
            isNotTooLargeCurrency({
                errorMessage:
                    availabilitySectionErrorMessages.costPerItem
                        .isNotTooLargeCurrency,
            }),
        ],
        lowStockAlertQuantity:
            isLowStockAlertFeatureEnabled && isLowStockAlertEnabled
                ? [
                      isConditionallyNonEmpty({
                          errorMessage:
                              availabilitySectionErrorMessages
                                  .lowStockAlertQuantity.nonBlank,
                      }),
                      isNonNegative({
                          errorMessage:
                              availabilitySectionErrorMessages
                                  .lowStockAlertQuantity.isNonNegative,
                      }),
                  ]
                : [],
        channelsAvailability: [
            hasOnlyChannelsWithOpenPriceAllowed({
                condition:
                    isOpenPriceFeatureAvailable &&
                    !hasVariants &&
                    (isSamePrice
                        ? price === null
                        : productHasEmptyPriceChannelWithOpenPriceDisabled(
                              channels,
                          )),
                errorMessage:
                    availabilitySectionErrorMessages.channelsAvailability
                        .hasOnlyChannelsWithOpenPriceAllowed,
                channels: locationActiveChannels,
            }),
        ],
    };
};

export const shippingSectionValidators: ValidatorsMap<ShippingSectionFields> = {
    value: [
        isPositiveOrEmpty({
            errorMessage: shippingSectionErrorMessages.value.isPositive,
        }),
    ],
};

const variantHasEmptyPriceChannelWithOpenPriceDisabled = (
    variantData: IAvailabilitySectionVariant,
    channels: IChannel[],
) =>
    channels.some(
        (channel) =>
            !channel.openPriceAllowed &&
            !variantData.channelsPrice?.[channel.id],
    );

export const variantValidators = (
    variantData: IAvailabilitySectionVariant,
    initialVariantData: IAvailabilitySectionVariant | undefined,
    skuDuplicates: string[],
    upcDuplicates: string[],
    { price, isSamePrice }: AvailabilitySectionFields,
    isProductDuplicated: boolean,
    shouldUseLegacyUPCValidation: boolean,
    isOpenPriceFeatureAvailable: boolean,
    locationActiveChannels: IChannel[],
): ValidatorsMap<IAvailabilitySectionVariant> => ({
    upc: [
        upcValueLength(shouldUseLegacyUPCValidation),
        uniqueEntityValue<IProductVariant>(
            'product',
            'upc',
            !isProductDuplicated && initialVariantData
                ? initialVariantData.upc
                : '',
            upcDuplicates,
            {
                errorMessage: availabilitySectionErrorMessages.upc.unique,
            },
        ),
    ],
    // if quantity then sku, no quantity no sku
    sku: [
        uniqueEntityValue<IProductVariant>(
            'product',
            'sku',
            !isProductDuplicated && initialVariantData
                ? initialVariantData.sku
                : '',
            skuDuplicates,
            {
                errorMessage: availabilitySectionErrorMessages.sku.unique,
            },
        ),
    ],
    quantity: [
        isConditionallyNonEmpty({
            errorMessage: availabilitySectionErrorMessages.quantity.nonBlank,
        }),
        isValidNumber({
            errorMessage: availabilitySectionErrorMessages.quantity.nonBlank,
        }),
    ],
    costPrice: [
        isPositiveOrEmpty({
            errorMessage: availabilitySectionErrorMessages.costPrice.isPositive,
        }),
        isNotTooLargeCurrency({
            errorMessage:
                availabilitySectionErrorMessages.costPrice
                    .isNotTooLargeCurrency,
        }),
    ],
    channels: [
        hasOnlyChannelsWithOpenPriceAllowed({
            condition:
                isOpenPriceFeatureAvailable &&
                (isSamePrice
                    ? price === null
                    : variantHasEmptyPriceChannelWithOpenPriceDisabled(
                          variantData,
                          locationActiveChannels,
                      )),
            errorMessage:
                availabilitySectionErrorMessages.channelsAvailability
                    .hasOnlyChannelsWithOpenPriceAllowed,
            channels: locationActiveChannels,
        }),
    ],
});

export function isNonEmptyForChannelWithOpenPriceDisabled({
    condition = true,
    errorMessage,
    channels,
    channelId,
}: {
    condition?: boolean;
    errorMessage: string;
    channels: IChannel[];
    channelId: string;
}): (value: number | null) => void {
    return (value: number | null) => {
        if (
            condition &&
            value === null &&
            channels.find(
                (channel) =>
                    channel.id === channelId && !channel.openPriceAllowed,
            )
        ) {
            throw Error(errorMessage ?? `Price can't be blank`);
        }
    };
}

export const channelsPricesValidators = (
    locationActiveChannels: IChannel[],
    channelsAvailability: string[],
    isOpenPriceFeatureAvailable: boolean,
): ValidatorsMap<number> => {
    return channelsAvailability.reduce((acc, curr) => {
        return {
            ...acc,
            [curr]: isOpenPriceFeatureAvailable
                ? [
                      isPositiveOrEmpty({
                          errorMessage:
                              availabilitySectionErrorMessages.price.isPositive,
                      }),
                      isNonEmptyForChannelWithOpenPriceDisabled({
                          errorMessage:
                              availabilitySectionErrorMessages.price
                                  .isPositiveForChannelWithOpenPriceDisabled,
                          channels: locationActiveChannels,
                          channelId: curr,
                      }),
                      isNotTooLargeCurrency({
                          errorMessage:
                              availabilitySectionErrorMessages.price
                                  .isNotTooLargeCurrency,
                      }),
                  ]
                : [
                      isExistingAndNonEmpty({
                          errorMessage:
                              availabilitySectionErrorMessages.price.nonBlank,
                      }),
                      isPositive({
                          errorMessage:
                              availabilitySectionErrorMessages.price.isPositive,
                      }),
                      isNotTooLargeCurrency({
                          errorMessage:
                              availabilitySectionErrorMessages.price
                                  .isNotTooLargeCurrency,
                      }),
                  ],
        };
    }, {});
};

export function isNotEditing(errorMessage?: string): (value: boolean) => void {
    return (value: boolean) => {
        if (value) {
            throw Error(errorMessage ?? 'Values can not be empty!');
        }
    };
}

export const attributesValidators: ValidatorsMap<IProductOption> = {
    isEditing: [
        isNotEditing('Exit edit product option mode to add a new variant'),
    ],
};

const getChannelsPricesErrors = async (
    channels: IChannel[],
    channelsAvailability: string[],
    channelsPrices: IAvailabilityChannelsPrice,
    isOpenPriceFeatureAvailable: boolean,
): Promise<ValidatorsResults<IAvailabilityChannelsPrice>> => {
    const validators = channelsPricesValidators(
        channels,
        channelsAvailability,
        isOpenPriceFeatureAvailable,
    );

    return getValidationErrors<IAvailabilityChannelsPrice>(
        channelsPrices || {},
        validators,
    );
};

export const validateProductOptions = async (
    productOptions: IProductOption[],
) => {
    const productOptionsValidationResult = await Promise.all(
        productOptions.map((productOption) => {
            return getValidationErrors(productOption, attributesValidators);
        }),
    );

    const isProductOptionsValid =
        !productOptionsValidationResult.some(hasErrors);

    const productOptionsErrors = productOptions
        .map((productOption) => {
            if (productOption.attribute !== null) {
                return productOption.attribute;
            }

            // Product option must have a key if it doesn't have an id
            return productOption.key;
        })
        .filter(Boolean)
        .reduce(
            (acc, curr, index) => ({
                ...acc,
                // We're filtering out the product options that don't have ids/keys in the filter call above
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                [curr!]: productOptionsValidationResult[index],
            }),
            {},
        );

    return { productOptionsErrors, isProductOptionsValid };
};

export const validateGeneralSection = async (
    initialGeneralSectionState: GeneralSectionFields,
    generalSectionState: GeneralSectionFields,
    variants: IAvailabilitySectionVariant[],
    isDuplicated: boolean,
    shouldUseLegacyUPCValidation: boolean,
): Promise<ValidationResult<IGeneralSectionErrors>> => {
    const errors = await getValidationErrors<GeneralSectionFields>(
        generalSectionState,
        generalSectionValidators(
            initialGeneralSectionState,
            generalSectionState,
            variants,
            isDuplicated,
            shouldUseLegacyUPCValidation,
        ),
    );

    let productOptionsErrors: IProductOptionsErrors = {};
    let isProductOptionsValid = true;

    if (generalSectionState.hasVariants) {
        ({ productOptionsErrors, isProductOptionsValid } =
            await validateProductOptions(generalSectionState.productOptions));
    }

    return {
        isValid: !hasErrors(errors) && isProductOptionsValid,
        errors: {
            ...mapObjectToEmptyErrorObject(generalSectionState),
            ...errors,
            productOptions: productOptionsErrors,
        },
    };
};

const countErrors = (errorsObject: Record<string, any>): number => {
    return Object.values(errorsObject).reduce((acc, v) => {
        const count = typeof v === 'object' ? countErrors(v) : v !== '' ? 1 : 0;
        return acc + count;
    }, 0);
};

export const revalidateProductOptions = async ({
    productOptions,
    currentProductOptionsErrors,
    setGeneralSectionErrors,
}: {
    productOptions: IProductOption[];
    currentProductOptionsErrors: IProductOptionsErrors;
    setGeneralSectionErrors: (
        value: React.SetStateAction<IGeneralSectionErrors>,
    ) => void;
}) => {
    const { productOptionsErrors } = await validateProductOptions(
        productOptions,
    );

    const howManyCurrentErrors = countErrors(currentProductOptionsErrors);
    const howManyPostValidationErrors = countErrors(productOptionsErrors);
    const isErrorsDecreased =
        howManyPostValidationErrors < howManyCurrentErrors;

    if (isErrorsDecreased) {
        setGeneralSectionErrors((currentErrors) => ({
            ...currentErrors,
            productOptions: productOptionsErrors,
        }));
    }
};

export const revalidateHasVariants = async ({
    hasVariants,
    variants,
    currentError,
    setGeneralSectionErrors,
}: {
    hasVariants: boolean;
    variants: IAvailabilitySectionVariant[];
    currentError: string;
    setGeneralSectionErrors: (
        value: React.SetStateAction<IGeneralSectionErrors>,
    ) => void;
}) => {
    const validators =
        hasVariants && currentError
            ? [
                  isAssociatedArrayNotEmpty<IAvailabilitySectionVariant>({
                      list: variants,
                      errorMessage:
                          generalSectionErrorMessages.hasVariants.nonEmpty,
                  }),
              ]
            : [];

    const errors = await getValidationError(variants, validators);

    setGeneralSectionErrors((currentErrors) => ({
        ...currentErrors,
        hasVariants: errors,
    }));
};

export const validateShippingSection = async (
    shippingSectionState: ShippingSectionFields,
    isECommerceSelected: boolean,
): Promise<ValidationResult<IShippingSectionErrors>> => {
    if (!isECommerceSelected) {
        return { isValid: true, errors: { unit: '', value: '' } };
    }

    const errors = await getValidationErrors<ShippingSectionFields>(
        shippingSectionState,
        shippingSectionValidators,
    );

    return {
        isValid: !hasErrors(errors),
        errors,
    };
};

export const areVariantsValid = async (
    initialAvailabilitySectionState: AvailabilitySectionFields,
    availabilitySectionState: AvailabilitySectionFields,
    isDuplicated: boolean,
    shouldUseLegacyUPCValidation: boolean,
    isOpenPriceFeatureAvailable: boolean,
    locationActiveChannels: IChannel[],
) => {
    let variantsErrors: IVariantsErrors = {};
    let channelsErrors: IChannelsErrors = {};
    let isVariantsValid = true;
    // validate variants
    if (availabilitySectionState.hasVariants) {
        const skuDuplicates = getVariantsDuplicatedValues(
            availabilitySectionState.variants,
            'sku',
        );
        const upcDuplicates = getVariantsDuplicatedValues(
            availabilitySectionState.variants,
            'upc',
        );

        const variantsValidationResult = await Promise.all(
            availabilitySectionState.variants.map((variant) => {
                const initialVariantData =
                    initialAvailabilitySectionState.variants.find(
                        (_variant) => variant.id === _variant.id,
                    );

                return getValidationErrors<IAvailabilitySectionVariant>(
                    variant,
                    variantValidators(
                        variant,
                        initialVariantData,
                        skuDuplicates,
                        upcDuplicates,
                        availabilitySectionState,
                        isDuplicated,
                        shouldUseLegacyUPCValidation,
                        isOpenPriceFeatureAvailable,
                        locationActiveChannels,
                    ),
                );
            }),
        );
        isVariantsValid = !variantsValidationResult.some(hasErrors);

        if (!availabilitySectionState.isSamePrice) {
            const variantsPriceValidationResult = await Promise.all(
                availabilitySectionState.variants.map(
                    ({ channels, channelsPrice }) =>
                        getChannelsPricesErrors(
                            locationActiveChannels,
                            channels,
                            channelsPrice || {},
                            isOpenPriceFeatureAvailable,
                        ),
                ),
            );

            isVariantsValid =
                !variantsPriceValidationResult.some(hasErrors) &&
                isVariantsValid;
            variantsErrors = availabilitySectionState.variants
                .map((variant) => variant.key)
                .reduce(
                    (acc, curr, index) => ({
                        ...acc,
                        [curr]: {
                            ...variantsValidationResult[index],
                            channelsPrice:
                                variantsPriceValidationResult[index] ??
                                undefined,
                        },
                    }),
                    {},
                );
        } else {
            variantsErrors = availabilitySectionState.variants
                .map((variant) => variant.key)
                .reduce(
                    (acc, curr, index) => ({
                        ...acc,
                        [curr]: variantsValidationResult[index],
                    }),
                    {},
                );
        }
    } else if (!availabilitySectionState.isSamePrice) {
        const channelsPrice =
            availabilitySectionState.channels.reduce<IAvailabilityChannelsPrice>(
                (prices, channel) => ({
                    ...prices,
                    [channel.id]: Number.isFinite(channel.price)
                        ? String(channel.price)
                        : null,
                }),
                {},
            );
        channelsErrors = await getChannelsPricesErrors(
            locationActiveChannels,
            availabilitySectionState.channelsAvailability,
            channelsPrice || {},
            isOpenPriceFeatureAvailable,
        );
    }

    return { variantsErrors, channelsErrors, isVariantsValid };
};

export const isAvailabilitySectionValid = async (
    initialAvailabilitySectionState: AvailabilitySectionFields,
    availabilitySectionState: AvailabilitySectionFields,
    isDuplicated: boolean,
    shouldUseLegacyUPCValidation: boolean,
    isOpenPriceFeatureAvailable: boolean,
    isLowStockAlertFeatureEnabled: boolean,
    locationActiveChannels: IChannel[],
): Promise<ValidationResult<IAvailabilitySectionErrors>> => {
    // validate availability data excluding variants and channels
    const availabilityValidationResult =
        await getValidationErrors<AvailabilitySectionFields>(
            availabilitySectionState,
            availabilitySectionValidators(
                availabilitySectionState,
                isOpenPriceFeatureAvailable,
                isLowStockAlertFeatureEnabled,
                locationActiveChannels,
            ),
        );

    let variantsErrors: IVariantsErrors = {};
    let channelsErrors: IChannelsErrors = {};
    let isVariantsValid = true;

    // validate variants
    ({ variantsErrors, channelsErrors, isVariantsValid } =
        await areVariantsValid(
            initialAvailabilitySectionState,
            availabilitySectionState,
            isDuplicated,
            shouldUseLegacyUPCValidation,
            isOpenPriceFeatureAvailable,
            locationActiveChannels,
        ));

    const isValid =
        isVariantsValid &&
        !hasErrors(channelsErrors) &&
        !hasErrors(availabilityValidationResult);

    return {
        isValid,
        errors: {
            ...availabilityValidationResult,
            variants: variantsErrors,
            channels: channelsErrors,
        },
    };
};

export const productOrVariantHasNoActiveChannels = (
    availabilitySectionState: AvailabilitySectionFields,
): boolean => {
    if (availabilitySectionState.hasVariants) {
        return availabilitySectionState.variants.some(
            ({ channels }) => channels.length === 0,
        );
    }

    return availabilitySectionState.channels.length === 0;
};

export const clearAvailabilityPricesError = (
    newState: AvailabilitySectionFields,
    currentState: AvailabilitySectionFields,
    errors: IAvailabilitySectionErrors,
    setErrors: (error: IAvailabilitySectionErrors) => void,
): void => {
    const changedChannel = newState.channels.find((newChannel) => {
        const currentChannel = currentState.channels.find(
            (currentChannel) => currentChannel.id === newChannel.id,
        );

        return currentChannel
            ? currentChannel.price !== newChannel.price
            : false;
    });

    if (changedChannel) {
        setErrors({
            ...errors,
            channels: {
                ...errors.channels,
                [changedChannel.id]: '',
            },
        });
    }
};

export const isECommerceProduct = (
    { hasVariants, variants, channelsAvailability }: AvailabilitySectionFields,
    locationChannels: IChannel[],
): boolean => {
    const ecommerceChannel = locationChannels.find(
        (channel) => String(channel.channelType.id) === E_COMMERCE_CHANNEL_ID,
    );

    // the E-Commerce channel is not available
    if (!ecommerceChannel) {
        return false;
    }

    if (hasVariants) {
        return (
            variants.length > 0 &&
            variants.some((variant) =>
                variant.channels.includes(ecommerceChannel.id),
            )
        );
    }
    return channelsAvailability.includes(ecommerceChannel.id);
};

export const removeDecimalPartFromQuantities = (
    newState: AvailabilitySectionFields,
) => {
    const itemsInStockWithoutDecimalPart = newState.itemsInStock
        ? Math.trunc(newState.itemsInStock)
        : newState.itemsInStock;
    const variantsWithoutDecimalPart =
        newState.variants.length > 0
            ? newState.variants.map((el) => ({
                  ...el,
                  quantity: el.quantity
                      ? Math.trunc(parseFloat(el.quantity)).toString()
                      : el.quantity,
              }))
            : [];

    return {
        itemsInStock: itemsInStockWithoutDecimalPart,
        variants: variantsWithoutDecimalPart,
    };
};

// filter product options based on simplified list of product options to remove
export const filterProductOptions = (
    productOptions: IProductOption[],
    productOptionsToRemove: IProductOptionsToRemove,
) => {
    return productOptions.reduce(
        (updatedOptions: IProductOption[], productOption): IProductOption[] => {
            const newProductOptionValues = productOption.values.filter(
                (optionValue) =>
                    !(
                        productOptionsToRemove[
                            String(productOption.attribute)
                        ] || []
                    ).includes(optionValue.value),
            );

            return [
                ...updatedOptions,
                {
                    ...productOption,
                    values: newProductOptionValues,
                },
            ];
        },
        [],
    );
};

export const getHasNewProductOptions = (
    newProductOptions: IProductOption[],
    oldProductOptions: IProductOption[],
) => {
    return !newProductOptions.every((newProductOption) =>
        oldProductOptions.find(
            (oldOption) =>
                oldOption.attribute === newProductOption.attribute &&
                newProductOption.values.every(
                    (newValue) =>
                        !newValue.value ||
                        oldOption.values.find(
                            (oldValue) => oldValue.value === newValue.value,
                        ),
                ),
        ),
    );
};

// get a simplified list of product options to remove from removed Variant Options and the current list of variants
// if some removed variants had variant options not used in any other variants we should remove those options
// output: [attribute]: [optionId, optionId...]
export const getSimplifiedProductOptionsToRemove = (
    removedVariantOptions: IProductVariantOption[],
    variants: IAvailabilitySectionVariant[],
) =>
    removedVariantOptions.reduce<IProductOptionsToRemove>(
        (optionsToRemove, removedOption) => {
            const shouldNotRemoveThisOption = variants.some((newVariant) => {
                return newVariant.variantOptions.some(
                    (newVariantOption) =>
                        newVariantOption.attribute ===
                            removedOption.attribute &&
                        newVariantOption.value === removedOption.value,
                );
            });

            if (
                !shouldNotRemoveThisOption &&
                removedOption.attribute &&
                removedOption.value
            ) {
                return {
                    ...optionsToRemove,
                    [removedOption.attribute]: [
                        ...(optionsToRemove[removedOption.attribute] || []),
                        removedOption.value,
                    ],
                };
            }

            return optionsToRemove;
        },
        {},
    );

// get a simplified list of product options to remove from old variants state and a new list of inactive variant options
export const getSimplifiedProductOptionsToRemoveAndNewVariantsState = (
    currentVariants: IAvailabilitySectionVariant[],
    inactiveVariantOptions: IProductVariantOption[][],
) => {
    let variantToRemove: IAvailabilitySectionVariant | undefined;
    const newVariants: IAvailabilitySectionVariant[] = [];

    // find variant added to the list of inactive (if exists) and filter it out of current variants
    currentVariants.forEach((variant) => {
        const newInactiveVariant = inactiveVariantOptions.find(
            (inactiveVariantOption) =>
                inactiveVariantOption.length ===
                    variant.variantOptions.length &&
                inactiveVariantOption.every((inactiveOption) =>
                    variant.variantOptions.find(
                        (variantOption) =>
                            variantOption.attribute ===
                                inactiveOption.attribute &&
                            variantOption.value === inactiveOption.value,
                    ),
                ),
        );

        newInactiveVariant
            ? (variantToRemove = variant)
            : newVariants.push(variant);
    });

    const productOptionsToRemove = !variantToRemove
        ? []
        : getSimplifiedProductOptionsToRemove(
              variantToRemove.variantOptions,
              newVariants,
          );

    return [productOptionsToRemove, newVariants];
};
