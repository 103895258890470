import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'reduxConfig';
import { IPartialProductData } from 'features/products/types';
import {
    productDuplicationSelectors,
    productDuplicationActions,
} from 'features/products/redux/productDuplication';

export interface IOwnPropTypes {
    productId?: string;
    isLoading?: boolean;
}

export interface IWeightWarningModal {
    isOpen: boolean;
    withButtons: boolean;
}

export interface IStatePropTypes extends IOwnPropTypes {
    isLoading: boolean;
    isEditing: boolean;
    isDuplicated: boolean;
    duplicatedProductData?: IPartialProductData;
}

export const defaultProps = {};

const mapStateToProps = (
    state: RootState,
    ownProps?: IOwnPropTypes,
): IStatePropTypes => {
    const productId = ownProps?.productId;
    const isLoading = !!ownProps?.isLoading;

    const isEditing = productId !== undefined;

    const duplicatedProductData =
        productDuplicationSelectors.selectDuplicatedProduct(state);
    // if there is productId - show product
    // if there is duplicated data in the store - show duplicated data
    // otherwise show new product form with initial data

    const isDuplicated =
        productId === undefined && duplicatedProductData !== undefined;

    return {
        duplicatedProductData,
        productId,
        isLoading,
        isEditing,
        isDuplicated,
    };
};

const mapDispatchToProps = {
    onCancel: productDuplicationActions.deleteDuplicatedProduct,
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type IPropTypes = ConnectedProps<typeof connector>;
