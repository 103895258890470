import {
    useQuery,
    useMutation,
    useInfiniteQuery,
    usePaginatedQuery,
} from 'features/common/serverStateHandler';
import { QueryKeyT } from 'features/common/serverStateHandler/types';
import {
    categoryGet,
    categoriesGet,
    categoryDelete,
    categoryCreate,
    categoryPatch,
    categoriesAllGet,
} from 'features/categories/api/Categories.data';
import {
    ICategoriesGetParams,
    ICategoriesGetResponse,
} from 'features/categories/api/Categories.types';
import { ICategory } from 'features/categories/types';
import { productVariantsKeys } from 'features/products/services/queryKeys';

import { categoryKeys } from './queryKeys';

const categoriesFetch = (params: ICategoriesGetParams) =>
    categoriesGet({
        page: params.page,
        ordering: params.ordering || 'name',
        pageSize: params.pageSize || 10,
        name: params.name || undefined,
        phrase: params.phrase,
    });

const categoriesFetchAll = () =>
    categoriesAllGet({
        page: 1,
    });

export const useGetCategory = (id: string) => {
    const { data, ...rest } = useQuery(
        categoryKeys.category(id),
        () => categoryGet(id),
        {
            enabled: !!id,
        },
    );

    return {
        singleCategory: data || null,
        ...rest,
    };
};

export const useAllCategories = () => {
    const { data, ...rest } = useQuery(categoryKeys.all, () =>
        categoriesFetchAll(),
    );

    return {
        ...rest,
        categories: data?.results || [],
    };
};

export const useCategories = (
    params: ICategoriesGetParams,
    enabled?: boolean,
) => {
    const { data, ...rest } = usePaginatedQuery<ICategory>({
        params,
        queryFn: categoriesFetch,
        queryKey: categoryKeys.list,
        options: { enabled, staleTime: 0 },
    });

    return {
        categories: data?.results || [],
        ...rest,
    };
};

export const useInfiniteCategories = (params: ICategoriesGetParams) => {
    const { data, ...rest } = useInfiniteQuery<
        QueryKeyT,
        ICategoriesGetResponse,
        unknown,
        ICategoriesGetResponse,
        number
    >(
        categoryKeys.infinity(params.phrase),
        ({ pageParam }) => categoriesFetch({ ...params, page: pageParam }),
        {
            keepPreviousData: true,
        },
    );

    return {
        categories: data?.pages.flatMap((pageData) => pageData.results) || [],
        ...rest,
    };
};

export const useDeleteCategory = (onUpdateSuccess?: () => void) => {
    return useMutation(categoryDelete, {
        onSuccess: ({ queryHandler }) => {
            onUpdateSuccess?.();
            queryHandler.refetchQueries(
                categoryKeys.list({
                    page: 1,
                }),
            );
            queryHandler.invalidateQueries(categoryKeys.list({}));
            queryHandler.invalidateQueries(categoryKeys.infinityList());
            // productVariantsKeys.all has to be removed
            // because ProductPage is freezing cached data on page load (form initialization)
            queryHandler.removeQueries(productVariantsKeys.all);
        },
        formatSuccessMessage: () => 'Category deleted successfully',
        formatErrorMessage: () => 'Error while deleting category. Try again.',
    });
};

export const useCreateCategory = () => {
    return useMutation(categoryCreate, {
        onSuccess: ({ queryHandler }) => {
            queryHandler.invalidateQueries(categoryKeys.list({}));
            queryHandler.invalidateQueries(categoryKeys.infinityList());
            queryHandler.refetchQueries(categoryKeys.list({}));
        },
        formatSuccessMessage: ({ name }) =>
            `Category ${name} created successfully`,
        formatErrorMessage: (_err, { name }) =>
            `Error while creating ${name} category.`,
    });
};

export const useUpdateCategory = () => {
    return useMutation(categoryPatch, {
        onSuccess: ({ queryHandler }) => {
            queryHandler.invalidateQueries(categoryKeys.list({}));
            queryHandler.invalidateQueries(categoryKeys.infinityList());
            queryHandler.refetchQueries(categoryKeys.list({}));
            // productVariantsKeys.all has to be removed
            // because ProductPage is freezing cached data on page load (form initialization)
            queryHandler.removeQueries(productVariantsKeys.all);
        },
        formatSuccessMessage: ({ name }) =>
            `Category ${name} updated successfully`,
        formatErrorMessage: () => 'Error while updating category.',
    });
};
