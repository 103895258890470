import { useState } from 'react';
import clsx from 'clsx';

import { NexusModal } from 'features/common/components';
import { DuplicationState } from 'features/common/components/DuplicationControls/DuplicationControls.types';
import { DuplicationControls } from 'features/common/components/DuplicationControls';

import { IPropTypes } from './DuplicationModal.types';
import styles from './DuplicationModal.module.scss';

export function DuplicationModal(props: IPropTypes) {
    const {
        isOpen,
        onCancel,
        onApply,
        title,
        initialName,
        initialState,
        fieldsLabel,
        inputLabel,
        fields,
        className,
        controlsClassName,
        isLoading,
    } = props;
    const [newName, setNewName] = useState<string>(initialName);
    const [duplicationState, setDuplicationState] =
        useState<DuplicationState>(initialState);

    const handleApply = () => onApply(newName, duplicationState);

    return (
        <div className={clsx(styles.DuplicationModal, className)}>
            <NexusModal
                isOpen={isOpen}
                onRequestClose={onCancel}
                className={styles.Modal}
            >
                <NexusModal.Header title={title} />
                <DuplicationControls
                    fieldsLabel={fieldsLabel}
                    inputLabel={inputLabel}
                    fields={fields}
                    name={newName}
                    duplicationState={duplicationState}
                    onNameChange={setNewName}
                    onStateChange={setDuplicationState}
                    className={controlsClassName}
                />
                <NexusModal.Footer
                    submitTitle="Duplicate"
                    cancelTitle="Cancel"
                    onSubmit={handleApply}
                    isCancelDisabled={isLoading}
                    isSubmitLoading={isLoading}
                    submit-button-data-testid="duplicationModalDuplicate"
                    cancel-button-data-testid="duplicationModalCancel"
                />
            </NexusModal>
        </div>
    );
}

export default DuplicationModal;
