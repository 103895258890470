import error500Src from 'features/common/assets/500.svg';
import ErrorPage from 'features/errors/components/ErrorPage/ErrorPage.component';

import styles from './ErrorPage500.module.scss';

export function ErrorPage500() {
    return (
        <ErrorPage
            message="Something went wrong. Please try again."
            imageSrc={error500Src}
            imageAlt="500 Internal Server Error"
            imageClassName={styles.Image}
        />
    );
}

export default ErrorPage500;
