import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';

import {
    IBusinessHoursDayData,
    IBusinessHoursWorkInterval,
    IHolidayBusinessHours,
    IWeekdayBusinessHours,
} from 'features/settings/types';

export const initialWeekdayBusinessHours: IWeekdayBusinessHours = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
};

export const mapHoursToString = (hours: number[]): string => {
    const start = moment().startOf('day').add(hours[0], 'minute').format('LT');
    const end = moment().startOf('day').add(hours[1], 'minute').format('LT');

    return `${start} - ${end}`;
};

export const mapHoursArrayToString = (hours: number[][]): string => {
    return hours.length > 0 ? hours.map(mapHoursToString).join(', ') : '-';
};

export const convertBusinessHoursToStrings = (
    weekdayBusinessHours: IWeekdayBusinessHours,
): string[] => {
    return [
        weekdayBusinessHours.monday.map(mapHoursToString).join(','),
        weekdayBusinessHours.tuesday.map(mapHoursToString).join(','),
        weekdayBusinessHours.wednesday.map(mapHoursToString).join(','),
        weekdayBusinessHours.thursday.map(mapHoursToString).join(','),
        weekdayBusinessHours.friday.map(mapHoursToString).join(','),
        weekdayBusinessHours.saturday.map(mapHoursToString).join(','),
        weekdayBusinessHours.sunday.map(mapHoursToString).join(','),
    ];
};
// endAt === '00:00' means the end of the day
const getEndOfTheDay = (period: string) =>
    period === '00:00' ? '24:00' : period;

export const checkValidity = (value: IBusinessHoursDayData[]) => {
    const errors = value.reduce((acc, day) => {
        if (!day.isChecked) {
            return acc;
        }

        const sub = day.intervals.reduce((acc, hour, currIndex) => {
            let errorMessage = '';
            const { endAt: prevClose } = day.intervals[currIndex - 1] || {};
            const openTime = moment(hour.startAt, 'HH:mm');
            const previousCloseTime = moment(
                getEndOfTheDay(prevClose),
                'HH:mm',
            );

            const hasOpenError = !/^([01]\d|2[0-3]):?([0-5]\d)$/.test(
                hour.startAt,
            );
            const hasCloseError = !/^([01]\d|2[0-3]):?([0-5]\d)$/.test(
                hour.endAt,
            );
            const hasDayError =
                prevClose && previousCloseTime.isAfter(openTime);

            if (hasOpenError) {
                errorMessage = 'Open time field is not valid!';
            } else if (hasCloseError) {
                errorMessage = 'Close time field is not valid!';
            } else if (hasDayError) {
                errorMessage = 'Hours should not overlap each other!';
            }

            return errorMessage ? { ...acc, [currIndex]: errorMessage } : acc;
        }, {} as Record<string, string>);

        return !isEmpty(sub) ? { ...acc, [day.title]: sub } : acc;
    }, {} as Record<string, Record<string, string>>);

    return !isEmpty(errors) ? errors : null;
};

export const parseMinutesToHours = (minutes: number) => {
    return moment(
        `${Math.trunc(minutes / 60)}:${minutes % 60}`,
        'HH:mm',
    ).format('HH:mm');
};

export const areHoursChanged = (
    currentHours: IWeekdayBusinessHours | null,
    prevHours: IWeekdayBusinessHours | null,
) => {
    return !isEqual(currentHours, prevHours);
};

export const mapHoursToWorkInterval = (
    hours: number[],
): IBusinessHoursWorkInterval => ({
    startAt: parseMinutesToHours(hours[0]),
    endAt: parseMinutesToHours(hours[1]),
});

export const sortHours = ([startA]: number[], [startB]: number[]) =>
    startA - startB;

export const parseTimeStringToMinutes = (time: string) => {
    const [hour, minutes] = time.split(':');
    return Number(hour) * 60 + Number(minutes);
};

/** Returns an array where first element is `start` and second `end` */
export const mapWorkIntervalToBusinessHour = (
    el: IBusinessHoursWorkInterval,
): number[] => {
    const start = parseTimeStringToMinutes(el.startAt);
    const end = parseTimeStringToMinutes(el.endAt);

    return [start, end];
};

/** Returns array of (array of integers with 2 elements) */
export const mapDayDataToBusinessHour = (
    dayData: IBusinessHoursDayData,
): number[][] => {
    return dayData?.isChecked
        ? dayData.intervals
              .map(mapWorkIntervalToBusinessHour)
              .filter((el) => el.length)
        : [];
};

/** Maps weekday intervals to hours */
export const mapWeekdayIntervalsToHours = (
    dayData: IBusinessHoursDayData[],
): IWeekdayBusinessHours => {
    return {
        monday: mapDayDataToBusinessHour(dayData[0]),
        tuesday: mapDayDataToBusinessHour(dayData[1]),
        wednesday: mapDayDataToBusinessHour(dayData[2]),
        thursday: mapDayDataToBusinessHour(dayData[3]),
        friday: mapDayDataToBusinessHour(dayData[4]),
        saturday: mapDayDataToBusinessHour(dayData[5]),
        sunday: mapDayDataToBusinessHour(dayData[6]),
    };
};

/** Maps holiday intervals to hours */
export const mapHolidayIntervalsToHours = (
    dayData: IBusinessHoursDayData[],
): IHolidayBusinessHours[] => {
    return dayData.map((dayData) => ({
        country: 'US',
        name: dayData.title,
        hours: mapDayDataToBusinessHour(dayData),
    }));
};

/** Validates interval */
export const validateInterval = (
    interval: IBusinessHoursWorkInterval,
): boolean => {
    const [first, last] = mapWorkIntervalToBusinessHour(interval);

    if (first && last) {
        return last >= first;
    }

    return true;
};

function rangeOverlaps(a: number[], b: number[]) {
    return Math.max(a[0], b[0]) < Math.min(a[1], b[1]);
}

/** Validates if ranges in an array of ranges don't overlap */
export const validateRangesDontOverlap = (ranges: number[][]): boolean => {
    if (ranges.length > 1) {
        for (let i = 0; i < ranges.length; i++) {
            for (let j = i + 1; j < ranges.length; j++) {
                if (rangeOverlaps(ranges[i], ranges[j])) {
                    return false;
                }
            }
        }
    }
    return true;
};

export const validateEmptyBusinessHours = (
    data: IBusinessHoursDayData[],
): boolean =>
    data.every((dayData) => {
        return (
            !dayData?.isChecked ||
            dayData.intervals.every(
                (interval) =>
                    interval.endAt.length > 0 && interval.startAt.length > 0,
            )
        );
    });

export const validateBusinessHoursIntervalsRanges = (
    data: IBusinessHoursDayData[],
): boolean =>
    data.every((dayData) => {
        return dayData?.isChecked
            ? validateRangesDontOverlap(
                  dayData.intervals.map(mapWorkIntervalToBusinessHour),
              )
            : true;
    });

const transformToBusinessHour = (
    title: string,
    hours: number[][],
): IBusinessHoursDayData => {
    return {
        title,
        isChecked: hours.length > 0,
        intervals: [...hours].sort(sortHours).map(mapHoursToWorkInterval),
    };
};

// Parse data from backend to Business Hours data model
export const parseWeekdayScheduleData = (
    weekdayBusinessHours: IWeekdayBusinessHours | null,
): IBusinessHoursDayData[] =>
    weekdayBusinessHours
        ? [
              transformToBusinessHour('Monday', weekdayBusinessHours.monday),
              transformToBusinessHour('Tuesday', weekdayBusinessHours.tuesday),
              transformToBusinessHour(
                  'Wednesday',
                  weekdayBusinessHours.wednesday,
              ),
              transformToBusinessHour(
                  'Thursday',
                  weekdayBusinessHours.thursday,
              ),
              transformToBusinessHour('Friday', weekdayBusinessHours.friday),
              transformToBusinessHour(
                  'Saturday',
                  weekdayBusinessHours.saturday,
              ),
              transformToBusinessHour('Sunday', weekdayBusinessHours.sunday),
          ]
        : [];

// Parse data from backend to Business Hours data model
export const parseHolidayScheduleData = (
    holidayBusinessHours: IHolidayBusinessHours[] | null,
): IBusinessHoursDayData[] =>
    holidayBusinessHours
        ? holidayBusinessHours.map((businessHour) =>
              transformToBusinessHour(businessHour.name, businessHour.hours),
          )
        : [];

export const markIntervalStart = (
    interval: IBusinessHoursWorkInterval,
    isInvalid: boolean,
): void => {
    interval.isStartAtInvalid = isInvalid;
};

export const markIntervalEnd = (
    interval: IBusinessHoursWorkInterval,
    isInvalid: boolean,
): void => {
    interval.isEndAtInvalid = isInvalid;
};

export const getFirstErrorMessage = (obj: Record<string, any>): any => {
    for (const value of Object.values(obj)) {
        if (typeof value === 'object') {
            return getFirstErrorMessage(value);
        }
        return value;
    }
    return null;
};

export const getFirstErrorMessageInArray = (
    arr: Record<string, any>[],
): any => {
    const obj = arr.find((el) => Object.keys(el).length > 0) || {};

    return getFirstErrorMessage(obj);
};
