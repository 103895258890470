import React, { useContext, useEffect, useState } from 'react';

import { noop } from 'features/common/utils';
import { useShouldBlockNavigation } from 'features/common/hooks';

import { IPropTypes } from './UnsavedPrompt.types';
import { UnsavedPromptRaw } from './UnsavedPromptRaw.component';

type Context = {
    setHasUnsavedChanges: (hasChanges: boolean) => void;
    hasUnsavedChanges: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const UnsavedPromptContext = React.createContext<Context>({
    setHasUnsavedChanges: noop,
    hasUnsavedChanges: false,
});

export const useUnsavedPrompt = (): Context => {
    const context = useContext(UnsavedPromptContext);

    if (!context) {
        throw new Error(
            'useUnsavedPrompt must be used within a UnsavedPromptProvider',
        );
    }

    return context;
};

export const useUnsavedChangesToggle = (isDirty: boolean) => {
    const { setHasUnsavedChanges } = useUnsavedPrompt();

    useEffect(() => {
        setHasUnsavedChanges(isDirty);
    }, [isDirty]);
};

export function UnsavedPromptProvider(props: IPropTypes): JSX.Element {
    const { children } = props;

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const { wasNavigationBlocked, handleCancel, handleOk } =
        useShouldBlockNavigation({
            when: hasUnsavedChanges,
            onSuccess: () => setHasUnsavedChanges(false),
        });

    return (
        <UnsavedPromptContext.Provider
            value={{
                setHasUnsavedChanges,
                hasUnsavedChanges,
            }}
        >
            <UnsavedPromptRaw
                shouldClearOnUnmount={false}
                isModalOpen={wasNavigationBlocked}
                onCloseModal={handleCancel}
                onConfirm={handleOk}
            />
            {children}
        </UnsavedPromptContext.Provider>
    );
}

export default UnsavedPromptProvider;
