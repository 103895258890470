import { SyntheticEvent } from 'react';
import { Input, Text, Checkbox } from 'spoton-lib';
import clsx from 'clsx';

import {
    IDuplicationCheckboxOnClick,
    IDuplicationField,
    DuplicationState,
    IPropTypes,
} from './DuplicationControls.types';
import styles from './DuplicationControls.module.scss';

const renderCheckbox =
    (
        duplicationState: DuplicationState,
        onClick: IDuplicationCheckboxOnClick,
    ) =>
    // eslint-disable-next-line react/display-name
    ({ field, label }: IDuplicationField) =>
        (
            <Checkbox
                key={label}
                checked={duplicationState[field]}
                name={label}
                value={label}
                label={label}
                onChange={() => onClick(field)}
                className={styles.Checkbox}
                data-testid={`duplicationControlsCheckbox-${label}`}
            />
        );

export function DuplicationControls({
    fieldsLabel = 'Select details to copy:',
    inputLabel,
    fields,
    name,
    duplicationState,
    onNameChange,
    onStateChange,
    className,
}: IPropTypes) {
    const handleCopyCheckboxClick: IDuplicationCheckboxOnClick = (key) => {
        const isChecked = duplicationState[key];
        const newData = { ...duplicationState, [key]: !isChecked };

        onStateChange(newData);
    };

    const handleNameChange = (event: SyntheticEvent<Element, Event>) => {
        const name = (event.target as HTMLInputElement).value;

        onNameChange(name);
    };

    return (
        <div
            className={clsx(styles.DuplicationControls, className)}
            data-testid="duplicationControls"
        >
            <div className={styles.InputWrapper}>
                <Input
                    label={inputLabel}
                    value={name}
                    onChange={handleNameChange}
                    className={styles.InputWrapper_nameInput}
                    variant="rounded"
                    data-testid="duplicationControlsNameInput"
                />
            </div>
            <div className={styles.CheckboxWrapper}>
                <Text className={styles.Title}>{fieldsLabel}</Text>
                <div className={styles.Fields}>
                    {fields.map(
                        renderCheckbox(
                            duplicationState,
                            handleCopyCheckboxClick,
                        ),
                    )}
                </div>
            </div>
        </div>
    );
}

export default DuplicationControls;
