/**
 * Serialize backend response
 *
 * This is for use for converting/transforming backend responses that are in
 * `snake_case` to `camelCase` and vice versa.
 *
 * NOTE: the type of object will have to be determined explicitly, this just
 * transforms any type of object.
 *
 * @example
 *
 * // An Axios request function to transform (serialize) the data
 * //
 * // data comes in as `snake_case` and we want it in `camelCase` and any data
 * // sent back to the server should be converted from `camelCase` back into
 * // `snake_case`.
 * function transformData(merchantId: string): AxiosRequestConfig {
 *      return {
 *          transformResponse: [
 *              (data: any): any => {
 *                  return serializeToCamelCase(JSON.parse(data));
 *              },
 *          ],
 *          transformRequest: [
 *              (data: any): any => {
 *                  return JSON.stringify(serializeToSnakeCase(data));
 *              },
 *          ],
 *      };
 * }
 *
 * then in your actual axios call
 *
 * // My api request
 * return axios
 *      .post<IDeliveryServiceEstimate>(
 *          '/v1/my/api',
 *          transformData(),
 *       );
 */
import camelCase from 'lodash.camelcase';
import cloneDeep from 'lodash/cloneDeep';
import snakeCase from 'lodash.snakecase';

/**
 * Recursively modify keys in an object
 *  @param {Object|Array} item - an object or an array to modify
 *  @param {Function} func - a function that modifys the key
 *  @returns {Object|Array} an object with the modified keys
 *
 * WARNING: modifies the object
 */
function modifyKeys(item: any, modifier: any): any {
    if (Array.isArray(item)) {
        return item.map((value) => modifyKeys(value, modifier));
    } else if (
        item !== undefined &&
        item !== null &&
        item.constructor === Object
    ) {
        return Object.keys(item).reduce((acc, key) => {
            return {
                ...acc,
                [modifier(key)]: modifyKeys(item[key], modifier),
            };
        }, {});
    }

    return item;
}

/**
 * Tests passed parameter if it is valid UUID format
 *  @param {string} item - string to be verified against UUID format
 *  @returns {boolean} returns true if item is valid UUID format, false otherwise
 */
function isUuid(item: string): boolean {
    return (
        item.length === 36 &&
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(
            item,
        )
    );
}

/**
 * Convert an objects keys to camelCase
 *  @param {Object|Array} unserializedObject - an object to convert
 *  @returns {Object|Array} returns a copy of the unserialized object with keys
 *   in camel case
 */
export function serializeToCamelCase(unserializedObject: any): any {
    // exceptions to the rule
    function convertToCamelCase(item: string): string {
        // skip uuids conversion
        if (isUuid(item)) {
            return item;
        }

        // convert to camel case by default
        return camelCase(item);
    }

    const object = cloneDeep(unserializedObject);
    return modifyKeys(object, convertToCamelCase);
}

/**
 * Convert an objects keys to snake_case
 *  @param {Object|Array} serializedObject - an object to convert
 *  @returns {Object|Array} returns a copy of the deserialized object with keys
 *   in snake case
 */
export function serializeToSnakeCase(serializedObject: any): any {
    // exceptions to the rule
    function convertToSnakeCase(item: string): string {
        // handle custom maps
        const custom: { [key: string]: string } = {
            /** Shippo 3rd party service uses that name instead generated here `from_address_country_iso_2`  */
            fromAddressCountryIso2: 'from_address_country_iso2',
        };
        if (custom[item] !== undefined) {
            return custom[item];
        }

        // skip uuids conversion
        if (isUuid(item)) {
            return item;
        }

        // convert to snake case by default
        return snakeCase(item);
    }

    const object = cloneDeep(serializedObject);
    return modifyKeys(object, convertToSnakeCase);
}
