import {
    IAvailabilityChannel,
    IAvailabilityChannelsList,
    IProductChannel,
} from 'features/products/types';
import { IDropdownOption } from 'features/common/types/dropdownOption.type';
import {
    IServiceChannelLink,
    IServiceFormChannel,
} from 'features/services/types';
import { IChannel, IChannelName } from 'features/common/types';

interface IAvailabilityLabels {
    allChannels: string;
    unavailable: string;
    available: string;
}

export const defaultAvailabilityLabels: IAvailabilityLabels = {
    allChannels: 'Available in all channels',
    unavailable: 'Unavailable',
    available: 'Available in ',
};

export const getAvailabilityText = (
    channels: IAvailabilityChannel[],
    availabilityList: IAvailabilityChannelsList,
    areAllSelected?: boolean,
    customLabels?: Partial<IAvailabilityLabels>,
): string => {
    const labels = { ...defaultAvailabilityLabels, ...customLabels };
    // if areAllSelected flag has been passed and it's true
    if (areAllSelected) {
        return labels.allChannels;
    }

    if (areAllSelected === undefined) {
        // if areAllSelected flag has not been passed,
        // check if all known channels has been selected
        const isAvailableOnAllChannels = channels.every(
            (channel) => availabilityList[channel.id],
        );

        if (isAvailableOnAllChannels) {
            return labels.allChannels;
        }
    }

    // check if there are no channels selected at all
    const hasAvailability = channels.some(
        (channel) => availabilityList[channel.id],
    );
    if (!hasAvailability) {
        return labels.unavailable;
    }

    // finally return list of channels separated with comma
    const channelsWithAvailability = channels
        .filter((channel) => availabilityList[channel.id])
        .map((channel) => channel.label);
    return `${labels.available}${channelsWithAvailability.join(', ')}`;
};

export const channelLabels: Record<IChannelName, string> = {
    [IChannelName.VIRTUAL_TERMINAL]: 'Virtual Terminal',
    [IChannelName.POINT_OF_SALE]: 'Point of Sale',
    [IChannelName.PAYMENTS_APP]: 'Payments App',
    [IChannelName.E_COMMERCE]: 'E-Commerce',
    [IChannelName.APPOINTMENTS]: 'Appointments',
};

export const getChannelName = (name: IChannelName): string => {
    return channelLabels[name] || name;
};

export const getAvailabilityTextForServices = (
    channels: IChannelName[],
): string => {
    if (channels.length === 0) {
        return 'Unavailable';
    }

    return `Available in ${channels.map(getChannelName).join(', ')}`;
};

export const mapChannelToDropdownOption = ({
    id,
    displayName,
}: IChannel): IDropdownOption => ({
    label: displayName,
    value: String(id),
});

export const duplicateChannelsAvailability = (
    allChannelsIds: string[],
    availabilityChannels: string[],
): string[] =>
    allChannelsIds.filter((channelId) =>
        availabilityChannels.includes(channelId),
    );

export const excludedChannels: IChannelName[] = [
    IChannelName.VIRTUAL_TERMINAL,
    IChannelName.PAYMENTS_APP,
];

export const serviceExcludedChannels: IChannelName[] = [
    IChannelName.E_COMMERCE,
];

export const filterExcludedServiceChannels = (
    channel: IServiceChannelLink,
): boolean =>
    !serviceExcludedChannels.includes(channel.channelName as IChannelName);

export const filterExcludedChannels = (channel: IChannel): boolean =>
    !serviceExcludedChannels.includes(channel.channelName as IChannelName);

export const getChannelPriceByName = (
    channels: IProductChannel[],
    name: IChannelName,
    onlyActive = false,
) => {
    const channel = channels.find(({ channelName }) => channelName === name);
    return !onlyActive || channel?.active ? channel?.salePrice ?? '' : '';
};

export const channelsOrder: Record<IChannelName, number> = {
    [IChannelName.VIRTUAL_TERMINAL]: 1,
    [IChannelName.POINT_OF_SALE]: 2,
    [IChannelName.PAYMENTS_APP]: 3,
    [IChannelName.E_COMMERCE]: 4,
    [IChannelName.APPOINTMENTS]: 5,
};

export const sortChannels = (
    a: IChannel | IServiceFormChannel | IServiceChannelLink,
    b: IChannel | IServiceFormChannel | IServiceChannelLink,
) => channelsOrder[a.channelName] - channelsOrder[b.channelName];
