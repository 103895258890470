import { nanoid } from '@reduxjs/toolkit';

import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import {
    IProductsImportListParams,
    IProductsImportListResponse,
    IProductsImportGetParams,
    IProductsImportGetResponse,
    IProductsImportScheduleParams,
    IProductsImportScheduleS3Params,
    IProductsImportScheduleResponse,
    IProductsImportGetUploadUrlResponse,
    IProductsImportUploadImageS3Params,
    IProductsImportGetUploadUrlParams,
} from './ProductsImport.types';

/** Obtains a paginated result from imports collection. */
export const importsGet = ({ page = 1, pageSize }: IProductsImportListParams) =>
    axios
        .get<IProductsImportListResponse>('/api/v1/imports/', {
            params: { page, pageSize },
        })
        .then(getData);

/** Obtains a specified products import result */
export const importGet = ({ importId }: IProductsImportGetParams) =>
    axios
        .get<IProductsImportGetResponse>(`/api/v1/imports/${importId}/`)
        .then(getData);

export const getUploadUrl = ({ file }: IProductsImportGetUploadUrlParams) => {
    return axios
        .post<IProductsImportGetUploadUrlResponse>(
            '/api/v1/imports/get-upload-url/',
            {
                fileName: `${nanoid()}_${file.name}`,
                contentType: file.type,
            },
        )
        .then(getData);
};

export const uploadImageS3 = ({
    presignedUrl,
    file,
}: IProductsImportUploadImageS3Params) => {
    return axios
        .put(presignedUrl, file, { headers: { 'Content-Type': file.type } })
        .then(getData);
};

/** Schedules a new products import */
export const scheduleImportS3 = async ({
    presignedUrl,
}: IProductsImportScheduleS3Params) => {
    return axios
        .post<IProductsImportScheduleResponse>(
            '/api/v1/imports/',
            {
                template: 'PRODUCTS',
                filePath: presignedUrl,
            },
            { headers: { 'Content-Type': 'application/json' } },
        )
        .then(getData);
};

/** Schedules a new products import */
export const scheduleImportOld = async ({
    file,
}: IProductsImportScheduleParams) => {
    const formData = new FormData();
    formData.append('template', 'PRODUCTS');
    formData.append('file', file);

    return axios.post<IProductsImportScheduleResponse>(
        '/api/v1/imports/',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
    );
};

/** Get xlsx template file */
export const getXlsxTemplate = () =>
    axios.get<Blob>('/api/v1/imports/downloads/sample-files/products/', {
        responseType: 'blob',
    });

/** Get pdf guidelines file */
export const getPdfGuidelines = () =>
    axios.get<Blob>('/api/v1/imports/downloads/overview-files/products/', {
        responseType: 'blob',
    });
