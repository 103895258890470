import { useEffect } from 'react';

import { axios, getConfigVar } from 'features/common';

export function LoginRedirect({ logout }: { logout?: boolean }) {
    useEffect(() => {
        axios.get('/api/v1/logout/').finally(() => {
            const dashboardUrl = getConfigVar(
                'REACT_APP_EXTERNAL_APP_DASHBOARD',
            );
            const urlParams = new URLSearchParams(window.location.href);
            const next = (urlParams.get('next') ?? '/').replace('/logout', '');
            const url = logout
                ? `${dashboardUrl}logout/`
                : `${dashboardUrl}redirect_with_auth?next=${window.location.origin}${next}`;

            window.location.href = url;
        });
    });

    return <div>Redirecting...</div>;
}

export default LoginRedirect;
