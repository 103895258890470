import { useRef, useState } from 'react';
import { Tooltip } from 'spoton-lib';
import clsx from 'clsx';

import { PropsTypes } from './TooltipEnhanced.types';
import styles from './TooltipEnhanced.module.scss';

// TODO: When Tooltip component will have `onBeforeShow` prop, then we will
// be able to get rid of wrapper div's `onMouseEnter` and handle showing tooltip using
// `onBeforeShow` prop.
export function TooltipEnhanced({
    content,
    tooltipClassName,
    contentClassName,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    contentTagName: WrapperTag = 'h3',
    contentTestId,
    ...tooltipProps
}: PropsTypes) {
    const [hasEllipsis, setHasEllipsis] = useState(false);
    const textRef = useRef<HTMLHeadingElement>(null);

    /**
     * Show Tooltip only if its content has ellipsis.
     */
    const handleCalculate = () => {
        const current = textRef.current;

        if (current) {
            const shouldShowEllipsis =
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                current.firstElementChild!.getBoundingClientRect().width > // In this case we are sure that first element (span) exists
                parseFloat(getComputedStyle(current).width);

            setHasEllipsis(!!shouldShowEllipsis);
        }
    };

    return (
        <div
            onMouseEnter={handleCalculate}
            onMouseLeave={() => setHasEllipsis(false)}
        >
            <Tooltip
                portalTarget={document.body}
                delayShow={300}
                //onBeforeShow={handleCalculate} - request prop: calculate width of content and decide wether to show or not
                //disabled={!shouldShow} - request prop
                {...tooltipProps}
                tooltipClassName={clsx(tooltipClassName, {
                    [styles.Tooltip_hidden]: !hasEllipsis,
                })}
                tooltipContent={content}
            >
                <WrapperTag
                    className={contentClassName}
                    ref={textRef}
                    data-testid={contentTestId}
                >
                    <span>{content}</span>
                </WrapperTag>
            </Tooltip>
        </div>
    );
}
