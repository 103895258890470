import {
    IUnitOfMeasureGroup,
    IUnitOfMeasureGroupName,
} from 'features/common/types';

export const getUnitsOfMeasureGroupUnits = (
    unitsOfMeasureGroups: IUnitOfMeasureGroup[],
    groupName: IUnitOfMeasureGroupName,
): string[] | string => {
    const group = unitsOfMeasureGroups.find(
        (group) => group.name === groupName,
    );

    if (!group) {
        return 'Not set';
    }

    const units = group.units
        .filter((unit) => unit.isEnabled)
        .map((unit) => unit.displayName);

    if (units.length === 0) {
        return 'Not set';
    }

    return units.join(', ');
};
