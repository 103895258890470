import { SyntheticEvent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Button, Checkbox, Icon, IconButton, Text } from 'spoton-lib';

import { getConfigVar, NexusModal } from 'features/common';
import * as iconSet from 'features/common/assets/icons';
import { acceptedImportContentTypes } from 'features/products/components/ImportModal/ImportModal.constants';

import { IPropTypes } from './ImportModalForm.types';
import styles from './ImportModalForm.module.scss';

export function ImportModalForm({
    isOpen,
    onClose,
    onSubmit,
    onOpenPrevious,
}: IPropTypes) {
    const [hasAgreedTerms, setHasAgreedTerms] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const importGuidelinesSrc = getConfigVar(
        'REACT_APP_IMPORT_GUIDELINES',
    ) as string;
    const importTemplateSrc = getConfigVar(
        'REACT_APP_IMPORT_TEMPLATE',
    ) as string;

    const onDropAccepted = useCallback(([file]) => {
        setFile(file);
        setError(null);
    }, []);
    const onDropRejected = useCallback(() => {
        setError('Invalid file format. File format must be either CSV or XLSX');
    }, []);
    const handleFileClear = useCallback(
        (event: SyntheticEvent<Element, Event>) => {
            event.stopPropagation();
            setFile(null);
        },
        [setFile],
    );
    const handleSubmit = useCallback(() => {
        if (file) {
            onSubmit({ file });
        }
    }, [onSubmit, file]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropAccepted,
        onDropRejected,
        accept: acceptedImportContentTypes,
        multiple: false,
    });

    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Import Products" />
                <Text className={styles.Modal_paragraph} type="sub2" as="p">
                    Upload CSV or XLSX
                </Text>
                <Text className={styles.Modal_paragraph}>
                    Files must be formatted as a .CSV or .XLSX file. We’ve
                    created a custom template based on your product attributes.
                    This template will show you how to structure your data for
                    an easy successfull import. To help minimize errors and
                    ensure your data is imported successfully, download our{' '}
                    <a
                        href={importGuidelinesSrc}
                        download="SpotOn Product Import Overview.pdf"
                        className={styles.Modal_link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        import guidelines
                    </a>{' '}
                    for more information.
                </Text>
                <Button
                    href={importTemplateSrc}
                    download="SpotOn Product Import Template.csv"
                    variant="tertiary"
                    type="button"
                >
                    Download Template
                </Button>
                <div
                    {...getRootProps()}
                    className={clsx(
                        styles.Dropzone,
                        error && styles.Dropzone___withError,
                        isDragActive && styles.Dropzone___dragActive,
                        !isDragActive && file && styles.Dropzone___withFile,
                    )}
                >
                    <input {...getInputProps()} />
                    {file && !isDragActive ? (
                        <>
                            <Icon
                                alt="CSV file icon"
                                name="CsvFileIcon"
                                size={24}
                                className={styles.Dropzone_icon}
                                iconSet={iconSet}
                            />
                            <Text className={styles.Dropzone_label}>
                                {file.name}
                            </Text>
                            <IconButton
                                variant="secondary"
                                name="CloseIcon"
                                alt="Remove"
                                onClick={handleFileClear}
                                className={styles.Dropzone_removeButton}
                                type="button"
                            />
                        </>
                    ) : (
                        <>
                            <Icon
                                alt="File upload icon"
                                name="FileUploadIcon"
                                size={24}
                                className={styles.Dropzone_icon}
                            />
                            <Text className={styles.Dropzone_label}>
                                {isDragActive
                                    ? 'Drop your file here'
                                    : 'Browse or drag and drop your file'}
                            </Text>
                        </>
                    )}
                </div>
                <div className={styles.Message}>
                    <Text className={styles.Error}>{error}</Text>
                    {onOpenPrevious && (
                        <button
                            onClick={onOpenPrevious}
                            className={styles.Modal_link}
                            type="button"
                        >
                            View previous import
                        </button>
                    )}
                </div>
                <Checkbox
                    label={
                        <Text>
                            I agree to the{' '}
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.spoton.com/legal/"
                                className={styles.Modal_link}
                            >
                                Terms & Conditions
                            </a>
                        </Text>
                    }
                    checked={hasAgreedTerms}
                    onChange={(event) =>
                        setHasAgreedTerms(event.currentTarget.checked)
                    }
                />
                <NexusModal.Footer
                    submitTitle="Import"
                    cancelTitle="Cancel"
                    onSubmit={handleSubmit}
                    isSubmitDisabled={!hasAgreedTerms || !file}
                />
            </div>
        </NexusModal>
    );
}

export default ImportModalForm;
