import { useChannels, useFlags } from 'features/common/hooks';
import { ErrorsBanner } from 'features/common/components';

import { IPropTypes } from './ProductErrorsBanner.types';
import {
    getErrorsListFromAvailabilityErrors,
    getErrorsListFromGeneralErrors,
    getErrorsListFromShippingErrors,
} from './ProductErrorsBanner.utils';

export function ProductErrorsBanner(props: IPropTypes) {
    /* @TODO Add smoothScrollTop mechanism to ProductErrorsBanner (from ServiceErrorsBanner) */
    const {
        availabilitySectionErrors,
        generalSectionErrors,
        shippingSectionErrors,
    } = props;

    const { isOpenPriceFeatureAvailable } = useFlags();
    const { locationChannels } = useChannels();

    const channelNamesWithOpenPriceDisabled = locationChannels
        .filter(({ openPriceAllowed }) => !openPriceAllowed)
        .map(({ displayName }) => displayName)
        .join(', ');

    const openPriceItemErrorMessage = `Variable Priced Items won’t be available on ${channelNamesWithOpenPriceDisabled}. 
    Please remove ${channelNamesWithOpenPriceDisabled} from the variants highlighted`;

    // get all unique errors
    const errors = new Set([
        ...getErrorsListFromGeneralErrors(generalSectionErrors),
        ...getErrorsListFromAvailabilityErrors(
            isOpenPriceFeatureAvailable,
            openPriceItemErrorMessage,
            availabilitySectionErrors,
        ),
        ...getErrorsListFromShippingErrors(shippingSectionErrors),
    ]);

    if (errors.size === 0) {
        return null;
    }

    return (
        <ErrorsBanner
            title="There are errors with this product"
            errors={Array.from(errors)}
        />
    );
}

export default ProductErrorsBanner;
