import {
    ContextType,
    useCallback,
    useState,
    useContext,
    useEffect,
} from 'react';
import {
    Navigator as BaseNavigator,
    UNSAFE_NavigationContext,
    useNavigate,
} from 'react-router-dom';
import type { Blocker, History, Transition } from 'history';

interface INavigator extends BaseNavigator {
    block: History['block'];
}

type NavigationContextWithBlock = ContextType<
    typeof UNSAFE_NavigationContext
> & { navigator: INavigator };

export function useShouldBlockNavigation({
    when,
    onSuccess,
}: {
    when: boolean;
    onSuccess?: () => void;
}): {
    wasNavigationBlocked: boolean;
    handleCancel: () => void;
    handleOk: () => void;
} {
    const [lastTransition, setLastTransition] = useState<Transition>();
    const { navigator } = useContext(
        UNSAFE_NavigationContext,
    ) as NavigationContextWithBlock;

    const navigate = useNavigate();

    const handleCancel = () => {
        setLastTransition(undefined);
    };

    const handleOk = () => {
        onSuccess?.();
        if (lastTransition?.action === 'POP') {
            navigate(-1);
        } else if (lastTransition?.location) {
            navigate(lastTransition.location.pathname);
        }
        setLastTransition(undefined);
    };

    const blocker: Blocker = useCallback(
        ({ action, location, retry }: Transition) => {
            switch (action) {
                case 'REPLACE': {
                    retry();
                    return;
                }
                case 'POP': {
                    setLastTransition({ action, location, retry });
                    return;
                }
                case 'PUSH': {
                    setLastTransition({ action, location, retry });
                    return;
                }
            }
        },
        [],
    );

    useEffect(() => {
        if (!when) return;
        if (lastTransition) return;
        if (!('block' in navigator)) return;
        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };
            blocker(autoUnblockingTx);
        });
        return unblock;
    }, [navigator, blocker, when, lastTransition]);

    return {
        wasNavigationBlocked: Boolean(lastTransition),
        handleCancel,
        handleOk,
    };
}
