import { UserApi } from 'features/common/api';
import { useQuery } from 'features/common/serverStateHandler';

export const currentUserQueryKeys = {
    current: [{ scope: 'currentUser' }] as const,
};

export const useGetCurrentUser = (isEnabled = true) => {
    const { data, isError, isLoading, refetch, ...result } = useQuery(
        currentUserQueryKeys.current,
        UserApi.userGetCurrentUserDetails,
        {
            enabled: isEnabled,
            // this data wont change during the session
            staleTime: Infinity,
            dataFunctionName: 'currentUser',
        },
    );

    return {
        ...result,
        currentUser: data,
        isCurrentUserLoading: isLoading,
        isCurrentUserError: isError,
        refetchCurrentUser: refetch,
    };
};
