import { generatePathFromRoot } from 'features/common/utils/routing.utils';
import {
    newProductsMountPath,
    productsMountPath,
} from 'features/products/routes/products.paths';
import { servicesMountPath } from 'features/services/routes/services.paths';
import { categoriesMountPath } from 'features/categories/routes/categories.paths';
import { settingsMountPath } from 'features/settings/routes/settings.paths';
import { vendorsMountPath } from 'features/vendors/routes/vendors.paths';

// paths used in routing definition
export const omnichannelLocalPaths = {
    root: '/',
    error: '/error',
    merchantLocations: '/locations',
    login: '/login',
    logout: '/logout',
    products: productsMountPath,
    /* @TODO: [Product Form Formik] Remove additional routing within last task */
    newProducts: newProductsMountPath,
    services: servicesMountPath,
    categories: categoriesMountPath,
    settings: settingsMountPath,
    vendors: vendorsMountPath,
} as const;

// paths used for navigation
export const omnichannelPaths = {
    root: generatePathFromRoot(omnichannelLocalPaths.root),
    error: generatePathFromRoot(omnichannelLocalPaths.error),
    merchantLocations: generatePathFromRoot(
        omnichannelLocalPaths.merchantLocations,
    ),
    login: generatePathFromRoot(omnichannelLocalPaths.login),
    logout: generatePathFromRoot(omnichannelLocalPaths.logout),
} as const;
