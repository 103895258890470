import { useState } from 'react';
import { FormikProvider, FieldArray, Field, Form, useFormik } from 'formik';
import { v4 as uuid } from 'uuid';
/* eslint-disable no-restricted-imports */
import {
    Checkbox,
    IconButton,
    Button,
    colors,
    Text,
    Row,
    Col,
    Container,
} from 'spoton-lib';
/* eslint-enable no-restricted-imports */
import clsx from 'clsx';

import { NumberFormatField } from 'features/common/components/NumberFormatField';
import { DeletePrompt } from 'features/common/components';
import { TextField } from 'features/common/components/TextField';
import { ShippingRateOptionFooter } from 'features/shipping/components/ShippingRateOptionFooter';
import { useUnsavedChangesToggle } from 'features/shipping/components/UnsavedPrompt';

import { ScrollToFieldError } from './FieldErrorScroller/FieldErrorScroller.component';
import { validationSchema } from './FlatRateShippingCostForm.utils';
import {
    IPropsTypes,
    ICostData,
    IDeletePromptProps,
} from './FlatRateShippingCostForm.types';
import styles from './FlatRateShippingCostForm.module.scss';

const initialData: ICostData = {
    deliveryTime: '',
    shippingPrice: null,
    minSpentPrice: null,
    rateName: '',
    withMinSpent: false,
    fieldId: uuid(),
};

export function FlatRateShippingCostForm({
    onCancel,
    onSubmit,
    initialValues,
}: IPropsTypes) {
    const formik = useFormik({
        initialValues: {
            costs: initialValues?.costs || [initialData],
        },
        onSubmit,
        validationSchema,
    });
    const [removeRateHandler, setRemoveRateHandler] =
        useState<IDeletePromptProps>();

    const {
        values: { costs },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dirty,
        isSubmitting,
    } = formik;

    useUnsavedChangesToggle(dirty);

    const onRemoveRate = () => {
        removeRateHandler?.removeRate();
        setRemoveRateHandler(undefined);
    };
    const areMultipleCosts = costs.length > 1;

    return (
        <FormikProvider value={formik}>
            <Form
                className={clsx(styles.Content, {
                    [styles.Content_withSingleCost]: !areMultipleCosts,
                })}
                data-testid="FlatRateShippingCostForm"
            >
                <ScrollToFieldError />
                <FieldArray name="costs">
                    {({ push, remove }) => (
                        <>
                            {costs.map(
                                ({ fieldId, withMinSpent, rateName }, idx) => {
                                    const onRemoveIconClick = () => {
                                        setRemoveRateHandler({
                                            rateName,
                                            removeRate: () => remove(idx),
                                        });
                                    };

                                    return (
                                        <Container
                                            key={fieldId}
                                            fluid
                                            className={styles.Container}
                                        >
                                            {costs.length > 1 && (
                                                <Row
                                                    className={
                                                        styles.SingleRateTitle
                                                    }
                                                    justify="between"
                                                >
                                                    <Text type="h6">
                                                        Flat Rate {idx + 1}
                                                    </Text>
                                                    <IconButton
                                                        data-testid="icon-button"
                                                        name="ClearSolidIcon"
                                                        onClick={
                                                            onRemoveIconClick
                                                        }
                                                        alt="Delete"
                                                        color={colors.base50}
                                                        buttonSize="large"
                                                        size={22}
                                                        disableBorder
                                                        className={
                                                            styles.RemoveButton
                                                        }
                                                        buttonProps={{
                                                            type: 'button',
                                                        }}
                                                    />
                                                </Row>
                                            )}
                                            <Row className={styles.Row}>
                                                <Col>
                                                    <TextField
                                                        name={`costs.${idx}.rateName`}
                                                        placeholder="E.g. Free Shipping"
                                                        label="Rate name"
                                                    />
                                                </Col>
                                                <Col>
                                                    <NumberFormatField
                                                        name={`costs.${idx}.shippingPrice`}
                                                        placeholder="$0.00"
                                                        label="Shipping price"
                                                        prefix="$"
                                                        decimalScale={2}
                                                        data-testid="costs.shippingPrice"
                                                        shouldShowInstantError={
                                                            false
                                                        }
                                                    />
                                                </Col>
                                            </Row>

                                            <Row className={styles.Row}>
                                                <Col xs={6}>
                                                    <TextField
                                                        name={`costs.${idx}.deliveryTime`}
                                                        placeholder="E.g. 5 - 8 days"
                                                        label="Estimated delivery time"
                                                        primaryCondition="Optional"
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Field
                                                        as={Checkbox}
                                                        checked={withMinSpent}
                                                        name={`costs.${idx}.withMinSpent`}
                                                        label="Offer free shipping with a minimum spent at checkout"
                                                    />
                                                </Col>
                                            </Row>

                                            {withMinSpent && (
                                                <Row
                                                    className={
                                                        styles.RowMinPrice
                                                    }
                                                >
                                                    <Col xs={6}>
                                                        <NumberFormatField
                                                            name={`costs.${idx}.minSpentPrice`}
                                                            placeholder="$0.00"
                                                            label="Set minimum spent"
                                                            prefix="$"
                                                            decimalScale={2}
                                                            data-testid={`costs.${idx}.minSpentPrice`}
                                                            shouldShowInstantError={
                                                                false
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </Container>
                                    );
                                },
                            )}
                            <Container>
                                <Row className={styles.Row}>
                                    <Col>
                                        <Button
                                            className={styles.AddRateButton}
                                            variant="secondary"
                                            type="button"
                                            onClick={() =>
                                                push({
                                                    ...initialData,
                                                    fieldId: uuid(),
                                                })
                                            }
                                        >
                                            Add another flat rate +
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )}
                </FieldArray>
                <div
                    className={clsx(styles.FooterWrapper, {
                        [styles.FooterWrapper_withWidth]: !areMultipleCosts,
                    })}
                >
                    <ShippingRateOptionFooter
                        onClose={onCancel}
                        onSave={() => onSubmit}
                        isSaveButtonDisabled={isSubmitting}
                        isCancelButtonDisabled={isSubmitting}
                        isLoading={isSubmitting}
                    />
                </div>
            </Form>
            <DeletePrompt
                isOpen={!!removeRateHandler}
                title={`Are you sure you want to delete ${
                    removeRateHandler?.rateName || 'this rate'
                }?`}
                message="If you delete this rate, you won't be able to access it again"
                onCancel={() => setRemoveRateHandler(undefined)}
                onConfirm={onRemoveRate}
            />
        </FormikProvider>
    );
}

export default FlatRateShippingCostForm;
