export function getVisitedPages(): string[] {
    const visited = localStorage.getItem('visited') || '[]';
    return JSON.parse(visited);
}

export function getPageVisited(page: string): boolean {
    const visited = getVisitedPages();
    return visited.includes(page);
}

export function setVisitedPage(page: string): void {
    const visited = getVisitedPages();
    const set = new Set([...visited, page]);
    const pages = Array.from(set);
    localStorage.setItem('visited', JSON.stringify(pages));
}
