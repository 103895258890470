import error403Src from 'features/common/assets/403.svg';
import ErrorPage from 'features/errors/components/ErrorPage/ErrorPage.component';

import styles from './ErrorPage403.module.scss';

export function ErrorPage403() {
    return (
        <ErrorPage
            message="You don't have permission to access this page."
            imageSrc={error403Src}
            imageAlt="403 Forbidden"
            imageClassName={styles.Image}
        />
    );
}

export default ErrorPage403;
