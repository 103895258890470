import { Skeleton } from 'spoton-lib';

import styles from './PageLoader.module.scss';

const headerSchema = [
    {
        width: '15%',
        height: '3.75rem',
    },
    {
        width: '20%',
        height: '3.75rem',
    },
];

const subHeaderSchema = [
    {
        width: '45%',
        height: '2rem',
    },
];

const titleSchema = [
    {
        width: '100%',
        height: '3.75rem',
    },
];

const contentSchema = [
    {
        width: '100%',
        height: '50rem',
    },
];

export function PageLoader() {
    return (
        <div className={styles.Wrapper}>
            <Skeleton
                className={styles.Wrapper_header}
                schema={headerSchema}
                schemaDirection="row"
            />
            <Skeleton
                className={styles.Wrapper_subHeader}
                schema={subHeaderSchema}
                schemaDirection="row"
            />
            <Skeleton
                className={styles.Wrapper_title}
                schema={titleSchema}
                schemaDirection="row"
            />
            <Skeleton
                className={styles.Wrapper_content}
                schema={contentSchema}
                schemaDirection="row"
            />
        </div>
    );
}
