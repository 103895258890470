import { useMemo, useState } from 'react';
import { Text, Banner, LoadingOverlay, showToast } from 'spoton-lib';
import { Formik } from 'formik';

import { NexusModal } from 'features/common/components';
import { IChannelName, validateWithContext } from 'features/common';
import {
    useBulkUpdate,
    useGetVariantsByParents,
} from 'features/products/services/products';
import { IBulkAction } from 'features/products/types';
import printingGuidelinesSrc from 'features/common/assets/barcode_printing_guidelines.pdf';

import { BarcodePrintingForm, validationSchema } from './BarcodePrintingForm';
import {
    IBarcodePrintingFormData,
    IBarcodePrintingVariantFormData,
} from './BarcodePrintingForm/BarcodePrintingForm.types';
import {
    defaultBarcodesRenderer,
    labelSizesMap,
    labelSizesOptions,
} from './BarcodePrinting.utils';
import styles from './BarcodePrintingModal.module.scss';
import { IPropTypes } from './BarcodePrintingModal.types';

export function BarcodePrintingModal({ onClose, productIds }: IPropTypes) {
    const [variantsCount, setVariantsCount] = useState(0);
    const { mutateAsync: bulkUpdate, isLoading: isUpdatingUpcs } =
        useBulkUpdate();
    const { variants, isFetching } = useGetVariantsByParents(
        productIds,
        IChannelName.POINT_OF_SALE,
    );
    const initialData = {
        size: labelSizesOptions[0].value,
        variants: variants.map(({ id, title, price, upc = '' }) => ({
            id,
            title,
            price,
            upc,
            initialUpc: upc,
            isSelected: true,
            quantity: 1,
        })),
    };
    const hasInitiallyEmptyUpcs = initialData.variants.some(({ upc }) => !upc);

    const updateUpcs = async (variants: IBarcodePrintingVariantFormData[]) => {
        const productsUpcs = variants
            .filter(({ upc, initialUpc }) => upc !== initialUpc)
            .reduce((map, { id, upc }) => ({ ...map, [id]: { upc } }), {});
        const productIds = Object.keys(productsUpcs);

        if (productIds.length > 0) {
            await bulkUpdate({
                action: IBulkAction.changeUpc,
                parameters: [
                    { filters: { id: productIds }, value: [productsUpcs] },
                ],
            });
        }
    };

    const validate = useMemo(() => validateWithContext(validationSchema()), []);

    const onSubmit = async ({ size, variants }: IBarcodePrintingFormData) => {
        const selected = variants.filter(({ isSelected }) => isSelected);
        const labelsCount = selected.reduce(
            (sum, { quantity }) => sum + quantity,
            0,
        );

        try {
            await updateUpcs(selected);
        } catch (error) {
            // skip printing since assigned UPC's couldn't be saved
            return;
        }

        const currentLabelSize = labelSizesMap[size];

        const renderer = currentLabelSize.renderer
            ? currentLabelSize.renderer
            : defaultBarcodesRenderer;

        renderer(currentLabelSize, selected);
        onClose();
        showToast({
            variant: 'success',
            title:
                labelsCount === 1
                    ? '1 label generated'
                    : `${labelsCount} labels generated`,
            content: 'Please continue with regular print procedure.',
            autoClose: 4000,
        });
    };

    return (
        <>
            <NexusModal
                isOpen={!isFetching}
                onRequestClose={onClose}
                className={styles.Modal}
                shouldReturnFocusAfterClose={false}
            >
                <Formik
                    initialValues={initialData}
                    validate={validate}
                    onSubmit={onSubmit}
                >
                    <div className={styles.Modal_content}>
                        <NexusModal.Header
                            title={
                                (variantsCount === 0 &&
                                    'No Variants Selected') ||
                                (variantsCount === 1 &&
                                    `${variantsCount} Variant Selected`) ||
                                `${variantsCount} Variants Selected`
                            }
                        />
                        <Text as="p" className={styles.Modal_subheader}>
                            Print your barcodes and labels so you can sell your
                            products faster.{' '}
                            <a
                                href={printingGuidelinesSrc}
                                download="SpotOn Barcode Printing Guidelines.pdf"
                                className={styles.Modal_link}
                            >
                                Click here
                            </a>{' '}
                            to learn more.
                        </Text>
                        {hasInitiallyEmptyUpcs && (
                            <Banner
                                className={styles.Modal_banner}
                                message="Barcode labels require a UPC. Please enter valid UPCs below or deselect products to remove them from the printing queue"
                                variant="informative"
                            />
                        )}
                        <BarcodePrintingForm
                            onChangeSelectedVariantsCount={setVariantsCount}
                        />
                    </div>
                </Formik>
            </NexusModal>
            {(isFetching || isUpdatingUpcs) && <LoadingOverlay />}
        </>
    );
}

export default BarcodePrintingModal;
