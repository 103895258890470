import {
    uniqueEntityValue,
    isConditionallyNonEmpty,
    hasLength,
    isPositiveOrEmpty,
} from 'features/common';
import { IInputValidators } from 'features/common/components/ValidateableInput/ValidateableInput.types';
import { INumberFormatValidators } from 'features/common/components/ValidateableNumberFormat/ValidateableNumberFormat.types';
import {
    IAvailabilitySectionVariant,
    IProductVariant,
} from 'features/products/types';
import { upcValueLength } from 'features/products/utils/validators';
import { availabilitySectionErrorMessages } from 'features/products/components/AvailabilitySection/AvailabilitySection.utils';

export const getVariantInlineValidators: Record<
    keyof Pick<
        IAvailabilitySectionVariant,
        'sku' | 'upc' | 'quantity' | 'costPrice'
    >,
    (
        initialValue: string,
        duplicateValues: string[],
        shouldUseLegacyUPCValidation?: boolean,
    ) => INumberFormatValidators | IInputValidators
> = {
    sku: (initialValue, duplicateValues) => ({
        onChange: [
            uniqueEntityValue<IProductVariant>(
                'product',
                'sku',
                initialValue,
                duplicateValues,
                {
                    errorMessage: availabilitySectionErrorMessages.sku.unique,
                },
            ),
        ],
    }),
    upc: (initialValue, duplicateValues, shouldUseLegacyUPCValidation) => ({
        onBlur: [upcValueLength(shouldUseLegacyUPCValidation)],
        onChange: [
            hasLength({
                length: 12,
                errorMessage: '',
            }),
            uniqueEntityValue<IProductVariant>(
                'product',
                'upc',
                initialValue,
                duplicateValues,
                {
                    errorMessage: availabilitySectionErrorMessages.upc.unique,
                },
            ),
        ],
    }),
    quantity: () => ({
        onBlur: [
            isConditionallyNonEmpty({
                errorMessage:
                    availabilitySectionErrorMessages.quantity.nonBlank,
            }),
        ],
        onChange: [
            isConditionallyNonEmpty({
                errorMessage:
                    availabilitySectionErrorMessages.quantity.nonBlank,
            }),
        ],
    }),
    costPrice: () => ({
        onBlur: [
            isPositiveOrEmpty({
                errorMessage:
                    availabilitySectionErrorMessages.costPrice.isPositive,
            }),
        ],
    }),
};

export function getVariantName(name: string): string {
    return name.replace(/\//g, ' / ');
}
