import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

import {
    IWeightLimitCheckPostPayload,
    IWeightLimitCheckResponse,
} from './WeightLimitCheck.types';

/** Check weight limit */
export function checkLimit(
    payload: IWeightLimitCheckPostPayload,
): Promise<AxiosResponse<IWeightLimitCheckResponse>> {
    return axios.post<IWeightLimitCheckResponse>(
        `api/v1/check-weight-limit/`,
        payload,
    );
}
