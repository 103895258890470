import React, { useState, createRef, useRef } from 'react';
import {
    MultiGrid,
    CellContentProps,
    Index,
    IconButton,
    ImageSelectionButton,
    ImageSelector,
    NumberFormat,
    colors,
    Tooltip,
    Icon,
} from 'spoton-lib';
import { mapValues } from 'lodash';

import * as iconSet from 'features/common/assets/icons';
import { useFlags, ValidationMode } from 'features/common';
import {
    ImageUploader,
    SelectionDropdown,
    ValidateableInput,
    ValidateableNumberFormat,
} from 'features/common/components';
import { IAvailabilitySectionVariant } from 'features/products/types';
import { IImage } from 'features/common/components/ImageUploader/ImageUploader.types';
import { closeMenuOnScroll } from 'features/common/utils/dropdown.utils';
import {
    areUnitsInStockCorrect,
    getVariantsDuplicatedValues,
} from 'features/products/components/AvailabilitySection/AvailabilitySection.utils';

import { IPropTypes } from './ProductVariantsAvailabilityGrid.types';
import styles from './ProductVariantsAvailabilityGrid.module.scss';
import {
    getVariantInlineValidators,
    getVariantName,
} from './ProductVariantsAvailabilityGrid.utils';

export function ProductVariantsAvailabilityGrid(props: IPropTypes) {
    const {
        variants,
        channels,
        isDifferentPricingPerChannel,
        onChange,
        onDeactivateVariant,
        areFractionalQuantitiesEnabled,
        isNegativeStockEnabled,
        lowStockThreshold,
        errors,
    } = props;
    const { shouldUseLegacyUPCValidation, isLowStockAlertFeatureEnabled } =
        useFlags();

    const initialVariants = useRef(variants);
    const [isImagesSidebarOpen, setIsImagesSidebarOpen] = useState(false);
    const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);
    const imageSelectorRefs = variants.map(() => createRef<ImageSelector>());
    const skuDuplicates = getVariantsDuplicatedValues(variants, 'sku');
    const upcDuplicates = getVariantsDuplicatedValues(variants, 'upc');

    const columnCount = isDifferentPricingPerChannel ? 8 + channels.length : 8;

    const createUpdatedVariants = (
        variants: IAvailabilitySectionVariant[],
        variantIndex: number,
        updater: (
            variant: IAvailabilitySectionVariant,
        ) => IAvailabilitySectionVariant,
    ) =>
        variants.map((variant, index) => {
            if (index !== variantIndex) {
                return variant;
            }

            return updater(variant);
        });

    const handleDeactivateClick = (variant: IAvailabilitySectionVariant) => {
        onDeactivateVariant(variant.key);
    };

    const handleImagesChange = (newImages: IImage[]) => {
        const newVariants = variants.concat();

        newVariants[selectedVariantIndex].images = newImages;
        const inputRef =
            imageSelectorRefs[selectedVariantIndex].current?.inputRef.current;

        if (inputRef) {
            inputRef.value = '';
        }

        onChange(newVariants);
    };

    const handleImageSelectionClick = (variantIndex: number) => {
        setSelectedVariantIndex(variantIndex);
        setIsImagesSidebarOpen(true);
    };

    const handleInputChange = (
        key: 'sku' | 'upc' | 'quantity' | 'costPrice',
        variantIndex: number,
        value: string | null,
    ) => {
        const newVariants = createUpdatedVariants(
            variants,
            variantIndex,
            (variant) => ({
                ...variant,
                [key]: value,
            }),
        );

        onChange(newVariants);
    };

    const handleChannelPriceChange = (
        variantIndex: number,
        channelId: string,
        price: string | null,
    ) => {
        const newVariants = createUpdatedVariants(
            variants,
            variantIndex,
            (variant) => ({
                ...variant,
                channelsPrice: {
                    ...variant.channelsPrice,
                    [channelId]: price,
                },
            }),
        );

        onChange(newVariants);
    };

    const handleAvailabilityChannelsChange = (
        options: string[],
        variantIndex: number,
    ) => {
        const newVariants = createUpdatedVariants(
            variants,
            variantIndex,
            (variant) => ({
                ...variant,
                channels: options,
                channelsPrice: mapValues(
                    variant.channelsPrice,
                    (price, channel) =>
                        options.includes(channel) ? price : '',
                ),
            }),
        );

        onChange(newVariants);
    };

    const rowHeight = 86;
    const headerRowHeight = 54;
    const scrollBar =
        window.innerWidth - document.documentElement.clientWidth || 15; // magic number to make sure even the non taking space scrollbar is not covering the table

    const tableHeight =
        headerRowHeight + variants.length * rowHeight + scrollBar;

    //index 0 for header row that has different height
    const getRowHeight = ({ index }: Index) =>
        index ? rowHeight : headerRowHeight;

    const getColumnWidth = ({ index }: Index) => {
        let baseValueColumnsIndex = 0;

        if (isDifferentPricingPerChannel) {
            baseValueColumnsIndex = channels.length;
        }

        switch (index) {
            // Delete button column.
            case 0:
                return 70;
            // Name column.
            case 1:
                return 155;
            // Images column.
            case 2:
                return 130;
            // Availability column.
            case 3:
                return 240;
            // Variant cost column.
            case 4:
                return 220;
            // SKU column.
            case baseValueColumnsIndex + 5:
                return 270;
            // UPC column.
            case baseValueColumnsIndex + 6:
                return 285;
            // Quantity column.
            case baseValueColumnsIndex + 7:
                return 190;
            // Pricing columns.
            default:
                return 220;
        }
    };

    const getInputBaseProps = (
        key: 'sku' | 'upc' | 'quantity',
        variantIndex: number,
        duplicates: string[] = [],
    ) => {
        const variantData = variants[variantIndex];
        const value = variantData[key];
        const variantKey = variantData.key;
        const errorMessage = errors?.[variantKey]?.[key] ?? '';
        // If variant has no id it means that it is new variant, so theres no ignored value.
        const initialVariantData = variantData.id
            ? initialVariants.current.find(({ id }) => variantData.id === id)
            : null;
        const initialValue = initialVariantData ? initialVariantData[key] : '';
        const validators = getVariantInlineValidators[key](
            initialValue,
            duplicates,
            shouldUseLegacyUPCValidation,
        );

        // NumberFormat needs test id to be passed as an explicit data attribute
        if (key === 'upc' || key === 'quantity') {
            return {
                key: variantKey,
                className: styles.CellInput,
                value: value ?? '',
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(key, variantIndex, event.target.value),
                'data-testid': `ProductVariant-${key}-${variantIndex}`,
                errorMessage,
                isValid: !errorMessage,
                validators,
            };
        }

        return {
            key: variantKey,
            className: styles.CellInput,
            value: value ?? '',
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(key, variantIndex, event.target.value),
            'data-testid': `ProductVariant-${key}-${variantIndex}`,
            errorMessage,
            isValid: !errorMessage,
            validators,
        };
    };

    const renderHeaderCell = ({ columnIndex }: CellContentProps) => {
        const baseHeaders = [
            'Active',
            `Variants (${variants.length})`,
            'Images',
            'Availability',
            'Variant cost',
            'SKU',
            'UPC',
            'Qty',
        ];
        let header = '';

        if (!isDifferentPricingPerChannel) {
            header = baseHeaders[columnIndex] ?? '';
        } else {
            const priceHeaders = channels.map(
                (channel) => `Price (${channel.label})`,
            );

            const headersWithPrice = [
                ...baseHeaders.slice(0, 5),
                ...priceHeaders,
                ...baseHeaders.slice(5),
            ];

            header = headersWithPrice[columnIndex] ?? '';
        }

        return <div className={styles.CellHeader}>{header}</div>;
    };

    const renderDeactivateCell = (variant: IAvailabilitySectionVariant) => {
        return (
            <div className={styles.CellDelete}>
                <IconButton
                    name="DeleteIcon"
                    alt="Delete variant icon"
                    className={styles.CellDelete_button}
                    onClick={() => handleDeactivateClick(variant)}
                    disableBorder
                    color={colors.black}
                    buttonProps={{
                        'data-testid': `ProductVariantsRemove-${variant.combinedVariantName}`,
                    }}
                />
            </div>
        );
    };

    const renderNameCell = (
        { title, quantity }: IAvailabilitySectionVariant,
        variantIndex: number,
    ) => {
        const name = getVariantName(title);
        const isLowStock =
            lowStockThreshold !== null &&
            quantity !== '' &&
            Number(quantity) < lowStockThreshold;

        return (
            <div className={styles.CellName}>
                <div
                    className={styles.CellName_content}
                    data-testid={`ProductVariantName-${variantIndex}`}
                >
                    <Tooltip
                        tooltipContent={name}
                        variant="topLeft"
                        portalTarget={document.body}
                    >
                        {name}
                    </Tooltip>
                </div>
                {isLowStockAlertFeatureEnabled && isLowStock && (
                    <Tooltip
                        tooltipContent="Low Stock"
                        variant="topLeft"
                        portalTarget={document.body}
                    >
                        <Icon
                            name="WarningSolidIcon"
                            alt="Warning icon"
                            size={18}
                            color={colors.warning70}
                            iconSet={iconSet}
                        />
                    </Tooltip>
                )}
            </div>
        );
    };

    const renderImagesCell = (
        variant: IAvailabilitySectionVariant,
        variantIndex: number,
    ) => {
        return (
            <ImageSelectionButton
                key={variant.key}
                images={variant.images.map((image) => image.imgUrl)}
                onClick={() => handleImageSelectionClick(variantIndex)}
                className={styles.CellImageSelection}
            />
        );
    };

    const renderAvailabilityDropdown = (
        variant: IAvailabilitySectionVariant,
        variantIndex: number,
    ) => {
        const variantKey = variants[variantIndex].key;

        return (
            <div className={styles.CellDropdown}>
                <SelectionDropdown
                    key={variant.key}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    label=""
                    selected={variant.channels.map(String)}
                    options={channels}
                    onOptionsChange={(options) =>
                        handleAvailabilityChannelsChange(options, variantIndex)
                    }
                    hasOneOptionAlwaysSelected
                    closeMenuOnScroll={closeMenuOnScroll}
                    isValid={!errors?.[variantKey]?.channels.length}
                />
            </div>
        );
    };

    const renderSku = (variantIndex: number) => (
        <ValidateableInput
            {...getInputBaseProps('sku', variantIndex, skuDuplicates)}
            variant="rounded"
            placeholder="000000000000"
        />
    );

    const renderUpc = (variantIndex: number) =>
        shouldUseLegacyUPCValidation ? (
            <ValidateableNumberFormat
                {...getInputBaseProps('upc', variantIndex, upcDuplicates)}
                validationMode={ValidationMode.sequential}
                variant="rounded"
                placeholder="000000000000"
                maxLength={12}
                allowLeadingZeros
            />
        ) : (
            <ValidateableInput
                {...getInputBaseProps('upc', variantIndex, upcDuplicates)}
                validationMode={ValidationMode.sequential}
                variant="rounded"
                placeholder="000000000000"
                maxLength={14}
            />
        );

    const renderQuantity = (
        _variant: IAvailabilitySectionVariant,
        variantIndex: number,
    ) => {
        const baseValue = getInputBaseProps('quantity', variantIndex).value;
        const value = areFractionalQuantitiesEnabled
            ? baseValue
            : !Number.isNaN(parseInt(baseValue))
            ? parseInt(baseValue)
            : undefined;

        return (
            <ValidateableNumberFormat
                {...getInputBaseProps('quantity', variantIndex)}
                value={value ?? ''}
                variant="rounded"
                placeholder="0"
                isAllowed={(values) =>
                    areUnitsInStockCorrect(
                        isNegativeStockEnabled,
                        areFractionalQuantitiesEnabled,
                        values,
                    )
                }
            />
        );
    };

    const renderPriceInput = (channelId: string, variantIndex: number) => {
        const value = variants[variantIndex]?.channelsPrice?.[channelId] || '';
        const isDisabled = !variants[variantIndex].channels.includes(channelId);
        const variantKey = variants[variantIndex].key;

        return (
            <NumberFormat
                key={variantKey}
                className={styles.CellInput}
                value={value}
                placeholder="$0.00"
                variant="rounded"
                disabled={isDisabled}
                isValid={
                    !errors?.[variantKey]?.channelsPrice?.[channelId]?.length
                }
                secondaryCondition={
                    errors?.[variantKey]?.channelsPrice?.[channelId]
                }
                onValueChange={(values) =>
                    handleChannelPriceChange(
                        variantIndex,
                        channelId,
                        values.floatValue !== undefined
                            ? String(values.floatValue)
                            : null,
                    )
                }
                thousandSeparator={true}
                prefix="$"
                data-testid={`ProductVariantChannel-${channelId}-${variantIndex}`}
                isMultilineSecondaryCondition
            />
        );
    };

    const renderCostPrice = (variantIndex: number) => {
        const key = 'costPrice';
        const variantData = variants[variantIndex];
        const value = variantData.costPrice || '';
        const variantKey = variantData.key;
        const errorMessage = variantKey ? errors?.[variantKey]?.[key] : '';

        return (
            <NumberFormat
                value={value}
                isValid={!errorMessage}
                secondaryCondition={errorMessage}
                onValueChange={(values) => {
                    handleInputChange(
                        key,
                        variantIndex,
                        values.floatValue !== undefined
                            ? String(values.floatValue)
                            : null,
                    );
                }}
                key="costPrice"
                className={styles.CellInput}
                placeholder="$0.00"
                variant="rounded"
                thousandSeparator={true}
                prefix="$"
                isMultilineSecondaryCondition
                allowNegative={false}
            />
        );
    };

    const renderBodyCell = ({
        columnIndex,
        rowIndex,
    }: CellContentProps): JSX.Element | string => {
        // Subtract header row
        const variantIndex = rowIndex - 1;
        const variant = variants[variantIndex];

        const baseCells = [
            renderDeactivateCell(variant),
            renderNameCell(variant, variantIndex),
            renderImagesCell(variant, variantIndex),
            renderAvailabilityDropdown(variant, variantIndex),
            renderCostPrice(variantIndex),
            renderSku(variantIndex),
            renderUpc(variantIndex),
            renderQuantity(variant, variantIndex),
        ];

        if (!isDifferentPricingPerChannel) {
            return baseCells[columnIndex];
        }

        const priceCells = channels.map((channel) =>
            renderPriceInput(channel.value, variantIndex),
        );

        const cellsWithPrice = [
            ...baseCells.slice(0, 5),
            ...priceCells,
            ...baseCells.slice(5),
        ];

        return cellsWithPrice[columnIndex];
    };

    const renderCellContent = (
        params: CellContentProps,
    ): JSX.Element | string => {
        if (params.rowIndex === 0) {
            return renderHeaderCell(params);
        }

        return renderBodyCell(params);
    };

    return (
        <div className={styles.ProductVariantsAvailabilityGrid}>
            <ImageUploader
                isSidebarOpen={isImagesSidebarOpen}
                showInitialImageSelector={false}
                sidebarImageSelectorRef={
                    imageSelectorRefs[selectedVariantIndex]
                }
                sidebarTitle={`Variant images (${
                    variants[selectedVariantIndex]?.images.length ?? 0
                })`}
                images={variants[selectedVariantIndex]?.images}
                onChange={handleImagesChange}
                onSidebarClose={() => setIsImagesSidebarOpen(false)}
            />
            <MultiGrid
                height={tableHeight}
                key={`${isDifferentPricingPerChannel}`} // We want to reload grid on that change, see CWF-667
                showScrollButton
                fixedColumnCount={2}
                fixedRowCount={1}
                rowHeight={getRowHeight}
                columnWidth={getColumnWidth}
                columnCount={columnCount}
                rowCount={variants.length + 1} // We need to take into consideration the header row
                cellContentRenderer={renderCellContent}
                cellClassName={styles.Cell}
                extraData={variants}
            />
        </div>
    );
}

export default ProductVariantsAvailabilityGrid;
