import React from 'react';

import { Props, AnchorProps, ButtonProps } from './ButtonBaseTemp.types';

// (!) Part of ModalHeadeTempFix, 1:1 copy from spotonLib
// to be removed when spotonLib is fixed

/**
 * Button Base where anchor or button should be used
 * Temp copy of https://github.com/SpotOnInc/style-guide-web/blob/dev/src/components/ButtonBase/ButtonBase.component.tsx
 * Should be deleted with ModalHeaderTempFix component
 */
class ButtonBaseTemp extends React.Component<Props> {
    render(): React.ReactNode {
        return !(this.props as AnchorProps).href ? (
            <button {...(this.props as ButtonProps)} />
        ) : (
            <a {...(this.props as AnchorProps)} />
        );
    }
}

export default ButtonBaseTemp;
