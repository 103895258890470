import {
    uniqueEntityValue,
    isNonEmptyWithMessage,
} from 'features/common/utils';
import { IProduct, IProductVariant } from 'features/products/types';
import { upcValueLength } from 'features/products/utils/validators';

export const generalSectionErrorMessages = {
    name: {
        nonBlank: `Name can't be blank`,
        unique: 'Name already taken',
    },
    sku: {
        unique: 'SKU already taken',
    },
    upc: {
        unique: 'UPC already taken',
    },
    hasVariants: {
        nonEmpty: 'At least one variant has to be defined',
    },
};

export const getGeneralSectionValidators = (
    initialValues: {
        name: string;
        sku: string;
        upc: string;
    },
    isDuplicated: boolean,
) => {
    return {
        name: {
            sync: [
                isNonEmptyWithMessage(
                    generalSectionErrorMessages.name.nonBlank,
                ),
            ],
            async: [
                uniqueEntityValue<IProduct>(
                    'product',
                    'title',
                    initialValues.name,
                    [],
                    {
                        errorMessage: generalSectionErrorMessages.name.unique,
                    },
                ),
            ],
        },
        sku: {
            sync: [],
            async: [
                uniqueEntityValue<IProductVariant>(
                    'product',
                    'sku',
                    isDuplicated ? undefined : initialValues.sku,
                    [],
                    {
                        errorMessage: generalSectionErrorMessages.sku.unique,
                    },
                ),
            ],
        },
        upc: {
            sync: [upcValueLength()],
            async: [
                uniqueEntityValue<IProductVariant>(
                    'product',
                    'upc',
                    isDuplicated ? undefined : initialValues.upc,
                    [],
                    {
                        errorMessage: generalSectionErrorMessages.upc.unique,
                    },
                ),
            ],
        },
    };
};
