import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from 'spoton-lib';

import { Section, PlaceholderWrapper } from 'features/common/components';

import { IPropTypes } from './LoyaltySectionController.types';
import styles from './LoyaltySectionController.module.scss';

// TODO: remove this component after the product page is moved to React Hook Form - CWF-1163
export function LoyaltySectionController({
    title = 'Loyalty',
    isLoading = false,
    earnLabel,
    redeemLabel,
}: IPropTypes) {
    const { control, setValue } = useFormContext();

    return (
        <Section
            title={title}
            className={styles.LoyaltySection}
            titleTag="sub2"
        >
            <PlaceholderWrapper isLoading={isLoading}>
                <Controller
                    name="canEarnReward"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            label={earnLabel}
                            checked={field.value}
                            onChange={() => setValue(field.name, !field.value)}
                            className={styles.Checkbox}
                            data-testid="LoyaltySectionEarnCheckbox"
                        />
                    )}
                />
                <Controller
                    name="canRedeemReward"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            label={redeemLabel}
                            checked={field.value}
                            onChange={() => setValue(field.name, !field.value)}
                            className={styles.Checkbox}
                            data-testid="LoyaltySectionRedeemCheckbox"
                        />
                    )}
                />
            </PlaceholderWrapper>
        </Section>
    );
}

export default LoyaltySectionController;
