export enum StateType {
    idle = 'idle',
    loading = 'loading',
    success = 'success',
    error = 'error',
}

export type WeightLimitCheckState =
    | { type: StateType.idle }
    | {
          type: StateType.loading;
          warningMessage: string | undefined;
          details: string | undefined;
      }
    | { type: StateType.error; errorMessage: string }
    | {
          type: StateType.success;
          warningMessage: string | undefined;
          details: string | undefined;
      };
