import { z } from 'zod';

import { AxiosResponse } from 'features/common/utils';
import { IPaginatedResponse } from 'features/common/types';
import { ErrorLogger } from 'features/common/utils/errorLogger.utils';

export const getData = <T>(response: AxiosResponse<T>): T => response.data;

export const getPaginatedData = <T>(
    response: AxiosResponse<IPaginatedResponse<T>>,
) => {
    return response.data.results;
};

const validateData =
    (schema: z.ZodTypeAny) =>
    <T>(data: T) => {
        const result = schema.safeParse(data);

        if (!result.success) {
            ErrorLogger.reportError(result.error);
        }

        return data;
    };

const validationBuilder =
    <Data extends AxiosResponse, R>(callback: (res: Data) => R) =>
    <Schema extends z.ZodTypeAny>(schema: Schema) =>
    (response: Data) => {
        const data = callback(response);
        const validate = validateData(schema);

        const validatedData: z.infer<Schema> = validate(data);
        return validatedData;
    };

export const getDataAndValidate = validationBuilder(getData);
export const getPaginatedDataAndValidate = validationBuilder(getPaginatedData);
