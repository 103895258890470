import { Tabs } from 'spoton-lib';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import { shippingPaths } from 'features/shipping/routes/shipping.paths';

import { IShippingPageTab, ITab } from './ShippingPage.types';
import styles from './ShippingPage.module.scss';

const tabs: ITab[] = [
    { name: IShippingPageTab.shippingMethods, label: 'Shipping' },
    { name: IShippingPageTab.localDelivery, label: 'Local delivery' },
    { name: IShippingPageTab.localPickup, label: 'Local pickup' },
];

export function ShippingPage() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const currentTab = pathname.split('/')[3] as IShippingPageTab;

    const onTabChange = (tabName: string) => {
        navigate(`${shippingPaths.root}/${tabName}`);
    };

    return (
        <div className={styles.ShippingPage}>
            <div className={styles.ShippingPage_tabsWrapper}>
                <Tabs
                    active={currentTab}
                    onSelect={onTabChange}
                    className={styles.Tabs}
                >
                    {tabs.map((tab) => (
                        <Tabs.Tab
                            name={tab.name}
                            key={tab.name}
                            className={styles.Tab}
                        >
                            {tab.label}
                        </Tabs.Tab>
                    ))}
                </Tabs>
            </div>
            <Outlet />
        </div>
    );
}

export default ShippingPage;
