import { useMemo } from 'react';

import { attributeKeys } from 'features/attributes/services/queryKeys';
import {
    attributesGet,
    attributeCreate,
    attributeGetById,
} from 'features/attributes/api/Attributes.data';
import { QueryDropdown } from 'features/common/components/QueryDropdown';
import {
    mapDropdownOptionToProductOption,
    mapProductOptionToAttributePost,
} from 'features/common/utils/attributes.utils';

import { IPropTypes } from './ProductAttributesDropdown.types';

export function ProductAttributesDropdown(props: IPropTypes): JSX.Element {
    const { value, onChange, label, allSelected = [], ...rest } = props;

    const memoAllSelected = useMemo(
        () => allSelected.map((selected) => String(selected.attribute)),
        [allSelected],
    );

    return (
        <QueryDropdown
            label={label}
            successMessageFactory={(name: string) =>
                `Attribute "${name}" created successfully`
            }
            errorMessageFactory={(name: string) =>
                `Error while creating "${name}" attribute. Try again.`
            }
            addNewMessage="Type to add a new attribute"
            fetchItems={attributesGet}
            fetchItem={attributeGetById}
            createItem={(params) =>
                attributeCreate(mapProductOptionToAttributePost(params))
            }
            queryKeys={{
                ...attributeKeys,
                item: attributeKeys.attribute,
            }}
            value={value}
            onChange={(params) =>
                onChange(mapDropdownOptionToProductOption(params))
            }
            hasEmptyOption={false}
            allSelected={memoAllSelected}
            itemType="attribute"
            {...rest}
        />
    );
}

export default ProductAttributesDropdown;
