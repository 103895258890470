import { Button } from 'spoton-lib';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
    useFieldArray,
    UseFieldArrayMove,
    useFormContext,
} from 'react-hook-form';

import { IProductOptionValue } from 'features/products/types';
import * as arrayUtils from 'features/common/utils/array.utils';

import styles from './ProductOptions.module.scss';
import { emptyAttributeField } from './ProductOptions.utils';
import { IPropsType, IProductOptionsFormType } from './ProductOptions.types';
import { ProductOptionsList } from './ProductOptionsList';

export function RHFProductOptions({ isEditing, isLoading }: IPropsType) {
    const { control, setValue, watch } =
        useFormContext<IProductOptionsFormType>();
    const productOptions = watch('productOptions');
    const { append, move, remove } = useFieldArray({
        name: 'productOptions',
        control,
    });

    const handleDragEnd =
        (move: UseFieldArrayMove) => (results: DropResult) => {
            const { destination, type, source } = results;

            if (!destination) {
                return;
            }

            if (type === 'productAttributes') {
                move(source.index, destination.index);
            } else {
                const attributeIndex = Number(source.droppableId.split('.')[1]);

                const updatedOrder = arrayUtils.move(
                    productOptions[attributeIndex].values,
                    source.index,
                    destination.index,
                ) as IProductOptionValue[];

                setValue(
                    `productOptions.${attributeIndex}.values`,
                    updatedOrder,
                );
            }
        };

    if (isLoading) {
        return null;
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd(move)}>
            <Droppable
                droppableId="productAttributes-list"
                type="productAttributes"
            >
                {({ innerRef, placeholder }) => (
                    <div ref={innerRef}>
                        <ProductOptionsList
                            productOptions={productOptions}
                            onRemove={remove}
                            isPageEditing={isEditing}
                        />
                        {placeholder}
                        {!isEditing && (
                            <div className={styles.AddButton}>
                                <Button
                                    variant="tertiary"
                                    type="button"
                                    onClick={() =>
                                        append(emptyAttributeField())
                                    }
                                >
                                    + Attribute
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default RHFProductOptions;
