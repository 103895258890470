import { Card, Text } from 'spoton-lib';
import clsx from 'clsx';

import styles from './Section.module.scss';
import { ISectionProps } from './Section.types';

export function Section(props: ISectionProps) {
    const {
        children,
        title,
        description,
        className,
        contentClassName,
        titleTag = 'h5',
        descriptionTag = 'p',
        headerComponent,
    } = props;
    return (
        <Card
            data-testid={props['data-testid']}
            className={clsx(styles.Section, className)}
            elevation={0}
        >
            {headerComponent}
            <Text className={styles.Section_title} type={titleTag}>
                {title}
            </Text>
            {description && (
                <Text
                    className={styles.Section_description}
                    type={descriptionTag}
                >
                    {description}
                </Text>
            )}
            <div className={contentClassName}>{children}</div>
        </Card>
    );
}

export default Section;
