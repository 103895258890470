import {
    ILocationSettingFieldName,
    ILocationSettingShippingTypeName,
    PackingSlipFormat,
    ShippingLabelFormat,
} from 'features/settings/types';
import {
    useGetMerchantLocationId,
    useGetMerchantLocationSettings,
} from 'features/common/services';
import { IAllMerchantLocationSettings } from 'features/common/types';
import { useUserShippoStatus } from 'features/shipping/services/userShippoStatus';

const getShippingSettings = (
    locationSettings: IAllMerchantLocationSettings,
) => {
    const packingSlipFormat = locationSettings[
        ILocationSettingFieldName.packingSlipFormat
    ]?.value as PackingSlipFormat;

    const shippingLabelFormat = locationSettings[
        ILocationSettingFieldName.shippingLabelFormat
    ]?.value as ShippingLabelFormat;

    return {
        isShippingEnabled:
            locationSettings[ILocationSettingFieldName.shippingEnabled]
                ?.value === 'True',
        shippingTypeName: (locationSettings[
            ILocationSettingFieldName.shippingTypeName
        ]?.value ||
            ILocationSettingShippingTypeName.none) as ILocationSettingShippingTypeName,
        packingSlipFormat,
        shippingLabelFormat,
    };
};

export const useShippingSettings = () => {
    const { getMerchantLocationSettingsLoaded } =
        useGetMerchantLocationSettings();
    const shippingSettings = getMerchantLocationSettingsLoaded();
    const { getMerchantLocationIdLoaded } = useGetMerchantLocationId();
    const merchantLocationId = getMerchantLocationIdLoaded();

    const {
        isShippingEnabled,
        shippingTypeName,
        packingSlipFormat,
        shippingLabelFormat,
    } = getShippingSettings(shippingSettings);

    const {
        isShippoActivated,
        userShippoStatus,
        isLoadingUserShippoStatus,
        isUserShippoStatusError,
        refetchUserShippoStatus,
        isFetching: isRefetchingShippoStatus,
    } = useUserShippoStatus(isShippingEnabled);

    const isShippoInUse =
        shippingTypeName === ILocationSettingShippingTypeName.shippo;
    const isNoShippingMethodSelected =
        shippingTypeName === ILocationSettingShippingTypeName.none;
    const areFlatRatesInUse =
        shippingTypeName === ILocationSettingShippingTypeName.flatRate;

    return {
        isShippingEnabled,
        shippingTypeName,
        packingSlipFormat,
        shippingLabelFormat,
        isUserShippoStatusError,
        userShippoStatus,
        merchantLocationId,
        isShippoActivated,
        isLoadingUserShippoStatus,
        isShippoInUse,
        areFlatRatesInUse,
        isNoShippingMethodSelected,
        refetchUserShippoStatus,
        isRefetchingShippoStatus,
    };
};
