import { Button, Text } from 'spoton-lib';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import spotonLogoSrc from 'features/common/assets/spoton.svg';
import { omnichannelPaths } from 'features/omnichannel/routes/omnichannel.paths';

import { IPropTypes } from './ErrorPage.types';
import styles from './ErrorPage.module.scss';

export function ErrorPage(props: IPropTypes) {
    const { title, message, imageSrc, imageAlt, imageClassName, actions } =
        props;

    const navigate = useNavigate();

    function handleHomeRedirect() {
        navigate(omnichannelPaths.root);
    }

    return (
        <div className={styles.ErrorPage}>
            <div className={styles.Header}>
                <img src={spotonLogoSrc} className={styles.Logo} alt="SpotOn" />
            </div>
            <div className={styles.ContentWrapper}>
                <div className={styles.Illustration}>
                    <img
                        src={imageSrc}
                        alt={imageAlt}
                        className={clsx(
                            styles.Illustration_image,
                            imageClassName,
                        )}
                    />
                </div>
                <div className={styles.Content}>
                    <Text type="h2" as="h1" className={styles.Content_title}>
                        {title || 'Oops!'}
                    </Text>
                    <Text type="sub1" as="p" className={styles.Content_message}>
                        {message}
                    </Text>
                    <div className={styles.Content_buttons}>
                        {actions || (
                            <>
                                <Button
                                    onClick={handleHomeRedirect}
                                    variant="primary"
                                    className={styles.Button}
                                >
                                    Go back home
                                </Button>
                                <Button
                                    variant="secondary"
                                    className={styles.Button}
                                    href="mailto:support@spoton.com"
                                >
                                    Email Support
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
