import { Icon } from 'spoton-lib';
import { Link } from 'spoton-lib/dist/esm/types/src/components/LeftNavAppMenu/LeftNavAppMenu.types';
import { VerticalLink } from 'spoton-lib/dist/esm/types/src/components/LeftNavVerticalsMenu/LeftNavVerticalsMenu.types';

import { getConfigVar } from 'features/common';
import * as iconSet from 'features/common/assets/icons';
import { IAppLinks } from 'features/omnichannel/types';
import { productsPaths } from 'features/products/routes/products.paths';
import { servicesPaths } from 'features/services/routes/services.paths';
import { categoriesPaths } from 'features/categories/routes/categories.paths';
import { settingsPaths } from 'features/settings/routes/settings.paths';
import { vendorsPaths } from 'features/vendors/routes/vendors.paths';

export interface ISettingsLink {
    id: string;
    displayName: string;
    hasDivider: boolean;
}

export interface INavItemsLinkSettings
    extends Omit<Link, 'isActive' | 'selectedIcon'> {
    selectedIcon: JSX.Element;
}

enum Services {
    loyalty = 1,
    marketing = 2,
    hasTablet = 3,
    spotsByVisit = 4,
    spotsByDollar = 5,
    reviews = 6,
    cpayData = 7,
    imobile3 = 8,
    merchantTablet = 9,
    pairedTablet = 10,
    poynt = 11,
    salons = 12,
    gusto = 13,
    nmi = 14,
    spotonTerminal = 15,
    website = 16,
    emaginePoyntKiosk = 17,
    giftCards = 18,
    restaurants = 19,
    otherLoyalty = 20,
    transitIntegration = 21,
    spotonReserve = 22,
    omnichannel = 23,
    eGiftCards = 24,
    dineIn = 25,
    enhancedRewards = 26,
}

const externalApps: VerticalLink[] = [
    {
        id: 0,
        displayName: 'Catalog',
        link: '/',
        isActive: true,
    },
    {
        id: 1,
        displayName: 'Appointments',
        link: getConfigVar(
            'REACT_APP_EXTERNAL_APP_DASHBOARD_APPOINTMENTS',
        ) as string,
        isActive: false,
    },
    {
        id: 2,
        displayName: 'Dashboard',
        link: getConfigVar('REACT_APP_EXTERNAL_APP_DASHBOARD') as string,
        isActive: false,
    },
    {
        id: 3,
        displayName: 'Payroll',
        link: getConfigVar('REACT_APP_EXTERNAL_APP_PAYROLL') as string,
        isActive: false,
    },
    {
        id: 4,
        displayName: 'Virtual Terminal',
        link: getConfigVar('REACT_APP_EXTERNAL_APP_TERMINAL') as string,
        isActive: false,
    },
    {
        id: 5,
        displayName: 'Websites',
        link: getConfigVar('REACT_APP_EXTERNAL_APP_WEBSITES') as string,
        isActive: false,
    },
];

const iconWithName = (name: string, title: string) => (
    <Icon alt={title} iconSet={iconSet} name={name} size={24} />
);

const getNavItem = (
    { selectedIcon, ...item }: INavItemsLinkSettings,
    activeHref: string,
): Link => {
    const isActive = activeHref.includes(item.link as string);

    return {
        ...item,
        isActive,
        icon: isActive ? selectedIcon : item.icon,
    };
};

export const navItems = (
    isVendorsManagementPageEnabled?: boolean,
): INavItemsLinkSettings[] => {
    const vendorsManagementPage = {
        id: 3,
        displayName: 'Vendors',
        link: vendorsPaths.root,
        icon: iconWithName('VendorsIcon', 'Vendors'),
        selectedIcon: iconWithName('VendorsIconActive', 'Vendors selected'),
        subLinks: [],
    };

    const settingsPage = {
        id: 4,
        displayName: 'Settings',
        link: settingsPaths.root,
        icon: iconWithName('SettingsIcon', 'Settings'),
        selectedIcon: iconWithName('SettingsSolidIcon', 'Settings selected'),
        subLinks: [],
    };

    const links = isVendorsManagementPageEnabled
        ? [vendorsManagementPage, settingsPage]
        : [settingsPage];

    return [
        {
            id: 0,
            displayName: 'Products',
            link: productsPaths.root,
            icon: iconWithName('ProductIcon', 'Products'),
            selectedIcon: iconWithName('ProductSolidIcon', 'Products selected'),
            subLinks: [],
        },
        {
            id: 1,
            displayName: 'Services',
            link: servicesPaths.root,
            icon: iconWithName('ServiceIcon', 'Services'),
            selectedIcon: iconWithName('ServiceSolidIcon', 'Services selected'),
            subLinks: [],
        },
        {
            id: 2,
            displayName: 'Categories',
            link: categoriesPaths.root,
            icon: iconWithName('CategoryIcon', 'Categories'),
            selectedIcon: iconWithName(
                'CategorySolidIcon',
                'Categories selected',
            ),
            subLinks: [],
        },
        ...links,
    ];
};

const settingsLinks: ISettingsLink[] = [
    {
        id: 'locations',
        displayName: 'Change Location',
        hasDivider: false,
    },
    {
        id: 'new_settings',
        displayName: 'Account Settings',
        hasDivider: false,
    },
    {
        id: 'admin',
        displayName: 'Admin',
        hasDivider: false,
    },
    {
        id: 'new_help',
        displayName: 'Statements',
        hasDivider: false,
    },
    {
        id: 'order-cards/gift_cards',
        displayName: 'Order Gift Cards',
        hasDivider: false,
    },
    {
        id: 'order-cards',
        displayName: 'Order loyalty cards',
        hasDivider: false,
    },
    {
        id: 'business_referral',
        displayName: 'Refer a business',
        hasDivider: false,
    },
    {
        id: 'add_services',
        displayName: 'Add services',
        hasDivider: false,
    },
    {
        id: 'support',
        displayName: 'Support and legal',
        hasDivider: true,
    },
    {
        id: 'logout',
        displayName: 'Log out',
        hasDivider: false,
    },
];

export const shouldBeIncluded = (
    appLinks: IAppLinks,
    link: ISettingsLink,
): boolean => {
    // Admin (Only available if `country == US` and `!root`) [href="{{ BASE_URL }}admin/"]
    if (link.id === 'admin' && appLinks.address.country === 'US') {
        return false;
    }

    // Order Gift Cards (only available if programs == c.pay) [href="{{ BASE_URL }}order-cards/gift_cards"]
    if (
        link.id === 'order-cards/gift_cards' &&
        !appLinks.programs.includes(Services.cpayData)
    ) {
        return false;
    }

    // Order loyalty cards (only available if programs == loyalty) [href="{{ BASE_URL }}order-cards/"]
    if (
        link.id === 'loyalty' &&
        !appLinks.programs.includes(Services.loyalty)
    ) {
        return false;
    }

    return true;
};

export const getSettingsLinks = (appLinks?: IAppLinks): ISettingsLink[] => {
    return appLinks
        ? settingsLinks.filter((link) => shouldBeIncluded(appLinks, link))
        : settingsLinks.filter((link) =>
              ['locations', 'logout'].includes(`${link.id}`),
          );
};

export const getExternalAppItems = (
    isAppointmentsIntegrated?: boolean,
): VerticalLink[] =>
    externalApps.filter(
        (link) =>
            !(
                !isAppointmentsIntegrated &&
                link.link ===
                    (getConfigVar(
                        'REACT_APP_EXTERNAL_APP_DASHBOARD_APPOINTMENTS',
                    ) as string)
            ),
    );

export const getNavMenuItems = (
    activeHref: string,
    isVendorsManagementPageEnabled?: boolean,
): Link[] =>
    navItems(isVendorsManagementPageEnabled).map<Link>((item) =>
        getNavItem(item, activeHref),
    );
