/**
 * Collection of available logging levels
 */
export enum ELogLevels {
    /** warning messages only */
    warn = 'warn',

    /** error messages only */
    error = 'error',

    /** show info messages only */
    info = 'info',

    /** show logs, warnings and errors */
    verbose = 'verbose',

    /** show logs, info, warnings, and errors */
    debug = 'debug',
}
