import { useMemo, useRef, useState, useEffect } from 'react';
import { Button } from 'spoton-lib';

import { IPropTypes } from './ShippingRateOptionFooter.types';
import styles from './ShippingRateOptionFooter.module.scss';

export function ShippingRateOptionFooter(props: IPropTypes) {
    const [width, setWidth] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const {
        onClose,
        onSave,
        isSaveButtonDisabled = false,
        isCancelButtonDisabled = false,
        onFormDirtyStateChange,
        isLoading,
    } = props;

    const onSetRefWidth = () => {
        if (ref.current) {
            setWidth(ref.current.clientWidth);
        }
    };

    useEffect(() => {
        onSetRefWidth();
        window.addEventListener('resize', onSetRefWidth);

        return () => window.removeEventListener('resize', onSetRefWidth);
    }, [ref.current, onSave]);

    useEffect(() => {
        onFormDirtyStateChange?.(true);

        return () => {
            onFormDirtyStateChange?.(false);
        };
    }, []);

    const actions = useMemo(
        () =>
            width ? (
                <div className={styles.Footer_actions} style={{ width }}>
                    <Button
                        onClick={onClose}
                        type="button"
                        className={styles.Footer_button}
                        variant="tertiary"
                        disabled={isCancelButtonDisabled}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onSave}
                        type="submit"
                        className={styles.Footer_button}
                        variant="primary"
                        disabled={isSaveButtonDisabled}
                        isLoading={isLoading}
                    >
                        Save
                    </Button>
                </div>
            ) : null,
        [width, onClose, onSave],
    );

    return (
        <div className={styles.Footer} ref={ref}>
            {actions}
        </div>
    );
}

export default ShippingRateOptionFooter;
