import { useWindowMatchMedia } from 'features/common/utils';

import { IMediaQuery } from './useMediaQuery.types';

export const useMediaQuery = (): IMediaQuery => {
    return {
        isMobile: useWindowMatchMedia('(max-width: 719px)'),
        isTablet: useWindowMatchMedia(
            '(min-width: 720px) and (max-width: 1023px)',
        ),
        isDesktop: useWindowMatchMedia('(min-width: 1024px)'),
    };
};
