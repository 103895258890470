import { useEffect } from 'react';
import { Button } from 'spoton-lib';
import { Form, useFormikContext } from 'formik';

import { NexusModal } from 'features/common/components';
import { DropdownField } from 'features/common';
import {
    generateSampleBarcode,
    labelSizesMap,
    labelSizesOptions,
} from 'features/products/components/BarcodePrintingModal';

import { BarcodePrintingTable } from './BarcodePrintingTable';
import {
    IBarcodePrintingFormData,
    IPropTypes,
} from './BarcodePrintingForm.types';
import styles from './BarcodePrintingForm.module.scss';

export function BarcodePrintingForm({
    onChangeSelectedVariantsCount,
}: IPropTypes) {
    const {
        values: { variants, size },
        isValid,
        submitCount,
        submitForm,
    } = useFormikContext<IBarcodePrintingFormData>();

    const handlePreviewLabel = () => generateSampleBarcode(labelSizesMap[size]);

    const selected = variants.filter(({ isSelected }) => isSelected);
    const selectedCount = selected.length;
    const labelsCount = selected.reduce((sum, next) => sum + next.quantity, 0);
    const willUpdateUpcs = selected.some(
        ({ upc, initialUpc }) => upc !== initialUpc,
    );

    const submitLabel = () => {
        if (labelsCount === 0) {
            return 'Nothing to print';
        }

        const action = willUpdateUpcs ? 'Save and print' : 'Print';
        const count = labelsCount > 1 ? `${labelsCount} labels` : '1 label';
        return `${action} ${count}`;
    };

    useEffect(
        () => onChangeSelectedVariantsCount(selectedCount),
        [selectedCount],
    );

    return (
        <Form>
            <div className={styles.Form}>
                <div className={styles.FormRow}>
                    <DropdownField
                        className={styles.FormRow_dropdown}
                        name="size"
                        label="Label Sizes"
                        placeholder="Label Sizes"
                        options={labelSizesOptions}
                        withDividers={false}
                        // this overrides max dropdown height so it don't exceed modal size
                        styles={{ menuList: () => ({ maxHeight: '13.5rem' }) }}
                    />
                    <Button
                        className={styles.FormRow_previewButton}
                        onClick={handlePreviewLabel}
                        variant="tertiary"
                        type="button"
                    >
                        Label Preview
                    </Button>
                </div>
                <BarcodePrintingTable className={styles.VariantsTable} />
                <NexusModal.Footer
                    submitTitle={submitLabel()}
                    cancelTitle="Cancel"
                    onSubmit={submitForm}
                    isSubmitDisabled={
                        labelsCount === 0 || (!isValid && submitCount > 0)
                    }
                />
            </div>
        </Form>
    );
}

export default BarcodePrintingForm;
