import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { nestedPath } from 'features/common/utils/routing.utils';
import { useAuthStoreSelectors } from 'features/common/zustand/useAuthState';
import { ErrorRoutes } from 'features/errors/routes';
import { MerchantLocationsPage } from 'features/settings/components';
import { LoginRedirect } from 'features/omnichannel/components';
import { useScrollRestoration } from 'features/common/hooks';

import { omnichannelLocalPaths } from './omnichannel.paths';
import ProtectedRoutes from './ProtectedRoutes.component';
import { redirectURL } from './utils';

export function OmnichannelRoutes() {
    useScrollRestoration();
    const location = useLocation();
    const isAuthenticated = useAuthStoreSelectors.use.isAuthenticated();

    return (
        <Routes>
            <Route
                path={nestedPath(omnichannelLocalPaths.error)}
                element={<ErrorRoutes />}
            />
            <Route
                path={omnichannelLocalPaths.merchantLocations}
                element={
                    isAuthenticated ? (
                        <MerchantLocationsPage />
                    ) : (
                        <Navigate replace to={redirectURL(location.pathname)} />
                    )
                }
            />
            <Route
                path={omnichannelLocalPaths.login}
                element={<LoginRedirect />}
            />
            <Route
                path={omnichannelLocalPaths.logout}
                element={<LoginRedirect logout />}
            />
            <Route
                path="*"
                element={
                    isAuthenticated ? (
                        <ProtectedRoutes />
                    ) : (
                        <Navigate replace to={redirectURL(location.pathname)} />
                    )
                }
            />
        </Routes>
    );
}
