import { DimensionUnit } from 'features/common/constants/shippingRates';
import { assertNever } from 'features/common/utils';

import { formatValue } from './utils';

const cmToInchFactor = 2.54;
const mmToInchFactor = cmToInchFactor * 10;

export class Dimension {
    private _value: number;
    private _unit: DimensionUnit;

    constructor(value: string, unit: DimensionUnit) {
        this._value = formatValue(value);
        this._unit = unit;
    }

    getValueInInches = () => {
        switch (this._unit) {
            case 'inch':
                return this._value;
            case 'cm':
                return formatValue(this._value / cmToInchFactor);
            case 'mm':
                return formatValue(this._value / mmToInchFactor);
            default:
                assertNever(this._unit);
                return 0;
        }
    };
}
