import { UIEvent } from 'react';

export const closeMenuOnScroll = (e: UIEvent<HTMLDivElement>): boolean => {
    if (
        e.target &&
        [document.documentElement, document.body, window, document].includes(
            e.target as HTMLDivElement,
        )
    ) {
        return true;
    }
    return false;
};
