import { Text, Button, colors } from 'spoton-lib';

import { networkErrorMessages } from 'features/common/constants';
import NetworkErrorIllustration from 'features/common/assets/network_error.png';
import { ReactComponent as ServerErrorIllustration } from 'features/common/assets/server_error.svg';

import { IPropTypes } from './NetworkError.types';
import styles from './NetworkError.module.scss';

export function NetworkError(props: IPropTypes) {
    const {
        offlineErrorMessage = networkErrorMessages.default,
        onRefreshClick,
        testId,
        apiCallErrorMessage = 'We cannot find Catalog data',
    } = props;

    const isOnline = window.navigator.onLine;

    if (!isOnline) {
        return (
            <div className={styles.NetworkError}>
                <img src={NetworkErrorIllustration} />
                <Text type="p" className={styles.NetworkError_title}>
                    Something went wrong!
                </Text>
                <Text type="p" className={styles.NetworkError_message}>
                    {offlineErrorMessage}
                </Text>
                <Button
                    className={styles.NetworkError_refreshButton}
                    variant="secondary"
                    onClick={onRefreshClick}
                    data-testid={testId}
                >
                    Refresh
                </Button>
            </div>
        );
    }

    return (
        <div className={styles.ServerError}>
            <Text
                type="h3"
                as="h1"
                color={colors.gray90}
                className={styles.ServerError_title}
            >
                {apiCallErrorMessage}
            </Text>
            <ServerErrorIllustration
                className={styles.ServerError_illustration}
            />
            <Button
                className={styles.ServerError_refreshButton}
                variant="primary"
                onClick={onRefreshClick}
                data-testid={testId}
            >
                Refresh
            </Button>
        </div>
    );
}

export default NetworkError;
