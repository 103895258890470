import { axios, AxiosResponse } from 'features/common/utils/axios.utils';

import {
    IDuplicationCheck,
    IDuplicationCheckPost,
} from './DuplicationCheck.types';

export function postServiceDuplicationCheck(
    params: IDuplicationCheckPost,
): Promise<AxiosResponse<IDuplicationCheck>> {
    return axios.post<IDuplicationCheck>(
        '/api/v2/services/duplication-check/',
        params,
    );
}

export function postProductDuplicationCheck(
    params: IDuplicationCheckPost,
): Promise<AxiosResponse<IDuplicationCheck>> {
    return axios.post<IDuplicationCheck>(
        '/api/v2/products/duplication-check/',
        params,
    );
}
