import { Controller, useFormContext } from 'react-hook-form';

import { ImageUploader } from 'features/common/components';

import { IPropTypes } from './ImageUploaderController.types';

export function ImageUploaderController({ name, ...innerProps }: IPropTypes) {
    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <ImageUploader
                    {...innerProps}
                    images={field.value}
                    onChange={(value) => setValue(name, value)}
                />
            )}
        />
    );
}

export default ImageUploaderController;
