import { getChannelName, sortChannels } from 'features/common/utils';
import { ChannelsApi } from 'features/common/api';
import { useMutation, useQuery } from 'features/common/serverStateHandler';
import { IChannel, IChannelData } from 'features/common/types';
import { IChannelPatchPayload } from 'features/common/api/Channels.types';

export const channelKeys = {
    all: [{ scope: 'channels' }] as const,
    channel: (id: string) => [{ ...channelKeys.all[0], id }] as const,
};

const updateChannels = (
    { id, ...data }: IChannelPatchPayload,
    channels: IChannelData[] = [],
) =>
    channels.map((channel) =>
        id === channel.id
            ? {
                  ...channel,
                  ...data,
              }
            : channel,
    );

export const useChannelUpdate = () => {
    return useMutation<
        IChannelData,
        unknown,
        IChannelPatchPayload,
        IChannelData[]
    >(ChannelsApi.channelPatch, {
        onMutate: ({ variables, queryHandler }) => {
            const previousChannels = queryHandler.getQueryData<IChannelData[]>(
                channelKeys.all,
            );

            const updatedChannels = updateChannels(
                variables,
                previousChannels || [],
            );
            queryHandler.setQueryData(channelKeys.all, updatedChannels);

            return previousChannels;
        },
        onSuccess: ({ data: apiResponse, context, queryHandler }) => {
            const updatedChannels = updateChannels(apiResponse, context);

            return queryHandler.setQueryData(channelKeys.all, updatedChannels);
        },
        onError: ({ context, queryHandler }) => {
            if (context) {
                queryHandler.setQueryData(channelKeys.all, context);
            }
        },
        formatSuccessMessage: () => 'Channel successfully updated',
        formatErrorMessage: () => 'Error while updating channel. Try again.',
    });
};

export const useFetchChannels = () => {
    const { data, ...result } = useQuery(
        channelKeys.all,
        () => ChannelsApi.channelsGet({ page: 1 }),
        {
            select: (data) =>
                data
                    .map<IChannel>((channel) => ({
                        ...channel,
                        channelName: channel.channelType.name,
                        displayName: getChannelName(channel.channelType.name),
                        merchantLocation: String(channel.merchantLocation),
                    }))
                    .sort(sortChannels),
        },
    );

    return {
        ...result,
        channels: data || [],
    };
};
