import { AxiosError } from 'axios';

import { useMutation, useQuery } from 'features/common/serverStateHandler';
import { ProductsExportApi } from 'features/products/api';
import { IProductsExportPostParams } from 'features/products/components/ExportModal/hooks/useExportModal/useExportModal.types';
import {
    IProductsExportStartRequest,
    IProductsExportStartResponse,
    ProductsExportAcknowledgeStatus,
} from 'features/products/api/ProductsExport.types';
import {
    FormattedErrorMessages,
    hasAxiosErrorsSet,
} from 'features/common/utils/formatError';

import { productsExportKeys } from './queryKeys';

export const usePostExport = ({
    onSuccess,
    onError,
}: IProductsExportPostParams) => {
    const { mutate, data, ...rest } = useMutation<
        IProductsExportStartResponse,
        AxiosError<FormattedErrorMessages>,
        IProductsExportStartRequest
    >(ProductsExportApi.startExport, {
        onSuccess: ({ queryHandler, data }) => {
            queryHandler.setQueryData(productsExportKeys.all, data);
            onSuccess();
        },
        onError: ({ error }) => {
            hasAxiosErrorsSet(error)
                ? onError(error?.response?.data)
                : onError();
        },
        isSuccessToast: false,
        isErrorToast: false,
    });

    const userEmail = data?.email || data?.user?.email || '';

    return {
        startExport: mutate,
        userEmail,
        ...rest,
    };
};

export const useGetLastExport = () => {
    const { data, refetch, ...rest } = useQuery(
        productsExportKeys.last(),
        () => ProductsExportApi.getExportHistory({ page: 1, pageSize: 1 }),
        { refetchOnMount: false },
    );

    return {
        lastExport: data?.results?.[0] || undefined,
        refetchLastExport: refetch,
        ...rest,
    };
};

export const acknowledgeExport = async (
    exportId: string,
    acknowledgeStatus: ProductsExportAcknowledgeStatus,
) => {
    if (!exportId) return;

    try {
        await ProductsExportApi.acknowledgeExport(exportId, acknowledgeStatus);
    } catch (error) {
        // error handling done by serverStateHandler
    }
};
