import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { productDuplicationReducer } from 'features/products/redux';

const appReducer = combineReducers({
    duplicatedProduct: productDuplicationReducer,
});

const rootReducer = (
    state: ReturnType<typeof appReducer> | undefined,
    action: AnyAction,
): ReturnType<typeof appReducer> => appReducer(state, action);

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
