import { IDropdownOption, IUnitOfMeasure } from 'features/common/types';

export const mapUnitOfMeasureToDropdownOption = (
    unit: IUnitOfMeasure,
): IDropdownOption => ({
    value: unit.name,
    label: unit.displayName,
});

export const findUnitOfMeasureByName =
    (name: string) =>
    (unit: IUnitOfMeasure): boolean =>
        unit.name === name;
// Quantity is a unit of measure that's always enabled for all the locations
export const quantityUnit: IUnitOfMeasure = {
    name: 'quantity',
    displayName: 'Quantity',
    isEnabled: true,
};

export const quantityDropdownUnit: IDropdownOption =
    mapUnitOfMeasureToDropdownOption(quantityUnit);

export const getUnitDisplayValue = ({
    unitsOfMeasure,
    quantityUnit,
    quantityValue,
}: {
    unitsOfMeasure: IUnitOfMeasure[];
    quantityUnit?: string;
    quantityValue?: string;
}) => {
    const unitOfMeasure = unitsOfMeasure.find(
        (unit) => unit.name === quantityUnit,
    );

    return unitOfMeasure
        ? `${quantityValue} (${unitOfMeasure.displayName})`
        : '';
};
