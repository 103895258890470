import numbro from 'numbro';
import { nanoid } from '@reduxjs/toolkit';

import {
    IChannel,
    mapImagesToItemImages,
    IUnit,
    cartesianProduct,
} from 'features/common';
import {
    IPartialProductData,
    IProduct,
    IProductOption,
    IProductVariant,
    IProductChannel,
    IProductData,
    IProductPost,
    IAvailabilitySectionVariant,
    IProductAttribute,
    IProductVariantOption,
    IVariantAttributeValue,
    IProductAttributeValuePost,
    IProductVariantPayload,
    IProductById,
    IProductStructure,
    IProductLink,
    IProductListProduct,
    IProductOrVariantById,
} from 'features/products/types';
import {
    getChannelName,
    mapItemImagesToImages,
    getChannelPriceByName,
} from 'features/common/utils';
import { IAvailabilitySectionChannelData } from 'features/common/types/pricingSectionChannelData.type';
import {
    mapProductOptionsToVariantOptions,
    uniqueVariantId,
} from 'features/products/components/ProductPage/ProductPage.utils';
import { IChannelName, IChannelsPriceRange } from 'features/common/types';
import { defaultCategoryOption } from 'features/common/constants';
import { getPriceRange } from 'features/products/utils';
import { IApplySidebarItem } from 'features/settings/components/Apply/Apply.types';
import { quantityUnit } from 'features/common/redux/unitsOfMeasure/unitsOfMeasure.utils';
import { UNITS_IN_STOCK_MAX_DECIMAL_PART } from 'features/products/components/AvailabilitySection/AvailabilitySection.constants';

const formatPrice = (value: string) => {
    return numbro(value).formatCurrency({
        thousandSeparated: true,
        mantissa: 2,
    });
};

export const parseProductQuantity = (
    quantity?: IUnit | null,
    defaultValue = '',
) =>
    quantity !== null && quantity !== undefined
        ? String(
              Number(quantity.value).toFixed(
                  quantity.unit !== quantityUnit.name
                      ? UNITS_IN_STOCK_MAX_DECIMAL_PART
                      : 0,
              ),
          )
        : defaultValue;

export const getDifferenceInQuantity = (
    newValue?: string | number,
    oldValue?: string,
) => {
    const value = oldValue
        ? (Number(newValue ?? '0') - Number(oldValue ?? '0')).toFixed(
              UNITS_IN_STOCK_MAX_DECIMAL_PART,
          )
        : newValue;

    return String(value);
};

const getChannelPriceRange = (channels: IProductChannel[]) => {
    const range = channels.reduce<IChannelsPriceRange>(
        (acc, { salePrice }) => {
            const price = Number(salePrice);

            return salePrice
                ? {
                      min: acc.min ? Math.min(acc.min, price) : price,
                      max: acc.max ? Math.max(acc.max, price) : price,
                  }
                : acc;
        },
        {
            min: undefined,
            max: undefined,
        },
    );

    return range.min && range.max
        ? getPriceRange(range.min, range.max)
        : undefined;
};

const getPrice = (
    channels: IProductChannel[],
    priceInChannel?: IChannelName,
) => {
    const price = priceInChannel
        ? getChannelPriceByName(channels, priceInChannel, true)
        : undefined;

    const priceRange = !priceInChannel
        ? getChannelPriceRange(channels)
        : undefined;

    return price ? formatPrice(price) : priceRange;
};

export const formatVariants = (
    products: IProductById[],
    priceInChannel?: IChannelName,
): IProductOrVariantById[] =>
    products
        .map(({ id, structure, variants, title, upc, channels, images }) => {
            return structure === IProductStructure.standalone
                ? [
                      {
                          id,
                          title,
                          price: getPrice(channels, priceInChannel),
                          upc: upc || '',
                          channels,
                          images,
                      },
                  ]
                : variants.map(
                      ({ id, attributeValues, upc, channels, images }) => {
                          return {
                              id,
                              title: [
                                  title,
                                  ...attributeValues.map(({ value }) => value),
                              ].join(' / '),
                              price: getPrice(channels, priceInChannel),
                              upc: upc || '',
                              channels,
                              images,
                          };
                      },
                  );
        })
        .flat();

export const parseProductLink = (
    product: IProductLink,
): IProductListProduct => {
    const stock = parseProductQuantity(product.stock?.quantityInStock);

    const priceRange = getPriceRange(
        Number(product.minPrice),
        Number(product.maxPrice),
    );

    return {
        id: product.id,
        category: product.category ?? defaultCategoryOption.label,
        vendor: product.vendor ?? 'None',
        created: product.created,
        modified: product.modified,
        imgUrl: product.primaryImage?.image,
        name: product.title,
        sku: product.sku,
        upc: product.upc,
        stock,
        lowStock: product.lowStock,
        priceRange,
        hasVariants: product.structure === IProductStructure.parent,
        channels: product.channels.reduce((acc, { channel }) => {
            return {
                ...acc,
                [channel]: true,
            };
        }, {}),
        tags: product.tags,
        areAllChannelsSelected: product.areAllChannelsSelected,
        variantsCount: product.variantsCount,
    };
};

export const parseProductLinkToApplySidebarItem = (
    product: IProductLink,
): IApplySidebarItem => {
    return {
        id: product.id,
        name: product.title,
        imageUrl: product.primaryImage?.image,
        type: 'product',
    };
};

export const createVariantAttributes = (
    variantOptions: IProductVariantOption[],
): IProductAttributeValuePost[] => {
    return variantOptions
        .map((variantOption) => {
            return {
                attribute: variantOption.attribute,
                value: variantOption.value,
            };
        })
        .filter(Boolean) as IProductAttributeValuePost[];
};

export const getProductChannelsFromAvailabilitySectionData = (
    {
        channels,
        channelsAvailability,
        isSamePrice,
        price,
    }: IProductData['availability'],
    locationChannels: IChannel[],
): IProductChannel[] => {
    return channelsAvailability
        .map<IProductChannel | null>((channelId) => {
            const channel = channels.find(
                (channel) => channelId === String(channel.id),
            );
            const apiChannel = locationChannels.find(
                (apiChannel) => channelId === String(apiChannel.id),
            );

            if (!apiChannel || !channel) {
                return null;
            }

            const priceField = isSamePrice ? price : channel.price;
            const salePrice = priceField ? String(priceField) : null;

            return {
                active: true,
                channel: apiChannel.id,
                channelName: apiChannel.channelName,
                salePrice,
                openPriceAllowed: apiChannel.openPriceAllowed,
            };
        })
        .filter((channel): channel is IProductChannel => channel !== null);
};

export const mapAvailabilitySectionToStandalonePayloadProductVariant = ({
    productData,
    locationChannels,
    allVariants,
}: {
    productData: IProductData;
    locationChannels: IChannel[];
    allVariants?: IProductVariant[];
}): IProductVariantPayload => {
    const { itemsInStock, priceUnit, costPerItem } = productData.availability;
    const channels = getProductChannelsFromAvailabilitySectionData(
        productData.availability,
        locationChannels,
    );

    // check how the quantity changed
    const quantity = getDifferenceInQuantity(
        itemsInStock,
        // stand-alone product has only one variant
        allVariants?.length ? allVariants[0].quantityInStock?.value : undefined,
    );

    return {
        attributeValues: [],
        upc: productData.general.upc,
        sku: productData.general.sku,
        channels,
        costPrice: costPerItem ? String(costPerItem) : null,
        quantityInStock: {
            unit: priceUnit,
            value: quantity,
        },
    };
};

export const mapAvailabilitySectionVariantToPayloadProductVariant = async ({
    variant,
    priceUnit,
    price,
    isSamePrice,
    variantId,
    locationChannels,
    allVariants,
}: {
    variant: IAvailabilitySectionVariant;
    priceUnit: string;
    price: string | null;
    isSamePrice: boolean;
    variantId?: string;
    locationChannels: IChannel[];
    allVariants?: IProductVariant[];
}): Promise<IProductVariantPayload> => {
    const images = await mapImagesToItemImages(variant.images);

    const oldVariant = allVariants?.find(({ id }) => id === variantId);

    // check how the quantity changed
    const quantity = getDifferenceInQuantity(
        variant.quantity,
        oldVariant?.quantityInStock?.value,
    );

    return {
        id: variantId,
        title: variant.title,
        attributeValues: createVariantAttributes(variant.variantOptions),
        upc: variant.upc,
        sku: variant.sku,
        channels: locationChannels.map((channel) => ({
            channel: channel.id,
            channelName: channel.channelName,
            salePrice: isSamePrice
                ? price
                : variant.channelsPrice?.[channel.id] || null,
            active: variant.channels.includes(channel.id),
        })),
        images,
        costPrice: variant.costPrice,
        quantityInStock: {
            unit: priceUnit,
            value: quantity,
        },
    };
};

const mapProductDataVariantToAvailabilitySectionVariant = (
    variant: IProductVariant,
): IAvailabilitySectionVariant => ({
    id: variant.id,
    key: nanoid(),
    channels: variant.channels
        .filter((channel) => channel.active)
        .map((channel) => channel.channel),
    combinedVariantName: variant.attributeValues
        .map((attribute) => attribute.value)
        .join('/'),
    variantOptions: variant.attributeValues.map((attribute) => ({
        // Attribute id is stored at attribute field
        attribute: attribute.attribute,
        value: attribute.value,
    })),
    images: mapItemImagesToImages(variant.images),
    quantity: variant.quantityInStock?.value ?? '',
    sku: variant.sku,
    title: variant.title,
    costPrice: variant.costPrice,
    upc: variant.upc,
    channelsPrice: variant.channels.reduce(
        (prev, curr) => ({ ...prev, [curr.channel]: curr.salePrice }),
        {},
    ),
    isExisting: true,
    isLowStock: variant.lowStock,
});

export const mapProductDataVariantsToAvailabilitySectionVariants = (
    variants: IProductVariant[],
    productOptions: IProductOption[],
): IAvailabilitySectionVariant[] => {
    const optionsValues = productOptions.map<IProductVariantOption[]>(
        (productOption) =>
            productOption.values
                .filter(({ value }) => value)
                .map<IProductVariantOption>((value) => ({
                    attribute: productOption.attribute,
                    value: value.value,
                })),
    );

    const variantsOrder = productOptions.length
        ? cartesianProduct(optionsValues)
        : [];

    return variantsOrder.reduce<IAvailabilitySectionVariant[]>(
        (acc, orderVariant) => {
            const uniqueId = uniqueVariantId(orderVariant);

            const existingVariant = variants.find(
                (variant) =>
                    uniqueVariantId(variant.attributeValues) === uniqueId,
            );

            if (existingVariant) {
                acc.push(
                    mapProductDataVariantToAvailabilitySectionVariant(
                        existingVariant,
                    ),
                );
            }
            return acc;
        },
        [],
    );
};

/**
 * Calculates whether product has the same price across all channels and/or variants and returns the result with a price
 */
export const getIsSamePrice = (
    variants: IProductVariant[],
): [boolean, number | null] => {
    if (variants.length > 0) {
        const allVariantsPrices = variants
            .map((variant) =>
                variant.channels
                    .filter((channel) => channel.active)
                    .map((channel) =>
                        channel.salePrice
                            ? Number(channel.salePrice).toFixed(2)
                            : '',
                    ),
            )
            .flat();

        const variantsPricesSet = new Set(allVariantsPrices);
        const isSamePrice = variantsPricesSet.size <= 1;

        if (isSamePrice) {
            return [
                true,
                allVariantsPrices[0] !== ''
                    ? Number(allVariantsPrices[0])
                    : null,
            ];
        }

        return [false, 0];
    }

    // If there are no variants it's a corrupted response from the BE
    return [true, 0];
};

const sortByOrder = (
    { displayOrder: a }: { displayOrder: number },
    { displayOrder: b }: { displayOrder: number },
) => a - b;

export const mapProductOptions = (
    productAttributes: IProductAttribute[],
): IProductOption[] =>
    productAttributes.sort(sortByOrder).map(({ id, options, ...rest }) => ({
        attribute: id,
        values: [...options].sort(sortByOrder),
        ...rest,
    }));

export const mapProductChannelToAvailabilitySectionChannel = ({
    channel,
    channelName,
    salePrice,
    openPriceAllowed,
}: IProductChannel): IAvailabilitySectionChannelData => ({
    id: String(channel),
    channelName,
    displayName: getChannelName(channelName),
    price: salePrice ? Number(salePrice) : null,
    openPriceAllowed,
});

export const filterGivenVariantOptionsFromVariantOptionsList = (
    allVariantOptions: IProductVariantOption[][],
    variantsOptionsToFilterOut:
        | IProductVariantOption[][]
        | IVariantAttributeValue[][],
) =>
    allVariantOptions.filter(
        (variantOptions) =>
            !variantsOptionsToFilterOut.find(
                (inactiveVariantOptions) =>
                    inactiveVariantOptions.length === variantOptions.length &&
                    inactiveVariantOptions.every((inactiveOption) =>
                        variantOptions.find(
                            (variantOption) =>
                                variantOption.attribute ===
                                    inactiveOption.attribute &&
                                variantOption.value === inactiveOption.value,
                        ),
                    ),
            ),
    );

export const mapProductToProductData = (
    product?: IProduct,
): IPartialProductData | undefined => {
    if (!product) {
        return;
    }
    const productVariants = product.variants ?? [];
    const productCategory = String(product.category?.id ?? '');
    const productVendor = String(product.vendor?.id ?? '');

    const productOptions = mapProductOptions(product.productAttributes);

    const allPossibleVariantOptions =
        mapProductOptionsToVariantOptions(productOptions);

    const variantsAttributeValues = productVariants.map(
        ({ attributeValues }) => attributeValues,
    );

    const inactiveVariantOptions =
        filterGivenVariantOptionsFromVariantOptionsList(
            allPossibleVariantOptions,
            variantsAttributeValues,
        );

    const [isSamePricePerVariantOrChannel, price] =
        getIsSamePrice(productVariants);

    const productChannels = productVariants.length
        ? (productVariants[0].channels ?? []).filter(({ active }) => active)
        : [];
    const hasVariants = product.productAttributes.length > 0;

    // product with no attributes and one variant is a standalone
    const standaloneProduct = !hasVariants ? product.variants[0] : undefined;

    return {
        general: {
            name: product.title,
            upc: standaloneProduct ? standaloneProduct.upc : undefined,
            sku: standaloneProduct ? standaloneProduct.sku : undefined,
            description: product.description,
            images: mapItemImagesToImages(product.images),
            category: productCategory,
            vendor: productVendor,
            tags: product.tags,
            canEarnReward: product.canEarnReward,
            canRedeemReward: product.canRedeemReward,
            // @TODO Which data from API is corresponding to that field?
            // see: CWF-345 - disable modifiers for phase 1
            // selectedModifiers: [], // @TODO modifiers
            productOptions,
            hasVariants,
        },
        availability: {
            channelsAvailability: productChannels.map(({ channel }) =>
                String(channel),
            ),
            channels: productChannels.map(
                mapProductChannelToAvailabilitySectionChannel,
            ),
            compareAtPrice: product.compareAtPrice
                ? Number(product.compareAtPrice)
                : null,
            costPerItem: product.costPrice ? Number(product.costPrice) : null,
            price,
            isSamePrice: isSamePricePerVariantOrChannel,
            itemsInStock: standaloneProduct
                ? Number(standaloneProduct.quantityInStock?.value ?? 0)
                : 0,
            variants: hasVariants
                ? mapProductDataVariantsToAvailabilitySectionVariants(
                      product.variants,
                      productOptions,
                  )
                : [],
            inactiveVariantOptions,
            hasVariants,
            priceUnit: product.priceUnit,
            isLowStockAlertEnabled: product.lowStockAlertEnabled,
            lowStockAlertQuantity: product.lowStockAlertQuantity
                ? Number(product.lowStockAlertQuantity?.value ?? 0)
                : null,
        },
        shipping: product.weight ?? undefined,
    };
};

export const mapProductDataToPartialProduct = async ({
    productData,
    locationChannels,
    isLowStockAlertFeatureEnabled,
    allVariants,
    isParent,
}: {
    productData: IProductData;
    locationChannels: IChannel[];
    isLowStockAlertFeatureEnabled: boolean;
    allVariants?: IProductVariant[];
    isParent?: boolean;
}): Promise<Omit<IProductPost, 'productAttributes'>> => {
    const { general, availability, shipping } = productData;

    const {
        priceUnit,
        isSamePrice,
        price,
        compareAtPrice,
        costPerItem,
        isLowStockAlertEnabled,
        lowStockAlertQuantity,
    } = availability;

    const { canRedeemReward, canEarnReward, name, description, tags } = general;

    const category = general.category ? general.category : null;

    const vendor = general.vendor ? general.vendor : null;

    const weight = shipping.value ? shipping : null;

    const images = await mapImagesToItemImages(general.images);

    const variants = isParent
        ? await Promise.all(
              availability.variants.map((variant) => {
                  return mapAvailabilitySectionVariantToPayloadProductVariant({
                      variant,
                      priceUnit,
                      price: price ? String(price) : null,
                      isSamePrice,
                      locationChannels,
                      variantId: variant.id ? variant.id : undefined,
                      allVariants,
                  });
              }),
          )
        : [
              mapAvailabilitySectionToStandalonePayloadProductVariant({
                  productData,
                  locationChannels,
                  allVariants,
              }),
          ];

    const lowStockQuantity =
        lowStockAlertQuantity !== undefined && lowStockAlertQuantity !== null
            ? {
                  unit: priceUnit,
                  value: String(lowStockAlertQuantity),
              }
            : null;

    const lowStockParams = isLowStockAlertFeatureEnabled
        ? {
              lowStockAlertEnabled: isLowStockAlertEnabled,
              lowStockAlertQuantity: lowStockQuantity,
          }
        : {};

    return {
        images,
        title: name,
        description,
        tags,
        priceUnit,
        compareAtPrice: compareAtPrice ? String(compareAtPrice) : null,
        costPrice: costPerItem ? String(costPerItem) : null,
        canEarnReward,
        canRedeemReward,
        category,
        vendor,
        weight,
        variants,
        ...lowStockParams,
    };
};

// Convert ProductFormData into API Product.
export const mapProductDataToApiProduct = async ({
    productData,
    locationChannels,
    allVariants,
    isLowStockAlertFeatureEnabled,
}: {
    productData: IProductData;
    locationChannels: IChannel[];
    allVariants?: IProductVariant[];
    isLowStockAlertFeatureEnabled: boolean;
}): Promise<IProductPost> => {
    const productAttributes = productData.general.productOptions
        .filter(({ attribute }) => attribute)
        .map(({ attribute, values }, index) => ({
            id: attribute as string,
            displayOrder: index,
            options: values.map(({ id }, index) => ({
                id: id as string,
                displayOrder: index,
            })),
        }));

    const isParent = productAttributes.length > 0;

    return {
        ...(await mapProductDataToPartialProduct({
            productData,
            locationChannels,
            isLowStockAlertFeatureEnabled,
            allVariants,
            isParent,
        })),
        productAttributes,
    };
};
