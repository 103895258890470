import { NexusPrompt } from 'features/common/components';

import { IPropTypes } from './DeletePrompt.types';

export function DeletePrompt(props: IPropTypes) {
    const {
        isOpen,
        message,
        onCancel,
        onConfirm,
        title = 'Are you sure you want to delete?',
        isLoading = false,
    } = props;

    return (
        <NexusPrompt
            isOpen={isOpen}
            onRequestClose={onCancel}
            title={title}
            message={message}
            onCancel={onCancel}
            onSubmit={onConfirm}
            cancelButtonText="Cancel"
            submitButtonText="Delete"
            isSubmitLoading={isLoading}
            isDanger
            cancel-button-data-testid="CancelButton"
            submit-button-data-testid="SubmitButton"
        />
    );
}

export default DeletePrompt;
