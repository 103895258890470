import { Controller, useFormContext } from 'react-hook-form';
import { TagsInput } from 'spoton-lib';

import { IPropTypes } from './TagsInputController.types';

export function TagsInputController(props: IPropTypes) {
    const { name } = props;
    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
                <TagsInput
                    {...props}
                    onTagAdd={(tag) => {
                        setValue(name, [...field.value, tag]);
                    }}
                    onTagRemove={(index) => {
                        setValue(name, [
                            ...field.value.slice(0, index),
                            ...field.value.slice(index + 1),
                        ]);
                    }}
                    onClear={() => {
                        setValue(name, []);
                    }}
                    tagList={field.value}
                    isValid={!fieldState.error}
                    secondaryCondition={
                        fieldState.error?.message ?? props.secondaryCondition
                    }
                />
            )}
        />
    );
}

export default TagsInputController;
