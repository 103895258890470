import { useState, useMemo, useEffect } from 'react';
import { Text, Modal, Button, Icon, Checkbox } from 'spoton-lib';

import { IShippingCarrierService } from 'features/shipping/types';
import { servicesOrder } from 'features/common/utils';
import { formatEstimatedDeliveryTime } from 'features/shipping/utils/formatEstimatedDeliveryTime';

import { IGroupedServices, IPropTypes } from './CarrierServicesModal.types';
import styles from './CarrierServicesModal.module.scss';

export function CarrierServicesModal(props: IPropTypes) {
    const { isOpen, selectedCarrier, onClose, addServices, checkedServices } =
        props;

    const [selectedServices, setSelectedServices] = useState<
        IShippingCarrierService[]
    >([]);

    useEffect(() => {
        setSelectedServices(checkedServices);
    }, [isOpen]);

    const hasServiceAlreadyBeenSelected = (serviceId: string) => {
        return selectedServices.some((service) => service.id === serviceId);
    };

    const removeServiceFromSelected = (serviceId: string) => {
        setSelectedServices(
            selectedServices.filter((service) => service.id !== serviceId),
        );
    };

    const addServiceToSelected = (service: IShippingCarrierService) => {
        setSelectedServices([...selectedServices, service]);
    };

    const handleAddServicesClick = (): void => {
        if (!selectedServices.length) return;
        addServices(selectedServices);
    };

    const onServiceCheck = (
        isChecked: boolean,
        checkedService: IShippingCarrierService,
    ): void => {
        if (hasServiceAlreadyBeenSelected(checkedService.id) && !isChecked) {
            removeServiceFromSelected(checkedService.id);
        } else if (
            !hasServiceAlreadyBeenSelected(checkedService.id) &&
            isChecked
        ) {
            addServiceToSelected(checkedService);
        }
    };

    const groupServices = () => {
        const groups: IGroupedServices = {};
        selectedCarrier?.services.forEach((service) => {
            if (!groups[service.speedClass]) {
                groups[service.speedClass] = [];
            }
            groups[service.speedClass].push(service);
        });

        return groups;
    };

    const renderHeader = useMemo(
        () => (
            <div className={styles.CarrierServicesModalHeader}>
                <div className={styles.CarrierServicesModalHeader_textBlock}>
                    <Text
                        type="h4"
                        className={styles.CarrierServicesModalHeader_title}
                    >
                        {`${selectedCarrier?.name}`} Services
                    </Text>
                    <Text type="p">
                        These services will be displayed to customers checkout.
                    </Text>
                </div>
                <Button
                    className={styles.CarrierServicesModalHeader_close}
                    variant="tertiary"
                    onClick={onClose}
                    block
                    data-testid="CloseServicesModalButton"
                >
                    <Icon name="CloseIcon" alt="close" size={20} />
                </Button>
            </div>
        ),
        [selectedCarrier],
    );

    const renderServiceRow = (service: IShippingCarrierService) => (
        <div
            key={service.id}
            className={styles.CarrierServicesModalContentItem}
        >
            <div className={styles.CarrierServicesModalContentItem_leftPart}>
                <Checkbox
                    data-testid={`ServiceCheckbox_${service.id}`}
                    className={styles.CarrierServicesModalContent_service}
                    label={service.name}
                    checked={hasServiceAlreadyBeenSelected(service.id)}
                    onChange={(event) =>
                        onServiceCheck(
                            (event.target as HTMLInputElement).checked,
                            service,
                        )
                    }
                />
                <Text
                    type="p"
                    className={styles.CarrierServicesModalContent_weight}
                >
                    {`(Up to ${service.weightLimit.value} ${service.weightLimit.unit})`}
                </Text>
            </div>

            <Text type="p">
                {formatEstimatedDeliveryTime(service.estimatedDeliveryTime)}
            </Text>
        </div>
    );

    const renderContent = () => {
        const groupedServices = groupServices();
        const keysArray =
            (selectedCarrier && servicesOrder[selectedCarrier.name]) ||
            Object.keys(groupedServices);
        return (
            <div className={styles.CarrierServicesModalContent}>
                {keysArray.map((groupName: string, index: number) => (
                    <div
                        key={groupName}
                        className={styles.CarrierServicesModalContentGroup}
                    >
                        <div
                            className={
                                styles.CarrierServicesModalContentGroup_header
                            }
                        >
                            <Text
                                type="h6"
                                className={
                                    styles.CarrierServicesModalContentGroup_title
                                }
                            >
                                {groupName}
                            </Text>
                            {index === 0 && (
                                <Text
                                    isBold
                                    className={
                                        styles.CarrierServicesModalContentGroup_delivery
                                    }
                                >
                                    Estimated Delivery time
                                </Text>
                            )}
                        </div>
                        {groupedServices[groupName] &&
                            groupedServices[groupName].map((service) =>
                                renderServiceRow(service),
                            )}
                    </div>
                ))}
            </div>
        );
    };

    const renderFooter = () => (
        <div className={styles.CarrierServicesModalFooter}>
            <Button
                variant="primary"
                onClick={handleAddServicesClick}
                data-testid="AddServicesModalButton"
                disabled={!selectedServices.length}
            >
                {checkedServices.length ? 'Save' : 'Add Services'}
            </Button>
        </div>
    );

    return (
        <Modal
            isOpen={isOpen}
            className={styles.CarrierServicesModal}
            // This fixes a console error "react-modal: App element is not defined"
            appElement={document.getElementById('root') ?? undefined}
        >
            {renderHeader}
            {renderContent()}
            {renderFooter()}
        </Modal>
    );
}

export default CarrierServicesModal;
