import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    LeftNavHeader,
    LeftNavVerticalsMenu,
    LeftNavAppMenu,
    LeftNavSettings,
    Banner,
} from 'spoton-lib';
import {
    Link,
    SubLink,
} from 'spoton-lib/dist/esm/types/src/components/LeftNavAppMenu/LeftNavAppMenu.types';
import { VerticalLink } from 'spoton-lib/dist/esm/types/src/components/LeftNavVerticalsMenu/LeftNavVerticalsMenu.types';

import { Logger } from 'features/common/utils/logger.utils';
import { GlobalPlaceholder } from 'features/common/components/GlobalPlaceholder';
import { MenuPopup } from 'features/common/components/MenuPopup';
import { getConfigVar, fullstory, NetworkError } from 'features/common';
import { omnichannelPaths } from 'features/omnichannel/routes/omnichannel.paths';
import { useFlags, useOnBoardedPages } from 'features/common/hooks';
import { useAppInitialLoad } from 'features/omnichannel/hooks';
import { useAuthStoreActions } from 'features/common/zustand/useAuthState';

import styles from './App.module.scss';
import {
    getExternalAppItems,
    getNavMenuItems,
    getSettingsLinks,
    ISettingsLink,
} from './App.utils';

export function App() {
    const {
        userName,
        userNameInitials,
        currentUser,
        merchantLocationId,
        menuLinks,
        userAddress,
        currentMerchantLocation,
        isLoading,
        isError,
        isMerchantLocationIdError,
        isAppointmentsIntegrated,
        refetchInitialLoad,
        refetchMerchantLocationId,
    } = useAppInitialLoad();
    const { isVendorsManagementPageEnabled } = useFlags();

    const appName = 'Catalog';
    const pageName = 'Catalog';

    const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
    const [isVerticalsMenuOpen, setIsVerticalsMenuOpen] = useState(false);
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

    const {
        isPopupOpen: isAppointmentsPopupOpen,
        isBannerVisible: isAppointmentsBannerVisible,
        showBanner: showAppointmentsBanner,
        closePopup: closeAppointmentsPopup,
        closeBanner: closeAppointmentsBanner,
    } = useOnBoardedPages({
        page: getConfigVar('REACT_APP_EXTERNAL_APP_APPOINTMENTS') as string,
        handleRedirect: toggleVerticalsMenu,
        id: merchantLocationId,
        isEnabled: isAppointmentsIntegrated,
    });

    const navigate = useNavigate();
    const location = useLocation();
    const activeHref = location.pathname;
    const navMenuItems = getNavMenuItems(
        activeHref,
        isVendorsManagementPageEnabled,
    );
    const navExternalApps = getExternalAppItems(isAppointmentsIntegrated);
    const [settingsLinks, setSettingsLinks] = useState<ISettingsLink[]>([]);
    const { logoutUserAction } = useAuthStoreActions();

    useEffect(() => {
        if (!currentUser || currentUser.id === undefined) {
            return;
        }

        fullstory.identify(String(currentUser.id));
    }, [currentUser]);

    useEffect(() => {
        if (
            merchantLocationId === '' ||
            merchantLocationId === undefined ||
            !currentMerchantLocation ||
            currentUser === null ||
            currentUser === undefined
        ) {
            return;
        }

        fullstory.setUserVars({
            merchantLocationId,
            isDemo: currentMerchantLocation.isDemo,
            isTest: currentMerchantLocation.isTest,
            userId: currentUser.spotonUserId,
            email: currentUser.email,
            merchantId: currentMerchantLocation.mid,
            nameOfBusiness: currentMerchantLocation.dbaName,
        });
    }, [merchantLocationId, currentMerchantLocation, currentUser]);

    useEffect(() => {
        setSettingsLinks(getSettingsLinks(menuLinks));
    }, [menuLinks]);

    function toggleMainMenu(): void {
        Logger.debug('toggle main menu');

        setIsMainMenuOpen(!isMainMenuOpen);
    }

    function toggleSettingsMenu(): void {
        Logger.debug('toggle settings menu');

        setIsSettingsMenuOpen(!isSettingsMenuOpen);
    }

    function toggleVerticalsMenu(): void {
        Logger.debug('toggle verticals menu');

        setIsVerticalsMenuOpen(!isVerticalsMenuOpen);
    }

    function handleAppMenuLinkSelection(menuItem: Link | SubLink): void {
        navigate(menuItem.link as string);
    }

    function handleAppMenuClose(): void {
        setIsMainMenuOpen(false);
    }

    function handleAppMenuOpenVerticalsMenu(): void {
        setIsVerticalsMenuOpen(true);
    }

    function handleVerticalsMenuSelection(appItem: VerticalLink): void {
        // Only 'Catalog' link is active. It's a link to omnichannel homepage.
        if (appItem.isActive) {
            navigate(appItem.link);
            setIsVerticalsMenuOpen(false);
        } else {
            window.location.href = appItem.link;
        }
    }

    function handleVerticalsMenusClose(): void {
        setIsVerticalsMenuOpen(false);
        closeAppointmentsPopup();
        showAppointmentsBanner();
    }

    function handleSettingsSelection(link: any): void {
        if (link.id === 'logout') {
            logoutUserAction();
            navigate(omnichannelPaths.logout);
        } else if (link.id === 'locations') {
            navigate(omnichannelPaths.merchantLocations);
        } else {
            window.location.href = `${getConfigVar(
                'REACT_APP_EXTERNAL_APP_DASHBOARD',
            )}${link.id}`;
        }

        setIsSettingsMenuOpen(false);
    }

    function handleSettingsClose(): void {
        setIsSettingsMenuOpen(false);
    }

    /* eslint-disable @typescript-eslint/naming-convention */
    const leftNavHeaderClassNames = {
        LeftNavHeader: styles.LeftNavHeader,
        UserDetails: 'fs-mask',
        UserDetailsAvatar: 'fs-mask',
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    const renderContent = () => {
        if (isMerchantLocationIdError) {
            return (
                <div className={styles.NetworkError}>
                    <NetworkError
                        onRefreshClick={refetchMerchantLocationId}
                        testId="AppMerchantLocationIdErrorButton"
                    />
                </div>
            );
        }

        if (isLoading) {
            return <GlobalPlaceholder />;
        }

        if (isError) {
            return (
                <div className={styles.NetworkError}>
                    <NetworkError
                        onRefreshClick={refetchInitialLoad}
                        testId="AppFasFetchErrorButton"
                    />
                </div>
            );
        }

        return (
            <div>
                <div>
                    <LeftNavAppMenu
                        isOpen={isMainMenuOpen}
                        applicationName={appName}
                        applicationLinks={navMenuItems}
                        onSelectLink={handleAppMenuLinkSelection}
                        onClose={handleAppMenuClose} // mobile only
                        onOpenVerticalsMenu={handleAppMenuOpenVerticalsMenu} // mobile only
                        className={styles.AppMenu}
                    />
                </div>

                <div className={styles.AppWrapper}>
                    <div className={styles.AppContent}>
                        {isAppointmentsBannerVisible && (
                            <Banner
                                message="You are now in the Catalog."
                                timeout={5000}
                                variant="informative"
                                onDismiss={closeAppointmentsBanner}
                            />
                        )}
                        <Outlet />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.App} key={merchantLocationId}>
            <LeftNavHeader
                classNames={leftNavHeaderClassNames}
                applicationName={appName}
                currentPageName={pageName}
                userName={userName}
                userNameInitials={userNameInitials}
                userAddress={userAddress}
                isLoading={isLoading}
                toggleMainMenu={toggleMainMenu}
                toggleSettingsMenu={toggleSettingsMenu}
                toggleVerticalsMenu={toggleVerticalsMenu}
            />
            <LeftNavSettings
                isOpen={isSettingsMenuOpen}
                closeSettingsMenu={handleSettingsClose}
                settingsLinks={settingsLinks}
                onSelectLink={handleSettingsSelection}
                className={styles.SettingsMenu}
                userName={userName}
                userAddress={userAddress}
                userNameInitials={userNameInitials}
            />
            {/* menu with links to external applications (links not dependant on omnichannel or user) */}
            <LeftNavVerticalsMenu
                isOpen={isVerticalsMenuOpen}
                applicationName=""
                links={navExternalApps}
                onSelectLink={handleVerticalsMenuSelection}
                onClose={handleVerticalsMenusClose}
            />
            <MenuPopup
                isOpen={isAppointmentsPopupOpen}
                text="Click Appointments to return to the app"
                onClick={closeAppointmentsPopup}
            />
            {renderContent()}
        </div>
    );
}

export default App;
