import { DependencyList, useMemo } from 'react';

import {
    useQuery,
    useMutation,
    useInfiniteQuery,
    usePaginatedQuery,
} from 'features/common/serverStateHandler';
import {
    productsGetByParentIds,
    productsBulkAction,
    productGetById,
    productUpdate,
    productCreate,
    productDelete,
    productsGet,
} from 'features/products/api/Products.data';
import {
    IProductBulkPayload,
    IProductsGetParams,
    IProductsGetResponse,
} from 'features/products/api/Products.types';
import { IProductLink } from 'features/products/types';
import { useDidUpdate } from 'features/common/utils';
import {
    QueryKeyT,
    UseMutationOptions,
} from 'features/common/serverStateHandler/types';
import { IChannelName } from 'features/common/types';

import {
    mapProductToProductData,
    formatVariants,
    parseProductLink,
    parseProductLinkToApplySidebarItem,
} from './products.utils';
import {
    productKeys,
    productVariantsKeys,
    variantsLinksKeys,
} from './queryKeys';

export const useGetVariantsByParents = (
    ids: string[],
    priceInChannel?: IChannelName,
) => {
    const { data, ...rest } = useQuery(
        productVariantsKeys.listByParent(ids),
        () => productsGetByParentIds(ids, ids.length),
        {
            // needed to keep upc state up-to-date,
            enabled: ids.length > 0,
            refetchOnWindowFocus: false,
            staleTime: 20_000,
        },
    );

    const variants = useMemo(() => {
        return formatVariants(data?.results || [], priceInChannel);
    }, [data?.results]);

    return {
        variants,
        ...rest,
    };
};

export const useBulkUpdate = () => {
    return useMutation(productsBulkAction, {
        onSuccess: ({ queryHandler }) => {
            // productVariantsKeys.all has to be removed
            // because ProductPage is freezing cached data on page load (form initialization)
            queryHandler.removeQueries(productVariantsKeys.all);
        },
        isSuccessToast: false,
        formatErrorMessage: () => 'Error while updating products.',
    });
};

const productsFetch = (params: IProductsGetParams) =>
    productsGet({
        ...params,
        pageSize: params.pageSize || 10,
    });

export const useGetInfiniteProductLinks = (
    params: IProductsGetParams,
    enabled = true,
) => {
    const { data, ...rest } = useInfiniteQuery<
        QueryKeyT,
        IProductsGetResponse,
        unknown,
        IProductsGetResponse,
        number
    >(
        productKeys.infinity(params.title),
        ({ pageParam }) => productsFetch({ ...params, page: pageParam }),
        {
            keepPreviousData: true,
            enabled,
        },
    );

    return {
        products:
            data?.pages.flatMap((pageData) =>
                pageData.results.map(parseProductLinkToApplySidebarItem),
            ) || [],
        ...rest,
    };
};

export const useGetPaginatedProductLinks = ({
    params,
    isEnabled = true,
    resetPaginationDeps = [],
}: {
    params: IProductsGetParams;
    isEnabled?: boolean;
    resetPaginationDeps?: DependencyList;
}) => {
    const {
        data,
        numberOfPages,
        isLoading,
        isFetching,
        isError,
        refetch,
        setCurrentPage,
        ...rest
    } = usePaginatedQuery<IProductLink>({
        params,
        queryFn: productsFetch,
        queryKey: productKeys.list,
        options: {
            enabled: isEnabled,
        },
    });

    useDidUpdate(() => {
        setCurrentPage(1);
    }, [...resetPaginationDeps]);

    return {
        products: data?.results?.map((item) => parseProductLink(item)) || [],
        productsNumberOfPages: numberOfPages,
        isLoadingProducts: isLoading,
        isFetchingProducts: isFetching,
        hasFetchProductsError: isError,
        refetchProductLinks: refetch,
        setCurrentPage,
        ...rest,
    };
};

type IUseProductBulkUpdateOptions = UseMutationOptions<
    void,
    unknown,
    IProductBulkPayload,
    unknown
>;

export const useProductBulkUpdate = (
    options: IUseProductBulkUpdateOptions,
    onUpdateSuccess?: () => void,
) => {
    return useMutation(productsBulkAction, {
        ...options,
        onSuccess: ({ queryHandler }) => {
            // productVariantsKeys.all has to be removed
            // because ProductPage is freezing cached data on page load (form initialization)
            queryHandler.removeQueries(productVariantsKeys.all);
            queryHandler.refetchQueries(productKeys.list({ page: 1 }));
            queryHandler.invalidateQueries(productKeys.list({}));
            queryHandler.invalidateQueries(productKeys.infinityList());
            onUpdateSuccess?.();
        },
    });
};

export const useFetchProductData = (id?: string) => {
    const { data, isLoading, ...rest } = useQuery(
        productVariantsKeys.product(id),
        () => (id ? productGetById(id) : Promise.resolve(undefined)),
        {
            enabled: !!id,
            useErrorBoundary: true,
        },
    );

    const productData = mapProductToProductData(data);

    return {
        productData,
        rawProduct: data,
        isLoading,
        ...rest,
    };
};

export const useUpdateProduct = (
    onUpdateSuccess: () => void,
    onUpdateError: () => void,
) => {
    const { mutate, isLoading, ...rest } = useMutation(productUpdate, {
        onSuccess: ({ queryHandler, variables }) => {
            queryHandler.refetchQueries(productKeys.list({ page: 1 }));
            queryHandler.invalidateQueries(productKeys.list({}));
            queryHandler.invalidateQueries(productVariantsKeys.all);
            queryHandler.invalidateQueries(productKeys.infinityList());
            queryHandler.invalidateQueries(
                variantsLinksKeys.list({ productId: variables.id }),
            );
            onUpdateSuccess?.();
        },
        onError: onUpdateError,
        formatSuccessMessage: () => 'Product updated successfully!',
        formatErrorMessage: () =>
            'Unexpected error when trying to update the product. Please try again.',
    });

    return {
        updateProduct: mutate,
        isUpdatingProduct: isLoading,
        ...rest,
    };
};

export const useCreateProduct = (
    onCreateSuccess: () => void,
    onCreateError: () => void,
) => {
    const { mutate, isLoading, ...rest } = useMutation(productCreate, {
        onSuccess: ({ queryHandler }) => {
            queryHandler.refetchQueries(productKeys.list({ page: 1 }));
            queryHandler.invalidateQueries(productKeys.list({}));
            queryHandler.invalidateQueries(productVariantsKeys.all);
            queryHandler.invalidateQueries(productKeys.infinityList());
            onCreateSuccess?.();
        },
        onError: onCreateError,
        formatSuccessMessage: () => 'Product created successfully!',
        formatErrorMessage: () =>
            'Unexpected error when trying to create the product. Please try again.',
    });

    return {
        createProduct: mutate,
        isCreatingProduct: isLoading,
        ...rest,
    };
};

export const useDeleteProduct = (
    onDeleteSuccess?: () => void,
    onDeleteError?: () => void,
) => {
    const { mutate, isLoading, ...rest } = useMutation(productDelete, {
        onSuccess: ({ queryHandler }) => {
            queryHandler.refetchQueries(productKeys.list({ page: 1 }));
            queryHandler.invalidateQueries(productKeys.list({}));
            queryHandler.invalidateQueries(productVariantsKeys.all);
            queryHandler.invalidateQueries(productKeys.infinityList());
            onDeleteSuccess?.();
        },
        onError: onDeleteError,
        formatSuccessMessage: () => 'Product deleted successfully!',
        formatErrorMessage: () =>
            'Unexpected error when trying to delete the product. Please try again.',
    });

    return {
        deleteProduct: mutate,
        isDeletingProduct: isLoading,
        ...rest,
    };
};
