import * as yup from 'yup';

import { IAddCarrierAccountPayload } from 'features/shipping/api/Shipping.types';

import { IFedExConnectFormValues } from './ConnectFedExFormModal.types';
import { defaultCountry } from './ConnectFedExFormModal.constants';

export const initialValues: IFedExConnectFormValues = {
    accountNickname: '',
    accountId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    fromAddressSt: '',
    fromAddressCity: '',
    fromAddressState: '',
    fromAddressZip: '',
    fromAddressCountryIso2: defaultCountry.value,
};

export const validationSchema: yup.SchemaOf<IFedExConnectFormValues> = yup
    .object()
    .shape({
        accountNickname: yup.string(),
        accountId: yup
            .string()
            .nullable()
            .test('len', 'Must be exactly 9 characters', (val) => {
                if (!val) return true;

                return val.length === 9;
            })
            .required('Required'),
        firstName: yup.string().required('Required'),
        lastName: yup.string().required('Required'),
        phoneNumber: yup
            .string()
            .required('Required')
            // agreed with product team that we do not need to validate phone number - it is shippo service responsibility
            .min(7, 'Phone number is not valid'),
        fromAddressSt: yup.string().required('Required'),
        fromAddressCity: yup.string().required('Required'),
        fromAddressState: yup.string(),
        fromAddressZip: yup.string(),
        fromAddressCountryIso2: yup.string().required('Required'),
    });

export const parseFedExConnectToAPIFormat = (
    data: IFedExConnectFormValues,
): IAddCarrierAccountPayload => {
    return {
        accountId: String(data.accountId),
        active: true, // fixed
        carrier: 'fedex', // currently ony fedex is working
        metadata: data.accountNickname || '',
        parameters: {
            firstName: data.firstName,
            lastName: data.lastName,
            fromAddressCity: data.fromAddressCity,
            fromAddressCountryIso2: data.fromAddressCountryIso2,
            fromAddressSt: data.fromAddressSt,
            fromAddressState: data.fromAddressState,
            fromAddressZip: data.fromAddressZip,
            phoneNumber: data.phoneNumber,
        },
    };
};
