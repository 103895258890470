import { Button, Text } from 'spoton-lib';

import styles from './AddEditPage.module.scss';
import { IAddEditPageProps } from './AddEditPage.types';

/**
 * Wrapper, allowing for contextual actions depending on content being wrapped.
 */
export function AddEditPage(props: IAddEditPageProps) {
    const {
        title,
        actions,
        children,
        onSaveHandler,
        saveBtnText,
        saveBtnTestId,
        onCancelHandler,
        cancelBtnText,
        cancelBtnTestId,
        isCancelButtonDisabled = false,
        isSaveButtonDisabled = false,
    } = props;

    return (
        <div className={styles.AddEditPage}>
            <div className={styles.TopBar}>
                <div className={styles.TopBar_paddingCover} />
                <div className={styles.TopBar_title}>
                    <Text type="h4">{title}</Text>
                </div>
                <div className={styles.TopBar_actionsList}>
                    <div className={styles.ActionsList}>
                        {actions &&
                            actions.map((action, idx) => {
                                return (
                                    <Button
                                        key={idx}
                                        onClick={action.onClick}
                                        className={styles.ActionsList_action}
                                        data-testid={action.testId}
                                    >
                                        {action.displayedText}
                                    </Button>
                                );
                            })}

                        <Button
                            onClick={onCancelHandler}
                            variant="secondary"
                            className={`${styles.ActionsList_actionDefault} ${styles.ActionsList_action}`}
                            data-testid={cancelBtnTestId}
                            type="button"
                            disabled={isCancelButtonDisabled}
                        >
                            {cancelBtnText || 'Cancel'}
                        </Button>

                        <Button
                            onClick={onSaveHandler}
                            className={`${styles.ActionsList_actionDefault} ${styles.ActionsList_action}`}
                            data-testid={saveBtnTestId}
                            type="button"
                            disabled={isSaveButtonDisabled}
                        >
                            {saveBtnText || 'Save'}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.AddEditPage_content}>{children}</div>
        </div>
    );
}

export default AddEditPage;
