// eslint-disable-next-line no-restricted-imports
import axiosDefault from 'axios';
import qs from 'qs';

import { omnichannelPaths } from 'features/omnichannel/routes/omnichannel.paths';

import {
    serializeToCamelCase,
    serializeToSnakeCase,
} from './serializers.utils';
import { getConfigVar } from './config.utils';
import { logout } from './auth.utils';
import { logResponseErrors } from './axiosInterceptor.utils';

// Current merchant location id. We can assume that there is always a id set, otherwise user would be redirected to SSO by auth mechanism.
let merchantLocationId: string | undefined = undefined;

const axios = axiosDefault.create({
    baseURL: getConfigVar('REACT_APP_OMNICHANNEL_API') as string,
    headers: {
        'Content-Type': 'application/json',
    },
    transformResponse: [
        (data: any): any => {
            // Parse json, if json is incorrect return data without parsing it
            // That's because error thrown by json parser doesn't contain status code which is needed in the interceptor
            try {
                return data ? serializeToCamelCase(JSON.parse(data)) : data;
            } catch {
                return data;
            }
        },
    ],
    transformRequest: [
        (data: any): any => {
            // Prevent stringify of multipart/form-data payload (FormData)
            // since it does not represent JSON and contains binary data (files) in most cases.
            // Used e.g. by Catalog Import feature.
            if (!data || data instanceof FormData || data instanceof File) {
                return data;
            }
            return JSON.stringify(serializeToSnakeCase(data));
        },
    ],
    paramsSerializer: (params: any) =>
        qs.stringify(serializeToSnakeCase(params)),
    withCredentials: true,
});

// Log errors
axios.interceptors.response.use(...logResponseErrors);

// Logout on 401
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // handy when debugging tests - needs to be commented as it also catches mocked axios errors
        // if (import.meta.env.MODE === 'test') {
        //     throw new Error(error);
        // }
        const dashboardUrl = getConfigVar(
            'REACT_APP_EXTERNAL_APP_DASHBOARD',
        ) as string;

        const status = error.response?.status;

        if (!error.config.url.includes('logout')) {
            // Logout user (delete cookies and redirect to SSO)
            // On 401
            if (status === 401) {
                logout();
                window.location.href = omnichannelPaths.login;
            }

            // On 403 if first calls, which is fetching merchant locations or current user
            // redirect to the dashboard as this account should not have access to this app
            if (
                status === 403 &&
                (error.config.url.includes('merchant-locations') ||
                    error.config.url.includes('current-user'))
            ) {
                window.location.href = dashboardUrl;
            }
        }

        return Promise.reject(error);
    },
);

// Add merchant location id header
axios.interceptors.request.use(
    (config) => {
        // Do not include the merchant ID if not set
        if (merchantLocationId !== undefined && config.headers) {
            config.headers['Merchant'] = merchantLocationId;
        }

        return config;
    },
    (error) => error,
);

// If this is spoton.sh domain (PR or dev environment) let the BE know
axios.interceptors.request.use(
    (config) => {
        if (/spoton\.sh/.test(window.location.host) && config.headers) {
            config.headers['Is-Spoton-Sh-Domain'] = 'true';
        }

        return config;
    },
    (error) => error,
);

export const setMerchantLocationIdHeader = (id?: string): void => {
    merchantLocationId = id;
};

export { axios, axiosDefault };

export type { AxiosResponse, AxiosError } from 'axios';

if (import.meta.env.MODE === 'test') {
    // axios will default to using the XHR adapter which can't be intercepted by
    // nock. So, configure axios to use the node adapter.
    // References:
    // https://github.com/nock/nock/issues/699#issuecomment-272708264
    // https://github.com/axios/axios/issues/305
    axios.defaults.adapter = require('axios/lib/adapters/http');
}
