import {
    fetchMerchantLocationSettingsById,
    merchantLocationSettingsCreate,
} from 'features/settings/api/MerchantLocationSettings.data';
import {
    IAnyMerchantLocationSettingPostPayloadItem,
    IMerchantLocationSettingPostResponse,
} from 'features/settings/api/MerchantLocationSettings.types';
import { useMutation, useQuery } from 'features/common/serverStateHandler';
import { UseMutationOptions } from 'features/common/serverStateHandler/types';
import { IAllMerchantLocationSettings } from 'features/common/types';

import { useGetMerchantLocationId } from './merchantLocationId';

export const merchantLocationSettingsQueryKeys = {
    current: [{ scope: 'merchantLocationSettings' }] as const,
};

export const useGetMerchantLocationSettings = () => {
    const { merchantLocationId } = useGetMerchantLocationId();

    const { data, isError, isLoading, refetch, ...result } = useQuery(
        merchantLocationSettingsQueryKeys.current,
        () => fetchMerchantLocationSettingsById(merchantLocationId),
        {
            enabled: !!merchantLocationId,
            select: (data) =>
                data.results.reduce<IAllMerchantLocationSettings>(
                    (acc, item) => ({ ...acc, [item.definition.name]: item }),
                    {},
                ),
            dataFunctionName: 'merchantLocationSettings',
        },
    );

    return {
        ...result,
        merchantLocationSettings: data,
        isMerchantLocationSettingsLoading: isLoading,
        isMerchantLocationSettingsError: isError,
        refetchMerchantLocationSettings: refetch,
    };
};

export type UseUpdateMerchantLocationSettings = UseMutationOptions<
    IMerchantLocationSettingPostResponse,
    unknown,
    Omit<IAnyMerchantLocationSettingPostPayloadItem, 'merchantLocationId'>,
    unknown
>;

export const useUpdateMerchantLocationSettings = (
    options: UseUpdateMerchantLocationSettings = {},
) => {
    const { getMerchantLocationIdLoaded } = useGetMerchantLocationId();
    const merchantLocationId = getMerchantLocationIdLoaded();

    const { mutateAsync, mutate, isLoading, ...rest } = useMutation<
        IMerchantLocationSettingPostResponse,
        unknown,
        Omit<IAnyMerchantLocationSettingPostPayloadItem, 'merchantLocationId'>
    >(
        (payload) =>
            merchantLocationSettingsCreate({ ...payload, merchantLocationId }),
        {
            onSuccess: ({ queryHandler, data }) => {
                queryHandler.setQueryData(
                    merchantLocationSettingsQueryKeys.current,
                    data,
                );
            },
            ...options,
        },
    );

    return {
        updateMerchantLocationSettingsSync: mutate,
        updateMerchantLocationSettings: mutateAsync,
        isUpdatingMerchantLocationSettings: isLoading,
        ...rest,
    };
};
