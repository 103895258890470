import { useEffect, useState } from 'react';
import { colors, IconButton, SelectionMenu } from 'spoton-lib';

import { IColumnsMap, ISelectionItem } from './useSelectableColumns.types';

export const useSelectableColumns = <T extends string>({
    availableColumns,
    columnsMap,
    isMobile,
    namespace,
    id,
}: {
    availableColumns: T[];
    columnsMap: IColumnsMap<T>;
    isMobile: boolean;
    namespace: string;
    id: string;
}) => {
    const lsName = `ac_${namespace}_${id}`;

    const getLSConfiguration = () => {
        try {
            const ls = localStorage.getItem(lsName) || '[]';
            const parsedLs = JSON.parse(ls);
            return Array.isArray(parsedLs) ? parsedLs : [];
        } catch {
            return [];
        }
    };

    const setLSConfiguration = (newUsedColumns: string[]) => {
        try {
            localStorage.setItem(lsName, JSON.stringify(newUsedColumns));
        } catch {
            //
        }
    };

    const getInitialColumnsConfiguration = () => {
        const storedColumns = getLSConfiguration();

        return availableColumns.filter(
            (column) =>
                (storedColumns.length
                    ? storedColumns.includes(column)
                    : columnsMap[column].isDefault) &&
                ((isMobile && !columnsMap[column].shouldHideOnMobile) ||
                    !isMobile),
        );
    };

    const [usedColumns, setUsedColumns] = useState<T[]>(() =>
        getInitialColumnsConfiguration(),
    );

    const columnsSelection = availableColumns
        .map((column) => {
            const { isNotSelectable, label } = columnsMap[column];

            return !isNotSelectable
                ? {
                      value: column,
                      label,
                      selected: usedColumns.includes(column),
                  }
                : null;
        })
        .filter((column) => column) as ISelectionItem[];

    const [isColumnsMenuOpen, setIsColumnsMenuOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] =
        useState<ISelectionItem[]>(columnsSelection);

    const handleToggleMenu = () => setIsColumnsMenuOpen(!isColumnsMenuOpen);

    const onColumnSelectionChange = (newSelectedColumns: ISelectionItem[]) => {
        setSelectedColumns(newSelectedColumns);
    };

    useEffect(() => {
        const chosenColumns = selectedColumns
            .filter(({ selected }) => selected)
            .map(({ value }) => value);

        let column: T;
        const permanentColumns: Partial<T[]> = [];

        for (column in columnsMap) {
            if (columnsMap[column].isNotSelectable) {
                permanentColumns.push(column);
            }
        }

        const newUsedColumns = availableColumns.filter(
            (column) =>
                permanentColumns.includes(column) ||
                (!isMobile && chosenColumns.includes(column)) ||
                (isMobile && !columnsMap[column].shouldHideOnMobile),
        );

        setLSConfiguration(newUsedColumns);

        setUsedColumns(newUsedColumns);
    }, [isMobile, selectedColumns]);

    const renderSelectionMenu = () => (
        <>
            {!isMobile && (
                <SelectionMenu
                    isOpen={isColumnsMenuOpen}
                    menuPosition="right"
                    selection={columnsSelection}
                    onSelectionChange={onColumnSelectionChange}
                    onRequestClose={handleToggleMenu}
                    data-testid="SelectableColumns"
                    triggerElement={
                        <IconButton
                            disableBorder
                            variant="primary"
                            name="AddCircleOutlineIcon"
                            alt="Toggle columns visibility"
                            size={24}
                            color={colors.primary70}
                            onClick={handleToggleMenu}
                        />
                    }
                />
            )}
        </>
    );

    return {
        usedColumns,
        renderSelectionMenu,
    };
};
