import { getConfigFlag } from './config.utils';
import { IFeatureName, IFeatureMap } from './features.utils.types';

/**
 * This is a map to distinct feature name from env variable
 * since we shouldn't care where feature state comes from
 */
export const featureToEnvVariable: IFeatureMap = {
    appointments: 'REACT_APP_FEATURE_APPOINTMENTS',
};

/**
 * A helper for checking if given feature is enabled
 */
export function isFeatureEnabled(feature: IFeatureName): boolean {
    return getConfigFlag(featureToEnvVariable[feature]);
}
