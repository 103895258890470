import {
    IAvailabilityChannel,
    IProductAvailabilityVariant,
    IProductAvailabilityVariantList,
} from 'features/products/types';

export const areAllVariantsSelected = (
    data: IProductAvailabilityVariantList,
    channel: IAvailabilityChannel,
) => {
    return data.variants
        .map((variant) => variant.channels[channel.id])
        .every(Boolean);
};

export const hasLastChannelSelected = (
    variant: IProductAvailabilityVariant,
): boolean => {
    return Object.values(variant.channels).filter(Boolean).length === 1;
};

const hasOnlyThisChannelSelected = (
    variant: IProductAvailabilityVariant,
    channelId: string,
): boolean => {
    const selectedChannels = Object.entries(variant.channels)
        .filter(([, v]) => Boolean(v))
        .map(([k, v]) => ({ channelId: k, isSelected: v }));
    return (
        selectedChannels.length === 1 &&
        selectedChannels[0].channelId === channelId
    );
};

// Returns false if 'isOpenPriceFeatureAvailable' feature flag is disabled
export const isViolatingOpenPriceSettings = (
    isOpenPriceFeatureAvailable: boolean,
    data: IProductAvailabilityVariantList,
    variant: IProductAvailabilityVariant,
    channelId: string,
): boolean => {
    let isViolated = false;
    if (isOpenPriceFeatureAvailable) {
        const isVariantOpenPriced = !variant.price;
        const isOpenPriceAllowedForChannel = !!data.channels.find(
            (c) => c.id === channelId,
        )?.openPriceAllowed;
        isViolated = isVariantOpenPriced && !isOpenPriceAllowedForChannel;
    }
    return isViolated;
};

export const isAnyVariantSelected = (
    data: IProductAvailabilityVariantList,
    channel: IAvailabilityChannel,
): boolean => {
    return data.variants
        .map((variant) => variant.channels[channel.id])
        .some(Boolean);
};

export const isVariantDisabled = (
    isOpenPriceFeatureAvailable: boolean,
    data: IProductAvailabilityVariantList,
    variant: IProductAvailabilityVariant,
    channel: IAvailabilityChannel,
): boolean => {
    return (
        hasOnlyThisChannelSelected(variant, channel.id) ||
        isViolatingOpenPriceSettings(
            isOpenPriceFeatureAvailable,
            data,
            variant,
            channel.id,
        )
    );
};

export const areAllVariantFieldsDisabled = (
    isOpenPriceFeatureAvailable: boolean,
    data: IProductAvailabilityVariantList,
    channel: IAvailabilityChannel,
): boolean => {
    return data.variants.every((variant) =>
        isVariantDisabled(isOpenPriceFeatureAvailable, data, variant, channel),
    );
};

export const isAnyNotDisabledVariantSelected = (
    isOpenPriceFeatureAvailable: boolean,
    data: IProductAvailabilityVariantList,
    channel: IAvailabilityChannel,
    options: { selectionValue: boolean } = { selectionValue: true },
): boolean => {
    return data.variants
        .filter(
            (variant) =>
                !isVariantDisabled(
                    isOpenPriceFeatureAvailable,
                    data,
                    variant,
                    channel,
                ),
        )
        .map((variant) => variant.channels[channel.id] ?? false)
        .some((value) => value === options.selectionValue);
};
