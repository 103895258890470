import React from 'react';
import clsx from 'clsx';
import { Button, Tooltip } from 'spoton-lib';

import { ShippingMethodInfoItem } from 'features/shipping/components/ShippingMethodInfoItem/ShippingMethodInfoItem.component';

import { MoreActionsDropdown } from './MoreActionsDropdown';
import styles from './ShippingMethod.module.scss';

type IShippingMethodProps = {
    children: React.ReactNode;
    className?: string;
};

export function ShippingMethod({
    children,
    className,
}: IShippingMethodProps): JSX.Element {
    return <div className={clsx(styles.Wrapper, className)}>{children}</div>;
}

type IShippingSelectButtonProps = {
    onClick: () => void;
    className?: string;
    testId?: string;
    disabled?: boolean;
    tooltipTextWhenDisabled?: string;
    buttonText?: string;
};

function SelectButton({
    onClick,
    className,
    disabled = false,
    tooltipTextWhenDisabled,
    buttonText = 'Select',
    testId,
}: IShippingSelectButtonProps): JSX.Element {
    const shouldRenderTooltip = !!tooltipTextWhenDisabled && disabled;
    const buttonVariant = 'tertiary';

    return (
        <div className={clsx(styles.ButtonWrapper, className)}>
            {shouldRenderTooltip ? (
                <Tooltip variant="top" tooltipContent={tooltipTextWhenDisabled}>
                    {/* cannot use here button disabled property - tooltip does not work */}
                    <Button
                        className={clsx(styles.ButtonWrapper_button, {
                            [styles.ButtonWrapper_button___disabled]: disabled,
                        })}
                        variant={buttonVariant}
                        data-testid={`Tooltip-${testId}`}
                    >
                        {buttonText}
                    </Button>
                </Tooltip>
            ) : (
                <Button
                    className={styles.ButtonWrapper_button}
                    variant={buttonVariant}
                    onClick={onClick}
                    data-testid={testId}
                    disabled={disabled}
                >
                    {buttonText}
                </Button>
            )}
        </div>
    );
}

ShippingMethod.Info = ShippingMethodInfoItem;
ShippingMethod.SelectButton = SelectButton;
ShippingMethod.MoreActionsDropdown = MoreActionsDropdown;
