import {
    merchantLocationBulkEnableLoyalty,
    merchantLocationGetById,
    merchantLocationPatch,
} from 'features/settings/api/MerchantLocations.data';
import {
    IMerchantLocationPatchParams,
    IMerchantLocationPatchPayload,
    IMerchantLocationPatchResponse,
} from 'features/settings/api/MerchantLocations.types';
import { useMutation, useQuery } from 'features/common/serverStateHandler';
import { isFeatureEnabled } from 'features/common/utils';
import { IMerchantLocation } from 'features/settings/types';

import { useGetMerchantLocationId } from './merchantLocationId';

export const currentMerchantLocationKeys = {
    all: [{ scope: 'currentMerchantLocation' }] as const,
    location: (id?: string) =>
        [{ ...currentMerchantLocationKeys.all[0], id }] as const,
};

const updateMerchantLocation = (
    patch: IMerchantLocationPatchPayload,
    merchantLocation: Partial<IMerchantLocation>,
): Partial<IMerchantLocation> => ({ ...merchantLocation, ...patch });

export const useGetCurrentMerchantLocation = () => {
    const { merchantLocationId } = useGetMerchantLocationId();

    const { data, isLoading, isError, refetch, ...result } = useQuery(
        currentMerchantLocationKeys.location(merchantLocationId),
        () => merchantLocationGetById(merchantLocationId),
        {
            enabled: !!merchantLocationId,
            dataFunctionName: 'currentMerchantLocation',
        },
    );

    return {
        ...result,
        currentMerchantLocation: data,
        isCurrentMerchantLocationLoading: isLoading,
        isCurrentMerchantLocationError: isError,
        refetchCurrentMerchantLocation: refetch,
        timezone: data?.timezone || '',
        isAppointmentsIntegrated:
            (data?.isAppointments && isFeatureEnabled('appointments')) || false,
    };
};

export const useUpdateCurrentMerchantLocation = () => {
    const { getMerchantLocationIdLoaded } = useGetMerchantLocationId();
    const merchantLocationId = getMerchantLocationIdLoaded();

    const { mutate, isLoading, ...rest } = useMutation<
        IMerchantLocationPatchResponse,
        unknown,
        Omit<IMerchantLocationPatchParams, 'id'>,
        Partial<IMerchantLocation>
    >(
        (payload) =>
            merchantLocationPatch({ ...payload, id: merchantLocationId }),
        {
            onMutate: ({ variables, queryHandler }) => {
                const previousMerchantLocation =
                    queryHandler.getQueryData<IMerchantLocation>(
                        currentMerchantLocationKeys.location(
                            merchantLocationId,
                        ),
                    );

                const updatedMerchantLocation = updateMerchantLocation(
                    variables,
                    previousMerchantLocation || {},
                );
                queryHandler.setQueryData(
                    currentMerchantLocationKeys.location(merchantLocationId),
                    updatedMerchantLocation,
                );

                return previousMerchantLocation;
            },
            onError: ({ context, queryHandler }) => {
                if (context) {
                    queryHandler.setQueryData(
                        currentMerchantLocationKeys.location(
                            merchantLocationId,
                        ),
                        context,
                    );
                }
            },
            formatSuccessMessage: () => 'Settings saved',
            formatErrorMessage: () => 'Failed to update a setting',
        },
    );

    return {
        updateCurrentMerchantLocation: mutate,
        isUpdatingCurrentMerchantLocation: isLoading,
        ...rest,
    };
};

export const useBulkEnableLoyalty = () => {
    const { getMerchantLocationIdLoaded } = useGetMerchantLocationId();
    const merchantLocationId = getMerchantLocationIdLoaded();

    const { mutate, isLoading, ...rest } = useMutation(
        () => merchantLocationBulkEnableLoyalty({ id: merchantLocationId }),
        {
            formatSuccessMessage: () =>
                'Enabled loyalty for all existing products & services',
            formatErrorMessage: () =>
                'Failed to enable loyalty for all existing products & services',
        },
    );

    return {
        bulkEnableLoyalty: mutate,
        isBulkEnablingLoyalty: isLoading,
        ...rest,
    };
};
