import { useCallback, useEffect, useState } from 'react';

import { ImportModalView } from 'features/products/components/ImportModal/ImportModal.types';
import { IProductsImport } from 'features/products/types';
import { assertNever } from 'features/common';

import { useImport } from './useImport';

export const useImportModal = ({ onClose }: { onClose: () => void }) => {
    const {
        isLoading,
        hasFailedFetchingByImportId,

        importResult,
        scheduleImport,
        hasScheduledSuccessfully,

        importIdFromQuery,
        dropImportIdFromQuery,
    } = useImport();
    const hasImports = Boolean(importResult);
    const hasIdInQuery = Boolean(importIdFromQuery);
    const hasWarnings = Boolean(importResult?.warnings?.length);
    const importStatus = importResult?.status;
    const issuesToLineItems = (issues: IProductsImport['errors']) =>
        (issues || [])
            .map(({ row, errors }) => errors.map((m) => `In row ${row} ${m}`))
            .flat();

    useEffect(() => {
        if (hasFailedFetchingByImportId) {
            onClose();
        }
    }, [hasFailedFetchingByImportId]);

    const [view, setView] = useState<ImportModalView>(ImportModalView.loader);

    const onOpenPrevious = useCallback(() => {
        switch (importStatus) {
            case 'COMPLETED':
            case 'COMPLETED_WITH_WARNINGS':
                setView(ImportModalView.completed);
                break;
            case 'FAILED':
                setView(ImportModalView.failed);
                break;
            case 'IN_PROGRESS':
            case 'SCHEDULED':
            case undefined:
                break;
            default:
                assertNever(importStatus);
        }
    }, [importStatus]);

    const onOpenWarnings = useCallback(() => {
        return setView(ImportModalView.failed);
    }, []);

    const onBack = useCallback(() => {
        switch (importStatus) {
            case 'COMPLETED':
            case 'FAILED':
                setView(ImportModalView.form);
                return;
            case 'COMPLETED_WITH_WARNINGS':
                view === ImportModalView.failed
                    ? setView(ImportModalView.completed)
                    : setView(ImportModalView.form);
                return;
        }
    }, [importStatus, view]);

    useEffect(() => {
        return () => dropImportIdFromQuery();
    }, [dropImportIdFromQuery]);

    useEffect(() => {
        if (isLoading) {
            return setView(ImportModalView.loader);
        }
        if (importStatus === 'IN_PROGRESS' || importStatus === 'SCHEDULED') {
            if (hasScheduledSuccessfully) {
                return setView(ImportModalView.started);
            }
            return setView(ImportModalView.pending);
        }
        if (hasIdInQuery) {
            if (
                importStatus === 'COMPLETED' ||
                importStatus === 'COMPLETED_WITH_WARNINGS'
            ) {
                return setView(ImportModalView.completed);
            }
            if (importStatus === 'FAILED') {
                return setView(ImportModalView.failed);
            }
        }
        return setView(ImportModalView.form);
    }, [isLoading, importStatus, hasScheduledSuccessfully, hasIdInQuery]);

    return {
        view,

        onOpenPrevious: hasImports ? onOpenPrevious : undefined,
        onOpenWarnings: hasWarnings ? onOpenWarnings : undefined,
        onBack:
            !hasIdInQuery ||
            (view === ImportModalView.failed &&
                importStatus === 'COMPLETED_WITH_WARNINGS')
                ? onBack
                : undefined,
        onClose,

        scheduleImport,
        importInitiator: importResult?.user.email,
        importResults: importResult?.results,
        importErrors: issuesToLineItems(importResult?.errors),
        importWarnings: issuesToLineItems(importResult?.warnings),
    };
};
