import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    RichTextEditor,
    RichTextEditorContentState,
    RichTextEditorState,
    TagsInput,
    Text,
    convertToRaw,
} from 'spoton-lib';
// Somehow import grid components from spoton-lib breaks the build. Only here. Yes, everywhere else it's fine.
// eslint-disable-next-line no-restricted-imports
import { Col, Container, Row } from 'react-grid-system';

import { useFlags } from 'features/common/hooks';
import {
    Section,
    ToggleableSection,
    ImageUploader,
    PlaceholderWrapper,
    ValidateableNumberFormat,
    ValidateableInput,
    LoyaltySection,
    CategoryDropdown,
    VendorDropdown,
} from 'features/common/components';
import { ValidationMode } from 'features/common';
import { ProductOptions } from 'features/products/components';
import { hasNonWhitespaceText } from 'features/common/utils';

import { getGeneralSectionInlineValidators } from './GeneralSection.utils';
import { IPropTypes } from './GeneralSection.types';
import styles from './GeneralSection.module.scss';

export function GeneralSection(props: IPropTypes) {
    const {
        isLoading,
        isEditing,
        isLoyaltyEnabled,
        name,
        description,
        sku,
        upc,
        tags,
        category,
        vendor,
        images,
        productOptions,
        canEarnReward,
        canRedeemReward,
        hasVariants,
        errors,
        onNameChange,
        onDescriptionChange,
        onSkuChange,
        onUpcChange,
        onAddTag,
        onRemoveTag,
        onClearTags,
        onCategoryChange,
        onVendorChange,
        onOptionsChange,
        onToggleVariants,
        onImagesChange,
        onCanEarnRewardChange,
        onCanRedeemRewardChange,
    } = props;
    const { isProductVendorFieldAvailable, shouldUseLegacyUPCValidation } =
        useFlags();
    const initialValues = useRef({ sku, upc, name });
    const inlineValidators = useMemo(
        () =>
            getGeneralSectionInlineValidators(
                initialValues.current,
                shouldUseLegacyUPCValidation,
            ),
        [],
    );
    const [descriptionState, setDescriptionState] =
        useState<RichTextEditorState>(RichTextEditorState.createEmpty());

    useEffect(() => {
        const { contentBlocks, entityMap } = htmlToDraft(description);
        const contentState = RichTextEditorContentState.createFromBlockArray(
            contentBlocks,
            entityMap,
        );
        const editorState = RichTextEditorState.createWithContent(contentState);
        setDescriptionState(editorState);
        // Do not use 'description' as dependency, because we want to create draftjs only state once, on initial load.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDescriptionEditorChange = useCallback(
        (newState: RichTextEditorState) => {
            setDescriptionState(newState);

            const currentContent = newState.getCurrentContent();
            if (!hasNonWhitespaceText(currentContent.getPlainText())) {
                return onDescriptionChange('');
            }

            const rawContentState = convertToRaw(currentContent);
            const htmlMarkup = draftToHtml(rawContentState);
            onDescriptionChange(htmlMarkup);
        },
        [setDescriptionState, onDescriptionChange],
    );

    return (
        <Section
            title="General"
            data-testid="GeneralSection"
            description="Add the general information needed for the creation of your product"
            className={styles.GeneralSection}
        >
            <Container className={styles.Container} fluid>
                <Row>
                    <Col md={6}>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <ValidateableInput
                                value={name}
                                label="Product name"
                                validators={inlineValidators.name}
                                secondaryCondition={errors.name}
                                isValid={!errors.name}
                                required={true}
                                onChange={onNameChange}
                                variant="rounded"
                                className={styles.NameInput}
                                data-testid="productNameInput"
                                primaryCondition="Required"
                                placeholder="Type product name"
                                maxLength={255}
                                isMultilineSecondaryCondition
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <Text type="label">Description</Text>
                            <RichTextEditor
                                editorState={descriptionState}
                                onStateChange={handleDescriptionEditorChange}
                                placeholder="Description"
                                className={styles.Description}
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <CategoryDropdown
                                value={category}
                                onChange={onCategoryChange}
                                data-testid="productCategoryDropdown"
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <TagsInput
                                onTagAdd={onAddTag}
                                onTagRemove={onRemoveTag}
                                onClear={onClearTags}
                                tagList={tags}
                                className={styles.TagsInput}
                                label="Tags"
                                data-testid="productTagsInput"
                                badgeTestId="productTag"
                                placeholder="Add tags"
                                secondaryCondition="Separate them by comma"
                                clearable={false}
                                withTooltips
                            />
                        </PlaceholderWrapper>
                    </Col>
                    <Col md={6} className={styles.RightColumn}>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <ImageUploader
                                images={images}
                                onChange={onImagesChange}
                                className={styles.ImageUploader}
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            {!hasVariants && (
                                <div className={styles.ProductIds}>
                                    <ValidateableInput
                                        validators={inlineValidators.sku}
                                        key="SKU"
                                        value={sku}
                                        label="SKU"
                                        onChange={onSkuChange}
                                        className={styles.ProductIds_field}
                                        variant="rounded"
                                        data-testid="productSkuInput"
                                        secondaryCondition={errors.sku}
                                        isValid={!errors.sku}
                                        placeholder="000000000000"
                                    />
                                    {shouldUseLegacyUPCValidation ? (
                                        <ValidateableNumberFormat
                                            validationMode={
                                                ValidationMode.sequential
                                            }
                                            validators={inlineValidators.upc}
                                            key="UPC"
                                            value={upc}
                                            label="UPC"
                                            onChange={onUpcChange}
                                            className={styles.ProductIds_field}
                                            variant="rounded"
                                            data-testid="productUPCInput"
                                            secondaryCondition={errors.upc}
                                            isValid={!errors.upc}
                                            placeholder="000000000000"
                                            maxLength={12}
                                            allowLeadingZeros
                                        />
                                    ) : (
                                        <ValidateableInput
                                            validationMode={
                                                ValidationMode.sequential
                                            }
                                            validators={inlineValidators.upc}
                                            key="UPC"
                                            value={upc}
                                            label="UPC"
                                            onChange={onUpcChange}
                                            className={styles.ProductIds_field}
                                            variant="rounded"
                                            data-testid="productUPCInput"
                                            secondaryCondition={errors.upc}
                                            isValid={!errors.upc}
                                            placeholder="000000000000"
                                            maxLength={14}
                                        />
                                    )}
                                </div>
                            )}
                        </PlaceholderWrapper>
                        {isProductVendorFieldAvailable && (
                            <PlaceholderWrapper isLoading={isLoading}>
                                <VendorDropdown
                                    value={vendor}
                                    onChange={onVendorChange}
                                    testId="productVendorDropdown"
                                />
                            </PlaceholderWrapper>
                        )}
                    </Col>
                </Row>
                {isLoyaltyEnabled ? (
                    <Row>
                        <Col>
                            <LoyaltySection
                                isLoading={isLoading}
                                earnLabel="Customers earn rewards when purchasing this product"
                                redeemLabel="Reward redemption can be applied to the product"
                                canEarn={canEarnReward}
                                canRedeem={canRedeemReward}
                                onCanEarnChange={onCanEarnRewardChange}
                                onCanRedeemChange={onCanRedeemRewardChange}
                            />
                        </Col>
                    </Row>
                ) : null}
            </Container>
            <Text className={styles.Section_title} type="h5">
                Variants
            </Text>
            <ToggleableSection
                className={styles.Variants}
                isLoading={isLoading}
                isOpen={hasVariants}
                isToggleDisabled={isEditing}
                switchLabel={
                    <Text type="p">
                        This product has multiple attributes, e.g. different
                        sizes and colors
                    </Text>
                }
                onToggle={onToggleVariants}
            >
                <ProductOptions
                    initialValues={{
                        productOptions,
                    }}
                    errors={errors.productOptions}
                    isEditing={isEditing}
                    onValueChange={onOptionsChange}
                    isLoading={isLoading}
                />
            </ToggleableSection>
        </Section>
    );
}

export default GeneralSection;
