import React from 'react';

import { ReactComponent as FlatRatesIcon } from 'features/common/assets/flat_rates.svg';
import { ReactComponent as RateByWeightIcon } from 'features/common/assets/rate_by_weight.svg';
import { ReactComponent as FreeShippingIcon } from 'features/common/assets/free_shipping.svg';
import { ReactComponent as CalculatedRatesIcon } from 'features/common/assets/calculated_rates.svg';
import { ShippingMethodType } from 'features/shipping/types';

export const config: Record<
    ShippingMethodType,
    { icon: React.ReactNode; title: string; methodDescription: string }
> = {
    [ShippingMethodType.rateByWeights]: {
        icon: <RateByWeightIcon />,
        title: 'Rate by weight',
        methodDescription: 'Define shipping prices based on order weight.',
    },
    [ShippingMethodType.calculatedRate]: {
        icon: <CalculatedRatesIcon />,
        title: 'Carrier Calculated Rates',
        methodDescription:
            'Display discounted rates to your customers from top carriers based on location, product weight, and dimensions.',
    },
    [ShippingMethodType.freeShipping]: {
        icon: <FreeShippingIcon />,
        title: 'Free Shipping',
        methodDescription: 'Offer free shipping on all orders',
    },
    [ShippingMethodType.flatRateShippingCost]: {
        icon: <FlatRatesIcon />,
        title: 'Flat Rates',
        methodDescription:
            'Set uniform fees for multiple shipping speeds. Free shipping options available.',
    },
};
