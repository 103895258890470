import { CSSProperties } from 'react';
import { Dropdown, Icon, colors, IconButton } from 'spoton-lib';

import { IPropTypes, DropdownMenuOnSelect } from './DropdownMenu.types';
import styles from './DropdownMenu.module.scss';

const getMenuDropdownStyles = ({
    menuWidth,
    menuZIndex,
}: Pick<IPropTypes, 'menuWidth' | 'menuZIndex'>) => ({
    // Override react-select styles. If styles from argument are being omitted, then default react-select styles are not applied.
    control: () => ({
        display: 'inline-block',
        backgroundColor: 'transparent',
        height: '100%',
    }),
    container: (
        styles: CSSProperties,
        state: Record<string, Record<string, boolean>>,
    ) => ({
        color: state.selectProps.menuIsOpen ? colors.primary50 : colors.black,
        display: 'flex',
    }),
    option: (
        styles: CSSProperties,
        { isFocused }: Record<string, boolean>,
    ) => ({
        width: '200px',
        backgroundColor: isFocused ? colors.primary10 : null,
    }),
    menu: () => ({
        width: menuWidth,
    }),
    // If menu is being rendered in portal - align it to the right of wrapper (instead of left).
    menuPortal: (reactSelectStyles: CSSProperties) => ({
        ...reactSelectStyles,
        display: 'flex',
        flexDirection: 'row-reverse',
        zIndex: menuZIndex || reactSelectStyles.zIndex,
    }),
});

export function DropdownMenu<Value, Label>({
    options,
    onSelect,
    className,
    iconSize = 45,
    menuPortalTarget,
    menuPosition = 'fixed',
    withDividers = true,
    useButtonTrigger = false,
    value,
    triggerElement,
    menuWidth = '200px',
    menuZIndex,
}: IPropTypes<Value, Label>) {
    const handleChange: DropdownMenuOnSelect<Value> = (option) => {
        onSelect(option);
    };

    const triggerIcon = useButtonTrigger ? (
        <IconButton
            variant="secondary"
            name="MoreVerticalIcon"
            alt="Menu icon"
        />
    ) : (
        <div className={styles.Trigger}>
            <Icon
                className={styles.Trigger_icon}
                name="MoreVerticalIcon"
                alt="Options"
                size={iconSize}
            />
        </div>
    );

    const trigger = triggerElement || triggerIcon;

    return (
        <Dropdown
            options={options}
            onChange={handleChange}
            trigger={trigger}
            styles={getMenuDropdownStyles({ menuWidth, menuZIndex })}
            className={className}
            menuPortalTarget={menuPortalTarget}
            menuPosition={menuPosition}
            withDividers={withDividers}
            value={value}
        />
    );
}

export default DropdownMenu;
