import { useMemo } from 'react';
import { colors, Icon, IconButton, Label } from 'spoton-lib';

import { naturalSortByProperty } from 'features/common/utils';
import { LowStockValue } from 'features/common/components/FiltersSidebarLowStock/FiltersSidebarLowStock.types';

import { IBadge, IPropTypes } from './SelectedFiltersList.types';
import styles from './SelectedFiltersList.module.scss';

export function SelectedFiltersList(props: IPropTypes) {
    const {
        filtersState,
        onCategoryRemoveClick,
        onChannelRemoveClick,
        onTagRemoveClick,
        onLowStockRemoveClick,
    } = props;

    const handleCategoryRemoveClick = (idToRemove: string) => {
        onCategoryRemoveClick &&
            onCategoryRemoveClick(
                filtersState.categories.filter(({ id }) => id !== idToRemove),
            );
    };

    const handleChannelRemoveClick = (idToRemove: string) => {
        onChannelRemoveClick &&
            onChannelRemoveClick(
                filtersState.channels.filter(({ id }) => id !== idToRemove),
            );
    };

    const handleTagRemoveClick = (tag: string) => {
        if (onTagRemoveClick) {
            const newTags = filtersState.tags.filter(
                (newTag) => newTag !== tag,
            );

            onTagRemoveClick(newTags);
        }
    };

    const handleLowStockRemoveClick = () => onLowStockRemoveClick?.();

    const getLowStockBadgeText = () =>
        `${
            filtersState.lowStock === LowStockValue.true ? 'Only' : 'Exclude'
        } low stock items`;

    const allFilters = useMemo(
        () =>
            naturalSortByProperty(
                [
                    ...filtersState.channels.map((channel) => ({
                        id: `channel-${channel.id}`,
                        text: channel.label,
                        iconName: 'ShareAllIcon',
                        onClick: () => handleChannelRemoveClick(channel.id),
                        testId: 'channelFilterBadge',
                        removeIconTestId: `RemoveChannelFilterButton-${channel.id}`,
                    })),
                    ...filtersState.categories.map((category) => ({
                        id: `category-${category.id}`,
                        text: category.label,
                        iconName: 'CategoryIcon',
                        onClick: () => handleCategoryRemoveClick(category.id),
                        testId: 'categoryFilterBadge',
                        removeIconTestId: `RemoveCategoryFilterButton-${category.id}`,
                    })),
                    ...filtersState.tags.map((tag) => ({
                        id: `tag-${tag}`,
                        text: tag,
                        iconName: 'DealIcon',
                        onClick: () => handleTagRemoveClick(tag),
                        testId: 'tagFilterBadge',
                        removeIconTestId: `RemoveTagFilterButton-${tag}`,
                    })),
                    ...(filtersState.lowStock !== null
                        ? [
                              {
                                  id: `lowStock-badge-${filtersState.lowStock}`,
                                  text: getLowStockBadgeText(),
                                  onClick: handleLowStockRemoveClick,
                                  testId: 'lowStockFilterBadge',
                                  removeIconTestId: `RemoveLowStockFilterButton-${filtersState.lowStock}`,
                              },
                          ]
                        : []),
                ],
                'text',
            ),
        [filtersState],
    );

    const renderBadge = ({
        id,
        text,
        iconName,
        onClick,
        testId,
        removeIconTestId,
    }: IBadge) => {
        return (
            <Label
                key={id}
                className={styles.SelectedFiltersList_badge}
                variant="informative"
                textColor={colors.primary50}
                backgroundColor={colors.primary10}
                text={text}
                icon={
                    iconName ? (
                        <Icon
                            name={iconName}
                            alt="Channel icon"
                            size={22}
                            color={colors.primary50}
                        />
                    ) : undefined
                }
                data-testid={testId}
                suffixIcon={
                    <IconButton
                        name="ClearIcon"
                        buttonProps={{
                            'data-testid': removeIconTestId,
                        }}
                        alt="Remove icon"
                        size={25}
                        onClick={onClick}
                        color={colors.primary50}
                        disableBorder
                    />
                }
            />
        );
    };

    return (
        <div
            className={styles.SelectedFiltersList}
            data-testid="SelectedFiltersList"
        >
            {allFilters.map((filter) => {
                return renderBadge(filter);
            })}
        </div>
    );
}

export default SelectedFiltersList;
