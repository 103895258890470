import * as yup from 'yup';

import { IProductOptionsFormType } from 'features/products/components/RHFProductOptions/ProductOptions.types';

export const validationSchema: yup.SchemaOf<IProductOptionsFormType> = yup
    .object()
    .shape({
        productOptions: yup.array().of(
            yup.object().shape({
                attribute: yup
                    .string()
                    .nullable()
                    .required('Attribute can not be empty!'),
                isEditing: yup
                    .boolean()
                    .oneOf(
                        [false],
                        'You must exit edit product option mode to add a new variant',
                    ),
                values: yup
                    .array()
                    .of(
                        yup.object().shape({
                            value: yup
                                .string()
                                .trim()
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                .attributeDefined()
                                .required('Attribute option can not be empty!'),
                            key: yup.string(),
                            id: yup.string(),
                            displayOrder: yup.number(),
                        }),
                    )
                    .required(),
                name: yup.string().trim().required(),
                key: yup.string().nullable(),
                displayOrder: yup.number(),
            }),
        ),
    });
