import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, LoadingOverlay, SortDirectionType } from 'spoton-lib';

import { Tag } from 'features/common/components/ProductKeywords/ProductKeywords.types';
import { IProductBulkPayload } from 'features/products/api/Products.types';
import {
    IFiltersSidebarFilterState,
    IFiltersSidebarContentType,
} from 'features/common/types';
import { getAssetUrl, useUserQueryHandler } from 'features/common/utils';
import {
    useFlags,
    useDebounce,
    usePageVisited,
    useChannels,
    useMediaQuery,
    useURLParams,
    useFilters,
} from 'features/common/hooks';
import {
    SearchBar,
    TablePlaceholder,
    TableNetworkError,
    FiltersSidebar,
    ProductKeywordsModal,
    TableNoResults,
    DeletePrompt,
    SelectedFiltersList,
    OnboardingSection,
    NetworkError,
} from 'features/common/components';
import {
    ProductPageHeader,
    ProductAvailabilityModal,
    ProductList,
    MoreMenu,
    ImportModal,
} from 'features/products/components';
import {
    IProductAvailabilityVariantList,
    IBulkAction,
} from 'features/products/types';
import {
    useGetPaginatedProductLinks,
    useProductBulkUpdate,
} from 'features/products/services/products';
import onboardingImg from 'features/common/assets/onboarding_no_products.svg';
import { productsPaths } from 'features/products/routes/products.paths';
import { useImportOpener } from 'features/products/components/ImportModal/hooks';
import { useGetCurrentUser } from 'features/common/services';
import { SelectedProductsHeader } from 'features/products/components/SelectedProductsHeader';
import { BarcodePrintingModal } from 'features/products/components/BarcodePrintingModal';
import { useAllCategories } from 'features/categories/services';
import { Export } from 'features/products/components/Export';
import { useSelectableColumns } from 'features/common/hooks/useSelectableColumns';
import { IFilterPage } from 'features/common/components/FiltersSidebar/FiltersSidebar.types';
import { LowStockBanner } from 'features/products/components/LowStockBanner/LowStockBanner.component';

import {
    ISearchContextOptions,
    IProductsColumns,
} from './MainProductsPage.types';
import styles from './MainProductsPage.module.scss';
import {
    mapProductListToBulkActionAvailabilityRequest,
    tagsIntersection,
    mapSearchContextToProductsGetParams,
    mapSelectedProductIdsToTags,
    availableColumnsProducts,
    productsColumnsMap,
    ProductsAvailableColumns,
    mapSortToSortString,
} from './MainProductsPage.utils';

const searchContextOptionsWithoutAnything = [
    ISearchContextOptions.productName,
    ISearchContextOptions.category,
    ISearchContextOptions.sku,
];
const searchContextOptionsWithAnything = [
    ISearchContextOptions.anything,
    ...searchContextOptionsWithoutAnything,
];

const pageSize = 10;

export function MainProductsPage() {
    const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
    const [shouldShowFilters, setShouldShowFilters] = useState(false);
    const [shouldShowAvailabilityModal, setShouldShowAvailabilityModal] =
        useState(false);
    const [shouldShowTagsModal, setShouldShowTagsModal] = useState(false);
    const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
    const [shouldShowImportModal, setShouldShowImportModal] = useState(false);
    const [shouldShowBarcodePrintingModal, setShouldShowBarcodePrintingModal] =
        useState(false);
    const { isMobile } = useMediaQuery();
    const [shouldShowExportModal, setShouldShowExportModal] = useState(false);

    // Local expanded variants product variable is needed, because callback from Table component
    // is called twice per run loop, and selecting this value from the store is being done
    // on the next run loop
    const [localExpandedVariantsProductId, setLocalExpandedVariantsProductId] =
        useState<string | null>(null);

    const { getCurrentUserLoaded } = useGetCurrentUser();
    const { id: currentUserID } = getCurrentUserLoaded();

    const { usedColumns, renderSelectionMenu } =
        useSelectableColumns<IProductsColumns>({
            availableColumns: availableColumnsProducts,
            columnsMap: productsColumnsMap,
            isMobile,
            id: currentUserID,
            namespace: 'products',
        });

    const {
        channels,
        productActiveChannels,
        isError: isErrorChannels,
        refetch: refetchChannels,
    } = useChannels();
    const {
        categories,
        refetch: refetchCategories,
        isError: isErrorCategories,
    } = useAllCategories();
    const [urlParams, setUrlParams, isInitialized] = useURLParams();

    const pickId = ({ id }: { id: string }): string => id;

    const { filtersState, filtersGetParams } = useFilters(
        urlParams,
        channels,
        categories,
    );

    const {
        isProductImportEnabled,
        isBarcodePrintingEnabled,
        shouldShowSearchByAnything,
        isLowStockAlertFeatureEnabled,
    } = useFlags();
    const isPageVisited = usePageVisited('products', currentUserID);

    const searchContextOptions = shouldShowSearchByAnything
        ? searchContextOptionsWithAnything
        : searchContextOptionsWithoutAnything;

    const searchGetParams = useMemo(
        () =>
            mapSearchContextToProductsGetParams(
                urlParams.searchContext as ISearchContextOptions,
                urlParams.q,
            ),
        [urlParams.searchContext, urlParams.q],
    );

    const sortBy: ProductsAvailableColumns =
        urlParams.sortBy && urlParams.sortBy in ProductsAvailableColumns
            ? (urlParams.sortBy as ProductsAvailableColumns)
            : ProductsAvailableColumns.modified;

    const sortDirection: SortDirectionType =
        urlParams.sortDirection === 'ASC' ? 'ASC' : 'DESC';

    const sortParam = mapSortToSortString(sortBy, sortDirection);

    const onSortChange = ({
        sortBy: newSortBy,
        sortDirection: newSortDirection,
    }: {
        sortBy: string;
        sortDirection: SortDirectionType;
    }) => {
        if (
            newSortBy in ProductsAvailableColumns &&
            productsColumnsMap[newSortBy as ProductsAvailableColumns]
                .sortOptions
        ) {
            const shouldReverseSortDirection =
                productsColumnsMap[newSortBy as ProductsAvailableColumns]
                    .sortOptions?.shouldReverseSortDirection &&
                newSortBy !== sortBy;

            setUrlParams({
                ...urlParams,
                page: undefined,
                sortBy: newSortBy,
                sortDirection: shouldReverseSortDirection
                    ? newSortDirection === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    : newSortDirection,
            });
        }
    };

    const {
        products,
        productsNumberOfPages,
        isLoadingProducts,
        isFetchingProducts,
        hasFetchProductsError,
        refetchProductLinks,
        currentPage,
        setCurrentPage,
    } = useGetPaginatedProductLinks({
        params: {
            pageSize,
            page: urlParams.page || 1,
            ordering: sortParam,
            ...searchGetParams,
            ...filtersGetParams,
        },
        isEnabled: isInitialized,
    });

    const areInitialProductsLoaded = !isLoadingProducts && isInitialized;
    const tagsForSelectedProducts = mapSelectedProductIdsToTags(
        selectedProductIds,
        products,
    );

    const onUpdateAvailabilitySuccess = () => {
        setShouldShowAvailabilityModal(false);
        setSelectedProductIds([]);
    };

    const onUpdateTagsSuccess = () => {
        setSelectedProductIds([]);
        setShouldShowTagsModal(false);
    };

    const {
        mutate: updateProductsAvailabilityBulk,
        status: updateProductsAvailabilityStatus,
    } = useProductBulkUpdate(
        {
            isSuccessToast: false,
            isErrorToast: false,
        },
        onUpdateAvailabilitySuccess,
    );
    useUserQueryHandler({
        status: updateProductsAvailabilityStatus,
        error: 'Error while updating products availability.',
        successMessage: 'Successfully updated products availability.',
    });

    const { mutate: addTagsBulk, isLoading: isAddingTagsBulk } =
        useProductBulkUpdate(
            {
                formatSuccessMessage: () => 'Tags have been added.',
                formatErrorMessage: () => 'Error while adding tags.',
            },
            onUpdateTagsSuccess,
        );

    const { mutate: removeTagsBulk, isLoading: isRemovingTagsBulk } =
        useProductBulkUpdate(
            {
                formatSuccessMessage: () => 'Tags have been removed.',
                formatErrorMessage: () => 'Error while removing tags.',
            },
            onUpdateTagsSuccess,
        );

    const onRemoveProductsSuccess = () => {
        setSelectedProductIds([]);
        setCurrentPage(1);
        setUrlParams({
            ...urlParams,
            page: undefined,
        });
    };

    const { mutate: removeProductsBulk, isLoading: isRemovingProductsBulk } =
        useProductBulkUpdate(
            {
                formatSuccessMessage: () => 'Successfully removed products.',
                formatErrorMessage: () => 'Error while removing products.',
            },
            onRemoveProductsSuccess,
        );

    useImportOpener(setShouldShowImportModal);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleNewProductClick = useCallback(
        () => navigate(productsPaths.new, { state: { from: pathname } }),
        [navigate],
    );

    const hasFiltersSelected =
        urlParams.categories.length !== 0 ||
        urlParams.channels.length !== 0 ||
        urlParams.tags.length !== 0 ||
        urlParams.lowStock !== undefined;
    const isGetProductsLoaded = areInitialProductsLoaded && !isFetchingProducts;
    const hasNoSearchResults =
        (urlParams.q !== undefined || hasFiltersSelected) &&
        isGetProductsLoaded &&
        products.length === 0;
    const hasNoProducts =
        urlParams.q === undefined &&
        !hasFiltersSelected &&
        isGetProductsLoaded &&
        products.length === 0;
    const isSavingTags = isAddingTagsBulk || isRemovingTagsBulk;

    const selectedProductsLength = selectedProductIds.length;

    const isProductSelected = selectedProductsLength > 0;

    //--- Helpers
    const calculateInitialTagsForSelectedProducts = (): string[] => {
        return tagsIntersection(tagsForSelectedProducts);
    };

    useEffect(() => {
        if (!isAddingTagsBulk && !isRemovingTagsBulk) {
            setShouldShowTagsModal(false);
        }
    }, [isAddingTagsBulk, isRemovingTagsBulk]);

    // Clear product selection when currentPage changes, and on component unmount.
    useEffect(() => {
        return () => {
            setSelectedProductIds([]);
        };
    }, [currentPage]);

    //--- Handlers
    const handleAvailabilitySave = async (
        data: IProductAvailabilityVariantList,
    ) => {
        const makeAvailableRequest =
            mapProductListToBulkActionAvailabilityRequest(data, true);
        const makeUnavailableRequest =
            mapProductListToBulkActionAvailabilityRequest(data, false);

        updateProductsAvailabilityBulk(makeAvailableRequest);
        updateProductsAvailabilityBulk(makeUnavailableRequest);
    };

    const handleFiltersApplyClick = (
        newFiltersState: IFiltersSidebarFilterState,
    ) => {
        setCurrentPage(1);
        setUrlParams({
            ...urlParams,
            tags: newFiltersState.tags,
            channels: newFiltersState.channels.map(pickId),
            categories: newFiltersState.categories.map(pickId),
            lowStock: newFiltersState.lowStock
                ? String(newFiltersState.lowStock)
                : undefined,
            page: undefined,
        });
        setShouldShowFilters(false);
    };

    const handleProductsSelectionChange = (productIds: string[]) => {
        setSelectedProductIds(productIds);
    };

    const debouncedSearchInputChange = useDebounce(
        (searchInputValue: string) => {
            setCurrentPage(1);
            setUrlParams({
                ...urlParams,
                page: undefined,
                q: searchInputValue?.trim() || undefined,
            });
        },
        300,
    );

    const handleSearchInputValueChange = (phrase: string) => {
        debouncedSearchInputChange(phrase);
    };

    useEffect(() => {
        setCurrentPage(urlParams.page || 1);
    }, [urlParams.page]);

    const handleExpandVariants = useDebounce((index: number) => {
        const productId = products[index].id;

        if (productId && productId !== localExpandedVariantsProductId) {
            setLocalExpandedVariantsProductId(productId);
        }
    }, 100);

    const handleUpdateTags = (tagsToBeAdded: Tag[], tagsToBeRemoved: Tag[]) => {
        const addTagsPayload: IProductBulkPayload = {
            action: IBulkAction.addTags,
            parameters: [
                {
                    filters: {
                        id: selectedProductIds,
                        all: false,
                    },
                    value: tagsToBeAdded,
                },
            ],
        };

        // Remove tags
        const removeTagsPayload: IProductBulkPayload = {
            action: IBulkAction.removeTags,
            parameters: [
                {
                    filters: {
                        id: selectedProductIds,
                        all: false,
                    },
                    value: tagsToBeRemoved,
                },
            ],
        };

        // If there is some changes, send requests, otherwise close modal
        if (tagsToBeAdded.length > 0 || tagsToBeRemoved.length > 0) {
            if (tagsToBeAdded.length) {
                addTagsBulk(addTagsPayload);
            }

            if (tagsToBeRemoved.length) {
                removeTagsBulk(removeTagsPayload);
            }
        } else {
            setShouldShowTagsModal(false);
        }
    };

    const handleErrorRefreshClick = () => {
        refetchProductLinks();
    };

    //--- Renderers
    const renderProductList = () => {
        if (hasFetchProductsError) {
            return (
                <TableNetworkError onRefreshClick={handleErrorRefreshClick} />
            );
        }

        if (hasNoSearchResults) {
            return <TableNoResults />;
        }

        if (
            areInitialProductsLoaded &&
            products.length === 0 &&
            !urlParams.q?.length
        ) {
            return (
                <OnboardingSection
                    isVisited={isPageVisited}
                    videoUrl={getAssetUrl('Add+a+Product.mp4')}
                    onClick={handleNewProductClick}
                    imageUrl={onboardingImg}
                    importCallback={() => setShouldShowImportModal(true)}
                    description="Setup your product catalog to start selling in store and online"
                    buttonLabel="New Product"
                    modalTitle="Create your Product Catalog"
                    videoDescription="Learn the ins and outs of creating your product catalog.  Plus how you can use your product catalog to sell on your retail point-of-sale device or even online!"
                />
            );
        }

        return (
            <ProductList
                products={products}
                channels={productActiveChannels}
                currentPage={currentPage}
                numberOfPages={productsNumberOfPages}
                onPageChange={(page: number) => {
                    setUrlParams({
                        ...urlParams,
                        page: page > 1 ? page : undefined,
                    });
                }}
                onProductsSelectionChange={handleProductsSelectionChange}
                onExpandVariants={handleExpandVariants}
                onSortChange={onSortChange}
                sortBy={sortBy}
                sortDirection={sortDirection}
                usedColumns={usedColumns}
                renderSelectionMenu={renderSelectionMenu}
            />
        );
    };

    const renderSearchControls = (
        <div className={styles.SearchControls}>
            <div className={styles.SearchControls_searchBar}>
                <SearchBar
                    selectedOption={urlParams.searchContext}
                    contextOptions={searchContextOptions}
                    defaultContextOption={ISearchContextOptions.productName}
                    onContextOptionSelected={(searchContext) => {
                        setCurrentPage(1);
                        setUrlParams({
                            ...urlParams,
                            page: undefined,
                            searchContext: searchContext || undefined,
                        });
                    }}
                    initialState={urlParams.q}
                    onInputChange={handleSearchInputValueChange}
                    placeholder="Search by product name, collection, category, SKU"
                />
            </div>
            <Button
                variant="secondary"
                className={styles.SearchControls_showFiltersButton}
                onClick={() => setShouldShowFilters(true)}
                data-testid="OpenFiltersSidebarButton"
                disabled={isFetchingProducts}
            >
                Filters
            </Button>
            <div className={styles.SearchControls_dropdownWrapper}>
                <MoreMenu
                    onImport={() => setShouldShowImportModal(true)}
                    onExport={() => setShouldShowExportModal(true)}
                />
            </div>
        </div>
    );

    const renderProductControls = (
        <SelectedProductsHeader
            onUpdateAvailability={() => setShouldShowAvailabilityModal(true)}
            onUpdateTags={() => setShouldShowTagsModal(true)}
            onPrintBarcodes={() => setShouldShowBarcodePrintingModal(true)}
            onDelete={() => setShouldShowDeleteModal(true)}
        />
    );

    const renderSelectedFiltersList = () => (
        <SelectedFiltersList
            filtersState={filtersState}
            onCategoryRemoveClick={(categories) => {
                setCurrentPage(1);
                setUrlParams({
                    ...urlParams,
                    categories: categories.map(pickId),
                    page: undefined,
                });
            }}
            onChannelRemoveClick={(channels) => {
                setCurrentPage(1);
                setUrlParams({
                    ...urlParams,
                    channels: channels.map(pickId),
                    page: undefined,
                });
            }}
            onTagRemoveClick={(tags) => {
                setCurrentPage(1);
                setUrlParams({
                    ...urlParams,
                    tags,
                    page: undefined,
                });
            }}
            onLowStockRemoveClick={() => {
                setCurrentPage(1);
                setUrlParams({
                    ...urlParams,
                    lowStock: undefined,
                    page: undefined,
                });
            }}
        />
    );

    const renderOverlay = () => {
        if (isSavingTags || isRemovingProductsBulk) {
            return <LoadingOverlay />;
        }
    };

    const renderTagsModal = () => {
        if (!shouldShowTagsModal) {
            return;
        }
        return (
            <ProductKeywordsModal
                initialTags={calculateInitialTagsForSelectedProducts()}
                isOpen={shouldShowTagsModal && !isSavingTags}
                productIds={selectedProductIds}
                onApply={handleUpdateTags}
                onCancel={() => setShouldShowTagsModal(false)}
            />
        );
    };

    const renderAvailabilityModal = () => {
        if (!shouldShowAvailabilityModal) {
            return;
        }
        return (
            <ProductAvailabilityModal
                isOpen={shouldShowAvailabilityModal}
                onCloseClick={() => setShouldShowAvailabilityModal(false)}
                channels={productActiveChannels}
                productIds={selectedProductIds}
                onSaveClick={handleAvailabilitySave}
            />
        );
    };

    const handleDelete = () => {
        const ids = [...selectedProductIds];

        removeProductsBulk({
            action: IBulkAction.delete,
            parameters: [{ filters: { id: ids, all: false } }],
        });
        setShouldShowDeleteModal(false);
    };

    const handleDeleteCancel = () => {
        setShouldShowDeleteModal(false);
    };

    const renderDeleteModal = () => {
        if (!shouldShowDeleteModal) {
            return;
        }
        const count = selectedProductIds.length;
        return (
            <DeletePrompt
                isOpen={shouldShowDeleteModal && !isRemovingProductsBulk}
                title={
                    count === 1
                        ? `Are you sure you want to delete the product?`
                        : `Are you sure you want to delete ${count} products?`
                }
                message={
                    count === 1
                        ? `If you delete this product, you won’t be able to access it again.`
                        : `If you delete these products, you won’t be able to access them again.`
                }
                onCancel={handleDeleteCancel}
                onConfirm={handleDelete}
            />
        );
    };

    if (isErrorChannels) {
        return (
            <NetworkError
                offlineErrorMessage="Failed to load channels"
                onRefreshClick={refetchChannels}
                data-testid="MainProductsPageChannelErrorButton"
            />
        );
    }

    if (isErrorCategories) {
        return (
            <NetworkError
                offlineErrorMessage="Failed to load categories"
                onRefreshClick={refetchCategories}
                data-testid="MainProductsPageCategoriesErrorButton"
            />
        );
    }

    const lowStockFilter: IFilterPage[] = isLowStockAlertFeatureEnabled
        ? ['lowStock']
        : [];

    return (
        <div className={styles.MainProductsPage}>
            {!hasFetchProductsError && (
                <FiltersSidebar
                    filtersState={filtersState}
                    filtersToShow={[
                        'categories',
                        'channels',
                        'tags',
                        ...lowStockFilter,
                    ]}
                    isSidebarOpen={shouldShowFilters}
                    onApply={handleFiltersApplyClick}
                    onClose={() => setShouldShowFilters(false)}
                    contentType={IFiltersSidebarContentType.products}
                />
            )}
            {isLowStockAlertFeatureEnabled && <LowStockBanner />}
            <Export
                shouldShowExportModal={shouldShowExportModal}
                onShouldShowExportModal={setShouldShowExportModal}
            />
            <ProductPageHeader
                onAddNewProductClick={handleNewProductClick}
                shouldHideActions={hasNoProducts}
            >
                <div className={styles.Controls}>
                    {isProductSelected
                        ? renderProductControls
                        : renderSearchControls}
                </div>
                {hasFiltersSelected ? renderSelectedFiltersList() : null}
            </ProductPageHeader>
            <TablePlaceholder
                isLoading={isFetchingProducts}
                hasHeader={true}
                lineHeight={90}
            >
                <div className={styles.ProductList}>{renderProductList()}</div>
            </TablePlaceholder>
            {renderAvailabilityModal()}
            {renderTagsModal()}
            {renderDeleteModal()}
            {renderOverlay()}
            {isProductImportEnabled && shouldShowImportModal && (
                <ImportModal onClose={() => setShouldShowImportModal(false)} />
            )}
            {isBarcodePrintingEnabled && shouldShowBarcodePrintingModal && (
                <BarcodePrintingModal
                    onClose={() => setShouldShowBarcodePrintingModal(false)}
                    productIds={selectedProductIds}
                />
            )}
        </div>
    );
}

export default MainProductsPage;
