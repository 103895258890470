import { Text as BaseText, Button, Modal } from 'spoton-lib';
import clsx from 'clsx';

import styles from './InfoModal.module.scss';
import { IModalProps, IBaseProps, IButtonProps } from './InfoModal.types';

export function InfoModal({
    isOpen,
    children,
    className,
    onClose,
}: IModalProps) {
    return (
        <Modal
            className={clsx(styles.Modal, className)}
            isOpen={isOpen}
            onRequestClose={onClose}
            // This fixes a console error "react-modal: App element is not defined"
            appElement={document.getElementById('root') ?? undefined}
        >
            {children}
        </Modal>
    );
}

function Heading({ children, className }: IBaseProps) {
    return (
        <BaseText as="h5" className={clsx(styles.Heading, className)}>
            {children}
        </BaseText>
    );
}

function Text({ children, className }: IBaseProps) {
    return (
        <BaseText className={clsx(styles.Text, className)} as="p">
            {children}
        </BaseText>
    );
}

function ButtonWrapper({ children, className }: IBaseProps) {
    return (
        <div className={clsx(styles.ButtonWrapper, className)}>{children}</div>
    );
}

function TertiaryButton({ children, className, onClick }: IButtonProps) {
    return (
        <Button
            onClick={onClick}
            className={clsx(styles.TertiaryButton, className)}
            variant="tertiary"
        >
            {children}
        </Button>
    );
}

function PrimaryButton({ children, className, onClick }: IButtonProps) {
    return (
        <Button onClick={onClick} className={className} variant="primary">
            {children}
        </Button>
    );
}

InfoModal.Heading = Heading;
InfoModal.Text = Text;
InfoModal.ButtonWrapper = ButtonWrapper;
InfoModal.TertiaryButton = TertiaryButton;
InfoModal.PrimaryButton = PrimaryButton;
