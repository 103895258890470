import {
    useQuery,
    useMutation,
    useInfiniteQuery,
} from 'features/common/serverStateHandler';
import { QueryKeyT } from 'features/common/serverStateHandler/types';
import {
    IDropdownItemsGetParams,
    IDropdownItemsGetResponse,
} from 'features/common/api/QueryDropdown.types';
import {
    IQueryCreateItem,
    IQueryFetchItem,
    IQueryFetchItems,
    IQueryKeys,
} from 'features/common/components/QueryDropdown/QueryDropdown.types';

const dropdownItemsFetch = (
    fetchItems: IQueryFetchItems,
    params: IDropdownItemsGetParams,
    page = 1,
) =>
    fetchItems({
        page,
        pageSize: params.pageSize || 10,
        ordering: params.ordering || 'name',
        name: params.name || undefined,
        phrase: params.phrase,
    });

export const useGetDropdownItem = (
    fetchItem: IQueryFetchItem,
    keys: { item: (id: string) => QueryKeyT },
    id: string,
) =>
    useQuery(keys.item(id), () => fetchItem(id), {
        enabled: !!id,
    });

export const useInfiniteDropdownItems = (
    fetchItems: IQueryFetchItems,
    keys: { infinity: (phrase?: string) => QueryKeyT },
    params: IDropdownItemsGetParams,
) =>
    useInfiniteQuery<
        QueryKeyT,
        IDropdownItemsGetResponse,
        unknown,
        IDropdownItemsGetResponse,
        number
    >(
        keys.infinity(params.phrase),
        ({ pageParam }) => dropdownItemsFetch(fetchItems, params, pageParam),
        {
            keepPreviousData: true,
        },
    );

export const useCreateDropdownItem = (
    createItem: IQueryCreateItem,
    keys: IQueryKeys,
    successMessageFactory = (name: string) =>
        `Item "${name}" created successfully`,
    errorMessageFactory = (name: string) =>
        `Error while creating "${name}" item. Try again.`,
) => {
    return useMutation(createItem, {
        onSuccess: ({ queryHandler }) => {
            queryHandler.invalidateQueries(keys.infinityList());
            queryHandler.invalidateQueries(keys.list({}));
            return queryHandler.refetchQueries(keys.list({ phrase: '' }));
        },
        formatSuccessMessage: ({ name }) => successMessageFactory(name),
        formatErrorMessage: (_, { name }) => errorMessageFactory(name),
    });
};
