import { generatePathFromRoot } from 'features/common/utils/routing.utils';

export const productsMountPath = '/products';

// paths used in routing definition
export const productsLocalPaths = {
    root: '/',
    new: '/new',
    edit: '/:id',
} as const;

// paths used for navigation
export const productsPaths = {
    root: generatePathFromRoot(productsMountPath),
    new: generatePathFromRoot(productsMountPath, productsLocalPaths.new),
    edit: (id: string): string => generatePathFromRoot(productsMountPath, id),
} as const;

{
    /* @TODO: [Product Form Formik] Remove additional routing within last task */
}
export const newProductsMountPath = '/new-products';

// paths used in routing definition
export const newProductsLocalPaths = {
    root: '/',
    new: '/new',
    edit: '/:id',
} as const;

// paths used for navigation
export const newProductsPaths = {
    root: generatePathFromRoot(newProductsMountPath),
    new: generatePathFromRoot(newProductsMountPath, newProductsLocalPaths.new),
    edit: (id: string): string =>
        generatePathFromRoot(newProductsMountPath, id),
} as const;
