import { NumberFormatValues } from 'spoton-lib';

import { IPartialProductData } from 'features/products/types';

import { IPropFields } from './ShippingSection.types';
import {
    WEIGHT_MAX_INTEGRAL_PART,
    WEIGHT_MAX_FRACTIONAL_PART,
} from './ShippingSection.constants';

export const getInitialShippingData = (
    productData?: IPartialProductData,
): IPropFields => ({
    value: '',
    unit: 'lb',
    ...(productData ? productData.shipping : {}),
});

export const shippingSectionErrorMessages = {
    value: {
        isPositive: 'Weight must be greater than zero',
    },
};

export const isWeightFormatCorrect = (values: NumberFormatValues) => {
    const [integralPart, decimalPart] = values.value.split('.');
    return (
        (integralPart?.length ?? 0) <= WEIGHT_MAX_INTEGRAL_PART &&
        (decimalPart?.length ?? 0) <= WEIGHT_MAX_FRACTIONAL_PART
    );
};
