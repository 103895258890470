import { Modal, IconButton, Text, colors, Button, showToast } from 'spoton-lib';
import { Form, Formik } from 'formik';
import clsx from 'clsx';

import FedEx from 'features/common/assets/fedex-express.svg';
import { addCarrierAccount } from 'features/shipping/api/Shipping.data';
import {
    NumberFormatField,
    DropdownField,
    PhoneNumberField,
} from 'features/common/components';
import { formatError } from 'features/common/utils';
import { TextField } from 'features/common/components/TextField';

import { IFedExConnectFormValues, IProps } from './ConnectFedExFormModal.types';
import {
    initialValues,
    parseFedExConnectToAPIFormat,
    validationSchema,
} from './ConnectFedExFormModal.utils';
import styles from './ConnectFedExFormModal.module.scss';
import { supportedCountries } from './ConnectFedExFormModal.constants';

export function ConnectFedExFormModal({ isOpen, onClose, onSuccess }: IProps) {
    const handleSubmit = async (values: IFedExConnectFormValues) => {
        try {
            await addCarrierAccount(parseFedExConnectToAPIFormat(values));

            onSuccess();
        } catch (err) {
            const errorContent = formatError(err) || 'Please try again later';

            showToast({
                variant: 'danger',
                title: 'Error',
                content: errorContent,
                autoClose: 4000,
            });
        }
    };

    return (
        <Modal
            className={styles.Modal}
            isOpen={isOpen}
            // This fixes a console error "react-modal: App element is not defined"
            appElement={document.getElementById('root') ?? undefined}
        >
            <div className={styles.ModalHeader}>
                <div className={styles.ModalHeader___title}>
                    <Text type="h4">Connect FedEx account</Text>
                    <img src={FedEx} className={styles.FedExLogo} />
                </div>
                <IconButton
                    className={styles.ModalHeader___closeBtn}
                    color={colors.black}
                    name="CloseIcon"
                    alt="Close"
                    variant="secondary"
                    onClick={onClose}
                />
            </div>
            <Formik<IFedExConnectFormValues>
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div
                            className={clsx(styles.ModalContent, {
                                [styles.Loading]: isSubmitting,
                            })}
                        >
                            <TextField
                                disabled={isSubmitting}
                                name="accountNickname"
                                placeholder="E.g. Test Account, US Domestic, CA Facility"
                                label="Account Nickname"
                            />
                            <NumberFormatField
                                disabled={isSubmitting}
                                name="accountId"
                                placeholder="Your 9-digit account number"
                                label="FedEx Account Number*"
                                shouldShowInstantError={false}
                                clearable
                                allowLeadingZeros
                                valueFormatter={({ value }) => value} // getting string
                                primaryCondition={
                                    (
                                        <a
                                            className={styles.WhereLink}
                                            href="https://support.goshippo.com/hc/en-us/articles/360029224112"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Where is this?
                                        </a>
                                    ) as any
                                }
                            />

                            <Text type="h5" className={styles.Section}>
                                Contact Information
                            </Text>
                            <TextField
                                disabled={isSubmitting}
                                name="firstName"
                                label="First Name*"
                            />
                            <TextField
                                disabled={isSubmitting}
                                name="lastName"
                                label="Last Name*"
                            />

                            <PhoneNumberField
                                name="phoneNumber"
                                disabled={isSubmitting}
                                numberLabel="Phone number*"
                            />

                            <Text type="h5" className={styles.Section}>
                                Shipping Address
                            </Text>
                            <TextField
                                disabled={isSubmitting}
                                name="fromAddressSt"
                                label="Street*"
                            />
                            <TextField
                                disabled={isSubmitting}
                                name="fromAddressCity"
                                label="City*"
                            />
                            <TextField
                                disabled={isSubmitting}
                                name="fromAddressState"
                                label="State / Province"
                            />
                            <TextField
                                disabled={isSubmitting}
                                name="fromAddressZip"
                                label="Zip / Postal Code"
                            />
                            <DropdownField
                                label="Country*"
                                name="fromAddressCountryIso2"
                                menuPlacement="top"
                                isDisabled={isSubmitting}
                                withDividers
                                isSearchable={false}
                                options={supportedCountries}
                            />
                        </div>
                        <div className={styles.ModalFooter}>
                            <Button type="submit" disabled={isSubmitting}>
                                Connect Account
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default ConnectFedExFormModal;
