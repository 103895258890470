import { usePaginatedQuery } from 'features/common/serverStateHandler';
import { removeEmpty } from 'features/common/utils/object.utils';
import { fetchMerchantLocationLinks } from 'features/settings/api/MerchantLocationLinks.data';
import { IMerchantLocationLinksGetParams } from 'features/settings/api/MerchantLocationLinks.types';
import { IMerchantLocationLink } from 'features/settings/types';

const queryKeys = {
    all: [{ scope: 'merchantLocationLinks' }] as const,
    lists: () => [{ ...queryKeys.all[0], entity: 'lists' }] as const,
    list: ({ page }: IMerchantLocationLinksGetParams) =>
        [
            removeEmpty({
                ...queryKeys.lists()[0],
                page,
            }),
        ] as const,
};

export const useGetMerchantLocationLinks = (
    params: IMerchantLocationLinksGetParams,
) => {
    const { data, isLoading, isError, refetch, ...rest } =
        usePaginatedQuery<IMerchantLocationLink>({
            params,
            queryFn: fetchMerchantLocationLinks,
            queryKey: queryKeys.list,
        });

    return {
        merchantLocationLinks: data?.results || [],
        isLoadingMerchantLocationLinks: isLoading,
        isErrorMerchantLocationLinks: isError,
        refetchMerchantLocationLinks: refetch,
        ...rest,
    };
};
