import { removeEmpty } from 'features/common/utils';
import { ICategoriesGetParams } from 'features/categories/api/Categories.types';

export const categoryKeys = {
    all: [{ scope: 'categories' }] as const,
    category: (id: string) => [{ ...categoryKeys.all[0], id }] as const,
    lists: () => [{ ...categoryKeys.all[0], entity: 'lists' }] as const,
    list: ({ phrase, page }: ICategoriesGetParams) =>
        [
            removeEmpty({
                ...categoryKeys.lists()[0],
                phrase,
                page,
            }),
        ] as const,
    infinityList: () =>
        [{ ...categoryKeys.all[0], entity: 'infinity' }] as const,
    infinity: (phrase?: string) =>
        [removeEmpty({ ...categoryKeys.infinityList()[0], phrase })] as const,
};
