import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LoadingOverlay, showToast } from 'spoton-lib';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    AddEditPage,
    mapChannelToDropdownOption,
    attributesDiffer,
    omit,
    usePreventUnload,
} from 'features/common';
import { ImageUploaderOnChange } from 'features/common/components/ImageUploader/ImageUploader.types';
import { SelectionDropdownOnChange } from 'features/common/components/SelectionDropdown/SelectionDropdown.types';
import { useForm, useFormHandlers } from 'features/common/hooks/useForm';
import {
    InputOnChange,
    NumberFormatInputOnChange,
    StringFormatInputOnChange,
    TagsInputOnAdd,
    TagsInputOnClear,
    TagsInputOnRemove,
} from 'features/common/types/inputs.type';
import { ToggleableOnChange } from 'features/common/types/toggleable.types';
import { EditControls } from 'features/common/components/EditControls';
import { AvailabilitySection } from 'features/products/components/AvailabilitySection';
import {
    AvailabilityChannelsOnChange,
    AvailabilityVariantsOnChange,
    IAvailabilitySectionErrors,
    IPropFields as AvailabilitySectionFields,
    ClearableFields as AvailabilitySectionClearableFields,
} from 'features/products/components/AvailabilitySection/AvailabilitySection.types';
import { GeneralSection } from 'features/products/components/GeneralSection';
import {
    GeneralSectionDescriptionOnChange,
    IGeneralSectionErrors,
    IPropFields as GeneralSectionFields,
    ClearableFields as GeneralSectionClearableFields,
} from 'features/products/components/GeneralSection/GeneralSection.types';
import { ProductOptionsOnChange } from 'features/products/components/ProductOptions/ProductOptions.types';
import {
    IPropFields as ShippingSectionFields,
    IShippingSectionErrors,
    // ShippingSectionOnInputsChange,
} from 'features/products/components/ShippingSection/ShippingSection.types';
import { getInitialGeneralData } from 'features/products/components/GeneralSection/GeneralSection.utils';
import {
    getChannelsFromAvailabilityData,
    getInitialAvailabilityData,
} from 'features/products/components/AvailabilitySection/AvailabilitySection.utils';
import { getInitialShippingData } from 'features/products/components/ShippingSection/ShippingSection.utils';
import {
    ProductDuplication,
    ProductErrorsBanner,
    ShippingSection,
} from 'features/products/components';
import {
    IProductOption,
    IProductOptionsToRemove,
    IProductVariantOption,
} from 'features/products/types';
import { mapObjectToEmptyErrorObject } from 'features/common/utils/validators.utils';
import { scrollToTop } from 'features/common/utils/scroll.utils';
import { useChannels, useFlags } from 'features/common/hooks';
import { productsPaths } from 'features/products/routes/products.paths';
import {
    useWeightLimitCheck,
    useShippingSettings,
} from 'features/shipping/hooks';
import { useUnsavedChangesToggle } from 'features/shipping/components/UnsavedPrompt';
import { WeightWarningModal } from 'features/shipping/components/WeightWarningModal';
import {
    quantityUnit,
    useFetchChannels,
    useGetCurrentMerchantLocation,
} from 'features/common/services';
import { ILocationState } from 'features/common/types';
import {
    useFetchProductData,
    useCreateProduct,
    useUpdateProduct,
    useDeleteProduct,
} from 'features/products/services/products';
import {
    filterGivenVariantOptionsFromVariantOptionsList,
    mapProductDataToApiProduct,
} from 'features/products/services/products.utils';

import {
    connector,
    IPropTypes,
    IWeightWarningModal,
} from './ProductPage.types';
import {
    mapProductOptionsToAvailabilitySectionVariants,
    isAvailabilitySectionValid,
    mapVariantErrorsForExistingVariants,
    validateGeneralSection,
    productOrVariantHasNoActiveChannels,
    clearAvailabilityPricesError,
    validateShippingSection,
    isECommerceProduct,
    getInitialProductPageData,
    removeDecimalPartFromQuantities,
    revalidateProductOptions,
    revalidateHasVariants,
    filterProductOptions,
    getHasNewProductOptions,
    getSimplifiedProductOptionsToRemoveAndNewVariantsState,
    mapProductOptionsToVariantOptions,
} from './ProductPage.utils';
import { useResetShippingData } from './hooks/use-reset-shipping-data';

export function ProductPage({
    isLoading,
    isDuplicated,
    productId,
    duplicatedProductData,
    isEditing,
}: IPropTypes) {
    const {
        shouldUseLegacyUPCValidation,
        isOpenPriceFeatureAvailable,
        isLowStockAlertFeatureEnabled,
    } = useFlags();
    const { locationActiveChannels, locationChannels } = useChannels();
    const { getCurrentMerchantLocationLoaded } =
        useGetCurrentMerchantLocation();
    const merchantLocation = getCurrentMerchantLocationLoaded();
    const isLoyaltyEnabled = merchantLocation.isLoyalty;
    const isOverallocationEnabled = merchantLocation.allowOverallocation;

    const location = useLocation();
    const navigate = useNavigate();

    const navigateBack = (shouldPreserveUrl: boolean) => {
        if (
            shouldPreserveUrl &&
            (location.state as ILocationState)?.from?.includes(
                productsPaths.root,
            )
        ) {
            navigate(-1);
        } else {
            navigate(productsPaths.root);
        }
    };

    const { rawProduct, productData: fetchedProductData } =
        useFetchProductData(productId);

    const productData = productId
        ? fetchedProductData
        : duplicatedProductData ||
          getInitialProductPageData(locationActiveChannels, isLoyaltyEnabled);

    const [isDirty, setIsDirty] = useState(isDuplicated);
    const { isShippingEnabled, isShippoActivated } = useShippingSettings();
    const [weightWarningModal, setWeightWarningModal] =
        useState<IWeightWarningModal>({
            isOpen: false,
            withButtons: false,
        });
    const [shouldPromptWhenDirty, setShouldPromptWhenDirty] = useState(true);

    // Initial data for each section. Should be computed once, at first render.
    const initialGeneralData = useMemo<GeneralSectionFields>(
        () => getInitialGeneralData(isLoyaltyEnabled, productData),
        [productData],
    );

    const initialAvailabilityData = useMemo<AvailabilitySectionFields>(
        () => getInitialAvailabilityData(locationActiveChannels, productData),
        [productData, locationActiveChannels],
    );

    const initialShippingData = useMemo<ShippingSectionFields>(
        () => getInitialShippingData(productData),
        [productData],
    );

    const [generalSectionErrors, setGeneralSectionErrors] =
        useState<IGeneralSectionErrors>({
            ...mapObjectToEmptyErrorObject(initialGeneralData),
            productOptions: {},
        });

    const [availabilitySectionErrors, setAvailabilitySectionErrors] =
        useState<IAvailabilitySectionErrors>({
            ...mapObjectToEmptyErrorObject(initialAvailabilityData),
            variants: {},
            channels: {},
        });

    const [shippingSectionErrors, setShippingSectionErrors] =
        useState<IShippingSectionErrors>(
            mapObjectToEmptyErrorObject(initialShippingData),
        );

    const {
        state: availabilitySectionState,
        stateUpdater: handleUpdateAvailabilitySection,
        updateField: handleUpdateAvailabilityField,
    } = useForm<AvailabilitySectionFields>(initialAvailabilityData, {
        onStateChange: (newState, currentState) => {
            if (!isDirty) {
                setIsDirty(true);
            }

            clearAvailabilityPricesError(
                newState,
                currentState,
                availabilitySectionErrors,
                setAvailabilitySectionErrors,
            );

            // Autocorrect fractional quantities when units of measure is switched to quantity
            if (
                attributesDiffer<AvailabilitySectionFields>(['priceUnit'])(
                    newState,
                    currentState,
                ) &&
                newState.priceUnit === quantityUnit.name
            ) {
                return removeDecimalPartFromQuantities(newState);
            }

            if (
                attributesDiffer<AvailabilitySectionFields>([
                    'channelsAvailability',
                    'price',
                    'compareAtPrice',
                ])(newState, currentState)
            ) {
                return {
                    channels: getChannelsFromAvailabilityData(
                        newState,
                        locationActiveChannels,
                    ),
                };
            }
        },
        onFieldChange: (field) => {
            const clearableFields: AvailabilitySectionClearableFields[] = [
                'compareAtPrice',
                'costPerItem',
                'itemsInStock',
                'lowStockAlertQuantity',
                'variants',
            ];

            if (field === 'channelsAvailability' || field === 'price') {
                setAvailabilitySectionErrors({
                    ...availabilitySectionErrors,
                    channelsAvailability: '',
                    price: '',
                });
            }

            if (clearableFields.includes(field)) {
                setAvailabilitySectionErrors({
                    ...availabilitySectionErrors,
                    [field]: '',
                });
            }
        },
    });

    const manageNewProductOptions = (
        productOptions: IProductOption[],
        generalSectionState: GeneralSectionFields,
    ) => {
        const allVariantOptions =
            mapProductOptionsToVariantOptions(productOptions);

        const activeVariantOptions =
            filterGivenVariantOptionsFromVariantOptionsList(
                allVariantOptions,
                availabilitySectionState.inactiveVariantOptions,
            );

        const variants = mapProductOptionsToAvailabilitySectionVariants(
            activeVariantOptions,
            availabilitySectionState.variants,
            productData?.availability?.variants ?? [],
            locationActiveChannels.map((channel) => channel.id),
        );

        revalidateProductOptions({
            productOptions,
            currentProductOptionsErrors: generalSectionErrors.productOptions,
            setGeneralSectionErrors,
        });

        revalidateHasVariants({
            hasVariants: generalSectionState.hasVariants,
            variants,
            currentError: generalSectionErrors.hasVariants,
            setGeneralSectionErrors,
        });

        setAvailabilitySectionErrors((availabilityErrors) => ({
            ...availabilityErrors,
            variants: mapVariantErrorsForExistingVariants(
                variants,
                availabilityErrors.variants,
            ),
        }));

        handleUpdateAvailabilityField('variants', variants);
    };

    // State for each section.
    const {
        state: generalSectionState,
        stateUpdater: handleUpdateGeneralSection,
        updateField: handleUpdateGeneralSectionField,
    } = useForm<GeneralSectionFields>(initialGeneralData, {
        onStateChange: () => {
            if (!isDirty) {
                setIsDirty(true);
            }
        },
        onFieldChange: (field, value) => {
            if (field === 'productOptions') {
                const newProductOptions = value as IProductOption[];
                const oldProductOptions = generalSectionState.productOptions;

                const hasNewProductOptions = getHasNewProductOptions(
                    newProductOptions,
                    oldProductOptions,
                );

                // since managing New Product Options is dependent on inactiveVariantOptions, first update those
                if (hasNewProductOptions) {
                    handleUpdateAvailabilityField('inactiveVariantOptions', []);
                } else {
                    manageNewProductOptions(
                        newProductOptions,
                        generalSectionState,
                    );
                }
            }

            if (field === 'hasVariants') {
                handleUpdateAvailabilityField('hasVariants', value as boolean);
            }

            const clearableFields: GeneralSectionClearableFields[] = [
                'name',
                'sku',
                'upc',
                'hasVariants',
            ];

            if (clearableFields.includes(field)) {
                setGeneralSectionErrors({
                    ...generalSectionErrors,
                    [field]: '',
                });
            }
        },
    });

    const isECommerceSelected = isECommerceProduct(
        availabilitySectionState,
        locationChannels,
    );

    const shouldCheckWeight = isShippingEnabled && isShippoActivated;

    const {
        state: shippingSectionState,
        stateUpdater: handleUpdateShippingSection,
    } = useForm<ShippingSectionFields>(initialShippingData, {
        onStateChange: () => {
            setIsDirty(true);
        },
        onFieldChange: (field) => {
            setShippingSectionErrors({
                ...shippingSectionErrors,
                [field]: '',
            });
        },
    });

    // we need to avoid updating variants and/or product options on initial render to avoid dirty form state
    const isInitialized = useRef<boolean>(false);

    useEffect(() => {
        if (!isInitialized.current) {
            isInitialized.current = true;
            return;
        }
        // if the list of inactive Variant Options was reset,
        // we need to recalculate the state of Product Options and afterwards variants based on those
        if (availabilitySectionState.inactiveVariantOptions.length === 0) {
            manageNewProductOptions(
                generalSectionState.productOptions,
                generalSectionState,
            );

            return;
        }

        // we have new inactive Variants and need to check if that means that some product options should be removed
        // afterwards variants should be updated
        const [productOptionsToRemove, newVariants] =
            getSimplifiedProductOptionsToRemoveAndNewVariantsState(
                availabilitySectionState.variants,
                availabilitySectionState.inactiveVariantOptions,
            );

        // if we found some product Options To Remove we need to update the state of Product Options
        // and afterwards variants based on those
        if (
            productOptionsToRemove &&
            Object.keys(productOptionsToRemove).length !== 0 &&
            Object.getPrototypeOf(productOptionsToRemove) === Object.prototype
        ) {
            const newProductOptions = filterProductOptions(
                [...generalSectionState.productOptions],
                productOptionsToRemove as IProductOptionsToRemove,
            );

            handleUpdateGeneralSectionField(
                'productOptions',
                newProductOptions,
            );
        } else {
            // no need to update product Options, simply update variants
            handleUpdateAvailabilityField('variants', newVariants);
        }
    }, [availabilitySectionState.inactiveVariantOptions.length]);

    const {
        isLoading: isCheckingWeight,
        warningMessage,
        weightCheckDetails,
        isExceedingWeightLimit,
    } = useWeightLimitCheck({
        unit: shippingSectionState.unit,
        value: shippingSectionState.value,
        shouldCheckWeight,
    });

    const openWeightWarningModalWithButtons = () => {
        setWeightWarningModal({
            isOpen: true,
            withButtons: true,
        });
    };

    const onProductSaveSuccess = () => {
        setIsDirty(false);
        navigateBack(false);

        if (productOrVariantHasNoActiveChannels(availabilitySectionState)) {
            showToast({
                variant: 'warning',
                title: 'Warning',
                content:
                    'This product is unavailable to sell because no channels have been activated.',
                autoClose: 4000,
            });
        }
    };

    const onProductSaveError = () => {
        setShouldPromptWhenDirty(true);
        scrollToTop();
    };

    const { createProduct, isCreatingProduct } = useCreateProduct(
        onProductSaveSuccess,
        onProductSaveError,
    );
    const { updateProduct, isUpdatingProduct } = useUpdateProduct(
        onProductSaveSuccess,
        onProductSaveError,
    );

    const isSaving = productId ? isUpdatingProduct : isCreatingProduct;

    const submitProductData = async () => {
        const savedProductData = {
            general: generalSectionState,
            availability: availabilitySectionState,
            shipping: shippingSectionState,
        };

        if (!savedProductData.general.hasVariants) {
            savedProductData.general.productOptions = [];
            savedProductData.availability.variants = [];
        }

        if (productId) {
            const allVariants = rawProduct?.variants;

            // Update existing product if productId is defined.
            updateProduct({
                id: productId,
                data: await mapProductDataToApiProduct({
                    productData: savedProductData,
                    locationChannels,
                    allVariants,
                    isLowStockAlertFeatureEnabled,
                }),
            });
        } else {
            // Create new product if productId is not defined.
            createProduct(
                await mapProductDataToApiProduct({
                    productData: savedProductData,
                    locationChannels,
                    isLowStockAlertFeatureEnabled,
                }),
            );
        }
    };

    usePreventUnload(isDirty);

    const shouldDisplayUnsavedChangesModal = isDirty && shouldPromptWhenDirty;
    const [isDuplicationOpen, setIsDuplicationOpen] = useState(false);

    //When duplicating a product we want to have custom behaviour of unsaved changes modal, therefore we don't display the default one
    useUnsavedChangesToggle(
        shouldDisplayUnsavedChangesModal && !isDuplicationOpen,
    );

    // Main product handlers (operations on product).
    const handleOnSave = async () => {
        setShouldPromptWhenDirty(false);

        if (isEditing && !isDirty) {
            navigateBack(false);
            return;
        }

        const generalValidationResult = await validateGeneralSection(
            initialGeneralData,
            generalSectionState,
            availabilitySectionState.variants,
            isDuplicated,
            shouldUseLegacyUPCValidation,
        );
        const availabilityValidationResult = await isAvailabilitySectionValid(
            initialAvailabilityData,
            availabilitySectionState,
            isDuplicated,
            shouldUseLegacyUPCValidation,
            isOpenPriceFeatureAvailable,
            isLowStockAlertFeatureEnabled,
            locationActiveChannels,
        );
        const shippingValidationResult = await validateShippingSection(
            shippingSectionState,
            isECommerceSelected,
        );

        setGeneralSectionErrors(generalValidationResult.errors);
        setAvailabilitySectionErrors(availabilityValidationResult.errors);
        setShippingSectionErrors(shippingValidationResult.errors);

        if (
            !generalValidationResult.isValid ||
            !availabilityValidationResult.isValid ||
            !shippingValidationResult.isValid
        ) {
            setShouldPromptWhenDirty(true);
            scrollToTop();

            return null;
        }

        if (isExceedingWeightLimit) {
            openWeightWarningModalWithButtons();
        } else {
            submitProductData();
        }
    };

    const toggleWeightWarningModal = () => {
        setWeightWarningModal((prev) => ({
            isOpen: !prev.isOpen,
            withButtons: false,
        }));
    };

    const saveProductDataAndCloseWeightWarningModal = () => {
        toggleWeightWarningModal();
        // it is fine to submit data - it is done after validation
        submitProductData();
    };

    const handleOnCancel = useCallback(() => {
        setShouldPromptWhenDirty(true);
        navigateBack(true);
    }, [navigate]);

    const onProductDeleteSuccess = () => {
        navigateBack(true);
    };

    const { deleteProduct } = useDeleteProduct(onProductDeleteSuccess);

    const handleOnDelete = useCallback(async () => {
        if (!productId) {
            return;
        }
        setShouldPromptWhenDirty(false);

        deleteProduct(productId);
    }, [navigate, productId]);

    const generalSection = useMemo(() => {
        // Handlers for general section.
        const onNameChange = handleUpdateGeneralSection<InputOnChange>(
            'name',
            useFormHandlers.handleInputOnChangeText,
        );

        const onDescriptionChange =
            handleUpdateGeneralSection<GeneralSectionDescriptionOnChange>(
                'description',
            );

        const onSkuChange = handleUpdateGeneralSection<InputOnChange>(
            'sku',
            useFormHandlers.handleInputOnChangeText,
        );

        const onUpcChange = handleUpdateGeneralSection<InputOnChange>(
            'upc',
            useFormHandlers.handleInputOnChangeText,
        );

        const onAddTag = handleUpdateGeneralSection<TagsInputOnAdd>(
            'tags',
            useFormHandlers.handleTagsInputOnAdd,
        );

        const onRemoveTag = handleUpdateGeneralSection<TagsInputOnRemove>(
            'tags',
            useFormHandlers.handleTagsInputOnRemove,
        );

        const onClearTags = handleUpdateGeneralSection<TagsInputOnClear>(
            'tags',
            useFormHandlers.handleTagsInputOnClear,
        );

        const onCategoryChange = handleUpdateGeneralSection('category');
        const onVendorChange = handleUpdateGeneralSection('vendor');

        const onOptionsChange =
            handleUpdateGeneralSection<ProductOptionsOnChange>(
                'productOptions',
            );

        const onToggleVariants =
            handleUpdateGeneralSection<ToggleableOnChange>('hasVariants');

        const onImagesChange =
            handleUpdateGeneralSection<ImageUploaderOnChange>('images');

        const onCanEarnRewardChange =
            handleUpdateGeneralSection<ToggleableOnChange>('canEarnReward');

        const onCanRedeemRewardChange =
            handleUpdateGeneralSection<ToggleableOnChange>('canRedeemReward');

        return (
            <GeneralSection
                {...generalSectionState}
                isLoading={isLoading}
                isEditing={isEditing}
                isLoyaltyEnabled={isLoyaltyEnabled}
                onNameChange={onNameChange}
                onSkuChange={onSkuChange}
                onUpcChange={onUpcChange}
                onAddTag={onAddTag}
                onRemoveTag={onRemoveTag}
                onClearTags={onClearTags}
                onCategoryChange={onCategoryChange}
                onVendorChange={onVendorChange}
                onOptionsChange={onOptionsChange}
                onToggleVariants={onToggleVariants}
                onDescriptionChange={onDescriptionChange}
                onImagesChange={onImagesChange}
                onCanEarnRewardChange={onCanEarnRewardChange}
                onCanRedeemRewardChange={onCanRedeemRewardChange}
                errors={generalSectionErrors}
            />
        );
    }, [
        generalSectionState,
        handleUpdateGeneralSection,
        isLoading,
        isEditing,
        generalSectionErrors,
    ]);

    const availabilitySection = useMemo(() => {
        const dropdownChannels = locationActiveChannels.map(
            mapChannelToDropdownOption,
        );
        // Handlers for availability section.
        const onChangePrice =
            handleUpdateAvailabilitySection<NumberFormatInputOnChange>(
                'price',
                useFormHandlers.handleInputOnChangeNumberFormat,
            );

        const onChangeCompareAtPrice =
            handleUpdateAvailabilitySection<NumberFormatInputOnChange>(
                'compareAtPrice',
                useFormHandlers.handleInputOnChangeNumberFormat,
            );

        const onChangeCostPerItem =
            handleUpdateAvailabilitySection<NumberFormatInputOnChange>(
                'costPerItem',
                useFormHandlers.handleInputOnChangeNumberFormat,
            );

        const onChangeItemsInStock =
            handleUpdateAvailabilitySection<NumberFormatInputOnChange>(
                'itemsInStock',
                useFormHandlers.handleInputOnChangeNumberFormat,
            );

        const onChangeUnit =
            handleUpdateAvailabilitySection<(value: string) => void>(
                'priceUnit',
            );

        const onChangeAvailability =
            handleUpdateAvailabilitySection<SelectionDropdownOnChange>(
                'channelsAvailability',
            );

        const onChangeSamePrice =
            handleUpdateAvailabilitySection<ToggleableOnChange>('isSamePrice');

        const onChangeChannels =
            handleUpdateAvailabilitySection<AvailabilityChannelsOnChange>(
                'channels',
            );

        const onChangeVariants =
            handleUpdateAvailabilitySection<AvailabilityVariantsOnChange>(
                'variants',
            );

        const onDeactivateVariant = (
            key: string,
            newInactiveVariantOptions: IProductVariantOption[][],
        ) => {
            handleUpdateAvailabilityField(
                'inactiveVariantOptions',
                newInactiveVariantOptions,
            );

            setAvailabilitySectionErrors((availabilityErrors) => ({
                ...availabilityErrors,
                variants: omit(availabilityErrors.variants, key),
            }));
        };

        const onChangeLowStockAlertEnabled =
            handleUpdateAvailabilitySection<ToggleableOnChange>(
                'isLowStockAlertEnabled',
            );

        const onChangeLowStockAlertQuantity =
            handleUpdateAvailabilitySection<NumberFormatInputOnChange>(
                'lowStockAlertQuantity',
                useFormHandlers.handleInputOnChangeNumberFormat,
            );

        return (
            <AvailabilitySection
                {...availabilitySectionState}
                isNegativeStockEnabled={isOverallocationEnabled}
                isLoading={isLoading}
                isEditing={isEditing}
                locationActiveChannels={locationActiveChannels}
                allChannels={dropdownChannels}
                onChangeVariants={onChangeVariants}
                onChangePrice={onChangePrice}
                onChangeCompareAtPrice={onChangeCompareAtPrice}
                onChangeCostPerItem={onChangeCostPerItem}
                onChangeItemsInStock={onChangeItemsInStock}
                onChangeUnit={onChangeUnit}
                onChangeSamePrice={onChangeSamePrice}
                onChangeAvailability={onChangeAvailability}
                onChangeChannels={onChangeChannels}
                onDeactivateVariant={onDeactivateVariant}
                onChangeLowStockAlertEnabled={onChangeLowStockAlertEnabled}
                onChangeLowStockAlertQuantity={onChangeLowStockAlertQuantity}
                errors={availabilitySectionErrors}
            />
        );
    }, [
        isLoading,
        isEditing,
        locationActiveChannels,
        availabilitySectionState,
        handleUpdateAvailabilitySection,
        availabilitySectionErrors,
    ]);

    const onUpdateWeight =
        handleUpdateShippingSection<NumberFormatInputOnChange>(
            'value',
            useFormHandlers.handleInputOnChangeNumberFormat,
        );

    const onUpdateUnit =
        handleUpdateShippingSection<StringFormatInputOnChange>('unit');

    useResetShippingData({
        shouldResetData: !isECommerceSelected && isDirty,
        updateWeight:
            handleUpdateShippingSection<StringFormatInputOnChange>('value'),
        updateUnit:
            handleUpdateShippingSection<StringFormatInputOnChange>('unit'),
    });

    const editControls = productId ? (
        <EditControls
            onDelete={handleOnDelete}
            isInactive={isLoading}
            isDuplicationOpen={isDuplicationOpen}
            setIsDuplicationOpen={setIsDuplicationOpen}
            shouldDisplayUnsavedChangesModal={shouldDisplayUnsavedChangesModal}
            renderDuplicationModal={(isOpen, onClose) => (
                <ProductDuplication
                    isOpen={isOpen}
                    onClose={onClose}
                    generalData={generalSectionState}
                    availabilityData={availabilitySectionState}
                    shippingData={shippingSectionState}
                />
            )}
        />
    ) : null;

    return (
        <AddEditPage
            title={productId ? 'Edit Product' : 'New Product'}
            onCancelHandler={handleOnCancel}
            onSaveHandler={handleOnSave}
            saveBtnTestId="productSaveChanges"
            cancelBtnTestId="productCancelChanges"
            saveBtnText="Done"
            isCancelButtonDisabled={isCheckingWeight}
            isSaveButtonDisabled={isCheckingWeight || !isDirty}
        >
            {weightCheckDetails && (
                <WeightWarningModal
                    isOpen={weightWarningModal.isOpen}
                    onCancel={toggleWeightWarningModal}
                    onSave={
                        weightWarningModal.withButtons
                            ? saveProductDataAndCloseWeightWarningModal
                            : undefined
                    }
                    weightCheckDetails={weightCheckDetails}
                />
            )}
            {editControls}
            <ProductErrorsBanner
                availabilitySectionErrors={availabilitySectionErrors}
                generalSectionErrors={generalSectionErrors}
                shippingSectionErrors={shippingSectionErrors}
            />
            {generalSection}
            {availabilitySection}
            {isECommerceSelected && (
                <ShippingSection
                    {...{
                        isLoading,
                        onUpdateUnit,
                        onUpdateWeight,
                        ...shippingSectionState,
                    }}
                    openWeightWarningModal={toggleWeightWarningModal}
                    errors={shippingSectionErrors}
                    warningText={warningMessage}
                    unitOfMeasure={availabilitySectionState.priceUnit}
                />
            )}
            {isSaving && <LoadingOverlay />}
        </AddEditPage>
    );
}
/*
    We need to download additional data in order to determine initial form state for existing product.
    That's why we cannot render ProductPage component, until this data will be available.
    In order to achieve such functionality, Loader component has been provided, it fires onMount handler once,
    and it should trigger all data fetching for given page.
*/
function ProductPageWrapper(props: IPropTypes): JSX.Element {
    const { isLoading, productId, isDuplicated, onCancel } = props;
    const { isLoading: isLoadingChannels } = useFetchChannels();
    const { isLoading: isLoadingProduct } = useFetchProductData(productId);

    useEffect(() => {
        return () => {
            // Make sure duplicated product data is cleared
            if (isDuplicated) {
                onCancel();
            }
        };
    }, [isDuplicated, productId]);

    const isLoadingData = isLoading || isLoadingProduct || isLoadingChannels;

    // Since all data is loaded on init, we want rerender the component entirely, thus key prop
    return (
        <ProductPage
            {...props}
            key={`${isLoadingData}`}
            isLoading={isLoadingData}
        />
    );
}

export default connector(ProductPageWrapper);
