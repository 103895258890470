import { Text } from 'spoton-lib';
import clsx from 'clsx';

import { UsePackageCombinedGirthProps } from './types';
import { Dimension } from './Dimension';
import { formatValue } from './utils';
import styles from './usePackageCombinedGirth.module.scss';

// formula taken from https://support.ordoro.com/how-do-i-calculate-the-girth-and-length-of-a-package-using-the-parcel-dimensions/

const uspsLimitInInches = 108;
const upsAndFedexLimitInInches = 165;

const limits = [uspsLimitInInches, upsAndFedexLimitInInches];
const maxLimit = Math.max(...limits);

export const usePackageCombinedGirth = ({
    length,
    width,
    height,
    dimensionUnit,
}: UsePackageCombinedGirthProps) => {
    const heightItem = new Dimension(height, dimensionUnit);
    const widthItem = new Dimension(width, dimensionUnit);
    const lengthItem = new Dimension(length, dimensionUnit);

    const [highestValue, secondValue, lastValue] = [
        heightItem.getValueInInches(),
        widthItem.getValueInInches(),
        lengthItem.getValueInInches(),
    ].sort((a, b) => b - a);

    const packageGirth = (secondValue + lastValue) * 2;
    const combinedPackageGirth = packageGirth + highestValue;

    const isOverAnyLimit = limits.some((limit) => combinedPackageGirth > limit);
    const isOverMaxLimit = combinedPackageGirth > maxLimit;

    const formatWarningText = () => {
        if (isOverAnyLimit) {
            return `The dimensions you have entered exceed the limits. USPS packages can be up to ${uspsLimitInInches} inches in length and girth combined. UPS/FedEx up to ${upsAndFedexLimitInInches} inches.`;
        }
    };

    return {
        combinedGirthInInches: (
            <Text as="p" className={styles.GirthText}>
                Current total girth:{' '}
                <Text
                    as="span"
                    isBold
                    className={clsx({
                        [styles.GirthText___isOverLimit]: isOverMaxLimit,
                    })}
                >
                    {formatValue(combinedPackageGirth)} in
                </Text>
            </Text>
        ),
        warningText: formatWarningText(),
        isGirthOverMaxLimit: isOverMaxLimit,
    };
};
