import { useCallback } from 'react';
import { Dropdown } from 'spoton-lib';

import { useDebouncedState } from 'features/common/utils';
import { DropdownOnChange, DropdownOnInputChange } from 'features/common/types';
import {
    useCreateDropdownItem,
    useGetDropdownItem,
    useInfiniteDropdownItems,
} from 'features/common/services/queryDropdown';

import { IPropTypes } from './QueryDropdown.types';
import {
    extractItemName,
    getItemsList,
    getSelectedItem,
    mapItemToDropdownOption,
    newItemValue,
} from './QueryDropdown.utils';

export function QueryDropdown(props: IPropTypes) {
    const {
        value,
        onChange,
        queryKeys,
        fetchItems,
        fetchItem,
        createItem,
        successMessageFactory,
        errorMessageFactory,
        hasEmptyOption = true,
        allSelected = [],
        addNewMessage,
        itemType,
        ...dropdownProps
    } = props;
    const [query, setQuery] = useDebouncedState('', 300);
    const { fetchNextPage, data, isLoading, isFetching } =
        useInfiniteDropdownItems(fetchItems, queryKeys, {
            pageSize: 10,
            phrase: query,
        });
    const { data: dropdownItem = null } = useGetDropdownItem(
        fetchItem,
        queryKeys,
        value,
    );

    const { mutateAsync: createDropdownItem, isLoading: isMutating } =
        useCreateDropdownItem(
            createItem,
            queryKeys,
            successMessageFactory,
            errorMessageFactory,
        );

    const isBusy = isLoading || isFetching || isMutating;

    const results = data?.pages.flatMap((pageData) => pageData.results) || [];

    const { itemsList } = getItemsList({
        value,
        query,
        hasEmptyOption,
        items: results.map(mapItemToDropdownOption),
        selectedElements: allSelected,
        itemType,
    });

    const selectedCategory = getSelectedItem(
        dropdownItem,
        value,
        itemsList,
        hasEmptyOption,
        isBusy,
    );

    const handleInputChange = useCallback<DropdownOnInputChange>(
        (value, { action }) => {
            if (action === 'input-change') {
                setQuery(value);
            }
        },
        [setQuery],
    );
    const clearInput = useCallback(() => setQuery(''), []);

    const handleDropdownChange = useCallback<DropdownOnChange>(
        async (selectedItem) => {
            let item = selectedItem;

            if (selectedItem.value === newItemValue) {
                const newItem = await createDropdownItem({
                    name: extractItemName(selectedItem.label),
                });

                item = mapItemToDropdownOption(newItem);
            }

            clearInput();
            onChange(item);
        },
        [onChange],
    );

    const noResultsFoundGenerator = (): string => {
        if (isLoading || isFetching) {
            return 'Loading...';
        }

        if (addNewMessage) {
            return addNewMessage;
        }

        return 'No options';
    };

    return (
        <Dropdown
            value={selectedCategory}
            options={itemsList}
            onBlur={clearInput}
            onChange={handleDropdownChange}
            onInputChange={handleInputChange}
            onMenuScrollToBottom={fetchNextPage}
            noOptionsMessage={noResultsFoundGenerator}
            menuPortalTarget={document.body}
            isLoading={isBusy}
            withDividers
            {...dropdownProps}
        />
    );
}

export default QueryDropdown;
