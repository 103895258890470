import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import {
    IUnitsOfMeasureGetResponse,
    IUnitsOfMeasurePostPayload,
    IUnitsOfMeasurePostResponse,
} from './UnitsOfMeasure.type';

export function unitsOfMeasureGet() {
    return axios
        .get<IUnitsOfMeasureGetResponse>('/api/v1/units/')
        .then(getData);
}

export function unitsOfMeasureSet(payload: IUnitsOfMeasurePostPayload) {
    return axios
        .post<IUnitsOfMeasurePostResponse>('/api/v1/units/', payload)
        .then(getData);
}
