import * as yup from 'yup';

import { IDiscountInfoData } from 'features/settings/components/DiscountInformation/DiscountInformation.types';

type ValidationSchema = Pick<IDiscountInfoData, 'name' | 'discount'>;

export const validationSchema: yup.SchemaOf<ValidationSchema> = yup
    .object()
    .shape({
        name: yup.string().required('Value can not be empty!'),
        discount: yup.number().required('Value can not be empty!').moreThan(0),
    });
