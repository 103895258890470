/** Generate an uninitialized array from 0 to n -1 length */
export const generateNumberArray = (n: number): number[] => {
    return Array.from(Array(n).keys());
};

/**
 * Returns intersecting elements in multiple arrays
 * @param arrays Arrays
 */
export const arraysIntersection = <T>(arrays: T[][]): T[] => {
    const firstSet: Set<T> = arrays.length ? new Set(arrays[0]) : new Set();
    const intersection = arrays.reduce(
        (acc, current) => new Set(current.filter((x) => acc.has(x))),
        firstSet,
    );

    return Array.from(intersection);
};

/**
 * Calculates "Cartesian Product" sets.
 * @example
 *   cartesianProduct([[1,2], [4,8], [16,32]])
 *   Returns:
 *   [
 *     [1, 4, 16],
 *     [1, 4, 32],
 *     [1, 8, 16],
 *     [1, 8, 32],
 *     [2, 4, 16],
 *     [2, 4, 32],
 *     [2, 8, 16],
 *     [2, 8, 32]
 *   ]
 * @see https://stackoverflow.com/a/36234242/1955709
 * @see https://en.wikipedia.org/wiki/Cartesian_product
 * @param arr {T[][]}
 * @returns {T[][]}
 */
export const cartesianProduct = <T>(arr: T[][]): T[][] => {
    return arr.reduce(
        (a, b) => {
            return a
                .map((x) => {
                    return b.map((y) => {
                        return x.concat(y);
                    });
                })
                .reduce((c, d) => c.concat(d), []);
        },
        [[]] as T[][],
    );
};

/**
 * Converts [2,3,4,5,6,7] array to
 * [
 *  [2,3],
 *  [4,5],
 *  [6,7]
 * ]
 * @param array Cn
 * @param number of elements in one line
 * @returns two-dimensional array
 */
export const splitArrayBy = <T>(array: T[], n: number): T[][] => {
    const copy = [...array];

    return new Array(Math.ceil(copy.length / n))
        .fill(n)
        .map((n) => copy.splice(0, n));
};

/**
 * Converts [[1,2], [3,4,[5]], 6] array (any depth) to
 * [1,2,3,4,5,6]
 *
 * param "array" has type "any" because it can be T[], T[][] and so on
 */
export const makeArrayFlat = <T>(array: any): T[] => {
    return array.reduce((flat: T[], toFlatten: any) => {
        return flat.concat(
            Array.isArray(toFlatten) ? makeArrayFlat(toFlatten) : toFlatten,
        );
    }, []);
};

const copyArrayLike = (arrayLike: ArrayLike<any>) => {
    if (!arrayLike) {
        return [];
    } else if (Array.isArray(arrayLike)) {
        return [...arrayLike];
    } else {
        const maxIndex = Object.keys(arrayLike)
            .map((key) => parseInt(key))
            .reduce((max, el) => (el > max ? el : max), 0);
        return Array.from({ ...arrayLike, length: maxIndex + 1 });
    }
};

/**
 * Pure function that moves one item into a given position.
 * move([1,2,3], 2, 0) results in [3,1,2]
 * @param array source array
 * @param fromIndex index of item to be moved
 * @param toIndex index of destination position
 * @returns new array with moved items
 */
// Formik implementation
// Source: https://github.com/jaredpalmer/formik/blob/master/packages/formik/src/FieldArray.tsx
export const move = (array: any[], fromIndex: number, toIndex: number) => {
    const copy = copyArrayLike(array);
    const value = copy[fromIndex];
    copy.splice(fromIndex, 1);
    copy.splice(toIndex, 0, value);
    return copy;
};
