import { useCallback } from 'react';
import { showToast } from 'spoton-lib';

import { ErrorLogger } from 'features/common/utils';

export const useClipboard = () => {
    const copy = useCallback(
        async (
            value: string,
            successMessage?: string | null,
            errorMessage?: string | null,
        ) => {
            try {
                // eslint-disable-next-line no-restricted-properties
                await navigator.clipboard.writeText(value);

                successMessage &&
                    showToast({
                        variant: 'success',
                        content: successMessage,
                        autoClose: 4000,
                    });
            } catch (e) {
                errorMessage &&
                    showToast({
                        variant: 'danger',
                        content: errorMessage,
                        autoClose: 4000,
                    });

                ErrorLogger.reportError(
                    errorMessage || `Copy to clipboard failed`,
                );
            }
        },
        [],
    );

    return { copy };
};
