import { Text, IconButton, colors } from 'spoton-lib';
import clsx from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';

import { DraggableWrapper } from 'features/common/components/DraggableWrapper';
import { DnDIcon } from 'features/common/components/DnDIcon';
import { useDeletePrompt } from 'features/products/components/ProductOptions/hooks';
import { ProductAttributesDropdown } from 'features/products/components/ProductAttributesDropdown';
import { IProductOption } from 'features/products/types';
import { IProductOptionsFormType } from 'features/products/components/RHFProductOptions/ProductOptions.types';
import { ProductAttributeOptions } from 'features/products/components/RHFProductOptions/ProductOptionsList/ProductAttributeOptions';

import styles from './ProductAttributeEdit.module.scss';
import { IPropsType } from './ProductAttributeEdit.types';

export function ProductAttributeEdit({
    index,
    onRemoveClick,
    isDragDisabled,
    attributeKey,
    isPageEditing,
}: IPropsType) {
    const { deletePromptElement, setDeletePromptOpen } =
        useDeletePrompt(onRemoveClick);

    const { control, setValue, watch, formState, getFieldState } =
        useFormContext<IProductOptionsFormType>();
    const productAttributeKey = `productOptions.${index}` as const;
    const allProductOptions = watch('productOptions');
    const productOption = watch(productAttributeKey);
    const optionAttribute = productOption.attribute;
    const setProductOptionValue = (value: any) => {
        setValue(productAttributeKey, value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };
    const { error } = getFieldState(
        `${productAttributeKey}.attribute`,
        formState,
    );
    const errorText = formState.isSubmitted ? error?.message : '';

    return (
        <>
            <DraggableWrapper
                draggableId={`edit-attribute-${attributeKey}`}
                index={index}
                key={attributeKey}
                isDragDisabled={isDragDisabled}
                className={clsx(
                    styles.Wrapper,
                    isPageEditing && styles.Wrapper___edit,
                )}
            >
                {({ dragHandleProps }) => (
                    <>
                        <Text type="sub2">Attribute {index + 1}</Text>
                        <div className={styles.AttributeInputWrapper}>
                            <div
                                className={styles.AttributeInputWrapper}
                                {...dragHandleProps}
                                tabIndex={-1}
                            >
                                <DnDIcon isDisabled={isDragDisabled} />
                            </div>
                            <div className={styles.AttributeDropdown}>
                                <Controller
                                    name={`productOptions.${index}`}
                                    control={control}
                                    render={({ field }) => (
                                        <ProductAttributesDropdown
                                            value={field.value.attribute}
                                            onChange={(
                                                item: IProductOption,
                                            ) => {
                                                setProductOptionValue(item);
                                            }}
                                            menuPortalTarget={document.body}
                                            isValid={!errorText}
                                            secondaryCondition={errorText}
                                            allSelected={allProductOptions}
                                            isDisabled={isPageEditing}
                                        />
                                    )}
                                />
                            </div>
                            {!isPageEditing && (
                                <IconButton
                                    name="DeleteIcon"
                                    alt={`Delete ${productOption.name} attribute icon`}
                                    onClick={() => setDeletePromptOpen(true)}
                                    disableBorder
                                    size={27}
                                    buttonProps={{ tabIndex: -1 }}
                                    color={colors.black}
                                />
                            )}
                        </div>
                        <div className={styles.ValuesWrapper}>
                            {optionAttribute && (
                                <>
                                    <Text type="p">
                                        Value (e.g. Small, Medium, Large)
                                    </Text>
                                    <ProductAttributeOptions
                                        name={`productOptions.${index}`}
                                        index={index}
                                        isError={!!error}
                                    />
                                </>
                            )}
                        </div>
                    </>
                )}
            </DraggableWrapper>
            {deletePromptElement}
        </>
    );
}
