import { useEffect, useState } from 'react';
// Disabled because of conditional rendering we can't use NexusModal proxy
// eslint-disable-next-line no-restricted-imports
import { Button, ModalFooter, Tabs } from 'spoton-lib';

import { NexusModal } from 'features/common';

import { ImportIssueTab } from './ImportIssueTab';
import type { IPropTypes as IImportIssueTabTypes } from './ImportIssueTab/ImportIssueTab.types';
import { IPropTypes } from './ImportModalIssues.types';
import styles from './ImportModalIssues.module.scss';

export function ImportModalIssues({
    isOpen,
    onBack,
    onClose,
    errors = [],
    warnings = [],
}: IPropTypes) {
    const [activeTab, setActiveTab] =
        useState<IImportIssueTabTypes['type']>('error');

    useEffect(() => {
        if (isOpen) {
            setActiveTab(
                errors.length === 0 && warnings.length > 0
                    ? 'warning'
                    : 'error',
            );
        }
    }, [isOpen, errors, warnings]);

    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="There are some issues in your file" />
                <Tabs
                    active={activeTab}
                    onSelect={(name) =>
                        setActiveTab(name as IImportIssueTabTypes['type'])
                    }
                >
                    <Tabs.Tab name="error">Errors</Tabs.Tab>
                    <Tabs.Tab name="warning">Warnings</Tabs.Tab>
                </Tabs>
                {activeTab === 'error' && (
                    <ImportIssueTab type="error" issues={errors} />
                )}
                {activeTab === 'warning' && (
                    <ImportIssueTab type="warning" issues={warnings} />
                )}
                <ModalFooter className={styles.ModalFooter}>
                    <Button
                        className={styles.ModalFooter_button}
                        onClick={onClose}
                        variant={onBack ? 'secondary' : 'primary'}
                    >
                        Close
                    </Button>
                    {onBack && (
                        <Button
                            className={styles.ModalFooter_button}
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    )}
                </ModalFooter>
            </div>
        </NexusModal>
    );
}

export default ImportModalIssues;
