import { ITenderDiscountType } from 'features/settings/types';

/**
 * Possible Chanel name
 */
export enum IChannelName {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    VIRTUAL_TERMINAL = 'VIRTUAL_TERMINAL',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    POINT_OF_SALE = 'POINT_OF_SALE',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    PAYMENTS_APP = 'PAYMENTS_APP',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    E_COMMERCE = 'E_COMMERCE',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    APPOINTMENTS = 'APPOINTMENTS',
}

/**
 * Channel interface
 */
export interface IChannelData {
    /** Channel id */
    id: string;

    /** Merchant location id */
    merchantLocation: string;

    /** Channel type */
    channelType: IChannelType;

    /** Is channel active */
    active: boolean;

    /** Devices that channels works on */
    deviceSet: string[];

    /** Whether the tender price effect is enabled for this channel */
    tenderPriceEffect: ITenderDiscountType;

    /** Is price for given channel required */
    openPriceAllowed: boolean;
}

export interface IChannel extends IChannelData {
    /** Channel name */
    channelName: IChannelName;

    /** Channel display  name */
    displayName: string;
}

/** Channel Type model */
export interface IChannelType {
    /** Channel type id */
    id: number;

    /** Channel type  name */
    name: IChannelName;
}

export interface IChannelLink {
    channel: string;
    channelName: IChannelName;
    openPriceAllowed: boolean;
}

export interface IChannelsPriceRange {
    min?: number;
    max?: number;
}
