import { useEffect, useRef, useState } from 'react';
import { ImageCropper } from 'spoton-lib';

import { NexusModal } from 'features/common/components';

import styles from './ImageCropModal.module.scss';
import { IPropTypes } from './ImageCropModal.types';

export function ImageCropModal({
    imageSrc,
    isOpen,
    onClose,
    onChange,
}: IPropTypes) {
    const cropper = useRef<ImageCropper>(null);
    const isInitialized = useRef<boolean>(false);
    const [isCropped, setIsCropped] = useState(false);

    useEffect(() => {
        isInitialized.current = false;
        setIsCropped(false);
    }, [isOpen]);

    const handleCrop = () => {
        if (!isInitialized.current) {
            isInitialized.current = true;
            return;
        }
        setIsCropped(true);
    };

    const handleSubmit = () => {
        if (cropper.current) {
            onChange(cropper.current.crop());
        }
    };

    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.ImageCropModal}
        >
            <NexusModal.Header title="Crop Photo" isCentered />
            <div className={styles.ImageCropModal_content}>
                <ImageCropper
                    ref={cropper}
                    src={imageSrc}
                    className={styles.ImageCropModal_cropper}
                    width={400}
                    height={400}
                    onUrlDataChange={handleCrop}
                />
            </div>
            <NexusModal.Footer
                submitTitle="Save"
                cancelTitle="Cancel"
                onSubmit={handleSubmit}
                isSubmitDisabled={!isCropped}
            />
        </NexusModal>
    );
}

export default ImageCropModal;
