import { connect, ConnectedProps } from 'react-redux';

import { productDuplicationActions } from 'features/products/redux/productDuplication';
import { DuplicationState } from 'features/common/components/DuplicationControls/DuplicationControls.types';
import { DuplicationModalOnCancel } from 'features/common/components/DuplicationModal/DuplicationModal.types';
import { IPropFields as AvailabilitySectionData } from 'features/products/components/AvailabilitySection/AvailabilitySection.types';
import { IPropFields as GeneralSectionData } from 'features/products/components/GeneralSection/GeneralSection.types';
import { IPropFields as ShippingSectionData } from 'features/products/components/ShippingSection/ShippingSection.types';

export interface IProductDuplicationState extends DuplicationState {
    images: boolean;
    variants: boolean;
    prices: boolean;
    stock: boolean;
}

export interface IOwnPropTypes {
    /** Is duplication modal visible. */
    isOpen: boolean;
    /** Product general section data. */
    generalData: GeneralSectionData;
    /** Product availability section data. */
    availabilityData: AvailabilitySectionData;
    /** Product shipping section data. */
    shippingData: ShippingSectionData;
    /** Handler fired when duplication modal should close. */
    onClose: DuplicationModalOnCancel;
}

const mapDispatchToProps = {
    onDuplicate: productDuplicationActions.createDuplicatedProduct,
};

export const connector = connect(null, mapDispatchToProps);

export type IPropTypes = ConnectedProps<typeof connector> & IOwnPropTypes;
