import { IChannelData } from 'features/common/types';
import { getData } from 'features/common/utils/api.utils';
import { axios } from 'features/common/utils/axios.utils';
import { excludedChannels } from 'features/common/utils/channels.utils';

import {
    IChannelsGetResponse,
    IChannelPatchPayload,
    IChannelPatchResponse,
    IChannelsGetParams,
} from './Channels.types';

/** Gets a list of channels */
export const channelsGet = async ({ page }: IChannelsGetParams) => {
    const getResponse = await axios.get<IChannelsGetResponse>(
        `/api/v1/channels/`,
        {
            params: {
                page,
            },
        },
    );
    const results = getResponse.data.results;
    const resultsFiltered = results.filter(
        (channel) => !excludedChannels.includes(channel.channelType.name),
    );
    return resultsFiltered;
};

/** Patches a model */
export function channelPatch(
    payload: IChannelPatchPayload,
): Promise<IChannelData> {
    const { id } = payload;

    return axios
        .patch<IChannelPatchResponse>(`/api/v1/channels/${id}/`, payload)
        .then(getData);
}
