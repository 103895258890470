import { useState, useEffect, useCallback } from 'react';
import {
    convertToRaw,
    RichTextEditor,
    RichTextEditorContentState,
    RichTextEditorState,
} from 'spoton-lib';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Controller, useFormContext } from 'react-hook-form';

import { IPropTypes } from './RichTextEditorController.types';

export function RichTextEditorController(props: IPropTypes) {
    const { name, placeholder, className } = props;

    const { control, getValues, setValue } = useFormContext();

    const [descriptionState, setDescriptionState] =
        useState<RichTextEditorState>(RichTextEditorState.createEmpty());

    useEffect(() => {
        const { contentBlocks, entityMap } = htmlToDraft(getValues(name));
        const contentState = RichTextEditorContentState.createFromBlockArray(
            contentBlocks,
            entityMap,
        );
        const editorState = RichTextEditorState.createWithContent(contentState);
        setDescriptionState(editorState);
        // Do not use 'description' as dependency, because we want to create draftjs only state once, on initial load.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDescriptionEditorChange = useCallback(
        (newState: RichTextEditorState) => {
            setDescriptionState(newState);
            const rawContentState = convertToRaw(newState.getCurrentContent());
            const htmlMarkup = draftToHtml(rawContentState);

            setValue(name, htmlMarkup);
        },
        [setDescriptionState],
    );

    return (
        <Controller
            name={name}
            control={control}
            render={() => (
                <RichTextEditor
                    editorState={descriptionState}
                    onStateChange={handleDescriptionEditorChange}
                    className={className}
                    placeholder={placeholder}
                />
            )}
        />
    );
}

export default RichTextEditorController;
