import { Text, LoadingOverlay, Switch, Button } from 'spoton-lib';
import clsx from 'clsx';

import { ShippingCarrier, shippingCarrierLogo } from 'features/common/utils';
import { CarrierServicesModal } from 'features/shipping/components';
import { IShippingCarrierService } from 'features/shipping/types';
import { ConnectFedExFormModal } from 'features/shipping/components/ConnectFedExFormModal';

import { IPropTypes, CarrierElementProps } from './ShippingCarrierItem.types';
import { useCarrier } from './hooks/useCarrier';
import { ActiveModalKey } from './hooks/types';
import styles from './ShippingCarrierItem.module.scss';

export function ShippingCarrierItem(props: IPropTypes) {
    const {
        carrier,
        selectedServices = [],
        emitSelectedServices,
        carrierElement,
        children,
        shouldRenderCarrierServicesModal = true,
        className = '',
    } = props;
    const {
        closeModal,
        openCarrierServicesModal,
        isRefetchingCarriers,
        activeModalKey,
        isFedExCarrier,
        toggleCarrierAvailability,
        isTogglingCarrier,
        onCarrierServicesAdd,
        refetchShippingCarriers,
    } = useCarrier({ carrier });

    const onAddServices = (services: IShippingCarrierService[]): void => {
        emitSelectedServices?.({
            [carrier.name]: services,
        });
        closeModal();
    };

    const handleFedExSubmissionSuccess = async () => {
        closeModal();
        await refetchShippingCarriers();
        openCarrierServicesModal();
    };

    const renderCarrier = () => {
        return (
            <div
                className={clsx(styles.ShippingCarrierToggleBlock, className, {
                    [styles.ShippingCarrierToggleBlock___disabled]:
                        !carrier.isEnabled,
                })}
            >
                <div className={styles.ShippingCarrierToggleBlock_wrapper}>
                    <img
                        src={
                            shippingCarrierLogo[carrier.name as ShippingCarrier]
                        }
                        alt={`${carrier.name} logo`}
                        className={styles.ShippingCarrierToggleBlock_img}
                    />
                    <div className={styles.ShippingCarrierToggleBlockText}>
                        <Text isBold>{carrier.name}</Text>
                    </div>
                </div>
                {!carrier.isEnabled ? (
                    <Text
                        type="label"
                        className={styles.ShippingCarrierComingSoon}
                    >
                        Coming Soon
                    </Text>
                ) : (
                    carrierElement({
                        isTogglingCarrier,
                        toggleCarrier: toggleCarrierAvailability,
                        onServicesButtonClick: onCarrierServicesAdd,
                    })
                )}
            </div>
        );
    };

    return (
        <>
            {isRefetchingCarriers && <LoadingOverlay hasBackgroundBlur />}

            {renderCarrier()}
            {children?.({ openCarrierServicesModal })}

            {shouldRenderCarrierServicesModal && (
                <CarrierServicesModal
                    isOpen={activeModalKey === ActiveModalKey.carrierServices}
                    selectedCarrier={carrier}
                    onClose={closeModal}
                    addServices={onAddServices}
                    checkedServices={selectedServices}
                />
            )}

            {isFedExCarrier && (
                <ConnectFedExFormModal
                    isOpen={activeModalKey === ActiveModalKey.connectFedex}
                    onClose={closeModal}
                    onSuccess={handleFedExSubmissionSuccess}
                />
            )}
        </>
    );
}

type CarrierTogglerProps = Pick<CarrierElementProps, 'toggleCarrier'> & {
    isActive: boolean;
    disabled?: boolean;
    testId?: string;
};

function CarrierToggler({
    isActive,
    toggleCarrier,
    testId,
    disabled = false,
}: CarrierTogglerProps) {
    return (
        <Switch
            checked={isActive}
            onChange={toggleCarrier}
            disabled={disabled}
            data-testid={testId}
        />
    );
}

type ServicesButtonProps = Pick<
    CarrierElementProps,
    'onServicesButtonClick'
> & {
    isEditing: boolean;
    isLoading?: boolean;
};

function ServicesButton({
    onServicesButtonClick,
    isEditing,
    isLoading = false,
}: ServicesButtonProps) {
    return (
        <Button
            className={styles.ServicesButton}
            variant="tertiary"
            onClick={onServicesButtonClick}
            type="button"
            isLoading={isLoading}
        >
            {isEditing ? 'Edit' : 'Add'} Services
        </Button>
    );
}

ShippingCarrierItem.CarrierToggler = CarrierToggler;
ShippingCarrierItem.ServicesButton = ServicesButton;

export default ShippingCarrierItem;
