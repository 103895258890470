export interface ITab {
    name: string;
    label: string;
}

export enum IShippingPageTab {
    shippingMethods = 'shipping-methods',
    localDelivery = 'local-delivery',
    localPickup = 'local-pickup',
}
