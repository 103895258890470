import { Avatar, Text } from 'spoton-lib';

import { IPropTypes, defaultProps } from './AvatarStack.types';
import styles from './AvatarStack.module.scss';

export function AvatarStack(props: IPropTypes) {
    const { avatars, maxAvatars, onClick } = props;

    const hasExtraAvatars = avatars.length > maxAvatars;
    const avatarsToRender = hasExtraAvatars
        ? avatars.slice(0, maxAvatars)
        : avatars;

    return (
        <div
            className={styles.AvatarStack}
            onClick={onClick}
            data-testid="AvatarStackButton"
        >
            {avatarsToRender.map((el, index) => (
                <div key={index} className={styles.AvatarStack_item}>
                    <Avatar
                        {...props}
                        photoUrl={el.photoUrl}
                        // In order to display user placeholder, photoUrl and initials have to be falsy.
                        initials={el.photoUrl ? el.initials : ''}
                    />
                </div>
            ))}
            {hasExtraAvatars ? (
                <div key="extraCounter" className={styles.AvatarStack_counter}>
                    <Text className={styles.Label}>{`+${
                        avatars.length - avatarsToRender.length
                    }`}</Text>
                </div>
            ) : null}
        </div>
    );
}

AvatarStack.defaultProps = defaultProps;

export default AvatarStack;
