// eslint-disable-next-line no-restricted-imports
import axios, { AxiosError } from 'axios';

export type ErrorMessage = string | undefined | JSX.Element;

type FormattedErrorMessage = {
    detail: string;
};

export type FormattedErrorMessages = Record<string, string[]>;

export const isAxiosError = (payload: unknown): payload is AxiosError =>
    axios.isAxiosError(payload);

export const hasAxiosErrorsSet = (payload: unknown) =>
    Boolean(
        isAxiosError(payload) &&
            !!payload.response &&
            typeof payload.response.data === 'object' &&
            payload.response.data !== null,
    );

const hasErrorDetailField = (
    axiosError: AxiosError<unknown>,
): axiosError is AxiosError<{ detail: string }> =>
    !!axiosError.response &&
    typeof axiosError.response.data === 'object' &&
    axiosError.response.data !== null &&
    'detail' in axiosError.response.data;

export const isAxiosErrorWithFormattedMessage = (
    axiosError: unknown,
): axiosError is AxiosError<FormattedErrorMessage> =>
    isAxiosError(axiosError) &&
    hasErrorDetailField(axiosError) &&
    typeof axiosError?.response?.data.detail === 'string';

export const formatError = (
    payload: unknown,
    onUnhandledBeError?: (axiosError: AxiosError) => ErrorMessage,
): ErrorMessage => {
    // non-HTTP error
    if (!isAxiosError(payload)) {
        return 'Unexpected Error';
    }

    // we are sure that here it is axios error

    if (isAxiosErrorWithFormattedMessage(payload)) {
        return payload.response?.data.detail;
    }

    return onUnhandledBeError?.(payload);
};
