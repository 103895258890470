import { vendorKeys } from 'features/vendors/services/queryKeys';
import {
    vendorCreate,
    vendorGet,
    vendorsGet,
} from 'features/vendors/api/Vendors.data';
import { QueryDropdown } from 'features/common/components/QueryDropdown';

import { IPropTypes } from './VendorDropdown.types';

export function VendorDropdown(props: IPropTypes) {
    const { value, onChange, ...rest } = props;

    return (
        <QueryDropdown
            label="Vendor"
            successMessageFactory={(name: string) =>
                `Vendor "${name}" created successfully`
            }
            errorMessageFactory={(name: string) =>
                `Error while creating "${name}" vendor. Try again.`
            }
            fetchItems={vendorsGet}
            fetchItem={vendorGet}
            createItem={vendorCreate}
            queryKeys={{
                ...vendorKeys,
                item: vendorKeys.vendor,
            }}
            value={value}
            onChange={(item) => onChange(String(item.value))}
            itemType="vendor"
            {...rest}
        />
    );
}

export default VendorDropdown;
