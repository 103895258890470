import { useRef } from 'react';
import { Index, Table, TableCellProps, Text, Button, Icon } from 'spoton-lib';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import {
    DropdownMenu,
    TablePlaceholder,
    useMerchantLocationId,
} from 'features/common';
import { DropdownMenuOnSelect } from 'features/common/components/DropdownMenu/DropdownMenu.types';
import * as iconSet from 'features/common/assets/icons';
import { setMerchantLocationCookie } from 'features/common/utils';
import { useClipboard } from 'features/common/hooks/useClipboard';
import { omnichannelPaths } from 'features/omnichannel/routes/omnichannel.paths';
import { useGetMerchantLocationLinks } from 'features/settings/services';
import { networkErrorMessages } from 'features/common/constants';
import { useBannerStoreActions } from 'features/common/zustand/useBannerStore';

import styles from './MerchantLocationsPage.module.scss';

export function MerchantLocationsPage() {
    const { showBanner } = useBannerStoreActions();
    const merchantLocationId = useMerchantLocationId();
    const {
        merchantLocationLinks,
        isLoadingMerchantLocationLinks,
        isErrorMerchantLocationLinks,
    } = useGetMerchantLocationLinks({ page: 1 });
    const currentLocation = merchantLocationLinks.find(
        (location) => location.id === merchantLocationId,
    );
    const navigate = useNavigate();
    const contentRef = useRef<HTMLDivElement>(null);
    const { copy } = useClipboard();

    const handleClickBack = () => {
        navigate(-1);
    };

    const renderControls = (cellProps: TableCellProps) => {
        const id = String(cellProps.rowData.id);

        const handleSelect: DropdownMenuOnSelect = (option) => {
            if (option.value === 'select') {
                setMerchantLocationCookie(id);

                showBanner(
                    `Now you’re viewing “${cellProps.rowData.nickname}”`,
                );

                // It was said that after selecting merchant location, we should get redirected to main page.
                navigate(omnichannelPaths.root);
            } else if (option.value === 'copyId') {
                copy(
                    id,
                    'Location ID has been copied to clipboard.',
                    'Location ID copy failed.',
                );
            }
        };

        return (
            <DropdownMenu
                options={[
                    {
                        label: 'Select Location',
                        value: 'select',
                    },
                    {
                        label: 'Copy Location ID',
                        value: 'copyId',
                    },
                ]}
                onSelect={handleSelect}
                menuPortalTarget={contentRef.current}
                iconSize={24}
            />
        );
    };

    const renderCurrentLocation = () => (
        <div className={styles.CurrentLocation}>
            <Text
                type="h3"
                className={clsx(styles.CurrentLocation_name, 'fs-mask')}
            >
                {currentLocation?.nickname}
            </Text>
            <Text type="p" className={styles.CurrentLocation_annotation}>
                (in use)
            </Text>
            <Text
                type="p"
                className={clsx(styles.CurrentLocation_description, 'fs-mask')}
            >
                {`${currentLocation?.addressString}, ${currentLocation?.city}, ${currentLocation?.state}`}
                <span className={styles.CurrentLocation_divider}>/</span>
                {currentLocation?.phone}
                <span className={styles.CurrentLocation_divider}>/</span>
                {currentLocation?.email}
            </Text>
        </div>
    );

    const renderMaskedCell = (content: JSX.Element | string): JSX.Element => {
        return <span className="fs-mask">{content}</span>;
    };

    const renderNickname = ({ rowData }: TableCellProps): JSX.Element =>
        renderMaskedCell(rowData.nickname);

    const renderAddress = ({ rowData }: TableCellProps): JSX.Element =>
        renderMaskedCell(rowData.addressString);

    const renderCityAndState = ({ rowData }: TableCellProps): JSX.Element =>
        renderMaskedCell([rowData.city, rowData.state].join(', '));

    const renderPhone = ({ rowData }: TableCellProps): JSX.Element =>
        renderMaskedCell(rowData.phone);

    const renderEmail = ({ rowData }: TableCellProps): JSX.Element =>
        renderMaskedCell(rowData.email);

    return (
        <div className={styles.MerchantLocationsPage}>
            <div className={styles.Header}>
                <Button
                    onClick={handleClickBack}
                    className={styles.HeaderButton}
                    variant="tertiary"
                >
                    <>
                        <Icon
                            alt="icon for go back button"
                            iconSet={iconSet}
                            name="LeftArrow"
                            size={18}
                            className={styles.HeaderButton_icon}
                        />
                        Back
                    </>
                </Button>
                <Text type="h2" className={styles.Header_title}>
                    Locations
                </Text>
            </div>
            <div className={styles.Content} ref={contentRef}>
                <TablePlaceholder
                    isLoading={isLoadingMerchantLocationLinks}
                    className={styles.Placeholder}
                    hasError={isErrorMerchantLocationLinks}
                    errorMessage={networkErrorMessages.default}
                    hasHeader={true}
                    lineHeight={55}
                >
                    {currentLocation && renderCurrentLocation()}
                    <div className={styles.LocationsTable}>
                        <Table
                            rowCount={merchantLocationLinks.length}
                            rowHeight={55}
                            rowGetter={({ index }: Index) =>
                                merchantLocationLinks[index]
                            }
                            keyGetter={({ index }: Index) =>
                                String(merchantLocationLinks[index].id)
                            }
                        >
                            <Table.Column
                                label="Nickname"
                                dataKey="nickname"
                                width={250}
                                flexGrow={1}
                                cellRenderer={renderNickname}
                            />
                            <Table.Column
                                dataKey="merchantLocationType"
                                label="Type"
                                width={150}
                                flexGrow={1}
                            />
                            <Table.Column
                                label="Address"
                                dataKey="address"
                                width={200}
                                flexGrow={1}
                                cellRenderer={renderAddress}
                            />
                            <Table.Column
                                label="City, State"
                                dataKey="city"
                                width={250}
                                flexGrow={1}
                                cellRenderer={renderCityAndState}
                            />
                            <Table.Column
                                label="Phone"
                                dataKey="phone"
                                width={200}
                                flexGrow={1}
                                cellRenderer={renderPhone}
                            />
                            <Table.Column
                                label="Email"
                                dataKey="email"
                                width={250}
                                flexGrow={1}
                                cellRenderer={renderEmail}
                            />
                            <Table.Column
                                label=""
                                dataKey=""
                                // a workaround to not render sort arrows
                                headerRenderer={() => null}
                                cellRenderer={renderControls}
                                width={60}
                            />
                        </Table>
                    </div>
                </TablePlaceholder>
            </div>
        </div>
    );
}

export default MerchantLocationsPage;
