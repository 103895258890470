import * as yup from 'yup';

import { ITaxFormData } from 'features/settings/components/TaxInformation/TaxInformation.types';

export const validationSchema: yup.SchemaOf<ITaxFormData> = yup.object().shape({
    name: yup.string().required(),
    value: yup.number().moreThan(0).max(100).required(),
    isEnabled: yup.boolean().required(),
    defaultForProducts: yup.boolean().required(),
    defaultForServices: yup.boolean().required(),
});
