import { NumberFormat, NumberFormatValues } from 'spoton-lib';

import styles from './PricingSectionChannel.module.scss';
import { IPropTypes } from './PricingSectionChannel.types';

export function PricingSectionChannel(props: IPropTypes) {
    const { title, price, isPriceRequired, errorMessage, onChangeData } = props;

    return (
        <div className={styles.PricingSectionChannel}>
            <div className={styles.Row}>
                <div className={styles.Row_element}>
                    <NumberFormat
                        onValueChange={(event: NumberFormatValues) =>
                            onChangeData(
                                event.value !== '' ? Number(event.value) : null,
                            )
                        }
                        prefix="$"
                        label={`Price - ${title}`}
                        value={price ?? ''}
                        isValid={!errorMessage}
                        secondaryCondition={errorMessage}
                        data-testid={`PricingSectionChannel${title}Price`}
                        placeholder="$0.00"
                        primaryCondition={isPriceRequired ? 'Required' : ''}
                    />
                </div>
            </div>
        </div>
    );
}

export default PricingSectionChannel;
