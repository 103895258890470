import { IPropTypes } from './Placeholder.types';
import styles from './Placeholder.module.scss';

export function Placeholder(props: IPropTypes) {
    const { lineHeight = 52, style } = props;

    return (
        <div
            data-testid="Placeholder"
            className={styles.Placeholder}
            style={{ ...style, minHeight: lineHeight }}
        />
    );
}

export default Placeholder;
