import { AxiosError } from 'axios';

import { ErrorMessage } from 'features/common/utils';

type WeightValueMessage = {
    weight: {
        value: string[];
    };
};

const isWeightValueMessage = (payload: any): payload is WeightValueMessage => {
    const errorMessages = payload?.weight?.value;
    const isArray = Array.isArray(errorMessages);

    if (isArray && errorMessages.every((el) => typeof el === 'string')) {
        return true;
    }
    return false;
};

export const formatWeightErrorMessage = (err: AxiosError): ErrorMessage => {
    if (isWeightValueMessage(err?.response?.data)) {
        return err.response?.data.weight.value.join(' ');
    }
};
