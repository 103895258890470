import { v4 as uuid } from 'uuid';

import { IProductOption } from 'features/products/types';
import { IAttributePost } from 'features/attributes/types';
import { IDropdownOption } from 'features/common/types';

export const mapDropdownOptionToProductOption = (
    item: IDropdownOption,
): IProductOption => ({
    attribute: item.value,
    name: item.label,
    isEditing: true,
    values: [
        {
            value: '',
            key: uuid(),
        },
    ],
});

export const getValidCodeFromName = (name: string): string => {
    return name.replace(/^([0-9]+)/g, '_$1').replace(/[^a-zA-Z0-9]/g, '_');
};

export const mapProductOptionToAttributePost = ({
    name,
}: {
    name: string;
}): IAttributePost => ({
    type: 'OPTION',
    code: getValidCodeFromName(name),
    name,
});
