import clsx from 'clsx';

import { useDelayedToggle } from 'features/common/utils';
import { PlaceholderList } from 'features/common/components';

import { IPropTypes } from './PlaceholderListWrapper.types';
import styles from './PlaceholderListWrapper.module.scss';

export function PlaceholderListWrapper(props: IPropTypes) {
    const {
        isLoading,
        children,
        className,
        toggleOnTimeSpan,
        toggleOffTimeSpan,
        ...placeholderListProps
    } = props;
    const isVisible = useDelayedToggle(
        isLoading,
        toggleOnTimeSpan,
        toggleOffTimeSpan,
    );

    return isVisible ? (
        <div className={clsx(styles.PlaceholderListWrapper, className)}>
            <span
                className={styles.PlaceholderListWrapper_children}
                data-testid="HiddenItems"
            >
                {children}
            </span>
            <PlaceholderList
                {...placeholderListProps}
                className={styles.PlaceholderListWrapper_list}
            />
        </div>
    ) : (
        <>{children}</>
    );
}

export default PlaceholderListWrapper;
