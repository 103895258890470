import { Text, Radio } from 'spoton-lib';

import { filtersContentTypeString } from 'features/common/components/FiltersSidebar/FiltersSidebar.utils';

import styles from './FiltersSidebarLowStock.module.scss';
import {
    LowStockLabel,
    LowStockValue,
    IPropTypes,
} from './FiltersSidebarLowStock.types';

export function FiltersSidebarLowStock(props: IPropTypes) {
    const { selectedLowStock, onChange, contentType } = props;

    return (
        <div className={styles.FiltersSidebarLowStock}>
            <Text className={styles.FiltersSidebarLowStock_header}>
                Select if you want to filter your{' '}
                {filtersContentTypeString[contentType]}s by low stock
            </Text>
            <form
                onSubmit={(e) => e.preventDefault()}
                className={styles.FiltersSidebarLowStock_form}
            >
                <div className={styles.Radio}>
                    <Radio
                        checked={selectedLowStock === LowStockValue.true}
                        value={LowStockValue.true}
                        label={LowStockLabel.true}
                        onChange={() => onChange(LowStockValue.true)}
                        className={styles.Radio_item}
                        data-testid="FiltersSidebarLowStockRadioTrue"
                    />
                </div>
                <div className={styles.Radio}>
                    <Radio
                        checked={selectedLowStock === LowStockValue.false}
                        value={LowStockValue.false}
                        label={LowStockLabel.false}
                        onChange={() => onChange(LowStockValue.false)}
                        className={styles.Radio_item}
                        data-testid="FiltersSidebarLowStockRadioFalse"
                    />
                </div>
            </form>
        </div>
    );
}

export default FiltersSidebarLowStock;
