import { useField } from 'formik';
import { Text, IconButton, colors } from 'spoton-lib';
import clsx from 'clsx';

import { IProductOption } from 'features/products/types';
import { DraggableWrapper } from 'features/common/components/DraggableWrapper';
import { DnDIcon } from 'features/common/components/DnDIcon/DnDIcon.component';
import { useDeletePrompt } from 'features/products/components/ProductOptions/hooks';

import { IPropsType } from './ProductAttribute.types';
import styles from './ProductAttribute.module.scss';

export function ProductAttribute({
    onRemoveClick,
    name,
    index,
    isDragDisabled,
    attributeKey,
    isPageEditing,
}: IPropsType) {
    const { deletePromptElement, setDeletePromptOpen } =
        useDeletePrompt(onRemoveClick);
    const [{ value }, , { setValue }] = useField<IProductOption>(name);

    return (
        <>
            <DraggableWrapper
                draggableId={`draggable-attribute-${attributeKey}`}
                index={index}
                key={attributeKey}
                isDragDisabled={isDragDisabled}
                className={clsx(
                    styles.Wrapper,
                    isPageEditing && styles.Wrapper___edit,
                )}
            >
                {({ dragHandleProps }) => (
                    <>
                        <div>
                            <div className={styles.Controls}>
                                <div {...dragHandleProps} tabIndex={-1}>
                                    <DnDIcon isDisabled={isDragDisabled} />
                                </div>
                                <div className={styles.Controls__name}>
                                    <Text type="sub1">{value.name}</Text>
                                </div>
                                <IconButton
                                    name="EditIcon"
                                    alt="Edit product option icon"
                                    onClick={() =>
                                        setValue({ ...value, isEditing: true })
                                    }
                                    disableBorder
                                    size={26}
                                    color={colors.primary50}
                                />
                            </div>
                            <div className={styles.OptionsTags}>
                                {value.values.map(({ value, key }) => (
                                    <div
                                        key={value || key}
                                        className={styles.OptionsTags__wrapper}
                                    >
                                        <div
                                            className={styles.OptionsTags__tag}
                                            data-testId={`ProductAttribute-${attributeKey}-${value}`}
                                        >
                                            <Text type="p">{value}</Text>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {!isPageEditing && (
                            <div className={styles.Delete}>
                                <IconButton
                                    name="DeleteIcon"
                                    alt={`Delete ${value.name} attribute icon`}
                                    onClick={() => setDeletePromptOpen(true)}
                                    disableBorder
                                    size={30}
                                    buttonSize="large"
                                    color={colors.primary50}
                                />
                            </div>
                        )}
                    </>
                )}
            </DraggableWrapper>

            {deletePromptElement}
        </>
    );
}
