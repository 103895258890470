import * as yup from 'yup';

import {
    IShippingCarrierMethod,
    IShippingCarrier,
    IShippingCarrierService,
    ISelectedServices,
} from 'features/shipping/types';

export const validationSchema = yup.object().shape({
    freeShippingFor: yup
        .string()
        .nullable()
        .when('withMinPrice', {
            is: true,
            then: yup
                .string()
                .required('Carrier service is required')
                .nullable(),
        }),
    minPrice: yup
        .number()
        .nullable()
        .when('withMinPrice', {
            is: true,
            then: yup
                .number()
                .min(0, 'Minimum spent must be greater than or equal to 0.')
                .required('Minimum spent is required')
                .nullable(),
        }),
});

export const getFreeShippingData = (
    realTimeMethods: IShippingCarrierMethod[],
) => {
    const methodWithFreeShipping = realTimeMethods.find(
        (item) => !!item.freeShippingFor,
    );

    const freeShippingData = {
        minPrice: methodWithFreeShipping?.minPrice,
        service: methodWithFreeShipping?.services.find(
            (el) => el.id === methodWithFreeShipping.freeShippingFor,
        )?.name,
    };

    return freeShippingData;
};

export const generateGroupedSelectedServices = (
    realTimeMethodsServices: IShippingCarrierService[],
    shippingCarriers: IShippingCarrier[],
) => {
    return shippingCarriers.reduce<ISelectedServices>(
        (
            acc: ISelectedServices,
            method: IShippingCarrier,
        ): ISelectedServices => {
            const filteredServices = realTimeMethodsServices.filter(
                ({ carrier: { name } }) => name === method.name,
            );
            if (filteredServices.length) {
                acc[method.name] = filteredServices;
            }
            return acc;
        },
        {},
    );
};
