import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import {
    IAttributesGetParams,
    IAttributesGetResponse,
    IAttributeGetByIdResponse,
    IAttributePostResponse,
    IAttributePostPayload,
    IAttributeDeleteResponse,
} from './Attributes.types';

/** Gets a list of attributes */
export const attributesGet = ({
    page = 1,
    pageSize,
    phrase = undefined,
}: IAttributesGetParams) =>
    axios
        .get<IAttributesGetResponse>(`/api/v1/attributes/`, {
            params: {
                page,
                pageSize,
                q: phrase,
            },
        })
        .then(getData);

/** Gets a attribute by id */
export const attributeGetById = (id: string) =>
    axios
        .get<IAttributeGetByIdResponse>(`/api/v1/attributes/${id}/`)
        .then(getData);

/** Creates a model */
export const attributeCreate = (attribute: IAttributePostPayload) =>
    axios
        .post<IAttributePostResponse>(`/api/v1/attributes/`, attribute)
        .then(getData);

/** Deletes a model */
export const attributeDelete = (id: string) =>
    axios
        .delete<IAttributeDeleteResponse>(`/api/v1/attributes/${id}/`)
        .then(getData);
