import {
    Text,
    Table,
    Checkbox,
    Index,
    TableCellProps,
    colors,
} from 'spoton-lib';

import { IUnitOfMeasure } from 'features/common/types';

import styles from './UnitsOfMeasurementSelector.module.scss';
import { IPropTypes } from './UnitsOfMeasurementSelector.types';

export function UnitsOfMeasurementSelector(props: IPropTypes) {
    const { data, groupsLabels, onChange } = props;

    const handleCheck = (rowIndex: number, colIndex: number) => {
        const dataCopy = [...data];
        const unitCategoryCopy = { ...dataCopy[rowIndex] };
        const unitsCopy = [...unitCategoryCopy.units];
        const unitCopy = {
            ...unitsCopy[colIndex],
            ...{ isEnabled: !unitsCopy[colIndex].isEnabled }, //toggle
        };

        unitsCopy[colIndex] = unitCopy;
        unitCategoryCopy.units = unitsCopy;
        dataCopy[rowIndex] = unitCategoryCopy;

        onChange(dataCopy);
    };

    const groupLabelCellRenderer = ({ rowIndex }: TableCellProps) => {
        const groupName = data[rowIndex].name;

        return groupsLabels[groupName];
    };

    const renderCheckboxList = (
        rowIndex: number,
        checkboxData: IUnitOfMeasure[],
    ) => {
        return checkboxData.map((el, index) => (
            <Checkbox
                key={el.name}
                checked={el.isEnabled}
                name={el.name}
                value={el.name}
                label={el.displayName}
                onChange={() => handleCheck(rowIndex, index)}
                className={`
                    ${styles.Checkbox} ${
                    el.isEnabled ? styles.Checkbox_checked : ''
                }`}
            />
        ));
    };

    const renderCollapsibleContent = (rowIndex: number) => {
        const category = data[rowIndex];

        return (
            <div className={styles.CheckboxWrapper}>
                <div className={styles.ContentWrapper_halfWrapper}>
                    {renderCheckboxList(rowIndex, category.units)}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.UnitsOfMeasurementSelector}>
            <Text
                className={styles.UnitsOfMeasurementSelector_title}
                type="p"
                color={colors.base90}
            >
                Say what you offer and the units of measure available in this
                location.
            </Text>
            <Table
                className={styles.UnitsOfMeasurementSelector_table}
                rowGetter={({ index }: Index) => data[index]}
                keyGetter={({ index }: Index) => data[index].name}
                rowCount={data.length}
                variant="secondary"
                isCollapsible
                collapsibleContentHeight={200}
                collapsibleContentGetter={(index: Index) =>
                    renderCollapsibleContent(index.index)
                }
                headerHeight={0}
                rowHeight={56}
                customCollapseIconProps={{
                    buttonProps: {
                        variant: 'secondary',
                        style: { color: colors.primary50 }, // hack for messed up specificity of IconButton inside Table styles
                    },
                    size: 24,
                }}
                row
            >
                <Table.Column
                    label="Variant Name"
                    dataKey="title"
                    cellRenderer={groupLabelCellRenderer}
                    flexGrow={1}
                    width={200}
                />
            </Table>
        </div>
    );
}

export default UnitsOfMeasurementSelector;
