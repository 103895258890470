import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { DuplicationModal } from 'features/common/components/DuplicationModal';
import { DuplicationModalOnApply } from 'features/common/components/DuplicationModal/DuplicationModal.types';
import { IDuplicationField } from 'features/common/components/DuplicationControls/DuplicationControls.types';
import { productsPaths } from 'features/products/routes/products.paths';
import { useChannels } from 'features/common/hooks';

import { prepareDuplicatedProductData } from './ProductDuplication.utils';
import {
    connector,
    IPropTypes,
    IProductDuplicationState,
} from './ProductDuplication.types';
import styles from './ProductDuplication.module.scss';

export function ProductDuplication({
    generalData,
    availabilityData,
    shippingData,
    isOpen,
    onClose,
    onDuplicate,
}: IPropTypes) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { locationActiveChannels } = useChannels();
    const [isDuplicating, setIsDuplicating] = useState(false);

    const handleDuplication: DuplicationModalOnApply = async (
        productName,
        dataToCopy,
    ) => {
        setIsDuplicating(true);

        const newProductData = await prepareDuplicatedProductData(
            productName,
            dataToCopy as IProductDuplicationState,
            {
                general: generalData,
                availability: availabilityData,
                shipping: shippingData,
            },
            locationActiveChannels,
        );
        onDuplicate(newProductData);

        navigate(productsPaths.new, { state: { from: pathname } });

        setIsDuplicating(false);
    };

    const initialState: IProductDuplicationState = {
        images: true,
        prices: true,
        shipping: true,
        stock: true,
        variants: true,
        availableInOS: true,
        availableInPOS: true,
    };

    const duplicationFields: IDuplicationField<
        keyof IProductDuplicationState
    >[] = [
        {
            field: 'images',
            label: 'Images',
        },
        {
            field: 'variants',
            label: 'Variants',
        },
        {
            field: 'prices',
            label: 'Prices',
        },
        {
            field: 'stock',
            label: 'Stock',
        },
    ];

    if (!isOpen) {
        return null;
    }

    return (
        <DuplicationModal
            isLoading={isDuplicating}
            isOpen={isOpen}
            onCancel={onClose}
            title="Duplicate Product"
            initialName={`Copy of ${generalData.name}`}
            initialState={initialState}
            inputLabel="Product name"
            fields={duplicationFields}
            onApply={handleDuplication}
            controlsClassName={styles.ProductDuplication_controls}
        />
    );
}

export default connector(ProductDuplication);
