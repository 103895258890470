import { Modal, Text, Button, IconButton, colors } from 'spoton-lib';

import { DangerSolidIcon } from 'features/common/assets/icons';

import { IPropTypes } from './WeightWarningModal.types';
import styles from './WeightWarningModal.module.scss';

export function WeightWarningModal({
    isOpen,
    weightCheckDetails,
    onCancel,
    onSave,
}: IPropTypes) {
    const [introText, ...ranges] = weightCheckDetails.split('.');

    return (
        <Modal
            isOpen={isOpen}
            className={styles.Modal}
            // This fixes a console error "react-modal: App element is not defined"
            appElement={document.getElementById('root') ?? undefined}
        >
            <IconButton
                name="CloseIcon"
                alt="close"
                size={24}
                color={colors.black}
                onClick={onCancel}
                className={styles.Modal_closeIcon}
                variant="secondary"
            />
            <div className={styles.Container}>
                <DangerSolidIcon className={styles.Container_icon} />
                <Text type="h5">You entered a heavy shipping weight</Text>
            </div>
            <Text className={styles.Text} as="p">
                {introText}.
            </Text>

            {ranges.length ? (
                <ul data-testid="RangesList" className={styles.List}>
                    {ranges.filter(Boolean).map((el) => (
                        <li key={el} className={styles.List_item}>
                            <Text as="p" className={styles.Text}>
                                {el.trim()}
                            </Text>
                        </li>
                    ))}
                </ul>
            ) : null}

            {!!onSave && (
                <div className={styles.ButtonContainer}>
                    <Button
                        variant="secondary"
                        className={styles.ButtonContainer_cancelBtn}
                        onClick={onCancel}
                    >
                        Keep editing
                    </Button>
                    <Button
                        variant="primary"
                        className={styles.ButtonContainer_saveBtn}
                        onClick={onSave}
                    >
                        Save anyway
                    </Button>
                </div>
            )}
        </Modal>
    );
}

export default WeightWarningModal;
