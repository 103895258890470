import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { nestedPath } from 'features/common/utils/routing.utils';
import { ErrorRoutes } from 'features/errors/routes';
import { App } from 'features/omnichannel/components';
import { ErrorPage404 } from 'features/errors/components';
import { useFlags } from 'features/common/hooks';
import { PageLoader } from 'features/common/components';

import { omnichannelLocalPaths } from './omnichannel.paths';

/* eslint-disable @typescript-eslint/naming-convention */
const ProductsRoutes = lazy(
    () => import('features/products/routes/ProductsRoutes.component'),
);

/* @TODO: [Product Form Formik] Remove additional routing within last task */
const ProductsRoutesNew = lazy(
    () => import('features/products/routes/ProductsRoutesNew.component'),
);

const ServicesRoutes = lazy(
    () => import('features/services/routes/ServicesRoutes.component'),
);

const CategoriesRoutes = lazy(
    () => import('features/categories/routes/CategoriesRoutes.component'),
);

const SettingsRoutes = lazy(
    () => import('features/settings/routes/SettingsRoutes.component'),
);

const VendorsRoutes = lazy(
    () => import('features/vendors/routes/VendorsRoutes.component'),
);
/* eslint-enable @typescript-eslint/naming-convention */

export function ProtectedRoutes() {
    const { isVendorsManagementPageEnabled, isProductFormikRefactorVisible } =
        useFlags();

    return (
        <Routes>
            <Route
                path={nestedPath(omnichannelLocalPaths.error)}
                element={<ErrorRoutes />}
            />
            <Route element={<App />}>
                <Route
                    path={omnichannelLocalPaths.root}
                    element={
                        <Navigate replace to={omnichannelLocalPaths.products} />
                    }
                />
                <Route
                    path={nestedPath(omnichannelLocalPaths.products)}
                    element={
                        <Suspense fallback={<PageLoader />}>
                            <ProductsRoutes />
                        </Suspense>
                    }
                />
                {/* @TODO: [Product Form Formik] Remove additional routing within last task */}
                {isProductFormikRefactorVisible && (
                    <Route
                        path={nestedPath(omnichannelLocalPaths.newProducts)}
                        element={
                            <Suspense fallback={<PageLoader />}>
                                <ProductsRoutesNew />
                            </Suspense>
                        }
                    />
                )}
                <Route
                    path={nestedPath(omnichannelLocalPaths.services)}
                    element={
                        <Suspense fallback={<PageLoader />}>
                            <ServicesRoutes />
                        </Suspense>
                    }
                />
                <Route
                    path={nestedPath(omnichannelLocalPaths.categories)}
                    element={
                        <Suspense fallback={<PageLoader />}>
                            <CategoriesRoutes />
                        </Suspense>
                    }
                />
                <Route
                    path={nestedPath(omnichannelLocalPaths.settings)}
                    element={
                        <Suspense fallback={<PageLoader />}>
                            <SettingsRoutes />
                        </Suspense>
                    }
                />
                {isVendorsManagementPageEnabled && (
                    <Route
                        path={nestedPath(omnichannelLocalPaths.vendors)}
                        element={
                            <Suspense fallback={<PageLoader />}>
                                <VendorsRoutes />
                            </Suspense>
                        }
                    />
                )}
                <Route path="*" element={<ErrorPage404 />} />
            </Route>
        </Routes>
    );
}

export default ProtectedRoutes;
