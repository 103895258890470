import {
    IAvailabilityChannel,
    IProductAvailabilityVariantList,
} from 'features/products/types';

export interface IPropTypes {
    /* Callback called when user clicks cancel button */
    onCloseClick(): void;

    productIds: string[];

    /* wether or not modal is open */
    isOpen: boolean;

    channels: IAvailabilityChannel[];

    /* Callback called when user clicks apply button */
    onSaveClick(data: IProductAvailabilityVariantList): void;
}

export enum IProductAvailabilityModalPage {
    selection = 'selection',
    confirmation = 'confirmation',
}
