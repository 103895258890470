import { Text, Input, Dropdown, Banner } from 'spoton-lib';
import { Field, useFormikContext } from 'formik';

import { IDropdownOption, NumberFormatField } from 'features/common';
import {
    DIMENSION_UNITS_OPTIONS,
    WEIGHT_UNITS_OPTIONS,
} from 'features/common/constants';
import { ICustomPackageForm } from 'features/shipping/components';

import { IEditCustomPackageProps } from './EditCustomPackage.types';
import styles from './EditCustomPackage.module.scss';

const dropdownStyles = {
    menu: () => ({
        maxHeight: '10rem',
    }),
    menuList: () => ({
        maxHeight: '10rem',
    }),
};

export function EditCustomPackage({
    packageCombinedGirth,
    warningText,
}: IEditCustomPackageProps) {
    const { touched, errors, values, setFieldValue } =
        useFormikContext<ICustomPackageForm>();

    return (
        <div className={styles.Form}>
            <div className={styles.FormBlock}>
                <Text isBold className={styles.FormBlock_title}>
                    Package Name
                </Text>
                <div className={styles.FieldsWrapper}>
                    <Field
                        as={Input}
                        name="name"
                        className={styles.FieldsWrapper_field}
                        placeholder="E.g. T-Shirt Box"
                        isValid={touched.name ? !errors.name : true}
                        secondaryCondition={
                            touched.name ? errors.name : undefined
                        }
                        clearable={false}
                        data-testid="NameInput"
                    />
                </div>
            </div>
            <div className={styles.FormBlock}>
                {warningText && (
                    <Banner
                        variant="danger"
                        message={warningText}
                        className={styles.FormBlock_warningBanner}
                    />
                )}
                <Text isBold className={styles.FormBlock_title}>
                    Dimensions
                </Text>
                {packageCombinedGirth}
                <div className={styles.FieldsWrapper}>
                    <NumberFormatField
                        label="Length"
                        name="length"
                        className={styles.FieldsWrapper_field}
                        placeholder="0"
                        thousandSeparator={false}
                        shouldShowInstantError={false}
                    />
                    <NumberFormatField
                        label="Width"
                        name="width"
                        className={styles.FieldsWrapper_field}
                        placeholder="0"
                        thousandSeparator={false}
                        shouldShowInstantError={false}
                    />
                    <NumberFormatField
                        label="Height"
                        name="height"
                        className={styles.FieldsWrapper_field}
                        placeholder="0"
                        thousandSeparator={false}
                        shouldShowInstantError={false}
                    />
                    <Dropdown
                        options={DIMENSION_UNITS_OPTIONS}
                        onChange={(option: IDropdownOption) =>
                            setFieldValue('distanceUnit', option.value)
                        }
                        className={styles.FieldsWrapper_field}
                        value={DIMENSION_UNITS_OPTIONS.find(
                            (opt) => opt.value === values.distanceUnit,
                        )}
                        isSearchable={false}
                        styles={dropdownStyles}
                    />
                </div>
            </div>
            <div className={styles.FormBlock}>
                <Text isBold className={styles.FormBlock_title}>
                    Weight when empty (optional)
                </Text>
                <div className={styles.FieldsWrapper}>
                    <NumberFormatField
                        label=""
                        name="weight"
                        className={styles.FieldsWrapper_field}
                        placeholder="0"
                        thousandSeparator={false}
                        data-testid="WeightInput"
                        shouldShowInstantError={false}
                    />
                    <Dropdown
                        options={WEIGHT_UNITS_OPTIONS}
                        onChange={(option: IDropdownOption) =>
                            setFieldValue('weightUnit', option.value)
                        }
                        className={styles.FieldsWrapper_field}
                        value={{
                            value: values.weightUnit,
                            label: values.weightUnit,
                        }}
                        isSearchable={false}
                        styles={dropdownStyles}
                        menuPlacement="top"
                    />
                </div>
            </div>
        </div>
    );
}

export default EditCustomPackage;
