/**
 * We need to check in what environment the code is run in, because import.meta.env is only available from the vite
 * environment while Jest is using babel. Although the Babel `babel-preset-vite` plugin copies everything from
 * import.meta to process.env, it does not download variables from .env. But these variables appear in process.env
 * so we need to merge these two objects to have access to the variables from .env and the required variables built
 * into Vite, such as import.meta.env.MODE and others.
 */
const devEnv =
    import.meta.env.MODE === 'test'
        ? { ...import.meta.env, ...process.env }
        : import.meta.env;

const env = import.meta.env.PROD ? (window as any)._env_ : devEnv;

/**
 * A helper for getting an env variable.
 *
 * When our project gets built for production we switch from the usual build
 * time ENV vars to run time variables injected via our build scripts, nginx,
 * and including it in the projects header.
 *
 * This allows us to set ENV vars whenever we want without the need to rebuild
 * and deploy, however it does mean a little extra work to get the right thing.
 * This method helps take out the guesswork, and gives a nice warning if a
 * variable is not set properly.
 *
 * @see /public/index.html
 * @see /env.sh
 */
export function getConfigVar(
    key: string,
): string | number | boolean | undefined {
    if (env[key] === undefined) {
        // don't use logging util because we always want this warning on
        console.warn(`[Warn] Environment variable '${key}' is undefined`);

        return undefined;
    }

    return env[key];
}

/**
 * A helper for getting an env variable which is expected to be a boolean
 */
export function getConfigFlag(key: string): boolean {
    return getConfigVar(key) === 'true';
}
