import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import {
    IProductVariantsLinksGetPayload,
    IProductVariantsLinksGetResponse,
} from './ProductVariants.types';

/** Gets a list of variants links. */
export function productVariantsLinksGet({
    productId,
    page = 1,
}: IProductVariantsLinksGetPayload) {
    return axios
        .get<IProductVariantsLinksGetResponse>(
            `/api/v2/products/${productId}/variants/`,
            {
                params: {
                    page,
                },
            },
        )
        .then(getData);
}
