import * as yup from 'yup';

import { IFlatRateCostData } from './FlatRateShippingCostForm.types';

export const validationSchema: yup.SchemaOf<IFlatRateCostData> = yup
    .object()
    .shape({
        costs: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                fieldId: yup.string(),
                deliveryTime: yup.string(),
                shippingPrice: yup
                    .number()
                    .min(0, 'Shipping price must be greater than or equal to 0')
                    .required('Shipping price is required')
                    .nullable(),
                minSpentPrice: yup
                    .number()
                    .nullable()
                    .when('withMinSpent', {
                        is: true,
                        then: yup
                            .number()
                            .min(
                                1,
                                'Minimum spent must be greater than or equal to 1',
                            )
                            .required('Minimum spent is required')
                            .nullable(),
                    }),
                rateName: yup.string().required('Rate name is required'),
                withMinSpent: yup.bool(),
            }),
        ),
    });
