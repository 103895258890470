import { Text } from 'spoton-lib';

import { ReactComponent as NoResultsImage } from 'features/common/assets/no_results.svg';

import styles from './TableNoResults.module.scss';

export function TableNoResults() {
    return (
        <div className={styles.TableNoResults} data-testid="TableNoResults">
            <div className={styles.TableNoResults_header}></div>
            <div className={styles.TableNoResults_content}>
                <div className={styles.Content}>
                    <NoResultsImage className={styles.Content_img} />
                    <Text className={styles.Content_text}>
                        Couldn&apos;t find result
                    </Text>
                </div>
            </div>
        </div>
    );
}

export default TableNoResults;
