import { showToast } from 'spoton-lib';

export function showError(error: string | null): void {
    showToast({
        variant: 'danger',
        title: 'Error',
        content: error ?? '',
        autoClose: 4000,
    });
}
