import { useState, useEffect } from 'react';

import { ExportModalViews } from 'features/products/components/ExportModal/ExportModal.types';
import { IProductsExportStartRequest } from 'features/products/api/ProductsExport.types';
import { useExport } from 'features/products/components/ExportModal/hooks';
import { FormattedErrorMessages } from 'features/common/utils/formatError';

export const useExportModal = () => {
    const [exportModalView, setExportModalView] = useState<ExportModalViews>(
        ExportModalViews.form,
    );
    const [errors, setErrors] = useState<FormattedErrorMessages>();

    const onExportInitializedSuccess = () => {
        setErrors(undefined);
        setExportModalView(ExportModalViews.exportInitialized);
    };

    const onExportInitializedFailed = (error?: FormattedErrorMessages) => {
        setErrors(error);
        setExportModalView(ExportModalViews.exportInitializationFailed);
    };

    const {
        startExport,
        userEmail,
        isAcknowledgementStatusDismissed,
        isExportInProgress,
        didExportProcessFail,
        canExportFileBeDownloaded,
        startDownload,
        acknowledgeDismissedExport,
        refetchLastExport,
    } = useExport({
        onExportInitializedSuccess,
        onExportInitializedFailed,
    });

    useEffect(() => {
        if (isExportInProgress) {
            setExportModalView(ExportModalViews.exportInProgress);
        }
    }, [isExportInProgress]);

    const onSubmitExport = ({
        fileType,
        email,
    }: IProductsExportStartRequest) => {
        startExport({ fileType, email });
    };

    return {
        onSubmitExport,
        exportModalView,
        userEmail,
        canShowExportInProgress:
            !isAcknowledgementStatusDismissed && isExportInProgress,
        canShowExportProcessFail:
            !isAcknowledgementStatusDismissed && didExportProcessFail,
        canShowExportFileDownload:
            !isAcknowledgementStatusDismissed && canExportFileBeDownloaded,
        startDownload,
        acknowledgeDismissedExport,
        refetchLastExport,
        errors,
    };
};
