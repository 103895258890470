import * as yup from 'yup';

import { IProductById } from 'features/products/types';
import { cacheTest, uniqueEntityValue } from 'features/common';
import { LabelSize } from 'features/products/components/BarcodePrintingModal/BarcodePrinting.types';

import { IBarcodePrintingFormData } from './BarcodePrintingForm.types';

export const errorMessages = {
    size: {
        nonBlank: `Size can't be blank`,
    },
    upc: {
        nonBlank: `UPC can't be blank`,
        allowed: 'UPC accepts alphanumeric chars.',
        length: 'UPC must have 8-14 characters',
        unique: 'UPC already taken',
    },
    quantity: {
        maximum: `Max. of 50 exceeded`,
    },
    variants: {
        positive: `You must pick at least one variant`,
    },
};

const getUpcsFromOptions = (
    context: yup.TestContext<IBarcodePrintingFormData>,
) => {
    const { parent, options } =
        context as yup.TestContext<IBarcodePrintingFormData>;

    return (options.context?.variants || [])
        .filter((variant) => variant.isSelected && variant.id !== parent.id)
        .map(({ upc }) => upc);
};

export const validationSchema = (): yup.SchemaOf<IBarcodePrintingFormData> => {
    return yup.object().shape({
        size: yup
            .mixed()
            .oneOf([...Object.values(LabelSize)])
            .required(errorMessages.size.nonBlank),
        variants: yup
            .array(
                yup.object().shape({
                    id: yup.string().required(),
                    isSelected: yup.boolean().required(),
                    title: yup.string().required(),
                    price: yup.string().notRequired(),
                    upc: yup.mixed().when('isSelected', {
                        is: true,
                        then: yup
                            .string()
                            .required(errorMessages.upc.nonBlank)
                            .matches(
                                // alphanumeric string
                                /^[a-zA-Z0-9]*$/i,
                                errorMessages.upc.allowed,
                            )
                            .test(
                                'hasMinLength',
                                errorMessages.upc.length,
                                (value = '') =>
                                    value.length >= 8 && value.length <= 14,
                            )
                            .test(
                                'isUpcUnique',
                                errorMessages.upc.unique,
                                cacheTest(async function (value, context) {
                                    if (!value) {
                                        return true;
                                    }

                                    const { parent } =
                                        context as yup.TestContext<IBarcodePrintingFormData>;
                                    const upcs = getUpcsFromOptions(
                                        context as yup.TestContext<IBarcodePrintingFormData>,
                                    );

                                    // workaround not to perform async validation against the value
                                    // that already occurred in the form
                                    // the duplication in the current set is validated in another validator and not cached
                                    if (upcs.includes(value)) {
                                        return true;
                                    }

                                    try {
                                        await uniqueEntityValue<IProductById>(
                                            'product',
                                            'upc',
                                            parent.initialUpc,
                                            [],
                                            {
                                                errorMessage:
                                                    errorMessages.upc.unique,
                                            },
                                        )(value);

                                        return true;
                                    } catch (err) {
                                        return false;
                                    }
                                }),
                            )
                            .test(
                                'isUpcUniqueInCurrentSet',
                                errorMessages.upc.unique,
                                (value, context) => {
                                    if (!value) {
                                        return true;
                                    }

                                    const upcs = getUpcsFromOptions(
                                        context as yup.TestContext<IBarcodePrintingFormData>,
                                    );

                                    return !upcs.includes(value);
                                },
                            ),
                    }),
                    initialUpc: yup.mixed().optional(),
                    quantity: yup.mixed().when('isSelected', {
                        is: true,
                        then: yup
                            .mixed()
                            .test(
                                'maxValue',
                                errorMessages.quantity.maximum,
                                (value) => !value || value < 51,
                            ),
                    }),
                }),
            )
            .min(1, errorMessages.variants.positive),
    });
};
