import { useFormikContext, Field, FieldProps } from 'formik';
import { PhoneInput } from 'spoton-lib';

import { IPropTypes } from './PhoneNumberField.types';

export function PhoneNumberField({
    name,
    numberLabel = 'Phone Number',
    placeholder = '000-000-0000',
    ...rest
}: IPropTypes) {
    const { setFieldValue, setFieldTouched } = useFormikContext();

    return (
        <Field name={name}>
            {({ field, meta }: FieldProps) => {
                const isValid = meta.touched ? !meta.error : true;
                const errorText = meta.touched ? meta.error : '';

                return (
                    <PhoneInput
                        value={field.value}
                        data-testid="phone"
                        secondaryCondition={errorText}
                        onChange={(phone) => setFieldValue(field.name, phone)}
                        onBlur={() => setFieldTouched(field.name)}
                        {...{
                            name,
                            numberLabel,
                            isValid,
                            placeholder,
                            ...rest,
                        }}
                    />
                );
            }}
        </Field>
    );
}

export default PhoneNumberField;
