import { useState } from 'react';
import {
    Text,
    SidebarPagePositions,
    Sidebar,
    LoadingOverlay,
    Button,
    colors,
    Switch,
} from 'spoton-lib';

import { Placeholder } from 'features/common/components';
import { useFlags } from 'features/common/hooks';
import {
    generateNumberArray,
    getAssetUrl,
    IUnitOfMeasureGroup,
    IUnitOfMeasureGroupName,
} from 'features/common';
import {
    UnitsOfMeasurementSelectorSidebar,
    SettingsOnboardingContent,
} from 'features/settings/components';
import {
    useGetCurrentMerchantLocation,
    useUnitsOfMeasure,
    useUnitsOfMeasureUpdate,
    useUpdateCurrentMerchantLocation,
} from 'features/common/services';

import styles from './UnitsOfMeasurePage.module.scss';
import { getUnitsOfMeasureGroupUnits } from './UnitsOfMeasurePage.utils';

const groupLabels: Record<IUnitOfMeasureGroupName, string> = {
    [IUnitOfMeasureGroupName.area]: 'By area',
    [IUnitOfMeasureGroupName.distance]: 'By distance',
    [IUnitOfMeasureGroupName.liquidMeasurement]: 'Liquid measurement',
    [IUnitOfMeasureGroupName.time]: 'By time',
    [IUnitOfMeasureGroupName.volume]: 'By volume',
    [IUnitOfMeasureGroupName.weight]: 'By weight',
};

export function UnitsOfMeasurePage() {
    const { unitsOfMeasureGroups, isLoadingUnitsOfMeasure } =
        useUnitsOfMeasure();
    const { updateUnitsOfMeasure, isUpdatingUnitsOfMeasure } =
        useUnitsOfMeasureUpdate();

    const { isAllowFractionalQuantitiesEnabled } = useFlags();
    const { getCurrentMerchantLocationLoaded } =
        useGetCurrentMerchantLocation();
    const { allowFractionalQuantities } = getCurrentMerchantLocationLoaded();

    const { updateCurrentMerchantLocation } =
        useUpdateCurrentMerchantLocation();

    const [shouldShowEditSidebar, setShouldShowEditSidebar] = useState(false);

    const handleEditClick = () => {
        setShouldShowEditSidebar(true);
    };

    const handleEditSidebarClose = () => {
        setShouldShowEditSidebar(false);
    };

    const handleUnitsSave = (data: IUnitOfMeasureGroup[]) => {
        updateUnitsOfMeasure(data);
        setShouldShowEditSidebar(false);
    };

    const handleSwitchChange = (isChecked: boolean) => {
        updateCurrentMerchantLocation({
            allowFractionalQuantities: isChecked,
        });
    };

    const renderUnitsOfMeasureGroup = (
        label: string,
        groupName: IUnitOfMeasureGroupName,
    ) => (
        <div className={styles.UnitsOfMeasureGroup}>
            <Text type="p" color={colors.base70}>
                <span className={styles.UnitsOfMeasureGroup_title}>
                    {label}:{' '}
                </span>
                {getUnitsOfMeasureGroupUnits(unitsOfMeasureGroups, groupName)}
            </Text>
        </div>
    );

    const renderUnitsOfMeasure = () => {
        if (isLoadingUnitsOfMeasure) {
            return generateNumberArray(7).map((i) => (
                <Placeholder
                    key={i}
                    lineHeight={32}
                    style={{ marginBottom: '1rem' }}
                />
            ));
        }

        return (
            <div className={styles.UnitsOfMeasure}>
                {renderUnitsOfMeasureGroup(
                    groupLabels[IUnitOfMeasureGroupName.distance],
                    IUnitOfMeasureGroupName.distance,
                )}
                {renderUnitsOfMeasureGroup(
                    groupLabels[IUnitOfMeasureGroupName.area],
                    IUnitOfMeasureGroupName.area,
                )}
                {renderUnitsOfMeasureGroup(
                    groupLabels[IUnitOfMeasureGroupName.volume],
                    IUnitOfMeasureGroupName.volume,
                )}
                {renderUnitsOfMeasureGroup(
                    groupLabels[IUnitOfMeasureGroupName.weight],
                    IUnitOfMeasureGroupName.weight,
                )}
                {renderUnitsOfMeasureGroup(
                    groupLabels[IUnitOfMeasureGroupName.time],
                    IUnitOfMeasureGroupName.time,
                )}
                {renderUnitsOfMeasureGroup(
                    groupLabels[IUnitOfMeasureGroupName.liquidMeasurement],
                    IUnitOfMeasureGroupName.liquidMeasurement,
                )}
            </div>
        );
    };

    return (
        <div className={styles.UnitsOfMeasurePage}>
            {isUpdatingUnitsOfMeasure && <LoadingOverlay />}
            <Sidebar isOpen={shouldShowEditSidebar}>
                <UnitsOfMeasurementSelectorSidebar
                    slideTo={SidebarPagePositions.CENTER}
                    groupsLabels={groupLabels}
                    data={unitsOfMeasureGroups}
                    onBackClick={handleEditSidebarClose}
                    onChange={() => {
                        /** Do nothing */
                    }}
                    onSaveClick={handleUnitsSave}
                />
            </Sidebar>
            <SettingsOnboardingContent
                modalTitle="Manage Units Of Measure"
                description="Select the units of measure your business use."
                modalVideoUrl={getAssetUrl('Settings++Units+of+Measure.mp4')}
            >
                {isAllowFractionalQuantitiesEnabled && (
                    <div className={styles.SwitchWrapper}>
                        <div>
                            <Switch
                                data-testid="fractionalQuantitiesSwitch"
                                onChange={handleSwitchChange}
                                checked={allowFractionalQuantities}
                            />
                        </div>
                        <div className={styles.SwitchWrapper_title}>
                            <Text color={colors.base90}>
                                Allow Fractional Quantities
                            </Text>
                        </div>
                        <div>
                            <Text color={colors.base70}>
                                Enabling this setting allows customers to buy
                                fractions of a product. Ex. 8.5 ounces of
                                yogurt.
                            </Text>
                        </div>
                    </div>
                )}
                <div className={styles.ContentHeader}>
                    <Text type="p" color={colors.base90}>
                        Unit of Measure
                    </Text>
                    <Button
                        onClick={handleEditClick}
                        variant="tertiary"
                        className={styles.EditButton}
                    >
                        Edit
                    </Button>
                </div>
                {renderUnitsOfMeasure()}
            </SettingsOnboardingContent>
        </div>
    );
}

export default UnitsOfMeasurePage;
