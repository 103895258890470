import { categoryKeys } from 'features/categories/services/queryKeys';
import {
    categoriesGet,
    categoryCreate,
    categoryGet,
} from 'features/categories/api/Categories.data';
import { QueryDropdown } from 'features/common/components/QueryDropdown';

import { IPropTypes } from './CategoryDropdown.types';

export function CategoryDropdown(props: IPropTypes) {
    const { value, onChange, ...rest } = props;

    return (
        <QueryDropdown
            label="Category"
            successMessageFactory={(name: string) =>
                `Category "${name}" created successfully`
            }
            errorMessageFactory={(name: string) =>
                `Error while creating "${name}" category. Try again.`
            }
            fetchItems={categoriesGet}
            fetchItem={categoryGet}
            createItem={categoryCreate}
            queryKeys={{
                ...categoryKeys,
                item: categoryKeys.category,
            }}
            value={value}
            onChange={(item) => onChange(String(item.value))}
            itemType="category"
            {...rest}
        />
    );
}

export default CategoryDropdown;
