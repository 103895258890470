import { IPaginatedRequest } from 'features/common';
import { getData } from 'features/common/utils/api.utils';
import { axios } from 'features/common/utils/axios.utils';

import {
    IProductsExportHistoryResponse,
    IProductsExportStartRequest,
    IProductsExportStartResponse,
    ProductsExportAcknowledgeStatus,
} from './ProductsExport.types';

export const startExport = ({ fileType, email }: IProductsExportStartRequest) =>
    axios
        .post<IProductsExportStartResponse>('/api/v1/exports/', {
            fileType,
            email,
            templateName: 'PRODUCTS',
            templateVersion: 'PRODUCTS_V1',
        })
        .then(getData);

export const getExportHistory = ({
    page = 1,
    pageSize = 10,
}: IPaginatedRequest) =>
    axios
        .get<IProductsExportHistoryResponse>('/api/v1/exports/', {
            params: { page, pageSize },
        })
        .then(getData);

export const downloadExportFile = (exportId: string) =>
    axios.get<Blob>(`/api/v1/exports/${exportId}/download/`, {
        responseType: 'blob',
    });

export const acknowledgeExport = (
    exportId: string,
    acknowledgeStatus: ProductsExportAcknowledgeStatus,
) =>
    axios
        .patch(`/api/v1/exports/${exportId}/`, { acknowledgeStatus })
        .then(getData);
