import { hasLength, test } from 'features/common';
import { availabilitySectionErrorMessages } from 'features/products/components/AvailabilitySection/AvailabilitySection.utils';

export const upcValueLength = (shouldUseLegacyValidation?: boolean) =>
    shouldUseLegacyValidation
        ? hasLength({
              length: 12,
              errorMessage: availabilitySectionErrorMessages.upc.lengthLegacy,
          })
        : test({
              regex: /(^[a-zA-Z0-9]{8,14}$)|(^$)/i,
              errorMessage: availabilitySectionErrorMessages.upc.length,
          });
