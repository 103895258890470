import { Icon } from 'spoton-lib';

import { DropdownMenu, IDropdownOption } from 'features/common';
import { useFlags } from 'features/common/hooks';

import { DropdownValue, IPropTypes } from './MoreMenu.types';
import styles from './MoreMenu.module.scss';

export function MoreMenu({ onImport, onExport }: IPropTypes) {
    const { isProductImportEnabled } = useFlags();

    if (!isProductImportEnabled) {
        return null;
    }

    const options: IDropdownOption<DropdownValue, JSX.Element>[] = [
        {
            label: (
                <div className={styles.OptionWrapper}>
                    <Icon
                        className={styles.OptionWrapper_icon}
                        size={24}
                        alt="Import"
                        name="FileImportIcon"
                    />
                    Import
                </div>
            ),
            value: DropdownValue.import,
        },
        {
            label: (
                <div className={styles.OptionWrapper}>
                    <Icon
                        className={styles.OptionWrapper_icon}
                        size={24}
                        alt="Export"
                        name="FileExportIcon"
                    />
                    Export
                </div>
            ),
            value: DropdownValue.export,
        },
    ];

    return (
        <DropdownMenu
            value={null}
            options={options}
            menuPortalTarget={document.body}
            onSelect={({ value }) => {
                if (value === DropdownValue.import) {
                    onImport();
                } else if (value === DropdownValue.export) {
                    onExport();
                }
            }}
            iconSize={24}
            menuPosition="absolute"
            withDividers={false}
            menuWidth="10rem"
            useButtonTrigger
        />
    );
}

export default MoreMenu;
