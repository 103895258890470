import { Text, Icon, colors, Radio } from 'spoton-lib';

import { ShippingCarrier, shippingCarrierLogo } from 'features/common';
import SmallBoxIcon from 'features/common/assets/small_box_icon.svg';
import { IBox } from 'features/shipping/types';

import { IPropTypes } from './CarrierPackageDropdown.types';
import styles from './CarrierPackageDropdown.module.scss';

export function CarrierPackageDropdown(props: IPropTypes) {
    const {
        carrierName,
        boxes,
        onBoxChecked,
        checkedBox,
        expandedCarrier,
        onCarrierExpanded,
    } = props;

    const generateSizeRow = (box: IBox) => {
        let sizeString = '';
        if (box.length) {
            sizeString += `${box.length} x `;
        }
        if (box.width) {
            sizeString += `${box.width} x `;
        }
        if (box.height) {
            sizeString += box.height;
        }
        if (box.distanceUnit) {
            sizeString += ` ${box.distanceUnit}`;
        }
        return sizeString;
    };

    const handleCarrierToggle = (carrierName: string) => {
        onCarrierExpanded(carrierName === expandedCarrier ? '' : carrierName);
    };

    const renderListOfBoxes = () => (
        <div className={styles.CarrierPackageDropdownBoxesList}>
            {boxes.map((box) => (
                <div
                    key={box.name}
                    className={styles.CarrierPackageDropdownBoxesListRow}
                    data-testid="CarrierPackageDropdownItem"
                >
                    <div
                        className={
                            styles.CarrierPackageDropdownBoxesListRow_mainBlock
                        }
                    >
                        <img src={SmallBoxIcon} alt="box icon" />
                        <div
                            className={
                                styles.CarrierPackageDropdownBoxesListRow_textBlock
                            }
                        >
                            <Text type="p">{box.name}</Text>
                            <Text
                                type="label"
                                className={
                                    styles.CarrierPackageDropdownBoxesListRow_size
                                }
                            >
                                {generateSizeRow(box)}
                            </Text>
                        </div>
                    </div>
                    <Radio
                        checked={!!checkedBox && box.name === checkedBox.name}
                        onChange={() => onBoxChecked(box)}
                        data-testid={`${box.slug}Id`}
                    />
                </div>
            ))}
        </div>
    );

    return (
        <div className={styles.CarrierPackageDropdown}>
            <button
                type="button"
                className={styles.CarrierPackageDropdownButton}
                onClick={() => handleCarrierToggle(carrierName)}
                data-testid="CarrierPackageDropdown"
            >
                <div className={styles.CarrierPackageDropdownMainBlock}>
                    <img
                        src={
                            shippingCarrierLogo[carrierName as ShippingCarrier]
                        }
                        alt="carrier logo"
                        className={styles.CarrierPackageDropdownMainBlock_logo}
                    />
                    <div
                        className={
                            styles.CarrierPackageDropdownMainBlock_textBlock
                        }
                    >
                        <Text isBold>{carrierName}</Text>
                        <Text type="label">Show packages</Text>
                    </div>
                </div>
                <Icon
                    name={
                        expandedCarrier === carrierName
                            ? 'CollapseIcon'
                            : 'ExpandIcon'
                    }
                    alt="toggle"
                    size={30}
                    color={
                        expandedCarrier === carrierName
                            ? colors.primary50
                            : colors.black
                    }
                />
            </button>

            {expandedCarrier === carrierName && renderListOfBoxes()}
        </div>
    );
}

export default CarrierPackageDropdown;
