import { TagsInput, Text } from 'spoton-lib';

import { filtersContentTypeString } from 'features/common/components/FiltersSidebar/FiltersSidebar.utils';

import styles from './FiltersSidebarTags.module.scss';
import { IPropTypes } from './FiltersSidebarTags.types';

/** Component for filtering products by tags */
export function FiltersSidebarTags(props: IPropTypes) {
    const { tagList, onTagAdd, onTagRemove, contentType, onClear } = props;

    return (
        <div className={styles.FiltersSidebarTags}>
            <Text className={styles.FiltersSidebarTags_header}>
                Select a collection in which you want to search your{' '}
                {filtersContentTypeString[contentType]}.
            </Text>
            <form
                onSubmit={(e) => e.preventDefault()}
                className={styles.FiltersSidebarTags_form}
                data-testid="FiltersSidebarTags"
            >
                <TagsInput
                    label="Tags"
                    className={styles.FiltersSidebarTags_input}
                    tagList={tagList}
                    onTagAdd={onTagAdd}
                    onTagRemove={onTagRemove}
                    onClear={onClear}
                    data-testid="FiltersSidebarTagsInput"
                    badgeTestId="FiltersSidebarTagsItem"
                    placeholder="Add Tags (separate them by comma)"
                    clearable={false}
                    withTooltips
                />
            </form>
        </div>
    );
}

export default FiltersSidebarTags;
