import { FieldArray, useField } from 'formik';
import { Button } from 'spoton-lib';
import { Droppable } from 'react-beautiful-dnd';

import {
    emptyAttributeOptionField,
    useIsDraggableEnabled,
} from 'features/products/components/ProductOptions/ProductOptions.utils';
import { ProductAttributeOption } from 'features/products/components/ProductOptions/ProductOptionsList/ProductAttributeOptions/ProductAttributeOption';
import { IProductOption } from 'features/products/types';

import { IPropsType } from './ProductAttributeOptions.types';
import styles from './ProductAttributeOptions.module.scss';

export function ProductAttributeOptions({ name, isError }: IPropsType) {
    const optionsFieldName = `${name}.values`;

    const [, , { setValue: setIsProductOptionEditing }] = useField<
        IProductOption['isEditing']
    >(`${name}.isEditing`);

    const [{ value: productOptionValues }, { error }] =
        useField<IProductOption['values']>(optionsFieldName);

    const optionsErrors = error as { value?: string }[] | undefined;

    const shouldDisableDoneButton =
        isError || (optionsErrors ? optionsErrors.some(Boolean) : false);

    const isDragDisabled = !useIsDraggableEnabled(productOptionValues.length);

    return (
        <>
            <Droppable droppableId={name} type={`type-${name}`}>
                {({ innerRef, droppableProps, placeholder }) => (
                    <FieldArray
                        name={optionsFieldName}
                        render={({ remove, push }) => (
                            <div
                                className={styles.ValuesList}
                                ref={innerRef}
                                {...droppableProps}
                            >
                                {productOptionValues.map((option, i) => (
                                    <ProductAttributeOption
                                        key={`${name}${
                                            option.key || option.value
                                        }`}
                                        id={option.key || option.value}
                                        index={i}
                                        isDragDisabled={isDragDisabled}
                                        optionsFieldName={name}
                                        onRemove={remove}
                                    />
                                ))}
                                {placeholder}
                                <Button
                                    variant="tertiary"
                                    type="button"
                                    className={styles.AddOptionButton}
                                    onClick={() =>
                                        push(emptyAttributeOptionField())
                                    }
                                >
                                    + Value
                                </Button>
                            </div>
                        )}
                    />
                )}
            </Droppable>
            <Button
                className={styles.DoneButton}
                variant="secondary"
                type="button"
                disabled={shouldDisableDoneButton}
                onClick={() => setIsProductOptionEditing(false)}
            >
                Done
            </Button>
        </>
    );
}
