import JsBarcode from 'jsbarcode';
import { jsPDF, TextOptionsLight } from 'jspdf';

import {
    LabelSizesMap,
    ILabelSize,
    LabelSize,
    ILabel,
} from './BarcodePrinting.types';

const MM_IN_PT = 0.3527777778;

export const formatBasedOnLength = (upc: string) => {
    if (/^\d{8}$/.test(upc)) return 'EAN8';
    if (/^\d{12}$/.test(upc)) return 'UPC';
    if (/^\d{13}$/.test(upc)) return 'EAN13';
    if (/^\d{14}$/.test(upc)) return 'ITF14';
    throw new Error(`Can't determine barcode format for '${upc}'`);
};

const createBarcode = (upc: string) => {
    const img = document.createElement('img');
    const options = {
        flat: true,
        displayValue: false,
        margin: 0,
        width: 2,
        height: 100,
    };

    // We try/catch and fallback to default barcode format CODE128
    // because JsBarcode does not expose method for actual format detection.
    try {
        JsBarcode(img, upc, { ...options, format: formatBasedOnLength(upc) });
    } catch (e) {
        JsBarcode(img, upc, { ...options, format: 'CODE128' });
    }

    return img;
};

// prepare labels queue for when 2 labels are printed on one page
const prepareLabelsQueue = (labels: ILabel[]) => {
    // each page consists of 2 labels so 1) let's make an array of all labels to print
    const allLabels = labels.reduce<ILabel[]>((all, label) => {
        return all.concat(
            Array.from({ length: label.quantity || 1 }, () => label),
        );
    }, []);

    // 2) split array of all into 2-pieces chunks
    return allLabels.reduce<ILabel[][]>((pairs, label, i) => {
        const chunk = Math.floor(i / 2);
        pairs[chunk] = pairs[chunk] ? pairs[chunk].concat(label) : [label];
        return pairs;
    }, []);
};

const generatePriceTag = (
    pdf: jsPDF,
    { price = '', upc }: ILabel,
    shift = 0,
) => {
    let priceSize: number;
    // the left piece of the price tag
    switch (price?.length) {
        case 14:
        case 13:
            priceSize = 7;
            break;
        case 12:
        case 11:
            priceSize = 9;
            break;
        case 10:
            priceSize = 10;
            break;
        case 9:
            priceSize = 11;
            break;
        default:
            priceSize = 13;
            break;
    }
    pdf.setFontSize(priceSize);
    const priceY = 5 + priceSize * MM_IN_PT * 0.5;

    pdf.text(price, 10.5, priceY + shift, {
        align: 'center',
        lineHeightFactor: 1,
        maxWidth: 18.5,
    });

    // the right piece of the price tag
    pdf.addImage(createBarcode(upc), 'JPEG', 36.5, 2 + shift, 16, 5.5);
    pdf.setFontSize(6);
    pdf.text(upc, 44.5, 9.5 + shift, { align: 'center' });
};

export const generateJewelleryTag = (size: ILabelSize, labels: ILabel[]) => {
    const { width, height } = size;

    const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [width, height],
        userUnit: 300,
    });

    const pairs = prepareLabelsQueue(labels);

    pairs.forEach(([first, second]) => {
        pdf.addPage();
        generatePriceTag(pdf, first);

        if (second) {
            generatePriceTag(pdf, second, 11.25);
        }
    });
    pdf.deletePage(1);

    window.open(pdf.output('bloburl'));
};

// custom renderer for double tiny labels 13x
export const generateTinyLabel = (size: ILabelSize, labels: ILabel[]) => {
    const { width, height, fontSize } = size;

    const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [width, height],
        userUnit: 300,
    });

    const prepareLabel = (
        pdf: jsPDF,
        { price = '', upc }: ILabel,
        shift = 0,
    ) => {
        pdf.text(price, 12.5, 2.75 + shift, { align: 'center' });
        pdf.addImage(
            createBarcode(upc),
            'JPEG',
            2.5,
            3.5 + shift,
            20,
            5,
            undefined,
            'NONE',
        );
        pdf.text(upc, 12.5, 10.75 + shift, { align: 'center' });
    };

    const pairs = prepareLabelsQueue(labels);

    pdf.setFontSize(fontSize);

    pairs.forEach(([first, second]) => {
        pdf.addPage();
        prepareLabel(pdf, first);

        if (second) {
            prepareLabel(pdf, second, 13);
        }
    });
    pdf.deletePage(1);

    window.open(pdf.output('bloburl'));
};

export const labelSizesMap: LabelSizesMap = {
    [LabelSize.dymo99012]: {
        value: LabelSize.dymo99012,
        label: 'DYMO Label 99012: 1⅖” x 3½” | 36mm x 89mm',
        width: 89,
        height: 36,
        fontSize: 10,
        maxLabelLinesCount: 3,
    },
    [LabelSize.dymo1738595]: {
        value: LabelSize.dymo1738595,
        label: 'DYMO Label 1738595: ¾” x 2½” | 19.05mm x 63.50mm',
        width: 19.05,
        height: 63.5,
        fontSize: 8,
        maxLabelLinesCount: 1,
    },
    [LabelSize.dymo30336]: {
        value: LabelSize.dymo30336,
        label: 'DYMO Label 30336: 2⅛” x 1” | 53.97mm x 25.40mm',
        width: 53.97,
        height: 25.4,
        fontSize: 9,
        maxLabelLinesCount: 2,
    },
    [LabelSize.dymo30330]: {
        value: LabelSize.dymo30330,
        label: 'DYMO Label 30330: ¾” x 2” | 19.05mm x 50.80mm',
        width: 19.05,
        height: 50.8,
        fontSize: 9,
        maxLabelLinesCount: 1,
    },
    [LabelSize.dymo30334]: {
        value: LabelSize.dymo30334,
        label: 'DYMO Label 30334: 2¼” x 1¼” | 57.15mm x 31.75mm',
        width: 57.15,
        height: 31.75,
        fontSize: 9,
        maxLabelLinesCount: 3,
    },
    [LabelSize.dymo30299]: {
        value: LabelSize.dymo30299,
        label: 'DYMO Label 30299: ⅜” x ¾” | 10mm x 19mm',
        width: 54,
        height: 22,
        fontSize: 8,
        maxLabelLinesCount: 1,
        showOnlyPrice: true,
        renderer: generateJewelleryTag,
        sampleQuantity: 2,
    },
    [LabelSize.dymo30333]: {
        value: LabelSize.dymo30333,
        label: 'DYMO Label 30333: ½” x 1” | 13mm x 25mm',
        width: 25,
        height: 25,
        fontSize: 7,
        maxLabelLinesCount: 1,
        showOnlyPrice: true,
        renderer: generateTinyLabel,
        sampleQuantity: 2,
    },
};
export const labelSizesOptions = Object.values(labelSizesMap);

const calculateElementsPlacement = ({
    width,
    height,
    fontSize,
    maxLabelLinesCount,
}: Pick<
    ILabelSize,
    'width' | 'height' | 'fontSize' | 'maxLabelLinesCount'
>) => {
    if (width < height) {
        [width, height] = [height, width];
    }
    const margin = 2;
    const sideMargin = 0.066 * width; // magic number so that sides are not clipped
    const fontHeight = fontSize * MM_IN_PT;
    const fontVerticalCorrection = -fontHeight * 0.25;

    return {
        width,
        height,
        fontSize,

        labelX: width / 2,
        labelY: fontHeight + fontVerticalCorrection + margin,
        labelMaxWidth: width - sideMargin * 2,

        imageX: sideMargin,
        imageW: width - sideMargin * 2,

        upcX: width / 2,
        upcY: height + fontVerticalCorrection - margin,

        getImageYH: (computedLabelLinesCount: number) => {
            const labelLinesCount = Math.min(
                computedLabelLinesCount,
                maxLabelLinesCount,
            );

            return {
                imageY: fontHeight * labelLinesCount + margin * 2,
                imageH:
                    height - fontHeight * (labelLinesCount + 1) - margin * 4,
            };
        },
    };
};

const getShortenedLabel = ({
    pdf,
    labelMaxWidth,
    maxLabelLinesCount,
    label,
}: {
    pdf: jsPDF;
    labelMaxWidth: number;
    maxLabelLinesCount: number;
    label: string;
}) => {
    const text: string[] = pdf.splitTextToSize(label, labelMaxWidth);

    if (text.length > maxLabelLinesCount) {
        const shortenedTextToMaxLines = text
            .slice(0, maxLabelLinesCount)
            .join(' ');

        // helps calculate label width with ellipsis
        const textWhichFitToBoundaries = pdf
            // more than three dots to be safe about calculating margins
            .splitTextToSize(`.....${shortenedTextToMaxLines}`, labelMaxWidth)
            .slice(0, maxLabelLinesCount);

        const textWithoutDots = textWhichFitToBoundaries.join(' ').slice(5);

        return `${textWithoutDots}...`;
    }

    return label;
};

export const defaultBarcodesRenderer = (size: ILabelSize, labels: ILabel[]) => {
    const {
        width,
        height,
        fontSize,
        labelX,
        labelY,
        labelMaxWidth,
        imageX,
        imageW,
        upcX,
        upcY,
        getImageYH,
    } = calculateElementsPlacement(size);
    const defaultLabelOptions: TextOptionsLight = {
        align: 'center',
        lineHeightFactor: 1,
        maxWidth: labelMaxWidth,
    };

    const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [width, height],
        userUnit: 300,
    });

    pdf.setFontSize(fontSize);

    labels.forEach(({ upc, title, price, quantity = 1 }) => {
        const barcode = createBarcode(upc);
        const label = [
            ...(!size.showOnlyPrice ? [title] : []),
            ...(price ? [price] : []),
        ].join(' / ');

        Array.from({ length: quantity }, () => {
            const text: string[] = pdf.splitTextToSize(label, labelMaxWidth);

            const { imageY, imageH } = getImageYH(text.length);
            const shortenedLabel = getShortenedLabel({
                pdf,
                label,
                labelMaxWidth,
                maxLabelLinesCount: size.maxLabelLinesCount,
            });

            pdf.addPage();
            pdf.text(shortenedLabel, labelX, labelY, defaultLabelOptions);
            pdf.addImage(barcode, 'JPEG', imageX, imageY, imageW, imageH);
            pdf.text(upc, upcX, upcY, { align: 'center' });
        });
    });
    pdf.deletePage(1);

    window.open(pdf.output('bloburl'));
};

export const generateSampleBarcode = (size: ILabelSize) => {
    const renderer = size.renderer ? size.renderer : defaultBarcodesRenderer;

    renderer(size, [
        {
            title: 'T-Shirt Band / Large / Black',
            price: '$15.99',
            upc: '987654321098',
            quantity: size.sampleQuantity ?? 1,
        },
    ]);
};
