import { useState, useEffect, Dispatch, SetStateAction } from 'react';

import { IUseStorageProps } from './types';

export const useStorage = <T>({
    storage,
    key,
    defaultValue,
}: IUseStorageProps<T>): [T, Dispatch<SetStateAction<T>>] => {
    const [value, setValue] = useState(() =>
        storage.getStoredValue(key, defaultValue),
    );

    useEffect(() => {
        storage.saveValue(key, value);
    }, [key, value]);

    return [value, setValue];
};
