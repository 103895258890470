// Disabled because of conditional rendering we can't use NexusModal proxy
// eslint-disable-next-line no-restricted-imports
import { Button, ModalFooter, Text } from 'spoton-lib';

import SuccessIllustration from 'features/common/assets/import_success.svg';
import WarningsIllustration from 'features/common/assets/import_warnings.svg';
import { NexusModal } from 'features/common';

import { IPropTypes } from './ImportModalComplete.types';
import styles from './ImportModalComplete.module.scss';

export function ImportModalComplete({
    isOpen,
    onBack,
    onClose,
    onOpenWarnings,
    productsCreated = 0,
    variantsCreated = 0,
}: IPropTypes) {
    const productsCount =
        (productsCreated > 1 && `${productsCreated} products`) ||
        (productsCreated === 1 && '1 product') ||
        null;

    const variantsCount =
        (variantsCreated > 1 && `${variantsCreated} variants`) ||
        (variantsCreated === 1 && '1 variant') ||
        null;

    const compoundCounts = [productsCount, variantsCount]
        .filter(Boolean)
        .join(' and ');

    const hasEmptyImport = productsCreated === 0 && variantsCreated === 0;

    const message =
        (hasEmptyImport && 'No products nor variants has been added.') ||
        (!onOpenWarnings && `You have added ${compoundCounts} successfully.`) ||
        `Your ${compoundCounts} were imported, but there were issues.`;

    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Import Complete" />
                <Text className={styles.Modal_paragraph}>{message}</Text>
                {onOpenWarnings && (
                    <button
                        onClick={onOpenWarnings}
                        className={styles.Modal_link}
                        type="button"
                    >
                        View import warnings
                    </button>
                )}
                <img
                    src={
                        onOpenWarnings
                            ? WarningsIllustration
                            : SuccessIllustration
                    }
                    className={styles.Illustration}
                />
                <ModalFooter className={styles.ModalFooter}>
                    <Button
                        className={styles.ModalFooter_button}
                        onClick={onClose}
                        variant={onBack ? 'secondary' : 'primary'}
                    >
                        Close
                    </Button>
                    {onBack && (
                        <Button
                            className={styles.ModalFooter_button}
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    )}
                </ModalFooter>
            </div>
        </NexusModal>
    );
}

export default ImportModalComplete;
