import UspsLogo from 'features/common/assets/usps_logo.png';
import UpsLogo from 'features/common/assets/ups_logo.jpeg';
import FedExLogo from 'features/common/assets/fedex_logo.png';

export enum ShippingCarrier {
    usps = 'USPS',
    ups = 'UPS',
    fedex = 'FedEx',
}

export const generalOrder = 'general';

export const shippingCarrierLogo = {
    [ShippingCarrier.usps]: UspsLogo,
    [ShippingCarrier.ups]: UpsLogo,
    [ShippingCarrier.fedex]: FedExLogo,
};

export interface IServiceOrder {
    [key: string]: string[];
}

enum SpeedClass {
    standard = 'standard',
    economy = 'economy',
    express = 'express',
}

export const servicesOrder: IServiceOrder = {
    [ShippingCarrier.usps]: [
        SpeedClass.economy,
        SpeedClass.standard,
        SpeedClass.express,
    ],
    [generalOrder]: [
        SpeedClass.economy,
        SpeedClass.standard,
        SpeedClass.express,
    ],
};
