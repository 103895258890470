export interface IFee {
    id: string;
    name: string;
    valueType: IFeeValueType; // Always PERCENT since there is only one type of values at the moment
    value: number;
    channels: string[];
    applyTo: IFeeApplyTo;
}

export enum IFeeValueType {
    fixed = 'FIXED',
    percent = 'PERCENT',
}

export enum IFeeApplyTo {
    productsAndServices = 'ANY', // 'ANY' does not include shipping ergo ANY === products And Services
    products = 'PRODUCT',
    services = 'SERVICE',
    shipping = 'SHIPPING',
}
