/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useRef } from 'react';
import { Input, Text } from 'spoton-lib';
import { useFormContext, Controller } from 'react-hook-form';
// Somehow import grid components from spoton-lib breaks the build. Only here. Yes, everywhere else it's fine.
// eslint-disable-next-line no-restricted-imports
import { Col, Container, Row } from 'react-grid-system';

import {
    Section,
    PlaceholderWrapper,
    RichTextEditorController,
    CategoryDropdown,
    TagsInputController,
    ImageUploaderController,
    VendorDropdownController,
    LoyaltySectionController,
    ToggleableSection,
} from 'features/common/components';
import { useFlags, useOptimizedValidation } from 'features/common/hooks/';
import RHFProductOptions from 'features/products/components/RHFProductOptions/RHFProductOptions.component';

import styles from './GeneralSection.module.scss';
import { IPropTypes } from './GeneralSection.types';
import { getGeneralSectionValidators } from './GeneralSection.utils';

export function GeneralSection(props: IPropTypes) {
    const { isLoading, isEditing, isDuplicated, isLoyaltyEnabled } = props;
    const getValidate = useOptimizedValidation();

    const { control, getValues, setValue, watch, formState } = useFormContext();
    const hasVariants = watch('hasVariants');
    const initialValues = useRef({
        name: getValues('name'),
        sku: getValues('sku'),
        upc: getValues('upc'),
    });

    const onToggleVariants = (value: boolean) => {
        setValue('hasVariants', value);
    };

    const { isProductVendorFieldAvailable } = useFlags();

    const generalSectionValidators = getGeneralSectionValidators(
        initialValues.current,
        isDuplicated,
    );

    const validateName = useCallback(
        getValidate(
            'name',
            generalSectionValidators.name.sync,
            generalSectionValidators.name.async,
        ),
        [],
    );

    const validateSKU = useCallback(
        getValidate(
            'sku',
            generalSectionValidators.sku.sync,
            generalSectionValidators.sku.async,
        ),
        [],
    );

    const validateUPC = useCallback(
        getValidate(
            'upc',
            generalSectionValidators.upc.sync,
            generalSectionValidators.upc.async,
        ),
        [],
    );

    return (
        <Section
            title="General"
            description="Add the general information needed for the creation of your product"
            className={styles.GeneralSection}
        >
            <Container className={styles.Container} fluid>
                <Row>
                    <Col md={6}>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    validate: validateName,
                                }}
                                render={({ field, fieldState }) => (
                                    <Input
                                        {...field}
                                        className={styles.NameInput}
                                        data-testid="productNameInput"
                                        label="Product name"
                                        placeholder="Type product name"
                                        variant="rounded"
                                        primaryCondition="Required"
                                        secondaryCondition={
                                            fieldState.error?.message
                                        }
                                        isValid={!fieldState.error}
                                        maxLength={255}
                                        isMultilineSecondaryCondition
                                        required={true}
                                    />
                                )}
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <Text type="label">Description</Text>
                            <RichTextEditorController
                                name="description"
                                placeholder="Type description"
                                className={styles.Description}
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <Controller
                                name="category"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <CategoryDropdown
                                        {...field}
                                        data-testid="productCategoryDropdown"
                                    />
                                )}
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <TagsInputController
                                name="tags"
                                className={styles.TagsInput}
                                label="Tags"
                                data-testid="productTagsInput"
                                badgeTestId="productTag"
                                placeholder="Add tags"
                                secondaryCondition="Separate them by comma"
                                clearable={false}
                                withTooltips
                            />
                        </PlaceholderWrapper>
                    </Col>
                    <Col md={6} className={styles.RightColumn}>
                        <PlaceholderWrapper isLoading={isLoading}>
                            <ImageUploaderController
                                name="images"
                                className={styles.ImageUploader}
                            />
                        </PlaceholderWrapper>
                        <PlaceholderWrapper isLoading={isLoading}>
                            {!hasVariants && (
                                <div className={styles.ProductIds}>
                                    <Controller
                                        name="sku"
                                        control={control}
                                        rules={{
                                            validate: validateSKU,
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Input
                                                {...field}
                                                key="SKU"
                                                label="SKU"
                                                className={
                                                    styles.ProductIds_field
                                                }
                                                variant="rounded"
                                                data-testid="productSkuInput"
                                                placeholder="000000000000"
                                                secondaryCondition={
                                                    fieldState.error?.message
                                                }
                                                isMultilineSecondaryCondition
                                                isValid={!fieldState.error}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="upc"
                                        control={control}
                                        rules={{
                                            validate: validateUPC,
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Input
                                                {...field}
                                                key="UPC"
                                                label="UPC"
                                                className={
                                                    styles.ProductIds_field
                                                }
                                                variant="rounded"
                                                data-testid="productUPCInput"
                                                placeholder="000000000000"
                                                secondaryCondition={
                                                    fieldState.error?.message
                                                }
                                                isMultilineSecondaryCondition
                                                isValid={!fieldState.error}
                                                maxLength={14}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </PlaceholderWrapper>
                        {isProductVendorFieldAvailable && (
                            <PlaceholderWrapper isLoading={isLoading}>
                                <VendorDropdownController
                                    name="vendor"
                                    testId="productVendorDropdown"
                                />
                            </PlaceholderWrapper>
                        )}
                    </Col>
                </Row>
                {isLoyaltyEnabled ? (
                    <Row>
                        <Col>
                            <LoyaltySectionController
                                title="Loyalty"
                                isLoading={isLoading}
                                earnLabel="Customers earn rewards when purchasing this product"
                                redeemLabel="Reward redemption can be applied to the product"
                            />
                        </Col>
                    </Row>
                ) : null}
            </Container>
            <ToggleableSection
                className={styles.Variants}
                isLoading={isLoading}
                isOpen={hasVariants}
                isToggleDisabled={isEditing}
                sectionTitle="Variants"
                switchLabel={
                    <Text type="p">
                        This product has multiple attributes, e.g. different
                        sizes and colors
                    </Text>
                }
                onToggle={onToggleVariants}
            >
                <RHFProductOptions
                    isEditing={isEditing}
                    isLoading={isLoading}
                />
            </ToggleableSection>
        </Section>
    );
}

export default GeneralSection;
