import * as yup from 'yup';

import { IMemberFormData } from 'features/teamMembers/types';
import { isPhoneNumber, cacheTest } from 'features/common/utils';

type ValidationSchema = Pick<IMemberFormData, 'name' | 'email' | 'phoneNumber'>;

export const initialMemberData: IMemberFormData = {
    name: '',
    email: '',
    phoneNumber: '',
    avatar: '',
    isActive: true,
};

export const validationSchema: yup.SchemaOf<ValidationSchema> = yup
    .object()
    .shape({
        name: yup.string().required('Value can not be empty!'),
        email: yup
            .string()
            .email('Invalid email address')
            .required('Value can not be empty!'),
        phoneNumber: yup.string().test(
            'isPhoneNumber',
            'Phone number is invalid',
            cacheTest(async (value) => {
                try {
                    await isPhoneNumber(value);
                } catch (error) {
                    return false;
                }

                return true;
            }),
        ),
    });
