import { Banner, colors, IconButton } from 'spoton-lib';

import { IPropTypes } from './ExportInProgressBanner.types';
import styles from './ExportInProgressBanner.module.scss';

export function ExportInProgressBanner({ onClose }: IPropTypes) {
    return (
        <Banner
            message="Your product catalog export is in progress"
            actions={
                <IconButton
                    name="CloseIcon"
                    disableBorder
                    color={colors.informative80}
                    alt="Close"
                    onClick={onClose}
                />
            }
            variant="informative"
            hideIcon={false}
            className={styles.Banner}
        />
    );
}

export default ExportInProgressBanner;
