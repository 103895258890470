import {
    Text,
    TagsInput,
    Label,
    BadgeVariants,
    IconButton,
    colors,
    Tooltip,
} from 'spoton-lib';

import styles from './ProductKeywords.module.scss';
import { IPropTypes, Tag, IKeywordRefersTo } from './ProductKeywords.types';

/** Component showing product tags picker */
export function ProductKeywords(props: IPropTypes) {
    const { tags, onChange, refersTo = 'products' } = props;

    const infoText: Record<IKeywordRefersTo, string> = {
        products: 'Use descriptive keywords to organize these products.',
        services: 'Use descriptive keywords to organize these services.',
    };

    const selectedTagsText: Record<IKeywordRefersTo, string> = {
        products: 'Tags that these products have in common.',
        services: 'Tags that these services have in common.',
    };

    const handleTagAdd = (tag: Tag) => {
        if (!tags.find((el) => el === tag)) {
            const newTags = [...tags, tag];

            onChange(newTags);
        }
    };

    const handleRemoveTag = (tag: Tag) => {
        const newTags = tags.filter((el) => el !== tag);

        onChange(newTags);
    };

    const tagElements = tags.map(
        (name: string): JSX.Element => (
            <Tooltip
                tooltipContent={name}
                key={name}
                portalTarget={document.body}
                variant="top"
                className={styles.Tooltip}
                tooltipClassName={styles.Tooltip_content}
            >
                <Label
                    text={name}
                    variant={BadgeVariants.BASE}
                    className={styles.TagsContainer_tag}
                    backgroundColor={colors.primary10}
                    textColor={colors.primary50}
                    suffixIcon={
                        <IconButton
                            name="CloseIcon"
                            alt={`remove tag ${name}`}
                            size={36}
                            onClick={(): void => handleRemoveTag(name)}
                            disableBorder
                            color={colors.primary50}
                            className={styles.RemoveIcon}
                        />
                    }
                    data-testid={`tagsBadge-${name}`}
                />
            </Tooltip>
        ),
    );

    return (
        <div className={styles.ProductKeywords}>
            <Text type="p" className={styles.TextInfo}>
                {infoText[refersTo]}
            </Text>
            <TagsInput
                useBadges={false}
                label="Tags"
                onTagAdd={handleTagAdd}
                placeholder="Add Tags (separate them by comma)"
                className={styles.TagsInput}
                data-testid="tagsInput"
            />
            <Text type="p" className={styles.TextInfo}>
                {selectedTagsText[refersTo]}
            </Text>
            <div className={styles.TagsContainer} data-testid="tagsContainer">
                {tagElements}
            </div>
        </div>
    );
}

export default ProductKeywords;
