import {
    useEffect,
    useRef,
    useCallback,
    EffectCallback,
    DependencyList,
    useState,
    Dispatch,
    SetStateAction,
} from 'react';
import { showToast } from 'spoton-lib';
import { matchPath, useLocation } from 'react-router-dom';

import {
    IUserActionRequestState,
    IUserQueryState,
} from 'features/common/types';
import { DebouncedFunc, useDebounce } from 'features/common/hooks';

export function useDidUpdate(
    effect: EffectCallback,
    dependencies: DependencyList = [],
): void {
    const isMounted = useRef(false);
    // The usage of useCallback here is dictated by react-hooks/exhaustive-deps eslint rule
    // effect should be a dependency of useEffect,
    // however it's not possible to merge dependencies and effect without creating a new array
    // useCallback ensures the effect stays up-to-date and satisfies useEffect dependencies
    const memoizedEffect = useCallback(() => effect(), [effect]);

    useEffect(() => {
        if (isMounted.current === true) {
            memoizedEffect();
        } else {
            isMounted.current = true;
        }
    }, dependencies);
}

export function useUserActionRequestHandler(
    requestState: IUserActionRequestState,
    successTitle?: string,
    errorTitle?: string,
): void {
    useDidUpdate(() => {
        if (requestState.successMessage !== null) {
            showToast({
                title: successTitle || 'Success',
                content: requestState.successMessage,
                variant: 'success',
                autoClose: 4000,
            });
            return;
        }

        if (requestState.error !== null) {
            showToast({
                title: errorTitle || 'Error',
                content: requestState.error,
                variant: 'danger',
                autoClose: 4000,
            });
        }
    }, [requestState]);
}

// Same as useUserActionRequestHandler but handles errors only
export function useUserActionRequestErrorHandler(
    requestState: IUserActionRequestState,
    _successTitle?: string,
    errorTitle?: string,
): void {
    useDidUpdate(() => {
        if (requestState.error !== null) {
            showToast({
                title: errorTitle || 'Error',
                content: requestState.error,
                variant: 'danger',
                autoClose: 4000,
            });
        }
    }, [requestState]);
}

// same as useUserActionRequestHandler but for useMutation outputs
export function useUserQueryHandler(
    requestState: IUserQueryState,
    successTitle?: string,
    errorTitle?: string,
): void {
    useDidUpdate(() => {
        if (requestState.status === 'success') {
            showToast({
                title: successTitle || 'Success',
                content: requestState.successMessage ?? '',
                variant: 'success',
                autoClose: 4000,
            });
            return;
        }

        if (requestState.status === 'error') {
            showToast({
                title: errorTitle || 'Error',
                content: requestState.error ?? '',
                variant: 'danger',
                autoClose: 4000,
            });
        }
    }, [requestState.status]);
}

export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export function usePreventUnload(condition = true): void {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();

        // Chrome requires returnValue
        event.returnValue = '';

        return '';
    };

    useEffect(() => {
        if (condition) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [condition]);
}

/**
 * Renders a placeholder in the same order provided placeholder items are ordered in the array
 * It keeps track of currently rendered placeholder and ensures they are rendered repeatedly in that same order
 *
 * This is a result of design requirement where we have placeholders A, B and C and they need to be rendered in the following order:
 *
 * A -> B -> C -> A -> B -> C -> A -> B ... and so on
 *
 * @param placeholders array of placeholders (in the desired order)
 * @returns function that renders the current placeholder
 */
export function useOrderedPlaceholders(
    placeholders: JSX.Element[],
): (elementId: number) => JSX.Element {
    const placeholderIndex = useRef(-1);
    const assignedElements = useRef<Record<number, number>>({});

    const renderPlaceholder = (elementId: number) => {
        if (assignedElements.current[elementId] !== undefined) {
            return placeholders[assignedElements.current[elementId]];
        }

        if (placeholderIndex.current >= placeholders.length - 1) {
            placeholderIndex.current = 0;
        } else {
            placeholderIndex.current += 1;
        }

        // We want to keep track of elements that we already assigned placeholders to in order to prevent placeholders changing on every re-render
        assignedElements.current = {
            ...assignedElements.current,
            [elementId]: placeholderIndex.current,
        };

        return placeholders[placeholderIndex.current];
    };

    return renderPlaceholder;
}

/**
 * Invokes callback when current url search params are matching expected query params.
 * Check is called on first hook call, or when url search params have changed.
 *
 * @param queryParams - expected query params as object
 *   '*' can be used in order to accept any param value
 * @param callback - callback to be called when params are matching
 */
export function useOnQueryParams<QueryParams extends Record<string, string>>(
    queryParams: QueryParams,
    callback: (searchParams: URLSearchParams) => void,
    dependencies: DependencyList = [],
): void {
    const searchString = window.location.search;

    useEffect(() => {
        const searchParams = new URLSearchParams(searchString);
        const hasMatchingParams = Object.entries(queryParams).every(
            ([key, value]) => {
                const queryValue = searchParams.get(key);

                if (queryValue === null) {
                    return false;
                }

                if (value === '*') {
                    return true;
                }

                return queryValue === value;
            },
        );

        if (hasMatchingParams) {
            callback(searchParams);
        }
    }, [...dependencies, searchString]);
}

/**
 * useState hook wrapped with debounce.
 *
 * @param initialState - Initial state for useState hook.
 * @param {number} delay - Timeout for useDebounce hook.
 * @returns [state, delayedSetState]
 */
export const useDebouncedState = <State = undefined>(
    initialState: State,
    delay = 200,
): [
    State,
    DebouncedFunc<Dispatch<SetStateAction<State>>>,
    Dispatch<SetStateAction<State>>,
] => {
    const [state, setState] = useState<State>(initialState);
    const debouncedSetState = useDebounce(setState, delay);

    return [state, debouncedSetState, setState];
};

/**
 * On/off state toggler with delayed state update.
 *
 * @param {boolean} isActive - Pending state. Used as initial state, triggers update when changed.
 * @param {number} toggleOnTimeSpan - Timeout for toggle on state.
 *  Determines delay, after which 'state' is changed when isActive becames `true`.
 * * @param {number} toggleOnTimeSpan - Timeout for toggle off state.
 *  Determines delay, after which 'state' is changed when isActive becames `false`.
 * @returns {boolean} state
 */
export const useDelayedToggle = (
    isActive: boolean,
    toggleOnTimeSpan = 0,
    toggleOffTimeSpan = 200,
): boolean => {
    const [isDelayedActive, setIsDelayedActive] = useState(isActive);
    const [timestamp, setTimestamp] = useState(Date.now());

    useDidUpdate(() => {
        const toggleTimeSpan = isActive ? toggleOnTimeSpan : toggleOffTimeSpan;
        const timestampDiff = Date.now() - timestamp;

        const handleUpdate = () => {
            setTimestamp(Date.now());
            setIsDelayedActive(isActive);
        };

        if (timestampDiff > toggleTimeSpan) {
            handleUpdate();

            return;
        }

        const timeout = setTimeout(
            handleUpdate,
            toggleTimeSpan - timestampDiff,
        );

        return () => {
            clearTimeout(timeout);
        };
    }, [isActive]);

    return isDelayedActive;
};

/**
 * useState<string> with additional value with delayed update.
 *
 * @param initialQuery - Initial query value used in useState and useDebouncedState.
 * @param {number} delay - Timeout for useDebounce hook.
 * @returns [searchValue, query, setSearchValue]
 * searchValue - immediately updated search query.
 * query - delayed search query.
 */
export const useSearchQuery = (
    initialQuery: string,
    delay = 200,
): [string, string, Dispatch<SetStateAction<string>>] => {
    const [searchValue, setSearchValue] = useState<string>(initialQuery);
    const [query, setQuery] = useDebouncedState<string>(searchValue, delay);

    useDidUpdate(() => {
        setQuery(searchValue);
    }, [searchValue]);

    return [searchValue, query, setSearchValue];
};

export const useWindowMatchMedia = (query: string): boolean => {
    const [isMatching, setIsMatching] = useState(
        // eslint-disable-next-line no-restricted-properties
        window.matchMedia(query).matches,
    );

    useEffect(() => {
        // eslint-disable-next-line no-restricted-properties
        const media = window.matchMedia(query);
        if (media.matches !== isMatching) {
            setIsMatching(media.matches);
        }
        const listener = () => setIsMatching(media.matches);
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [query]);

    return isMatching;
};

export const useRouteParams = (path: string) => {
    const { pathname } = useLocation();
    const match = matchPath(path, pathname);
    return match?.params || {};
};
