import { object, string } from 'yup';
import { Banner, colors, ContextualAlertButton, IconButton } from 'spoton-lib';

import { useGetPaginatedProductLinks } from 'features/products/services/products';
import { SessionStorage, useStorage } from 'features/common/hooks/useStorage';
import { LowStockValue } from 'features/common/components/FiltersSidebarLowStock/FiltersSidebarLowStock.types';
import { useSearchParams } from 'features/common/hooks/useSearchParams/useSearchParams';
import { useGetCurrentUser } from 'features/common/services';

import styles from './LowStockBanner.module.scss';

export function LowStockBanner() {
    const { productsNumberOfPages } = useGetPaginatedProductLinks({
        params: {
            pageSize: 1,
            page: 1,
            lowStock: 'true',
        },
    });

    const { getCurrentUserLoaded } = useGetCurrentUser();
    const { id } = getCurrentUserLoaded();

    const key = `wasLowStockBannerDismissed_${id}`;

    const [, setSearchParams] = useSearchParams(
        object({
            lowStock: string(),
        }),
    );

    const [wasLowStockBannerDismissed, setWasLowStockBannerDismissed] =
        useStorage({
            storage: new SessionStorage(),
            key,
            defaultValue: false,
        });

    const onClose = () => setWasLowStockBannerDismissed(true);

    const onFilter = () => {
        setWasLowStockBannerDismissed(true);
        setSearchParams({
            lowStock: LowStockValue.true,
        });
    };

    const shouldShowBanner =
        productsNumberOfPages > 0 && !wasLowStockBannerDismissed;

    return (
        <>
            {shouldShowBanner && (
                <Banner
                    message={`You have ${productsNumberOfPages} ${
                        productsNumberOfPages > 1
                            ? 'items that are'
                            : 'item that is'
                    } in low stock`}
                    actions={
                        <>
                            <ContextualAlertButton
                                onClick={onFilter}
                                variant="warning"
                                data-testid="ShowLowStockItemsButton"
                            >
                                Show items
                            </ContextualAlertButton>
                            <IconButton
                                name="CloseIcon"
                                disableBorder
                                color={colors.warning90}
                                alt="Close"
                                variant="danger"
                                onClick={onClose}
                                data-testid="CloseLowStockItemsBannerButton"
                            />
                        </>
                    }
                    variant="warning"
                    hideIcon={false}
                    className={styles.Banner}
                    data-testid="LowStockBanner"
                />
            )}
        </>
    );
}

export default LowStockBanner;
