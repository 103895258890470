import { Banner, colors, ContextualAlertButton, IconButton } from 'spoton-lib';

import { IPropTypes } from './ExportDownloadBanner.types';
import styles from './ExportDownloadBanner.module.scss';

export function ExportDownloadBanner({ onDownload, onClose }: IPropTypes) {
    return (
        <Banner
            message="Your product catalog export is now ready to download!"
            actions={
                <>
                    <ContextualAlertButton
                        onClick={onDownload}
                        variant="informative"
                    >
                        Download
                    </ContextualAlertButton>
                    <IconButton
                        name="CloseIcon"
                        disableBorder
                        color={colors.informative80}
                        alt="Close"
                        onClick={onClose}
                    />
                </>
            }
            variant="informative"
            hideIcon={false}
            className={styles.Banner}
        />
    );
}

export default ExportDownloadBanner;
