import { IUnit } from 'features/common/types';

export interface IWeightLimitCheckPostPayload {
    weight: IUnit;
}

export enum WeightLimitCheckMessageType {
    exceedRangeLimit = 'Entered weight exceeds the carrier range',
    closeToRangeLimit = 'Entered weight is close to shipping range',
}

export interface IWeightLimitCheckResponse {
    detail?: string;
    message?: WeightLimitCheckMessageType;
}
