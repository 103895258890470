import { useState, useEffect } from 'react';

import { ShippingCarrier } from 'features/common/utils';
import {
    useShippingCarriers,
    useCarrierToggle,
} from 'features/shipping/services/shippingCarriers';

import { IPropTypes, ActiveModalKey } from './types';

export const useCarrier = ({ carrier }: IPropTypes) => {
    const [activeModalKey, setActiveModalKey] = useState<ActiveModalKey>();
    const [shouldCheckFedex, setShouldCheckFedex] = useState(false);

    const {
        refetch: refetchShippingCarriers,
        isRefetching: isRefetchingCarriers,
    } = useShippingCarriers();
    const { toggleCarrier, isTogglingCarrier } = useCarrierToggle();

    const isFedExCarrier = carrier.name === ShippingCarrier.fedex;
    const isCarrierNotConnected = carrier.isActive === null;

    const toggleCarrierAvailability = async () => {
        if (isFedExCarrier && isCarrierNotConnected) {
            await refetchShippingCarriers();
            setShouldCheckFedex(true);
            return;
        }

        try {
            await toggleCarrier({
                carrier: carrier.name.toLocaleLowerCase(),
                active: !carrier.isActive,
            });

            return true;
        } catch {
            // no need to handle error here - serverStateHandler is responsible for displaying toast
            return false;
        }
    };

    const closeModal = () => {
        setActiveModalKey(undefined);
    };

    const openConnectFedexModal = () => {
        setActiveModalKey(ActiveModalKey.connectFedex);
    };

    const openCarrierServicesModal = () => {
        setActiveModalKey(ActiveModalKey.carrierServices);
    };

    const onCarrierServicesAdd = async () => {
        if (!carrier.isActive) {
            const hasBeenActivated = await toggleCarrierAvailability();

            if (!hasBeenActivated) return;
        }

        openCarrierServicesModal();
    };

    useEffect(() => {
        if (!shouldCheckFedex) return;

        if (isFedExCarrier && isCarrierNotConnected) {
            openConnectFedexModal();
        }

        setShouldCheckFedex(false);
    }, [carrier, shouldCheckFedex]);

    return {
        closeModal,
        openCarrierServicesModal,
        isRefetchingCarriers,
        refetchShippingCarriers,
        activeModalKey,
        isFedExCarrier,
        toggleCarrierAvailability,
        isTogglingCarrier,
        onCarrierServicesAdd,
    };
};
