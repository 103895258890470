import { IconButton, colors } from 'spoton-lib';
import { useFormContext } from 'react-hook-form';

import { DnDIcon } from 'features/common/components/DnDIcon';
import { DraggableWrapper } from 'features/common/components/DraggableWrapper';
import { IAttributeOption } from 'features/common/types/AttributeOptions.type';
import { ProductAttributeOptionsDropdown } from 'features/products/components/ProductAttributeOptionsDropdown';
import { IProductOptionValue } from 'features/products/types';
import { useGetAttributes } from 'features/attributes/services/attributes';
import { IProductOptionsFormType } from 'features/products/components/RHFProductOptions/ProductOptions.types';

import styles from './ProductAttributeOption.module.scss';
import { IPropsType } from './ProductAttributeOption.types';

export function ProductAttributeOption({
    id,
    index,
    attributeIndex,
    isDragDisabled,
    optionsFieldName,
    onRemove,
}: IPropsType) {
    const { setValue, watch, formState, getFieldState } =
        useFormContext<IProductOptionsFormType>();
    const productOptionKey = `productOptions.${attributeIndex}` as const;
    const productAttributeOptionKey =
        `${productOptionKey}.values.${index}` as const;

    const productOption = watch(productOptionKey);
    const setProductAttributeOption = (value: IProductOptionValue) => {
        setValue(productAttributeOptionKey, value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };
    const productAttributeOptionValue = productOption.values[index].value;
    const { error } = getFieldState(
        `${productAttributeOptionKey}.value`,
        formState,
    );

    const {
        data: attributeData,
        isLoading,
        isFetched,
    } = useGetAttributes(productOption?.attribute);

    const errorText = formState.isSubmitted ? error?.message : '';

    return (
        <>
            <DraggableWrapper
                className={styles.AttributeInputWrapper}
                key={id}
                draggableId={`${id}`}
                index={index}
                isDragDisabled={isDragDisabled}
            >
                {({ dragHandleProps }) => (
                    <>
                        <div
                            className={styles.AttributeInputWrapper}
                            data-testid={`btn${index}`}
                            {...dragHandleProps}
                            tabIndex={-1}
                        >
                            <DnDIcon isDisabled={isDragDisabled} />
                        </div>
                        <div className={styles.AttributeDropdown}>
                            {productOption?.attribute && (
                                <ProductAttributeOptionsDropdown
                                    allSelected={productOption.values}
                                    attributeOptions={
                                        attributeData
                                            ? attributeData.options
                                            : []
                                    }
                                    attribute={productOption.attribute}
                                    value={productAttributeOptionValue}
                                    isValid={!errorText}
                                    secondaryCondition={errorText}
                                    onChange={({
                                        id,
                                        option,
                                    }: IAttributeOption) =>
                                        setProductAttributeOption({
                                            id,
                                            value: option,
                                        })
                                    }
                                    data-testid={`select-${optionsFieldName}.values.${index}.value`}
                                    isLoading={isLoading && !isFetched}
                                />
                            )}
                        </div>

                        {productOption.values.length > 1 && (
                            <IconButton
                                name="DeleteIcon"
                                alt={`Delete ${productOption.values[index].value} option icon`}
                                onClick={() => onRemove(index)}
                                disableBorder
                                size={27}
                                color={colors.black}
                                buttonProps={{ tabIndex: -1 }}
                            />
                        )}
                    </>
                )}
            </DraggableWrapper>
        </>
    );
}
