import { NetworkError } from 'features/common/components/NetworkError';

import { IPropTypes } from './TableNetworkError.types';
import styles from './TableNetworkError.module.scss';

export function TableNetworkError(props: IPropTypes) {
    return (
        <div className={styles.TableNetworkError}>
            <div className={styles.TableNetworkError_header}></div>
            <div className={styles.TableNetworkError_content}>
                <NetworkError {...props} />
            </div>
        </div>
    );
}

export default TableNetworkError;
