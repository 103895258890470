import { useRef } from 'react';
import debounce from 'lodash/debounce';

import { getValidationError } from 'features/common/utils';

// Async wrapper for debounce which returns a ref to a promise so debounced function can be awaited.
// Standard debounce from lodash is not returning the same promise as underlying function, so it's swallowing await.
// Our validation strategy assumes we should wait for completion of async validation before removing sync error.
function asyncDebounce(func: any, wait: any) {
    const debounced = debounce((resolve, reject, args) => {
        func(...args)
            .then(resolve)
            .catch(reject);
    }, wait);
    return (...args: any[]) =>
        new Promise((resolve, reject) => {
            debounced(resolve, reject, args);
        });
}

interface IControllersRef {
    [key: string]: AbortController;
}

// This hook exposes getter for validate() function which is optimized in terms of sync and async validation.
// Sync validation is always done synchronously, so if there is a sync error it will be evaluated and returned immediately.
// If sync validation doesn't return error, we want to wait for async validation to finish,
// before resolving with error or removing sync error (so we don't experience blinking like: sync error -> no error -> async error).
// Async validation is also debounced (to save number of BE requests done) and we abort any older validation request,
// if there is a new one triggered - so we only keep one, most recent validator as an actively running function.
// This ensures no problems with race condition between old and new validation requests as we can't guarantee BE response time.
// Return value is optimized for React Hook Form which accepts error message or true if field value is correct.
export const useOptimizedValidation = (debounceTime = 300) => {
    // We only need one ref to keep track of most recently created controller

    const controllersRef = useRef<IControllersRef>({});

    const optimizedValidation = async <T,>(
        value: T,
        syncValidation: CallableFunction,
        asyncValidation: CallableFunction,
        signal: AbortSignal,
    ) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise<string | boolean>(async (resolve, reject) => {
            let isAborted = false;
            const onAbort = () => {
                isAborted = true;
                reject();
            };
            signal.addEventListener('abort', onAbort);

            const syncError = await syncValidation(value);
            if (syncError) {
                resolve(syncError);
                return;
            }

            // we need to stop JS function execution to spare BE request even if promise is already aborted
            if (isAborted) return;
            const asyncError = await asyncValidation(value);

            signal.removeEventListener('abort', onAbort);
            if (isAborted) return; // second escape route in case of aborted signal was passed
            resolve(asyncError || true); // true for React Hook Formik means that there is no error
        });
    };

    const validate = <T,>(
        value: T,
        fieldName: string,
        syncValidation: CallableFunction,
        asyncValidation: CallableFunction,
    ) => {
        controllersRef.current[fieldName]?.abort();
        controllersRef.current[fieldName] = new AbortController();
        const signal = controllersRef.current[fieldName].signal;
        return optimizedValidation(
            value,
            syncValidation,
            asyncValidation,
            signal,
        );
    };

    const getValidate = (
        fieldName: string,
        syncValidators: CallableFunction[],
        asyncValidators: CallableFunction[],
    ) => {
        controllersRef.current[fieldName] = new AbortController();
        const syncValidation = async <T,>(value: T) => {
            return getValidationError(value, syncValidators);
        };
        const asyncValidation = asyncDebounce(async <T,>(value: T) => {
            return getValidationError(value, asyncValidators);
        }, debounceTime);
        return <T,>(value: T) =>
            validate(value, fieldName, syncValidation, asyncValidation);
    };

    return getValidate;
};
