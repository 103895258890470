import { z } from 'zod';

import { IPaginatedResponse } from 'features/common';

export enum ProductsExportFileType {
    csv = 'CSV',
    excel = 'XLSX',
}

export enum ProductsExportAcknowledgeStatus {
    downloaded = 'DOWNLOADED',
    inProgressDismissed = 'IN_PROGRESS_DISMISSED',
    downloadedDismissed = 'DOWNLOADED_DISMISSED',
    failedDismissed = 'FAILED_DISMISSED',
}

export enum ProductsExportStatus {
    scheduled = 'SCHEDULED',
    inProgress = 'IN_PROGRESS',
    completed = 'COMPLETED',
    failed = 'FAILED',
}

const productsExportStartRequestSchema = z.object({
    fileType: z.nativeEnum(ProductsExportFileType),
    email: z.string(),
});

export type IProductsExportStartRequest = z.infer<
    typeof productsExportStartRequestSchema
>;

const productsExportUserInfoSchema = z.object({
    id: z.string(),
    username: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    spotonUserId: z.string(),
    email: z.string(),
});

const productsExportStartResponseSchema = z.object({
    fileType: z.nativeEnum(ProductsExportFileType),
    fileName: z.string(),
    file: z.string(),
    acknowledgeStatus: z.union([
        z.nativeEnum(ProductsExportAcknowledgeStatus),
        z.null(),
    ]),
    modified: z.string(),
    started: z.string(),
    finished: z.string(),
    id: z.string(),
    email: z.string(),
    user: productsExportUserInfoSchema,
    status: z.nativeEnum(ProductsExportStatus),
    acknowledgedAt: z.string(),
    created: z.string(),
});

export type IProductsExportStartResponse = z.infer<
    typeof productsExportStartResponseSchema
>;

const productsExportHistorySchema = z.object({
    acknowledgeStatus: z.union([
        z.nativeEnum(ProductsExportAcknowledgeStatus),
        z.null(),
    ]),
    id: z.string(),
    email: z.string(),
    user: productsExportUserInfoSchema,
    status: z.nativeEnum(ProductsExportStatus),
    acknowledgedAt: z.string(),
    created: z.string(),
});

export type IProductsExportHistory = z.infer<
    typeof productsExportHistorySchema
>;

export type IProductsExportHistoryResponse =
    IPaginatedResponse<IProductsExportHistory>;
