import { Text } from 'spoton-lib';

import { NexusModal } from 'features/common';

import { IPropTypes } from './ExportModalInProgress.types';
import styles from './ExportModalInProgress.module.scss';

export function ExportModalInProgress({
    isOpen,
    onClose,
    userEmail,
}: IPropTypes) {
    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Exporting Products" isCentered />
                <Text className={styles.Modal_paragraph}>
                    A product export is currently underway. Once it is complete
                    you will receive an email at <strong>{userEmail}</strong>
                </Text>
                <Text className={styles.Modal_paragraph}>
                    If the email hasn’t arrived within one hour of exporting
                    please call support at (877)-814-4102
                </Text>
                <NexusModal.Footer submitTitle="Close" onSubmit={onClose} />
            </div>
        </NexusModal>
    );
}

export default ExportModalInProgress;
