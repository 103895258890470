import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import { IUserGetCurrentUserDetailsResponse } from './User.types';

export function userGetCurrentUserDetails() {
    return axios
        .get<IUserGetCurrentUserDetailsResponse>(`/api/v1/current-user/`)
        .then(getData);
}
