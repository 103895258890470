import { ILowStockValue } from 'features/common/components/FiltersSidebarLowStock/FiltersSidebarLowStock.types';

export enum IFiltersSidebarContentType {
    products,
    services,
}

export interface IFilterValue {
    id: string;
    label: string;
    value: string;
}

export interface IFiltersSidebarFilterState {
    categories: IFilterValue[];
    channels: IFilterValue[];
    tags: string[];
    lowStock: ILowStockValue;
}
