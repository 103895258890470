import { useEffect, useRef } from 'react';

import {
    IProductsExportHistory,
    ProductsExportAcknowledgeStatus,
    ProductsExportStatus,
} from 'features/products/api/ProductsExport.types';
import {
    acknowledgeExport,
    useGetLastExport,
    usePostExport,
} from 'features/products/services';
import { ProductsExportApi } from 'features/products/api';
import { useFileDownload } from 'features/common/hooks/useFileDownload';

import { IUseExportParams } from './useExport.types';

export function useExport({
    onExportInitializedSuccess,
    onExportInitializedFailed,
}: IUseExportParams) {
    const { lastExport, refetchLastExport } = useGetLastExport();
    const intervalRef = useRef<NodeJS.Timer>();
    const { acknowledgeStatus, status, email, user } =
        lastExport || ({} as IProductsExportHistory);

    const { startExport, userEmail: postExportEmail } = usePostExport({
        onSuccess: onExportInitializedSuccess,
        onError: onExportInitializedFailed,
    });

    const onStartDownload = useFileDownload(
        () => ProductsExportApi.downloadExportFile(lastExport?.id || ''),
        '',
        true,
    );

    const onAcknowledgeDismissedExport = async (
        status: ProductsExportAcknowledgeStatus,
    ) => {
        await acknowledgeExport(lastExport?.id || '', status);
        refetchLastExport();
    };

    const isAcknowledgementStatusDismissed =
        (status === ProductsExportStatus.inProgress &&
            acknowledgeStatus ===
                ProductsExportAcknowledgeStatus.inProgressDismissed) ||
        (status === ProductsExportStatus.failed &&
            acknowledgeStatus ===
                ProductsExportAcknowledgeStatus.failedDismissed) ||
        (status === ProductsExportStatus.completed &&
            acknowledgeStatus ===
                ProductsExportAcknowledgeStatus.downloadedDismissed);

    const isExportInProgress = status === ProductsExportStatus.inProgress;
    const didExportProcessFail = status === ProductsExportStatus.failed;
    const canExportFileBeDownloaded =
        acknowledgeStatus !== ProductsExportAcknowledgeStatus.downloaded &&
        status === ProductsExportStatus.completed;

    const lastExportEmail = email || user?.email;

    useEffect(() => {
        if (isExportInProgress) {
            intervalRef.current = setInterval(refetchLastExport, 10000);
        } else {
            intervalRef.current && clearInterval(intervalRef.current);
        }

        return () => intervalRef.current && clearInterval(intervalRef.current);
    }, [isExportInProgress]);

    return {
        isExportInProgress,
        isAcknowledgementStatusDismissed,
        didExportProcessFail,
        canExportFileBeDownloaded,
        startDownload: onStartDownload,
        startExport,
        userEmail: postExportEmail || lastExportEmail || '',
        acknowledgeDismissedExport: onAcknowledgeDismissedExport,
        refetchLastExport,
    };
}
