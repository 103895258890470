import { IAttributeOption, IDropdownOption } from 'features/common/types';
import { IProductOptionValue } from 'features/products/types';
import {
    getNewItem,
    loadingLabel,
} from 'features/common/components/QueryDropdown/QueryDropdown.utils';

export const getSelectedItem = (
    memoizedItem: IAttributeOption | null,
    selectedItemValue: string | null,
    items: IDropdownOption[],
    isBusy: boolean,
): IDropdownOption | null => {
    if (isBusy) {
        return {
            label: loadingLabel,
            value: '',
        };
    }

    if (!selectedItemValue) {
        return null;
    }

    if (memoizedItem?.option === selectedItemValue) {
        return {
            label: memoizedItem.option,
            value: selectedItemValue,
        };
    }

    const itemFromList = items.find(({ value }) => value === selectedItemValue);

    return (
        itemFromList ?? {
            label: selectedItemValue,
            value: selectedItemValue,
        }
    );
};

const mapItemToDropdownOption = ({
    option,
    id,
}: IAttributeOption): IDropdownOption => ({
    label: option,
    value: String(id),
});

export const getUniqueResults = ({
    selectedElements,
    value,
    options,
}: {
    selectedElements: IProductOptionValue[];
    value: string | null;
    options: IAttributeOption[];
}): IAttributeOption[] => {
    const elementsWithoutSelected = selectedElements.filter(
        (element) => element.value !== value,
    );

    return options.filter(
        (options) =>
            !elementsWithoutSelected.find(
                (element) => element.value === options.option,
            ),
    );
};

export const getItemsList = ({
    selectedElements,
    value,
    options,
    query,
}: {
    selectedElements: IProductOptionValue[];
    value: string | null;
    options: IAttributeOption[];
    query: string;
}) => {
    let baseList: IAttributeOption[];
    let isQueryItemExists = false;

    const items = getUniqueResults({ selectedElements, value, options });

    if (query) {
        baseList = items.filter(
            (item) =>
                item.option.toLowerCase().indexOf(query.toLowerCase().trim()) >
                -1,
        );
        isQueryItemExists = !!options.find(
            (item) => item.option.toLowerCase() === query.toLowerCase(),
        );
    } else {
        baseList = items;
    }

    const itemsList = [
        ...baseList.map(mapItemToDropdownOption),
        ...(query && !isQueryItemExists
            ? [getNewItem({ label: `Create new value "${query}"` })]
            : []),
    ];

    return {
        itemsList,
        isQueryItemExists,
    };
};
