import { useEffect, useState } from 'react';

import {
    Tag,
    IKeywordRefersTo,
} from 'features/common/components/ProductKeywords/ProductKeywords.types';
import ProductKeywords from 'features/common/components/ProductKeywords/ProductKeywords.component';
import { NexusModal } from 'features/common/components';

import styles from './ProductKeywordsModal.module.scss';
import { IPropTypes } from './ProductKeywordsModal.types';

/** Component showing product tags as a modal */
export function ProductKeywordsModal(props: IPropTypes) {
    const {
        isOpen,
        productIds,
        initialTags,
        refersTo = 'products',
        onCancel,
        onApply,
    } = props;

    const [tags, setTags] = useState<Tag[]>(initialTags);

    const selectedText: Record<IKeywordRefersTo, string> = {
        products: `${productIds.length} Products selected`,
        services: `${productIds.length} Services selected`,
    };

    const handleOnChangeTags = (tags: Tag[]): void => {
        setTags(tags);
    };

    const handleOnApplyClick = () => {
        // Add tags
        const tagsToBeAdded: string[] = tags.filter(
            (tag) => !initialTags.includes(tag),
        );

        const tagsToBeRemoved: string[] = initialTags.filter(
            (tag) => !tags.includes(tag),
        );

        onApply(tagsToBeAdded, tagsToBeRemoved);
    };

    useEffect(() => {
        setTags(initialTags);
    }, [initialTags]);

    return (
        <div className={styles.ProductKeywordsModal}>
            <NexusModal
                isOpen={isOpen}
                onRequestClose={onCancel}
                className={styles.Modal}
            >
                <NexusModal.Header title={selectedText[refersTo]} />
                <ProductKeywords
                    tags={tags}
                    onChange={handleOnChangeTags}
                    refersTo={refersTo}
                />
                <NexusModal.Footer
                    submitTitle="Apply Changes"
                    cancelTitle="Cancel"
                    onSubmit={handleOnApplyClick}
                    submit-button-data-testid="addTagsApplyButton"
                    cancel-button-data-testid="addTagsCancelButton"
                />
            </NexusModal>
        </div>
    );
}

export default ProductKeywordsModal;
