import { availableColumnsProducts } from './MainProductsPage.utils';

export interface IPropTypes {
    /**
     * This overrides the default auto width adjustments handled by react-virtualized.
     * It is needed to properly render the table in testing environment (auto width adjustment doesn't work there)
     */
    productsTableWidth?: number;
}

export enum ISearchContextOptions {
    anything = 'Anything',
    productName = 'Product name',
    category = 'Category',
    sku = 'SKU',
}

export type IProductsColumns = typeof availableColumnsProducts[number];
