export interface IPropTypes {
    onUpdateAvailability: () => void;
    onUpdateTags: () => void;
    onPrintBarcodes: () => void;
    onDelete: () => void;
}

export enum DropdownValue {
    barcodesAndLabels = 'barcodesAndLabels',
    delete = 'delete',
}
