import { Text } from 'spoton-lib';

import { NexusModal } from 'features/common';

import { IPropTypes } from './ImportModalInProgress.types';
import styles from './ImportModalInProgress.module.scss';

export function ImportModalInProgress({
    isOpen,
    onClose,
    initiator,
}: IPropTypes) {
    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={styles.Modal}
            shouldReturnFocusAfterClose={false}
        >
            <div className={styles.Modal_content}>
                <NexusModal.Header title="Importing Products" isCentered />
                <Text className={styles.Modal_paragraph}>
                    A product import is currently underway. Once it is complete
                    you will receive an email at <strong>{initiator}</strong>
                </Text>
                <Text className={styles.Modal_paragraph}>
                    If the email hasn’t arrived within one hour of uploading
                    please call support at (877)-814-4102
                </Text>
                <NexusModal.Footer submitTitle="Close" onSubmit={onClose} />
            </div>
        </NexusModal>
    );
}

export default ImportModalInProgress;
