import React from 'react';
import { DefaultOptions, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { IPaginatedResponse } from 'features/common/types';
import { checkIfPageExists } from 'features/common/serverStateHandler/utils';

export const queryDefaultOptions: DefaultOptions['queries'] = {
    staleTime: 30_000,
    // No need to have so aggressive behavior for development
    retry: (failureCount, error) => {
        const shouldRetry = import.meta.env.PROD && failureCount < 3;

        /**
         * We must disable retry if the error is an error from paginated response
         * Needed by `usePaginatedQuery` to properly handle pagination
         */
        return checkIfPageExists(error) ? shouldRetry : false;
    },
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
        const { next } = lastPage as IPaginatedResponse<unknown>;

        if (next) {
            return new URL(next).searchParams.get('page') || undefined;
        }

        return undefined;
    },
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: queryDefaultOptions,
    },
});

export function ReactQueryProvider({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    const devTools = import.meta.env.DEV ? <ReactQueryDevtools /> : null;

    return (
        <QueryClientProvider client={queryClient}>
            {devTools}
            {children}
        </QueryClientProvider>
    );
}
