import { Checkbox } from 'spoton-lib';

import { Section, PlaceholderWrapper } from 'features/common/components';

import { IPropTypes } from './LoyaltySection.types';
import styles from './LoyaltySection.module.scss';

// TODO: remove this component after the product page is moved to formik - CWF-1163
export function LoyaltySection({
    earnLabel,
    redeemLabel,
    canEarn,
    canRedeem,
    onCanEarnChange,
    onCanRedeemChange,
    isLoading = false,
    title = 'Loyalty',
}: IPropTypes) {
    return (
        <Section
            title={title}
            className={styles.LoyaltySection}
            titleTag="sub2"
        >
            <PlaceholderWrapper isLoading={isLoading}>
                <Checkbox
                    checked={canEarn}
                    label={earnLabel}
                    onChange={() => onCanEarnChange(!canEarn)}
                    className={styles.Checkbox}
                    data-testid="LoyaltySectionEarnCheckbox"
                />
                <Checkbox
                    checked={canRedeem}
                    label={redeemLabel}
                    onChange={() => onCanRedeemChange(!canRedeem)}
                    className={styles.Checkbox}
                    data-testid="LoyaltySectionRedeemCheckbox"
                />
            </PlaceholderWrapper>
        </Section>
    );
}

export default LoyaltySection;
