import { IPropTypes } from 'features/common/components/DropdownMenu/DropdownMenu.types';

export enum ShippingMethodAction {
    edit = 'edit',
    delete = 'delete',
    change = 'change',
}

export type MenuOption = {
    action: ShippingMethodAction;
    onSelect: () => void;
};

export type IMoreActionsDropdownProps = Omit<
    IPropTypes,
    'onSelect' | 'options'
> & {
    menuOptions: MenuOption[];
    buttonText?: string;
};
