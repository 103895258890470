import { Avatar } from 'spoton-lib';

export interface IAvatar {
    photoUrl: string;
    initials: string;
}

export interface IPropTypes
    extends Partial<React.ComponentProps<typeof Avatar>> {
    /** Array of avatars to be shown */
    avatars: IAvatar[];

    /** Maximum number of avatars to be shown */
    maxAvatars: number;

    /** Handler fired when user clicks the stack. */
    onClick: () => void;
}

export const defaultProps = {
    className: '',
    avatarColor: '#EFEFEF',
    size: 'xl',
};
