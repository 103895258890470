import { Switch } from 'spoton-lib';
import clsx from 'clsx';

import { PlaceholderWrapper } from 'features/common';
import { ToggleableOnChange } from 'features/common/types/toggleable.types';

import styles from './ToggleableSection.module.scss';
import { IPropTypes } from './ToggleableSection.types';

export function ToggleableSection(props: IPropTypes) {
    const {
        isLoading = false,
        isOpen,
        onToggle,
        sectionTitle,
        switchLabel,
        isToggleDisabled,
        className,
        openContentClassName,
        children,
        'data-testid': testId = 'ToggleableSectionToggle',
    } = props;

    const handleToggle: ToggleableOnChange = (checked) => {
        if (isToggleDisabled) {
            return;
        }

        onToggle(checked);
    };

    return (
        <div className={clsx(styles.ToggleableSection, className)}>
            {sectionTitle && (
                <div className={styles.ToggleableSection_title}>
                    {sectionTitle}
                </div>
            )}
            <div className={styles.ToggleableSection_switchContainer}>
                <PlaceholderWrapper isLoading={isLoading}>
                    <div className={styles.SwitchContainer}>
                        <div className={styles.SwitchContainer_switch}>
                            <Switch
                                checked={isOpen}
                                onChange={handleToggle}
                                disabled={isToggleDisabled}
                                data-testid={testId}
                            />
                        </div>
                        <div className={styles.SwitchContainer_label}>
                            {switchLabel}
                        </div>
                    </div>
                </PlaceholderWrapper>
            </div>
            <PlaceholderWrapper isLoading={isLoading}>
                <div className={styles.ToggleableSection_content}>
                    <div
                        className={clsx(
                            styles.ChildrenContainer,
                            isOpen && styles.ChildrenContainer___open,
                            !isOpen && styles.ChildrenContainer___collapsed,
                            isOpen && openContentClassName,
                        )}
                    >
                        {children}
                    </div>
                </div>
            </PlaceholderWrapper>
        </div>
    );
}

export default ToggleableSection;
