import { useEffect, useState } from 'react';

import { getPageVisited, setVisitedPage } from 'features/common/utils';

// usage: const isPageVisited = usePageVisited('category', currentUserID);
export const usePageVisited = (page: string, id?: string): boolean => {
    const isPageVisitedInitially =
        Number.isInteger(id) && getPageVisited(`${page}_${id}`);

    const [isPageVisited, setIsPageVisited] = useState<boolean>(
        isPageVisitedInitially,
    );

    useEffect(() => {
        if (id) {
            const name = `${page}_${id}`;
            const isVisited = getPageVisited(name);
            setIsPageVisited(isVisited);
            // set the value in the local storage so the next time the hook is called for the view it returns true
            setVisitedPage(name);
        }
    }, [setIsPageVisited, id, page]);

    return isPageVisited;
};
