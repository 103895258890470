type ComparisonObject<T> = Record<keyof T, unknown>;

export const attributesDiffer =
    <
        ObjectType extends Partial<ComparisonObject<ObjectType>>,
        ObjectAttributes extends (keyof ObjectType)[] = (keyof ObjectType)[],
    >(
        atrributes: ObjectAttributes,
    ) =>
    (first: ObjectType, second: ObjectType): boolean =>
        atrributes.some((attribute) => first[attribute] !== second[attribute]);

export const omit = <
    TargetObject,
    TargetKeys extends (keyof TargetObject)[],
    ClearedObject = Omit<TargetObject, TargetKeys[number]>,
>(
    targetObject: TargetObject,
    ...keys: TargetKeys
): ClearedObject => {
    const clearedObject = Object.entries(targetObject).reduce<ClearedObject>(
        (resultObject, [key, value]) => {
            if (!keys.includes(key as keyof TargetObject)) {
                resultObject[key as keyof ClearedObject] =
                    value as ClearedObject[keyof ClearedObject];
            }
            return resultObject;
        },
        {} as ClearedObject,
    );
    return clearedObject;
};

export const pick = <
    TargetObject,
    TargetKeys extends (keyof TargetObject)[],
    ClearedObject = Pick<TargetObject, TargetKeys[number]>,
>(
    targetObject: TargetObject,
    ...keys: TargetKeys
): ClearedObject => {
    const keysToOmit = Object.keys(targetObject).filter(
        (key) => !keys.includes(key as keyof TargetObject),
    ) as (keyof TargetObject)[];

    return omit(targetObject, ...keysToOmit);
};

export const flattenObjectValues = <TargetObject>(
    obj: TargetObject,
    values: any[] = [],
): any[] => {
    return Object.keys(obj).reduce((acc, curr) => {
        if (typeof obj[curr as keyof TargetObject] === 'object') {
            return flattenObjectValues(obj[curr as keyof TargetObject], acc);
        }

        return [...acc, obj[curr as keyof TargetObject]];
    }, values);
};

export const removeEmpty = <T>(obj: T): T =>
    Object.entries(obj)
        .filter(([, v]) => v !== null && v !== undefined)
        .reduce<T>((acc, [k, v]) => ({ ...acc, [k]: v }), {} as T);
