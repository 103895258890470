import { NumberFormatValues } from 'spoton-lib';

import { IChannel } from 'features/common';
import { quantityDropdownUnit } from 'features/common/redux/unitsOfMeasure/unitsOfMeasure.utils';
import { IAvailabilitySectionChannelData } from 'features/common/types/pricingSectionChannelData.type';
import {
    IAvailabilitySectionVariant,
    IPartialProductData,
} from 'features/products/types';

import {
    UNITS_IN_STOCK_MAX_DIGITS,
    UNITS_IN_STOCK_MAX_DECIMAL_PART,
} from './AvailabilitySection.constants';
import { IPropFields } from './AvailabilitySection.types';

export const getInitialAvailabilityData = (
    locationChannels: IChannel[],
    productData?: IPartialProductData,
): IPropFields => {
    // If theres no productData, by default select all channels.
    let channels: IAvailabilitySectionChannelData[] = [];
    let channelsAvailability: string[] = [];
    if (!productData || productData.availability?.channels?.length === 0) {
        channels = locationChannels.map<IAvailabilitySectionChannelData>(
            ({ displayName, id, channelName, openPriceAllowed }) => ({
                formData: {},
                id: String(id),
                displayName,
                channelName,
                openPriceAllowed,
                price: 0,
            }),
        );
        channelsAvailability = locationChannels.map(({ id }) => String(id));
    }

    return {
        hasVariants: false,
        variants: [],
        compareAtPrice: null,
        costPerItem: null,
        isSamePrice: true,
        itemsInStock: 0,
        costPrice: null,
        price: 0,
        isNegativeStockEnabled: true,
        // Select 'quantity' as default unit. Quantity should be always available.
        priceUnit: quantityDropdownUnit.value,
        channels,
        channelsAvailability,
        inactiveVariantOptions: [],
        isLowStockAlertEnabled: false,
        lowStockAlertQuantity: null,
        ...(productData ? productData.availability : {}),
    };
};

export const getVariantsDuplicatedValues = (
    variants: IAvailabilitySectionVariant[],
    field: 'upc' | 'sku',
): string[] => {
    return variants
        .map((variant) => variant[field])
        .filter((field) => Boolean(field))
        .reduce(
            (acc: { unique: Set<string>; duplicates: string[] }, value) => {
                if (acc.unique.has(value)) {
                    acc.duplicates.push(value);
                }
                acc.unique.add(value);
                return acc;
            },
            {
                unique: new Set<string>(),
                duplicates: [],
            },
        ).duplicates;
};

export const getChannelsFromAvailabilityData = (
    { isSamePrice, channelsAvailability, channels, price }: IPropFields,
    locationChannels: IChannel[],
): IAvailabilitySectionChannelData[] => {
    return channelsAvailability.map<IAvailabilitySectionChannelData>(
        (channelId) => {
            const existingChannelData = channels.find(
                ({ id }) => id === channelId,
            );

            if (existingChannelData) {
                if (!isSamePrice) {
                    return existingChannelData;
                }

                return {
                    ...existingChannelData,
                    price,
                };
            }

            const existingChannel = locationChannels.find(
                (channel) => String(channel.id) === channelId,
            );

            return {
                id: channelId,
                displayName:
                    existingChannel?.displayName ?? 'Missing channel data??',
                price,
                channelName:
                    existingChannel?.channelName ?? 'Missing channel data??',
                openPriceAllowed: !!existingChannel?.openPriceAllowed,
            };
        },
    );
};

export const availabilitySectionErrorMessages = {
    sku: {
        unique: 'SKU already taken',
    },
    upc: {
        length: 'UPC must have between 8-14 alphanumeric characters',
        lengthLegacy: 'UPC must be exactly 12 characters long',
        unique: 'UPC already taken',
    },
    quantity: {
        nonBlank: `Quantity can't be blank`,
    },
    price: {
        nonBlank: `Price can't be blank`,
        isPositive: 'Price must be positive',
        isNonNegative: `Price can't be negative`,
        isPositiveForChannelWithOpenPriceDisabled: `Price can't be blank`,
        isNotTooLargeCurrency: 'Must be less than 100 Million',
    },
    costPrice: {
        isPositive: 'Variant cost must be positive',
        isNotTooLargeCurrency: 'Must be less than 100 Million',
    },
    compareAtPrice: {
        isPositive: 'Compare at price must be positive',
        isNotTooLargeCurrency: 'Must be less than 100 Million',
    },
    costPerItem: {
        isPositive: 'Cost per item must be positive',
        isNotTooLargeCurrency: 'Must be less than 100 Million',
    },
    channelsAvailability: {
        hasOnlyChannelsWithOpenPriceAllowed: `Variable Priced Items aren't available on some of selected channels`,
    },
    lowStockAlertQuantity: {
        nonBlank: `Low Stock Alert Threshold can't be blank`,
        isNonNegative: `Low Stock Alert Threshold can't be negative`,
    },
};

const isAllowedIntegerInput = (values: NumberFormatValues) => {
    const hasDecimalPart = values.value.includes('.');
    const isInputInteger =
        Number.isInteger(values.floatValue) && !hasDecimalPart;
    return isInputInteger || values.value === '' || values.value === '-';
};

const areMaxDigitsLimitsSatisfied = (values: NumberFormatValues) => {
    const [integralPart, decimalPart] = values.value.split('.');
    const areDigitsInLimit =
        (integralPart?.length ?? 0) + (decimalPart?.length ?? 0) <=
        UNITS_IN_STOCK_MAX_DIGITS;
    const isDecimalPartInLimit =
        (decimalPart?.length ?? 0) <= UNITS_IN_STOCK_MAX_DECIMAL_PART;
    return areDigitsInLimit && isDecimalPartInLimit;
};

export const areUnitsInStockCorrect = (
    isNegativeStockEnabled: boolean,
    areFractionalQuantitiesEnabled: boolean,
    values: NumberFormatValues,
) => {
    if (!isNegativeStockEnabled && values.formattedValue[0] === '-') {
        return false;
    }
    if (!areMaxDigitsLimitsSatisfied(values)) {
        return false;
    }
    if (!areFractionalQuantitiesEnabled && !isAllowedIntegerInput(values)) {
        return false;
    }
    return true;
};
