import { Text, Checkbox, Table, TableCellProps } from 'spoton-lib';
import cloneDeep from 'lodash/cloneDeep';

import { useFlags } from 'features/common';
import {
    IAvailabilityChannel,
    IAvailabilityChannelsList,
    IProductAvailabilityVariant,
} from 'features/products/types';
import { useOrderedPlaceholders } from 'features/common/utils';
import {
    ProductPlaceholderA,
    ProductPlaceholderB,
    ProductPlaceholderC,
    ProductPlaceholderD,
} from 'features/common/assets/placeholders';
import { TooltipEnhanced } from 'features/common/components';

import { IPropTypes } from './ProductAvailability.types';
import styles from './ProductAvailability.module.scss';
import {
    areAllVariantsSelected,
    hasLastChannelSelected,
    isViolatingOpenPriceSettings,
    isAnyVariantSelected,
    isVariantDisabled,
    areAllVariantFieldsDisabled,
    isAnyNotDisabledVariantSelected,
} from './ProductAvailability.utils';

/** Component showing product availability options */
export function ProductAvailability(props: IPropTypes) {
    const { data, onChange, hasError } = props;
    const { isOpenPriceFeatureAvailable } = useFlags();

    const renderImagePlaceholder = useOrderedPlaceholders([
        <ProductPlaceholderA key="a" />,
        <ProductPlaceholderB key="b" />,
        <ProductPlaceholderC key="c" />,
        <ProductPlaceholderD key="d" />,
    ]);

    //--- Handlers
    const onCheckboxClick = (
        row: number,
        key: keyof IAvailabilityChannelsList,
        isChecked: boolean,
    ) => {
        const dataCopy = cloneDeep(data);
        dataCopy.variants[row].channels[key] = isChecked;

        onChange(dataCopy);
    };

    const onHeaderClickCheckbox = (channel: IAvailabilityChannel) => {
        const isAnyNotDisabledSelected = isAnyNotDisabledVariantSelected(
            isOpenPriceFeatureAvailable,
            data,
            channel,
        );

        const dataCopy = cloneDeep(data);
        dataCopy.variants.forEach((variant) => {
            if (
                !isVariantDisabled(
                    isOpenPriceFeatureAvailable,
                    data,
                    variant,
                    channel,
                )
            ) {
                variant.channels[channel.id] = isAnyNotDisabledSelected
                    ? false
                    : true;
            }
        });

        onChange(dataCopy);
    };

    //--- Renderers
    const renderVariantNameCell = ({
        rowIndex,
    }: TableCellProps): JSX.Element => {
        const product = data.variants[rowIndex];
        return (
            <TooltipEnhanced
                content={product.title}
                tooltipClassName={styles.Tooltip}
                contentClassName={styles.ProductName}
                variant="topLeft"
                contentTagName="span"
            />
        );
    };

    const renderCheckboxCell = (info: any) => {
        const variant: IProductAvailabilityVariant =
            data.variants[info.rowIndex];
        const channelId = `${info.dataKey as keyof IAvailabilityChannelsList}`;
        const isChecked = variant.channels[channelId];
        const isDisabled =
            (isChecked && hasLastChannelSelected(variant)) ||
            isViolatingOpenPriceSettings(
                isOpenPriceFeatureAvailable,
                data,
                variant,
                channelId,
            );

        return (
            <div className={styles.CheckboxCell}>
                <Checkbox
                    checked={isChecked}
                    onChange={() =>
                        onCheckboxClick(info.rowIndex, channelId, !isChecked)
                    }
                    className={styles.Checkbox}
                    data-testid={`productRowCheckbox-${info.rowIndex}-${channelId}`}
                    disabled={isDisabled}
                />
            </div>
        );
    };

    const renderImageCell = ({ rowData, rowIndex }: TableCellProps) => {
        if (!rowData.imgUrl) {
            return <div>{renderImagePlaceholder(rowIndex)}</div>;
        }

        return (
            <img
                src={rowData.imgUrl}
                width="40"
                height="40"
                alt={rowData.productName}
            />
        );
    };

    const renderHeader = (channel: IAvailabilityChannel) => {
        const areAllSelected = areAllVariantsSelected(data, channel);
        const isAnySelected = isAnyVariantSelected(data, channel);

        const isIndeterminate = isAnySelected && !areAllSelected;
        const isChecked = isAnySelected;
        const isDisabled = areAllVariantFieldsDisabled(
            isOpenPriceFeatureAvailable,
            data,
            channel,
        );

        return (
            <div key={channel.id} className={styles.ProductHeader}>
                <Text className={styles.ProductHeader_label}>
                    {channel.label}
                </Text>
                <Checkbox
                    onChange={() => onHeaderClickCheckbox(channel)}
                    checked={isChecked}
                    className={styles.Checkbox}
                    indeterminate={isIndeterminate}
                    data-testid={`productChannelCheckbox-${channel.label}`}
                    disabled={isDisabled}
                />
            </div>
        );
    };

    // Render columns
    const contentColumns = data.channels.map((channel) => (
        <Table.Column
            key={channel.id}
            label={channel.label}
            dataKey={channel.id}
            cellRenderer={(data: any) => renderCheckboxCell(data)}
            width={135}
            flexGrow={1}
            flexShrink={0}
            headerRenderer={() => renderHeader(channel)}
        />
    ));

    return (
        <div className={styles.ProductAvailability}>
            <Table
                variant="secondary"
                rowCount={data.variants.length}
                keyGetter={({ index }: any) => data.variants[index].title}
                rowGetter={({ index }: any) => data.variants[index]}
                rowClassName={styles.TableCell}
                height={350}
                rowHeight={50}
                hasError={hasError}
            >
                <Table.Column
                    dataKey="imgUrl"
                    width={60}
                    flexShrink={0}
                    cellRenderer={renderImageCell}
                />
                <Table.Column
                    label="Product/Variant Name"
                    dataKey="title"
                    cellRenderer={(data: any) => renderVariantNameCell(data)}
                    flexShrink={0}
                    width={210}
                />
                {contentColumns}
            </Table>
        </div>
    );
}

export default ProductAvailability;
