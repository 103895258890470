import { Icon, Text } from 'spoton-lib';

import { ShippingMethodAction } from './MoreActionsDropdown.types';
import styles from './MoreActionsDropdown.module.scss';

const labelsConfig: Record<
    ShippingMethodAction,
    { text: string; iconName: string; iconAlt: string }
> = {
    [ShippingMethodAction.edit]: {
        text: 'Edit rates',
        iconName: 'EditIcon',
        iconAlt: 'edit',
    },
    [ShippingMethodAction.delete]: {
        text: 'Delete rates',
        iconName: 'DeleteIcon',
        iconAlt: 'delete',
    },
    [ShippingMethodAction.change]: {
        text: 'Change shipping method',
        iconName: 'SortHorizontalIcon',
        iconAlt: 'change',
    },
};

const generateLabel = (action: ShippingMethodAction) => {
    const label = labelsConfig[action];

    return (
        <div className={styles.OptionWrapper}>
            <Icon
                className={styles.OptionWrapper_icon}
                size={20}
                alt={label.iconAlt}
                name={label.iconName}
            />
            <Text>{label.text}</Text>
        </div>
    );
};

export const allOptions = Object.values(ShippingMethodAction).map(
    (shippingMethodAction) => ({
        label: generateLabel(shippingMethodAction),
        value: shippingMethodAction,
    }),
);
