import { Button } from 'spoton-lib';
import { Droppable } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import {
    emptyAttributeOptionField,
    useIsDraggableEnabled,
} from 'features/products/components/ProductOptions/ProductOptions.utils';
import { IProductOptionsFormType } from 'features/products/components/RHFProductOptions/ProductOptions.types';

import { IPropsType } from './ProductAttributeOptions.types';
import styles from './ProductAttributeOptions.module.scss';
import { ProductAttributeOption } from './ProductAttributeOption';

export function ProductAttributeOptions({ name, index, isError }: IPropsType) {
    const optionsFieldName = `productOptions.${index}.values` as const;

    const { control, setValue, watch, formState, getFieldState } =
        useFormContext<IProductOptionsFormType>();
    const { append, remove } = useFieldArray({
        name: optionsFieldName,
        control,
    });

    const setIsProductOptionEditing = (value: boolean) => {
        setValue(`productOptions.${index}.isEditing`, value);
    };
    const productOptionValues = watch(optionsFieldName);
    const { error } = getFieldState(optionsFieldName, formState);

    const optionsErrors = error as { value?: string }[] | undefined;

    const shouldDisableDoneButton =
        isError || (optionsErrors ? optionsErrors.some(Boolean) : false);

    const isDragDisabled = !useIsDraggableEnabled(productOptionValues.length);

    return (
        <>
            <Droppable droppableId={name} type={`type-${name}`}>
                {({ innerRef, droppableProps, placeholder }) => (
                    <div
                        className={styles.ValuesList}
                        ref={innerRef}
                        {...droppableProps}
                    >
                        {productOptionValues.map((option, i) => (
                            <ProductAttributeOption
                                key={`${name}${option.key || option.value}`}
                                id={option.key || option.value}
                                attributeIndex={index}
                                index={i}
                                isDragDisabled={isDragDisabled}
                                optionsFieldName={`productOptions.${index}`}
                                onRemove={remove}
                            />
                        ))}
                        {placeholder}
                        <Button
                            variant="tertiary"
                            type="button"
                            className={styles.AddOptionButton}
                            onClick={() => append(emptyAttributeOptionField())}
                        >
                            + Value
                        </Button>
                    </div>
                )}
            </Droppable>
            <Button
                className={styles.DoneButton}
                variant="secondary"
                type="button"
                disabled={shouldDisableDoneButton}
                onClick={() => setIsProductOptionEditing(false)}
            >
                Done
            </Button>
        </>
    );
}
