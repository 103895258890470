import { Button } from 'spoton-lib';

import maintenanceSrc from 'features/common/assets/maintenance.svg';
import ErrorPage from 'features/errors/components/ErrorPage/ErrorPage.component';

import styles from './MaintenancePage.module.scss';

export function MaintenancePage() {
    return (
        <ErrorPage
            title="Temporarily Down for Maintenance"
            imageSrc={maintenanceSrc}
            message="We expect to be back in a couple hours. Thank you for your patience!"
            imageAlt="Temporarily Down for Maintenance"
            imageClassName={styles.Image}
            actions={
                <Button
                    variant="secondary"
                    className={styles.Button}
                    href="mailto:support@spoton.com"
                >
                    Email Support
                </Button>
            }
        />
    );
}

export default MaintenancePage;
