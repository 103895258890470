import { Text } from 'spoton-lib';

import { NexusModal } from 'features/common/components';

import { IPropTypes } from './NexusPrompt.types';

/**
 * This is an UI adapter that should be used instead of spotonLib's Prompt.
 * Should be used for prompts including only text.
 * Based upon NexuxModal adapter, guarantees compatibility with Nexus Guidelines.
 */
export function NexusPrompt(props: IPropTypes) {
    const {
        isOpen,
        title,
        message,
        onRequestClose,
        onSubmit,
        onCancel,
        submitButtonText,
        cancelButtonText,
        className,
        isDanger = false,
        isSubmitLoading = false,
        'submit-button-data-testid': submitButtonTestId,
        'cancel-button-data-testid': cancelButtonTestId,
    } = props;

    return (
        <NexusModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={className}
        >
            <NexusModal.Header title={title} />
            <Text>{message}</Text>
            <NexusModal.Footer
                isDanger={isDanger}
                onSubmit={onSubmit}
                onCancel={onCancel}
                isSubmitLoading={isSubmitLoading}
                submitTitle={submitButtonText}
                cancelTitle={cancelButtonText}
                submit-button-data-testid={submitButtonTestId}
                cancel-button-data-testid={cancelButtonTestId}
            />
        </NexusModal>
    );
}

export default NexusPrompt;
