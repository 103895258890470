import { useState, useEffect } from 'react';
import {
    Modal,
    Text,
    IconButton,
    Button,
    Checkbox,
    Tooltip,
    Icon,
    colors,
} from 'spoton-lib';
import { Form, useFormik, FormikProvider } from 'formik';

import {
    CarrierPackageDropdown,
    AddCustomPackageDropdown,
    EditCustomPackage,
} from 'features/shipping/components';
import { IBox } from 'features/shipping/types';
import {
    DEFAULT_DIMENSION_UNIT_OPT,
    DEFAULT_WEIGHT_UNIT_OPT,
} from 'features/common/constants';
import {
    useSaveShippingPackage,
    useEditShippingPackage,
} from 'features/shipping/services/shippingPackages';

import { ICustomPackageForm, IPropTypes } from './AddPackagesModal.types';
import {
    convertBoxToPackageType,
    getFormValues,
    customPackageValidationSchema,
    carrierPackageValidationSchema,
} from './AddPackagesModal.utils';
import { usePackageCombinedGirth } from './hooks';
import styles from './AddPackagesModal.module.scss';

const defaultCustomPackage: ICustomPackageForm = {
    slug: '',
    length: '',
    width: '',
    height: '',
    distanceUnit: DEFAULT_DIMENSION_UNIT_OPT.value,
    weight: '',
    weightUnit: DEFAULT_WEIGHT_UNIT_OPT.value,
    name: '',
    isCarrierPackage: false,
    isDefault: false,
    id: null,
};

export function AddPackagesModal(props: IPropTypes) {
    const {
        isOpen,
        onClose,
        carrierBoxes,
        packageToBeEdited,
        isAbleToSetDefaultPackage = true,
    } = props;
    const isEditMode = !!packageToBeEdited;

    const [checkedBox, setCheckedBox] = useState<IBox | null>(null);
    const [expandedCarrier, setExpandedCarrier] = useState('');
    const [isSetAsDefault, setIsSetAsDefault] = useState(false);
    const { saveShippingPackage, isSavingShippingPackage } =
        useSaveShippingPackage({
            onSuccess: onClose,
        });
    const { editShippingPackage, isEditingShippingPackage } =
        useEditShippingPackage({ onSuccess: onClose });

    const valuesForCheckedPackageType = checkedBox
        ? getFormValues(convertBoxToPackageType(checkedBox))
        : defaultCustomPackage;

    const valuesForSelectedCarrier =
        expandedCarrier === 'custom'
            ? defaultCustomPackage
            : valuesForCheckedPackageType;

    const onSubmit = async (values: ICustomPackageForm) => {
        const data = convertBoxToPackageType(
            values,
            isEditMode ? false : expandedCarrier !== 'custom',
            isEditMode ? values.isDefault : isSetAsDefault,
        );

        if (isEditMode) {
            editShippingPackage({
                ...data,
                id: values.id,
            });
        } else {
            saveShippingPackage(data);
        }
    };

    const isCustomBoxSelected = expandedCarrier === 'custom';
    // there is no need to validate packages provided by carriers
    const shouldValidateCustomBox = isCustomBoxSelected || isEditMode;

    const initialValues = isEditMode
        ? getFormValues(packageToBeEdited)
        : valuesForSelectedCarrier;

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: shouldValidateCustomBox
            ? customPackageValidationSchema
            : carrierPackageValidationSchema,
        initialValues,
        onSubmit,
    });

    const {
        values: { length, height, distanceUnit, width },
    } = formik;

    const { combinedGirthInInches, warningText, isGirthOverMaxLimit } =
        usePackageCombinedGirth({
            dimensionUnit: distanceUnit,
            length,
            height,
            width,
        });

    useEffect(() => {
        if (isOpen === false) {
            setExpandedCarrier('');
            formik.resetForm();
        }

        setIsSetAsDefault(false);
    }, [isOpen]);

    useEffect(() => {
        setCheckedBox(null);
    }, [expandedCarrier]);

    const handleBoxChecked = (box: IBox | null): void => {
        setCheckedBox(box);
    };

    const renderHeader = (): JSX.Element => (
        <div
            className={styles.AddPackagesModalHeader}
            data-testid="AddPackagesModalHeader"
        >
            <div className={styles.AddPackagesModalHeader_textBlock}>
                <Text type="h4">
                    {isEditMode ? 'Edit Custom Package' : 'Add Package'}
                </Text>
                {!isEditMode && (
                    <Text
                        type="p"
                        className={styles.AddPackagesModalHeader_subtitle}
                    >
                        Select from the available default packages offered by
                        our carriers.
                    </Text>
                )}
            </div>
            <IconButton
                name="CloseIcon"
                disableBorder
                alt="close"
                onClick={onClose}
                size={24}
                iconClassname={styles.AddPackagesModalHeader_close}
            />
        </div>
    );

    const isDefaultPackageCheckboxVisible =
        !isEditMode && isAbleToSetDefaultPackage;

    const isSubmitButtonDisabled =
        isSavingShippingPackage ||
        isEditingShippingPackage ||
        (isGirthOverMaxLimit && shouldValidateCustomBox);

    return (
        <Modal
            isOpen={isOpen}
            className={styles.AddPackagesModal}
            // This fixes a console error "react-modal: App element is not defined"
            appElement={document.getElementById('root') ?? undefined}
        >
            {renderHeader()}

            <FormikProvider value={formik}>
                <Form>
                    {isEditMode ? (
                        <div className={styles.AddPackagesModalContent}>
                            <EditCustomPackage
                                packageCombinedGirth={combinedGirthInInches}
                                warningText={warningText}
                            />
                        </div>
                    ) : (
                        <div className={styles.AddPackagesModalContent}>
                            {Object.entries(carrierBoxes).map((entry) => {
                                const [carrierName, boxes] = entry;

                                return (
                                    <CarrierPackageDropdown
                                        key={carrierName}
                                        carrierName={carrierName}
                                        boxes={boxes}
                                        onBoxChecked={handleBoxChecked}
                                        checkedBox={checkedBox}
                                        expandedCarrier={expandedCarrier}
                                        onCarrierExpanded={setExpandedCarrier}
                                    />
                                );
                            })}
                            <AddCustomPackageDropdown
                                isExpanded={expandedCarrier === 'custom'}
                                onToggle={setExpandedCarrier}
                                packageToBeEdited={packageToBeEdited}
                                packageCombinedGirth={combinedGirthInInches}
                                warningText={warningText}
                            />
                        </div>
                    )}
                    <div className={styles.AddPackagesModalFooter}>
                        {isDefaultPackageCheckboxVisible && (
                            <div
                                className={
                                    styles.AddPackagesModalFooter_checkboxWrapper
                                }
                            >
                                <Checkbox
                                    label="Save as default package"
                                    checked={isSetAsDefault}
                                    onChange={(event) =>
                                        setIsSetAsDefault(
                                            (event.target as HTMLInputElement)
                                                .checked,
                                        )
                                    }
                                    className={
                                        styles.AddPackagesModalFooter_checkbox
                                    }
                                    data-testid="DefaultPackageCheckbox"
                                />
                                <Tooltip
                                    variant="bottomLeft"
                                    tooltipContent="It is recommended that you set your most frequently used package size as the default"
                                    className={
                                        styles.AddPackagesModalFooter_tooltip
                                    }
                                    tooltipClassName={
                                        styles.AddPackagesModalFooter_tooltipContent
                                    }
                                >
                                    <Icon
                                        alt="play icon"
                                        color={colors.white}
                                        name="InfoCircleIcon"
                                        size={20}
                                        className={
                                            styles.AddPackagesModalFooter_tooltipIcon
                                        }
                                    />
                                </Tooltip>
                            </div>
                        )}

                        <Button
                            type="submit"
                            className={styles.AddPackagesModalFooter_button}
                            data-testid="SubmitButton"
                            disabled={isSubmitButtonDisabled}
                        >
                            {isEditMode ? 'Save changes' : 'Add Package'}
                        </Button>
                    </div>
                </Form>
            </FormikProvider>
        </Modal>
    );
}

export default AddPackagesModal;
