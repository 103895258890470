import { useEffect, useState } from 'react';
/* eslint-disable no-restricted-imports */
import {
    QueryFunctionContext,
    useInfiniteQuery as useInfiniteQueryBase,
    useMutation as useMutationBase,
    useQuery as useQueryBase,
    useQueryClient,
    MutationFunction,
    UseQueryResult,
    useIsMutating as useIsMutatingBase,
} from 'react-query';
/* eslint-enable no-restricted-imports */
import { showToast } from 'spoton-lib';

import { capitalizeFirstLetter, formatError } from 'features/common/utils';
import { IPaginatedRequest, IPaginatedResponse } from 'features/common/types';

import {
    QueryKeyT,
    UseQueryOptions,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    UsePaginatedDataPrefetchProps,
    UseIsMutatingProps,
} from './types';
import { checkIfPageExists, safeDataGetter, SafeDataGetterFunc } from './utils';

export const useQuery = <
    TQueryKey extends QueryKeyT,
    TQueryFnData,
    TError = unknown,
    TData = TQueryFnData,
    TPageParams = unknown,
    GetterNonNullFlag extends string = '',
>(
    queryKey: TQueryKey,
    queryFn: (
        context: QueryFunctionContext<TQueryKey, TPageParams>,
    ) => Promise<TQueryFnData>,
    options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
        dataFunctionName?: GetterNonNullFlag;
    } = {},
) => {
    const { dataFunctionName, ...queryOptions } = options;
    const baseResults = useQueryBase<TQueryFnData, TError, TData, TQueryKey>(
        queryKey,
        queryFn,
        queryOptions,
    );

    type ReturnType = UseQueryResult<TData, TError> &
        SafeDataGetterFunc<GetterNonNullFlag, TData>;

    if (dataFunctionName !== '' && dataFunctionName !== undefined) {
        const getterFunctionName = `get${capitalizeFirstLetter(
            dataFunctionName,
        )}Loaded`;

        return {
            ...baseResults,
            [getterFunctionName]: safeDataGetter(baseResults, dataFunctionName),
        } as ReturnType;
    }

    return baseResults as ReturnType;
};

export const useInfiniteQuery = <
    TQueryKey extends QueryKeyT,
    TQueryFnData,
    TError = unknown,
    TData = TQueryFnData,
    TPageParams = unknown,
>(
    queryKey: TQueryKey,
    queryFn: (
        context: QueryFunctionContext<TQueryKey, TPageParams>,
    ) => Promise<TQueryFnData>,
    options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
    return useInfiniteQueryBase<TQueryFnData, TError, TData, TQueryKey>(
        queryKey,
        queryFn,
        {
            ...options,
        },
    );
};

export const useMutation = <
    TData = unknown,
    TError = unknown,
    TVariables = void,
    TContext = unknown,
>(
    mutationFn: MutationFunction<TData, TVariables>,
    options: UseMutationOptions<TData, TError, TVariables, TContext>,
) => {
    const queryClient = useQueryClient();

    return useMutationBase<TData, TError, TVariables, TContext>(mutationFn, {
        ...options,
        onSuccess: (data, variables, context) => {
            const {
                isSuccessToast = true,
                formatSuccessTitle,
                formatSuccessMessage,
                onSuccess,
            } = options;

            if (isSuccessToast) {
                const successTitle = formatSuccessTitle?.(
                    data,
                    variables,
                    context,
                );
                const successMessage = formatSuccessMessage?.(
                    data,
                    variables,
                    context,
                );

                showToast({
                    title: successTitle || 'Success',
                    content: successMessage || 'Success',
                    variant: 'success',
                    autoClose: 4000,
                });
            }

            onSuccess?.({
                data,
                variables,
                context,
                queryHandler: queryClient,
            });
        },
        onError: (error, variables, context) => {
            const {
                isErrorToast = true,
                formatErrorTitle,
                onError,
                formatErrorMessage,
            } = options;

            if (isErrorToast) {
                const errorTitle = formatErrorTitle?.(
                    error,
                    variables,
                    context,
                );
                const errorMessage = formatError(error, (err) =>
                    formatErrorMessage?.(err, variables, context),
                );

                showToast({
                    title: errorTitle || 'Error',
                    content: errorMessage || 'Error. Try again',
                    variant: 'danger',
                    autoClose: 4000,
                });
            }

            onError?.({
                error,
                variables,
                context,
                queryHandler: queryClient,
            });
        },
        onSettled: (data, error, variables, context) => {
            const { onSettled } = options;

            onSettled?.({
                data,
                error,
                variables,
                context,
                queryHandler: queryClient,
            });
        },
        onMutate: (variables) => {
            return options.onMutate?.({
                variables,
                queryHandler: queryClient,
            });
        },
    });
};

export const usePaginatedDataPrefetch = <TData, TFnData>({
    data,
    queryFn,
    queryKey,
}: UsePaginatedDataPrefetchProps<TData, TFnData>) => {
    const queryClient = useQueryClient();

    useEffect(() => {
        if (data?.next) {
            queryClient.prefetchQuery(queryKey, queryFn);
        }
    }, [data]);
};

export const usePaginatedQuery = <
    TData,
    TQueryFnData = IPaginatedResponse<TData>,
    TParams extends IPaginatedRequest = IPaginatedRequest,
    TQueryKey extends QueryKeyT = QueryKeyT,
    TError = unknown,
>({
    params,
    queryFn,
    queryKey,
    options = {},
}: {
    params: TParams;
    queryKey: (params: TParams) => TQueryKey;
    queryFn: (params: TParams) => Promise<TQueryFnData>;
    options?: Omit<
        UseQueryOptions<
            TQueryFnData,
            TError,
            IPaginatedResponse<TData>,
            TQueryKey
        >,
        'keepPreviousData'
    >;
}) => {
    const [currentPage, setCurrentPage] = useState(params.page ?? 1);
    const paramsEnhanced: TParams = {
        ...params,
        page: currentPage,
    };

    const { data, ...rest } = useQuery(
        queryKey(paramsEnhanced),
        () => queryFn(paramsEnhanced),
        {
            keepPreviousData: true,
            ...options,
            onError: (error) => {
                options.onError?.(error);

                /**
                 * In case when user removes last element from last page
                 * we change page to first one because otherwise user would
                 * see "Invalid page" error
                 */
                if (!checkIfPageExists(error) && currentPage > 1) {
                    setCurrentPage(1);
                }
            },
        },
    );

    const nextPage = currentPage + 1;
    const nextParams = { ...paramsEnhanced, page: nextPage };

    usePaginatedDataPrefetch({
        queryKey: queryKey(nextParams),
        queryFn: () => queryFn(nextParams),
        data,
    });

    return {
        data,
        numberOfPages: Math.ceil((data?.count || 0) / (params?.pageSize || 10)),
        setCurrentPage,
        currentPage,
        ...rest,
    };
};

export const useIsMutating = ({ queryKey }: UseIsMutatingProps) => {
    return useIsMutatingBase(queryKey);
};
