import { mapValues } from 'lodash';

import { IAvailabilitySectionErrors } from 'features/products/components/AvailabilitySection/AvailabilitySection.types';
import { availabilitySectionErrorMessages } from 'features/products/components/AvailabilitySection/AvailabilitySection.utils';
import { IGeneralSectionErrors } from 'features/products/components/GeneralSection/GeneralSection.types';
import { IShippingSectionErrors } from 'features/products/components/ShippingSection/ShippingSection.types';

export const getErrorsListFromGeneralErrors = (
    generalSectionErrors: IGeneralSectionErrors | undefined,
): string[] => {
    if (!generalSectionErrors) {
        return [];
    }
    const { productOptions, ...generalErrors } = generalSectionErrors;
    return [
        ...Object.values(productOptions)
            .map(({ values, name, isEditing }) => [values, name, isEditing])
            .flat(),
        ...Object.values(generalErrors),
    ].filter(Boolean);
};

const parseAvailabilityErrors = (
    isOpenPriceFeatureAvailable: boolean,
    openPriceItemErrorMessage: string,
    { channels, variants, ...plainErrors }: IAvailabilitySectionErrors,
) => {
    const parsedPlainErrors = {
        ...plainErrors,
        price:
            isOpenPriceFeatureAvailable &&
            plainErrors.price ===
                availabilitySectionErrorMessages.price
                    .isPositiveForChannelWithOpenPriceDisabled
                ? openPriceItemErrorMessage
                : plainErrors.price,
        channelsAvailability:
            plainErrors.channelsAvailability ===
            availabilitySectionErrorMessages.channelsAvailability
                .hasOnlyChannelsWithOpenPriceAllowed
                ? openPriceItemErrorMessage
                : plainErrors.channelsAvailability,
    };

    const parsedChannelErrors = mapValues(channels, (channelError) =>
        isOpenPriceFeatureAvailable &&
        channelError ===
            availabilitySectionErrorMessages.price
                .isPositiveForChannelWithOpenPriceDisabled
            ? openPriceItemErrorMessage
            : channelError,
    );

    const parsedVariantErrors = mapValues(variants, (variantError) => {
        return {
            ...variantError,
            channels:
                variantError.channels ===
                availabilitySectionErrorMessages.channelsAvailability
                    .hasOnlyChannelsWithOpenPriceAllowed
                    ? openPriceItemErrorMessage
                    : variantError.channels,
            channelsPrice: mapValues(
                variantError.channelsPrice,
                (channelError) =>
                    isOpenPriceFeatureAvailable &&
                    channelError ===
                        availabilitySectionErrorMessages.price
                            .isPositiveForChannelWithOpenPriceDisabled
                        ? openPriceItemErrorMessage
                        : channelError,
            ),
        };
    });
    return {
        parsedPlainErrors,
        parsedChannelErrors,
        parsedVariantErrors,
    };
};

export const getErrorsListFromAvailabilityErrors = (
    isOpenPriceFeatureAvailable: boolean,
    openPriceItemErrorMessage: string,
    availabilityErrors?: IAvailabilitySectionErrors,
): string[] => {
    if (!availabilityErrors) {
        return [];
    }
    const { parsedPlainErrors, parsedChannelErrors, parsedVariantErrors } =
        parseAvailabilityErrors(
            isOpenPriceFeatureAvailable,
            openPriceItemErrorMessage,
            availabilityErrors,
        );
    return [
        ...Object.values(parsedPlainErrors),
        ...Object.values(parsedChannelErrors),
        ...Object.values(parsedVariantErrors)
            .map(({ channelsPrice, ...variantErrors }) => [
                ...Object.values(variantErrors),
                ...Object.values(channelsPrice || {}),
            ])
            .flat(),
    ].filter(Boolean);
};

export const getErrorsListFromShippingErrors = (
    plainErrors?: IShippingSectionErrors,
): string[] => {
    if (!plainErrors) {
        return [];
    }
    return Object.values(plainErrors).filter(Boolean);
};
