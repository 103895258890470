import error404Src from 'features/common/assets/404.svg';
import ErrorPage from 'features/errors/components/ErrorPage/ErrorPage.component';

import styles from './ErrorPage404.module.scss';

export function ErrorPage404() {
    return (
        <ErrorPage
            message="We were unable to find the page you were looking for."
            imageSrc={error404Src}
            imageAlt="404 Not Found"
            imageClassName={styles.Image}
        />
    );
}

export default ErrorPage404;
