import { IPaginatedResponse } from 'features/common/types';
import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import {
    getData,
    getPaginatedDataAndValidate,
} from 'features/common/utils/api.utils';
import { axiosShipping } from 'features/common/utils/axiosShipping.utils';
import {
    IShippingPackage,
    shippingCarrierSchema,
} from 'features/shipping/types';
import { ShippoStatus } from 'features/shipping/constants';

import {
    ICarrierBoxes,
    IShippingGetParams,
    IAddCarrierAccountPayload,
    IAddCarrierAccountResponse,
    IGetSampleFileParams,
    ISampleFileResponse,
    ICarrierTogglePayload,
} from './Shipping.types';

export function userShippoStatusGet(): Promise<ShippoStatus> {
    // return axiosShipping
    //     .get('/api/v1/shipping/user-shippo-status/')
    //     .then(getDataAndValidate(z.nativeEnum(ShippoStatus)));
    return new Promise((resolve) => resolve(ShippoStatus.notConnected));
}

export function toggleShippoStatus() {
    return axiosShipping.post<null>('/api/v1/user/toggle-shippo-status/', null);
}

export function shippingCarriersGet({
    page = 1,
    pageSize,
}: IShippingGetParams) {
    return axios
        .get('/api/v1/carriers/', {
            params: {
                page,
                pageSize,
            },
        })
        .then(getPaginatedDataAndValidate(shippingCarrierSchema.array()));
}

export function saveShippoCode(code: string): Promise<string> {
    return axiosShipping.post('/api/v1/user/save-token/', { code });
}

export function getShippingPackages({
    page = 1,
    pageSize,
}: IShippingGetParams) {
    return axios
        .get<IPaginatedResponse<IShippingPackage>>('/api/v1/shipping-boxes/', {
            params: {
                page,
                pageSize,
            },
        })
        .then((res) => res.data.results);
}

export function getAllAvailableBoxes() {
    return axios.get<ICarrierBoxes>('/api/v1/carrier-boxes/').then(getData);
}

export function saveShippingPackage(box: IShippingPackage) {
    return axios
        .post<IShippingPackage>('/api/v1/shipping-boxes/', box)
        .then(getData);
}

export function deleteShippingPackage(
    id: string,
): Promise<AxiosResponse<void>> {
    return axios.delete<void>(`/api/v1/shipping-boxes/${id}/`);
}

export function editShippingPackage(box: IShippingPackage) {
    return axios
        .patch<IShippingPackage>(`/api/v1/shipping-boxes/${box.id}/`, box)
        .then(getData);
}

export function addCarrierAccount(
    payload: IAddCarrierAccountPayload,
): Promise<AxiosResponse<IAddCarrierAccountResponse>> {
    return axiosShipping.post('/api/v1/user/carrier-accounts/', payload);
}

export function getSampleFile({
    fileType,
    fileFormat,
}: IGetSampleFileParams): Promise<AxiosResponse<ISampleFileResponse>> {
    return axiosShipping.get<ISampleFileResponse>(
        '/api/v1/shipping/sample-files/',
        {
            params: {
                fileType,
                fileFormat,
            },
        },
    );
}

export function toggleCarrier(payload: ICarrierTogglePayload) {
    return axiosShipping.put<null>('/api/v1/user/carrier-accounts/', payload);
}
