export interface IPropTypes {
    onClose: () => void;
    email: string;
}

export enum ExportModalViews {
    form = 'form',
    exportInitialized = 'exportInitialized',
    exportInitializationFailed = 'exportInitializationFailed',
    exportInProgress = 'exportInProgress',
}
