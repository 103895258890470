// eslint-disable-next-line no-restricted-imports
import axiosDefault from 'axios';
import qs from 'qs';

import {
    serializeToCamelCase,
    serializeToSnakeCase,
} from './serializers.utils';
import { getConfigVar } from './config.utils';
import { logResponseErrors } from './axiosInterceptor.utils';

const axiosShipping = axiosDefault.create({
    baseURL: getConfigVar('REACT_APP_OMNISHIPPING_API') as string,
    headers: {
        'Content-Type': 'application/json',
    },
    transformResponse: [
        (data: any): any => {
            // Parse json, if json is incorrect return data without parsing it
            // That's because error thrown by json parser doesn't contain status code which is needed in the interceptor
            try {
                return data ? serializeToCamelCase(JSON.parse(data)) : data;
            } catch {
                return data;
            }
        },
    ],
    transformRequest: [
        (data: any): any => {
            return data ? JSON.stringify(serializeToSnakeCase(data)) : data;
        },
    ],
    paramsSerializer: (params: any) =>
        qs.stringify(serializeToSnakeCase(params)),
    withCredentials: true,
});

// Log errors
axiosShipping.interceptors.response.use(...logResponseErrors);

export { axiosShipping };

if (import.meta.env.MODE === 'test') {
    // axios will default to using the XHR adapter which can't be intercepted by
    // nock. So, configure axios to use the node adapter.
    // References:
    // https://github.com/nock/nock/issues/699#issuecomment-272708264
    // https://github.com/axios/axios/issues/305
    axiosShipping.defaults.adapter = require('axios/lib/adapters/http');
}
