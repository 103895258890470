export enum ShippingMethodType {
    freeShipping = 'FREE_ON_ALL_ORDERS',
    flatRateShippingCost = 'SHIPPING_COST_OR_MIN_SPEND',
    rateByWeights = 'BY_WEIGHT',
    calculatedRate = 'SHIPPO',
}

export type FlatRateShippingMethod = Exclude<
    ShippingMethodType,
    ShippingMethodType.calculatedRate
>;

type IBaseShippingMethod = {
    id: string;
    merchantLocation: string;
    name: string;
    estimatedDeliveryTime: string;
    description?: string;
};

export type IFreeShippingMethod = IBaseShippingMethod & {
    flatRate: string;
    type: ShippingMethodType.freeShipping;
};

export type IFlatRateShippingCostMethod = IBaseShippingMethod & {
    flatRate: string;
    type: ShippingMethodType.flatRateShippingCost;
    minPrice?: string;
};

interface IWeight {
    unit: string;
    value: string;
}

export interface IWeightRate {
    toWeight: IWeight | null;
    price: string;
    id: string;
}

export type IRateByWeights = IBaseShippingMethod & {
    type: ShippingMethodType.rateByWeights;
    ratesByWeight: IWeightRate[];
    minPrice?: string;
};

export type IShippingMethod =
    | IFlatRateShippingCostMethod
    | IFreeShippingMethod
    | IRateByWeights;

export const isFlatRatesShippingMethods = (
    shippingMethods: IShippingMethod[],
): shippingMethods is IFlatRateShippingCostMethod[] => {
    return shippingMethods.every(
        (el) => el.type === ShippingMethodType.flatRateShippingCost,
    );
};
