import { ShippingCarrier } from 'features/common/utils';
import { FORMATTED_UPS_SERVICES_NAME } from 'features/shipping/constants';
import { IShippingCarrierService } from 'features/shipping/types';

type FormatServiceNameArgs = {
    carrier: ShippingCarrier;
    serviceName: string;
};

const formatServiceName = ({
    carrier,
    serviceName,
}: FormatServiceNameArgs): string => {
    const upsFormattedServiceName =
        FORMATTED_UPS_SERVICES_NAME.get(serviceName);

    if (carrier === ShippingCarrier.ups && upsFormattedServiceName) {
        return upsFormattedServiceName;
    }

    return serviceName;
};

export const formatCarrierServices = (
    services: IShippingCarrierService[],
): IShippingCarrierService[] => {
    const updatedServices = services.map((service) => {
        return {
            ...service,
            name: formatServiceName({
                carrier: service.carrier.name,
                serviceName: service.name,
            }),
        };
    });

    return updatedServices;
};

export const isServiceNameAlreadyFormatted = (serviceName: string): boolean => {
    return Array.from(FORMATTED_UPS_SERVICES_NAME.values()).includes(
        serviceName,
    );
};
