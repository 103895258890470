import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'react-router-dom';

import {
    useGetImport,
    useGetLastImport,
    useScheduleImport,
} from 'features/products/services';

export const useImport = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const importIdFromQuery = searchParams.get('importId') ?? '';
    const dropImportIdFromQuery = useCallback(() => {
        searchParams.delete('importId');
        setSearchParams(searchParams);
    }, []);

    const {
        lastImport,
        isLoading: isLoadingLast,
        isRefetching: isRefetchingLast,
    } = useGetLastImport(!importIdFromQuery);

    const {
        singleImport,
        isLoading: isLoadingSingle,
        isLoadingError: isLoadingSingleError,
        isRefetching: isRefetchingSingle,
    } = useGetImport(
        Boolean(importIdFromQuery || lastImport),
        importIdFromQuery || String(lastImport?.id),
    );

    const scheduleImportErrorMessage = 'Scheduling import failed. Try again.';
    const {
        scheduleImport,
        isLoading: isSchedulingImport,
        isSuccess: hasScheduledSuccessfully,
    } = useScheduleImport(scheduleImportErrorMessage);

    return {
        isLoading:
            isLoadingLast ||
            isRefetchingLast ||
            isLoadingSingle ||
            isRefetchingSingle ||
            isSchedulingImport,
        hasFailedFetchingByImportId: importIdFromQuery && isLoadingSingleError,

        importResult: singleImport,
        scheduleImport,
        hasScheduledSuccessfully,

        importIdFromQuery,
        dropImportIdFromQuery,
    };
};
