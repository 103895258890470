import { getData } from 'features/common/utils/api.utils';
import { axios } from 'features/common/utils/axios.utils';

import {
    IAttributeOptionPostPayload,
    IAttributeOptionPostResponse,
} from './AttributeOptions.types';

/** Creates a model */
export const attributeOptionCreate = (attribute: IAttributeOptionPostPayload) =>
    axios
        .post<IAttributeOptionPostResponse>(
            '/api/v1/attribute-options/',
            attribute,
        )
        .then(getData);
