import { createRef, useEffect, useState } from 'react';
import { Checkbox, Dropdown } from 'spoton-lib';

import {
    IPropTypes,
    ISelectionDropdownOption,
    propTypes,
} from './SelectionDropdown.types';
import styles from './SelectionDropdown.module.scss';

export function SelectionDropdown({ testId, ...props }: IPropTypes) {
    const {
        selected,
        options,
        onOptionsChange,
        hasOneOptionAlwaysSelected,
        isValid,
    } = props;

    // react-select doesn't expose a type for the ref
    const [menuPlacement, setMenuPlacement] = useState('bottom');
    const selectRef = createRef<any>();

    const maxMenuHeight = 300;

    const selectionOptions: ISelectionDropdownOption[] =
        options.map<ISelectionDropdownOption>((option) => ({
            isChecked: selected.includes(option.value),
            ...option,
        }));

    useEffect(() => {
        if (selectRef.current) {
            if (
                selectRef.current.select &&
                selectRef.current.select.controlRef
            ) {
                const controlRect =
                    selectRef.current.select.controlRef.getBoundingClientRect();

                const placement =
                    controlRect.top + maxMenuHeight > window.innerHeight
                        ? 'top'
                        : 'bottom';

                setMenuPlacement(placement);
            }
        }
    }, [selectRef]);

    const handleOnChange = (option: ISelectionDropdownOption) => {
        // if forceOneSelected at least one option has to be selected
        const isDisabledOption =
            option.isChecked &&
            hasOneOptionAlwaysSelected &&
            selectionOptions.filter((el) => el.isChecked).length === 1;

        if (option.value === '~') {
            const allSelected: string[] = options.map(({ value }) => value);

            onOptionsChange(allSelected);
        } else {
            if (isDisabledOption) {
                return;
            }

            const newSelected: string[] = option.isChecked
                ? selected.filter(
                      (selectedOption) => selectedOption !== option.value,
                  )
                : [...selected, option.value];

            onOptionsChange(newSelected);
        }
    };

    const getValue = () => {
        const label = selectionOptions
            .filter((el) => el.isChecked)
            .map((el) => el.label)
            .join(', ');

        if (!label) {
            return { value: label, label: 'Unavailable' };
        }

        return { value: label, label };
    };

    const customOption = ({
        data,
        innerProps,
    }: {
        data: ISelectionDropdownOption;
        innerProps: any;
    }) => {
        const isDisabled =
            data.isChecked &&
            hasOneOptionAlwaysSelected &&
            selectionOptions.filter((el) => el.isChecked).length === 1;

        return (
            <div {...innerProps} className={styles.SelectionDropdownOption}>
                <div className={styles.CheckboxWrapper}>
                    <Checkbox
                        checked={data.isChecked}
                        onChange={() => {
                            /** do nothing */
                        }}
                        // disabled
                        className={`${styles.Checkbox} ${
                            data.isChecked ? styles.Checkbox___checked : ''
                        }`}
                        label={data.label}
                        data-testid={`${testId}DropdownOption${data.value}`}
                        disabled={isDisabled}
                    />
                </div>
                <hr className={styles.Divider} />
            </div>
        );
    };

    return (
        <div className={styles.SelectionDropdown}>
            <Dropdown
                {...props}
                ref={selectRef}
                closeMenuOnSelect={false}
                menuPlacement={menuPlacement}
                maxMenuHeight={maxMenuHeight}
                // eslint-disable-next-line @typescript-eslint/naming-convention
                components={{ Option: customOption }}
                onChange={handleOnChange}
                value={getValue()}
                options={selectionOptions}
                data-testid={`${testId}Dropdown`}
                inputId={testId}
                isValid={isValid}
            />
        </div>
    );
}

SelectionDropdown.propTypes = propTypes;

export default SelectionDropdown;
