import { Controller, useFormContext } from 'react-hook-form';

import { VendorDropdown } from 'features/common/components';

import { IPropTypes } from './VendorDropdownController.types';

export function VendorDropdownController({ name, ...innerProps }: IPropTypes) {
    const { control, setValue } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <VendorDropdown
                    {...innerProps}
                    onChange={(value: string) => setValue(name, value)}
                    value={field.value}
                />
            )}
        />
    );
}

export default VendorDropdownController;
