import { EmptyState } from 'features/common';
import { SettingsPageHeader } from 'features/settings/components';

import { IPropTypes } from './SettingsOnboardingContent.types';
import styles from './SettingsOnboardingContent.module.scss';

export function SettingsOnboardingContent({
    description,
    headerContent,
    modalVideoUrl,
    showHeaderContent = true,
    showEmptyState = false,
    emptyStateImage = '',
    emptyStateAlt = '',
    emptyStateDescription = '',
    emptyStateCallToAction,
    headerLeftContent,
    children,
}: IPropTypes) {
    const renderHeaderContent = () => {
        if (!showHeaderContent || !modalVideoUrl) {
            return null;
        }

        return <div className={styles.HeaderContent}>{headerContent}</div>;
    };

    return (
        <div className={styles.SettingsOnboardingContent}>
            <SettingsPageHeader text={description} className={styles.Header}>
                {headerLeftContent}
                {renderHeaderContent()}
            </SettingsPageHeader>
            <div className={styles.Content}>
                {showEmptyState ? (
                    <EmptyState
                        imageUrl={emptyStateImage}
                        imageAlt={emptyStateAlt}
                        description={emptyStateDescription}
                        actions={<>{emptyStateCallToAction}</>}
                        testId="SettingsOnboardingContent"
                    />
                ) : null}
                {showEmptyState ? null : children}
            </div>
        </div>
    );
}

export default SettingsOnboardingContent;
