import { axios, AxiosResponse } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';
import { defaultSortString } from 'features/products/components/MainProductsPage/MainProductsPage.utils';
import { excludedChannels } from 'features/common/utils/channels.utils';

import {
    IProductDeleteResponse,
    IProductGetByIdResponse,
    IProductPostPayload,
    IProductPostResponse,
    IProductPatchResponse,
    IProductBulkPayload,
    IProductBulkResponse,
    IProductsGetParams,
    IProductsGetResponse,
    IProductsGetByIdsResponse,
    IProductPatchPayload,
} from './Products.types';

/** Gets a list of products */
export async function productsGet({
    page = 1,
    pageSize,
    query,
    categoryName,
    sku,
    title,
    ordering = defaultSortString,
    channel,
    category,
    location,
    tags,
    lowStock,
}: IProductsGetParams) {
    const productsData = (
        await axios.get<IProductsGetResponse>(`/api/v2/products/`, {
            params: {
                page,
                pageSize,
                q: query,
                categoryName,
                title,
                sku,
                ordering,
                channel,
                category,
                location,
                tags,
                lowStock,
            },
        })
    ).data;
    const productsDataFiltered = {
        ...productsData,
        results: productsData.results.map((product) => {
            return {
                ...product,
                channels: product.channels.filter((channel) => {
                    return !excludedChannels.includes(channel.channelName);
                }),
            };
        }),
    };
    return productsDataFiltered;
}

/** Gets a product by id */
export async function productGetById(id: string) {
    const productData = await axios
        .get<IProductGetByIdResponse>(`/api/v2/products/${id}/`)
        .then(getData);
    const productDataFiltered = {
        ...productData,
        variants: productData.variants.map((variant) => {
            return {
                ...variant,
                channels: variant.channels.filter((channel) => {
                    return !excludedChannels.includes(channel.channelName);
                }),
            };
        }),
    };
    return productDataFiltered;
}

/** Creates a model */
export function productCreate(product: IProductPostPayload) {
    return axios
        .post<IProductPostResponse>(`/api/v2/products/`, {
            ...product,
            source: 'CATALOG',
        })
        .then(getData);
}

/** Updates a model*/
export function productUpdate({ id, data }: IProductPatchPayload) {
    return axios
        .patch<IProductPatchResponse>(`/api/v2/products/${id}/`, data)
        .then(getData);
}

/** Deletes a model */
export function productDelete(id: string) {
    return axios
        .delete<IProductDeleteResponse>(`/api/v2/products/${id}/`)
        .then(getData);
}

/** Issues a bulk action on products */
export function productBulkAction(
    payload: IProductBulkPayload,
): Promise<AxiosResponse<IProductBulkResponse>> {
    return axios.post<IProductBulkResponse>(`/api/v2/products/bulk/`, payload);
}

/** Get products by parentId */
export function productsGetByParentIds(ids: string[], pageSize: number) {
    return axios
        .get<IProductsGetByIdsResponse>(`/api/v2/products-by-parent/`, {
            params: { parent: ids.join(','), pageSize },
        })
        .then(getData);
}

// TODO: remove this when we switch to React Query for product page and reuse `productBulkAction`
/** Issues a bulk action on products */
export function productsBulkAction(payload: IProductBulkPayload) {
    return axios
        .post<IProductBulkResponse>(`/api/v2/products/bulk/`, payload)
        .then(getData);
}
