import { createReducer, Reducer, AnyAction } from '@reduxjs/toolkit';

import * as productDuplicationActions from './productDuplication.actions';
import { IProductDuplicationState } from './productsDuplication.types';

export const initialState: IProductDuplicationState = {
    product: undefined,
};

export const productDuplicationReducer: Reducer<
    IProductDuplicationState,
    AnyAction
> = createReducer(initialState, (builder) => {
    builder
        .addCase(
            productDuplicationActions.createDuplicatedProduct,
            (state, action) => {
                state.product = action.payload;
            },
        )
        .addCase(productDuplicationActions.deleteDuplicatedProduct, (state) => {
            state.product = undefined;
        })
        .addDefaultCase((state) => {
            return state;
        });
});

export default productDuplicationReducer;
