import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { AppProvider } from 'appProviders/AppProvider.component';
import { Outlet } from 'react-router-dom';

import { fullstory, ErrorLogger, getConfigVar } from 'features/common/utils';
import { ErrorBoundaryGeneric } from 'features/common/components';

// Global styles
import './index.css';

// Initialize FullStory integration
fullstory.init();

// Initialize error logging integration
ErrorLogger.init();

(async () => {
    const localUser = getConfigVar('REACT_APP_LAUNCH_DARKLY_LOCAL_USER');
    const custom = localUser ? { localUser } : undefined;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const LaunchDarklyProvider = await asyncWithLDProvider({
        clientSideID: getConfigVar('REACT_APP_LAUNCH_DARKLY_ID') as string,
        options: { bootstrap: 'localStorage', streaming: false },
        // NOTE: identifying all users with same 'anonymous' key,
        // we drop support of e.g. percentage rollout. Read more here:
        // https://docs.launchdarkly.com/home/users/anonymous-users#tracking-anonymous-users-with-a-shared-key
        user: { anonymous: true, key: 'anonymous', custom },
    });

    ReactDOM.render(
        <React.StrictMode>
            <LaunchDarklyProvider>
                <AppProvider>
                    <Suspense fallback={null}>
                        <ErrorBoundaryGeneric>
                            <Outlet />
                        </ErrorBoundaryGeneric>
                    </Suspense>
                </AppProvider>
            </LaunchDarklyProvider>
        </React.StrictMode>,
        document.getElementById('root'),
    );
})();
