import * as yup from 'yup';
import { v4 as uuid } from 'uuid';

import { IProductOption, IProductOptionValue } from 'features/products/types';

import { IProductOptionsFormType } from './ProductOptions.types';

yup.addMethod(yup.string, 'attributeDefined', function () {
    return this.test(
        'attributeDefined',
        'Attribute option can not be empty!',
        (value) => {
            return value !== undefined;
        },
    );
});

export const validationSchema: yup.SchemaOf<IProductOptionsFormType> = yup
    .object()
    .shape({
        productOptions: yup.array().of(
            yup.object().shape({
                attribute: yup
                    .string()
                    .nullable()
                    .required('Attribute can not be empty!'),
                isEditing: yup
                    .boolean()
                    .oneOf(
                        [false],
                        'You must exit edit product option mode to add a new variant',
                    ),
                values: yup
                    .array()
                    .of(
                        yup.object().shape({
                            value: yup
                                .string()
                                .trim()
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                .attributeDefined()
                                .required('Attribute option can not be empty!'),
                            key: yup.string(),
                            id: yup.string(),
                            displayOrder: yup.number(),
                        }),
                    )
                    .required(),
                name: yup.string().trim().required(),
                key: yup.string().nullable(),
                displayOrder: yup.number(),
            }),
        ),
    });

export const emptyAttributeOptionField = (): IProductOptionValue => ({
    key: uuid(),
    value: '',
    displayOrder: 0,
});

export const emptyAttributeField = (): IProductOption => ({
    attribute: null,
    key: uuid(),
    name: '',
    isEditing: true,
    values: [emptyAttributeOptionField()],
    displayOrder: 0,
});

export const useIsDraggableEnabled = (listLength: number): boolean => {
    return listLength > 1;
};
