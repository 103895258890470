import { FormikErrors } from 'formik';

const objectKeys = <T>(obj: T): (keyof T)[] => {
    return Object.keys(obj) as (keyof T)[];
};

export const getFieldErrorName = <T>(formikErrors: FormikErrors<T>) => {
    const transformObjectToDotNotation = (
        obj: Record<string, any>,
        prefix = '',
        result: string[] = [],
    ) => {
        objectKeys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    const [fieldErrorName] = transformObjectToDotNotation(formikErrors);
    return fieldErrorName;
};
