export interface IUnitOfMeasure {
    name: string;
    displayName: string;
    isEnabled: boolean;
}

export enum IUnitOfMeasureGroupName {
    distance = 'Distance',
    area = 'Area',
    volume = 'Volume',
    weight = 'Weight',
    time = 'Time',
    liquidMeasurement = 'Liquid measurement',
}

export interface IUnitOfMeasureGroup {
    name: IUnitOfMeasureGroupName;
    units: IUnitOfMeasure[];
}
