import { LoadingOverlay } from 'spoton-lib';

import { useImportModal } from './hooks';
import { ImportModalForm } from './ImportModalForm';
import { ImportModalStarted } from './ImportModalStarted';
import { ImportModalInProgress } from './ImportModalInProgress';
import { ImportModalComplete } from './ImportModalComplete';
import { ImportModalIssues } from './ImportModalIssues';
import { IPropTypes, ImportModalView } from './ImportModal.types';

export function ImportModal(props: IPropTypes) {
    const {
        view,

        onOpenPrevious,
        onOpenWarnings,
        onBack,
        onClose,

        scheduleImport,
        importInitiator,
        importResults,
        importErrors,
        importWarnings,
    } = useImportModal(props);

    return (
        <>
            {view === ImportModalView.loader && <LoadingOverlay />}
            <ImportModalForm
                isOpen={view === ImportModalView.form}
                onClose={onClose}
                onSubmit={scheduleImport}
                onOpenPrevious={onOpenPrevious}
            />
            <ImportModalStarted
                isOpen={view === ImportModalView.started}
                onClose={onClose}
                initiator={importInitiator}
            />
            <ImportModalInProgress
                isOpen={view === ImportModalView.pending}
                onClose={onClose}
                initiator={importInitiator}
            />
            <ImportModalComplete
                isOpen={view === ImportModalView.completed}
                onBack={onBack}
                onClose={onClose}
                onOpenWarnings={onOpenWarnings}
                productsCreated={importResults?.productsCreated}
                variantsCreated={importResults?.variantsCreated}
            />
            <ImportModalIssues
                isOpen={view === ImportModalView.failed}
                onBack={onBack}
                onClose={onClose}
                errors={importErrors}
                warnings={importWarnings}
            />
        </>
    );
}

export default ImportModal;
