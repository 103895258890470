import { IFiltersSidebarContentType } from 'features/common/types';

export interface IPropTypes {
    onChange: (lowStockSelection: ILowStockValue) => void;
    contentType: IFiltersSidebarContentType;
    selectedLowStock: ILowStockValue;
}

export enum LowStockValue {
    true = 'true',
    false = 'false',
}

export enum LowStockLabel {
    true = 'Show only low stock items',
    false = 'Exclude low stock items',
}

export type ILowStockValue = LowStockValue.true | LowStockValue.false | null;
