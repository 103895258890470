import { Icon } from 'spoton-lib';
import clsx from 'clsx';

import styles from './PlainIconButton.module.scss';
import { IPropTypes } from './PlainIconButton.types';
/**
 * This component provides a transparent button that displays an icon and text.
 * It is used in the omnichannel duplicate/view/delete view
 */
export function PlainIconButton({
    children,
    icon,
    alt,
    className,
    testId,
    onClick,
}: IPropTypes) {
    return (
        <button
            className={clsx(styles.PlainIconButton, className)}
            title={alt}
            data-testid={testId}
            onClick={onClick}
            type="button"
        >
            <Icon name={icon} alt={alt} size={18} color="#000" />
            <span className={styles.Label}>{children}</span>
        </button>
    );
}

export default PlainIconButton;
