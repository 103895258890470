import PropTypes from 'prop-types';
import { Dropdown } from 'spoton-lib';

import { IDropdownOption } from 'features/common/types/dropdownOption.type';

export interface ISelectionDropdownOption {
    value: string;
    label: string;
    isChecked: boolean;
}

export type SelectionDropdownOnChange = (selectedOptions: string[]) => void;

export interface IPropTypes extends React.ComponentProps<typeof Dropdown> {
    /** label shown above the input */
    label: string;

    /** Ids of selected options  */
    selected: string[];

    /** All options */
    options: IDropdownOption[];

    /** callback called on every change */
    onOptionsChange: SelectionDropdownOnChange;

    /** id for test purposes */
    testId?: string;

    /** at least one option is always selected */
    hasOneOptionAlwaysSelected?: boolean;

    /** should not display error */
    isValid?: boolean;
}

export const propTypes = {
    selected: PropTypes.array,
    options: PropTypes.array,
    onOptionsChange: PropTypes.func,
    allSelectedLabel: PropTypes.string,
    label: PropTypes.string,
};
