import { UseQueryResult } from 'react-query';

import {
    capitalizeFirstLetter,
    isAxiosErrorWithFormattedMessage,
} from 'features/common/utils';

export type SafeDataGetterFunc<GetterNonNullFlag, TData> =
    GetterNonNullFlag extends string
        ? {
              [K in GetterNonNullFlag as `get${Capitalize<K>}Loaded`]: () => TData;
          }
        : never;

export const safeDataGetter =
    <GetterNonNullFlag extends string, TData, TError>(
        baseResults: UseQueryResult<TData, TError>,
        dataFunctionName: GetterNonNullFlag,
    ) =>
    () => {
        if (!baseResults.data) {
            throw new Error(
                `Wait for data before using get${capitalizeFirstLetter(
                    dataFunctionName,
                )}Loaded()`,
            );
        }

        return baseResults.data;
    };

/**
 * Works only for paginated queries. Checks if query contains page that does not exist.
 */
export const checkIfPageExists = (error: unknown) => {
    if (isAxiosErrorWithFormattedMessage(error)) {
        if (error.response?.data.detail === 'Invalid page.') {
            return false;
        }
    }

    return true;
};
