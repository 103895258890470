import { useIsDraggableEnabled } from 'features/products/components/ProductOptions/ProductOptions.utils';

import { ProductAttribute } from './ProductAttribute';
import { ProductAttributeEdit } from './ProductAttributeEdit';
import { IPropsType } from './ProductOptionsList.types';

export function ProductOptionsList({
    productOptions,
    onRemove,
    isPageEditing,
}: IPropsType) {
    const isDragDisabled = !useIsDraggableEnabled(productOptions.length);

    return (
        <>
            {productOptions.map(({ isEditing, attribute, key }, index) => {
                const props = {
                    key: attribute || key,
                    attributeKey: attribute || key,
                    index,
                    isDragDisabled,
                    name: `productOptions.${index}`,
                    onRemoveClick: () => onRemove(index),
                    isPageEditing,
                };

                return isEditing ? (
                    <ProductAttributeEdit {...props} />
                ) : (
                    <ProductAttribute {...props} />
                );
            })}
        </>
    );
}
