import { createContext, useContext } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button, Modal, ModalFooter } from 'spoton-lib';
import clsx from 'clsx';

import { ModalHeaderTempFix } from 'features/common/components/ModalHeaderTempFix';

import {
    INexusModalContext,
    INexusModalPropTypes,
    INexusModalHeaderPropTypes,
    INexusModalFooterPropTypes,
} from './NexusModal.types';
import styles from './NexusModal.module.scss';

// eslint-disable-next-line @typescript-eslint/naming-convention
const NexusModalContext = createContext<INexusModalContext>(undefined);

function useNexusModalContext() {
    const modalContext = useContext(NexusModalContext);
    if (!modalContext) {
        throw new Error(
            `NexusModal compound components cannot be rendered outside the NexusModal component.`,
        );
    }
    return modalContext;
}

/**
 * This is a spotonLib's Modal wrapper that should be used as a default.
 * It follows Compound Components pattern and enforces HTML structure and CSS styles according to Nexus Guidelines.
 *
 * If you need to use modified footer (e.g. with 3 buttons) you can write your own footer,
 * but please take into consideration all the rules in Nexus-Web-UI-Kit on Figma.
 */
export function NexusModal({
    isOpen,
    onRequestClose,
    shouldCloseOnOverlayClick,
    children,
    ...modalProps
}: INexusModalPropTypes) {
    return (
        <NexusModalContext.Provider value={{ onRequestClose }}>
            <Modal
                {...modalProps}
                isOpen={isOpen ?? false}
                onRequestClose={onRequestClose}
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ?? false}
                // This fixes a console error "react-modal: App element is not defined"
                appElement={document.getElementById('root') ?? undefined}
            >
                {children}
            </Modal>
        </NexusModalContext.Provider>
    );
}

/**
 * Should be used as a child of NexusModal.
 */
function Header({
    title,
    isCloseButtonDisplayed = true,
    closeButtonClassName,
    isCentered = false,
    ...headerProps
}: INexusModalHeaderPropTypes) {
    const modalContext = useNexusModalContext();

    return (
        <ModalHeaderTempFix
            content={title}
            onRequestClose={
                isCloseButtonDisplayed ? modalContext.onRequestClose : undefined
            }
            iconAlt="Close modal"
            className={clsx(
                styles.ModalHeader,
                isCentered ? styles.ModalHeader_centered : undefined,
            )}
            closeButtonClassName={closeButtonClassName}
            {...headerProps}
        />
    );
}

/**
 * Should be used as a child of NexusModal.
 * Please use 'isDanger' as true for irreversible actions.
 */
function Footer({
    submitTitle,
    cancelTitle,
    isDanger = false,
    isSubmitLoading = false,
    isSubmitDisabled = false,
    isCancelDisabled = false,
    onSubmit,
    onCancel,
    submitButtonId,
    cancelButtonId,
    'submit-button-data-testid': submitButtonTestId,
    'cancel-button-data-testid': cancelButtonTestId,
}: INexusModalFooterPropTypes) {
    const modalContext = useNexusModalContext();
    const onCancelClick = !onCancel ? modalContext.onRequestClose : onCancel;

    return (
        <ModalFooter className={styles.ModalFooter}>
            {cancelTitle && (
                <Button
                    onClick={onCancelClick}
                    disabled={isCancelDisabled}
                    variant="secondary"
                    type="button"
                    id={cancelButtonId}
                    data-testid={cancelButtonTestId}
                    className={styles.ModalFooter_button}
                >
                    {cancelTitle}
                </Button>
            )}
            <Button
                onClick={onSubmit}
                disabled={isSubmitDisabled}
                isLoading={isSubmitLoading}
                variant="primary"
                type="button"
                id={submitButtonId}
                data-testid={submitButtonTestId}
                danger={isDanger}
                className={styles.ModalFooter_button}
            >
                {submitTitle}
            </Button>
        </ModalFooter>
    );
}

NexusModal.Header = Header;
NexusModal.Footer = Footer;

export default NexusModal;
