import { axios } from 'features/common/utils/axios.utils';
import { getData } from 'features/common/utils/api.utils';

import {
    IMerchantLocationSettingGetByIdResponse,
    IMerchantLocationSettingPostResponse,
    IAnyMerchantLocationSettingPostPayloadItem,
} from './MerchantLocationSettings.types';

/**
 * Gets a MerchantLocationSetting by merchant location id
 * `/api/v1/merchant-locations/${merchantLocationId}/settings/`
 * */
export function fetchMerchantLocationSettingsById(merchantLocationId?: string) {
    return axios
        .get<IMerchantLocationSettingGetByIdResponse>(
            `/api/v1/merchant-locations/${merchantLocationId}/settings/`,
        )
        .then(getData);
}

/**
 * Creates a model
 * POST `/api/v1/merchant-locations/${merchantLocationId}/settings/`
 */
export function merchantLocationSettingsCreate(
    merchantLocationSettings: IAnyMerchantLocationSettingPostPayloadItem,
) {
    const { merchantLocationId } = merchantLocationSettings;

    return axios
        .post<IMerchantLocationSettingPostResponse>(
            `/api/v1/merchant-locations/${merchantLocationId}/settings/`,
            merchantLocationSettings,
        )
        .then(getData);
}
