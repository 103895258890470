import { Checkbox, Text } from 'spoton-lib';

import { NetworkError } from 'features/common/components';
import { filtersContentTypeString } from 'features/common/components/FiltersSidebar/FiltersSidebar.utils';
import { useFetchChannels } from 'features/common/services';
import { naturalSortByProperty } from 'features/common/utils';

import { IPropTypes } from './FiltersSidebarChannel.types';
import styles from './FiltersSidebarChannel.module.scss';

/** Component for filtering products by channel */
export function FiltersSidebarChannel(props: IPropTypes) {
    const { selectedChannels, contentType, onChange } = props;
    const { channels, isError, refetch } = useFetchChannels();
    const options = naturalSortByProperty(
        channels.map((category) => ({
            id: String(category.id),
            label: category.displayName,
            value: `${category.id}`,
        })),
        'label',
    );

    const selectedIds = selectedChannels.map(({ id }) => id);

    const handleChange = (channelId: string) => {
        const isSelected = selectedIds.includes(channelId);
        const newSelectedChannels = options.filter(({ id }) =>
            id === channelId ? !isSelected : selectedIds.includes(id),
        );
        onChange(newSelectedChannels);
    };

    if (isError) {
        return (
            <div className={styles.FiltersSidebarChannel}>
                <NetworkError
                    offlineErrorMessage="Failed to load channels"
                    onRefreshClick={refetch}
                    data-testid="FiltersSidebarChannelErrorButton"
                />
            </div>
        );
    }

    return (
        <div className={styles.FiltersSidebarChannel}>
            <Text className={styles.FiltersSidebarChannel_header}>
                Select a channel in which you want to search your{' '}
                {filtersContentTypeString[contentType]}.
            </Text>
            <form
                onSubmit={(e) => e.preventDefault()}
                className={styles.FiltersSidebarChannel_form}
                data-testid="FiltersSidebarChannels"
            >
                {options.map(({ id, label }) => (
                    <Checkbox
                        key={id}
                        checked={selectedIds.includes(id)}
                        onChange={() => handleChange(id)}
                        label={label}
                        className={styles.CheckboxItem}
                        data-testid={`FiltersSidebarChannelOption-${id}`}
                    />
                ))}
            </form>
        </div>
    );
}

export default FiltersSidebarChannel;
