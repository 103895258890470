import { useState } from 'react';
import clsx from 'clsx';

import { DeletePrompt, PlainIconButton } from 'features/common';
import { UnsavedPromptRaw } from 'features/shipping/components/UnsavedPrompt';

import styles from './EditControls.module.scss';
import { IPropTypes } from './EditControls.types';

export function EditControls({
    onDelete,
    renderDuplicationModal,
    isInactive,
    isDuplicationOpen,
    setIsDuplicationOpen,
    shouldDisplayUnsavedChangesModal,
}: IPropTypes) {
    const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);
    const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useState(false);

    // open certain modals only when the main view with data is loaded and ready
    const handleDuplicateClick = () => {
        setIsDuplicationOpen(!isInactive);
        setIsDuplicationModalOpen(!shouldDisplayUnsavedChangesModal);
    };

    const handleUnsavedChangesPromptClose = () => setIsDuplicationOpen(false);

    const handleUnsavedChangesPromptConfirm = () =>
        setIsDuplicationModalOpen(true);

    const handleDeleteClick = () => setIsDeletePromptOpen(true);

    const handleDeletePromptCancel = () => setIsDeletePromptOpen(false);

    const handleDeletePromptConfirm = () => {
        setIsDeletePromptOpen(false);
        onDelete();
    };

    const handleDuplicationClose = () => {
        setIsDuplicationModalOpen(false);
        setIsDuplicationOpen(false);
    };

    const unsavedChangesPrompt =
        isDuplicationOpen &&
        shouldDisplayUnsavedChangesModal &&
        !isDuplicationModalOpen ? (
            <UnsavedPromptRaw
                shouldClearOnUnmount={false}
                isModalOpen={
                    isDuplicationOpen &&
                    shouldDisplayUnsavedChangesModal &&
                    !isDuplicationModalOpen
                }
                onCloseModal={handleUnsavedChangesPromptClose}
                onConfirm={handleUnsavedChangesPromptConfirm}
            />
        ) : null;

    const duplicationModal = renderDuplicationModal(
        isDuplicationModalOpen,
        handleDuplicationClose,
    );

    const deletePrompt = isDeletePromptOpen ? (
        <DeletePrompt
            isOpen={isDeletePromptOpen}
            message="If you delete this product, you won’t be able to access it again."
            onCancel={handleDeletePromptCancel}
            onConfirm={handleDeletePromptConfirm}
        />
    ) : null;

    return (
        <>
            <div className={styles.EditControls} data-testid="editControls">
                <PlainIconButton
                    icon="DuplicateIcon"
                    alt="Duplicate"
                    onClick={handleDuplicateClick}
                    data-testid="editControlsDuplicate"
                    className={clsx(
                        isInactive && styles.EditControls_inactiveButton,
                    )}
                >
                    Duplicate
                </PlainIconButton>
                <PlainIconButton
                    icon="DeleteIcon"
                    alt="Delete"
                    onClick={handleDeleteClick}
                    testId="editControlsDelete"
                >
                    Delete
                </PlainIconButton>
            </div>
            {unsavedChangesPrompt}
            {duplicationModal}
            {deletePrompt}
        </>
    );
}

export default EditControls;
